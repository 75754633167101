import { useEffect, useState } from "react";

interface UxSVGProps {
  classes: any;
}

export const UserResearchSVG = ({ classes }: UxSVGProps) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [svgYindex, setSvgYindex] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      // Update the scroll position
      setScrollPosition(window.scrollY);
    };

    // Add the event listener for scroll
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const div = document.getElementById("ux-research-svg"); // Replace 'myDiv' with your div's ID

    if (div) {
      const rect = div.getBoundingClientRect();
      const yCoordinateDocument = rect.top + window.scrollY;
      setSvgYindex(yCoordinateDocument);
    }
  }, []);

  return (
    <div className={`${classes}`} id="ux-research-svg">
      <svg
        id="e16XPYsai9b1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 500 437"
        shape-rendering="geometricPrecision"
        text-rendering="geometricPrecision"
      >
        <defs>
          <linearGradient
            id="e16XPYsai9b3-fill"
            x1="853.7242"
            y1="129.298415"
            x2="956.0663"
            y2="320.790415"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b3-fill-0"
              offset="0%"
              stop-color="#0a7cb7"
            ></stop>
            <stop
              id="e16XPYsai9b3-fill-1"
              offset="100%"
              stop-color="#44baff"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b6-fill"
            x1="985.8605"
            y1="44.831315"
            x2="848.2275"
            y2="270.090715"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop id="e16XPYsai9b6-fill-0" offset="0%" stop-color="#fff"></stop>
            <stop
              id="e16XPYsai9b6-fill-1"
              offset="99.64%"
              stop-color="#ebeff2"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b7-fill"
            x1="834.006"
            y1="297.601915"
            x2="956.568"
            y2="297.601915"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop id="e16XPYsai9b7-fill-0" offset="0%" stop-color="#fff"></stop>
            <stop
              id="e16XPYsai9b7-fill-1"
              offset="99.64%"
              stop-color="#ebeff2"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b8-fill"
            x1="834.006"
            y1="314.547415"
            x2="956.568"
            y2="314.547415"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop id="e16XPYsai9b8-fill-0" offset="0%" stop-color="#fff"></stop>
            <stop
              id="e16XPYsai9b8-fill-1"
              offset="99.64%"
              stop-color="#ebeff2"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b9-fill"
            x1="834.006"
            y1="331.485415"
            x2="956.568"
            y2="331.485415"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop id="e16XPYsai9b9-fill-0" offset="0%" stop-color="#fff"></stop>
            <stop
              id="e16XPYsai9b9-fill-1"
              offset="99.64%"
              stop-color="#ebeff2"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b10-fill"
            x1="834.004"
            y1="49.691015"
            x2="895.656"
            y2="49.691015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b10-fill-0"
              offset="0%"
              stop-color="#fff"
            ></stop>
            <stop
              id="e16XPYsai9b10-fill-1"
              offset="99.64%"
              stop-color="#ebeff2"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b11-fill"
            x1="834.004"
            y1="58.138015"
            x2="895.656"
            y2="58.138015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b11-fill-0"
              offset="0%"
              stop-color="#fff"
            ></stop>
            <stop
              id="e16XPYsai9b11-fill-1"
              offset="99.64%"
              stop-color="#ebeff2"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b18-fill"
            x1="834.004"
            y1="66.589915"
            x2="895.656"
            y2="66.589915"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b18-fill-0"
              offset="0%"
              stop-color="#fff"
            ></stop>
            <stop
              id="e16XPYsai9b18-fill-1"
              offset="99.64%"
              stop-color="#ebeff2"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b19-fill"
            x1="875.5555"
            y1="95.101715"
            x2="802.9265"
            y2="235.005915"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b19-fill-0"
              offset="17.12%"
              stop-color="#5b5bc1"
            ></stop>
            <stop
              id="e16XPYsai9b19-fill-1"
              offset="91.53%"
              stop-color="#8f8ce4"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b22-fill"
            x1="888.1598"
            y1="92.781515"
            x2="777.2698"
            y2="274.271715"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b22-fill-0"
              offset="0%"
              stop-color="#fff"
            ></stop>
            <stop
              id="e16XPYsai9b22-fill-1"
              offset="99.64%"
              stop-color="#ebeff2"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b29-fill"
            x1="788.5057"
            y1="138.984915"
            x2="798.4247"
            y2="228.662915"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b29-fill-0"
              offset="0%"
              stop-color="#ffc444"
            ></stop>
            <stop
              id="e16XPYsai9b29-fill-1"
              offset="99.64%"
              stop-color="#f36f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b31-fill"
            x1="765.652"
            y1="203.530515"
            x2="800.855"
            y2="203.530515"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b31-fill-0"
              offset="0%"
              stop-color="#0a7cb7"
            ></stop>
            <stop
              id="e16XPYsai9b31-fill-1"
              offset="100%"
              stop-color="#44baff"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b32-fill"
            x1="875.5588"
            y1="118.138115"
            x2="833.0638"
            y2="177.408115"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b32-fill-0"
              offset="0%"
              stop-color="#0a7cb7"
            ></stop>
            <stop
              id="e16XPYsai9b32-fill-1"
              offset="100%"
              stop-color="#44baff"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b35-fill"
            x1="746.9183"
            y1="115.991215"
            x2="706.0333"
            y2="249.970215"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b35-fill-0"
              offset="0%"
              stop-color="#ffc444"
            ></stop>
            <stop
              id="e16XPYsai9b35-fill-1"
              offset="99.64%"
              stop-color="#f36f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b41-fill"
            x1="1101.161"
            y1="18.482115"
            x2="1030.881"
            y2="153.860115"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b41-fill-0"
              offset="17.12%"
              stop-color="#5b5bc1"
            ></stop>
            <stop
              id="e16XPYsai9b41-fill-1"
              offset="91.53%"
              stop-color="#8f8ce4"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b44-fill"
            x1="1113.354"
            y1="16.238815"
            x2="1006.054"
            y2="191.857215"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b44-fill-0"
              offset="0%"
              stop-color="#fff"
            ></stop>
            <stop
              id="e16XPYsai9b44-fill-1"
              offset="99.64%"
              stop-color="#ebeff2"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b51-fill"
            x1="1016.9236"
            y1="60.943615"
            x2="1026.5236"
            y2="147.719615"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b51-fill-0"
              offset="0%"
              stop-color="#ffc444"
            ></stop>
            <stop
              id="e16XPYsai9b51-fill-1"
              offset="99.64%"
              stop-color="#f36f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b53-fill"
            x1="994.3982"
            y1="98.850315"
            x2="1045.8652"
            y2="165.027915"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b53-fill-0"
              offset="0%"
              stop-color="#487ba9"
            ></stop>
            <stop
              id="e16XPYsai9b53-fill-1"
              offset="100%"
              stop-color="#0a7cb7"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b54-fill"
            x1="1101.1637"
            y1="40.777415"
            x2="1060.0437"
            y2="98.130615"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b54-fill-0"
              offset="0%"
              stop-color="#0a7cb7"
            ></stop>
            <stop
              id="e16XPYsai9b54-fill-1"
              offset="100%"
              stop-color="#44baff"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b59-fill"
            x1="1094.2346"
            y1="386.056615"
            x2="1097.3746"
            y2="424.849615"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b59-fill-0"
              offset="0%"
              stop-color="#311944"
            ></stop>
            <stop
              id="e16XPYsai9b59-fill-1"
              offset="100%"
              stop-color="#a03976"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b60-fill"
            x1="1102.15"
            y1="407.634015"
            x2="1101.5"
            y2="367.450015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b60-fill-0"
              offset="0%"
              stop-color="#ffc444"
            ></stop>
            <stop
              id="e16XPYsai9b60-fill-1"
              offset="99.64%"
              stop-color="#f36f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b61-fill"
            x1="1151.8438"
            y1="381.387715"
            x2="1154.9838"
            y2="420.179715"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b61-fill-0"
              offset="0%"
              stop-color="#311944"
            ></stop>
            <stop
              id="e16XPYsai9b61-fill-1"
              offset="100%"
              stop-color="#a03976"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b62-fill"
            x1="1154.9587"
            y1="406.778015"
            x2="1154.3088"
            y2="366.593015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b62-fill-0"
              offset="0%"
              stop-color="#ffc444"
            ></stop>
            <stop
              id="e16XPYsai9b62-fill-1"
              offset="99.64%"
              stop-color="#f36f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b63-fill"
            x1="1092.3364"
            y1="342.529115"
            x2="1122.0042"
            y2="342.529115"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b63-fill-0"
              offset="0%"
              stop-color="#0a7cb7"
            ></stop>
            <stop
              id="e16XPYsai9b63-fill-1"
              offset="99.64%"
              stop-color="#046290"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b65-fill"
            x1="1100.53"
            y1="339.514015"
            x2="1160.5685"
            y2="339.514015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b65-fill-0"
              offset="0%"
              stop-color="#0a7cb7"
            ></stop>
            <stop
              id="e16XPYsai9b65-fill-1"
              offset="99.64%"
              stop-color="#046290"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b66-fill"
            x1="1150.4147"
            y1="204.336615"
            x2="1083.0247"
            y2="314.992615"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b66-fill-0"
              offset="0%"
              stop-color="#099"
            ></stop>
            <stop
              id="e16XPYsai9b66-fill-1"
              offset="100%"
              stop-color="#046290"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b67-fill"
            x1="1136.7483"
            y1="202.939815"
            x2="1130.3983"
            y2="258.932815"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b67-fill-0"
              offset="0%"
              stop-color="#ffc444"
            ></stop>
            <stop
              id="e16XPYsai9b67-fill-1"
              offset="99.64%"
              stop-color="#f36f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b68-fill"
            x1="1138.4695"
            y1="215.997115"
            x2="1129.5195"
            y2="189.648115"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b68-fill-0"
              offset="0%"
              stop-color="#444b8c"
            ></stop>
            <stop
              id="e16XPYsai9b68-fill-1"
              offset="99.64%"
              stop-color="#3e4177"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b69-fill"
            x1="1131.0292"
            y1="196.495115"
            x2="1136.0192"
            y2="237.753115"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b69-fill-0"
              offset="0%"
              stop-color="#ffc444"
            ></stop>
            <stop
              id="e16XPYsai9b69-fill-1"
              offset="99.64%"
              stop-color="#f36f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b70-fill"
            x1="1058.28"
            y1="261.502915"
            x2="1106.41"
            y2="261.502915"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b70-fill-0"
              offset="0%"
              stop-color="#aa80f9"
            ></stop>
            <stop
              id="e16XPYsai9b70-fill-1"
              offset="99.64%"
              stop-color="#6165d7"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b71-fill"
            x1="1048.0415"
            y1="271.389615"
            x2="1057.2969"
            y2="276.996815"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b71-fill-0"
              offset="0%"
              stop-color="#ffc444"
            ></stop>
            <stop
              id="e16XPYsai9b71-fill-1"
              offset="99.64%"
              stop-color="#f36f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b72-fill"
            x1="1186.8927"
            y1="226.548615"
            x2="1119.5027"
            y2="337.205615"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b72-fill-0"
              offset="0%"
              stop-color="#099"
            ></stop>
            <stop
              id="e16XPYsai9b72-fill-1"
              offset="100%"
              stop-color="#046290"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b74-fill"
            x1="1038.8199"
            y1="284.015215"
            x2="1161.3936"
            y2="284.015215"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b74-fill-0"
              offset="0%"
              stop-color="#444b8c"
            ></stop>
            <stop
              id="e16XPYsai9b74-fill-1"
              offset="99.64%"
              stop-color="#26264f"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b75-fill"
            x1="925.7724"
            y1="245.335015"
            x2="1052.2466"
            y2="245.335015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b75-fill-0"
              offset="0%"
              stop-color="#444b8c"
            ></stop>
            <stop
              id="e16XPYsai9b75-fill-1"
              offset="99.64%"
              stop-color="#26264f"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b78-fill"
            x1="971.9421"
            y1="316.405115"
            x2="992.2751"
            y2="223.008115"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b78-fill-0"
              offset="0%"
              stop-color="#444b8c"
            ></stop>
            <stop
              id="e16XPYsai9b78-fill-1"
              offset="99.64%"
              stop-color="#26264f"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b79-fill"
            x1="1002.1846"
            y1="179.725815"
            x2="973.8046"
            y2="304.979815"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b79-fill-0"
              offset="0%"
              stop-color="#444b8c"
            ></stop>
            <stop
              id="e16XPYsai9b79-fill-1"
              offset="99.64%"
              stop-color="#26264f"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e16XPYsai9b80-fill"
            x1="1154.6542"
            y1="307.070615"
            x2="1159.6342"
            y2="294.231615"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e16XPYsai9b80-fill-0"
              offset="0%"
              stop-color="#ffc444"
            ></stop>
            <stop
              id="e16XPYsai9b80-fill-1"
              offset="99.64%"
              stop-color="#f36f56"
            ></stop>
          </linearGradient>
        </defs>
        <g transform="translate(-697.418091 0)">
          <path
            d="M987.2,30.3L800.7,24c-4.3-.1-7.7,3.2-7.7,7.4v338.7c0,4.2,3.5,7.4,7.7,7.2l186.5-10.6c3.9-.2,7-3.6,7-7.6v-321.3c0-4-3.1-7.4-7-7.5Z"
            fill="url(#e16XPYsai9b3-fill)"
          ></path>
          <g opacity="0.3">
            <path
              d="M910.7,280.5c-3.2-38.7,20.6-54.7,11.5-89.8-11.2-43.4-54.4-45.2-69.1-87.8-5.6-16.3-7.9-40.9,8.3-76.8L800.7,24c-4.3-.1-7.7,3.2-7.7,7.4v338.7c0,4.2,3.5,7.4,7.7,7.2L967,367.8c-42.3-32.7-54.3-63.5-56.3-87.3Z"
              opacity="0.3"
              fill="#d8dee8"
            ></path>
          </g>
          <path
            d="M987.2,34.6L800.7,28.5c-1.7-.1-3.2,1.3-3.2,3v338.4c0,1.7,1.4,3.1,3.2,3l186.5-10.4c1.6-.1,2.9-1.5,2.9-3.1v-321.7c0-1.7-1.3-3.1-2.9-3.1Z"
            opacity="0.3"
            fill="url(#e16XPYsai9b6-fill)"
          ></path>
          <path
            d="M952,290.7l-113.2,4.1c-2.7.1-4.8,2.3-4.8,5s2.2,4.7,4.8,4.6L952,300c2.5-.1,4.5-2.3,4.5-4.9.1-2.4-2-4.5-4.5-4.4Z"
            opacity="0.6"
            fill="url(#e16XPYsai9b7-fill)"
          ></path>
          <path
            d="M952,307.4L838.8,312c-2.7.1-4.8,2.4-4.8,5s2.2,4.7,4.8,4.6L952,316.7c2.5-.1,4.5-2.3,4.5-4.9.1-2.5-2-4.5-4.5-4.4Z"
            opacity="0.6"
            fill="url(#e16XPYsai9b8-fill)"
          ></path>
          <path
            d="M952,324.1l-113.2,5.1c-2.7.1-4.8,2.4-4.8,5c0,2.7,2.2,4.7,4.8,4.6L952,333.4c2.5-.1,4.5-2.3,4.5-4.9.1-2.5-2-4.5-4.5-4.4Z"
            opacity="0.6"
            fill="url(#e16XPYsai9b9-fill)"
          ></path>
          <path
            d="M893.3,48.1l-56.9-1.6c-1.3,0-2.4,1-2.4,2.3s1.1,2.4,2.4,2.5l56.9,1.5c1.3,0,2.3-1,2.3-2.3.1-1.2-1-2.3-2.3-2.4Z"
            opacity="0.6"
            fill="url(#e16XPYsai9b10-fill)"
          ></path>
          <path
            d="M893.3,56.5L836.4,55c-1.3,0-2.4,1-2.4,2.3s1.1,2.4,2.4,2.5l56.9,1.4c1.3,0,2.3-1,2.3-2.3s-1-2.3-2.3-2.4Z"
            opacity="0.6"
            fill="url(#e16XPYsai9b11-fill)"
          ></path>
          <path
            d="M911.1,280.4c-3.2-38.7,20.6-54.7,11.5-89.8-11.2-43.4-54.3-45.2-69-87.8C848.3,87.3,846,64,860,30.4l-58.8-1.9c-1.7-.1-3.2,1.3-3.2,3v338.3c0,1.7,1.4,3.1,3.2,3l161.2-9c-38.2-31.2-49.4-60.5-51.3-83.4Z"
            opacity="0.35"
            fill="#fff"
          ></path>
          <path
            d="M910.5,279.6l46.8-1c1.2,0,2.2,1,2.2,2.2v21.8c0,1.3-1,2.3-2.2,2.4L908,306.7c-1.3,0-2.3-1-2.3-2.3v-28.5l4.8,3.7Z"
            opacity="0.3"
            fill="#8f8ce4"
          ></path>
          <path
            d="M914,298l37.5-1.1v2.6L914,300.7v-2.7Z"
            opacity="0.67"
            fill="#8f8ce4"
          ></path>
          <path
            d="M914,292.7l37.5-1v2.6L914,295.4v-2.7Z"
            opacity="0.67"
            fill="#8f8ce4"
          ></path>
          <path
            d="M914,287.3l37.5-.9v2.6L914,290v-2.7Z"
            opacity="0.67"
            fill="#8f8ce4"
          ></path>
        </g>
        <g
          id="e16XPYsai9b17"
          transform="translate(-697.418091 -66.429108)"
          className={`${
            scrollPosition - svgYindex >= -450 ? "translate-test" : ""
          }`}
        >
          <path
            d="M893.3,64.9l-56.9-1.4c-1.3,0-2.4,1-2.4,2.3s1.1,2.4,2.4,2.4l56.9,1.3c1.3,0,2.3-1,2.3-2.3.1-1.2-1-2.3-2.3-2.3Z"
            opacity="0.6"
            fill="url(#e16XPYsai9b18-fill)"
          ></path>
          <path
            d="M889.3,81.1L739,76c-3.4-.1-6.2,2.5-6.2,5.9v272.9c0,3.4,2.8,6,6.2,5.8l150.3-8.5c3.1-.2,5.6-2.9,5.6-6.2v-258.8c0-3.2-2.5-5.9-5.6-6Z"
            fill="url(#e16XPYsai9b19-fill)"
          ></path>
          <g opacity="0.3">
            <path
              d="M827.6,282.6c-2.6-31.2,16.6-44.1,9.3-72.4-9-34.9-43.8-36.4-55.6-70.7-4.5-13.1-6.3-33,6.7-61.9L739,76c-3.4-.1-6.2,2.5-6.2,5.9v272.9c0,3.4,2.8,6,6.2,5.8L873,353c-34.1-26.3-43.8-51.2-45.4-70.4Z"
              opacity="0.3"
              fill="#d8dee8"
            ></path>
          </g>
          <path
            d="M889.3,84.5L739,79.6c-1.4,0-2.6,1-2.6,2.4v272.6c0,1.4,1.1,2.5,2.6,2.4l150.3-8.4c1.3-.1,2.3-1.2,2.3-2.5v-259.1c0-1.3-1-2.4-2.3-2.5Z"
            fill="url(#e16XPYsai9b22-fill)"
          ></path>
          <path
            d="M860.9,290.9l-91.2,3.3c-2.2.1-3.9,1.9-3.9,4c0,2.2,1.7,3.8,3.9,3.7l91.2-3.5c2-.1,3.7-1.8,3.7-3.9c0-2-1.7-3.7-3.7-3.6Z"
            fill="#d8dee8"
          ></path>
          <path
            d="M860.9,304.4l-91.2,3.7c-2.2.1-3.9,1.9-3.9,4.1s1.7,3.8,3.9,3.7l91.2-3.9c2-.1,3.7-1.8,3.7-3.9c0-2.2-1.7-3.8-3.7-3.7Z"
            fill="#d8dee8"
          ></path>
          <path
            d="M860.9,317.8l-91.2,4.1c-2.2.1-3.9,1.9-3.9,4.1s1.7,3.8,3.9,3.7l91.2-4.3c2-.1,3.7-1.9,3.7-3.9c0-2.2-1.7-3.8-3.7-3.7Z"
            fill="#d8dee8"
          ></path>
          <path
            d="M813.6,95.5l-45.9-1.3c-1.1,0-1.9.8-1.9,1.9s.9,2,1.9,2l45.9,1.2c1,0,1.9-.8,1.9-1.8c0-1.1-.9-2-1.9-2Z"
            fill="#9fc8f0"
          ></path>
          <path
            d="M813.6,102.2L767.7,101c-1.1,0-1.9.8-1.9,1.9s.9,1.9,1.9,2l45.9,1.1c1,0,1.9-.8,1.9-1.9c0-1-.9-1.9-1.9-1.9Z"
            fill="#9fc8f0"
          ></path>
          <path
            d="M813.6,109l-45.9-1.1c-1.1,0-1.9.8-1.9,1.9s.9,1.9,1.9,2l45.9,1c1,0,1.9-.8,1.9-1.9c0-1-.9-1.9-1.9-1.9Z"
            fill="#9fc8f0"
          ></path>
          <path
            d="M795.9,181.6c3-5.6,8.6-9.6,15.1-10.4l.5-27.8c-18.6.5-34.6,12-41.8,28.4l26.2,9.8Z"
            fill="url(#e16XPYsai9b29-fill)"
          ></path>
          <path
            d="M832.4,194.2c-1.6,9.2-9.5,16.3-19.1,16.4-2.8,0-5.5-.6-8-1.6l-14.6,24c6.7,3.6,14.4,5.5,22.6,5.4c24.2-.4,43.8-19.4,46-43.1l-26.9-1.1Z"
            fill="#0a7cb7"
          ></path>
          <path
            d="M786.2,230.3l14.6-24c-4.4-3.6-7.2-9.1-7.2-15.3c0-1.5.2-3,.5-4.4l-26.3-9.8c-1.4,4.5-2.1,9.3-2.1,14.2-.1,16.4,8.1,30.8,20.5,39.3Z"
            fill="url(#e16XPYsai9b31-fill)"
          ></path>
          <path
            d="M816.2,171.3c8.8,1.4,15.6,8.7,16.4,17.7l44.5,1.8c0,0,0,0,0-.1c0-34.5-26.3-63.1-60.1-65.6l-.8,46.2Z"
            fill="url(#e16XPYsai9b32-fill)"
          ></path>
          <path
            d="M828,282.6c-2.6-31.2,16.6-44.1,9.3-72.4-9-34.9-43.8-36.4-55.6-70.7-4.3-12.6-6.2-31.4,5.1-58.4l-47.4-1.6c-1.4,0-2.6,1-2.6,2.4v272.6c0,1.4,1.1,2.5,2.6,2.4l129.9-7.2c-30.8-25.1-39.8-48.7-41.3-67.1Z"
            opacity="0.35"
            fill="#fff"
          ></path>
        </g>
        <g id="e16XPYsai9b34" transform="translate(-697.418091 117.028778)">
          <path
            d="M777.7,155l-72.7-.7c-1.6,0-3-1.3-3-2.9v-27.7c0-1.6,1.3-2.9,3-2.8l63.3,1.5c1.6,0,2.8,1.3,2.8,2.9v23.7l6.6,6Z"
            fill="url(#e16XPYsai9b35-fill)"
          ></path>
          <path
            d="M760.9,132.4l-48.2-.9v-3.3l48.2,1v3.2Z"
            opacity="0.67"
            fill="#fff"
          ></path>
          <path
            d="M760.9,138.9l-48.2-.8v-3.3l48.2.9v3.2Z"
            opacity="0.67"
            fill="#fff"
          ></path>
          <path
            d="M768,146l-55.2-.8v-3.8l55.2.9v3.7Z"
            opacity="0.67"
            fill="#fff"
          ></path>
        </g>
        <g id="e16XPYsai9b39" transform="translate(-697.418091 116.124581)">
          <rect
            width="17"
            height="106.7"
            rx="0"
            ry="0"
            transform="translate(993.7 127.3)"
            fill="none"
          ></rect>
          <path
            d="M1114.4,4.9L969,0c-3.3-.1-6,2.5-6,5.8v264.1c0,3.3,2.7,5.8,6,5.6l145.4-8.3c3-.2,5.4-2.8,5.4-6v-250.4c.1-3.2-2.4-5.8-5.4-5.9Z"
            fill="url(#e16XPYsai9b41-fill)"
          ></path>
          <g opacity="0.3">
            <path
              d="M1054.8,199.9c-2.5-30.2,16.1-42.7,9-70-8.7-33.8-42.4-35.3-53.8-68.4-4.4-12.7-6.1-31.9,6.5-59.9L969,0c-3.3-.1-6,2.5-6,5.8v264.1c0,3.3,2.7,5.8,6,5.6l129.7-7.4c-33-25.5-42.4-49.6-43.9-68.2Z"
              opacity="0.3"
              fill="#d8dee8"
            ></path>
          </g>
          <path
            d="M1114.4,8.3L969,3.5c-1.4,0-2.5,1-2.5,2.4v263.8c0,1.3,1.1,2.4,2.5,2.3l145.4-8.1c1.2-.1,2.2-1.2,2.2-2.4v-250.8c.1-1.3-.9-2.4-2.2-2.4Z"
            fill="url(#e16XPYsai9b44-fill)"
          ></path>
          <path
            d="M1087,208l-88.2,3.2c-2.1.1-3.8,1.8-3.8,3.9s1.7,3.7,3.8,3.6l88.2-3.4c2-.1,3.6-1.8,3.6-3.8-.1-2-1.7-3.6-3.6-3.5Z"
            fill="#d8dee8"
          ></path>
          <path
            d="M1087,221l-88.2,3.6c-2.1.1-3.8,1.8-3.8,3.9s1.7,3.7,3.8,3.6l88.2-3.8c2-.1,3.6-1.8,3.6-3.8-.1-2-1.7-3.6-3.6-3.5Z"
            fill="#d8dee8"
          ></path>
          <path
            d="M1087,234l-88.2,4c-2.1.1-3.8,1.9-3.8,3.9c0,2.1,1.7,3.7,3.8,3.6l88.2-4.2c2-.1,3.6-1.8,3.6-3.8-.1-2.1-1.7-3.6-3.6-3.5Z"
            fill="#d8dee8"
          ></path>
          <path
            d="M1041.2,18.8l-44.4-1.3c-1,0-1.9.8-1.9,1.8s.8,1.9,1.9,1.9l44.4,1.2c1,0,1.8-.8,1.8-1.8c0-.9-.8-1.8-1.8-1.8Z"
            fill="#9fc8f0"
          ></path>
          <path
            d="M1041.2,25.4l-44.4-1.2c-1,0-1.9.8-1.9,1.8s.8,1.9,1.9,1.9l44.4,1.1c1,0,1.8-.8,1.8-1.8s-.8-1.8-1.8-1.8Z"
            fill="#9fc8f0"
          ></path>
          <path
            d="M1041.2,31.9l-44.4-1.1c-1,0-1.9.8-1.9,1.8s.8,1.9,1.9,1.9l44.4,1c1,0,1.8-.8,1.8-1.8c0-.9-.8-1.8-1.8-1.8Z"
            fill="#9fc8f0"
          ></path>
          <path
            d="M1024.1,102.2c2.9-5.4,8.3-9.3,14.6-10l.4-26.9c-18,.5-33.5,11.6-40.5,27.5l25.5,9.4Z"
            fill="url(#e16XPYsai9b51-fill)"
          ></path>
          <path
            d="M1059.1,114.3c-1.4,9-8.9,16-18.2,16.2-2.7.1-5.4-.5-7.8-1.5l-13.7,23.4c6.6,3.4,14.1,5.1,21.9,4.8c23.4-.8,42.1-19.5,43.8-42.5l-26-.4Z"
            fill="#0a7cb7"
          ></path>
          <path
            d="M1014.7,149.3l14.2-23.2c-4.3-3.5-7-8.8-7-14.9c0-1.5.2-2.9.5-4.2l-25.5-9.5c-1.3,4.3-2.1,9-2.1,13.8c0,15.8,7.9,29.7,19.9,38Z"
            fill="url(#e16XPYsai9b53-fill)"
          ></path>
          <path
            d="M1043.8,92.2c8.5,1.3,15.1,8.4,15.9,17.1l43,1.7c0,0,0,0,0-.1c0-33.3-25.5-61.1-58.2-63.5l-.7,44.8Z"
            fill="url(#e16XPYsai9b54-fill)"
          ></path>
          <path
            d="M1055.1,199.9c-2.5-30.2,16.1-42.7,9-70-8.7-33.8-42.4-35.3-53.8-68.4-4.2-12.2-6-30.3,5-56.5L969.4,3.5c-1.4,0-2.5,1-2.5,2.4v263.8c0,1.3,1.1,2.4,2.5,2.3l125.7-7c-29.8-24.4-38.5-47.3-40-65.1Z"
            opacity="0.35"
            fill="#fff"
          ></path>
        </g>
        <g transform="translate(-697.418091 0)">
          <g>
            <ellipse
              rx="88.5"
              ry="17"
              transform="translate(1101.5 420)"
              fill="#88a9c7"
              fillOpacity="0.5"
            ></ellipse>
            <path
              d="M1100.2,405.4c0,0-12.3,5.6-12.4,6.5-.2.9,7.9-.3,11.2-.2s6-.9,5.7-2.3-1.5-4.9-1.5-4.9l-3,.9Z"
              fill="url(#e16XPYsai9b59-fill)"
            ></path>
            <path
              d="M1099.8,400.9l.8,5.5c0,0,1.9.7,3.2-.1l.5-6.1-4.5.7Z"
              fill="url(#e16XPYsai9b60-fill)"
            ></path>
            <path
              d="M1157.6,400.9l1.4,2.3c.4.6.5,1.4.3,2.1-.5,1.9-1.5,5.6-1.9,6.6-.6,1.3-8.5,2.1-8.5,2.1s-.7-1,.9-2.2c1.7-1.2,2.7-1.4,3.3-3.2s1-6.8,1-6.8l3.5-.9Z"
              fill="url(#e16XPYsai9b61-fill)"
            ></path>
            <path
              d="M1151.6,395c.5.4,3.1,8.2,3.1,8.2s.8,1.1,2.4-.5.7-.7.7-.7l-.3-7.6-5.9.6Z"
              fill="url(#e16XPYsai9b62-fill)"
            ></path>
            <path
              d="M1092.8,289.9c-.6,16.5-.6,34.4.4,53.5c1.1,21.5,3.3,41.4,6.1,59.5c1,0,2.1,0,3.2,0c.8,0,1.5-.1,2.2-.1c1.4-3.7,3-8.7,4.5-14.6c1.5-6.2,2.5-11.7,3.1-17c2.3-18.7-.5-20.3,1.4-34.1c1.7-12.3,4.1-12.7,6.9-30.1c3-18.9.7-21.7-.4-22.7-4.8-4.5-16-1.5-27.4,5.6Z"
              fill="url(#e16XPYsai9b63-fill)"
            ></path>
            <path
              d="M1120.1,284.2c-4.5-4.1-14.9-1.6-25.8,4.8c12.6,13.5,14,22.4,12.5,28.1-1.7,6.5-6.9,8.3-6.8,15.4c0,8.7,7.9,11,7.7,20.1-.1,6.1-3.5,6-4.3,13.5-.9,8.9,3.6,11.9,2.1,18.5-.6,2.8-2.4,6.5-7.5,10.3.4,2.7.8,5.4,1.1,8c1,0,2.1,0,3.2,0c.7,0,1.5-.1,2.2-.1c1.4-3.7,3-8.7,4.5-14.6c1.5-6.2,2.5-11.7,3.1-17c2.3-18.7-.5-20.3,1.4-34.1c1.7-12.3,4.1-12.7,6.9-30.1c3.1-19,.8-21.8-.3-22.8Z"
              fill="#046290"
            ></path>
            <path
              d="M1100.5,289.6c10.9,15.8,22.6,35.5,33.1,59c7.9,17.8,13.7,34.5,17.9,49.4.6.3,1.9.8,3.6.6c2.3-.3,3.7-1.8,4.1-2.2c1.7-10.7,1.5-19.7.9-26.3-.4-4.2-1.1-11.1-3.7-19.7-3-9.6-5.1-10.2-9-20.3-4.1-10.6-3.1-13.2-7-26.4-1.6-5.4-4.3-13.5-8.7-23.2-10.4,2.9-20.8,6-31.2,9.1Z"
              fill="url(#e16XPYsai9b65-fill)"
            ></path>
            <path
              d="M1056.4,267.3c.1,3.8,6.4,7.2,11.5,8.3c11.9,2.4,25.7-6.2,32.2-15.8c1.3-1.9,2.7-4.2,3.1-4c.8.4-6.4,12.2-9.5,25.5-1.6,6.8-1.2,10-4.1,14.6-.7,1-1.5,2.1-1.2,3.3.7,3.1,7.7,3.8,12.6,4.4c5.1.6,9.9.5,14.6.5c7.6-.1,8.2-.5,9.9.3c5.2,2.6,4.9,8.8,8.8,9.6c3.3.7,6.7-3,7.8-4.2c5.6-6.1,5.3-13.5,6.2-20.9c2.3-17.3,11.7-36.2,20.9-48.2c2.8-3.6,4.7-5.5,4.8-8.9.4-9.9-15.3-20.9-28.4-22.1-3.6-.3-10-.2-16,.3-2.4.2-5,.6-7.7,1-6.7,1.2-11.3,2-16.1,4.1-19.1,8.7-14.9,28.2-35.6,41.6-7.1,4.8-13.9,6.5-13.8,10.6Z"
              fill="url(#e16XPYsai9b66-fill)"
            ></path>
            <path
              d="M1134.7,199.4c0,0,.8,4.6,2.7,7.8c1.7,2.9,8.8,4,10.2,4.5c1.5.5-2.2,3.1-6.1,4s-15.4,1.5-17.6.3s1.5-1.6,4.2-3s3.6-7.2,2.8-9.6c-1-2.5,3.8-4,3.8-4Z"
              fill="url(#e16XPYsai9b67-fill)"
            ></path>
            <path
              d="M1127.2,198.1c-2.8-2-3.6-7.5-2-8.8.4-.4.9-.2,7.3,2.4c3.2,1.3,4.8,2,5.3,3.4.3.9.3,2-.1,2.9-.2.5-.5,1.1-1.3,2.2-.7,1-.7,1-.9,1.2-.5.7-1.5,1.9-3.3,3.2-.1-.5-.3-1.1-.5-1.8-.1-.3-.5-1.3-1.2-2.2-1.1-1.4-2.4-1.9-3.3-2.5Z"
              fill="url(#e16XPYsai9b68-fill)"
            ></path>
            <path
              d="M1134.5,203.3c0,0-1.9,2.8-5.3,2.4-3.1-.3-3.2-7.3-1.2-8.7.7-.5,2-.6,3.1-.3.6.2.8.5,1.9.8.9.3,1.2.2,1.5.5s.6,1.1-.1,3.1c.6-.6,1.3-.8,1.8-.5.1,0,.3.2.4.4.3.8-.4,1.8-2.1,2.3Z"
              fill="url(#e16XPYsai9b69-fill)"
            ></path>
            <path
              d="M1073.1,266.7c8.6-1.9,12.1-1.9,16.3-5.3c7.1-5.7,8.2-14.4,12.3-14.3c1.9.1,3.5,2,4.7,4-1,1.6-2.1,3.3-3.1,4.9c0-.1,0-.1-.1-.1-.3-.2-1.8,2.1-3.1,4-6.6,9.6-20.4,18.2-32.2,15.8-3.4-.7-7.4-2.5-9.7-4.7c6-2.1,11-3.4,14.9-4.3Z"
              opacity="0.3"
              fill="url(#e16XPYsai9b70-fill)"
            ></path>
            <path
              d="M1059.1,274.1c0,0-4.9-.5-7.8-1.7s-4.8.5-4.5,1.8c.3,1.2,5,3,6.9,3s5.4-3.1,5.4-3.1Z"
              fill="url(#e16XPYsai9b71-fill)"
            ></path>
            <path
              d="M1163.9,299.9c5.2.5,17,.7,25.3-7c13.9-12.8,7-37.2,2.2-47.7-5.5-12-14.3-18.8-19.7-22.9-6.6-5.1-10.7-8.2-15.6-7.5-12.6,1.8-21.3,27.6-14.4,35c2.3,2.4,4.5,1,11.1,3.1c2.1.7,18.1,5.8,20.8,17.5c1.9,8.2-3,17.8-11.8,24.3.8,1.7,1.4,3.4,2.1,5.2Z"
              fill="url(#e16XPYsai9b72-fill)"
            ></path>
            <path
              d="M1105.3,295.6c5.9-3.7,6.8-7.5,13.4-10.1c8.6-3.3,12.8.9,17-2.5c5.1-4.1.6-11.1,4.4-23.4.6-2.1,5.5-17.9,16.8-19.9c5.2-.9,12.2,1.1,14.8,5.5c2.1,3.5.1,6.1,2.7,9.9c2.5,3.8,5.6,2.9,8.8,6.1c5.1,5.1,5.9,15.8,1.3,21.9-2.2,2.8-5,3.9-10.3,6-3.2,1.3-6.2,2.1-8.8,2.6c6.5-6.2,9.8-14.2,8.2-21.2-1.7-7.3-8.6-12-14-14.7-5.4,10.2-9.9,22.1-11.4,33.2-1,7.4-.7,14.8-6.2,20.9-1.1,1.2-4.4,4.9-7.8,4.2-3.9-.8-3.6-7-8.8-9.6-1.7-.8-2.3-.5-9.9-.3-4.7.1-9.5.1-14.6-.5-4.3-.5-10.3-1.2-12.1-3.5c7.9-.2,13.1-2.4,16.5-4.6Z"
              fill="#046290"
            ></path>
            <path
              d="M1160.5,304l.6-1.8c1-2.9-.6-5.9-3.6-6.8l-115.2-35.1-3.5,11.9l114.6,35.3c3,.9,6.1-.7,7.1-3.5Z"
              fill="url(#e16XPYsai9b74-fill)"
            ></path>
            <path
              d="M927.8,228.1c8.8-34.8,43.3-55.8,77.5-45.9c34.8,10,54.7,47.7,44.1,83.2-10.3,34.7-45.7,53.4-78.7,42.7-32.4-10.6-51.5-46-42.9-80Zm111.6,34.1c8.7-29.7-7.9-61.2-37-69.6-28.6-8.3-57.6,9.1-65.1,38.4-7.4,28.7,8.7,58.5,36.1,67.3c27.8,8.9,57.4-7,66-36.1Z"
              fill="url(#e16XPYsai9b75-fill)"
            ></path>
            <path
              d="M1042.3,260.3l14.7,4.5c1,4.7,3,7.4,4.9,9c7.4,6.1,17.3-.6,31.2,6.7c6.9,3.6,5.6,5.9,12.1,8.4c10.8,4.2,15.7-1.6,25.2,2.2c9.6,3.8,10.6,12.1,16,11.8c2.3-.1,5.3-1.8,8.7-8.3l2.4.7c2.9.9,4.5,3.9,3.6,6.8l-.6,1.8c-1,2.8-4.1,4.4-7.1,3.5l-114.6-35.3l3.5-11.8Z"
              opacity="0.3"
              fill="#d8dee8"
            ></path>
            <path
              d="M970.7,303c28.8,9.2,59.4-7.1,68.3-37.3c9.1-30.8-8.2-63.4-38.3-72.1-29.6-8.6-59.7,9.5-67.4,39.7-7.6,29.7,9,60.6,37.4,69.7Z"
              opacity="0.56"
              fill="#fff"
            ></path>
            <path
              d="M925.6,231c8.8-34.8,43.3-55.8,77.5-45.9c34.8,10,54.7,47.7,44.1,83.2-10.3,34.7-45.7,53.4-78.7,42.7-32.5-10.6-51.5-46-42.9-80Zm111.6,34.2c8.7-29.7-7.9-61.2-37-69.6-28.6-8.3-57.6,9.1-65.1,38.4-7.4,28.7,8.7,58.5,36.1,67.3c27.7,8.9,57.4-7,66-36.1Z"
              fill="url(#e16XPYsai9b78-fill)"
            ></path>
            <path
              d="M928.9,232.1c8.3-32.8,40.9-52.5,73.1-43.2c32.7,9.5,51.5,44.9,41.6,78.4-9.7,32.7-43,50.4-74.2,40.3-30.6-10-48.6-43.4-40.5-75.5Zm108.3,33.1c8.7-29.7-7.9-61.2-37-69.6-28.6-8.3-57.6,9.1-65.1,38.4-7.4,28.7,8.7,58.5,36.1,67.3c27.7,8.9,57.4-7,66-36.1Z"
              fill="url(#e16XPYsai9b79-fill)"
            ></path>
            <path
              d="M1163.5,298.8l-1.2-2.9c0,0-1.9.1-3.5-1-.8-.6-2.4-.8-2.8-.7s-2.1.1-.2,1s1.9,1.6,1.1,2.3-3.8,2.3-4.9,2.4.9,1.7,2,2c1.1.2,2.9.5,5.1-.8.8-.6,4.4-2.3,4.4-2.3Z"
              fill="url(#e16XPYsai9b80-fill)"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  );
};
