export const dashboard = "/dashboard";
export const signup = "/signup";
export const homepage = "/";
export const appointment = "/appointment";
export const f_password = "/forgot-password";
export const availDocs = "/available-doctors";
export const profile = "/profile";
export const custSoftDev = "/custom-software-development";
export const mobileAppDev = "/mobile-app-development";
export const webAppServ = "/web-application-services";
export const uxOtherServ = "/other-services";
export const uiDesign = "/ui-design";
export const uxDesign = "/ux-design";
export const uxRevAnaly = "/ux-review-and-analysis";
