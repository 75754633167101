interface UxSVGProps {
  classes: any;
}

export const UserTestingSVG = ({ classes }: UxSVGProps) => {
  return (
    <div className={`${classes}`}>
      <svg
        id="ewFJuUgkZ6s1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1160 450"
        shape-rendering="geometricPrecision"
        text-rendering="geometricPrecision"
      >
        <defs>
          <linearGradient
            id="ewFJuUgkZ6s5-fill"
            x1="1001.9836"
            y1="27.565515"
            x2="1086.816"
            y2="42.989115"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s5-fill-0"
              offset="0%"
              stop-color="#b37cff"
            ></stop>
            <stop
              id="ewFJuUgkZ6s5-fill-1"
              offset="100%"
              stop-color="#f895e7"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s6-fill"
            x1="1095.001"
            y1="21.388915"
            x2="1099.9586"
            y2="22.289415"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s6-fill-0"
              offset="0%"
              stop-color="#b37cff"
            ></stop>
            <stop
              id="ewFJuUgkZ6s6-fill-1"
              offset="100%"
              stop-color="#f895e7"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s8-fill"
            x1="1113.8398"
            y1="63.930815"
            x2="1117.2386"
            y2="64.548815"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s8-fill-0"
              offset="0%"
              stop-color="#b37cff"
            ></stop>
            <stop
              id="ewFJuUgkZ6s8-fill-1"
              offset="100%"
              stop-color="#f895e7"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s9-fill"
            x1="1023.5545"
            y1="87.463215"
            x2="1110.936"
            y2="103.351015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s9-fill-0"
              offset="0%"
              stop-color="#b37cff"
            ></stop>
            <stop
              id="ewFJuUgkZ6s9-fill-1"
              offset="100%"
              stop-color="#f895e7"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s10-fill"
            x1="1011.4915"
            y1="104.303315"
            x2="1016.3862"
            y2="105.194115"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s10-fill-0"
              offset="0%"
              stop-color="#b37cff"
            ></stop>
            <stop
              id="ewFJuUgkZ6s10-fill-1"
              offset="100%"
              stop-color="#f895e7"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s16-fill"
            x1="936.3386"
            y1="98.023615"
            x2="939.0789"
            y2="97.194215"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s16-fill-0"
              offset="0%"
              stop-color="#b37cff"
            ></stop>
            <stop
              id="ewFJuUgkZ6s16-fill-1"
              offset="100%"
              stop-color="#f895e7"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s17-fill"
            x1="923.8824"
            y1="73.031515"
            x2="980.7427"
            y2="43.873015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s17-fill-0"
              offset="0%"
              stop-color="#b37cff"
            ></stop>
            <stop
              id="ewFJuUgkZ6s17-fill-1"
              offset="100%"
              stop-color="#f895e7"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s19-fill"
            x1="967.8511"
            y1="119.490615"
            x2="1027.1506"
            y2="89.082215"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s19-fill-0"
              offset="0%"
              stop-color="#b37cff"
            ></stop>
            <stop
              id="ewFJuUgkZ6s19-fill-1"
              offset="100%"
              stop-color="#f895e7"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s20-fill"
            x1="959.8741"
            y1="117.669315"
            x2="964.0651"
            y2="116.400715"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s20-fill-0"
              offset="0%"
              stop-color="#b37cff"
            ></stop>
            <stop
              id="ewFJuUgkZ6s20-fill-1"
              offset="100%"
              stop-color="#f895e7"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s25-fill"
            x1="942.5997"
            y1="414.780015"
            x2="937.9297"
            y2="322.315015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s25-fill-0"
              offset="58.57%"
              stop-color="#2d4868"
            ></stop>
            <stop
              id="ewFJuUgkZ6s25-fill-1"
              offset="94.63%"
              stop-color="#192c39"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s26-fill"
            x1="700"
            y1="200.025715"
            x2="1159.0688"
            y2="200.025715"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s26-fill-0"
              offset="0%"
              stop-color="#2d4868"
            ></stop>
            <stop
              id="ewFJuUgkZ6s26-fill-1"
              offset="100%"
              stop-color="#344c5e"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s28-fill"
            x1="939.5861"
            y1="387.859515"
            x2="911.951"
            y2="448.537515"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s28-fill-0"
              offset="0%"
              stop-color="#192c39"
            ></stop>
            <stop
              id="ewFJuUgkZ6s28-fill-1"
              offset="0%"
              stop-color="#1a2d3b"
            ></stop>
            <stop
              id="ewFJuUgkZ6s28-fill-2"
              offset="19.89%"
              stop-color="#243c54"
            ></stop>
            <stop
              id="ewFJuUgkZ6s28-fill-3"
              offset="36.94%"
              stop-color="#2b4563"
            ></stop>
            <stop
              id="ewFJuUgkZ6s28-fill-4"
              offset="50.83%"
              stop-color="#2d4868"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s29-fill"
            x1="877.1145"
            y1="861.349015"
            x2="956.2314"
            y2="-321.972985"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s29-fill-0"
              offset="25.87%"
              stop-color="#27628e"
            ></stop>
            <stop
              id="ewFJuUgkZ6s29-fill-1"
              offset="50.58%"
              stop-color="#2f6894"
            ></stop>
            <stop
              id="ewFJuUgkZ6s29-fill-2"
              offset="89.12%"
              stop-color="#4579a5"
            ></stop>
            <stop
              id="ewFJuUgkZ6s29-fill-3"
              offset="93.13%"
              stop-color="#487ba7"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s34-fill"
            x1="744.0027"
            y1="75.550415"
            x2="820.5297"
            y2="-113.615685"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s34-fill-0"
              offset="0%"
              stop-color="#53d8ff"
            ></stop>
            <stop
              id="ewFJuUgkZ6s34-fill-1"
              offset="100%"
              stop-color="#3840f7"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s35-fill"
            x1="757.9871"
            y1="81.207515"
            x2="834.5142"
            y2="-107.959385"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s35-fill-0"
              offset="0%"
              stop-color="#53d8ff"
            ></stop>
            <stop
              id="ewFJuUgkZ6s35-fill-1"
              offset="100%"
              stop-color="#3840f7"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s36-fill"
            x1="771.644"
            y1="86.732415"
            x2="848.1711"
            y2="-102.433985"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s36-fill-0"
              offset="0%"
              stop-color="#53d8ff"
            ></stop>
            <stop
              id="ewFJuUgkZ6s36-fill-1"
              offset="100%"
              stop-color="#3840f7"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s41-fill"
            x1="1035.1517"
            y1="-216.168785"
            x2="977.1066"
            y2="323.632215"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s41-fill-0"
              offset="25.87%"
              stop-color="#27628e"
            ></stop>
            <stop
              id="ewFJuUgkZ6s41-fill-1"
              offset="50.58%"
              stop-color="#2f6894"
            ></stop>
            <stop
              id="ewFJuUgkZ6s41-fill-2"
              offset="89.12%"
              stop-color="#4579a5"
            ></stop>
            <stop
              id="ewFJuUgkZ6s41-fill-3"
              offset="93.13%"
              stop-color="#487ba7"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s44-fill"
            x1="922.8325"
            y1="234.036315"
            x2="888.0795"
            y2="289.735315"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s44-fill-0"
              offset="0%"
              stop-color="#d1aa6d"
            ></stop>
            <stop
              id="ewFJuUgkZ6s44-fill-1"
              offset="0%"
              stop-color="#ba945f"
            ></stop>
            <stop
              id="ewFJuUgkZ6s44-fill-2"
              offset="22.24%"
              stop-color="#9f7a4e"
            ></stop>
            <stop
              id="ewFJuUgkZ6s44-fill-3"
              offset="36.68%"
              stop-color="#8a6541"
            ></stop>
            <stop
              id="ewFJuUgkZ6s44-fill-4"
              offset="52.71%"
              stop-color="#7b5737"
            ></stop>
            <stop
              id="ewFJuUgkZ6s44-fill-5"
              offset="71.52%"
              stop-color="#724f32"
            ></stop>
            <stop
              id="ewFJuUgkZ6s44-fill-6"
              offset="100%"
              stop-color="#6f4c30"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s46-fill"
            x1="878.5812"
            y1="274.303415"
            x2="854.4812"
            y2="346.604415"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s46-fill-0"
              offset="11.37%"
              stop-color="#f6c546"
            ></stop>
            <stop
              id="ewFJuUgkZ6s46-fill-1"
              offset="100%"
              stop-color="#e57a58"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s47-fill"
            x1="880.0284"
            y1="272.562315"
            x2="873.5125"
            y2="258.091215"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s47-fill-0"
              offset="23.21%"
              stop-color="#d1aa6d"
            ></stop>
            <stop
              id="ewFJuUgkZ6s47-fill-1"
              offset="53.83%"
              stop-color="#a88354"
            ></stop>
            <stop
              id="ewFJuUgkZ6s47-fill-2"
              offset="100%"
              stop-color="#6f4c30"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s48-fill"
            x1="880.3646"
            y1="237.396815"
            x2="867.1406"
            y2="266.025815"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s48-fill-0"
              offset="0%"
              stop-color="#6f4c30"
            ></stop>
            <stop
              id="ewFJuUgkZ6s48-fill-1"
              offset="31.88%"
              stop-color="#9e794d"
            ></stop>
            <stop
              id="ewFJuUgkZ6s48-fill-2"
              offset="61.27%"
              stop-color="#c39d64"
            ></stop>
            <stop
              id="ewFJuUgkZ6s48-fill-3"
              offset="76.79%"
              stop-color="#d1aa6d"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s50-fill"
            x1="874.1432"
            y1="234.523115"
            x2="860.9202"
            y2="263.151115"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s50-fill-0"
              offset="0%"
              stop-color="#6f4c30"
            ></stop>
            <stop
              id="ewFJuUgkZ6s50-fill-1"
              offset="31.88%"
              stop-color="#9e794d"
            ></stop>
            <stop
              id="ewFJuUgkZ6s50-fill-2"
              offset="61.27%"
              stop-color="#c39d64"
            ></stop>
            <stop
              id="ewFJuUgkZ6s50-fill-3"
              offset="76.79%"
              stop-color="#d1aa6d"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s51-fill"
            x1="843.4637"
            y1="452.766715"
            x2="892.0087"
            y2="365.143715"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s51-fill-0"
              offset="0%"
              stop-color="#d1aa6d"
            ></stop>
            <stop
              id="ewFJuUgkZ6s51-fill-1"
              offset="0%"
              stop-color="#ba945f"
            ></stop>
            <stop
              id="ewFJuUgkZ6s51-fill-2"
              offset="22.24%"
              stop-color="#9f7a4e"
            ></stop>
            <stop
              id="ewFJuUgkZ6s51-fill-3"
              offset="36.68%"
              stop-color="#8a6541"
            ></stop>
            <stop
              id="ewFJuUgkZ6s51-fill-4"
              offset="52.71%"
              stop-color="#7b5737"
            ></stop>
            <stop
              id="ewFJuUgkZ6s51-fill-5"
              offset="71.52%"
              stop-color="#724f32"
            ></stop>
            <stop
              id="ewFJuUgkZ6s51-fill-6"
              offset="100%"
              stop-color="#6f4c30"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s52-fill"
            x1="853.9363"
            y1="421.851715"
            x2="868.3688"
            y2="421.713715"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s52-fill-0"
              offset="0%"
              stop-color="#2b3582"
            ></stop>
            <stop
              id="ewFJuUgkZ6s52-fill-1"
              offset="100%"
              stop-color="#150e42"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s53-fill"
            x1="898.8047"
            y1="387.760315"
            x2="825.1898"
            y2="412.073315"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s53-fill-0"
              offset="0%"
              stop-color="#6f4c30"
            ></stop>
            <stop
              id="ewFJuUgkZ6s53-fill-1"
              offset="33.22%"
              stop-color="#916c45"
            ></stop>
            <stop
              id="ewFJuUgkZ6s53-fill-2"
              offset="75.23%"
              stop-color="#d1aa6d"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s54-fill"
            x1="867.3412"
            y1="424.335215"
            x2="884.5453"
            y2="424.171215"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s54-fill-0"
              offset="0%"
              stop-color="#2b3582"
            ></stop>
            <stop
              id="ewFJuUgkZ6s54-fill-1"
              offset="100%"
              stop-color="#150e42"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s55-fill"
            x1="886.9583"
            y1="372.222915"
            x2="865.6163"
            y2="322.309915"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s55-fill-0"
              offset="17.12%"
              stop-color="#5b5bc1"
            ></stop>
            <stop
              id="ewFJuUgkZ6s55-fill-1"
              offset="91.53%"
              stop-color="#8f8ce4"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s56-fill"
            x1="848.1282"
            y1="317.577215"
            x2="917.7103"
            y2="214.409215"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s56-fill-0"
              offset="0%"
              stop-color="#6f4c30"
            ></stop>
            <stop
              id="ewFJuUgkZ6s56-fill-1"
              offset="10.31%"
              stop-color="#745133"
            ></stop>
            <stop
              id="ewFJuUgkZ6s56-fill-2"
              offset="30.14%"
              stop-color="#9c774c"
            ></stop>
            <stop
              id="ewFJuUgkZ6s56-fill-3"
              offset="48.25%"
              stop-color="#b9935e"
            ></stop>
            <stop
              id="ewFJuUgkZ6s56-fill-4"
              offset="63.88%"
              stop-color="#cba469"
            ></stop>
            <stop
              id="ewFJuUgkZ6s56-fill-5"
              offset="75.23%"
              stop-color="#d1aa6d"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s58-fill"
            x1="1034.0327"
            y1="408.282515"
            x2="1105.5627"
            y2="484.613515"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s58-fill-0"
              offset="0%"
              stop-color="#2b3582"
            ></stop>
            <stop
              id="ewFJuUgkZ6s58-fill-1"
              offset="100%"
              stop-color="#150e42"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s59-fill"
            x1="1057.0699"
            y1="413.787015"
            x2="1050.7845"
            y2="413.846915"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s59-fill-0"
              offset="0%"
              stop-color="#f9b776"
            ></stop>
            <stop
              id="ewFJuUgkZ6s59-fill-1"
              offset="100%"
              stop-color="#f47960"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s60-fill"
            x1="1027.6276"
            y1="321.252815"
            x2="1088.6476"
            y2="402.330815"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s60-fill-0"
              offset="10.04%"
              stop-color="#47889a"
            ></stop>
            <stop
              id="ewFJuUgkZ6s60-fill-1"
              offset="70.32%"
              stop-color="#8f8ce4"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s61-fill"
            x1="1049.7809"
            y1="393.531215"
            x2="1121.3009"
            y2="469.865115"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s61-fill-0"
              offset="0%"
              stop-color="#2b3582"
            ></stop>
            <stop
              id="ewFJuUgkZ6s61-fill-1"
              offset="100%"
              stop-color="#150e42"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s62-fill"
            x1="1079.7271"
            y1="413.243815"
            x2="1074.3026"
            y2="413.295815"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s62-fill-0"
              offset="0%"
              stop-color="#f9b776"
            ></stop>
            <stop
              id="ewFJuUgkZ6s62-fill-1"
              offset="100%"
              stop-color="#f47960"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s63-fill"
            x1="1038.2885"
            y1="313.231215"
            x2="1099.3085"
            y2="394.308215"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s63-fill-0"
              offset="10.04%"
              stop-color="#47889a"
            ></stop>
            <stop
              id="ewFJuUgkZ6s63-fill-1"
              offset="70.32%"
              stop-color="#8f8ce4"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s64-fill"
            x1="1060.8958"
            y1="279.891015"
            x2="1098.0957"
            y2="332.754015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s64-fill-0"
              offset="0%"
              stop-color="#f6c551"
            ></stop>
            <stop
              id="ewFJuUgkZ6s64-fill-1"
              offset="77.15%"
              stop-color="#e57a58"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s65-fill"
            x1="1029.6207"
            y1="249.976915"
            x2="997.4972"
            y2="250.281915"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s65-fill-0"
              offset="0%"
              stop-color="#f9b776"
            ></stop>
            <stop
              id="ewFJuUgkZ6s65-fill-1"
              offset="100%"
              stop-color="#f47960"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s66-fill"
            x1="1004.6031"
            y1="244.830615"
            x2="1026.6332"
            y2="293.674615"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s66-fill-0"
              offset="0%"
              stop-color="#f9b776"
            ></stop>
            <stop
              id="ewFJuUgkZ6s66-fill-1"
              offset="100%"
              stop-color="#f47960"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s67-fill"
            x1="997.4448"
            y1="228.561215"
            x2="988.3177"
            y2="214.816115"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s67-fill-0"
              offset="0%"
              stop-color="#f9b776"
            ></stop>
            <stop
              id="ewFJuUgkZ6s67-fill-1"
              offset="100%"
              stop-color="#f47960"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s68-fill"
            x1="1079.3104"
            y1="266.929715"
            x2="1137.2404"
            y2="349.246815"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s68-fill-0"
              offset="0%"
              stop-color="#f6c551"
            ></stop>
            <stop
              id="ewFJuUgkZ6s68-fill-1"
              offset="39.88%"
              stop-color="#e57a58"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s69-fill"
            x1="1100.3586"
            y1="294.808315"
            x2="1059.6982"
            y2="295.195315"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s69-fill-0"
              offset="0%"
              stop-color="#f9b776"
            ></stop>
            <stop
              id="ewFJuUgkZ6s69-fill-1"
              offset="100%"
              stop-color="#f47960"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s70-fill"
            x1="1068.7279"
            y1="251.649715"
            x2="1052.6525"
            y2="251.802615"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s70-fill-0"
              offset="0%"
              stop-color="#f9b776"
            ></stop>
            <stop
              id="ewFJuUgkZ6s70-fill-1"
              offset="100%"
              stop-color="#f47960"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s71-fill"
            x1="1048.7302"
            y1="241.610415"
            x2="1066.3003"
            y2="230.663415"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s71-fill-0"
              offset="0%"
              stop-color="#f9b776"
            ></stop>
            <stop
              id="ewFJuUgkZ6s71-fill-1"
              offset="100%"
              stop-color="#f47960"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s73-fill"
            x1="1053.4095"
            y1="245.579215"
            x2="1069.2195"
            y2="235.730215"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s73-fill-0"
              offset="0%"
              stop-color="#f9b776"
            ></stop>
            <stop
              id="ewFJuUgkZ6s73-fill-1"
              offset="100%"
              stop-color="#f47960"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s74-fill"
            x1="1048.801"
            y1="268.982215"
            x2="1026.371"
            y2="362.457215"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s74-fill-0"
              offset="0%"
              stop-color="#2b3582"
            ></stop>
            <stop
              id="ewFJuUgkZ6s74-fill-1"
              offset="100%"
              stop-color="#150e42"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s75-fill"
            x1="1064.1748"
            y1="293.710915"
            x2="1051.46"
            y2="293.710915"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s75-fill-0"
              offset="0%"
              stop-color="#f9b776"
            ></stop>
            <stop
              id="ewFJuUgkZ6s75-fill-1"
              offset="100%"
              stop-color="#f47960"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s76-fill"
            x1="1039.7596"
            y1="278.950115"
            x2="1043.1096"
            y2="292.920115"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s76-fill-0"
              offset="0%"
              stop-color="#53d8ff"
            ></stop>
            <stop
              id="ewFJuUgkZ6s76-fill-1"
              offset="100%"
              stop-color="#3840f7"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s79-fill"
            x1="836.6965"
            y1="286.777715"
            x2="823.2615"
            y2="306.930715"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s79-fill-0"
              offset="0%"
              stop-color="#f9b776"
            ></stop>
            <stop
              id="ewFJuUgkZ6s79-fill-1"
              offset="100%"
              stop-color="#f47960"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s80-fill"
            x1="814.829"
            y1="426.138315"
            x2="837.895"
            y2="426.138315"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s80-fill-0"
              offset="0%"
              stop-color="#53d8ff"
            ></stop>
            <stop
              id="ewFJuUgkZ6s80-fill-1"
              offset="100%"
              stop-color="#3840f7"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s81-fill"
            x1="837.8176"
            y1="387.549215"
            x2="832.5666"
            y2="403.855215"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s81-fill-0"
              offset="0%"
              stop-color="#cb236d"
            ></stop>
            <stop
              id="ewFJuUgkZ6s81-fill-1"
              offset="100%"
              stop-color="#4c246d"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s82-fill"
            x1="816.958"
            y1="415.310815"
            x2="823.457"
            y2="415.310815"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s82-fill-0"
              offset="0%"
              stop-color="#f9b776"
            ></stop>
            <stop
              id="ewFJuUgkZ6s82-fill-1"
              offset="100%"
              stop-color="#f47960"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s83-fill"
            x1="773.6602"
            y1="430.030315"
            x2="782.5"
            y2="430.030315"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s83-fill-0"
              offset="0%"
              stop-color="#53d8ff"
            ></stop>
            <stop
              id="ewFJuUgkZ6s83-fill-1"
              offset="100%"
              stop-color="#3840f7"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s84-fill"
            x1="796.0461"
            y1="374.106015"
            x2="790.7951"
            y2="390.412015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s84-fill-0"
              offset="0%"
              stop-color="#cb236d"
            ></stop>
            <stop
              id="ewFJuUgkZ6s84-fill-1"
              offset="100%"
              stop-color="#4c246d"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s85-fill"
            x1="780.4686"
            y1="411.922015"
            x2="788.235"
            y2="411.922015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s85-fill-0"
              offset="0%"
              stop-color="#f9b776"
            ></stop>
            <stop
              id="ewFJuUgkZ6s85-fill-1"
              offset="100%"
              stop-color="#f47960"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s86-fill"
            x1="799.1846"
            y1="423.465215"
            x2="825.3536"
            y2="327.051215"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s86-fill-0"
              offset="21.44%"
              stop-color="#2d4868"
            ></stop>
            <stop
              id="ewFJuUgkZ6s86-fill-1"
              offset="94.63%"
              stop-color="#192c39"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s87-fill"
            x1="838.2821"
            y1="272.562915"
            x2="782.4911"
            y2="338.684915"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s87-fill-0"
              offset="10.04%"
              stop-color="#47889a"
            ></stop>
            <stop
              id="ewFJuUgkZ6s87-fill-1"
              offset="70.32%"
              stop-color="#8f8ce4"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s88-fill"
            x1="818.7214"
            y1="274.229115"
            x2="822.0854"
            y2="256.567115"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s88-fill-0"
              offset="0%"
              stop-color="#f9b776"
            ></stop>
            <stop
              id="ewFJuUgkZ6s88-fill-1"
              offset="100%"
              stop-color="#f47960"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s89-fill"
            x1="827.8243"
            y1="241.824415"
            x2="808.6293"
            y2="256.512415"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s89-fill-0"
              offset="0%"
              stop-color="#f9b776"
            ></stop>
            <stop
              id="ewFJuUgkZ6s89-fill-1"
              offset="100%"
              stop-color="#f47960"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s91-fill"
            x1="825.4434"
            y1="238.709515"
            x2="806.2484"
            y2="253.397515"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s91-fill-0"
              offset="0%"
              stop-color="#f9b776"
            ></stop>
            <stop
              id="ewFJuUgkZ6s91-fill-1"
              offset="100%"
              stop-color="#f47960"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ewFJuUgkZ6s92-fill"
            x1="820.687"
            y1="309.335915"
            x2="811.83"
            y2="322.619915"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ewFJuUgkZ6s92-fill-0"
              offset="0%"
              stop-color="#f9b776"
            ></stop>
            <stop
              id="ewFJuUgkZ6s92-fill-1"
              offset="100%"
              stop-color="#f47960"
            ></stop>
          </linearGradient>
        </defs>
        <ellipse
          rx="87.5"
          ry="20"
          transform="translate(824.4 430)"
          fill="#88a9c7"
          fill-opacity="0.5"
        ></ellipse>
        <ellipse
          rx="60"
          ry="13.5"
          transform="translate(1033.9 426.5)"
          fill="#88a9c7"
          fill-opacity="0.5"
        ></ellipse>
        <g opacity="0.3">
          <path
            d="M997.7,61.4c-.8-.1-1.3-.8-1.2-1.6.1-1.7.3-3.4.6-5.1.1-.8.9-1.4,1.7-1.3c0,0,0,0,0,0c.8.1,1.3.9,1.2,1.7-.3,1.6-.5,3.2-.6,4.9-.1.8-.8,1.5-1.6,1.4c0,0-.1,0-.1,0Zm2.4-13c0,0-.1,0-.2,0-.8-.2-1.2-1.1-1-1.9.5-1.6,1.1-3.3,1.7-4.9.3-.8,1.2-1.1,2-.8s1.1,1.2.8,2c-.6,1.5-1.1,3.1-1.6,4.6-.2.7-.9,1.2-1.7,1Zm5.3-12c-.2,0-.3-.1-.5-.2-.7-.4-1-1.3-.6-2.1.9-1.5,1.8-2.9,2.8-4.4.5-.7,1.4-.9,2.1-.4s.8,1.4.4,2.1c-.9,1.3-1.8,2.7-2.6,4.1-.4.7-1,1-1.6.9Zm7.8-10.6c-.3-.1-.5-.2-.8-.4-.6-.6-.6-1.5-.1-2.1c1.2-1.3,2.4-2.5,3.7-3.6.6-.6,1.6-.5,2.1.1.6.6.5,1.6-.1,2.1-1.2,1.1-2.4,2.3-3.5,3.4-.3.4-.8.6-1.3.5Zm10-8.6c-.4-.1-.7-.3-1-.6-.5-.7-.3-1.6.4-2.1c1.4-1,2.9-1.9,4.4-2.7.7-.4,1.7-.2,2.1.6.4.7.2,1.6-.6,2.1-1.4.8-2.8,1.7-4.1,2.6-.5.1-.9.1-1.2.1Zm11.6-6.2c-.5-.1-.9-.4-1.1-.9-.3-.8.1-1.7.8-2c1.6-.6,3.2-1.2,4.9-1.7.8-.2,1.6.2,1.9,1c.2.8-.2,1.6-1,1.9-1.6.5-3.1,1-4.6,1.6-.4.1-.6.1-.9.1Zm55.1,6.4c-.2,0-.4-.1-.6-.2-1.3-.9-2.7-1.8-4.1-2.6-.7-.4-1-1.3-.6-2.1.4-.7,1.3-1,2.1-.6c1.5.9,3,1.8,4.4,2.8.7.5.9,1.4.4,2.1-.4.4-1,.7-1.6.6Zm-42.4-9.9c-.6-.1-1.1-.6-1.2-1.3-.1-.8.4-1.6,1.3-1.7c1.7-.3,3.4-.4,5.1-.6.8-.1,1.5.6,1.6,1.4c0,.8-.6,1.6-1.4,1.6-1.6.1-3.3.3-4.9.5-.2.2-.3.2-.5.1Zm30.8,3.6c-.1,0-.2,0-.3-.1-1.5-.6-3.1-1.2-4.6-1.6-.8-.2-1.2-1.1-1-1.9s1.1-1.3,1.9-1c1.6.5,3.3,1.1,4.9,1.7.8.3,1.1,1.2.8,2-.3.7-1,1-1.7.9ZM1060.7,7c-.8-.1-1.3-.8-1.2-1.6s.8-1.5,1.6-1.4c1.7.1,3.4.3,5.1.6c0,0,0,0,0,0c.8.1,1.4.9,1.2,1.7-.1.8-.9,1.4-1.7,1.3-1.6-.3-3.2-.4-4.9-.6c0,0-.1,0-.1,0Z"
            opacity="0.3"
            fill="url(#ewFJuUgkZ6s5-fill)"
          ></path>
          <path
            d="M1098.1,24.2c-.3-.1-.6-.2-.8-.4-.6-.6-1.1-1.1-1.7-1.6-.6-.6-.6-1.5-.1-2.1.6-.6,1.5-.7,2.1-.1s1.2,1.1,1.8,1.7.6,1.5,0,2.1c-.3.3-.8.5-1.3.4Z"
            opacity="0.3"
            fill="url(#ewFJuUgkZ6s6-fill)"
          ></path>
        </g>
        <g opacity="0.3">
          <path
            d="M1115.3,66.9c-.7-.1-1.2-.8-1.2-1.5c0-.8,0-1.6,0-2.4s.6-1.5,1.5-1.5c.8,0,1.5.6,1.5,1.5c0,.8,0,1.7,0,2.5s-.7,1.5-1.5,1.5c-.2,0-.3-.1-.3-.1Z"
            opacity="0.3"
            fill="url(#ewFJuUgkZ6s8-fill)"
          ></path>
          <path
            d="M1045.9,123.7c-.7-.1-1.4-.3-2.1-.4-.8-.2-1.3-1-1.2-1.8.2-.8,1-1.3,1.8-1.2c1.6.3,3.2.6,4.8.8.8.1,1.4.9,1.3,1.7s-.9,1.4-1.7,1.3c-.9-.1-1.9-.2-2.9-.4Zm-9.7-2.6c-.1,0-.2,0-.2-.1-1.6-.6-3.2-1.2-4.7-1.9-.8-.4-1.1-1.2-.7-2c.3-.8,1.2-1.1,2-.7c1.4.7,2.9,1.3,4.5,1.8.8.3,1.2,1.2.9,1.9-.4.7-1.1,1.1-1.8,1Zm20.6,3.5c-.7-.1-1.2-.7-1.2-1.5s.7-1.5,1.5-1.5c1.6,0,3.2-.1,4.8-.2.8-.1,1.6.5,1.6,1.4.1.8-.5,1.6-1.4,1.6-1.7.2-3.4.2-5.1.2c0,.1-.1,0-.2,0Zm-32.2-9.3c-.2,0-.4-.1-.5-.2-1.4-.9-2.8-1.9-4.2-2.9-.7-.5-.8-1.5-.3-2.1.5-.7,1.5-.8,2.1-.3c1.3,1,2.6,1.9,3.9,2.8.7.4.9,1.4.4,2.1-.2.5-.8.7-1.4.6Zm45.1,7.8c-.6-.1-1.1-.5-1.2-1.1-.2-.8.3-1.6,1.1-1.8c1.6-.4,3.1-.8,4.6-1.3.8-.3,1.7.2,1.9,1s-.2,1.6-1,1.9c-1.6.5-3.2,1-4.9,1.4-.1-.1-.3-.1-.5-.1Zm12.2-4.3c-.5-.1-.9-.4-1.1-.8-.4-.8-.1-1.7.7-2c1.4-.7,2.9-1.5,4.2-2.3.7-.4,1.6-.2,2.1.5.4.7.2,1.6-.5,2.1-1.5.9-3,1.7-4.5,2.4-.3.1-.6.2-.9.1Zm11-6.8c-.3-.1-.7-.2-.9-.6-.5-.7-.4-1.6.2-2.1c1.3-1,2.5-2,3.7-3.1.6-.6,1.6-.5,2.1.1.6.6.5,1.6-.1,2.1-1.2,1.1-2.5,2.3-3.8,3.3-.4.2-.8.3-1.2.3Zm9.2-9.1c-.2,0-.5-.1-.7-.3-.7-.5-.7-1.5-.2-2.1c1-1.2,2-2.5,2.9-3.9.5-.7,1.4-.9,2.1-.4s.9,1.4.4,2.1c-.9,1.4-2,2.8-3,4.1-.4.4-1,.6-1.5.5Zm7-10.9c-.1,0-.3-.1-.4-.1-.8-.4-1.1-1.3-.7-2c.7-1.4,1.4-2.9,2-4.4.3-.8,1.2-1.2,2-.9s1.2,1.2.9,2c-.6,1.6-1.3,3.1-2.1,4.6-.4.6-1,.9-1.7.8Zm4.5-12.2c-.1,0-.1,0,0,0-.9-.2-1.4-1-1.2-1.8.3-1.1.5-2.3.7-3.5.1-.4.2-.8.2-1.3.1-.8.9-1.4,1.7-1.3s1.4.9,1.2,1.7c-.1.4-.1.9-.2,1.3-.2,1.2-.5,2.4-.8,3.6-.1.9-.9,1.4-1.6,1.3Z"
            opacity="0.3"
            fill="url(#ewFJuUgkZ6s9-fill)"
          ></path>
          <path
            d="M1014.5,107.1c-.3-.1-.6-.2-.8-.4-.6-.6-1.1-1.2-1.7-1.8s-.5-1.6.1-2.1c.6-.6,1.6-.5,2.1.1s1.1,1.2,1.6,1.7c.6.6.6,1.6,0,2.1-.3.4-.8.5-1.3.4Z"
            opacity="0.3"
            fill="url(#ewFJuUgkZ6s10-fill)"
          ></path>
        </g>
        <g id="ewFJuUgkZ6s11" transform="translate(0 0)">
          <path
            d="M1019.6,90.2c.1.3.4.4.7.4l8.5-1.5c2.7,3,5.9,5.6,9.5,7.6l.4,8.6c0,.3.2.6.5.6l9.9,2.9c.3.1.6,0,.8-.3l4.9-7c4.1.2,8.2-.3,12.1-1.4l6.4,5.8c.2.2.6.2.8.1l9-5c.3-.1.4-.4.4-.7l-1.5-8.5c3-2.7,5.6-5.9,7.6-9.5l8.6-.4c.3,0,.6-.2.6-.5l2.9-9.9c.1-.3,0-.6-.3-.8l-7-4.9c.2-4.1-.3-8.2-1.4-12.1l5.8-6.4c.2-.2.2-.6.1-.8l-5-9c-.1-.3-.4-.4-.7-.4l-8.5,1.5c-2.7-3-5.9-5.6-9.5-7.6l-.4-8.6c0-.3-.2-.6-.5-.6l-9.9-2.9c-.3-.1-.6,0-.8.3l-4.9,7c-4.1-.2-8.2.3-12.1,1.4l-6.4-5.8c-.2-.2-.5-.2-.8-.1l-9,5c-.3.1-.4.4-.3.7l1.5,8.5c-3,2.7-5.6,5.9-7.6,9.5l-8.6.4c-.3,0-.6.2-.6.5l-2.9,9.9c-.1.3,0,.6.3.8l7,4.9c-.2,4.1.3,8.2,1.4,12.1l-5.8,6.4c-.2.2-.2.6-.1.8l4.9,9Z"
            fill="#8f8ce4"
          ></path>
          <path
            d="M1054.9,74c-5-.9-8.7-5.3-8.5-10.5.2-5.7,5-10.2,10.7-10c2.8.1,5.3,1.3,7.2,3.3s2.9,4.7,2.8,7.4c-.2,5.7-5,10.2-10.7,10-.5,0-1-.1-1.5-.2Zm3.3-18c-.4-.1-.8-.1-1.2-.1-4.4-.1-8.1,3.3-8.2,7.7-.2,4.4,3.3,8.1,7.7,8.2s8.1-3.3,8.2-7.7c.1-2.1-.7-4.1-2.1-5.7-1.2-1.3-2.7-2.1-4.4-2.4Z"
            opacity="0.56"
            fill="#fff"
          ></path>
          <path
            d="M1052.5,88.8c-.8-.2-1.7-.4-2.5-.6-.3-.1-.5-.2-.8-.2-1.3-.4-2.6-.9-3.8-1.6c0,0,0,0,0,0s-.1,0-.1-.1c-.1,0-.2-.1-.3-.1-.2-.1-.3-.4-.3-.6c0-.1,0-.1.1-.2.2-.3.5-.4.8-.2c1.5.8,3.1,1.5,4.8,1.9c8.3,2.4,16.8.2,22.8-5.1c3.3-3,5.9-6.9,7.2-11.5.2-.8.4-1.6.6-2.4c1-5.5.1-11.1-2.7-16-3.1-5.7-8.3-9.8-14.5-11.6-12.9-3.7-26.4,3.7-30.1,16.6-.2.8-.4,1.6-.6,2.4-.6,3.5-.5,7.1.4,10.5c0,.1,0,.2,0,.3c0,.2-.2.4-.4.5-.3.1-.7-.1-.7-.4-1-3.6-1.1-7.4-.4-11.1.2-.8.3-1.7.6-2.5c3.9-13.5,18.1-21.3,31.6-17.4c6.5,1.9,12,6.2,15.2,12.2c2.9,5.2,3.8,11.1,2.8,16.8-.1.9-.4,1.7-.6,2.6-1.9,6.5-6.2,12-12.2,15.3-5.3,2.6-11.2,3.5-16.9,2.5Z"
            opacity="0.56"
            fill="#fff"
          ></path>
        </g>
        <g opacity="0.3">
          <path
            d="M938.7,99.8c-.5.1-1,0-1.2-.4-.5-.8-1-1.6-1.5-2.4-.3-.5-.1-1.2.4-1.5s1.2-.1,1.5.4c.4.8.9,1.5,1.4,2.3.3.5.2,1.2-.3,1.5-.1,0-.2.1-.3.1Z"
            opacity="0.3"
            fill="url(#ewFJuUgkZ6s16-fill)"
          ></path>
          <path
            d="M934.8,92.4c-.5.2-1.1-.1-1.3-.6-.6-1.5-1.2-3-1.6-4.5-.1-.3-.2-.6-.3-1-.2-.6.2-1.2.8-1.3.6-.2,1.2.2,1.3.8.1.3.2.6.3.9.4,1.5,1,2.9,1.5,4.3.2.6,0,1.2-.6,1.4c0,0,0,0-.1,0Zm-3-10.9c0,0-.1,0-.2,0-.6.1-1.1-.3-1.2-.9-.3-1.9-.5-3.8-.5-5.8c0-.6.5-1.1,1.1-1.1s1.1.5,1.1,1.1c.1,1.8.2,3.7.5,5.5c0,.6-.3,1.1-.8,1.2Zm-.3-11.2c-.1,0-.3.1-.4,0-.6-.1-1-.6-1-1.2.2-1.9.5-3.8.9-5.7.1-.6.7-1,1.3-.8.6.1,1,.7.8,1.3-.4,1.8-.7,3.6-.9,5.5c0,.4-.3.7-.7.9Zm2.4-11.1c-.2.1-.5.1-.7,0-.6-.2-.9-.8-.7-1.4.6-1.8,1.4-3.6,2.3-5.3.3-.5.9-.8,1.5-.5.5.3.8.9.5,1.5-.8,1.6-1.6,3.3-2.2,5.1-.1.3-.3.6-.7.6Zm5.1-10c-.3.1-.6.1-.9-.1-.5-.3-.6-1-.3-1.5c1.1-1.6,2.2-3.1,3.5-4.6.4-.5,1.1-.5,1.5-.1.5.4.5,1.1.1,1.5-1.2,1.4-2.3,2.9-3.3,4.4-.1.2-.3.3-.6.4Zm7.4-8.6c-.4.1-.8,0-1.1-.3-.4-.4-.4-1.1.1-1.5c1.4-1.3,2.9-2.5,4.5-3.6.5-.3,1.2-.2,1.5.3s.2,1.2-.3,1.5c-1.5,1.1-2.9,2.2-4.3,3.4-.1.1-.3.2-.4.2Zm9.2-6.4c-.5.1-1-.1-1.3-.5-.3-.5-.1-1.2.5-1.5c1.7-.9,3.5-1.7,5.3-2.4.6-.2,1.2.1,1.4.6.2.6-.1,1.2-.6,1.4-1.7.7-3.4,1.4-5,2.3-.1,0-.2,0-.3.1Zm16.1-5.1c-.1,0-.1,0-.2,0-1.6.2-3.2.5-4.7.8-.2.1-.5.1-.7.2-.6.1-1.2-.2-1.3-.8s.2-1.2.8-1.3c.2-.1.5-.1.7-.2c1.6-.4,3.3-.7,5-.9.6-.1,1.1.4,1.2,1s-.3,1.1-.8,1.2Z"
            opacity="0.3"
            fill="url(#ewFJuUgkZ6s17-fill)"
          ></path>
        </g>
        <g opacity="0.3">
          <path
            d="M991,118.4c-.6.2-1.2-.1-1.4-.7s.1-1.2.7-1.4c1.7-.5,3.4-1.2,5.1-2c.5-.2,1.2,0,1.4.5s0,1.2-.5,1.4c-1.7.9-3.4,1.6-5.3,2.2c0,0,0,0,0,0Zm-5.4,1.4c0,0-.1,0-.1,0-1.9.4-3.8.6-5.6.7-.6,0-1.1-.4-1.2-1c0-.6.4-1.1,1-1.2c1.8-.1,3.6-.4,5.4-.7.6-.1,1.2.3,1.3.9s-.2,1.1-.8,1.3Zm15.5-6c-.5.1-1,0-1.2-.5-.3-.5-.2-1.2.4-1.5c1.5-.9,3-2,4.4-3.1.5-.4,1.2-.3,1.5.2.4.5.3,1.2-.2,1.5-1.5,1.2-3,2.3-4.7,3.3c0,0-.1.1-.2.1Zm-26.5,6.7c-.1,0-.2.1-.4,0-1.9-.1-3.8-.3-5.7-.7-.6-.1-1-.7-.9-1.3s.7-1,1.3-.9c1.8.3,3.6.5,5.4.6.6,0,1.1.5,1,1.1c0,.7-.3,1.1-.7,1.2Zm35.2-13.6c-.4.1-.8,0-1.1-.3-.4-.4-.4-1.1,0-1.5c1.3-1.3,2.5-2.7,3.6-4.1.4-.5,1-.6,1.5-.2s.6,1,.2,1.5c-1.2,1.5-2.4,2.9-3.7,4.3-.2.1-.3.2-.5.3Zm6.8-8.8c-.3.1-.6.1-.9-.1-.5-.3-.7-1-.4-1.5.9-1.6,1.8-3.2,2.5-4.8.2-.6.9-.8,1.4-.6s.8.9.6,1.4c-.8,1.7-1.6,3.4-2.6,5.1-.1.2-.4.4-.6.5Zm4.4-10.2c-.2.1-.4.1-.6,0-.6-.2-.9-.8-.7-1.4.5-1.7.9-3.5,1.2-5.3.1-.6.7-1,1.3-.9s1,.7.9,1.3c-.3,1.9-.8,3.7-1.3,5.5-.1.4-.4.7-.8.8Z"
            opacity="0.3"
            fill="url(#ewFJuUgkZ6s19-fill)"
          ></path>
          <path
            d="M963.6,118.5c-.2.1-.4.1-.6,0-.9-.3-1.8-.6-2.6-.9-.6-.2-.8-.8-.6-1.4s.8-.8,1.4-.6c.8.3,1.7.6,2.5.9.6.2.9.8.7,1.4-.2.3-.5.5-.8.6Z"
            opacity="0.3"
            fill="url(#ewFJuUgkZ6s20-fill)"
          ></path>
        </g>
        <g id="ewFJuUgkZ6s21" transform="translate(0 0)">
          <path
            d="M960.4,104.8c.2.1.5.1.6,0l5.3-4c2.9,1.1,6,1.8,9.3,1.9l3.4,5.8c.1.2.4.3.6.3l7.9-1.5c.2,0,.4-.2.4-.5l.9-6.6c2.9-1.3,5.6-3.1,7.9-5.2l6.5,1.7c.2.1.5,0,.6-.2l4.5-6.7c.1-.2.1-.5,0-.6l-4-5.3c1.1-2.9,1.8-6,1.9-9.3l5.8-3.4c.2-.1.3-.4.3-.6l-1.5-7.9c0-.2-.2-.4-.5-.4l-6.6-.9c-1.3-2.9-3.1-5.6-5.2-7.9l1.7-6.5c.1-.2,0-.5-.2-.6l-6.7-4.5c-.2-.1-.5-.1-.6,0l-5.3,4c-2.9-1.1-6-1.8-9.3-1.9l-3.4-5.8c-.1-.2-.4-.3-.6-.3l-7.9,1.5c-.2,0-.4.2-.4.5l-.9,6.6c-2.9,1.3-5.6,3.1-7.9,5.2L950.5,50c-.2-.1-.5,0-.6.2l-4.5,6.7c-.1.2-.1.5,0,.6l4,5.3c-1.1,2.9-1.8,6-1.9,9.3l-5.8,3.4c-.2.1-.3.4-.3.6l1.5,7.9c0,.2.2.4.5.4l6.6.9c1.3,2.9,3.1,5.6,5.2,7.9l-1.7,6.5c-.1.2,0,.5.2.6l6.7,4.5Z"
            fill="#8f8ce4"
          ></path>
          <path
            d="M979.1,81.2c-1.7.5-3.5.5-5.1-.1-2.1-.7-3.7-2.2-4.7-4.2-1.9-4.1-.2-9,3.9-10.9s9-.2,10.9,3.9c.9,2,1,4.2.3,6.3s-2.2,3.7-4.2,4.7c-.3,0-.7.1-1.1.3ZM975,67.5c-.3.1-.6.2-.8.3-3,1.4-4.3,5-2.9,8.1.7,1.5,1.9,2.6,3.4,3.1s3.2.5,4.6-.2c1.5-.7,2.6-1.9,3.1-3.4s.5-3.2-.2-4.6c-1.3-2.8-4.4-4.2-7.2-3.3Z"
            opacity="0.56"
            fill="#fff"
          ></path>
          <path
            d="M982.8,92.7c-.7.2-1.3.4-2,.5-1.4.3-2.8.4-4.2.4-.1,0-.1,0-.2,0-.5,0-.9-.3-1-.8c0-.1,0-.2,0-.3c0-.6.5-1.1,1.1-1.1c1.3,0,2.6-.1,3.9-.3.1,0,.1,0,.2,0c3-.6,5.8-2,8.1-3.9c1.2-1.1,2.4-2.3,3.3-3.7c0,0,0,0,0,0s0-.1.1-.1c2.7-4.1,3.7-8.9,2.8-13.7-.1-.6-.3-1.2-.5-1.8-1.2-4-3.8-7.5-7.3-9.8-4.1-2.7-8.9-3.7-13.7-2.8s-8.9,3.7-11.7,7.7c-2.7,4.1-3.7,8.9-2.8,13.7.1.6.3,1.2.4,1.8.8,2.6,2.1,4.9,3.9,6.9.1.1.2.3.2.4.1.4,0,.8-.3,1.1-.4.4-1.1.4-1.5-.1-2-2.2-3.5-4.9-4.4-7.7-.2-.7-.4-1.3-.5-2-1-5.4.1-10.8,3.1-15.4c3.1-4.5,7.7-7.6,13.1-8.7c5.4-1,10.8.1,15.4,3.1c4,2.7,6.8,6.5,8.2,11c.2.7.4,1.3.5,2c2,10.5-4.3,20.6-14.2,23.6Z"
            opacity="0.56"
            fill="#fff"
          ></path>
        </g>
        <path
          d="M893.2,310.3l-9.9,68.4h115l-15-68.4h-90.1Z"
          fill="url(#ewFJuUgkZ6s25-fill)"
        ></path>
        <path
          d="M700,91.3v203.6c0,16.7,13.3,30.4,30.1,30.9c74.4,1.9,334.5,6.6,405.3,5.5c12.2-.2,22-9.9,22.4-22.1c1.4-47.3,3.6-161-4.6-213.9-1.7-11.1-11.1-19.4-22.4-19.8-62.3-2-338-8.3-408.8-6.7-12.3.3-22,10.3-22,22.5Z"
          fill="url(#ewFJuUgkZ6s26-fill)"
        ></path>
        <path
          d="M712.8,90.3v193.4c0,9.8,7.9,17.7,17.6,17.9l394.8,4.9c9.7.1,17.6-7.7,17.7-17.3l.7-191.7c0-6.9-5.5-12.6-12.4-12.8L727,76.5c-7.8-.2-14.2,6.1-14.2,13.8Z"
          fill="#fff"
        ></path>
        <path
          d="M1023,396.9h-168.3c-4.6,0-8-3.3-7.1-6.8l2.2-8.5c.6-2.6,3.5-4.5,6.8-4.6l162.3-5.1c3.5-.1,6.6,1.8,7.4,4.4l3.9,13.6c1,3.7-2.5,7-7.2,7Z"
          fill="url(#ewFJuUgkZ6s28-fill)"
        ></path>
        <path
          d="M1143.4,114.2l.1-17.2c0-6.9-5.5-12.7-12.4-12.8L726.6,76c-7.8-.2-14.2,6-14.2,13.8v24.4h431Z"
          fill="url(#ewFJuUgkZ6s29-fill)"
        ></path>
        <path
          d="M843.5,294.2h-117.8c-1.4,0-2.5-1.1-2.5-2.5v-166.1c0-1.4,1.1-2.5,2.5-2.5h117.8c1.4,0,2.5,1.1,2.5,2.5v166.1c-.1,1.4-1.2,2.5-2.5,2.5Z"
          opacity="0.36"
          fill="#fff"
        ></path>
        <path
          id="ewFJuUgkZ6s31"
          d="M942.1,294.8h-75.9c-1.5,0-2.6-1.2-2.6-2.6v-62.2c0-1.5,1.2-2.6,2.6-2.6h75.9c1.5,0,2.6,1.2,2.6,2.6v62.1c.1,1.5-1.1,2.7-2.6,2.7Z"
          opacity="0.57"
          fill="#0198d8"
          transform="translate(0 0)"
        ></path>
        <path
          id="ewFJuUgkZ6s32"
          d="M1031.5,294.7h-76.7c-1.3,0-2.3-1-2.3-2.3v-62.8c0-1.3,1-2.3,2.3-2.3h76.7c1.3,0,2.3,1,2.3,2.3v62.8c0,1.3-1.1,2.3-2.3,2.3Z"
          opacity="0.57"
          fill="#0198d8"
          transform="translate(0 0)"
        ></path>
        <path
          d="M1121,294.7h-76.6c-1.3,0-2.3-1-2.3-2.3v-62.8c0-1.3,1-2.3,2.3-2.3h76.6c1.3,0,2.3,1,2.3,2.3v62.8c0,1.3-1,2.3-2.3,2.3Z"
          opacity="0.57"
          fill="#fff"
        ></path>
        <path
          d="M734.8,103.3c2.7,0,5-2.2,5-5c0-2.7-2.2-5-5-5s-5,2.2-5,5c0,2.7,2.3,5,5,5Z"
          fill="url(#ewFJuUgkZ6s34-fill)"
        ></path>
        <path
          d="M751.1,103.3c2.7,0,5-2.2,5-5c0-2.7-2.2-5-5-5s-5,2.2-5,5c0,2.7,2.2,5,5,5Z"
          fill="url(#ewFJuUgkZ6s35-fill)"
        ></path>
        <path
          d="M767,103.3c2.7,0,5-2.2,5-5c0-2.7-2.2-5-5-5s-5,2.2-5,5c0,2.7,2.2,5,5,5Z"
          fill="url(#ewFJuUgkZ6s36-fill)"
        ></path>
        <path
          id="ewFJuUgkZ6s37"
          d="M833.2,133.9h-97.8v46.7h97.8v-46.7Z"
          opacity="0.64"
          fill="#0198d8"
          transform="translate(0 0)"
        ></path>
        <path
          id="ewFJuUgkZ6s38"
          d="M833.2,186.6h-97.8v46.7h97.8v-46.7Z"
          opacity="0.64"
          fill="#0198d8"
          transform="translate(0 0)"
        ></path>
        <path
          id="ewFJuUgkZ6s39"
          d="M833.2,239.2h-97.8v46.7h97.8v-46.7Z"
          opacity="0.64"
          fill="#0198d8"
          transform="translate(0 0)"
        ></path>
        <g>
          <path
            d="M1120.2,219.4h-253.5c-1.7,0-3.2-1.4-3.2-3.2v-89.4c0-1.7,1.4-3.2,3.2-3.2h253.4c1.8,0,3.2,1.4,3.2,3.2v89.4c0,1.8-1.4,3.2-3.1,3.2Z"
            fill="url(#ewFJuUgkZ6s41-fill)"
          ></path>
          <path
            d="M1123.3,138.6h-259.7v21.4h259.8v-21.4Z"
            fill="#6ec4e9"
          ></path>
        </g>
        <g>
          <path
            d="M918.9,248.5c-.3-.5-1-.4-1.2.1-.6,2.1-2,5.7-4.9,7-.1.1-.3.1-.4.2-.1-.4-.1-.9.2-1.3.9-1.4-.7-3.3-.7-3.3s-.1,1.7-1.4,2.7c-1.1.9-1.6,2.9-.9,4.6c1.6,6.2,4,14.3-5.3,20.2-9.2,5.9-22.3-11.2-22.3-11.2l6.5,20.5c0,0,10,7.4,21.6-3c11.6-10.3,3.9-25.4,3.9-25.4s8.8-3.6,4.9-11.1Z"
            fill="url(#ewFJuUgkZ6s44-fill)"
          ></path>
          <path
            d="M880.4,237.4c0,0,2.7-12-11.1-8.8c0,0-6.7-1.1-7,5.2c0,0-4.3,2.8-1.2,8.3c0,0-12.8-4.9-18.4,6.3s-.5,17.4,4.9,19c0,0-4.4,2.2-2.3,7.1s12.5,8.4,18.7,2.4c0,0,17.5,2.3,17.2-10.1c0,0,8.2-5.3-.4-15.5-8.5-10.3-.4-13.9-.4-13.9Z"
            fill="#06242b"
          ></path>
          <path
            d="M881.2,265.2c-2.8-.8-10.8-2.4-18.5,4.7-1.3,1.2-1.8,3-1.4,4.7c2.1,7.6,7.5,29.9,2.7,39.6c0,0,8.4,9.2,17.5,1.8c0,0,4.1-17.9,9.5-25.7c3.7-5.4-5.9-20.4-8.4-24-.3-.5-.8-.9-1.4-1.1Z"
            fill="url(#ewFJuUgkZ6s46-fill)"
          ></path>
          <path
            d="M873.5,250.2c0,0-4.1,11.6-4.4,16.7c0,0,4.5,2.1,8.8-1.4c0,0,.4-11.6,1.1-13.6l-5.5-1.7Z"
            fill="url(#ewFJuUgkZ6s47-fill)"
          ></path>
          <path
            d="M879.2,234.4c0,0,10.7,15,4.3,19.3s-14-6.3-14-6.3-2.5-8.2.1-11c2.6-2.7,9.6-2,9.6-2Z"
            fill="url(#ewFJuUgkZ6s48-fill)"
          ></path>
          <path
            d="M874.3,230.8c0,0,4.9,1.2,5.6,3.4s-7,4.3-9.1,4.4l-1.7,9c0,0-5.1-8.4-3.1-13.3s8.3-3.5,8.3-3.5Z"
            fill="#06242b"
          ></path>
          <path
            d="M871.6,246.3c0,0-4.2-4.8-5.8-1.7-1.7,3.1,3.7,5.5,5.6,4l.2-2.3Z"
            fill="url(#ewFJuUgkZ6s50-fill)"
          ></path>
          <path
            d="M892.3,353.6c0,.8,1.4,7.3,2.2,10.7.3,1.4.1,2.8-.6,4.1L867,413.6c-.3.5-.6,1.1-.7,1.8-.5,2.1-1.5,7.3-1,12.1l1.4,2.9-6-1.4-1.3-16.1l1.7-1c1.5-.9,2.6-2.2,3.3-3.8c2.4-5.9,8.5-20.9,10-28.3c1.8-8.3,11-15.1,11-15.1l-1.6-3.8l8.5-7.3Z"
            fill="url(#ewFJuUgkZ6s51-fill)"
          ></path>
          <path
            d="M854,423.4c0,0,3.2-5.1,3.6-7.2.4-2,1.4-5.5,3.4-4.5c0,0,.9,9.7.6,13.5s4.3,3.7,4.3,3.7s1.3.2,2.2,1.1c.6.6.2,1.8-.7,1.8-1.5.1-3.9.1-6.4-.4-.9-.2-1.5-.9-1.6-1.8l-.7-11.2-4.1,5.2-.6-.2Z"
            fill="url(#ewFJuUgkZ6s52-fill)"
          ></path>
          <path
            d="M876.2,360.8c0,0-.9,40.5-1.1,46.2-.1,5.7.9,18.5,6.7,22.3l-2.5,1.9c0,0-3.1-2.7-6.2-7.8-3.4-5.8-4.5-6.8-4.5-6.8l1.1-2.3c.5-1,.6-2.2.4-3.3-1.2-6-5.4-26.1-6.5-30.4-1.3-5.1-1.4-11.9,3.2-17.6c4.7-5.7,9.4-2.2,9.4-2.2Z"
            fill="url(#ewFJuUgkZ6s53-fill)"
          ></path>
          <path
            d="M870.9,433c-.2,0-.3-.1-.4-.3-.2-1.3-1-6.8-1.6-8.2-.7-1.6-3.2-8.4,0-8.9c0,0,7.9,11.6,9.5,13.2c1.5,1.6,3.4.5,3.4.5s1.5.4,2.6,1.9c.5.7,0,1.7-.8,1.7l-5.3.1c-1.6,0-2.9-.9-3.5-2.4-.9-2.4-2.6-5.8-4.4-7.3l1,9.4c-.1.1-.3.3-.5.3Z"
            fill="url(#ewFJuUgkZ6s54-fill)"
          ></path>
          <path
            d="M882.8,311.7c0,0-1.2,3.8.6,10.2L895,362c.2.6-.2,1.3-.8,1.5-3.4,1.2-14.7,4.7-30.1,3.7-.8-.1-1.4-.6-1.6-1.4-1-4.6-4.5-20.2-5.2-23.4-2.7-12.2-5.8-25.4,7.6-33.3c6.1,2.6,11.9,4.1,17.9,2.6Z"
            fill="url(#ewFJuUgkZ6s55-fill)"
          ></path>
          <path
            d="M861.6,271.4c0,0-13.1,10.9-16.3,25.7s19.1,16.4,19.1,16.4s3-3.4,6.6-2.2.3-5.9-3.9-5.9-2.3,3.3-8.2.6c-5.8-2.6-9.4-10.8,5.4-23.1.9-.7,1.5-1.6,2-2.7c2.8-7.1.5-11.8-4.7-8.8Z"
            fill="url(#ewFJuUgkZ6s56-fill)"
          ></path>
        </g>
        <g>
          <path
            d="M1056.5,413.8c0,0,.8,4.1,1.2,8.2.2,1.6-1.1,3-2.7,3.1-5.5.3-17.2.8-21.4.7-.6,0-1-.7-.8-1.3.2-.5.6-.9,1-1.1l19.2-9.1l3.5-.5Z"
            fill="url(#ewFJuUgkZ6s58-fill)"
          ></path>
          <path
            d="M1057,411.4l-.5,5c0,0-3.4,1.6-5.7-.1l1.3-5.5l4.9.6Z"
            fill="url(#ewFJuUgkZ6s59-fill)"
          ></path>
          <path
            d="M1055.7,318c.3,1.3,2,64.7-4.5,93.2-.1.5.1.9.6,1.1c1.1.4,3.1,1,5.5,1.1.8,0,1.4-.5,1.6-1.2c1.9-6.6,10.8-39.7,11.8-71.3c1-35.3,0-1.2,0-1.2l-1-21.8-14,.1Z"
            fill="url(#ewFJuUgkZ6s60-fill)"
          ></path>
          <path
            d="M1078.7,415.8c0,0,1.2,2.1,2.4,7.6.2,1-.5,2-1.5,2.1-1.8.2-4.6.5-6.8.5-1,0-1.8-.9-1.7-1.9.2-2,1-5.4,3.3-8l4.3-.3Z"
            fill="url(#ewFJuUgkZ6s61-fill)"
          ></path>
          <path
            d="M1079.7,409.4l-1.3,7.5c0,0-1.4,1-4.1.3l.6-8.1l4.8.3Z"
            fill="url(#ewFJuUgkZ6s62-fill)"
          ></path>
          <path
            d="M1082.6,319.7c5.9,33.4-.4,82.7-2.6,92.3-.1.6-.6,1-1.2,1.2-1,.2-2.7.3-4.3-.1-.6-.2-1-.7-1-1.3-.4-7.8-.3-55.6-5.6-74.5-.3-1-.9-1.8-1.7-2.4l-10.1-7-.9-11.5l27.4,3.3Z"
            fill="url(#ewFJuUgkZ6s63-fill)"
          ></path>
          <path
            d="M1046.6,256.7c16.6-.4,35,4,35,4c5.2,28.8,1.5,62.2,1.5,62.2-12.8,3.5-27.4-1.8-27.4-1.8-2.2-12.1-12.6-45.9-12.6-45.9-8.1-.9-14.8-3.2-20.4-6.3c4.2-4.9,6.1-11.1,6.9-14.8c6,1.8,11.9,2.7,17,2.6Z"
            fill="url(#ewFJuUgkZ6s64-fill)"
          ></path>
          <path
            d="M997.3,232.7c1.6-2,3.7-1.1,3.7-1.1c2.5,10.3,15.4,18.6,28.6,22.5-.8,3.7-2.7,9.9-6.9,14.8-21.9-12.1-25.4-36.2-25.4-36.2Z"
            fill="url(#ewFJuUgkZ6s65-fill)"
          ></path>
          <path
            d="M997.7,233.2c-.2.5,1.8,1.4,3-.9c0-1.1.6-5.6,0-6.5-3.4-4.6-10-10.3-10-10.3-1.1,1.1-.2,2.2-.2,2.2l3.2,3.3c-.7,1.2-1.5,2.2-1.8,3.7-.3,1.1-.1,2.3.5,3.3s1.3,2.3,2.1,3.3c1.3,1.9,3.2,1.9,3.2,1.9Z"
            fill="url(#ewFJuUgkZ6s66-fill)"
          ></path>
          <path
            d="M995.1,223.1l2.9,2.4.9,2.4-2.6,2.9c0,0-.9-4.9-1.6-5.9-.5-1-.2-1.5.4-1.8Z"
            fill="url(#ewFJuUgkZ6s67-fill)"
          ></path>
          <path
            d="M1075.4,276.8l6.2-16.1c0,0,15.6,10.4,18.3,23.7-2.9.2-8.3,1.1-13.5,3.7-1.2-6.2-11-11.3-11-11.3Z"
            fill="url(#ewFJuUgkZ6s68-fill)"
          ></path>
          <path
            d="M1062,292.1c0,0,17.5,9.7,23.3,1.2c1.2-1.8,1.5-3.5,1.2-5.1c5.2-2.7,10.5-3.5,13.5-3.7.8,4,.4,8.3-1.9,12.7-10.1,18.9-38.3-.7-38.3-.7l2.2-4.4Z"
            fill="url(#ewFJuUgkZ6s69-fill)"
          ></path>
          <path
            d="M1063.3,243l5.4,15.2c.1.3,0,.5-.2.7-1.4.8-6.6,3.2-12.9-.7-.7-.4-1.1-1.1-1.2-1.8l-1.8-13.2l10.7-.2Z"
            fill="url(#ewFJuUgkZ6s70-fill)"
          ></path>
          <path
            d="M1051.7,229c0,0-5.7,11-4.9,15.4s8,4,14.2.4l4.3-11.8-13.6-4Z"
            fill="url(#ewFJuUgkZ6s71-fill)"
          ></path>
          <path
            d="M1060.7,239c-.3-.1-.4-.4-.2-.7.5-.7,1.3-2.2,1.6-3.4c0,0,1-.7-2.7-1.5-3.7-.9-9.2-2.2-10.4-5.3s1.7-10.8,6.5-4.7c.2.2.5.2.7,0c.8-1,2.8-3.1,5.6-1.9c2.7,1.2,3,3.1,3,4.1c0,.3.2.5.5.5c1.3-.3,4.6-.5,6.1,2.8c1.3,2.7.5,4.3-.2,5-.2.2-.1.6.2.7.9.3,2,1,1.9,3.1-.1,2.9-3.3,2.6-4.2,2.5-.2,0-.3,0-.4.1-.8.9-4.2,4.6-8,5-.3,0-.6-.3-.5-.6l1.6-4.8c.1-.2,0-.5-.2-.6l-.9-.3Z"
            fill="#06242b"
          ></path>
          <path
            d="M1060,239.6c0,0,4.1-2.9,4.4-.5s-3.7,3.5-5.7,2.6l1.3-2.1Z"
            fill="url(#ewFJuUgkZ6s73-fill)"
          ></path>
          <path
            d="M1065.6,307.4l-9.5-33.3c-.1-.4-.4-.7-.8-.7-1.7-.2-6.7-.8-12.5-1.3-7-.6-15.1-1.1-19.9-.7-.7.1-1.1.8-.8,1.4c2.8,5.6,14.1,28.2,19.7,35.6.2.2.5.4.8.4l22.1-.2c.6,0,1-.6.9-1.2Z"
            fill="url(#ewFJuUgkZ6s74-fill)"
          ></path>
          <path
            d="M1063.2,299.4c0,0,1.3-5.5.8-7.4s-1.6-4.4-5-3.9c-3.5.6-7.6,2-7.6,2s.2,2.5,2.8,1.7l-1.6,1.3c0,0,.2,2,2.5.9l-1.5,1.7c0,0,1,1.7,2.6.6s2.4,3.7,7,3.1Z"
            fill="url(#ewFJuUgkZ6s75-fill)"
          ></path>
          <path
            d="M1043.3,282.1c0,1.5-1.2,2.8-2.8,2.8s-2.8-1.3-2.8-2.8s1.2-2.8,2.8-2.8s2.8,1.2,2.8,2.8Z"
            fill="url(#ewFJuUgkZ6s76-fill)"
          ></path>
          <path
            d="M1054.9,283.9l.7-.2-1.9-6.6c-.3-1.1-1.2-1.9-2.3-2.1-6.2-1.3-22.4-1.5-23.1-1.6v.7c.2,0,16.8.3,22.9,1.5.8.2,1.5.8,1.7,1.6l2,6.7Z"
            fill="#fff"
          ></path>
        </g>
        <g>
          <path
            d="M817.9,319.2l14.8.2-5.2-4.9c-1.3-1.2-3.3-1.4-4.8-.4-.9.6-1.8,1.3-2.6,2.4c0,0-9.3-3.4-13.7-11-1.4,2.1-3,4.9-4.4,8.4c4,2.3,9.2,4.2,15.9,5.3Z"
            fill="url(#ewFJuUgkZ6s79-fill)"
          ></path>
          <path
            d="M837.2,425c-4,.3-10.8.9-16.2,1.1-3.3.1-5.1-.2-6.2-.6c0,.1,0,.2,0,.3c0,.6.5,1,1.1,1.1c10.2.6,18.3.2,21.2,0c.6,0,1-.7.6-1.2-.1-.2-.3-.4-.5-.7Z"
            fill="url(#ewFJuUgkZ6s80-fill)"
          ></path>
          <path
            d="M821,426.2c5.4-.2,12.3-.8,16.2-1.1-3.9-4.8-16.2-8-16.2-8h-3.5c-2.4,3.5-2.7,6.7-2.7,8.5c1.1.4,3,.7,6.2.6Z"
            fill="url(#ewFJuUgkZ6s81-fill)"
          ></path>
          <path
            d="M817,411.4l.3,6.4c0,.7.6,1.3,1.3,1.4s1.6.1,2.6-.2c.6-.2,1.1-.7,1.2-1.3l1.1-6.3h-6.5Z"
            fill="url(#ewFJuUgkZ6s82-fill)"
          ></path>
          <path
            d="M776.5,430.6c-1.7-.3-2.5-1.3-2.8-2.4c0,0,0,0,0,0-.3,1.2.3,2.4,1.5,3c1.4.7,3,.8,4.2.8c1.3-.1,2.5-.7,3.1-1.7v0c-1.3.7-3.1.7-6,.3Z"
            fill="url(#ewFJuUgkZ6s83-fill)"
          ></path>
          <path
            d="M784.6,413.4h-2.9c-4.4-.1-7.1,10.6-8,14.8.3,1.1,1.1,2.1,2.8,2.4c2.9.5,4.7.4,6-.4c2.4-3.6,3.5-8.9,4.1-12c.6-3.5-2-4.8-2-4.8Z"
            fill="url(#ewFJuUgkZ6s84-fill)"
          ></path>
          <path
            d="M782.1,407.4l-1.6,6.3c-.2.8.2,1.6.9,2c.9.5,2.1.9,3.3.8.5-.1,1-.4,1.2-1l2.3-6.7-6.1-1.4Z"
            fill="url(#ewFJuUgkZ6s85-fill)"
          ></path>
          <path
            d="M838.2,323.8c0,0-14.3,89.1-14,89.6c0,0-3.9,1.8-8.2.2c0,0,1.3-47.6,4.3-65.9c0,0-24.3,42.6-32,63.8c0,0-4-.5-7.4-2.7c0,0,26.2-76.6,33.2-86.8l24.1,1.8Z"
            fill="url(#ewFJuUgkZ6s86-fill)"
          ></path>
          <path
            d="M839.7,266.3c-2.4-5-23.1-5.6-38.3-.5-3.2,1.1-5.8,3.4-7.1,6.5-4.1,9.4-10.7,30.8,7.7,41.7c1.4-3.5,3.1-6.3,4.4-8.4-3-5-3.8-11.8,1.4-20.8c3.8,10.4,5.6,25.5,6,32.2.1,2.2-.3,4.4-1.1,6.4l-.1.2c-.2.4.1.9.5,1c10.4,2.3,21.2,1.7,24.4,1.4.6,0,1.1-.5,1.2-1c5.1-21.3,4.6-51.2,1-58.7Z"
            fill="url(#ewFJuUgkZ6s87-fill)"
          ></path>
          <path
            d="M817,249.2c0,0-1,11.1-2,14.4-.1.5.1,1,.5,1.3c1.6.9,5.3,2.4,10.5-.2.8-.4,1.3-1.3,1.3-2.2l-.5-10.7-9.8-2.6Z"
            fill="url(#ewFJuUgkZ6s88-fill)"
          ></path>
          <path
            d="M827.1,235c0,0,3.4,7.7,3.6,11.8.2,4,1.1,6.7-3.6,7.5s-8.6-1.5-10.8-5.5c0,0-3.3-6.2-2.6-8.8.8-2.6,13.4-5,13.4-5Z"
            fill="url(#ewFJuUgkZ6s89-fill)"
          ></path>
          <path
            d="M818,245.8l.3-.1c.3-.1.5-.6.3-.9-.7-1.3-1.9-4.2-.3-4.9c2.1-.9,10.6-2.6,12.5-5.6c1.8-2.9.2-6.6-2.1-7.7-.2-.1-.3,0-.4.1l-.2.4c-.1.3-.5.2-.6-.1s-.4-.3-.6-.1c-.6.9-2.1,2.4-6.1,3.1-5.8,1.1-9.6,3.4-9.7,7.4c0,.3-.3.4-.5.3-.4-.3-1.1-.4-2,.4-1.6,1.3-.9,4.8,2.5,7.6.2.1.2.4,0,.5-.6.4-1.6,1.4.9,2.7s4.4,1.2,5.1,1.1c.2,0,.3-.2.3-.4l-.8-3.5c0-.2.1-.4.3-.4l1.1.1v0Z"
            fill="#06242b"
          ></path>
          <path
            d="M818.1,246.6c0,0-1.3-4.9-3.5-3.5s1.1,5.5,2.9,5.7l.6-2.2Z"
            fill="url(#ewFJuUgkZ6s91-fill)"
          ></path>
          <path
            d="M811.7,313.2c0,0,1.3-2.1,4.2-2.1s6.3,5.5,6.3,5.5-4.6,2.1-6.3,3.8c-1.6,1.6-4.2-3.8-4.2-7.2Z"
            fill="url(#ewFJuUgkZ6s92-fill)"
          ></path>
        </g>
      </svg>
    </div>
  );
};
