interface UxSVGProps {
  classes: any;
}

export const RapidPrototypeSVG = ({ classes }: UxSVGProps) => {
  return (
    <div className={`${classes}`}>
      <svg
        id="ejzlHNaD2um1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 549 517"
        shape-rendering="geometricPrecision"
        text-rendering="geometricPrecision"
      >
        <defs>
          <linearGradient
            id="ejzlHNaD2um5-fill"
            x1="254.4801"
            y1="309.136015"
            x2="255.6901"
            y2="354.502015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um5-fill-0"
              offset="0%"
              stop-color="#444b8c"
            ></stop>
            <stop
              id="ejzlHNaD2um5-fill-1"
              offset="99.64%"
              stop-color="#26264f"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um6-fill"
            x1="264.3523"
            y1="252.742915"
            x2="131.0592"
            y2="-215.282085"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um6-fill-0"
              offset="0%"
              stop-color="#444b8c"
            ></stop>
            <stop
              id="ejzlHNaD2um6-fill-1"
              offset="99.64%"
              stop-color="#26264f"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um8-fill"
            x1="103.0621"
            y1="-11.145485"
            x2="335.4641"
            y2="120.016815"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um8-fill-0"
              offset="25.87%"
              stop-color="#006393"
            ></stop>
            <stop
              id="ejzlHNaD2um8-fill-1"
              offset="43.03%"
              stop-color="#086797"
            ></stop>
            <stop
              id="ejzlHNaD2um8-fill-2"
              offset="69.78%"
              stop-color="#1e71a1"
            ></stop>
            <stop
              id="ejzlHNaD2um8-fill-3"
              offset="93.13%"
              stop-color="#377cac"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um12-fill"
            x1="52"
            y1="290.875415"
            x2="424.357"
            y2="290.875415"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um12-fill-0"
              offset="0%"
              stop-color="#444b8c"
            ></stop>
            <stop
              id="ejzlHNaD2um12-fill-1"
              offset="99.64%"
              stop-color="#26264f"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um13-fill"
            x1="167.9103"
            y1="93.354915"
            x2="274.3043"
            y2="324.002015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um13-fill-0"
              offset="0%"
              stop-color="#007dbb"
            ></stop>
            <stop
              id="ejzlHNaD2um13-fill-1"
              offset="100%"
              stop-color="#0bf"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um14-fill"
            x1="302.8188"
            y1="42.047215"
            x2="400.8978"
            y2="254.668915"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um14-fill-0"
              offset="0%"
              stop-color="#ffc444"
            ></stop>
            <stop
              id="ejzlHNaD2um14-fill-1"
              offset="99.64%"
              stop-color="#f36f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um15-fill"
            x1="185.5804"
            y1="85.326115"
            x2="287.4254"
            y2="306.112315"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um15-fill-0"
              offset="0%"
              stop-color="#007dbb"
            ></stop>
            <stop
              id="ejzlHNaD2um15-fill-1"
              offset="100%"
              stop-color="#0bf"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um19-fill"
            x1="347.7048"
            y1="337.765515"
            x2="452.6918"
            y2="248.193515"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um19-fill-0"
              offset="0%"
              stop-color="#444b8c"
            ></stop>
            <stop
              id="ejzlHNaD2um19-fill-1"
              offset="99.64%"
              stop-color="#26264f"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um20-fill"
            x1="434.4402"
            y1="365.355015"
            x2="420.5712"
            y2="-7.521485"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um20-fill-0"
              offset="0%"
              stop-color="#444b8c"
            ></stop>
            <stop
              id="ejzlHNaD2um20-fill-1"
              offset="99.64%"
              stop-color="#26264f"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um22-fill"
            x1="474.4257"
            y1="198.526015"
            x2="420.7447"
            y2="314.900015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um22-fill-0"
              offset="0%"
              stop-color="#007dbb"
            ></stop>
            <stop
              id="ejzlHNaD2um22-fill-1"
              offset="100%"
              stop-color="#0bf"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um23-fill"
            x1="448.8692"
            y1="264.447315"
            x2="400.8552"
            y2="368.535215"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um23-fill-0"
              offset="0%"
              stop-color="#ffc444"
            ></stop>
            <stop
              id="ejzlHNaD2um23-fill-1"
              offset="99.64%"
              stop-color="#f36f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um24-fill"
            x1="450.6191"
            y1="139.462215"
            x2="400.5341"
            y2="248.038215"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um24-fill-0"
              offset="25.87%"
              stop-color="#006393"
            ></stop>
            <stop
              id="ejzlHNaD2um24-fill-1"
              offset="43.03%"
              stop-color="#086797"
            ></stop>
            <stop
              id="ejzlHNaD2um24-fill-2"
              offset="69.78%"
              stop-color="#1e71a1"
            ></stop>
            <stop
              id="ejzlHNaD2um24-fill-3"
              offset="93.13%"
              stop-color="#377cac"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um25-fill"
            x1="443.4859"
            y1="314.587615"
            x2="408.7669"
            y2="389.854615"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um25-fill-0"
              offset="0%"
              stop-color="#007dbb"
            ></stop>
            <stop
              id="ejzlHNaD2um25-fill-1"
              offset="100%"
              stop-color="#0bf"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um26-fill"
            x1="408.9641"
            y1="201.558715"
            x2="363.0391"
            y2="301.118715"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um26-fill-0"
              offset="0%"
              stop-color="#007dbb"
            ></stop>
            <stop
              id="ejzlHNaD2um26-fill-1"
              offset="100%"
              stop-color="#0bf"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um31-fill"
            x1="380.0974"
            y1="401.153515"
            x2="445.4844"
            y2="345.367515"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um31-fill-0"
              offset="0%"
              stop-color="#444b8c"
            ></stop>
            <stop
              id="ejzlHNaD2um31-fill-1"
              offset="99.64%"
              stop-color="#26264f"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um32-fill"
            x1="434.5897"
            y1="424.215015"
            x2="425.1507"
            y2="170.437115"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um32-fill-0"
              offset="0%"
              stop-color="#444b8c"
            ></stop>
            <stop
              id="ejzlHNaD2um32-fill-1"
              offset="99.64%"
              stop-color="#26264f"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um34-fill"
            x1="456.1681"
            y1="314.119615"
            x2="422.0731"
            y2="388.031615"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um34-fill-0"
              offset="0%"
              stop-color="#007dbb"
            ></stop>
            <stop
              id="ejzlHNaD2um34-fill-1"
              offset="100%"
              stop-color="#0bf"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um35-fill"
            x1="442.68"
            y1="361.815615"
            x2="414.638"
            y2="422.606615"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um35-fill-0"
              offset="0%"
              stop-color="#007dbb"
            ></stop>
            <stop
              id="ejzlHNaD2um35-fill-1"
              offset="100%"
              stop-color="#0bf"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um36-fill"
            x1="439.0446"
            y1="395.139415"
            x2="420.0817"
            y2="436.249415"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um36-fill-0"
              offset="0%"
              stop-color="#ffc444"
            ></stop>
            <stop
              id="ejzlHNaD2um36-fill-1"
              offset="99.64%"
              stop-color="#f36f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um37-fill"
            x1="423.0825"
            y1="315.906815"
            x2="393.3895"
            y2="380.277815"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um37-fill-0"
              offset="0%"
              stop-color="#ffc444"
            ></stop>
            <stop
              id="ejzlHNaD2um37-fill-1"
              offset="99.64%"
              stop-color="#f36f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um38-fill"
            x1="443.9482"
            y1="273.646715"
            x2="414.2522"
            y2="338.023715"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um38-fill-0"
              offset="25.87%"
              stop-color="#006393"
            ></stop>
            <stop
              id="ejzlHNaD2um38-fill-1"
              offset="43.03%"
              stop-color="#086797"
            ></stop>
            <stop
              id="ejzlHNaD2um38-fill-2"
              offset="69.78%"
              stop-color="#1e71a1"
            ></stop>
            <stop
              id="ejzlHNaD2um38-fill-3"
              offset="93.13%"
              stop-color="#377cac"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um43-fill"
            x1="116.4504"
            y1="424.607015"
            x2="114.5454"
            y2="462.217015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um43-fill-0"
              offset="0%"
              stop-color="#ff9085"
            ></stop>
            <stop
              id="ejzlHNaD2um43-fill-1"
              offset="100%"
              stop-color="#fb6fbb"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um44-fill"
            x1="116.4504"
            y1="424.607015"
            x2="114.5454"
            y2="462.217015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um44-fill-0"
              offset="0%"
              stop-color="#444b8c"
            ></stop>
            <stop
              id="ejzlHNaD2um44-fill-1"
              offset="99.64%"
              stop-color="#26264f"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um45-fill"
            x1="111.65"
            y1="458.345315"
            x2="222.9728"
            y2="458.345315"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um45-fill-0"
              offset="0%"
              stop-color="#444b8c"
            ></stop>
            <stop
              id="ejzlHNaD2um45-fill-1"
              offset="99.64%"
              stop-color="#26264f"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um46-fill"
            x1="111.65"
            y1="458.605515"
            x2="222.972"
            y2="458.605515"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um46-fill-0"
              offset="0%"
              stop-color="#ff9085"
            ></stop>
            <stop
              id="ejzlHNaD2um46-fill-1"
              offset="100%"
              stop-color="#fb6fbb"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um47-fill"
            x1="143.1723"
            y1="501.661415"
            x2="121.7493"
            y2="474.998415"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um47-fill-0"
              offset="0%"
              stop-color="#aa80f9"
            ></stop>
            <stop
              id="ejzlHNaD2um47-fill-1"
              offset="99.64%"
              stop-color="#6165d7"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um48-fill"
            x1="130.0188"
            y1="495.325915"
            x2="100.5028"
            y2="458.187915"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um48-fill-0"
              offset="0%"
              stop-color="#754a2a"
            ></stop>
            <stop
              id="ejzlHNaD2um48-fill-1"
              offset="31.88%"
              stop-color="#a47745"
            ></stop>
            <stop
              id="ejzlHNaD2um48-fill-2"
              offset="61.27%"
              stop-color="#c99b5b"
            ></stop>
            <stop
              id="ejzlHNaD2um48-fill-3"
              offset="76.79%"
              stop-color="#d7a863"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um49-fill"
            x1="66.4635"
            y1="457.102415"
            x2="41.9402"
            y2="508.118415"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um49-fill-0"
              offset="0%"
              stop-color="#444b8c"
            ></stop>
            <stop
              id="ejzlHNaD2um49-fill-1"
              offset="99.64%"
              stop-color="#26264f"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um50-fill"
            x1="122.4303"
            y1="444.065815"
            x2="76.1065"
            y2="295.657815"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um50-fill-0"
              offset="0%"
              stop-color="#ffc444"
            ></stop>
            <stop
              id="ejzlHNaD2um50-fill-1"
              offset="18.72%"
              stop-color="#febc46"
            ></stop>
            <stop
              id="ejzlHNaD2um50-fill-2"
              offset="48.08%"
              stop-color="#fba54a"
            ></stop>
            <stop
              id="ejzlHNaD2um50-fill-3"
              offset="84.21%"
              stop-color="#f68152"
            ></stop>
            <stop
              id="ejzlHNaD2um50-fill-4"
              offset="100%"
              stop-color="#f36f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um51-fill"
            x1="105.6536"
            y1="374.872915"
            x2="109.2686"
            y2="433.365015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um51-fill-0"
              offset="0%"
              stop-color="#ff9085"
            ></stop>
            <stop
              id="ejzlHNaD2um51-fill-1"
              offset="100%"
              stop-color="#fb6fbb"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um52-fill"
            x1="67.5793"
            y1="452.136015"
            x2="143.743"
            y2="452.136015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um52-fill-0"
              offset="0%"
              stop-color="#aa80f9"
            ></stop>
            <stop
              id="ejzlHNaD2um52-fill-1"
              offset="99.64%"
              stop-color="#6165d7"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um54-fill"
            x1="43.4866"
            y1="427.140715"
            x2="94.6133"
            y2="427.140715"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um54-fill-0"
              offset="0%"
              stop-color="#ffc444"
            ></stop>
            <stop
              id="ejzlHNaD2um54-fill-1"
              offset="99.64%"
              stop-color="#f36f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um55-fill"
            x1="132.049"
            y1="349.388015"
            x2="120.178"
            y2="383.832015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um55-fill-0"
              offset="0%"
              stop-color="#ffc444"
            ></stop>
            <stop
              id="ejzlHNaD2um55-fill-1"
              offset="99.64%"
              stop-color="#f36f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um57-fill"
            x1="142.3125"
            y1="347.112915"
            x2="113.2795"
            y2="395.519915"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um57-fill-0"
              offset="0%"
              stop-color="#754a2a"
            ></stop>
            <stop
              id="ejzlHNaD2um57-fill-1"
              offset="31.88%"
              stop-color="#a47745"
            ></stop>
            <stop
              id="ejzlHNaD2um57-fill-2"
              offset="61.27%"
              stop-color="#c99b5b"
            ></stop>
            <stop
              id="ejzlHNaD2um57-fill-3"
              offset="76.79%"
              stop-color="#d7a863"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um59-fill"
            x1="143.9296"
            y1="355.891315"
            x2="139.3426"
            y2="361.329315"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um59-fill-0"
              offset="0%"
              stop-color="#754a2a"
            ></stop>
            <stop
              id="ejzlHNaD2um59-fill-1"
              offset="31.88%"
              stop-color="#a47745"
            ></stop>
            <stop
              id="ejzlHNaD2um59-fill-2"
              offset="61.27%"
              stop-color="#c99b5b"
            ></stop>
            <stop
              id="ejzlHNaD2um59-fill-3"
              offset="76.79%"
              stop-color="#d7a863"
            ></stop>
          </linearGradient>
          <linearGradient
            id="ejzlHNaD2um62-fill"
            x1="89.948"
            y1="435.103515"
            x2="176.09"
            y2="435.103515"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="ejzlHNaD2um62-fill-0"
              offset="49.4%"
              stop-color="#ebeff2"
            ></stop>
            <stop
              id="ejzlHNaD2um62-fill-1"
              offset="100%"
              stop-color="#fff"
            ></stop>
          </linearGradient>
        </defs>
        <ellipse
          rx="101"
          ry="23.5"
          transform="translate(131 493.5)"
          fill="#88a9c7"
          fill-opacity="0.5"
        ></ellipse>
        <g>
          <path
            d="M296.5,353c-6.4-2.1-11.2-7.5-12.5-14.2l-9.3-46-79.6,2.8-9.8,48c-1.4,6.7,4.1,13.5,10.4,15.9l33.3,13c6.2,2.4,12.9,3.4,19.5,2.8l70.4-6c2.1-.2,3.7-1.8,3.9-3.9s1.7-4.4-.3-5.1l-26-7.3Z"
            fill="#97a6b7"
          ></path>
          <path
            d="M297.2,350.2c-6.4-2.1-11.2-7.4-12.5-14l-9.3-45.5-77.7,2.8-9.8,47.4c-1.4,6.6,2.3,13.3,8.6,15.7l33.3,12.9c6.2,2.4,12.8,3.3,19.4,2.8l70.3-5.9c2.1-.2,3.7-1.8,3.9-3.8s-1-4-3-4.6l-23.2-7.8Z"
            fill="url(#ejzlHNaD2um5-fill)"
          ></path>
          <path
            d="M424.4,260.7v-206.4c0-4.2-3.5-7.8-7.8-8L61.8,32c-5.4-.2-9.8,3.5-9.8,8.3v228.2l372.4-7.8Z"
            fill="url(#ejzlHNaD2um6-fill)"
          ></path>
          <path
            d="M63,72.1v178.2c0,4.4,4.1,8,9.1,7.8l336.7-9.6c4-.1,7.3-3.5,7.3-7.4v-159L63,72.1Z"
            fill="#fff"
          ></path>
          <path
            d="M416.1,82.2v-18.3c0-3.9-3.2-7.2-7.1-7.3L71.9,44C67,43.8,63,47.3,63,51.6v20.5L416.1,82.2Z"
            fill="url(#ejzlHNaD2um8-fill)"
          ></path>
          <path
            d="M87.6,58.2c0,1.5-1.4,2.7-3.1,2.7-1.7-.1-3.1-1.3-3.1-2.9c0-1.5,1.4-2.7,3.1-2.7c1.7.1,3.1,1.4,3.1,2.9Z"
            fill="#9d3060"
          ></path>
          <path
            d="M101.1,58.6c0,1.5-1.4,2.7-3.1,2.7-1.7-.1-3.1-1.3-3.1-2.9s1.4-2.7,3.1-2.7c1.7.1,3.1,1.4,3.1,2.9Z"
            fill="#9d3060"
          ></path>
          <path
            d="M114.4,59.1c0,1.5-1.4,2.7-3,2.6-1.7-.1-3.1-1.3-3.1-2.8s1.4-2.7,3.1-2.6c1.6,0,3,1.2,3,2.8Z"
            fill="#9d3060"
          ></path>
          <path
            d="M52,268.5v45.5c0,4.8,4.4,8.5,9.8,8.3l354.8-15c4.3-.2,7.8-3.8,7.8-8v-39.9L52,268.5Z"
            fill="url(#ejzlHNaD2um12-fill)"
          ></path>
          <path
            d="M284.4,171.9L88.8,173v-81.7l195.6,3.6v77Z"
            fill="url(#ejzlHNaD2um13-fill)"
          ></path>
          <path
            d="M394.9,171.3l-99,.5v-76.7l99,1.8v74.4Z"
            opacity="0.8"
            fill="url(#ejzlHNaD2um14-fill)"
          ></path>
          <path
            d="M394.9,226.6l-306.1,7v-50L394.9,181v45.6Z"
            fill="url(#ejzlHNaD2um15-fill)"
          ></path>
          <g opacity="0.48">
            <path
              d="M385.9,234.1c-29.1-9.8-53.2,10.2-74.2-5.6-23.6-17.8-4.5-52-26.4-72.2-30.3-28-91,15.4-117.3-11-18.1-18.2,3.3-47.2-12.1-77.8-7.8-15.5-22.4-26.1-40.1-33.4l-54-2.2c-5.4-.2-9.8,3.5-9.8,8.3v228.2v45.6c0,4.8,4.4,8.5,9.8,8.3l354.8-15c4.3-.2,7.8-3.8,7.8-8v-29.5c-12.5-22.1-26.3-31.6-38.5-35.7Z"
              opacity="0.48"
              fill="#fff"
            ></path>
          </g>
        </g>
        <g id="ejzlHNaD2um18" transform="translate(0 50)">
          <path
            d="M361.5,155.4v201.7c0,3,3.3,3.9,7,4.3L498,375.9l2,.2c4.1.4,7.4-1.9,7.4-5v-210.8c0-3.2-3.3-5.8-7.4-6l-2.7-.1-129.9.4c-3.7,0-5.9-2.2-5.9.8Z"
            fill="url(#ejzlHNaD2um19-fill)"
          ></path>
          <path
            d="M357.6,155.2v201.6c0,3,3.1,5.8,6.8,6.1L496,375.8c4.1.4,7.4-1.9,7.4-5v-210.7c0-3.2-3.3-5.8-7.4-6L364.3,150c-3.7-.2-6.7,2.2-6.7,5.2Z"
            fill="url(#ejzlHNaD2um20-fill)"
          ></path>
          <path
            d="M493.1,166l-125.5-4.4c-3.2-.1-5.8,2.4-5.9,5.6l-.2,185.1c0,3.5,2.6,6.4,6.1,6.7l125.1,12.1c3.2.3,6-2.2,6-5.4v-194c.1-3-2.4-5.6-5.6-5.7Z"
            fill="#fff"
          ></path>
          <path
            d="M488.9,212.2l-71.4-3.4-.1,66.9l71.4,4.9.1-68.4Z"
            fill="url(#ejzlHNaD2um22-fill)"
          ></path>
          <path
            d="M488.8,289.1l-118.1-8.4v40.2l118.1,9.9v-41.7Z"
            opacity="0.8"
            fill="url(#ejzlHNaD2um23-fill)"
          ></path>
          <path
            d="M492.6,165.9l-124.9-4.3c-3.2-.1-5.9,2.5-5.9,5.7v27.5l137,6.1v-28.8c0-3.3-2.7-6.1-6.2-6.2Z"
            fill="url(#ejzlHNaD2um24-fill)"
          ></path>
          <path
            d="M488.8,340L370.7,329.7v18.1l118.1,10.9v-18.7Z"
            fill="url(#ejzlHNaD2um25-fill)"
          ></path>
          <path
            d="M410.9,208.4l-40-1.9-.1,65.9l40,2.8.1-66.8Z"
            fill="url(#ejzlHNaD2um26-fill)"
          ></path>
          <path
            d="M426.4,158.1c0,1.1,1.1,2,2.4,2c1.4,0,2.4-.8,2.4-1.9s-1.1-2-2.4-2-2.4.9-2.4,1.9Z"
            fill="#de8bdf"
          ></path>
          <g opacity="0.48">
            <path
              d="M400.1,271.2c12-24,40-18.3,50.5-44.5c6.3-15.6-1.5-23,3.2-42.1c3.2-12.9,10.1-23.2,18-31.3l24.2.8c4.1.1,7.4,2.8,7.4,6v210.7c0,3.1-3.3,5.4-7.4,5L390,365.5c7.8-10.9,11.2-21.1,12.4-30c3.8-27.7-12.9-42.9-2.3-64.3Z"
              opacity="0.48"
              fill="#fff"
            ></path>
          </g>
        </g>
        <g id="ejzlHNaD2um30" transform="translate(1.45 -203.116901)">
          <path
            d="M394.9,281.1v139.2c0,2.1,1.8,2.7,3.8,2.9l69.5,8.6l1.1.1c2.2.2,3.9-1.4,3.9-3.5v-144.9c0-2.2-1.8-4-3.9-4.1h-1.4l-69.7,1.1c-2.1-.1-3.3-1.5-3.3.6Z"
            fill="url(#ejzlHNaD2um31-fill)"
          ></path>
          <path
            d="M392.8,280.9v139.2c0,2.1,1.6,4,3.7,4.2l70.7,7.4c2.2.2,3.9-1.4,3.9-3.5v-144.8c0-2.2-1.8-4-3.9-4.1l-70.7-2.1c-2.1,0-3.7,1.6-3.7,3.7Z"
            fill="url(#ejzlHNaD2um32-fill)"
          ></path>
          <path
            d="M464.7,287.5l-61.8-2.3c-4.3-.2-7.9,3.3-7.9,7.6v124.7c0,2,1.5,3.8,3.6,4l66.2,7c2,.2,3.8-1.4,3.8-3.4v-133.6c0-2.1-1.7-3.9-3.9-4Z"
            fill="#fff"
          ></path>
          <path
            d="M460.9,320.7l-36.4-1.9v46.4l36.4,2.8v-47.3Z"
            fill="url(#ejzlHNaD2um34-fill)"
          ></path>
          <path
            d="M460.9,373.9l-58.7-4.6v27.9l58.7,5.4v-28.7Z"
            fill="url(#ejzlHNaD2um35-fill)"
          ></path>
          <path
            d="M460.9,407.7l-58.7-5.6v12.6l58.7,6v-13Z"
            opacity="0.8"
            fill="url(#ejzlHNaD2um36-fill)"
          ></path>
          <path
            d="M420.6,318.6l-18.3-1v45.8l18.3,1.4v-46.2Z"
            opacity="0.8"
            fill="url(#ejzlHNaD2um37-fill)"
          ></path>
          <path
            d="M465,287.5l-66-2.4c-2.2-.1-4,1.7-4,3.8v19.3l73.5,3.6v-20.6c.1-2-1.5-3.6-3.5-3.7Z"
            fill="url(#ejzlHNaD2um38-fill)"
          ></path>
          <path
            d="M429.8,282.5c0,.7.6,1.4,1.3,1.4s1.3-.6,1.3-1.3-.6-1.4-1.3-1.4-1.3.6-1.3,1.3Z"
            fill="#de8bdf"
          ></path>
          <g opacity="0.48">
            <path
              d="M415.7,364.3c6.4-16.2,23.8-12.4,29.2-28.8c4.4-13.4-5.4-21.2-1.6-36.7c2.2-8.7,7.5-15.1,12.9-19.8l10.8.3c2.2.1,4,1.9,4,4.1v144.7c0,2.2-1.8,3.7-4,3.5l-54.2-5.7c4.3-6.9,6.1-13.1,6.7-18.3c2.4-18.9-9.7-28.5-3.8-43.3Z"
              opacity="0.48"
              fill="#fff"
            ></path>
          </g>
        </g>
        <g id="ejzlHNaD2um42" transform="translate(0 -97.334082)">
          <path
            d="M114.4,453.5l88.9-22.2l4.4,21.6-81.3,37.5L62,474.5L23.1,436.4l91.3,17.1Z"
            fill="url(#ejzlHNaD2um43-fill)"
          ></path>
          <path
            d="M114.4,453.5l88.9-22.2l4.4,21.6-81.3,37.5L62,474.5L23.1,436.4l91.3,17.1Z"
            fill="url(#ejzlHNaD2um44-fill)"
          ></path>
          <path
            d="M111.7,483.3c17.7-9.5,30.7-19.1,39.6-26.4c17.4-14.3,25.4-25.2,43-29.4c3.2-.8,21.1-5.1,26.9,2.9c6.4,8.8-6.1,27.3-7.6,29.5-22.5,33.3-79.9,31.8-97.9,30.7-1.4-2.4-2.7-4.8-4-7.3Z"
            fill="url(#ejzlHNaD2um45-fill)"
          ></path>
          <path
            d="M221.2,430.4c-1.7-2.3-4.4-3.6-7.5-4.2c1,3.6,1.1,6.9-.2,9.8-3.5,8-13.9,5.5-21.3,14.9-7.2,9.2-2.2,18.1-8.6,21.9-7.3,4.4-16.3-5.8-24.1-1-5.3,3.3-3.7,9.6-9.8,13-4.3,2.4-8.5,1.2-13.3.6-5.6-.7-13.2-.9-22.8,1.2.7,1.3,1.5,2.6,2.2,3.9c18,1.1,75.4,2.6,97.9-30.7c1.4-2.1,13.9-20.6,7.5-29.4Z"
            opacity="0.3"
            fill="url(#ejzlHNaD2um46-fill)"
          ></path>
          <path
            d="M125,488.3c0,0,3.2-2.3,7.9-.7s12.2,2.9,12.1,5.2c0,2.3-7.5,3.7-12.6,2.8-4.9-1-8.8-5.2-7.4-7.3Z"
            fill="url(#ejzlHNaD2um47-fill)"
          ></path>
          <path
            d="M120.3,484.4l8.6,2.8c0,0,1.4,2.3-.5,3.8s-8.5-1.7-8.5-1.7l.4-4.9Z"
            fill="url(#ejzlHNaD2um48-fill)"
          ></path>
          <path
            d="M0.1,444.6c-2.6,14.9,29.5,30.9,36.9,34.6c15,7.5,43.1,18.1,84.9,14c.6-3.3,1.1-6.7,1.7-10-5.5-1.9-13.7-5-23.2-9.6-16.2-8-22.3-13.7-44.8-27.2-24.9-15-32.1-17-39.5-15.1-6.4,1.5-14.8,6.4-16,13.3Z"
            fill="url(#ejzlHNaD2um49-fill)"
          ></path>
          <path
            d="M43.9,419.2c-4.8-17.5,3.2-41.1,20-52.5c13.7-9.3,29.9-8.7,34.9-8.5c5.5.2,4.9.9,22.3,4.1c24,4.4,26.3,3.4,33.3,6.7c13.2,6.3,19.5,17.8,20.7,20c6.1,11.4,11.1,32.6-.5,43-6.6,5.9-15.9,5.9-21.1,5.3-.4-2.3-.7-4.7-1.1-7c1.1-.8,7.3-5.8,8.1-14.7.9-10.2-6.2-16.7-7-17.4-2.5,4.9-5.6,12.3-7,21.7-1.8,12.2.1,22.2,1.6,28.1-6.4,3.5-16.9,8.2-30.6,9.7-10.3,1.1-23.1,2.6-31.3-3.8-11.5-9-12.5-32.5-1.1-56.9-4.2,2.8-10.2,7.8-12.7,15.8-.4,1.2-1,3.6-1.3,12.8-.4,16.5-22.2,11.7-27.2-6.4Z"
            fill="url(#ejzlHNaD2um50-fill)"
          ></path>
          <path
            d="M122,431.3c-5.8.7-7.4,6.7-12.8,6.4-11.7-.8-21.1-21-16.2-32.1c3.2-7.2,11.6-8.5,11.1-13.2-.5-3.8-6.3-6.7-11.1-6.8-7.4-.2-12.3,6.2-14,8.5-4,5.3-4.9,11.1-5,14.9c2.9-5.8,7.6-9.7,11.1-12-11.4,24.4-10.4,47.9,1.1,56.9c8.2,6.4,20.9,5,31.3,3.8c11.1-1.2,20.1-4.6,26.5-7.7-6.2-10.1-14.1-19.7-22-18.7Z"
            opacity="0.3"
            fill="url(#ejzlHNaD2um51-fill)"
          ></path>
          <path
            d="M143.5,448.4l-76-1.1L87.7,457l56-3.7-.2-4.9Z"
            fill="url(#ejzlHNaD2um52-fill)"
          ></path>
          <path
            d="M64.9,449.3c0,1.4.9,2.7,2.2,3.2L91.4,462l.1-6.6-23.9-8.1c-1.4-.4-2.7.6-2.7,2Z"
            fill="#d8dee8"
          ></path>
          <path
            d="M46.1,405.9c5.2-5,18.1-1.8,22.9,5.3c3.5,5.2.6,9.6,3.2,16.2c3.2,8.1,15.1,15.4,22.4,17.8-1.1,2.5-.8,3.1-2,5.6C88,450.2,54.3,445,46,425c-.4-1-5.5-13.8.1-19.1Z"
            fill="url(#ejzlHNaD2um54-fill)"
          ></path>
          <path
            d="M137.2,347.7c0,0-7.8,9.6-14.7,11s-17.1.2-18.8,1.2s9.1,4.1,17.5,5.8c8.5,1.8,23.9,5.4,28,4.1s-5-6.1-8.5-7.3c-3.6-1.2.9-8.8.9-8.8l-4.4-6Z"
            fill="url(#ejzlHNaD2um55-fill)"
          ></path>
          <path
            d="M150,352.1c3-1,5.5-4.6,5.3-8.2-.2-3-2.5-5-3.8-6.1-3.9-3.4-10.5-5.2-15-2.5-2.7,1.6-3.5,8-3.7,9.2-.2,1.3.3,2.1.5,3.4.3,1.7.7,4.4,1.4,8.8.7-.7,1.7-1.5,2.9-2.2.5-.3,2.3-1.4,4.3-2c4-1.4,5.2.6,8.1-.4Z"
            fill="#0c1833"
          ></path>
          <path
            d="M132.5,351.9c0,0-.3,6.5,5.5,9.9c5.2,3,13.5-7.7,11.9-12.2-.5-1.5-2.4-3.3-4.5-4-1.2-.4-1.9-.2-3.8-.9-1.8-.6-2.1-1.1-2.9-1-.9.1-2.2,1-3.4,5-.3-1.6-1.2-2.8-2.3-2.9-.2,0-.7,0-1.1.2-1.5.7-1.4,3.1.6,5.9Z"
            fill="url(#ejzlHNaD2um57-fill)"
          ></path>
          <path
            d="M139,342.8c.1.3-1.2.7-2.1,2-1.8,2.4-1.3,7.1,1.1,9c1.4,1.2,2.4.5,5.1,2c1.7.9,2.8,1.9,3.5,2.7-1,3.2-3.6,5.4-6.3,5.5-2.9,0-4.8-2.5-5.6-3.5-.5-.7-2.3-2.9-2.1-5.8.2-3,2.3-3.4,1.9-5.8-.2-1.2-.9-2.1-.9-4.2c0-.5.1-1,.1-1.3c2.2-.5,5.1-1.1,5.3-.6Z"
            fill="#0c1833"
          ></path>
          <path
            d="M144.1,358.2c0,0-1.5-.2-3.3-1.5-.1-.1-.2-.1-.2-.1-.2.1.5,1.7,1.8,2.2.9.3,2,0,2.1-.3-.1-.1-.2-.2-.4-.3Z"
            fill="url(#ejzlHNaD2um59-fill)"
          ></path>
          <path
            d="M93.5,409.1l-5.4,48.8c-.2,1.7,2.2,4.2,3.9,4.1l78.6-4.8c1.3-.1,2.4-1,2.6-2.3l9.9-47.1c.3-1.7-2.8-3.5-4.5-3.4l-82.3,2.3c-1.5,0-2.6,1-2.8,2.4Z"
            fill="#d8dee8"
          ></path>
          <path
            d="M95.3,410.5L90,459.3c-.2,1.7,1.2,3.1,2.9,3.1l79.6-3.8c1.3-.1,2.4-1,2.6-2.3l8.1-47.3c.3-1.7-1.1-3.3-2.8-3.2L98,408c-1.4.1-2.5,1.1-2.7,2.5Z"
            fill="#fff"
          ></path>
          <path
            d="M142,447.1c-6.9-2.9-4.5-8.6-12.7-15.6-10.8-9.2-21.9-5.2-25.7-12.4-1.2-2.2-1.8-5.7.3-11.3l-6,.2c-1.4,0-2.5,1.1-2.7,2.5L90,459.3c-.2,1.7,1.2,3.1,2.9,3.1l79.6-3.8c1.3-.1,2.4-1,2.6-2.3l1.1-6.5c-2.4-1.3-5.3-2.6-8.7-3.2-11.7-2.5-17.5,3.9-25.5.5Z"
            fill="url(#ejzlHNaD2um62-fill)"
          ></path>
        </g>
      </svg>
    </div>
  );
};
