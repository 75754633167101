interface UxSVGProps {
  classes: any;
}

export const QualitySVG = ({ classes }: UxSVGProps) => {
  return (
    <div className={`${classes}`}>
      <svg
        id="efDeGdW83Eu1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 767 749"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
      >
        <defs>
          <rect
            id="efDeGdW83Eu2"
            width="65.5"
            height="38.2"
            rx="0"
            ry="0"
          ></rect>
          <rect
            id="efDeGdW83Eu3"
            width="42.3"
            height="24.6"
            rx="0"
            ry="0"
          ></rect>
          <rect
            id="efDeGdW83Eu4"
            width="272.5"
            height="292.5"
            rx="0"
            ry="0"
          ></rect>
          <linearGradient
            id="efDeGdW83Eu28-fill"
            x1="-57.6469"
            y1="21499.4746"
            x2="-9.5475"
            y2="18016.6738"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="efDeGdW83Eu28-fill-0"
              offset="0%"
              stopColor="#e8edfe"
            ></stop>
            <stop
              id="efDeGdW83Eu28-fill-1"
              offset="100%"
              stopColor="#eef4ff"
            ></stop>
          </linearGradient>
          <linearGradient
            id="efDeGdW83Eu135-fill"
            x1="693.7814"
            y1="261.7574"
            x2="663.2064"
            y2="49.8085"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="efDeGdW83Eu135-fill-0"
              offset="0%"
              stopColor="#2e3bbb"
            ></stop>
            <stop
              id="efDeGdW83Eu135-fill-1"
              offset="100%"
              stopColor="#7258d8"
            ></stop>
          </linearGradient>
          <linearGradient
            id="efDeGdW83Eu136-fill"
            x1="11742.5342"
            y1="25694.9531"
            x2="7621.7344"
            y2="16169.4531"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="efDeGdW83Eu136-fill-0"
              offset="0%"
              stopColor="#2e3bbb"
            ></stop>
            <stop
              id="efDeGdW83Eu136-fill-1"
              offset="100%"
              stopColor="#7258d8"
            ></stop>
          </linearGradient>
          <linearGradient
            id="efDeGdW83Eu137-fill"
            x1="32277.6641"
            y1="8320.3169"
            x2="36870.9648"
            y2="9507.5156"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="efDeGdW83Eu137-fill-0"
              offset="0%"
              stopColor="#9d9ae7"
            ></stop>
            <stop
              id="efDeGdW83Eu137-fill-1"
              offset="100%"
              stopColor="#eef4ff"
            ></stop>
          </linearGradient>
          <linearGradient
            id="efDeGdW83Eu157-fill"
            x1="650.597"
            y1="141.2845"
            x2="687.0101"
            y2="150.6479"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="efDeGdW83Eu157-fill-0"
              offset="0%"
              stopColor="#27a7f9"
            ></stop>
            <stop
              id="efDeGdW83Eu157-fill-1"
              offset="100%"
              stopColor="#4fccf9"
            ></stop>
          </linearGradient>
          <linearGradient
            id="efDeGdW83Eu164-fill"
            x1="371.1232"
            y1="591.077"
            x2="904.8162"
            y2="780.035"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="efDeGdW83Eu164-fill-0"
              offset="0%"
              stopColor="#000"
            ></stop>
            <stop
              id="efDeGdW83Eu164-fill-1"
              offset="63.05%"
              stopColor="rgba(0,0,0,0)"
            ></stop>
          </linearGradient>
        </defs>
        <g opacity="0.8">
          <path
            d="M16.8,167.4L312.7,5.2c.8-.5,1.7-.9,2.6-.9.9-.1,1.9.1,2.7.5.8.6,1.4,1.3,1.8,2.2s.5,1.8.4,2.8L321,171c-.1,2.8-1,5.6-2.4,8.1s-3.5,4.5-5.9,6L16.9,347.1c-.8.6-1.7.9-2.6,1s-1.9-.1-2.8-.5c-.8-.6-1.4-1.3-1.8-2.2s-.5-1.8-.4-2.8l-.9-161c.1-2.9.9-5.6,2.4-8.1c1.4-2.5,3.5-4.6,6-6.1Z"
            opacity="0.8"
            fill="#70d0d7"
          ></path>
          <path
            d="M220.2,55.4l-86,228.7l125.3-68.8L321.4,51.8L321.2,0l-101,55.4Z"
            opacity="0.14"
            fill="#fff"
          ></path>
          <path
            d="M87.4,309.8l86-228.7-76.9,42.1L9.8,352.3l77.6-42.5Z"
            opacity="0.14"
            fill="#fff"
          ></path>
        </g>
        <path
          d="M8.2,182.9L304.1,20.8c.8-.5,1.7-.9,2.6-.9.9-.1,1.9.1,2.7.5.8.6,1.4,1.3,1.8,2.2s.5,1.8.4,2.8l.8,161.2c-.1,2.9-.9,5.6-2.4,8.1-1.4,2.5-3.4,4.5-5.9,6L8.4,362.7c-.7.6-1.7,1-2.6,1.1-1,.1-1.9-.1-2.8-.6-.8-.5-1.5-1.2-1.9-2.1s-.5-1.9-.3-2.8L0,197c.2-2.8,1-5.6,2.4-8s3.4-4.5,5.9-6h-.1Z"
          fill="#087cb7"
        ></path>
        <path
          d="M211.5,71l-86,228.6l125.4-68.8L312.8,67.2l-.3-51.8L211.5,71Z"
          opacity="0.14"
          fill="#fff"
        ></path>
        <path
          d="M78.7,325.3l86-228.8-76.9,42.3L1.1,367.9l77.6-42.6Z"
          opacity="0.14"
          fill="#fff"
        ></path>
        <g id="efDeGdW83Eu12" transform="translate(0.000001 0.000001)">
          <g opacity="0.35">
            <g opacity="0.35">
              <path
                d="M183.9,155.5c-2.2,1.4-4,3.3-5.4,5.6-1.3,2.3-2.1,4.8-2.2,7.4l.1,30.4c0,4.8,3.4,6.7,7.6,4.3l94.8-54.7c2.2-1.4,4-3.3,5.3-5.6s2-4.8,2.2-7.4v-30.4c0-4.8-3.4-6.7-7.6-4.3l-94.8,54.7Z"
                fill="#087cb7"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M255.6,128.9c-.6,1-1,2.2-1,3.4v12.3c0,.4,0,.9.2,1.3s.5.7.8,1c.4.2.8.3,1.2.3s.8-.2,1.2-.4l10.7-6.2c1-.6,1.9-1.6,2.4-2.6.6-1,1-2.2,1-3.4v-12.3c0-.4,0-.9-.2-1.3s-.5-.7-.8-1c-.4-.2-.8-.3-1.2-.2-.4,0-.8.2-1.2.5l-10.8,6.2c-1,.5-1.8,1.4-2.3,2.4Zm13.4,4.2l-9.7-3.7l8.8-5.1c.5-.3.7-.4.8-.3s0,.3,0,.9v8.2h.1ZM257.7,142v-8.3l9.7,3.7-8.8,5.1c-.5.3-.7.4-.8.4c0-.3,0-.6-.1-.9"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M232.1,149.2c0,.3,0,.6.1.9s.2.6.4.8c-.3.7-.5,1.5-.5,2.4v4.4c-.1.4,0,.9.2,1.2.2.4.5.7.9.9s.8.3,1.2.2c.4,0,.8-.2,1.2-.5l10.8-6.2c1-.6,1.8-1.5,2.4-2.5s1-2.2,1-3.3v-4.4c0-.6-.2-1.2-.5-1.7.1-.4.3-.8.4-1.2s.1-.8.2-1.2v-4c.1-.4,0-.9-.2-1.2-.2-.4-.5-.7-.9-.9s-.8-.3-1.2-.2c-.4,0-.8.2-1.2.5l-10.8,6.2c-.9.5-1.7,1.3-2.2,2.2-.6.9-1,1.9-1.2,3l-.1,4.6Zm3.3,5.9v-2.8c0-.3,0-.6.1-.9.2-.2.5-.4.8-.5l9.5-5.4c.5-.3.7-.4.8-.4c0,.3,0,.6,0,.8v2.8c0,.3,0,.6,0,.9-.2.2-.5.4-.8.6l-9.5,5.4c-.5.3-.7.4-.8.3-.1,0,0-.2-.1-.8v0Zm.1-8.2v-2.7c-.1-.3-.1-.6,0-.9.2-.2.5-.4.8-.6l9.5-5.4c.2-.2.5-.3.8-.3c0,.3,0,.6,0,.8v2.7c.1.2.1.3.1.5s-.1.3-.2.5c-.1.1-.2.3-.3.4s-.3.2-.4.2l-9.5,5.4c-.5.3-.7.4-.8.3-.1,0,0-.3-.1-.8"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M227.4,147.9l-3.3,1.9v3.7l3.2-1.9v-3.7Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M227.4,164.5v-3.7l-3.2,1.9v3.7l3.2-1.9Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M212,152.9l.1,20.4l3.3-1.9v-15.1l1.4,1.2l4.3-2.5-5.4-4.3-3.7,2.2Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M190.4,165.3v3.7l13.5-7.8c.2-.2.5-.3.8-.3c0,0,.1.3.1.9v2.4c.1.3.1.6,0,.9-.2.2-.5.4-.8.5l-10.1,5.8c-1,.6-1.8,1.5-2.4,2.5s-1,2.2-1,3.3v4.4c-.1.4,0,.9.2,1.2.2.4.5.7.8,1c.4.2.8.3,1.2.2.4,0,.8-.2,1.2-.5l10.8-6.2c1-.6,1.8-1.5,2.4-2.5s.9-2.2,1-3.4v-1.3l-3.2,1.9v.5c0,.3,0,.6-.1.9-.2.2-.5.4-.8.6l-9.5,5.4c-.5.3-.7.4-.8.3-.1-.3-.1-.6,0-.8v-2.8c0-.3,0-.6.1-.9.2-.2.5-.4.8-.5l13.5-7.8v-11.9l-17.7,10.3Z"
                fill="#c0c0ed"
              ></path>
            </g>
          </g>
          <path
            d="M189.6,161.2c-2.2,1.4-4,3.4-5.4,5.7-1.3,2.3-2.1,4.9-2.2,7.5l.1,30.9c0,4.8,3.4,6.8,7.6,4.4l95-55.6c2.2-1.4,4-3.4,5.3-5.7s2.1-4.9,2.2-7.5v-30.9c0-4.8-3.4-6.8-7.6-4.4l-95,55.6Z"
            fill="url(#efDeGdW83Eu28-fill)"
          ></path>
          <g>
            <path
              d="M215.7,160.5l-10.6,6.4c-.3.1-.7.5-1.1.9s-.8,1-1.2,1.6c-.3.6-.6,1.3-.8,1.9s-.2,1.1-.2,1.4v11.7c0,1.1.4,1.8,1,2.1.5.4,1.3.3,2.4-.3l10.5-6.3c.3-.1.7-.5,1.2-.9.4-.5.8-1.1,1.2-1.7.3-.6.6-1.3.8-1.9s.2-1.1.2-1.4v-11.7c0-1.1-.4-1.8-1-2.1s-1.4-.3-2.4.3ZM205,179.3v-7.8c0-.5-.1-.7,0-.8s.3-.3.8-.6l8.6-5.2-9.5,14.5.1-.1Zm10.8-4.2c0,.2,0,.5,0,.8-.1.1-.3.3-.8.5l-8.6,5.2l9.4-14.5v7.8"
              fill="#c1c5f4"
            ></path>
            <path
              d="M240,150c-.2-.9-.6-1.4-1.2-1.6-.5-.3-1.3-.2-2.1.3l-10.5,5.9c-.3.1-.7.3-1.1.8-.4.4-.8,1-1.2,1.7s-.7,1.3-.8,1.9c-.2.6-.2,1.1-.2,1.5v4.1c0,.4.1.7.1,1c.1.3.2.6.4.8-.3.9-.5,1.7-.5,2.4v4.5c0,1.2.3,2,1,2.3.5.4,1.3.4,2.3-.1l10.5-5.9c.3-.1.7-.3,1.1-.8.4-.4.8-1,1.2-1.7.4-.6.7-1.3.8-1.9.2-.6.2-1.1.2-1.5v-4.7c0-.8-.2-1.4-.5-1.8.2-.4.3-.9.4-1.3s.1-.8.1-1.1v-4.8Zm-3.3,14.6c0,.5.1.7,0,.8s-.3.3-.8.5l-9.2,5.3c-.3.2-.5.3-.8.3c0-.3,0-.6,0-1v-2.9c0-.2,0-.5,0-.8.1-.1.3-.3.8-.5l9.2-5.3c.3-.2.5-.3.7-.3.1.2.1.5.1.9v3v0Zm.2-8.6c-.1.6,0,.9-.1,1s-.3.3-.8.5l-9.2,5.3c-.2.2-.3.3-.4.3-.1.1-.2.1-.3,0-.1,0-.1-.1-.1-.3c0-.1,0-.3.1-.5v-2.8c0-.2,0-.5,0-.8.3-.3.5-.4.8-.5l9.2-5.3c.3-.2.5-.3.8-.3c0,.3,0,.6,0,1v2.8"
              fill="#c1c5f4"
            ></path>
            <path
              d="M248.4,142.2l-4.6,9.5l3.6-1.8l1.2-2.5v14.8l2.8-1.4v-20.1l-3,1.5Z"
              fill="#c1c5f4"
            ></path>
            <path
              d="M255.2,138.2v12.3l13.1-7.2c.3-.2.5-.3.7-.3.1.2.1.5.1.9v2.9c.1.2.1.5,0,.9-.1.1-.3.3-.8.5l-9.2,5.1c-.3.2-.5.3-.7.3-.1-.2-.1-.5-.1-.9v-.6l-3.1,1.7v1.4c.1,1.2.4,2,1,2.3.6.4,1.4.4,2.3-.1l10.5-5.8c.3-.1.7-.4,1.1-.8s.8-1,1.2-1.6c.3-.6.6-1.3.8-1.9s.2-1.1.2-1.5v-4.6c0-1.2-.4-2-1-2.3-.5-.4-1.3-.4-2.3.1l-9.8,5.4c-.3.2-.5.3-.8.3-.1-.3-.1-.6,0-1v-2.5c0-.6.1-1,.1-1c.3-.3.5-.4.7-.5l13.1-7.2v-3.8l-17.1,9.5Z"
              fill="#c1c5f4"
            ></path>
          </g>
        </g>
        <g id="efDeGdW83Eu34" transform="translate(-17.750002 4.371946)">
          <g>
            <path
              d="M280.2,56.6c1.7-1,3.1-.2,3.1,1.8v23.2c-.1,1.1-.4,2.1-.9,3s-1.3,1.7-2.2,2.3l-49.8,28.7c-1.8,1-3.1.2-3.1-1.8v-23.2c0-1.1.3-2.1.9-3c.5-.9,1.3-1.7,2.2-2.3l49.8-28.7ZM237.5,99.9c.9-.6,1.6-1.4,2.2-2.3.5-.9.8-2,.9-3c0-1.9-1.4-2.7-3.1-1.8-.9.6-1.6,1.4-2.1,2.3s-.8,2-.9,3c0,1.9,1.3,2.7,3,1.8Zm34.2-19.8c.9-.6,1.6-1.3,2.1-2.2s.8-1.9.9-3c0-1.9-1.4-2.7-3-1.8-.9.6-1.6,1.4-2.2,2.3-.5.9-.8,2-.9,3c.1,2,1.4,2.7,3.1,1.7v0ZM249,93.2c.9-.6,1.6-1.4,2.2-2.3.5-.9.8-2,.9-3c0-1.9-1.4-2.7-3-1.8-.9.6-1.6,1.4-2.1,2.3s-.8,2-.9,3c-.1,2,1.3,2.8,2.9,1.8Zm11.3-6.4c.9-.6,1.6-1.4,2.2-2.3.5-.9.8-2,.9-3c0-1.9-1.4-2.7-3-1.8-.9.6-1.6,1.4-2.2,2.3-.5.9-.8,2-.9,3-.1,1.9,1.3,2.7,3,1.8Z"
              fill="#3aafd2"
            ></path>
            <path
              d="M288.7,56.9c1.8-1,3.3-.2,3.3,1.9v24.9c-.1,1.1-.4,2.2-1,3.2s-1.4,1.8-2.3,2.4l-53.6,30.8c-1.9,1-3.3.2-3.3-1.9v-24.9c.1-1.1.4-2.2.9-3.2.6-1,1.4-1.8,2.3-2.4l53.7-30.8Zm-45.9,46.3c1-.6,1.8-1.5,2.3-2.4.6-1,.9-2.1.9-3.2c0-2.1-1.5-2.9-3.3-1.9-1,.6-1.7,1.5-2.3,2.4-.6,1-.9,2.1-.9,3.2c0,2.1,1.5,2.9,3.3,1.9Zm36.8-21.1c.9-.6,1.7-1.4,2.3-2.4s.9-2.1,1-3.2c0-2.1-1.5-2.9-3.3-1.9-1,.6-1.8,1.5-2.3,2.4-.6,1-.9,2.1-.9,3.2-.1,2.1,1.4,2.9,3.2,1.9v0Zm-24.4,14c1-.6,1.8-1.5,2.3-2.4.6-1,.9-2.1.9-3.2c0-2-1.5-2.9-3.3-1.9-1,.6-1.7,1.5-2.3,2.4-.6,1-.9,2.1-.9,3.2c0,2.1,1.5,2.9,3.3,1.9Zm12.1-6.9c1-.6,1.8-1.5,2.3-2.4.6-1,.9-2.1.9-3.2c0-2.1-1.5-2.9-3.3-1.9-1,.6-1.8,1.5-2.3,2.4-.6,1-.9,2.1-.9,3.2c0,2,1.5,2.9,3.3,1.9Z"
              fill="#0a4d90"
            ></path>
          </g>
          <path
            d="M242.8,96.9c1.7-1,3-.2,3,1.8c0,1.1-.3,2.1-.9,3.1-.5.9-1.3,1.7-2.1,2.3-1.6,1-3,.2-3-1.8c0-1.1.3-2.1.9-3.1.5-.9,1.3-1.7,2.1-2.3Z"
            fill="#c1c5f4"
          ></path>
          <path
            d="M254.9,88.9c1.7-1,3-.2,3,1.8c0,1.1-.3,2.1-.9,3.1-.5.9-1.3,1.7-2.1,2.3-1.7,1-3,.2-3-1.8c0-1.1.3-2.1.9-3.1.5-.9,1.2-1.7,2.1-2.3Z"
            fill="#c1c5f4"
          ></path>
          <path
            d="M266.9,82.9c1.8-1,3-.2,3,1.8c0,1.1-.3,2.1-.9,3.1-.5.9-1.3,1.7-2.1,2.3-1.8,1-3,.2-3-1.8c0-1.1.3-2.1.9-3.1.5-.9,1.2-1.7,2.1-2.3Z"
            fill="#c1c5f4"
          ></path>
          <path
            d="M278.9,74.9c1.7-1,3-.2,3,1.8-.1,1.1-.4,2.1-.9,3.1-.5.9-1.2,1.7-2.1,2.3-1.8,1-3,.2-3-1.8.1-1.1.4-2.1.9-3c.5-1,1.3-1.8,2.1-2.4Z"
            fill="#c1c5f4"
          ></path>
        </g>
        <g id="efDeGdW83Eu42" transform="translate(0 0)">
          <path
            d="M138.2,160.1L49.1,209.4c-1.5.9-2.8,0-2.8-1.6v-24.3c0-1,.3-1.9.8-2.7s1.2-1.5,2-2.1l89.6-49.4c1.5-.8,2.7,0,2.7,1.7l-.5,24.3c-.1.9-.3,1.9-.8,2.7-.4.9-1.1,1.6-1.9,2.1Z"
            fill="#03437c"
          ></path>
          <path
            d="M62,194.7c1.7-1.1,3.1-2.5,4.1-4.2s1.6-3.7,1.7-5.7c0-3.7-2.5-5.2-5.8-3.5-1.7,1.1-3.1,2.5-4.1,4.2s-1.6,3.7-1.7,5.7c0,3.7,2.5,5.2,5.8,3.5Z"
            fill="#ffcb3b"
          ></path>
          <path
            d="M65.2,183.9c-.9,1.9-2,3.8-3.1,5.6-.2.5-.5.9-.8,1.3v.2c-1.4-.9-2.4-1.6-2.3-1.7c1,.2,1.9.6,2.7,1.2h-.3c.2-.4.5-.8.8-1.2.9-1.9,1.9-3.6,3-5.4Z"
            fill="#e0e0e0"
          ></path>
          <path
            d="M132,146c0,0-12.5,7.3-28.3,15.9-15.8,8.7-28.5,15.6-28.5,15.5s12.8-7.3,28.5-16c15.8-8.7,28.3-15.5,28.3-15.4Z"
            fill="#fafafa"
          ></path>
          <path
            d="M132.2,151.4c0,0-12.5,7.2-28.3,15.9s-28.5,15.6-28.5,15.5s12.8-7.3,28.5-16s28.3-15.6,28.3-15.4Z"
            fill="#fafafa"
          ></path>
        </g>
        <g id="efDeGdW83Eu48" transform="translate(0.000001 0)">
          <path
            d="M50.9,235.5L150.2,181c4.1-2.2,7.4.5,7.6,6.1l4.5,134.2c.1,2.9-.5,5.7-1.7,8.3s-3.1,4.8-5.4,6.5L52.3,392.6c-4.1,2.3-7.5-.7-7.6-6.8L43.6,249.6c0-2.7.6-5.5,1.9-7.9s3.1-4.6,5.4-6.2Z"
            fill="#fff"
          ></path>
          <g>
            <path
              d="M61.7,237.8c-2.3,1.4-4.2,3.2-5.6,5.5-1.7,2.5-2.9,5.3-3.5,8.3-.7,2.8-.8,5.7-.4,8.5.3,2.2,1.3,4.2,3,5.7.7.6,1.6,1,2.5,1.2s1.9.1,2.8-.1c2.3-.8,4.3-2.2,5.8-4.1c1.9-2.3,3.4-4.9,4.4-7.8c1-2.8,1.6-5.8,1.5-8.8c0-1.7-.4-3.4-1-5-.5-1.3-1.3-2.4-2.3-3.4-1-.8-2.2-1.2-3.4-1.2-1.3,0-2.6.4-3.8,1.2Z"
              opacity="0.3"
              clip-rule="evenodd"
              fill="#91c9ed"
              fill-rule="evenodd"
            ></path>
            <path
              d="M62.4,259.4c-3,1.7-5.6-.4-5.7-4.6-.1-2.1.4-4.1,1.3-6c.9-1.8,2.3-3.4,3.9-4.7c3-1.7,5.6.4,5.7,4.6.1,2.1-.4,4.1-1.3,6s-2.3,3.5-3.9,4.7Z"
              fill="#3aafd2"
            ></path>
          </g>
          <path
            d="M81.8,245.9l52.7-29c1-.6,1.9,0,1.9,1.6v2.7c0,.7-.1,1.4-.5,2.1-.3.6-.8,1.2-1.4,1.6l-52.8,29c-1.1.6-1.9,0-1.9-1.6v-2.7c0-.7.2-1.4.5-2.1.5-.6.9-1.2,1.5-1.6Z"
            opacity="0.3"
            fill="#91c9ed"
          ></path>
          <path
            d="M56.5,279.4l90-49.4c3.6-2,6.7.5,6.9,5.6l2.6,81.9c.1,2.6-.4,5.1-1.5,7.5-1.1,2.3-2.8,4.3-4.9,5.8L57.5,381.4c-3.7,2-6.7-.7-6.8-6.1L50,292.4c0-2.5.5-5,1.6-7.3c1.2-2.3,2.8-4.2,4.9-5.7Z"
            opacity="0.35"
            fill="#4786c6"
          ></path>
          <path
            d="M123.9,255.8l-28.5,73-18.9-12.4L55,376.2l96.9-53.4-28-67Z"
            clip-rule="evenodd"
            fill="#3061ad"
            fill-rule="evenodd"
          ></path>
          <path
            d="M91.2,292.6c-3.6,2-6.6-.5-6.7-5.7-.1-2.5.5-5,1.6-7.2s2.8-4.2,4.8-5.7c3.6-2,6.6.5,6.7,5.7.1,2.5-.5,5-1.6,7.3-1.1,2.2-2.8,4.1-4.8,5.6Z"
            fill="#f7c751"
          ></path>
          <path
            d="M81,231.8l29-16c1-.6,1.9,0,1.9,1.6v2.7c0,.7-.1,1.4-.4,2.1-.3.6-.8,1.2-1.4,1.6l-29,16c-1,.6-1.9,0-1.9-1.6v-2.7c0-.7.1-1.4.4-2.1.3-.6.8-1.2,1.4-1.6Z"
            opacity="0.3"
            fill="#91c9ed"
          ></path>
        </g>
        <g>
          <path
            d="M423.7,202.1L503,239.6c1.4.7,2.5,1.7,3.2,3.1.7,1.3,1,2.9.8,4.4l-15.5,65.8c-.1.6-.4,1.2-.8,1.6-.4.5-.9.8-1.5,1-1.4.3-2.8.1-4-.6l-77-42.4c-1.2-.7-2.2-1.7-2.8-3-.6-1.2-.8-2.6-.6-4l13.4-61.1c.1-.6.4-1.1.7-1.5.4-.4.8-.8,1.3-1.1.6-.2,1.2-.3,1.8-.3.5.2,1.1.3,1.7.6Z"
            clip-rule="evenodd"
            fill="#024aa0"
            fill-rule="evenodd"
          ></path>
          <path
            d="M485.6,212.3l-73.9-33.7c-4.2-1.9-7.6.4-7.6,5.1v76.1c.1,2.6.9,5.1,2.2,7.3s3.2,4.1,5.4,5.5L486,313.2c4.9,2.7,8.9.7,8.9-4.5l-.4-83c-.2-2.8-1.1-5.5-2.6-7.9-1.7-2.4-3.8-4.3-6.3-5.5Z"
            fill="#f2f3fa"
          ></path>
          <path
            d="M486.6,221.6l-75.7-35.1c-1.1-.5-1.9,0-1.9,1.3v4.8c0,.6.2,1.3.6,1.8.3.5.8,1,1.4,1.3l75.8,36c1.2.6,2.2,0,2.2-1.3v-5.2c0-.7-.2-1.5-.6-2.1-.6-.7-1.1-1.2-1.8-1.5Z"
            fill="#dce8f6"
          ></path>
          <path
            d="M487.5,243.1l-77.2-37.3c-.1-.1-.3-.1-.5-.1s-.3.1-.5.1c-.1.1-.2.2-.3.4-.1.1-.1.3-.1.5v3.3c0,.4.2.8.4,1.2s.6.7.9.9l77.2,37.8c.8.4,1.5,0,1.5-.8v-3.5c0-.5-.2-1-.5-1.4-.1-.5-.5-.8-.9-1.1Z"
            fill="#dce8f6"
          ></path>
          <path
            d="M487.6,260.3l-77.3-38.5c-.7-.4-1.3,0-1.3.8v3.3c0,.4.1.9.4,1.2s.5.7.9.9l77.3,39c.8.4,1.5,0,1.5-.8v-3.5c0-.5-.2-1-.5-1.4-.2-.4-.6-.7-1-1Z"
            fill="#dce8f6"
          ></path>
          <path
            d="M487.6,277.6l-77.3-39.7c-.7-.4-1.3,0-1.3.8v3.3c0,.4.1.9.4,1.2.2.4.5.7.9.9l77.3,40.1c.8.4,1.5,0,1.5-.8v-3.5c-.1-.5-.2-.9-.5-1.3-.2-.5-.6-.8-1-1Z"
            fill="#dce8f6"
          ></path>
          <path
            d="M451.3,223.6l25.4,12c1.4.7,2.5,1.8,3.4,3.1s1.5,2.7,1.8,4.2l8,69.4c.1.6.1,1.2,0,1.8-.1.5-.4.9-.8,1.2s-.9.4-1.4.3c-.6-.1-1.2-.3-1.7-.6l-78.1-42.9c-1.2-.8-2.2-1.8-3-3s-1.3-2.6-1.5-4L402,250.9l-5.1-49.4-.9-8.7c-.2-.8-.2-1.7,0-2.6.2-.7.6-1.3,1.1-1.8.5-.4,1.2-.7,1.8-.7.8,0,1.6.2,2.3.6l30.6,14.4c3.5,1.8,6.3,4.8,7.8,8.5c4,7.9,4.9,9,11.7,12.4Z"
            clip-rule="evenodd"
            fill="#5b5bc1"
            fill-rule="evenodd"
          ></path>
        </g>
        <g>
          <path
            d="M579.1,149.6v57.8c.3,2.7-.2,5.5-1.3,8s-2.8,4.7-5,6.4c-2.4,1.2-5.1,1.8-7.7,1.6-2.7-.2-5.3-1.1-7.5-2.6L392.3,129.1c-6.5-3.9-11.8-9.5-15.5-16.2-4-6.6-6.2-14.1-6.5-21.8v-58.6c-.3-2.8.2-5.6,1.3-8.1c1.1-2.6,2.9-4.8,5.1-6.5c2.5-1.2,5.2-1.7,7.9-1.5s5.4,1.1,7.6,2.7l165.2,93c6.4,3.9,11.6,9.4,15.2,16c4,6.5,6.2,13.9,6.5,21.5Z"
            clip-rule="evenodd"
            fill="#0053cc"
            fill-rule="evenodd"
          ></path>
          <path
            d="M442.6,83l125.3,70.1c1.1.7,1.9,1.6,2.5,2.8.6,1.1,1,2.3,1,3.6v9.8c0,.5,0,.9-.2,1.4-.2.4-.4.8-.8,1.1-.4.2-.8.4-1.2.4s-.9-.1-1.2-.4l-125.3-70c-1.1-.7-1.9-1.6-2.6-2.8-.6-1.1-.9-2.3-1-3.6v-10c0-.5,0-.9.2-1.4.2-.4.4-.8.8-1.1.4-.2.8-.4,1.3-.4.4.2.8.3,1.2.5Z"
            opacity="0.2"
            clip-rule="evenodd"
            fill="#fff"
            fill-rule="evenodd"
          ></path>
          <path
            d="M546,168.9L441.1,110.4c-1.1-.6-2.1,0-2,1.3v5.4c0,.7.2,1.4.6,2c.3.6.8,1.2,1.4,1.6L546,179c1.2.7,2.1,0,2.1-1.2v-5.4c0-.7-.3-1.4-.6-2-.4-.6-.9-1.2-1.5-1.5Z"
            opacity="0.2"
            fill="#fff"
          ></path>
          <path
            d="M405.7,55c5,2.9,9.3,6.9,12.7,11.5c3.7,5,6.6,10.7,8.4,16.7c1.7,5.2,2.2,10.7,1.3,16.1-.2,2-.9,4-2,5.8s-2.5,3.3-4.2,4.4c-1.8,1-3.7,1.6-5.7,1.7s-4-.2-5.9-.9c-5-1.9-9.6-4.9-13.2-8.9-4.3-4.6-7.7-9.8-10.2-15.6-2.4-5.3-3.7-11.1-3.9-16.9-.2-2.9.3-5.8,1.5-8.5s3.1-4.9,5.4-6.7c2.5-1.2,5.3-1.8,8.1-1.6c2.8.4,5.4,1.3,7.7,2.9Z"
            opacity="0.2"
            clip-rule="evenodd"
            fill="#fff"
            fill-rule="evenodd"
          ></path>
          <path
            d="M405.8,64.7c3.2,1.9,5.9,4.4,8.1,7.3c2.4,3.2,4.2,6.8,5.3,10.7c1.1,3.3,1.4,6.8.9,10.2-.2,1.3-.6,2.6-1.3,3.7s-1.6,2.1-2.7,2.8c-1.1.6-2.4,1-3.6,1.1-1.3.1-2.6-.1-3.8-.6-3.2-1.2-6.1-3.1-8.4-5.7-2.7-2.9-4.9-6.2-6.5-9.9-1.6-3.4-2.4-7.1-2.5-10.9-.1-2,.3-4,1.1-5.9.6-1.5,1.7-2.8,3.1-3.7c1.4-.8,3.1-1.2,4.7-1.1c2,.3,3.9,1,5.6,2Zm0,6.9c-1.5-.9-3.2-1.3-4.9-1-.7.2-1.4.5-2,1s-1,1.1-1.3,1.8c-.7,1.8-.8,3.8-.5,5.7.4,2.4,1.2,4.8,2.4,6.9c1.2,2,2.7,3.8,4.5,5.3c1.4,1.2,3.2,2,5,2.2.7.1,1.5,0,2.2-.3s1.3-.7,1.8-1.3c1.1-1.4,1.6-3.1,1.5-4.9c0-1.5-.2-2.9-.6-4.3-.5-1.5-1.1-3-1.9-4.4s-1.7-2.7-2.8-3.8c-.9-1.1-2.1-2-3.3-2.7v-.2Z"
            clip-rule="evenodd"
            fill="#fff"
            fill-rule="evenodd"
          ></path>
        </g>
        <path
          d="M696.7,272.3l-67.5-35.6c-6.9-3.6-12.6.6-12.6,9.3v43c0,8.7,5.6,19,12.6,22.8l49.3,27.1-.4,15.1l12.7-8.3l5.9,3.3c7.2,4,13.2,0,13.2-9.1v-44.2c0-9-6-19.6-13.2-23.4Zm-69-18c0-2.5,1.6-3.6,3.6-2.6l15.3,8.1c1.1.7,1.9,1.6,2.6,2.8.6,1.1,1,2.4,1,3.6c0,2.4-1.6,3.6-3.5,2.6l-15.3-8.2c-1.1-.7-1.9-1.6-2.6-2.7s-1.1-2.3-1.1-3.6Zm65.1,77.2l-27.1-14.8c-1.1-.7-2-1.6-2.6-2.8-.6-1.1-1-2.4-1-3.6c0-2.5,1.6-3.6,3.6-2.5l27.1,14.7c1.1.7,2,1.7,2.6,2.8s1,2.4,1,3.7c0,2.5-1.6,3.7-3.6,2.5Zm0-19l-58-31.3c-1.1-.7-1.9-1.6-2.5-2.7s-.9-2.3-1-3.6c0-2.5,1.6-3.6,3.5-2.6l58,31.1c1.1.7,2,1.7,2.6,2.8s1,2.4,1,3.7c0,2.6-1.6,3.7-3.6,2.6v0Zm0-19.1L658.2,275c-1.1-.7-2-1.6-2.6-2.7s-1-2.3-1-3.6c0-2.5,1.6-3.7,3.6-2.6l34.5,18.4c1.1.7,2,1.7,2.6,2.8s1,2.4,1,3.7c.1,2.4-1.5,3.6-3.5,2.4v0Z"
          fill="#197bdd"
        ></path>
        <g>
          <g opacity="0.35">
            <g opacity="0.35">
              <path
                d="M663.4,79.4l11.3-4.7c10.3-4.3,22.4-1.1,33.6,10.4L697,89.7c-11.3-11.5-23.3-14.6-33.6-10.3Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M714.1,210.8l-10.5,4.3c9.5-3.8,17.4-14.1,21.5-30.1c8.2-32-2-74.9-22.8-95.5l10.5-4.2c21,20.7,31.1,63.4,22.8,95.5-4,15.9-12,26.2-21.5,30Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M700.2,87c21.4,21,31.8,64.3,23.3,96.9s-32.7,42-54.1,21C648,184,637.6,140.6,646.1,108c8.6-32.6,32.8-42,54.1-21Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M716,176.4c-3.5,13.7-10.6,22.3-19,25-7.6,2.4-16.3-.1-24.5-8.1-17.2-16.9-25.6-51.7-18.7-77.9c4-15.6,12.6-24.6,22.6-25.7c6.7-.8,14,2.1,20.9,8.9c17.2,16.8,25.5,51.6,18.7,77.8Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M716,176.5c-3.5,13.7-10.6,22.3-18.9,25-6.7.9-14.1-2-21-8.8-17.2-16.8-25.5-51.8-18.8-77.9c3.6-13.8,10.7-22.4,19.1-25c6.7-.8,13.9,2.1,20.9,8.9c17.2,16.8,25.5,51.6,18.7,77.8Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M696.2,103.6c.2.2.3.4.3.7.1.3.1.5,0,.8l-1.6,6.8c-.1.5-.5.6-.8.3-.2-.2-.3-.4-.3-.7-.1-.3-.1-.5,0-.8l1.6-6.8c.1-.4.5-.6.8-.3Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M680.2,94.9c.2.3.3.6.4.9l.6,8.2c0,.6-.3,1-.5,1.1-.1,0-.2-.1-.3-.2-.2-.3-.3-.6-.4-.9l-.6-8.2c-.1-.6.3-1,.5-1c.1,0,.2,0,.3.1Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M665.8,99.7c.1.1.2.3.2.4l2.8,7.4c.1.2.1.5.1.7c0,.3-.1.5-.3.7s-.4.2-.6-.1c-.1-.1-.2-.3-.3-.4l-2.9-7.3c-.1-.2-.2-.5-.1-.7c0-.3.1-.5.3-.7.5-.2.6-.1.8,0Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M661.5,121.3c.2.2.3.4.3.7.1.3.1.6,0,.8-.1.5-.5.7-.8.3l-4.4-4.6c-.2-.2-.3-.4-.3-.7-.1-.3-.1-.6,0-.8.1-.5.5-.7.8-.3l4.4,4.6Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M661.1,141.9c.2.3.4.6.4,1c.1.6-.2,1.1-.5,1.1l-4.9-.4c-.1,0-.2,0-.3-.1-.2-.3-.4-.6-.4-1-.1-.7.3-1.1.6-1.1l4.9.4c0,0,.1,0,.2.1Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M667.3,163.5c.1.1.2.2.3.4s.2.5.1.7c0,.3-.1.5-.3.7l-3.7,3.8c0,0-.1.1-.2.1s-.1,0-.2,0-.1,0-.2-.1c0,0-.1-.1-.1-.1-.1-.1-.2-.3-.3-.4-.1-.2-.2-.5-.1-.7c0-.3.1-.5.3-.7l3.7-3.8c.4-.2.6-.2.7.1Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M678.9,180.3c.2.2.3.4.3.7.1.3.1.5,0,.8l-1.6,6.8c-.1.5-.5.7-.8.3-.1-.2-.3-.4-.3-.7-.1-.3,0-.5,0-.8l1.6-6.8c.2-.4.6-.6.8-.3Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M692.7,187.6c.2.3.3.6.4,1l.6,8.2c0,.6-.3,1.1-.5,1.1-.1,0-.2-.1-.3-.2-.2-.3-.3-.6-.4-1l-.6-8.2c0-.6.3-1,.5-1s.3,0,.3.1Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M704.8,183.6c.1.1.2.3.3.4l2.8,7.4c.1.2.2.5.1.7c0,.3-.1.5-.2.7-.2.2-.4.2-.6-.1-.1-.1-.2-.3-.3-.4l-2.8-7.4c-.1-.2-.2-.5-.1-.7c0-.3.1-.5.2-.8.1,0,.1-.1.2-.1s.1,0,.2,0s.1,0,.2.1c0,.1,0,.2,0,.2Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M716.4,173.9c.2.2.3.4.3.7.1.3.1.6,0,.8-.1.5-.5.7-.8.3l-4.4-4.6c-.2-.2-.3-.4-.3-.7-.1-.3-.1-.6,0-.8.1-.5.5-.7.8-.4l4.4,4.7Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M717.3,149.1c.2.3.4.6.4,1c0,.6-.3,1.1-.6,1.1l-4.9-.4c-.1,0-.2-.1-.3-.2-.2-.3-.4-.6-.4-1-.1-.6.3-1.1.6-1.1l4.9.4c.1,0,.2.1.3.2Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M709.8,123.4c.1.1.2.3.3.4.1.2.2.5.1.7c0,.3-.1.5-.2.7l-3.7,3.8c-.1,0-.1.1-.2.1s-.1,0-.2,0-.1,0-.2-.1c0,0-.1-.1-.1-.1-.1-.1-.2-.3-.2-.4-.1-.2-.2-.5-.1-.7c0-.3.1-.5.3-.8l3.7-3.8c.1-.1.2-.1.3-.1.1.1.1.2.2.3Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M669.8,125.7l4.9-2c.1,0,.2,0,.2,0c.1,0,.1.1.2.1.1.1.2.2.2.3l-4.9,2c0-.1-.1-.2-.2-.3s-.1-.1-.2-.1c0-.1-.1-.1-.2,0Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M684.6,145c-.1.5-.1.9,0,1.4s.3.8.6,1.2l-4.8,1.9c-.3-.3-.5-.7-.6-1.2-.1-.4-.1-.9,0-1.4c0-.2.1-.3.2-.5.1-.1.2-.3.4-.3l4.8-1.9c-.1.1-.3.2-.4.3s-.2.3-.2.5Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M685.4,147.5c.1.1.3.2.4.2s.3,0,.5-.1l-4.9,1.9c-.2.1-.3.1-.5.1s-.3-.1-.4-.2l4.9-1.9Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M680.2,146.2l4.9-2c.2-.1.3-.1.5-.1s.3.1.4.2l-4.9,2c-.1-.1-.2-.2-.4-.2s-.3,0-.5.1Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M686.1,147.9l-4.8,1.9c.1-.1.3-.2.4-.3s.2-.3.2-.5c.1-.5.1-.9,0-1.4-.1-.4-.3-.8-.6-1.1l4.8-1.9c.3.3.5.7.6,1.1s.1.9,0,1.4c0,.2-.1.3-.2.5-.1.1-.3.2-.4.3Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M686.8,149.9l-4.8,1.9c.3-.1.5-.3.7-.6s.4-.5.4-.9l4.8-1.9c-.1.3-.2.6-.4.9-.1.3-.4.5-.7.6Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M708.8,149.8l-4.8,1.9c.3-.1.2-1,0-1.2l4.8-1.9c.3.3.4,1.1,0,1.2Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M703,149.9c.3.3.3,1.3-.1,1.3l-20.2-.1c-.6,1.6-1.9,2.1-3,.9-1-1.2-1.6-2.8-1.5-4.5L671,126.8c-.3-.9.2-1.6.7-1.2.1.1.2.2.2.3l9.2,18.8c.2.1.4.2.5.4.4.4.7.8.9,1.3l20.5,3.4c-.1,0-.1,0,0,.1Zm-22.9.3c.6.6,1.2.2,1.5-.6.1-.5.1-1,0-1.4-.1-.5-.3-.9-.6-1.2-.6-.6-1.2-.2-1.4.6-.1.5-.1,1,0,1.4c0,.4.2.8.5,1.2Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M681.2,146.2c.3.3.5.7.6,1.1s.1.9,0,1.4c-.2.9-.9,1.1-1.4.6-.3-.3-.5-.7-.6-1.2-.1-.4-.1-.9,0-1.4.2-.8.8-1,1.4-.5Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M671.3,126l4.8-1.9l9,18.3-4.8,1.9-9-18.3Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M681,144.3l4.8-1.9c.2.1.4.2.5.4.4.4.7.8.9,1.3l-4.8,1.9c-.2-.5-.5-.9-.9-1.3-.1-.2-.3-.3-.5-.4Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M682.5,146.4l5-2.2l21,3.8-5,2.2-21-3.8Z"
                fill="#c0c0ed"
              ></path>
            </g>
            <g opacity="0.35">
              <path
                d="M703.7,150.4l4.8-1.9c.1,0,.2,0,.2,0l-4.9,2c0,0-.1-.1-.1-.1Z"
                fill="#c0c0ed"
              ></path>
            </g>
          </g>
          <path
            d="M646.1,83.7L657.4,79c10.3-4.3,22.4-1.1,33.6,10.4L679.7,94c-11.3-11.5-23.4-14.6-33.6-10.3Z"
            fill="url(#efDeGdW83Eu135-fill)"
          ></path>
          <path
            d="M696.8,215.2l-10.5,4.3c9.5-3.8,17.4-14.1,21.5-30.1c8.2-32-2-74.9-22.8-95.5l10.5-4.2c21,20.7,31.1,63.4,22.8,95.5-4.1,15.8-12.1,26.2-21.5,30Z"
            fill="url(#efDeGdW83Eu136-fill)"
          ></path>
          <path
            d="M682.9,91.3c21.4,21,31.8,64.3,23.3,96.9s-32.7,42-54.1,21-31.8-64.3-23.3-96.9s32.7-42,54.1-21Z"
            fill="url(#efDeGdW83Eu137-fill)"
          ></path>
          <path
            d="M702.1,181.9c-2.9,13.2-9.9,21.2-18.5,23.3-7,.5-15-2.8-22.7-9.9-19.2-17.5-30-52.2-24.5-77.2c2.9-13.3,9.9-21.2,18.7-23.3c7-.3,14.8,2.9,22.6,9.9c19.2,17.6,30,52.1,24.4,77.2Z"
            fill="#c1c5f4"
          ></path>
          <path
            d="M678.7,108c.2.2.3.4.4.7s.1.5,0,.8l-1.5,6.7c-.1.5-.5.6-.8.3-.2-.2-.3-.4-.4-.7s-.1-.5,0-.8l1.5-6.7c.1-.5.5-.6.8-.3Z"
            fill="#9ca1e2"
          ></path>
          <path
            d="M662.5,99.3c.2.3.4.6.4,1l1.1,8.2c.1.6-.2,1-.5,1-.1,0-.2-.1-.3-.2-.2-.3-.4-.6-.5-1l-1.1-8.2c-.1-.6.2-1,.5-1c.2,0,.3.1.4.2Z"
            fill="#9ca1e2"
          ></path>
          <path
            d="M648.2,104.1c.1.1.2.3.3.4l3.4,7.4c.1.2.2.5.2.7c0,.3-.1.5-.2.7-.2.2-.4.1-.6-.1-.1-.1-.2-.3-.3-.4l-3.5-7.3c-.1-.2-.2-.5-.2-.7c0-.3.1-.5.2-.7.3-.2.4-.2.7,0Z"
            fill="#9ca1e2"
          ></path>
          <path
            d="M644.3,125.7c.2.2.3.4.4.7s.1.5,0,.8c-.1.5-.5.6-.8.3l-4.9-4.6c-.2-.2-.3-.4-.4-.7s-.1-.5,0-.8c.1-.5.5-.6.8-.3l4.9,4.6Z"
            fill="#9ca1e2"
          ></path>
          <path
            d="M643.7,146.4c.3.3.4.6.4,1c.1.6-.1,1-.5,1l-5-.6c-.1,0-.2,0-.4-.1-.3-.3-.4-.6-.5-1-.1-.6.2-1,.5-1l4.9.6c.4,0,.5.1.6.1Z"
            fill="#9ca1e2"
          ></path>
          <path
            d="M650,168c.1.1.2.3.3.4.1.2.2.5.2.7c0,.3-.1.5-.2.7l-3.7,3.5c-.1,0-.1.1-.2.1s-.1,0-.2,0-.1,0-.2-.1c0,0-.1-.1-.1-.1-.1-.1-.2-.3-.3-.4-.1-.2-.2-.5-.2-.7c0-.3.1-.5.2-.7l3.7-3.5c.3-.2.5-.1.7.1Z"
            fill="#9ca1e2"
          ></path>
          <path
            d="M661.5,184.7c.2.2.3.4.4.7s.1.5,0,.8l-1.5,6.7c-.1.5-.5.6-.8.3-.2-.2-.3-.4-.4-.7s-.1-.5,0-.8l1.5-6.7c.1-.5.5-.7.8-.3Z"
            fill="#9ca1e2"
          ></path>
          <path
            d="M675.1,192c.3.3.4.6.5,1l1.1,8.1c.1.6-.2,1-.5,1-.1,0-.3-.1-.3-.2-.3-.3-.4-.6-.5-1l-1.1-8.1c-.1-.6.2-1,.5-1c.1,0,.2.1.3.2Z"
            fill="#9ca1e2"
          ></path>
          <path
            d="M687.1,188c.1.1.2.3.3.4l3.4,7.4c.1.2.2.5.2.7c0,.3-.1.5-.2.7-.2.2-.4.1-.6-.1-.1-.1-.2-.3-.3-.4l-3.4-7.4c-.1-.2-.2-.5-.2-.7c0-.3.1-.5.2-.7.1,0,.1-.1.2-.1s.1,0,.2,0s.1,0,.2.1c0,0,0,0,0,.1Z"
            fill="#9ca1e2"
          ></path>
          <path
            d="M699.2,178.3c.2.2.3.4.4.7s.1.5,0,.8c-.1.5-.5.6-.8.3l-4.8-4.6c-.2-.2-.3-.4-.4-.7s-.1-.5,0-.8c.1-.5.5-.6.8-.3l4.8,4.6Z"
            fill="#9ca1e2"
          ></path>
          <path
            d="M692.4,127.9c.1.1.2.3.3.4.1.2.2.5.2.7c0,.3-.1.5-.2.7l-3.7,3.5c-.1,0-.1.1-.2.1s-.1,0-.2,0-.1,0-.2-.1c0,0-.1-.1-.1-.1-.1-.1-.2-.3-.3-.4-.1-.2-.2-.5-.2-.7c0-.3.1-.5.2-.7l3.7-3.5c.1-.1.2-.1.3-.1.2,0,.3.1.4.2Z"
            fill="#9ca1e2"
          ></path>
          <path
            d="M652.5,130l4.9-2c.1,0,.2,0,.2,0c.1,0,.1.1.2.1.1.1.2.2.2.3l-4.9,2c0-.1-.1-.2-.2-.3s-.1-.1-.2-.1c0-.1-.1,0-.2,0Z"
            fill="#2f97ed"
          ></path>
          <path
            d="M667.2,149.3c-.1.5-.1.9,0,1.4s.3.8.6,1.2l-4.8,1.9c-.3-.3-.5-.7-.6-1.2s-.1-.9,0-1.4c0-.2.1-.3.2-.5.1-.1.2-.3.4-.3l4.8-1.9c-.1.1-.3.2-.4.3-.1.2-.1.3-.2.5Z"
            fill="#2f97ed"
          ></path>
          <path
            d="M668,151.8c.1.1.3.2.4.2s.3,0,.5-.1l-4.9,1.9c-.2.1-.3.1-.5.1s-.3-.1-.4-.2l4.9-1.9Z"
            fill="#2f97ed"
          ></path>
          <path
            d="M662.9,150.5l4.9-2c.2-.1.3-.1.5-.1s.3.1.4.2l-4.9,2c-.1-.1-.2-.2-.4-.2s-.3.1-.5.1Z"
            fill="#fff"
          ></path>
          <path
            d="M668.7,152.2l-4.8,1.9c.1-.1.3-.2.4-.3s.2-.3.2-.5c.1-.5.1-.9,0-1.4-.1-.4-.3-.8-.6-1.2l4.8-1.9c.3.3.5.7.6,1.1s.1.9,0,1.4c0,.2-.1.3-.2.5s-.2.3-.4.4Z"
            fill="#fff"
          ></path>
          <path
            d="M669.5,154.3l-4.8,1.9c.3-.1.5-.3.7-.6s.4-.5.4-.9l4.8-1.9c-.1.3-.2.6-.4.9-.2.2-.4.4-.7.6Z"
            fill="#2f97ed"
          ></path>
          <path
            d="M691.4,154.2l-4.8,1.9c.3-.1.2-1,0-1.2l4.8-1.9c.3.2.4,1,0,1.2Z"
            fill="#2f97ed"
          ></path>
          <path
            d="M685.6,154.2c.3.3.3,1.3-.1,1.3l-20.2-.1c-.6,1.6-1.9,2.1-3,.9-1-1.2-1.6-2.8-1.5-4.5l-7.2-20.7c-.3-.9.2-1.6.7-1.2.1.1.2.2.2.3l9.2,18.8c.2.1.4.2.5.4.4.4.7.8.9,1.3l20.5,3.4c-.1,0,0,.1,0,.1Zm-22.9.3c.6.6,1.2.2,1.5-.6.1-.5.1-1,0-1.4-.1-.5-.3-.9-.6-1.2-.6-.6-1.2-.2-1.4.6-.1.5-.1,1,0,1.4s.3.9.5,1.2Z"
            fill="url(#efDeGdW83Eu157-fill)"
          ></path>
          <path
            d="M663.9,150.5c.3.3.5.7.6,1.1s.1.9,0,1.4c-.2.9-.9,1.1-1.4.6-.3-.3-.5-.7-.6-1.2-.1-.4-.1-.9,0-1.4.2-.8.8-1,1.4-.5Z"
            fill="#fff"
          ></path>
          <path
            d="M654,130.3l4.8-1.9l9,18.3-4.8,1.9-9-18.3Z"
            fill="#2f97ed"
          ></path>
          <path
            d="M663.7,148.6l4.8-1.9c.2.1.4.2.5.4.4.4.7.8.9,1.3l-4.8,1.9c-.2-.5-.5-.9-.9-1.3-.1-.1-.3-.3-.5-.4Z"
            fill="#2f97ed"
          ></path>
          <path
            d="M665.2,150.8l5-2.2l21,3.8-5,2.2-21-3.8Z"
            fill="#2f97ed"
          ></path>
          <path
            d="M686.3,154.8l4.8-1.9c.1,0,.2,0,.2,0l-4.9,2c.1-.1,0-.1-.1-.1Z"
            fill="#2f97ed"
          ></path>
        </g>
        <g>
          <path
            id="efDeGdW83Eu164"
            d="M583.7,551.1c24.5,14.2,24.7,37.1.3,51.3l-208.2,121c-24.4,14.2-64,14.2-88.5,0L77.5,602.4c-24.5-14.2-24.7-37.1-.3-51.3L285.5,430c24.4-14.2,64-14.2,88.5,0L583.7,551.1Z"
            transform="translate(21.637389 0)"
            fill="url(#efDeGdW83Eu164-fill)"
            fill-opacity="0.15"
          ></path>
        </g>
        <g id="efDeGdW83Eu165" transform="translate(0 0.000001)">
          <path
            d="M630,523.2L400.7,656.3c-26.8,15.6-70.3,15.5-97.4,0L72.4,523.2c-13.6-7.8-20.3-18.1-20.3-28.4v20.9c0,10.3,6.8,20.5,20.3,28.4L303.2,677.2c27,15.5,70.6,15.6,97.4,0L629.9,544.1c13.3-7.7,20-17.8,20-28v-20.9c0,10.1-6.6,20.3-19.9,28Z"
            fill="#087cb7"
          ></path>
          <path
            d="M629.5,466.8c27,15.6,27.1,40.8.3,56.4L400.6,656.3c-26.8,15.6-70.3,15.5-97.4,0L72.4,523.2c-27-15.6-27.1-40.8-.3-56.4L301.4,333.7c26.8-15.6,70.3-15.6,97.4,0L629.5,466.8Z"
            fill="#03437c"
          ></path>
          <path
            d="M224.2,474.3c.8-1.4,1.7-2.8,2.6-4.2c6.6-9.8,15.8-18.7,27.5-26.5c23.2-15.5,54.1-25.2,86.9-27.3c32.6-2.1,63.4,3.6,86.6,16c12.8,6.8,22.6,15.3,29.2,25.2c3.3,4.9,5.7,10.2,7.3,15.7c1.5,5.4,2.1,11.2,1.8,17.1-.6,10.6-4.3,21-11,30.9-6.6,9.8-15.8,18.7-27.5,26.5-23.2,15.5-54.1,25.2-86.9,27.3-32.6,2.1-63.4-3.6-86.6-16-12.8-6.8-22.6-15.3-29.2-25.2-3.3-4.9-5.7-10.2-7.3-15.7-1.5-5.4-2.1-11.2-1.8-17.1.5-9.1,3.4-18.1,8.4-26.7Zm38.2-14.9c-17.4,11.6-27.5,25.9-28.4,40-1,16.1,10,31.5,30.1,42.2c10.4,5.6,22.6,9.6,36.2,12c13.3,2.4,27.4,3.1,41.8,2.2s28.4-3.5,41.6-7.5c13.5-4.1,25.4-9.7,35.6-16.4c17.5-11.7,27.6-25.9,28.4-40.1c1-16.1-10-31.5-30.1-42.2-10.4-5.6-22.6-9.6-36.2-12-13.3-2.4-27.4-3.1-41.8-2.2s-28.4,3.5-41.6,7.5c-13.4,4.2-25.4,9.7-35.6,16.5Z"
            fill="#ffc72c"
          ></path>
          <g opacity="0.5">
            <path
              d="M538.7,414.5l-164.4-20-148-17L142.7,426l499.2,49.1-103.2-60.6Z"
              opacity="0.14"
              fill="#fff"
            ></path>
          </g>
          <g opacity="0.2">
            <path
              d="M176.7,584.3l299,27.5l90.4-51.5L73.5,523.7l103.2,60.6Z"
              opacity="0.5"
              fill="#fff"
            ></path>
          </g>
          <g>
            <g clip-path="url(#efDeGdW83Eu178)">
              <g>
                <g opacity="0.69">
                  <path
                    d="M137.9,521.5c18.1,0,32.8-8.6,32.8-19.1c0-10.6-14.7-19.1-32.8-19.1s-32.8,8.6-32.8,19.1s14.7,19.1,32.8,19.1Z"
                    fill="#012454"
                  ></path>
                </g>
              </g>
              <clipPath id="efDeGdW83Eu178">
                <use
                  xlinkHref="#efDeGdW83Eu2"
                  transform="translate(105.1 483.3)"
                ></use>
              </clipPath>
            </g>
          </g>
          <path
            d="M134.8,465.3l-26.2-15.1c-4-2.3-7.3-5.6-9.6-9.6s-3.5-8.5-3.5-13.1v-36.600001h11.5L107,427.5c0,2.6.7,5.1,2,7.4c1.3,2.2,3.1,4.1,5.4,5.4l26.2,15.2-5.8,9.8Z"
            fill="#0b0b3c"
          ></path>
          <path
            d="M141.2,480.2l-5.8-10l13.3-7.7c.7-.4,1.3-1,1.7-1.7s.6-1.5.6-2.3v-64.9h11.5v64.9c0,2.8-.8,5.6-2.2,8.1s-3.5,4.5-5.9,5.9l-13.2,7.7Z"
            fill="#0b0b3c"
          ></path>
          <path
            d="M137.7,504.8c-1.5,0-3-.6-4.1-1.7s-1.7-2.6-1.7-4.1v-79.4c0-.8.1-1.6.4-2.3s.7-1.4,1.2-2s1.2-1,1.9-1.3s1.5-.5,2.3-.5s1.6.2,2.3.5s1.4.8,1.9,1.3c.5.6,1,1.2,1.2,2s.4,1.5.4,2.3v79.4c0,1.5-.6,3-1.7,4.1s-2.6,1.7-4.1,1.7Z"
            fill="#0b0b3c"
          ></path>
          <g>
            <path
              d="M132.1,383.8l-20.7-12c-1.7-1-3.6-1.5-5.6-1.5s-3.9.5-5.6,1.5-3.1,2.4-4.1,4.1-1.5,3.7-1.5,5.6v32.3c0,2,.5,3.9,1.5,5.6s2.4,3.1,4.1,4.1l20.7,12c1.7,1,3.6,1.5,5.6,1.5s3.9-.5,5.6-1.5s3.1-2.4,4.1-4.1s1.5-3.7,1.5-5.6v-32.3c0-2-.5-3.9-1.5-5.6s-2.4-3.1-4.1-4.1Z"
              fill="#3aafd2"
            ></path>
            <path
              d="M126.5,438.2c-2.1,0-4.2-.6-6.1-1.7l-20.7-12c-1.9-1.1-3.4-2.6-4.5-4.5s-1.6-4-1.6-6.1v-32.3c0-2.1.6-4.2,1.7-6.1s2.6-3.4,4.5-4.5s3.9-1.6,6.1-1.6c2.1,0,4.2.6,6.1,1.6l20.7,12c1.9,1.1,3.4,2.6,4.5,4.5s1.6,4,1.6,6.1v32.3c0,2.2-.5,4.3-1.6,6.1-1.1,1.9-2.6,3.4-4.5,4.5s-4.1,1.7-6.2,1.7Zm-20.7-66.9c-2.7,0-5.3,1.1-7.2,3s-3,4.5-3,7.2v32.3c0,1.8.5,3.6,1.4,5.1.9,1.6,2.2,2.9,3.7,3.8l20.7,12c1.6.9,3.3,1.4,5.1,1.4s3.6-.5,5.1-1.4c1.6-.9,2.8-2.2,3.7-3.7.9-1.6,1.4-3.3,1.4-5.1v-32.3c0-1.8-.5-3.6-1.4-5.1-.9-1.6-2.2-2.8-3.7-3.8l-20.7-12c-1.6-.9-3.3-1.4-5.1-1.4v0Z"
              fill="#eaf2fa"
            ></path>
            <path
              d="M121.2,354.6l-8.6,5c-1,.6-1.9,1.4-2.4,2.5-.6,1-.9,2.2-.9,3.4s.3,2.3.9,3.4c.6,1,1.4,1.9,2.4,2.5l22.2,12.8c1,.6,2.2.9,3.3.9c1.2,0,2.3-.3,3.3-.9l21.9-12.7c1-.6,1.9-1.4,2.4-2.5.6-1,.9-2.2.9-3.4s-.3-2.3-.9-3.4c-.6-1-1.4-1.9-2.4-2.5l-8.9-5.1c-5.1-2.9-10.8-4.5-16.6-4.5s-11.5,1.6-16.6,4.5Z"
              fill="#b8e2ef"
            ></path>
            <path
              d="M138.1,386c-1.4,0-2.7-.4-3.9-1L112,372.2c-1.2-.7-2.1-1.7-2.8-2.8-.7-1.2-1-2.5-1-3.9s.4-2.7,1-3.9c.7-1.2,1.6-2.2,2.8-2.8l8.6-5c5.2-3,11.1-4.6,17.1-4.6s11.9,1.6,17.1,4.6l8.9,5.1c1.2.7,2.1,1.7,2.8,2.8.7,1.2,1,2.5,1,3.9s-.4,2.7-1,3.9c-.7,1.2-1.6,2.1-2.8,2.8L142,384.9c-1.2.7-2.5,1.1-3.9,1.1Zm-16.3-30.5l-8.6,5c-.9.5-1.6,1.2-2.1,2.1s-.8,1.8-.8,2.8.3,2,.8,2.8s1.2,1.6,2.1,2.1l22.2,12.8c.9.5,1.8.8,2.8.8s2-.3,2.8-.8l21.9-12.7c.9-.5,1.6-1.2,2.1-2.1s.8-1.8.8-2.8-.3-2-.8-2.8-1.2-1.6-2.1-2.1l-8.9-5.1c-4.9-2.8-10.5-4.3-16.1-4.3s-11.2,1.4-16.1,4.3Z"
              fill="#eaf2fa"
            ></path>
            <path
              d="M143.3,383.8l20.7-12c1.7-1,3.6-1.5,5.6-1.5s3.9.5,5.6,1.5s3.1,2.4,4.1,4.1s1.5,3.7,1.5,5.6v32.3c0,2-.5,3.9-1.5,5.6s-2.4,3.1-4.1,4.1l-20.7,12c-1.7,1-3.6,1.5-5.6,1.5s-3.9-.5-5.6-1.5-3.1-2.4-4.1-4.1-1.5-3.7-1.5-5.6v-32.3c0-2,.5-3.9,1.5-5.6s2.4-3.1,4.1-4.1Z"
              fill="#2c839e"
            ></path>
            <path
              d="M148.9,438.2c-3.2,0-6.4-1.3-8.6-3.6-2.3-2.3-3.6-5.4-3.6-8.7v-32.3c0-2.2.6-4.3,1.6-6.1c1.1-1.9,2.6-3.4,4.5-4.5l20.7-12c1.9-1.1,4-1.6,6.1-1.6s4.3.6,6.1,1.6c1.9,1.1,3.4,2.6,4.5,4.5s1.6,4,1.6,6.1v32.3c0,2.2-.6,4.3-1.6,6.1-1.1,1.9-2.6,3.4-4.5,4.5l-20.7,12c-1.8,1.1-3.9,1.7-6.1,1.7Zm20.7-66.9c-1.8,0-3.6.5-5.1,1.4l-20.7,12c-1.6.9-2.8,2.2-3.7,3.8s-1.4,3.3-1.4,5.1v32.3c0,1.8.5,3.6,1.4,5.1.9,1.6,2.2,2.8,3.7,3.7c1.6.9,3.3,1.4,5.1,1.4s3.6-.5,5.1-1.4l20.7-12c1.6-.9,2.8-2.2,3.7-3.8s1.4-3.3,1.4-5.1v-32.3c0-2.7-1.1-5.3-3-7.2s-4.5-3-7.2-3v0Z"
              fill="#eaf2fa"
            ></path>
          </g>
          <path
            d="M159.7,440.6l11.3,6.5c.2.1.5.2.7.2s.5-.1.7-.2.4-.3.5-.5.2-.5.2-.7v-16.6h5.7v16.6c0,1.3-.3,2.5-.9,3.6s-1.5,2-2.6,2.6-2.3,1-3.6,1c-1.2,0-2.5-.3-3.6-1l-11.2-6.5v-3.3l2.8-1.7Z"
            fill="#0b0b3c"
          ></path>
          <g>
            <path
              d="M174.1,420.7l-6.5-3.8c-.5-.3-1.1-.5-1.8-.5-.6,0-1.2.2-1.8.5-.5.3-1,.8-1.3,1.3s-.5,1.2-.5,1.8v10.2c0,.6.2,1.2.5,1.8.3.5.8,1,1.3,1.3l6.5,3.8c.5.3,1.1.5,1.8.5.6,0,1.2-.2,1.8-.5.5-.3,1-.8,1.3-1.3s.5-1.2.5-1.8v-10.2c0-.6-.2-1.2-.5-1.8-.3-.5-.7-1-1.3-1.3Z"
              fill="#3aafd2"
            ></path>
            <path
              d="M172.4,438.5c-.8,0-1.6-.2-2.3-.6l-6.5-3.8c-.7-.4-1.3-1-1.7-1.7s-.6-1.5-.6-2.3v-10.1c0-.8.2-1.6.6-2.3s1-1.3,1.7-1.7s1.5-.6,2.3-.6s1.6.2,2.3.6l6.5,3.8c.7.4,1.3,1,1.7,1.7s.6,1.5.6,2.3v10.2c0,1.2-.5,2.4-1.3,3.2-1,.9-2.1,1.3-3.3,1.3Zm-6.6-21c-.4,0-.9.1-1.3.3s-.7.5-.9.9-.3.8-.3,1.3v10.2c0,.4.1.9.3,1.3s.5.7.9.9l6.5,3.8c.4.2.8.3,1.3.3.4,0,.9-.1,1.3-.3s.7-.5.9-.9.3-.8.3-1.3v-10.2c0-.4-.1-.9-.3-1.3s-.5-.7-.9-.9l-6.5-3.8c-.4-.2-.8-.3-1.3-.3Z"
              fill="#eaf2fa"
            ></path>
            <path
              d="M170.7,411.5l-2.7,1.6c-.3.2-.6.5-.8.8s-.3.7-.3,1.1.1.7.3,1.1c.2.3.4.6.8.8l7,4.1c.3.2.7.3,1.1.3s.7-.1,1.1-.3l6.9-4c.3-.2.6-.5.8-.8s.3-.7.3-1.1-.1-.7-.3-1.1c-.2-.3-.5-.6-.8-.8l-2.8-1.6c-1.6-.9-3.4-1.4-5.2-1.4-2-.1-3.8.3-5.4,1.3Z"
              fill="#b8e2ef"
            ></path>
            <path
              d="M176,422.1c-.5,0-1.1-.1-1.6-.4l-7-4.1c-.5-.3-.9-.7-1.1-1.1-.3-.5-.4-1-.4-1.6s.1-1.1.4-1.6.7-.9,1.1-1.1l2.7-1.6c1.7-1,3.7-1.5,5.8-1.5c2,0,4,.5,5.8,1.5l2.8,1.6c.5.3.9.7,1.1,1.1.3.5.4,1,.4,1.6s-.1,1.1-.4,1.6-.7.9-1.1,1.1l-6.9,4c-.5.3-1,.5-1.6.5Zm0-11c-1.7,0-3.3.4-4.7,1.3l-2.7,1.6c-.2.1-.3.2-.4.4s-.1.4-.1.5c0,.2.1.4.1.5s.2.3.4.4l7,4.1c.2.1.4.1.5.1.2,0,.4-.1.5-.1l6.9-4c.2-.1.3-.2.4-.4s.1-.4.1-.5-.1-.4-.1-.6c-.1-.2-.2-.3-.4-.4l-2.8-1.6c-1.4-.9-3.1-1.3-4.7-1.3Z"
              fill="#eaf2fa"
            ></path>
            <path
              d="M177.7,420.7l6.5-3.8c.5-.3,1.1-.5,1.8-.5.6,0,1.2.2,1.8.5.5.3,1,.8,1.3,1.3s.5,1.2.5,1.8v10.2c0,.6-.2,1.2-.5,1.8s-.8,1-1.3,1.3l-6.5,3.8c-.5.3-1.1.5-1.8.5-.6,0-1.2-.2-1.8-.5-.5-.3-1-.8-1.3-1.3s-.5-1.1-.5-1.8v-10.2c0-.6.2-1.2.5-1.8.3-.5.7-1,1.3-1.3Z"
              fill="#2c839e"
            ></path>
            <path
              d="M179.4,438.5c-1.2,0-2.4-.5-3.2-1.3s-1.3-2-1.3-3.2v-10.2c0-.8.2-1.6.6-2.3s1-1.3,1.7-1.7l6.5-3.8c.7-.4,1.5-.6,2.3-.6s1.6.2,2.3.6s1.3,1,1.7,1.7.6,1.5.6,2.3v10.2c0,.8-.2,1.6-.6,2.3s-1,1.3-1.7,1.7l-6.5,3.8c-.8.3-1.6.5-2.4.5Zm6.6-21c-.4,0-.9.1-1.3.3l-6.5,3.8c-.4.2-.7.5-.9.9s-.3.8-.3,1.3v10.2c0,.4.1.9.3,1.3s.5.7.9.9.8.3,1.3.3c.4,0,.9-.1,1.3-.3l6.5-3.8c.4-.2.7-.5.9-.9s.3-.8.3-1.3v-10.2c0-.4-.1-.9-.3-1.3s-.5-.7-.9-.9c-.5-.2-.9-.3-1.3-.3Z"
              fill="#eaf2fa"
            ></path>
          </g>
          <g>
            <path
              d="M86.3,400.6l-16.9-9.8c-1.4-.8-3-1.2-4.6-1.2s-3.2.4-4.6,1.2-2.6,2-3.4,3.4-1.2,3-1.2,4.6v26.5c0,1.6.4,3.2,1.2,4.6s2,2.6,3.4,3.4l16.9,9.8c1.4.8,3,1.2,4.6,1.2s3.2-.4,4.6-1.2s2.6-2,3.4-3.4s1.2-3,1.2-4.6v-26.5c0-1.6-.4-3.2-1.2-4.6-.9-1.4-2-2.5-3.4-3.4Z"
              fill="#3aafd2"
            ></path>
            <path
              d="M81.7,445.3c-1.8,0-3.5-.5-5.1-1.4l-16.9-9.8c-1.5-.9-2.8-2.2-3.7-3.7s-1.4-3.3-1.4-5.1v-26.5c0-1.8.5-3.6,1.4-5.1.9-1.6,2.2-2.8,3.7-3.7s3.3-1.4,5.1-1.4s3.5.5,5.1,1.4l16.9,9.8c1.5.9,2.8,2.2,3.7,3.7s1.4,3.3,1.4,5.1v26.5c0,1.8-.5,3.6-1.4,5.1-.9,1.6-2.2,2.8-3.7,3.7-1.5,1-3.3,1.4-5.1,1.4ZM64.8,390.6c-1.4,0-2.8.4-4.1,1.1-1.2.7-2.3,1.7-3,3-.7,1.2-1.1,2.7-1.1,4.1v26.5c0,1.4.4,2.8,1.1,4.1.7,1.2,1.7,2.3,3,3l16.9,9.8c1.2.7,2.6,1.1,4.1,1.1c1.4,0,2.8-.4,4.1-1.1c1.2-.7,2.3-1.8,3-3s1.1-2.7,1.1-4.1v-26.5c0-1.4-.4-2.8-1.1-4.1-.7-1.2-1.7-2.3-3-3l-16.9-9.8c-1.2-.7-2.7-1.1-4.1-1.1v0Z"
              fill="#eaf2fa"
            ></path>
            <path
              d="M77.4,376.7l-7.1,4.1c-.8.5-1.5,1.2-2,2s-.7,1.8-.7,2.7c0,1,.3,1.9.7,2.7.5.8,1.2,1.5,2,2l18.1,10.5c.8.5,1.8.7,2.7.7c1,0,1.9-.3,2.7-.7l17.9-10.4c.8-.5,1.5-1.2,2-2s.7-1.8.7-2.7c0-1-.3-1.9-.7-2.7-.5-.8-1.2-1.5-2-2l-7.3-4.2c-4.1-2.4-8.8-3.6-13.6-3.6-4.6,0-9.2,1.2-13.4,3.6Z"
              fill="#b8e2ef"
            ></path>
            <path
              d="M91.2,402.6c-1.1,0-2.3-.3-3.2-.9L69.9,391.2c-1-.6-1.8-1.4-2.4-2.4s-.9-2.1-.9-3.3c0-1.1.3-2.3.9-3.3s1.4-1.8,2.4-2.4l7.1-4.1c4.3-2.5,9.2-3.8,14.1-3.8c5,0,9.8,1.3,14.1,3.8l7.3,4.2c1,.6,1.8,1.4,2.4,2.4s.9,2.1.9,3.3c0,1.1-.3,2.3-.9,3.3s-1.4,1.8-2.4,2.4L94.6,401.7c-1.2.6-2.3.9-3.4.9ZM77.4,376.7l.5.9-7.1,4.1c-.7.4-1.2,1-1.6,1.6-.4.7-.6,1.4-.6,2.2s.2,1.6.6,2.2c.4.7,1,1.2,1.6,1.6L89,399.9c.7.4,1.4.6,2.2.6s1.6-.2,2.2-.6l17.9-10.4c.7-.4,1.2-1,1.6-1.6s.6-1.5.6-2.2c0-.8-.2-1.6-.6-2.2s-1-1.2-1.6-1.6l-7.3-4.2c-4-2.3-8.5-3.5-13.1-3.5s-9.1,1.2-13.1,3.5l-.4-1Z"
              fill="#eaf2fa"
            ></path>
            <path
              d="M95.5,400.6l16.9-9.8c1.4-.8,3-1.2,4.6-1.2s3.2.4,4.6,1.2s2.6,2,3.4,3.4s1.2,3,1.2,4.6v26.5c0,1.6-.4,3.2-1.2,4.6s-2,2.6-3.4,3.4l-16.9,9.8c-1.4.8-3,1.2-4.6,1.2s-3.2-.4-4.6-1.2-2.6-2-3.4-3.4-1.2-3-1.2-4.6v-26.5c0-1.6.4-3.2,1.2-4.6s2-2.5,3.4-3.4Z"
              fill="#2c839e"
            ></path>
            <path
              d="M100.1,445.3c-1.8,0-3.5-.5-5.1-1.4s-2.8-2.2-3.7-3.7c-.9-1.6-1.4-3.3-1.4-5.1v-26.5c0-1.8.5-3.5,1.4-5.1.9-1.5,2.2-2.8,3.7-3.7l16.9-9.8c1.5-.9,3.3-1.4,5.1-1.4s3.5.5,5.1,1.4c1.5.9,2.8,2.2,3.7,3.7s1.4,3.3,1.4,5.1v26.5c0,1.8-.5,3.5-1.4,5.1-.9,1.5-2.2,2.8-3.7,3.7l-16.9,9.8c-1.6,1-3.3,1.4-5.1,1.4ZM117,390.6c-1.4,0-2.8.4-4.1,1.1L96,401.5c-1.2.7-2.3,1.8-3,3s-1.1,2.7-1.1,4.1v26.5c0,1.4.4,2.8,1.1,4.1.7,1.2,1.7,2.3,3,3c1.2.7,2.6,1.1,4.1,1.1c1.4,0,2.8-.4,4.1-1.1l16.9-9.8c1.2-.7,2.3-1.8,3-3s1.1-2.7,1.1-4.1v-26.5c0-2.2-.9-4.3-2.4-5.8-1.6-1.5-3.6-2.4-5.8-2.4v0Z"
              fill="#eaf2fa"
            ></path>
          </g>
          <g>
            <path
              d="M95.5,447.7l-6.6-3.8c-.5-.3-1.2-.5-1.8-.5s-1.3.2-1.8.5c-.6.3-1,.8-1.3,1.3s-.5,1.2-.5,1.8v10.3c0,.6.2,1.3.5,1.8s.8,1,1.3,1.3l6.6,3.8c.5.3,1.2.5,1.8.5s1.2-.2,1.8-.5c.5-.3,1-.8,1.3-1.3s.5-1.2.5-1.8v-10.3c0-.6-.2-1.2-.5-1.8-.3-.5-.8-1-1.3-1.3Z"
              fill="#3aafd2"
            ></path>
            <path
              d="M93.7,465.8c-.8,0-1.6-.2-2.3-.6l-6.6-3.8c-.7-.4-1.3-1-1.7-1.7s-.6-1.5-.6-2.3v-10.4c0-.8.2-1.6.6-2.3s1-1.3,1.7-1.7s1.5-.6,2.3-.6s1.6.2,2.3.6l6.6,3.8c.7.4,1.3,1,1.7,1.7s.6,1.5.6,2.3v10.3c0,1.2-.5,2.4-1.4,3.3-.8.8-2,1.3-3.2,1.4Zm-6.6-21.4c-.5,0-.9.1-1.3.3s-.7.5-.9.9-.3.8-.3,1.3v10.3c0,.5.1.9.3,1.3s.5.7.9.9l6.6,3.8c.4.2.8.3,1.3.3s.9-.1,1.3-.3.7-.6.9-.9.3-.8.3-1.3v-10.3c0-.5-.1-.9-.3-1.3s-.5-.7-.9-.9l-6.6-3.8c-.4-.2-.9-.3-1.3-.3Z"
              fill="#eaf2fa"
            ></path>
            <path
              d="M92,438.3l-2.8,1.6c-.3.2-.6.5-.8.8s-.3.7-.3,1.1.1.7.3,1.1c.2.3.5.6.8.8l7.1,4.1c.3.2.7.3,1.1.3s.7-.1,1.1-.3l7-4.1c.3-.2.6-.5.8-.8s.3-.7.3-1.1-.1-.7-.3-1.1c-.2-.3-.5-.6-.8-.8l-2.8-1.6c-1.6-.9-3.5-1.4-5.3-1.4-1.9,0-3.8.5-5.4,1.4Z"
              fill="#b8e2ef"
            ></path>
            <path
              d="M97.4,449c-.6,0-1.1-.1-1.6-.4l-7.1-4.1c-.5-.3-.9-.7-1.2-1.2s-.4-1-.4-1.6.1-1.1.4-1.6.7-.9,1.2-1.2l2.8-1.6c1.8-1,3.8-1.6,5.8-1.6s4.1.5,5.8,1.6l2.8,1.6c.5.3.9.7,1.2,1.2s.4,1,.4,1.6-.1,1.1-.4,1.6-.7.9-1.2,1.2l-7,4.1c-.4.3-.9.4-1.5.4Zm-.1-11.1c-1.7,0-3.3.4-4.8,1.3l-2.8,1.6c-.2.1-.3.2-.4.4s-.1.4-.1.6.1.4.1.6c.1.2.2.3.4.4l7.1,4.1c.2.1.4.1.6.1s.4-.1.6-.1l7-4.1c.2-.1.3-.2.4-.4s.2-.4.2-.6-.1-.4-.2-.6-.2-.3-.4-.4l-2.8-1.6c-1.5-.9-3.2-1.3-4.9-1.3v0Z"
              fill="#eaf2fa"
            ></path>
            <path
              d="M99,447.7l6.6-3.8c.5-.3,1.2-.5,1.8-.5s1.3.2,1.8.5s1,.8,1.3,1.3.5,1.2.5,1.8v10.3c0,.6-.2,1.3-.5,1.8s-.8,1-1.3,1.3l-6.6,3.8c-.5.3-1.2.5-1.8.5s-1.3-.2-1.8-.5-1-.8-1.3-1.3-.5-1.2-.5-1.8v-10.3c0-.6.2-1.3.5-1.8.4-.5.8-1,1.3-1.3Z"
              fill="#2c839e"
            ></path>
            <path
              d="M100.9,465.8c-1.2,0-2.4-.5-3.2-1.4-.9-.9-1.3-2-1.4-3.3v-10.3c0-.8.2-1.6.6-2.3s1-1.3,1.7-1.7l6.6-3.8c.7-.4,1.5-.6,2.3-.6s1.6.2,2.3.6s1.3,1,1.7,1.7.6,1.5.6,2.3v10.3c0,.8-.2,1.6-.6,2.3s-1,1.3-1.7,1.7l-6.6,3.8c-.7.4-1.5.7-2.3.7Zm6.6-21.4c-.5,0-.9.1-1.3.3l-6.6,3.8c-.4.2-.7.6-.9.9-.2.4-.3.8-.3,1.3v10.3c0,.5.1.9.3,1.3s.6.7.9.9c.4.2.8.3,1.3.3s.9-.1,1.3-.3l6.6-3.8c.4-.2.7-.6.9-.9.2-.4.3-.8.3-1.3v-10.2c0-.5-.1-.9-.3-1.3s-.6-.7-.9-.9c-.4-.3-.9-.4-1.3-.4Z"
              fill="#eaf2fa"
            ></path>
          </g>
          <g>
            <g clip-path="url(#efDeGdW83Eu217)">
              <g>
                <g opacity="0.69">
                  <path
                    d="M583.7,536.5c11.7,0,21.2-5.5,21.2-12.3s-9.5-12.3-21.2-12.3-21.2,5.5-21.2,12.3s9.5,12.3,21.2,12.3Z"
                    fill="#012454"
                  ></path>
                </g>
              </g>
              <clipPath id="efDeGdW83Eu217">
                <use
                  xlinkHref="#efDeGdW83Eu3"
                  transform="translate(562.5 511.9)"
                ></use>
              </clipPath>
            </g>
          </g>
          <path
            d="M580.6,490.3l6.1-10.7-23.9-13.9c-1.9-1.1-3.5-2.7-4.6-4.6s-1.7-4.1-1.7-6.3v-116.8h-12.3v116.8c0,4.4,1.2,8.7,3.3,12.5c2.2,3.8,5.3,6.9,9.1,9.1l24,13.9Z"
            fill="#0b0b3c"
          ></path>
          <path
            d="M587.2,504l12.1-7c2.4-1.4,4.3-3.4,5.7-5.7c1.4-2.4,2.1-5.1,2.1-7.8v-59.3h-12.3v59.3c0,.6-.2,1.2-.4,1.7-.3.5-.7.9-1.2,1.2l-12.1,7l6.1,10.6Z"
            fill="#0b0b3c"
          ></path>
          <path
            d="M583.6,526.6c1.6,0,3.2-.7,4.3-1.8c1.2-1.2,1.8-2.7,1.8-4.4v-72.6c-.1-1.6-.8-3.1-1.9-4.2s-2.7-1.7-4.2-1.7-3.1.6-4.2,1.7-1.8,2.6-1.9,4.2v72.6c0,1.6.6,3.2,1.8,4.4c1.1,1.2,2.7,1.8,4.3,1.8Z"
            fill="#0b0b3c"
          ></path>
          <g>
            <path
              d="M610.4,353.9L624,346c1.1-.6,2.4-1,3.7-1s2.6.3,3.7,1c1.1.6,2,1.6,2.7,2.7s1,2.4,1,3.7v21.2c0,1.3-.3,2.6-1,3.7-.6,1.1-1.6,2.1-2.7,2.7l-13.6,7.9c-1.1.6-2.4,1-3.7,1s-2.6-.3-3.7-1c-1.1-.6-2-1.6-2.7-2.7-.6-1.1-1-2.4-1-3.7v-21.2c0-1.3.3-2.6,1-3.7s1.6-2.1,2.7-2.7Z"
              fill="#3aafd2"
            ></path>
            <path
              d="M614.1,390c1.5,0,2.9-.4,4.2-1.2l13.6-7.9c1.3-.7,2.3-1.8,3.1-3.1.7-1.3,1.1-2.8,1.1-4.2v-21.2c0-1.5-.4-3-1.1-4.2-.7-1.3-1.8-2.4-3.1-3.1s-2.7-1.1-4.2-1.1-2.9.4-4.2,1.1L609.9,353c-1.3.7-2.3,1.8-3.1,3.1-.7,1.3-1.1,2.8-1.1,4.2v21.2c0,2.3.9,4.4,2.5,6c1.5,1.6,3.7,2.5,5.9,2.5Zm13.6-43.9c1.7,0,3.3.7,4.4,1.9s1.8,2.8,1.8,4.5v21.2c0,1.1-.3,2.2-.8,3.2-.6,1-1.3,1.8-2.3,2.3l-13.6,7.9c-1,.6-2,.8-3.1.8s-2.2-.3-3.1-.8c-1-.6-1.7-1.4-2.3-2.3-.6-1-.8-2-.8-3.2v-21.2c0-1.1.3-2.2.8-3.2.6-1,1.3-1.8,2.3-2.3l13.6-7.9c.9-.6,2-.9,3.1-.9Z"
              fill="#eaf2fa"
            ></path>
            <path
              d="M603.1,353.9L589.5,346c-1.1-.6-2.4-1-3.7-1s-2.6.3-3.7,1c-1.1.6-2,1.6-2.7,2.7-.6,1.1-1,2.4-1,3.7v21.2c0,1.3.3,2.6,1,3.7.6,1.1,1.6,2.1,2.7,2.7l13.6,7.9c1.1.6,2.4,1,3.7,1s2.6-.3,3.7-1c1.1-.6,2.1-1.6,2.7-2.7s1-2.4,1-3.7v-21.2c0-1.3-.3-2.6-1-3.7s-1.6-2.1-2.7-2.7Z"
              fill="#2c839e"
            ></path>
            <path
              d="M599.4,390c2.2,0,4.4-.9,6-2.5s2.5-3.7,2.5-6v-21.2c0-1.5-.4-3-1.1-4.2-.7-1.3-1.8-2.4-3.1-3.1l-13.6-7.9c-1.3-.7-2.7-1.1-4.2-1.1s-2.9.4-4.2,1.1-2.4,1.8-3.1,3.1-1.1,2.8-1.1,4.2v21.2c0,1.5.4,2.9,1.1,4.2s1.8,2.4,3.1,3.1l13.6,7.9c1.2.8,2.6,1.2,4.1,1.2Zm-13.6-43.9c1.1,0,2.2.3,3.1.9l13.6,7.9c1,.6,1.7,1.3,2.3,2.3s.8,2,.8,3.2v21.2c0,1.1-.3,2.2-.8,3.2-.6,1-1.3,1.8-2.3,2.3-1,.6-2,.8-3.1.8s-2.2-.3-3.1-.8l-13.6-7.9c-1-.6-1.7-1.4-2.3-2.3-.6-1-.8-2-.8-3.2v-21.2c0-1.7.7-3.3,1.8-4.5s2.7-1.9,4.4-1.9Z"
              fill="#eaf2fa"
            ></path>
            <path
              d="M595.9,334.7l-5.7,3.3c-.7.4-1.2.9-1.6,1.6s-.6,1.4-.6,2.2.2,1.5.6,2.2.9,1.2,1.6,1.6l14.5,8.4c.7.4,1.4.6,2.2.6s1.5-.2,2.2-.6l14.4-8.4c.7-.4,1.2-.9,1.6-1.6s.6-1.4.6-2.2-.2-1.5-.6-2.2-.9-1.2-1.6-1.6l-5.8-3.4c-3.3-1.9-7.1-2.9-10.9-2.9s-7.5,1-10.9,3Z"
              fill="#b8e2ef"
            ></path>
            <path
              d="M607,355.7c1,0,1.9-.2,2.7-.7l14.4-8.4c.8-.5,1.5-1.2,2-2s.7-1.8.7-2.7c0-1-.3-1.9-.7-2.7-.5-.8-1.2-1.5-2-2l-5.8-3.4c-3.5-2-7.4-3.1-11.5-3.1-4,0-8,1.1-11.5,3.1l-5.7,3.3c-.8.5-1.5,1.2-2,2s-.7,1.8-.7,2.7c0,1,.3,1.9.7,2.7.5.8,1.2,1.5,2,2l14.5,8.4c1,.6,1.9.8,2.9.8Zm-.1-22.9c3.6,0,7.2,1,10.4,2.8l5.8,3.4c.5.3.9.7,1.2,1.2s.4,1.1.4,1.7-.2,1.2-.4,1.7c-.3.5-.7.9-1.2,1.2l-14.4,8.4c-.5.3-1.1.4-1.7.4s-1.2-.2-1.7-.4l-14.5-8.4c-.5-.3-.9-.7-1.2-1.2s-.4-1.1-.4-1.7.2-1.2.4-1.7.7-.9,1.2-1.2l5.7-3.3c3.1-1.9,6.7-2.9,10.4-2.9Z"
              fill="#eaf2fa"
            ></path>
          </g>
          <g>
            <path
              d="M546.1,313.8l-16-9.3c-1.3-.8-2.8-1.2-4.3-1.2s-3,.4-4.3,1.2-2.4,1.9-3.2,3.2-1.2,2.8-1.2,4.3v65.1c0,1.5.4,3,1.2,4.3s1.9,2.4,3.2,3.2l16,9.2c1.3.8,2.8,1.2,4.3,1.2s3-.4,4.3-1.2s2.4-1.9,3.2-3.2s1.2-2.8,1.2-4.3v-65.1c0-1.5-.4-3-1.2-4.3-.8-1.2-1.9-2.3-3.2-3.1Z"
              fill="#2c839e"
            ></path>
            <path
              d="M541.7,396.2c2.6,0,5.1-1,6.9-2.9c1.8-1.8,2.8-4.3,2.8-6.9v-65.1c0-1.7-.5-3.4-1.3-4.9-.9-1.5-2.1-2.7-3.6-3.6l-16-9.3c-1.5-.8-3.2-1.3-4.9-1.3s-3.4.5-4.9,1.3c-1.5.9-2.7,2.1-3.6,3.6s-1.3,3.2-1.3,4.9v65.1c0,1.7.5,3.4,1.3,4.9.9,1.5,2.1,2.7,3.6,3.6l16,9.3c1.7.8,3.3,1.3,5,1.3Zm-15.9-91.7c1.3,0,2.6.4,3.8,1l16,9.3c1.1.7,2.1,1.6,2.8,2.8s1,2.5,1,3.8v65.1c0,1.3-.3,2.6-1,3.8s-1.6,2.1-2.8,2.8-2.5,1-3.8,1-2.6-.4-3.8-1l-16-9.3c-1.1-.7-2.1-1.6-2.8-2.8s-1-2.5-1-3.8v-65.1c0-1.3.3-2.6,1-3.8s1.6-2.1,2.8-2.8c1.1-.6,2.5-1,3.8-1v0Z"
              fill="#eaf2fa"
            ></path>
            <path
              d="M537.7,291.3l-6.7,3.9c-.8.5-1.4,1.1-1.9,1.9s-.7,1.7-.7,2.6.2,1.8.7,2.6s1.1,1.4,1.9,1.9l17.1,9.9c.8.5,1.7.7,2.6.7s1.8-.2,2.6-.7l16.9-9.8c.8-.5,1.4-1.1,1.9-1.9s.7-1.7.7-2.6-.2-1.8-.7-2.6-1.1-1.4-1.9-1.9l-6.8-4c-3.9-2.3-8.3-3.4-12.8-3.4-4.6-.1-9,1.1-12.9,3.4Z"
              fill="#b8e2ef"
            ></path>
            <path
              d="M550.7,315.7c1.1,0,2.2-.3,3.1-.8l16.9-9.8c1-.5,1.7-1.3,2.3-2.3.5-1,.8-2,.8-3.1s-.3-2.2-.8-3.1c-.6-1-1.3-1.7-2.3-2.3l-6.8-4c-4.1-2.3-8.7-3.6-13.4-3.6s-9.3,1.2-13.4,3.6l-6.7,3.9c-1,.5-1.7,1.3-2.3,2.3s-.8,2-.8,3.1.3,2.2.8,3.1c.6,1,1.3,1.7,2.3,2.3l17.1,9.9c1,.5,2.1.8,3.2.8Zm-.2-26.8c4.3,0,8.5,1.1,12.3,3.3l6.8,4c.6.4,1.1.9,1.5,1.5s.5,1.3.5,2-.2,1.4-.5,2c-.4.6-.9,1.1-1.5,1.5L552.7,313c-.6.4-1.3.5-2,.5s-1.4-.2-2-.5l-17.1-9.9c-.6-.4-1.1-.9-1.5-1.5s-.5-1.3-.5-2s.2-1.4.5-2c.4-.6.9-1.1,1.5-1.5l6.7-3.9c3.7-2.2,7.9-3.3,12.2-3.3Z"
              fill="#eaf2fa"
            ></path>
            <path
              d="M554.7,313.8l16-9.3c1.3-.8,2.8-1.2,4.3-1.2s3,.4,4.3,1.2s2.4,1.9,3.2,3.2s1.2,2.8,1.2,4.3v65.1c0,1.5-.4,3-1.2,4.3s-1.9,2.4-3.2,3.2l-16,9.2c-1.3.8-2.8,1.2-4.3,1.2s-3-.4-4.3-1.2-2.4-1.9-3.2-3.2-1.2-2.8-1.2-4.3v-65.1c0-1.5.4-3,1.2-4.3.8-1.2,1.9-2.3,3.2-3.1Z"
              fill="#3aafd2"
            ></path>
            <path
              d="M559,396.2c1.7,0,3.4-.5,4.9-1.3l16-9.3c1.5-.9,2.7-2.1,3.6-3.6s1.3-3.2,1.3-4.9v-65.1c0-1.7-.5-3.4-1.3-4.9-.9-1.5-2.1-2.7-3.6-3.6s-3.2-1.3-4.9-1.3-3.4.4-4.9,1.3l-16,9.3c-1.5.9-2.7,2.1-3.6,3.6s-1.3,3.2-1.3,4.9v65.1c0,2.6,1,5.1,2.9,6.9c1.9,1.9,4.3,2.9,6.9,2.9Zm16-91.7c1.3,0,2.6.4,3.8,1c1.1.7,2.1,1.6,2.8,2.8s1,2.5,1,3.8v65.1c0,1.3-.4,2.6-1,3.8-.7,1.2-1.6,2.1-2.8,2.8l-16,9.3c-1.1.7-2.5,1-3.8,1s-2.6-.4-3.8-1c-1.1-.7-2.1-1.6-2.8-2.8s-1-2.5-1-3.8v-65.1c0-1.3.4-2.6,1-3.8s1.6-2.1,2.8-2.8l16-9.3c1.1-.7,2.4-1,3.8-1v0Z"
              fill="#eaf2fa"
            ></path>
          </g>
          <g>
            <path
              d="M576.7,395.8l-25.6-14.9c-2.1-1.2-4.5-1.9-6.9-1.9s-4.8.6-6.9,1.9-3.9,3-5.1,5.1-1.9,4.5-1.9,7v40c0,2.4.6,4.9,1.9,7c1.2,2.1,3,3.9,5.1,5.1L562.9,460c2.1,1.2,4.5,1.9,6.9,1.9s4.8-.6,6.9-1.9c2.1-1.2,3.9-3,5.1-5.1s1.9-4.5,1.9-7v-40.1c0-2.4-.6-4.8-1.9-7-1.3-2.1-3-3.8-5.1-5Z"
              fill="#2c839e"
            ></path>
            <path
              d="M569.7,462.9c4,0,7.8-1.6,10.6-4.4s4.4-6.6,4.4-10.6v-40c0-2.6-.7-5.2-2-7.5s-3.2-4.2-5.5-5.5L551.6,380c-2.3-1.3-4.9-2-7.5-2s-5.2.7-7.5,2-4.2,3.2-5.5,5.5-2,4.9-2,7.5v40c0,2.6.7,5.2,2,7.5s3.2,4.2,5.5,5.5l25.6,14.9c2.3,1.3,4.9,2,7.5,2Zm6.4-66.2c1.9,1.1,3.5,2.8,4.7,4.7c1.1,2,1.7,4.2,1.7,6.4v40c0,2.3-.6,4.5-1.7,6.4-1.1,2-2.7,3.6-4.7,4.7-1.9,1.1-4.2,1.7-6.4,1.7s-4.5-.6-6.4-1.7L537.7,444c-1.9-1.1-3.6-2.8-4.7-4.7-1.1-2-1.7-4.2-1.7-6.4v-40c0-2.3.6-4.5,1.7-6.4c1.1-2,2.7-3.6,4.7-4.7c1.9-1.1,4.2-1.7,6.4-1.7s4.5.6,6.4,1.7l25.6,14.9Z"
              fill="#eaf2fa"
            ></path>
            <path
              d="M563.2,359.5l-10.7,6.2c-1.3.7-2.3,1.8-3,3-.7,1.3-1.1,2.7-1.1,4.2s.4,2.9,1.1,4.2s1.8,2.3,3,3L580,396c1.3.7,2.7,1.1,4.1,1.1s2.9-.4,4.1-1.1l27.1-15.7c1.3-.7,2.3-1.8,3-3c.7-1.3,1.1-2.7,1.1-4.2s-.4-2.9-1.1-4.2-1.8-2.3-3-3l-11-6.4c-6.3-3.6-13.4-5.5-20.6-5.5-7.1,0-14.2,1.9-20.5,5.5Z"
              fill="#b8e2ef"
            ></path>
            <path
              d="M584.1,398.2c1.6,0,3.3-.4,4.7-1.3L616,381.2c1.4-.8,2.6-2,3.4-3.4s1.2-3,1.2-4.7c0-1.6-.4-3.3-1.2-4.7s-2-2.6-3.4-3.4l-11-6.4c-6.4-3.7-13.7-5.7-21.1-5.7s-14.7,2-21.1,5.7l-10.7,6.2c-1.4.8-2.6,2-3.4,3.4s-1.2,3-1.2,4.7c0,1.6.4,3.3,1.2,4.7s2,2.6,3.4,3.4l27.4,15.9c1.3.9,3,1.3,4.6,1.3Zm-.3-43.1c7,0,14,1.8,20.1,5.4l11,6.4c1.1.6,2,1.6,2.6,2.7s1,2.3,1,3.6-.3,2.5-1,3.6c-.6,1.1-1.5,2-2.6,2.7l-27.2,15.7c-1.1.6-2.3,1-3.6,1s-2.5-.3-3.6-1l-27.5-16c-1.1-.6-2-1.6-2.6-2.6-.6-1.1-1-2.3-1-3.6s.3-2.5,1-3.6c.6-1.1,1.5-2,2.6-2.7l10.7-6.2c6.1-3.6,13.1-5.4,20.1-5.4Z"
              fill="#eaf2fa"
            ></path>
            <path
              d="M590.6,395.8l25.6-14.9c2.1-1.2,4.5-1.9,6.9-1.9s4.8.6,6.9,1.9c2.1,1.2,3.9,3,5.1,5.1s1.9,4.5,1.9,7v40c0,2.4-.6,4.9-1.9,7-1.2,2.1-3,3.9-5.1,5.1L604.5,460c-2.1,1.2-4.5,1.9-6.9,1.9s-4.8-.6-6.9-1.9-3.9-3-5.1-5.1-1.9-4.5-1.9-7v-40.1c0-2.4.7-4.8,1.9-7c1.1-2.1,2.9-3.8,5-5Z"
              fill="#3aafd2"
            ></path>
            <path
              d="M597.5,462.9c2.6,0,5.2-.7,7.5-2L630.6,446c2.3-1.3,4.2-3.2,5.5-5.5s2-4.9,2-7.5v-40c0-2.6-.7-5.2-2-7.5s-3.2-4.2-5.5-5.5-4.9-2-7.5-2-5.2.7-7.5,2L590,394.7c-2.3,1.3-4.2,3.2-5.5,5.5s-2,4.9-2,7.5v40c0,2.6.7,5.3,2,7.5c1.3,2.3,3.2,4.2,5.5,5.5c2.3,1.5,4.9,2.2,7.5,2.2Zm25.6-82.8c3.4,0,6.6,1.4,9,3.8s3.7,5.7,3.8,9.1v40c0,2.3-.6,4.5-1.7,6.4-1.1,2-2.7,3.6-4.7,4.7L603.9,459c-1.9,1.1-4.2,1.7-6.4,1.7s-4.5-.6-6.4-1.7-3.6-2.8-4.7-4.7c-1.1-2-1.7-4.2-1.7-6.4v-40c0-2.3.6-4.5,1.7-6.4s2.7-3.6,4.7-4.7l25.6-14.9c2-1.2,4.2-1.8,6.4-1.8v0Z"
              fill="#eaf2fa"
            ></path>
          </g>
          <path
            d="M620.5,442.3l16.2,9.4c.3.2.7.3,1,.3.4,0,.7-.1,1-.3s.6-.4.7-.7c.2-.3.3-.7.3-1v-24h8.2v24c0,1.8-.5,3.6-1.4,5.1-.9,1.6-2.2,2.9-3.7,3.8-1.6.9-3.3,1.4-5.1,1.4s-3.6-.5-5.1-1.4l-16.2-9.4v-4.8l4.1-2.4Z"
            fill="#0b0b3c"
          ></path>
          <g>
            <path
              d="M641.2,413.7l-9.4-5.4c-.8-.5-1.6-.7-2.5-.7s-1.8.2-2.5.7c-.8.4-1.4,1.1-1.9,1.9-.4.8-.7,1.7-.7,2.6v14.7c0,.9.2,1.8.7,2.6.4.8,1.1,1.4,1.9,1.9l9.4,5.4c.8.5,1.6.7,2.5.7s1.8-.2,2.6-.7c.8-.4,1.4-1.1,1.9-1.9.4-.8.7-1.7.7-2.6v-14.6c0-.9-.2-1.8-.7-2.6-.6-.9-1.2-1.5-2-2Z"
              fill="#2c839e"
            ></path>
            <path
              d="M638.7,439c1.1,0,2.1-.3,3.1-.8.9-.5,1.7-1.3,2.3-2.3.5-.9.8-2,.8-3.1v-14.6c0-1.1-.3-2.2-.8-3.1s-1.3-1.7-2.3-2.3l-9.4-5.4c-.9-.5-2-.8-3.1-.8s-2.1.3-3.1.8c-.9.5-1.7,1.3-2.3,2.3-.5.9-.8,2-.8,3.1v14.7c0,1.1.3,2.2.8,3.1s1.3,1.7,2.3,2.3l9.4,5.4c.9.4,2,.7,3.1.7Zm-9.4-30.3c.7,0,1.4.2,2,.5l9.4,5.4c.6.4,1.1.9,1.5,1.5s.5,1.3.5,2v14.6c0,.7-.2,1.4-.5,2-.4.6-.9,1.1-1.5,1.5s-1.3.5-2,.5-1.4-.2-2-.5l-9.4-5.4c-.6-.4-1.1-.9-1.5-1.5s-.5-1.3-.5-2v-14.6c0-1.1.4-2.1,1.2-2.8.7-.8,1.7-1.2,2.8-1.2v0Z"
              fill="#eaf2fa"
            ></path>
            <path
              d="M636.3,400.5l-3.9,2.2c-.5.3-.8.7-1.1,1.1-.3.5-.4,1-.4,1.5s.1,1.1.4,1.5c.3.5.6.8,1.1,1.1l10,5.8c.5.3,1,.4,1.5.4s1.1-.1,1.5-.4l9.9-5.8c.5-.3.8-.6,1.1-1.1s.4-1,.4-1.5-.1-1.1-.4-1.5-.6-.8-1.1-1.1l-4-2.3c-2.3-1.3-4.9-2-7.5-2-2.7.1-5.3.8-7.5,2.1Z"
              fill="#b8e2ef"
            ></path>
            <path
              d="M643.9,415.3c.7,0,1.4-.2,2.1-.6l9.9-5.8c.6-.4,1.1-.9,1.5-1.5s.6-1.3.6-2.1c0-.7-.2-1.4-.6-2.1-.4-.6-.9-1.1-1.5-1.5l-4-2.3c-2.5-1.4-5.2-2.2-8.1-2.2s-5.6.7-8.1,2.2l-3.9,2.3c-.6.4-1.1.9-1.5,1.5s-.5,1.3-.5,2.1c0,.7.2,1.4.5,2.1.4.6.9,1.1,1.5,1.5l10,5.8c.6.4,1.4.6,2.1.6Zm-.1-15.7c2.5,0,4.9.6,7,1.9l4,2.3c.3.2.5.4.7.7s.3.6.3,1c0,.3-.1.7-.3,1s-.4.5-.7.7l-9.9,5.8c-.3.2-.6.3-1,.3s-.7-.1-1-.3l-10-5.8c-.3-.2-.5-.4-.7-.7s-.3-.6-.3-1c0-.3.1-.7.3-1s.4-.5.7-.7l3.9-2.3c2.1-1.3,4.5-1.9,7-1.9v0Z"
              fill="#eaf2fa"
            ></path>
            <path
              d="M646.3,413.7l9.4-5.4c.8-.5,1.6-.7,2.5-.7s1.8.2,2.5.7c.8.4,1.4,1.1,1.9,1.9.4.8.7,1.7.7,2.6v14.7c0,.9-.2,1.8-.7,2.5-.4.8-1.1,1.4-1.9,1.9l-9.4,5.4c-.8.5-1.6.7-2.5.7s-1.8-.2-2.5-.7c-.8-.4-1.4-1.1-1.9-1.9-.4-.8-.7-1.7-.7-2.6v-14.6c0-.9.2-1.8.7-2.6s1.1-1.4,1.9-1.9Z"
              fill="#3aafd2"
            ></path>
            <path
              d="M648.8,439c1.1,0,2.1-.3,3.1-.8l9.4-5.4c.9-.5,1.7-1.3,2.3-2.3.5-.9.8-2,.8-3.1v-14.6c0-1.1-.3-2.2-.8-3.1s-1.3-1.7-2.3-2.3c-.9-.5-2-.8-3.1-.8s-2.1.3-3.1.8l-9.4,5.4c-.9.5-1.7,1.3-2.3,2.3-.5.9-.8,2-.8,3.1v14.6c0,1.1.3,2.2.8,3.1s1.3,1.7,2.3,2.3c1,.5,2,.8,3.1.8Zm9.4-30.3c.7,0,1.4.2,2,.5s1.1.9,1.5,1.5c.3.6.5,1.3.5,2v14.7c0,.7-.2,1.4-.5,2s-.9,1.1-1.5,1.5l-9.4,5.4c-.6.4-1.3.5-2,.5s-1.4-.2-2-.5c-.6-.4-1.1-.9-1.5-1.5s-.5-1.3-.5-2v-14.6c0-.7.2-1.4.5-2c.4-.6.9-1.1,1.5-1.5l9.4-5.4c.6-.4,1.3-.6,2-.6v0Z"
              fill="#eaf2fa"
            ></path>
          </g>
        </g>
        <ellipse
          id="efDeGdW83Eu251"
          rx="70.2"
          ry="40.5"
          transform="matrix(0.9997 -0.025628 0.025628 0.9997 337.109504 489.61477)"
          fill="#012454"
        ></ellipse>
        <g id="efDeGdW83Eu252" transform="translate(0 0)">
          <g clip-path="url(#efDeGdW83Eu1037)">
            <g>
              <path
                d="M286.6,438.4l9.1,5.3v-59.7l-9.1-5.3v59.7Z"
                fill="#e2b566"
              ></path>
              <path
                d="M304.9,438.4l-9.1,5.3v-59.7l9.1-5.3v59.7Z"
                fill="#d69625"
              ></path>
              <path
                d="M313.4,480.6l12.2,7v-31.2l-12.2-7v31.2Z"
                fill="#105687"
              ></path>
              <path
                d="M337.8,480.6l-12.2,7v-31.2l12.2-7v31.2Z"
                fill="#184470"
              ></path>
              <g opacity="0.5">
                <path
                  d="M325.6,480.6l-12.2-7v7.7l12.2,7l12.2-7v-7.7l-12.2,7Z"
                  opacity="0.5"
                  fill="#0b0b3c"
                ></path>
              </g>
              <path
                d="M333.2,493l12.2,7v-31.2l-12.2-7v31.2Z"
                fill="#105687"
              ></path>
              <path
                d="M357.5,493l-12.2,7v-31.2l12.2-7v31.2Z"
                fill="#184470"
              ></path>
              <path
                d="M322.2,366.4l-29.3,17L349.3,416v64l28.8-16.6v-64.6l-55.9-32.4Z"
                fill="#066"
              ></path>
              <path
                d="M293.5,447.6l55.9,32.3v-64.6L293.5,383v64.6Z"
                fill="#099"
              ></path>
              <path
                d="M358,470.9l9.1,5.3v-59.7l-9.1-5.3v59.7Z"
                fill="#e9c689"
              ></path>
              <path
                d="M376.3,470.9l-9.1,5.3v-59.7l9.1-5.3v59.7Z"
                fill="#d69625"
              ></path>
              <path
                d="M296.3,400.3l-12,5.5c22.8,17.6,70.3,37.9,105.3-2.2l22.5-36.1c1.6-2.5,2.5-5.4,2.6-8.4s-.6-6-2-8.6l-49.9-94.1c-1.4-2.6-3.5-4.9-6-6.5s-5.4-2.5-8.4-2.6-5.9.6-8.6,2c-2.6,1.4-4.9,3.5-6.4,6l-33.9,54.2c-8.4,13.5-13.2,29-13.7,44.9-.6,16,3,31.8,10.5,45.9Z"
                fill="#e8c485"
              ></path>
              <path
                d="M342.5,330.2L437,275.5l-94.5-54.7L248,275.5l94.5,54.7Z"
                fill="#d69625"
              ></path>
              <path
                d="M281.1,404l3.4,2c25.8,14.9,58-3.7,58-33.5v-35.9c0-1.9-.5-3.8-1.5-5.5s-2.4-3.1-4-4l-72.5-41.9c-1.7-1-3.6-1.5-5.5-1.5s-3.8.5-5.5,1.5-3.1,2.4-4,4c-1,1.7-1.5,3.6-1.5,5.5v51.9c0,11.6,3.1,23.1,8.9,33.2c5.8,10,14.1,18.4,24.2,24.2Z"
                fill="#e8c485"
              ></path>
              <path
                d="M437,384.9l-61.6,35.6c-3.3,1.9-7.1,2.9-11,2.9s-7.6-1-11-2.9c-3.3-1.9-6.1-4.7-8-8s-2.9-7.1-2.9-11v-71.2L437,275.7v109.2Z"
                fill="#d69625"
              ></path>
              <path
                d="M307.1,388.1v-.4c0-.3-.1-.6-.3-.8s-.4-.4-.7-.5c-9.2-2.8-17.8-7.6-25-14.1l-1.2-1c-.3-.2-.6-.3-1-.3-.3,0-.7.1-1,.3s-.5.4-.7.7-.3.6-.3,1c0,4,1.1,7.9,3.1,11.4s4.9,6.3,8.3,8.3l5.3,3c1.3.8,2.9,1.2,4.4,1.2c1.6,0,3.1-.4,4.4-1.2s2.5-1.9,3.2-3.2c1.1-1.4,1.5-2.9,1.5-4.4Z"
                fill="#ff2760"
              ></path>
              <path
                d="M304.4,385.4c0,0-8.1-1.4-22.2-11.5-1.2-.9-1.7,5.1-.4,9.2c1.2,3.9,2.3,6.9,6.8,9.6l5,3.1c1.4.8,2.9,1.2,4.5,1.2s3.2-.4,4.5-1.2c1.4-.8,2.5-1.9,3.3-3.3s1.2-2.9,1.2-4.5c0-.7,0-2.4-2.7-2.6Z"
                fill="#ff6e8a"
              ></path>
              <path
                d="M277.2,372.9v2.4c0,4.1,1.6,8.1,4.5,11.1l-1.8-15.3c-.3-.1-.6-.2-.9-.2s-.6.1-.9.3-.5.4-.6.7c-.3.4-.3.7-.3,1Z"
                fill="#8c1439"
              ></path>
              <path
                d="M279.9,371.2v4.7c0,.6.1,1.1.4,1.6s.7.9,1.2,1.2l20.8,12c.2.1.4.2.7.2.2,0,.5-.1.7-.2s.4-.3.5-.5.2-.4.2-.7v-4.3l-1.2-.5c-7.3-3-14.2-6.9-20.6-11.7l-2.7-1.8v0Z"
                fill="#eaf2fa"
              ></path>
              <path
                d="M272.6,340.6l-6.7-3.9v10.8l6.7,3.9v-10.8Z"
                fill="#0b0b3c"
              ></path>
              <path
                d="M263.9,335l13.8,8-1,1.8-13.8-8l1-1.8Z"
                fill="#0b0b3c"
              ></path>
              <path
                d="M307.7,360.3l15.2,8.8-1,1.8-15.2-8.8l1-1.8Z"
                fill="#0b0b3c"
              ></path>
              <path
                d="M318.3,368l-6.7-3.9v10.9l6.7,3.9v-10.9Z"
                fill="#0b0b3c"
              ></path>
              <path
                d="M282,341.8v-2.3c0-.5-.1-1-.4-1.4-.2-.4-.6-.8-1-1l-4.3-3.2c-5.5-4.2-11.7-7.5-18.3-9.8-.2-.1-.3-.1-.5-.1s-.4,0-.5.1c-.2.1-.3.2-.4.4s-.1.3-.1.5v3c0,.4.1.8.4,1.1s.6.5,1,.6c7.2,1.6,13.9,5.1,19.3,10.1l3.2,2.9c.2.1.3.1.5.1s.4,0,.5-.1c.2-.1.3-.2.4-.4s.2-.3.2-.5Z"
                fill="#0b0b3c"
              ></path>
              <path
                d="M328.2,368.7v-2.4c0-.5-.1-1-.4-1.5-.3-.4-.6-.8-1.1-1.1l-.7-.7c-6.1-5.9-13.6-10.2-21.8-12.5l-.8-.2c-.2-.1-.3-.1-.5-.1s-.4,0-.5.1c-.2.1-.3.2-.4.4s-.1.3-.1.5v3.2c0,.4.1.8.4,1.1s.6.5,1,.6c7.6,1.4,14.7,5,20.2,10.4l3.1,3c.2.1.3.1.5.1s.4-.1.5-.1c.2-.1.3-.2.4-.4.1-.1.2-.3.2-.4Z"
                fill="#0b0b3c"
              ></path>
              <g opacity="0.5">
                <path
                  d="M345.3,492.4l-12.2-7v7.7l12.2,7l12.2-7v-7.7l-12.2,7Z"
                  opacity="0.5"
                  fill="#0b0b3c"
                ></path>
              </g>
              <path
                d="M289.8,294.9c3.4,2.8.4-16.5,15.4-13.5c12.3,2.5,7.4,19.3,7.4,19.3s12.4-15.6.2-26.9c-17.3-16-37,9.8-23,21.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M310.1,305.4l3.2-4.1c.1-.2,12.8-16.5.1-28.3-5.3-4.9-11.4-6.5-17.2-4.6-3,1.1-5.7,2.9-7.8,5.4-2.1,2.4-3.5,5.4-4.1,8.6-.5,2.4-.3,5,.5,7.3.9,2.3,2.4,4.4,4.3,5.9.2.2.5.4.8.4.3.1.6.1.9,0c1.1-.4,1.5-1.8,2.1-4.1c1.6-6.1,3.7-11.3,12-9.7c1.2.2,2.3.6,3.4,1.3c1,.7,1.9,1.6,2.5,2.6c3.2,5.3.8,14,.7,14.1l-1.4,5.2Zm-9-35.7c3.8,0,7.6,1.6,11,4.8c4.7,4.4,6.1,10,4.2,16.6-.5,1.6-1.1,3.1-1.8,4.6.3-3.2.1-7.2-1.9-10.4-.8-1.3-1.8-2.4-3.1-3.2-1.2-.8-2.7-1.4-4.1-1.6-10.8-2.2-13.1,6.5-14.3,11.1-.2.8-.4,1.6-.8,2.4-1.6-1.3-2.9-3.1-3.6-5-.7-2-.9-4.1-.4-6.2.6-2.8,1.8-5.4,3.7-7.6s4.2-3.8,6.9-4.8c1.3-.5,2.8-.7,4.2-.7Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M301.8,318c.6-1.2-4.9.7-4.7-3.8.1-3.7,5.3-3.1,5.3-3.1"
                fill="#c8c6f7"
              ></path>
              <path
                d="M301.3,318.6c-.1,0-.2-.1-.3-.2s-.1-.2-.2-.3c0-.1-.1-.2-.1-.3s0-.2.1-.3l1.8,1c.1-.2.2-.4.2-.6s0-.4-.1-.6c-.4-.7-1.1-.7-1.9-.8-2-.2-2.8-.5-2.8-2.3c0-.3,0-.5.1-.8.1-.2.3-.5.5-.6c1-.8,2.9-.8,3.5-.8l.2-2c-.3,0-3.3-.3-5.1,1.2-.4.4-.7.8-1,1.3-.2.5-.3,1-.3,1.6-.1,4,3.2,4.2,4.6,4.4.4.1.6.1.8.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M350.6,306.2c-2.4.8,3.3-8.9-5.4-10.4-7.1-1.3-8,8.8-8,8.8s-3.4-11,5.5-14.5c12.7-4.9,17.8,13,7.9,16.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M337.8,309.8l.5-5.1c0-.1.5-5,3.2-7.1.5-.4,1.1-.7,1.7-.8s1.3-.1,1.9,0c4.4.8,4.4,3.7,4,7.2-.2,1.4-.3,2.4.4,3c.2.2.4.3.7.3s.5,0,.7-.1c1.4-.4,2.7-1.3,3.8-2.4c1-1.1,1.7-2.5,1.9-4c.4-1.9.2-3.9-.4-5.7s-1.7-3.4-3.2-4.7c-2.8-2.4-6.6-2.8-10.6-1.3-7.7,3-7.4,11.6-6.1,15.7l1.5,5Zm9.2-19.6c1.7,0,3.4.6,4.7,1.7c1.2,1,2.1,2.4,2.6,3.8.5,1.5.6,3.1.4,4.6-.2,1-.6,2-1.2,2.8s-1.5,1.4-2.4,1.8c0-.3.1-.5.1-.8.3-2.7,1-8.3-5.7-9.5-.9-.2-1.8-.2-2.7,0s-1.7.6-2.5,1.2c-1.1.9-2,2.1-2.6,3.4.1-3,1.3-6.6,5.5-8.3c1.2-.4,2.5-.7,3.8-.7Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M318.8,309.3c1.4-2.6-10.9,1.6-10.6-8.6.2-8.4,11.9-7.1,11.9-7.1s-11.7-6.4-17.8,2.9c-8.5,13.3,10.7,23.4,16.5,12.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M311.3,314.8c1.7,0,3.4-.4,4.9-1.3s2.7-2.1,3.5-3.6c.1-.2.2-.5.2-.7c0-.3,0-.5-.2-.8-.5-.9-1.5-1-3.2-1.1-4-.3-7.6-1.1-7.4-6.5c0-.8.1-1.5.5-2.2.3-.7.8-1.3,1.3-1.8c3-2.6,8.9-2,9-2l5.1.6-4.5-2.5c-.1-.1-12.6-6.8-19.1,3.2-2.7,4.2-3.1,8.5-1.1,12.2c1.1,1.9,2.7,3.5,4.5,4.7c2.1,1.1,4.2,1.7,6.5,1.8v0Zm6.4-5.5c-.7,1.1-1.6,1.9-2.7,2.5s-2.4.9-3.6.9h-.1c-1.8,0-3.6-.6-5.2-1.5s-2.9-2.3-3.8-3.9c-1.2-2.2-1.9-5.7,1-10.2c2.3-3.5,5.7-5,10.1-4.5.5.1,1.1.2,1.6.3-1.9.3-3.7,1.1-5.2,2.3-.8.7-1.4,1.5-1.9,2.5-.4,1-.7,2-.6,3.1-.2,7.7,6.2,8.3,9.3,8.5.3,0,.7,0,1.1,0v0Zm.3-.5l.9.5-.9-.5v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M361.8,307.7c-.4,3-7.8-7.8-13.7.7-4.7,6.9,5.4,12.7,5.4,12.7s-13.2-1.8-12.6-12.9c.9-15.7,22.4-12.4,20.9-.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M358.5,322.8l-4.5-2.6c0,0-5.1-3-6-6.9-.2-.8-.2-1.5,0-2.3s.5-1.5,1-2.1c3-4.4,6.4-2.9,9.8-.8c1.4.8,2.3,1.4,3.2,1c.2-.1.4-.3.6-.5.1-.2.2-.5.2-.7.2-1.7,0-3.5-.7-5-.7-1.6-1.8-2.9-3.2-3.9-1.8-1.3-3.9-2.1-6.1-2.3s-4.4.2-6.4,1.1c-3.8,1.8-6.1,5.6-6.4,10.5-.7,11.9,13.3,13.9,13.5,13.9l5,.6Zm-5.1-18.7c-2,0-4.2.9-6.1,3.7-.6.8-1.1,1.8-1.3,2.8s-.2,2.1,0,3.1c.5,1.8,1.5,3.5,2.8,4.9-.5-.2-1-.5-1.4-.7-3.9-2.2-5.7-5.5-5.5-9.6.3-5.3,3-7.7,5.2-8.8c1.7-.8,3.5-1.1,5.4-.9c1.8.2,3.6.8,5.1,1.9c1,.7,1.9,1.7,2.4,2.8.6,1.1.8,2.4.7,3.7-.3-.2-.7-.4-1-.6-1.4-.9-3.7-2.3-6.3-2.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M333.8,296c1-1.7-7.3,1.1-7.1-5.8.2-5.6,7.9-4.7,7.9-4.7s-7.8-4.3-11.9,1.9c-5.6,8.9,7.2,15.6,11.1,8.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M328.8,299.9c1.2,0,2.4-.3,3.4-.9s1.9-1.5,2.5-2.6c.1-.2.2-.4.2-.7c0-.2,0-.5-.2-.7-.4-.7-1.3-.8-2.4-.9-2.7-.2-4.7-.7-4.7-4c0-.5.1-.9.3-1.4.2-.4.5-.8.8-1.1c1.6-1.4,4.7-1.4,5.7-1.3l5.1.6-4.5-2.5c-3-1.6-9.6-3.3-13.2,2.3-1.9,2.9-2.1,5.9-.7,8.5.8,1.3,1.8,2.5,3.2,3.3c1.4,1,2.9,1.4,4.5,1.4v0Zm3.6-3.6c-.4.5-1,1-1.6,1.2-.6.3-1.3.4-2,.4v0c-1.2,0-2.3-.4-3.3-1s-1.8-1.5-2.4-2.5c-.7-1.4-1.2-3.6.6-6.5.6-1,1.5-1.8,2.5-2.3s2.2-.7,3.4-.6c-.7.3-1.4.7-2,1.2s-1,1.1-1.3,1.8-.5,1.4-.5,2.2c-.2,5.5,4.3,5.9,6.5,6.1h.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M327.8,291.6c.6,1.9,4.3-6,9.1-1.2c3.9,4-2.1,9-2.1,9s8.5-2.7,6.8-9.9c-2.4-10.3-16.2-5.6-13.8,2.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M330.2,301.9l4.9-1.6c3.3-1,9-4.7,7.5-11.1-.8-3.3-2.8-5.6-5.7-6.4-1.5-.4-3.1-.4-4.5,0-1.5.4-2.8,1.2-3.9,2.3-.9.9-1.5,1.9-1.8,3.1s-.2,2.4.1,3.6c.1.2.2.4.3.6.2.2.4.3.6.4.8.2,1.5-.4,2.3-1.1c2-1.8,3.8-3,6.1-.6.3.3.6.7.8,1.1s.3.9.2,1.4c-.1,2.5-3,5-3,5l-3.9,3.3Zm4.6-17.3c.5,0,1.1.1,1.6.2c1.5.4,3.4,1.6,4.2,4.9.3,1.1.3,2.3-.1,3.4-.3,1.1-1,2.1-1.9,2.9.3-.7.5-1.5.6-2.3c0-.8-.1-1.5-.4-2.2s-.7-1.3-1.3-1.9c-3.8-3.9-7.2-.9-8.9.5l-.1.1c-.1-.7,0-1.4.2-2c.2-.7.6-1.2,1.1-1.7c1.4-1.2,3.2-2,5-1.9Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M285,297.6c-.6-1.9-4.3,6-9.1,1-3.9-4.1,2.2-9,2.2-9s-8.5,2.6-6.9,9.8c2.3,10.4,16.1,5.9,13.8-1.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M278.1,306.5c2.3,0,4.6-.9,6.2-2.5.9-.8,1.5-1.9,1.8-3.1s.3-2.4-.1-3.6c-.1-.2-.2-.4-.3-.6-.2-.2-.4-.3-.6-.4-.8-.2-1.5.3-2.3,1-2,1.8-3.8,2.9-6.1.5-.3-.3-.6-.7-.8-1.1s-.3-.9-.2-1.4c.2-2.5,3.1-5,3.1-5l4-3.2-4.9,1.5c-3.3,1-9.1,4.6-7.6,11c.7,3.4,2.7,5.7,5.6,6.5.6.3,1.4.4,2.2.4Zm6.1-7.7c.1.7,0,1.4-.3,2-.2.7-.6,1.2-1.1,1.7-.8.8-1.9,1.4-3,1.7s-2.3.3-3.5,0c-1.5-.4-3.4-1.6-4.2-5-.3-1.1-.2-2.3.1-3.4.4-1.1,1-2.1,1.9-2.8-.3.7-.5,1.5-.6,2.2c0,.8.1,1.5.3,2.2.3.7.7,1.3,1.2,1.9c3.8,3.9,7.2,1,8.9-.4l.3-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M322.4,321.9c-2.6-4.8-4.9,11.1-18.6-3.7-11.3-12.1-1.9-19.5-1.9-19.5s-16.3.3-7.6,18.4c12.5,25.6,38.6,24.4,28.1,4.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M317.8,337.5c3.1,0,5.6-1.2,6.9-3.3c1.9-3,1.4-7.5-1.4-12.8-.2-.4-.4-.7-.7-.9s-.6-.4-1-.5c-1.1-.2-1.9.6-2.9,1.4-2.3,2-5.8,5.2-14.2-3.8-3.7-4-5.5-7.9-5.3-11.5.2-2.5,1.4-4.9,3.2-6.6l2.3-1.9-3,.1c-.3,0-6.9.2-9.8,5-2.2,3.5-1.7,8.5,1.4,14.8c6.4,13.2,16.5,19.5,23.6,20h.9Zm3.4-15.5c.1.1.2.3.3.4c2.5,4.6,3,8.4,1.5,10.7-1.1,1.7-3.1,2.5-5.8,2.3-6.6-.4-15.9-6.3-21.9-18.8-2.7-5.6-3.2-10-1.4-12.9c1.3-2.2,3.7-3.2,5.5-3.6-1.2,1.7-1.9,3.7-2,5.7-.3,4.2,1.6,8.6,5.8,13.1c9.7,10.4,14.5,6.2,17,4c.2-.3.6-.7,1-.9Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M277.6,298.8c1.8.9-1.3-7.3,5.5-7.3c5.6,0,5,7.8,5,7.8s4.1-8-2.3-11.8c-9-5.4-15.3,7.6-8.2,11.3Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M286.7,304.4l2.3-4.6c1.5-3,3-9.7-2.7-13.1-2.9-1.8-6-1.9-8.5-.4-1.3.8-2.4,1.9-3.1,3.3s-1.1,2.9-1.1,4.4c0,1.2.3,2.4,1,3.5.6,1,1.5,1.9,2.6,2.4.2.1.4.2.7.2.2,0,.5-.1.7-.2.7-.4.8-1.3.8-2.4.1-2.7.5-4.7,3.8-4.8.5,0,.9.1,1.4.2.4.2.8.4,1.1.8c1.7,1.9,1.4,5.7,1.4,5.7l-.4,5Zm-3.5-13.9c-5.5,0-5.7,4.5-5.8,6.7c0,.1,0,.1,0,.2-.5-.4-1-1-1.3-1.6s-.5-1.3-.5-2c0-1.2.3-2.3.9-3.4.6-1,1.4-1.9,2.4-2.5c1.4-.8,3.5-1.4,6.5.4c1,.6,1.8,1.4,2.4,2.4.5,1,.8,2.2.7,3.3-.3-.7-.7-1.4-1.2-2-.5-.5-1.1-1-1.8-1.3-.8-.1-1.6-.2-2.3-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M357.6,327.3c-.6-1.9-4.3,6-9.1,1-3.9-4.1,2.2-9,2.2-9s-8.5,2.6-6.9,9.8c2.3,10.4,16.1,6,13.8-1.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M350.6,336.3c2.3,0,4.6-.9,6.2-2.5.9-.8,1.5-1.9,1.8-3.1s.3-2.4-.1-3.6c-.1-.2-.2-.4-.3-.6-.2-.2-.4-.3-.6-.4-.8-.2-1.5.3-2.3,1.1-2,1.8-3.8,2.9-6.1.5-.3-.3-.6-.7-.8-1.1s-.3-.9-.2-1.4c.2-2.5,3.1-4.9,3.1-5l4-3.3-4.9,1.5c-3.3,1-9.1,4.6-7.6,11c.7,3.4,2.7,5.7,5.6,6.5.7.3,1.5.4,2.2.4Zm6.2-7.8c.1.7,0,1.4-.3,2-.2.6-.6,1.2-1.1,1.7-.8.8-1.9,1.4-3,1.7s-2.3.3-3.5,0c-1.5-.4-3.4-1.7-4.1-5-.3-1.1-.2-2.3.1-3.4.4-1.1,1-2.1,1.9-2.8-.3.7-.5,1.5-.6,2.2c0,.8.1,1.5.3,2.2.3.7.7,1.3,1.2,1.9c3.8,3.9,7.2,1,8.9-.4l.2-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M332.5,327.4c-1-1.7-7.9-12.7,6.8-14.2.7-.1,1.3,0,2,.2c2.1.8,3.7-2.1,1.9-3.4s-4.6-1.7-9.4-.3c-19.4,5.5,1.6,22.7-1.3,17.7Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M332.6,329.3c.2,0,.3,0,.5-.1s.3-.2.4-.3c.6-.7.1-1.7-.1-2l-.1-.2c-2.2-3.8-2.8-6.7-1.7-8.8c1-2.1,3.7-3.2,7.9-3.6.5-.1,1,0,1.5.2.7.3,1.4.3,2.1,0s1.2-.7,1.6-1.4c.4-.6.5-1.4.4-2.1s-.5-1.3-1.1-1.8c-2.2-1.7-5.6-1.8-10.3-.5-7.2,2-8.8,5.7-9,8.4-.3,5.6,5.5,11.3,7.4,12.1c0,0,.3.1.5.1Zm7.2-17.1c-.2,0-.4,0-.6,0-4.9.5-8.1,2.1-9.5,4.7-1.1,2.2-.9,5,.5,8.3-1.8-1.9-3.7-4.9-3.7-7.7c0-3.1,2.6-5.4,7.5-6.8c4-1.1,6.9-1.1,8.5.2.2.1.3.4.3.6s0,.5-.1.7-.3.4-.5.4c-.2.1-.4.1-.7,0-.5-.4-1.1-.5-1.7-.4v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M331.2,316.7c-1-1.7-13.7-24.5,6.8-14.2c2.6,1.4-6.2,6.4-6.2,6.4s19.5-9.4,7-14.7c-11.5-4.8-4.7,27.6-7.6,22.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M331.7,318.3c.1,0,.2,0,.3-.1c1.1-.3,1.1-1.5.9-6.1c0-.8-.1-1.6-.1-2.5c2.6-1.3,11.3-6.1,11.2-11-.1-2.1-1.7-3.9-4.8-5.3-2-.8-3.6-.8-5,.2-1.5,1-2.4,3.1-2.9,5.5-2.2-.4-3.8,0-4.9,1.1-.9.9-3.2,4.9,3.8,17.1.3.3.7,1.1,1.5,1.1ZM331,301c-.3,3.7-.4,7.5-.1,11.2c0,.7,0,1.4.1,2-3.9-7.4-4-11.7-3-12.7.3-.4,1.2-.8,3-.5Zm6.4,2.4c-.4.7-2.3,2.3-4.6,3.8c0-1.9.1-3.8.2-5.7c1.5.6,3,1.2,4.4,1.9Zm-.8-8.6c.6,0,1.2.2,1.8.4c2.3,1,3.6,2.2,3.6,3.4c0,1.3-1,2.7-2.6,4.2-.1-.2-.2-.5-.4-.7s-.4-.4-.6-.5c-1.6-.9-3.3-1.6-5.1-2.1.4-2,1-3.6,2-4.4.4-.2.9-.3,1.3-.3v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M340.1,324.3c-1.9.4,5.7,4.7.4,9.1-4.3,3.6-8.8-2.8-8.8-2.8s2,8.7,9.3,7.6c10.5-1.6,7-15.7-.9-13.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M339.8,339.3c.5,0,1,0,1.4-.1c3.4-.5,5.8-2.3,6.8-5.1.5-1.5.6-3,.3-4.5s-1-2.9-2-4.1c-.8-.9-1.8-1.6-3-2s-2.4-.4-3.6-.2c-.2,0-.4.1-.6.3-.2.1-.3.3-.4.6-.3.8.2,1.5.9,2.4c1.6,2.2,2.7,4,.1,6.1-.3.3-.7.6-1.2.7-.4.1-.9.2-1.4.1-2.5-.3-4.7-3.4-4.7-3.4l-3-4.2l1.2,5c.9,3,3.6,8.4,9.2,8.4Zm1.6-14.2c.7,0,1.3.2,1.9.4.6.3,1.1.7,1.6,1.2.8.9,1.3,2,1.5,3.1.2,1.2.1,2.3-.2,3.5-.5,1.5-1.9,3.3-5.2,3.8-1.1.2-2.3.1-3.4-.4-1.1-.4-2-1.2-2.7-2.1.7.4,1.4.6,2.2.7.7.1,1.5,0,2.2-.2s1.4-.6,1.9-1.1c4.2-3.5,1.5-7.1.2-8.9l-.1-.2.1.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M340.8,331.7c1-1.7-7.3.8-6.9-6c.4-5.6,8.1-4.4,8.1-4.4s-7.7-4.6-11.9,1.5c-6,8.6,6.5,15.8,10.7,8.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M335.9,335.5c1.2,0,2.3-.3,3.3-.8c1-.6,1.9-1.4,2.4-2.4.1-.2.2-.4.2-.7c0-.2,0-.5-.1-.7-.4-.8-1.2-.9-2.3-1-2.7-.3-4.7-.8-4.5-4.1c0-.5.1-.9.3-1.4s.5-.8.9-1.1c1.6-1.4,4.7-1.2,5.8-1.1l5.1.8-4.4-2.6c-2.9-1.7-9.5-3.6-13.3,1.8-2,2.8-2.3,5.8-1,8.5.7,1.4,1.8,2.5,3,3.4c1.3.8,2.8,1.3,4.3,1.4h.3Zm3.5-3.5c-.5.5-1,.9-1.7,1.2-.6.3-1.3.4-2,.3-1.2-.1-2.3-.4-3.3-1.1-1-.6-1.8-1.5-2.3-2.6-.7-1.4-1.1-3.6.9-6.4.6-1,1.5-1.7,2.6-2.2s2.2-.7,3.4-.5c-.7.2-1.4.6-2,1.1s-1.1,1.1-1.4,1.7c-.3.7-.5,1.4-.5,2.2-.4,5.5,4.1,6,6.3,6.3v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M312.1,294.4c-.6,1.9-4.2,14.4-15.1,4.3-.5-.4-.9-1-1.1-1.6-.8-2.1-4-1.4-3.8.9.2,2.1,1.8,4.5,6,7.2c16.9,10.8,15.9-16.3,14-10.8Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M305.2,308.8c1.7,0,3.3-.5,4.6-1.6c4.4-3.5,4.7-11.6,4.1-13.5-.1-.3-.2-.5-.4-.7s-.5-.3-.8-.3c-1,0-1.3,1.1-1.4,1.4l-.1.2c-1.4,4.2-3.2,6.6-5.4,7.1-2.3.6-4.9-.6-7.9-3.5-.4-.3-.7-.8-.9-1.2-.2-.7-.7-1.2-1.4-1.6-.6-.3-1.4-.4-2.1-.3s-1.3.5-1.8,1.1c-.4.6-.6,1.3-.6,2c.2,2.7,2.4,5.4,6.5,8c3.1,2.2,5.6,2.9,7.6,2.9Zm6.8-11.2c-.3,2.6-1.1,6-3.3,7.9-2.3,2-5.7,1.7-10.1-1.1-3.5-2.2-5.4-4.4-5.5-6.5c0-.2.1-.5.2-.6.1-.2.4-.3.6-.3.2-.1.5,0,.7.1s.4.3.4.5c.3.8.8,1.5,1.4,2c3.6,3.4,6.9,4.7,9.8,3.9c2.4-.7,4.3-2.6,5.8-5.9Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M357.4,312.5c-.6,1.9-9.1,26.5-15.1,4.3-.8-2.9,8.9.3,8.9.3s-20-8.3-15.6,4.6c3.9,11.8,23.6-14.8,21.8-9.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M348.1,327c1.4,0,5.8-1.1,10.1-14.2.1-.3.5-1.4-.4-1.9-1-.6-1.9.2-5.1,3.3-.5.5-1.2,1.1-1.8,1.7-2.7-1-12.1-4.4-15.7-1-1.6,1.5-1.8,3.9-.7,7.1.7,2,1.8,3.2,3.4,3.6c1.8.4,3.9-.3,6-1.6c1.3,2,2.6,3,4.2,3v0Zm7.5-12.7c-2.9,7.9-6,10.7-7.5,10.7-.6,0-1.4-.4-2.4-2c3-2.2,5.8-4.7,8.5-7.3.5-.5,1-1,1.4-1.4v0Zm-12.5,8c-1.8,1.1-3.4,1.7-4.6,1.4-.9-.2-1.5-1-2-2.3-.8-2.4-.8-4.1.2-5c.9-.9,2.7-1.1,4.8-.8-.1.2-.2.5-.3.7c0,.3,0,.5.1.8.5,1.7,1.1,3.5,1.8,5.2v0Zm6.1-4.7c-1.4,1.3-2.9,2.5-4.4,3.6-.6-1.5-1.1-3-1.5-4.5.8-.2,3.2.2,5.9.9v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M356.4,260.4c-4.3-1.2,6.1,15.3-8.8,18.5-12.3,2.6-14.4-14.8-14.4-14.8s-5.2,19.3,10.4,24.8c22.2,7.9,30.1-23.6,12.8-28.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M350.4,291.2c3.9.1,7.6-1.4,10.5-3.9c2.4-2.2,4.1-5,5.1-8c1-3.1,1.1-6.3.4-9.5-.5-2.5-1.7-4.7-3.4-6.5s-3.9-3.1-6.3-3.7c-.3-.1-.6-.1-.9-.1-.3.1-.6.2-.8.4-.9.8-.7,2.3-.3,4.5.9,6.2,1.1,11.9-7.2,13.6-1.2.3-2.4.3-3.6.1s-2.3-.7-3.3-1.4c-5.1-3.6-6.2-12.6-6.2-12.7l-.6-5.1-1.4,5c-.1.2-5.3,20.3,11,26.1c2.1.7,4.6,1.1,7,1.2Zm5.9-29.8c2,.6,3.8,1.7,5.3,3.2c1.4,1.5,2.4,3.4,2.8,5.5.6,2.8.5,5.7-.4,8.4s-2.4,5.2-4.5,7.1c-4,3.6-9.5,4.4-15.6,2.2s-9.6-6.8-10.4-13.6c-.2-1.6-.2-3.3-.1-4.9c1,3,2.8,6.7,5.8,8.9c1.2.9,2.6,1.5,4.1,1.7c1.5.3,3,.2,4.4-.1c10.8-2.3,9.5-11.1,8.8-15.9-.1-.8-.2-1.6-.2-2.5v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M324.4,251.4c-.5,4.4,14-8.7,19.7,5.5c4.6,11.7-12.1,16.7-12.1,16.7s19.9,1.9,22.7-14.5c3.9-23.1-28.5-25.6-30.3-7.7Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M333.6,274.8c5.1,0,19.6-1.3,22-15.4c1.2-7.1-.8-13.1-5.6-16.9-2.6-2-5.6-3.2-8.8-3.6s-6.4,0-9.4,1.2c-2.3.9-4.4,2.5-5.8,4.5-1.5,2-2.4,4.4-2.6,6.9-.1.3,0,.6.1.9s.3.6.5.8c.9.8,2.4.3,4.5-.4c5.9-2,11.5-3.1,14.6,4.7.5,1.1.7,2.3.7,3.5s-.3,2.4-.8,3.5c-2.7,5.6-11.3,8.3-11.4,8.3l-5,1.5l5.2.5c.1-.1.7,0,1.8,0Zm5.1-34.1c3.6,0,7.2,1.1,10.1,3.3c4.2,3.3,5.9,8.7,4.9,15-1.1,6.3-5,10.6-11.6,12.6-1.6.5-3.2.8-4.8,1c2.8-1.5,6.1-3.9,7.7-7.3.6-1.4,1-2.8,1-4.3s-.3-3-.9-4.4c-4.1-10.3-12.6-7.4-17.1-5.9-.8.3-1.6.5-2.4.7.2-2.1,1-4.1,2.3-5.8s3-3,4.9-3.7c1.7-.9,3.8-1.3,5.9-1.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M336.2,243.9c-.1,1.3,4.2-2.6,5.8,1.7c1.4,3.5-3.6,5-3.6,5s5.9.6,6.8-4.3c1.2-7-8.4-7.8-9-2.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M339.1,251.5c2.5,0,6.4-1,7.1-5.2.4-2.4-.3-4.4-1.9-5.7-.9-.7-1.9-1.1-2.9-1.2-1.1-.1-2.2,0-3.1.4-.8.3-1.5.9-2,1.6s-.8,1.5-.9,2.4c0,.2,0,.4.1.6s.2.4.4.5c.6.5,1.3.2,2,0c1.9-.6,2.8-.6,3.5,1c.1.2.2.5.2.8s-.1.5-.2.8c-.6,1.2-2.4,1.9-3,2.1l-5,1.5l5.2.5c0-.1.3-.1.5-.1Zm1.4-10.1c.9,0,1.8.3,2.5.8c1.1.8,1.5,2.1,1.2,3.8-.1.9-.6,1.7-1.2,2.3.2-.5.4-1,.4-1.6c0-.5-.1-1.1-.3-1.6-1.3-3.2-3.9-2.8-5.4-2.4.3-.5.8-.9,1.4-1.1.4-.1.9-.2,1.4-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M296,274.1c1.9-1.7.5,9.5,9.1,7.4c7-1.7,3.9-11.2,3.9-11.2s7.4,8.8.6,15.5c-9.6,9.5-21.4-4.9-13.6-11.7Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M302.8,290c2.7,0,5.3-1.2,7.6-3.4c5.9-5.8,2.2-13.5-.6-16.8l-3.3-3.9l1.6,4.9c0,0,1.5,4.8-.1,7.8-.3.6-.8,1-1.3,1.4s-1.1.6-1.7.7c-4.4,1-5.5-1.7-6.5-5-.4-1.4-.7-2.3-1.6-2.6-.2-.1-.5-.1-.7,0s-.5.2-.6.4c-1.2,1-2,2.2-2.5,3.7-.5,1.4-.5,3-.2,4.4.4,1.9,1.3,3.6,2.6,5.1c1.3,1.4,3,2.5,4.8,3.1.7,0,1.6.2,2.5.2Zm7.9-14.7c1.1,2.8,1.5,6.6-1.8,9.8s-6.3,3.1-8.2,2.5c-1.5-.5-2.8-1.3-3.9-2.5s-1.8-2.6-2.2-4.1c-.2-1-.2-2,0-3c.3-1,.8-1.9,1.5-2.6.1.2.2.5.2.7.8,2.6,2.4,8,8.9,6.4.9-.2,1.8-.6,2.5-1.1s1.4-1.2,1.8-2c.8-1.3,1.2-2.7,1.2-4.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M324,258.6c-.3,3,9.4-5.8,13.2,3.7c3.1,7.8-8.1,11.2-8.1,11.2s13.3,1.3,15.2-9.7c2.6-15.5-19.1-17.2-20.3-5.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M330.1,274.6c3.5,0,13.4-.9,15.1-10.6.8-4.9-.5-9-3.9-11.7-1.8-1.3-3.8-2.2-6-2.5s-4.4,0-6.5.8c-1.6.6-3,1.7-4,3.1s-1.7,3-1.8,4.8c0,.3,0,.5.1.8s.2.5.4.6c.8.6,1.8.3,3.3-.2c3.8-1.3,7.4-2,9.4,3c.3.7.5,1.5.5,2.2c0,.8-.2,1.5-.5,2.2-1.8,3.6-7.4,5.4-7.4,5.4l-5,1.5l5.2.5c-.1.1.4.1,1.1.1Zm3.5-22.9c2.4,0,4.6.7,6.5,2.2s4.1,4.5,3.1,9.7c-.7,4.1-3.2,6.9-7.5,8.1-.5.2-1,.3-1.5.4c1.6-1,2.9-2.4,3.8-4.1.5-.9.7-2,.7-3c0-1.1-.2-2.1-.6-3.1-2.8-7.2-9-5.1-11.9-4.2-.4.1-.8.3-1.1.4.2-1.3.7-2.4,1.5-3.4s1.8-1.7,3-2.2c1.3-.5,2.6-.8,4-.8Zm-8.6,7l-1-.1l1,.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M285.2,277.1c-.8-2.9,10.3,4.1,12.3-6c1.7-8.2-10-9.6-10-9.6s12.8-3.6,16.7,6.8c5.3,14.9-15.6,20.4-19,8.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M294,284.4c1.8,0,3.5-.4,5.2-1.1c1.6-.7,3.1-1.8,4.3-3.1c2.8-3.2,3.4-7.5,1.7-12.2-4.1-11.2-17.7-7.5-17.9-7.4l-5,1.4l5.1.6c.1,0,5.9.7,8.3,4c.4.6.8,1.3.9,2.1s.1,1.5-.1,2.3c-1,5.3-4.7,5.2-8.7,4.6-1.6-.2-2.7-.4-3.3.4-.2.2-.3.5-.3.7c0,.3,0,.5.1.8.5,1.7,1.4,3.2,2.6,4.4c1.3,1.2,2.8,2,4.5,2.3.8.1,1.7.2,2.6.2Zm-1.2-22.5c.5,0,1,0,1.6.1c4.4.5,7.4,2.7,8.9,6.7c1.8,5,.3,8.3-1.4,10.2-1.3,1.4-2.8,2.4-4.6,2.9-1.8.6-3.6.7-5.4.3-1.2-.2-2.4-.8-3.4-1.6s-1.7-1.9-2.1-3.1c.4,0,.8.1,1.2.1c3.1.4,9.5,1.4,11-6.2.2-1,.3-2.1.1-3.1s-.6-2-1.2-2.9c-1.4-1.5-2.9-2.6-4.7-3.4v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M315.5,276.9c-.2,2,6.3-3.9,8.8,2.5c2.1,5.2-5.4,7.4-5.4,7.4s8.9.8,10.1-6.5c1.8-10.3-12.7-11.5-13.5-3.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M319.9,287.9c3.6,0,9.1-1.5,10.1-7.4.6-3.4-.4-6.3-2.7-8.1-1.2-.9-2.7-1.5-4.2-1.7s-3.1,0-4.5.6c-1.1.5-2.1,1.2-2.8,2.2s-1.2,2.1-1.3,3.4c0,.2,0,.5.1.7s.2.4.4.6c.7.5,1.5.2,2.5-.1c2.6-.9,4.6-1.3,5.8,1.8.2.4.3.9.3,1.4s-.1.9-.3,1.4c-1.1,2.3-4.7,3.4-4.7,3.4l-4.9,1.5l5.1.5c.4-.2.7-.2,1.1-.2Zm2-15.3c1.5,0,2.9.5,4.1,1.4c1.2,1,2.5,2.8,2,6.2-.2,1.1-.7,2.2-1.4,3.1s-1.7,1.5-2.8,1.9c.6-.5,1-1.2,1.4-1.9.3-.7.5-1.4.5-2.2c0-.7-.1-1.5-.4-2.2-2-5.1-6.3-3.7-8.4-3l-.2.1c.2-.7.5-1.3,1-1.8s1-.9,1.7-1.2c.8-.2,1.6-.4,2.5-.4v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M322.8,278.5c-1.3-1.5-1.5,7.2-7.9,4.7-5.2-2.1-1.7-9.1-1.7-9.1s-6.8,5.9-2.3,11.8c6.3,8.5,17.1-1.2,11.9-7.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M316.8,290.2c.4,0,.7,0,1.1-.1c1.5-.2,3-.9,4.2-1.8c1.2-1,2.1-2.2,2.7-3.7.4-1.1.6-2.4.4-3.5-.2-1.2-.7-2.3-1.5-3.2-.1-.2-.3-.3-.5-.4s-.5-.1-.7-.1c-.8.1-1.2.9-1.7,1.9-1.2,2.5-2.3,4.2-5.3,3-.4-.2-.8-.4-1.2-.7-.3-.3-.6-.7-.8-1.2-.7-2,.3-4.9.8-5.8l2.3-4.6-3.9,3.4c-2.6,2.2-6.4,7.9-2.5,13.2c1.7,2.3,4.1,3.6,6.6,3.6Zm5.8-10.3c.3.6.5,1.3.6,2c0,.7-.1,1.4-.3,2-.4,1.1-1.2,2-2.1,2.8-.9.7-2,1.2-3.2,1.4-1.5.2-3.8-.1-5.8-2.8-.7-.9-1.1-2-1.3-3.2-.1-1.1.1-2.3.6-3.3c0,.8.1,1.5.4,2.3.3.7.7,1.3,1.2,1.9.5.5,1.2.9,1.9,1.2c5.1,2,7-2,7.9-4l.1-.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M359.7,256c1.3,1.5,1.6-7.2,7.9-4.5c5.2,2.2,1.5,9.1,1.5,9.1s6.8-5.8,2.5-11.8c-6.1-8.5-17,1-11.9,7.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M365.9,264.7l3.9-3.3c2.6-2.2,6.5-7.8,2.7-13.1-2-2.8-4.7-4.1-7.7-3.8-1.5.2-3,.8-4.2,1.8s-2.2,2.2-2.7,3.6c-.5,1.1-.6,2.4-.4,3.6s.7,2.3,1.5,3.3c.1.2.3.3.5.4s.4.1.7.1c.8-.1,1.2-.9,1.7-1.9c1.2-2.4,2.3-4.2,5.4-2.9.4.2.8.4,1.2.7.3.3.6.7.7,1.2.9,2.4-.9,5.8-.9,5.8l-2.4,4.5Zm-.2-18.3c1.5,0,3.4.6,5.1,3c.7.9,1.1,2,1.2,3.2s-.1,2.3-.6,3.3c0-.8-.1-1.5-.3-2.3-.3-.7-.7-1.3-1.2-1.9-.5-.5-1.2-1-1.9-1.2-5-2.1-7,1.9-8,3.9c0,.1-.1.1-.1.2-.3-.6-.5-1.3-.6-2s.1-1.4.4-2c.5-1.1,1.2-2,2.1-2.8.9-.7,2-1.2,3.2-1.4.3,0,.5,0,.7,0v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M335.2,261c1.4-3.6-11.1,2.9-11-11c.1-11.4,12-10.4,12-10.4s-12.1-7.9-18.1,5.1c-8.6,18.6,11.2,31,17.1,16.3Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M327.1,268.5c.4,0,.7,0,1.1-.1c3.5-.4,6.3-2.9,7.9-7c.1-.3.2-.6.2-.9s-.1-.6-.3-.9c-.6-.9-1.7-.9-3.2-1-3.3-.2-7.8-.4-7.7-8.6c0-3,.9-5.3,2.7-6.9c3.2-2.9,8.2-2.5,8.3-2.5l4,.3-3.3-2.2c-.2-.2-5.8-3.8-11.6-2.1-3.4,1-6.1,3.5-8,7.7-3.2,6.9-2.9,14,.8,19.1c2.3,3.2,5.7,5.1,9.1,5.1Zm7.1-7.7c-1.4,3.3-3.6,5.3-6.3,5.6-2.9.4-6.1-1.2-8.3-4.2-3.3-4.4-3.5-10.8-.6-17c1.6-3.6,3.9-5.8,6.7-6.6c2.1-.5,4.4-.4,6.5.3-2.1.4-4.1,1.3-5.7,2.7-2.2,2-3.3,4.8-3.3,8.3-.1,10.2,6.5,10.5,9.6,10.7.4.1,1,.1,1.4.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M366,252c-2-.1,4.1,6.2-2.2,8.9-5.1,2.2-7.6-5.2-7.6-5.2s-.6,8.9,6.8,9.9c10.4,1.5,11-13.1,3-13.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M364.4,266.7c1.2,0,2.5-.2,3.6-.7s2.1-1.3,2.9-2.3c.9-1.3,1.4-2.7,1.6-4.3.2-1.5-.1-3.1-.7-4.5-.5-1.1-1.3-2.1-2.3-2.8s-2.2-1.1-3.4-1.2c-.2,0-.5,0-.7.1s-.4.2-.6.4c-.5.7-.2,1.5.2,2.5.9,2.5,1.4,4.6-1.6,5.9-.4.2-.9.3-1.3.3-.5,0-.9-.1-1.4-.3-2.3-1-3.6-4.6-3.6-4.7l-1.7-4.9-.3,5.1c-.2,3.4,1.1,10.1,7.7,11c.5.4,1,.4,1.6.4Zm2.4-13.5c.7.2,1.3.5,1.8,1s.9,1,1.2,1.7c.5,1.1.7,2.3.5,3.4-.1,1.2-.5,2.3-1.2,3.3-.9,1.2-2.7,2.6-6.1,2.2-1.1-.1-2.2-.6-3.1-1.3s-1.6-1.7-2-2.8c.5.6,1.2,1,1.9,1.4.7.3,1.4.5,2.2.4.7,0,1.5-.2,2.2-.5c5-2.2,3.5-6.4,2.7-8.5c0-.2,0-.3-.1-.3v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M281.3,257.4c1.3,1.5,1.6-7.2,7.9-4.5c5.2,2.2,1.5,9.1,1.5,9.1s6.8-5.8,2.5-11.8c-6.1-8.5-17,1-11.9,7.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M287.5,266.1l3.9-3.3c2.6-2.2,6.5-7.8,2.7-13.2-2-2.8-4.7-4.1-7.7-3.8-1.5.2-3,.8-4.2,1.8-1.2.9-2.2,2.2-2.7,3.6-.5,1.1-.6,2.4-.4,3.6s.7,2.3,1.5,3.2c.1.2.3.3.5.4s.4.1.7.1c.8-.1,1.2-.9,1.7-1.9c1.2-2.4,2.3-4.2,5.4-2.9.4.2.8.4,1.2.7.3.3.6.7.7,1.2.9,2.4-.9,5.8-.9,5.8l-2.4,4.7Zm-.2-18.3c1.5,0,3.4.6,5.1,3c.7.9,1.1,2,1.2,3.2s-.1,2.3-.6,3.3c0-.8-.1-1.5-.4-2.3-.3-.7-.7-1.3-1.2-1.9-.5-.5-1.2-.9-1.9-1.2-5-2.1-7,1.9-8,3.9c0,.1-.1.1-.1.2-.3-.6-.5-1.3-.6-2c0-.7.1-1.4.4-2c.5-1.1,1.2-2,2.1-2.8.9-.7,2-1.2,3.2-1.4.3.1.6.1.8,0v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M304.4,247.5c1.6,1.2,12.3,8.5-.7,15.7-.6.3-1.2.5-1.9.6-2.2.1-2.6,3.4-.4,3.9c2,.5,4.9-.2,8.8-3.4c15.6-12.8-10.5-20.3-5.8-16.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M302.7,268.8c2.3,0,5.1-1.3,8.2-3.8c5.8-4.7,5.9-8.7,4.9-11.3-1.9-5.2-9.4-8.1-11.5-8.1v0c-.3,0-.6,0-.8.2s-.4.4-.5.7c-.3.9.6,1.6.9,1.8l.2.1c3.5,2.6,5.2,5.1,5.1,7.4s-2.1,4.4-5.8,6.5c-.4.3-.9.4-1.4.4-.7,0-1.4.3-1.9.8s-.8,1.2-.9,1.9.1,1.4.5,2s1,1,1.7,1.2c.3.2.8.2,1.3.2Zm4.6-20.3c2.4,1.1,5.3,3,6.5,5.6c1.2,2.9-.2,6-4.2,9.2-3.2,2.6-5.9,3.7-7.9,3.2-.2-.1-.4-.2-.5-.4s-.2-.4-.1-.7c0-.2.1-.4.3-.6s.4-.3.6-.3c.8,0,1.6-.3,2.3-.7c4.3-2.4,6.6-5.1,6.8-8.1.1-2.2-1.1-4.7-3.8-7.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M309.8,256.7c1.6,1.2,22.2,17-.7,15.7-2.9-.2,3.1-8.4,3.1-8.4s-14.2,16.4-.6,16.3c12.5,0-6.5-27.1-1.8-23.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M311.6,281.4v0c2.2,0,3.7-.7,4.6-2.1c1-1.5,1-3.8.4-6.2c2.2-.5,3.5-1.5,4.1-2.9.4-1.2,1-5.8-10.3-14.2-.3-.2-1.2-.9-1.9-.3-.9.8-.4,1.9,1.5,5.9.3.7.7,1.5,1.1,2.3-1.8,2.2-8,10.1-5.9,14.6.8,1.9,3,2.9,6.4,2.9Zm3-8c.5,2,.5,3.7-.2,4.8-.5.8-1.4,1.2-2.8,1.2-2.5,0-4.1-.6-4.6-1.7-.5-1.2-.1-2.9.7-4.8.2.2.4.4.6.5s.5.2.8.2c1.8,0,3.6,0,5.5-.2Zm-.5-2c-1.6.1-3.2.1-4.7,0c0-.8,1.2-3,2.7-5.3.7,1.7,1.4,3.5,2,5.3ZM310.9,259c6.5,5.3,8.3,9.1,7.8,10.5-.2.5-.8,1.2-2.6,1.6-1.2-3.6-2.6-7-4.3-10.4l-.9-1.7Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M298.6,253.3c1.6-1.2-7.1-2.1-4-8.2c2.5-5,9.2-.9,9.2-.9s-5.3-7.2-11.6-3.3c-9,5.6-.2,17.1,6.4,12.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M295.3,255.4c1.4,0,2.8-.5,3.9-1.3.2-.1.4-.3.5-.5s.2-.4.2-.7c0-.8-.8-1.3-1.8-1.8-2.3-1.4-4-2.6-2.5-5.6.2-.4.5-.8.8-1.1.4-.3.8-.5,1.2-.7c2.4-.7,5.7,1.3,5.7,1.3l4.4,2.7-3-4.1c-2-2.8-7.3-7-12.9-3.5-2.9,1.8-4.4,4.5-4.3,7.4.1,1.5.6,3,1.5,4.3s2,2.3,3.4,3c.8.4,1.8.7,2.9.6Zm1.9-2.4c-.6.3-1.3.4-2,.4s-1.4-.2-2-.5c-1.1-.5-1.9-1.3-2.6-2.3s-1.1-2.1-1.2-3.3c-.1-1.5.4-3.8,3.3-5.6c1-.6,2.1-1,3.2-1c1.2,0,2.3.3,3.3.9-.8-.1-1.5,0-2.3.2-.7.2-1.4.6-2,1.1s-1,1.1-1.3,1.8c-2.5,4.9,1.5,7.2,3.3,8.2l.3.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M295,246.8c-.3,2,6.4-3.7,8.7,2.8c1.9,5.3-5.7,7.3-5.7,7.3s8.8,1.2,10.4-6.1c2.1-10.4-12.3-12-13.4-4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M299.4,257.9c3.6,0,8.8-1.4,9.9-7c.7-3.4-.2-6.3-2.4-8.2-1.2-1-2.6-1.6-4.1-1.9s-3.1-.1-4.5.4c-1.1.4-2.2,1.1-2.9,2.1-.8.9-1.2,2.1-1.4,3.3c0,.2,0,.5,0,.7.1.2.2.4.4.6.6.5,1.5.3,2.5,0c2.6-.8,4.7-1.1,5.8,2c.2.4.3.9.2,1.4c0,.5-.1.9-.4,1.3-1,1.9-3.9,3-4.9,3.3l-5,1.3l5.1.7c.7,0,1.2.1,1.7,0Zm1.9-15.2c1.6,0,3.1.5,4.3,1.5s2.4,2.9,1.7,6.2c-.2,1.1-.7,2.2-1.5,3.1s-1.8,1.5-2.9,1.8c.6-.5,1.1-1.1,1.5-1.8.3-.7.6-1.4.6-2.1s-.1-1.5-.4-2.2c-1.8-5.2-6.2-3.9-8.2-3.3l-.2.1c.2-.7.6-1.3,1.1-1.8s1.1-.9,1.7-1.1c.7-.2,1.5-.4,2.3-.4Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M336.1,269.7c-.2-2-1.8-14.9,12.1-9.9.6.2,1.2.6,1.7,1c1.5,1.6,4.2-.3,3.2-2.3-1-1.9-3.4-3.5-8.4-4.3-19.8-3.2-8.1,21.3-8.6,15.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M335.9,271.6c.2,0,.3,0,.5-.1.9-.4.8-1.5.7-1.9v-.2c-.4-4.4.3-7.3,2.1-8.7s4.7-1.4,8.7,0c.5.2.9.4,1.3.8.5.5,1.2.8,1.9.9s1.4-.1,2-.6c.6-.4,1-1,1.2-1.7s.1-1.4-.3-2.1c-1.3-2.4-4.3-4-9.1-4.8-7.3-1.2-10.4,1.4-11.7,3.8-2.6,5,.2,12.6,1.6,14.1.1.2.3.3.5.4.2,0,.3.1.6.1Zm6.7-14c-1.7-.1-3.4.4-4.7,1.4-1.9,1.5-3,4.1-3,7.7-.8-2.5-1.4-6-.1-8.6c1.4-2.8,4.6-3.8,9.7-3c4.1.7,6.7,1.9,7.6,3.7.1.2.1.4.1.7-.1.2-.2.4-.4.5s-.4.2-.6.2-.4-.1-.6-.3c-.6-.6-1.3-1-2.1-1.3-1.9-.6-3.9-.9-5.9-1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M287.4,271c-.2-2-2.1-28,12.1-9.9c1.8,2.3-8.3,3.2-8.3,3.2s21.6-.3,12.6-10.4c-8.4-9.3-15.9,22.9-16.4,17.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M287.7,272.7c1,0,1.5-1.2,3.2-5.1.3-.7.6-1.5,1-2.3c2.9-.1,12.8-.8,14.8-5.3.9-2,.1-4.3-2.1-6.8-1.4-1.6-3-2.2-4.6-2-1.8.3-3.4,1.8-4.9,3.8-1.9-1.3-3.5-1.7-4.9-1.1-1.2.5-5,3.1-3.8,17.1c0,.4.1,1.5,1.1,1.6l.2.1Zm6.2-16c-1.9,3.2-3.5,6.6-4.9,10.1l-.8,1.8c-.4-8.4,1.3-12.3,2.7-12.8.5-.2,1.4-.2,3,.9Zm4.7,4.9c-.7.5-3,1.1-5.8,1.5.8-1.7,1.6-3.4,2.6-5c1.2,1,2.3,2.2,3.2,3.5v0Zm2.1-8.4c.5,0,.9.2,1.3.4s.8.6,1,.9c1.7,1.9,2.3,3.5,1.8,4.6-.5,1.2-2.1,2-4.1,2.7c0-.3,0-.5,0-.8-.1-.3-.2-.5-.4-.7-1.1-1.5-2.4-2.8-3.7-4.1c1.2-1.7,2.5-2.9,3.7-3.1.1.1.2.1.4.1v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M414.4,296.9c-1.6,3,12.4-2.2,12.4,9.4c0,9.6-13.3,8.5-13.3,8.5s13.6,6.9,20.1-3.9c9.4-15.4-12.9-26.2-19.2-14Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M422.7,317.9c4.1,0,8.7-1.4,11.8-6.6c2.9-4.8,3.2-9.7.8-13.8-1.3-2.1-3.1-3.9-5.3-5.1s-4.6-1.8-7.1-1.8c-1.9,0-3.8.6-5.5,1.6s-3,2.5-3.9,4.2c-.1.2-.2.5-.2.8s.1.6.2.8c.5.9,1.7,1,3.5,1.1c4.6.3,8.8,1,8.8,7.2c0,.9-.1,1.8-.4,2.6s-.8,1.6-1.5,2.2c-3.4,3.2-10.1,2.6-10.2,2.6l-5.1-.4l4.6,2.3c2.9,1.5,6.2,2.3,9.5,2.3Zm.5-25.3c2.1,0,4.1.6,5.9,1.6s3.3,2.5,4.4,4.3c2,3.5,1.7,7.6-.8,11.8-2.5,4.1-6.4,6-11.5,5.5-.8-.1-1.7-.2-2.5-.4c2.4-.3,4.6-1.3,6.4-2.9.9-.8,1.5-1.8,2-2.9s.7-2.3.6-3.4c0-8.7-7-9-10.7-9.3-.5,0-1.1-.1-1.6-.1.7-1.3,1.8-2.4,3.1-3.2s2.8-1.2,4.3-1.1h.4Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M399.2,324.1c4.2,1.4-5.6-15.5,9.4-18.2c12.4-2.2,13.9,15.3,13.9,15.3s5.9-19.1-9.6-25.2c-21.8-8.6-30.8,22.6-13.7,28.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M422,326.4l1.5-5c.1-.2,6-20.1-10.2-26.4-6.7-2.6-13-1.9-17.7,2-2.4,2.1-4.3,4.8-5.3,7.9-1.1,3-1.3,6.3-.7,9.5.4,2.5,1.5,4.8,3.2,6.6s3.8,3.2,6.2,3.9c.3.1.6.2.9.1.3,0,.6-.2.9-.4.9-.8.8-2.3.5-4.5-.7-6.2-.7-11.9,7.6-13.4c1.2-.3,2.4-.3,3.6,0s2.3.8,3.3,1.5c5,3.8,5.8,12.8,5.8,12.9l.4,5.3Zm-11.3-21.7c-.7,0-1.5.1-2.2.2-10.9,1.9-9.8,10.8-9.3,15.6.1.8.2,1.7.2,2.5-2-.6-3.8-1.8-5.2-3.4s-2.3-3.5-2.7-5.6c-.5-2.8-.3-5.7.7-8.4.9-2.7,2.6-5.1,4.7-7c4.1-3.5,9.7-4.1,15.7-1.7s9.3,7,9.9,13.9c.1,1.6.1,3.3,0,4.9-.9-3.1-2.6-6.8-5.5-9-1.8-1.3-4-2.1-6.3-2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M394.3,310.9c1.3.4-1.7-4.6,2.8-5.4c3.7-.7,4.1,4.5,4.1,4.5s1.7-5.7-2.8-7.4c-6.6-2.6-9.2,6.7-4.1,8.3Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M400.7,315.3l1.5-4.9c.7-2.3.9-7-3.4-8.7-2.2-.9-4.4-.6-6,.7-.8.7-1.4,1.6-1.8,2.6s-.4,2.1-.2,3.2c.2.9.5,1.7,1.1,2.3s1.3,1.1,2.2,1.4c.2.1.4.1.6.1s.4-.1.6-.3c.6-.5.5-1.2.4-2-.2-2,0-2.9,1.7-3.2.3-.1.5-.1.8,0s.5.2.7.3c1,.8,1.4,2.7,1.5,3.3l.3,5.2Zm-3-10.9c-.3,0-.5,0-.8.1-3.4.6-3.5,3.2-3.4,4.8-.4-.4-.7-.9-.8-1.5-.1-.7-.1-1.4.2-2.1.2-.7.6-1.3,1.2-1.8c1-.9,2.4-1,3.9-.4.8.3,1.5.9,2,1.7-.7-.5-1.5-.8-2.3-.8v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M415.7,356.5c-1.2-2.2,9.4,1.4,9.1-7.4-.2-7.2-10.2-6.1-10.2-6.1s10.1-5.5,15.3,2.5c7.3,11.4-9.2,20.1-14.2,11Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M422.1,361.3v0c2,0,3.9-.6,5.5-1.6c1.7-1,3-2.4,4-4.1c1.8-3.3,1.4-7-.9-10.7-4.5-7-12.8-4.9-16.6-2.9l-4.5,2.5l5.1-.6c0,0,5-.5,7.6,1.7.5.4.9.9,1.1,1.5.3.6.4,1.2.4,1.9.1,4.5-2.8,5.1-6.3,5.4-1.4.1-2.4.2-2.8,1-.1.2-.2.5-.2.7c0,.3.1.5.2.7.7,1.3,1.8,2.4,3.1,3.2c1.3.9,2.8,1.3,4.3,1.3Zm-.3-19.1c1.4,0,2.9.3,4.1,1c1.3.7,2.3,1.7,3.1,2.9c2.5,3.8,1.8,6.8.8,8.6-.8,1.4-1.9,2.5-3.2,3.3s-2.9,1.2-4.4,1.3v0c-1,0-2-.2-2.9-.7s-1.7-1.1-2.3-2l.8-.1c2.7-.2,8.3-.7,8.1-7.4c0-.9-.2-1.8-.6-2.7-.4-.8-.9-1.6-1.6-2.2-1.1-.9-2.4-1.5-3.9-1.8.6-.1,1.3-.2,2-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M406.2,325.9c2.8.9-3.8-10.4,6.3-12.1c8.3-1.4,9.3,10.2,9.3,10.2s3.9-12.8-6.4-16.8c-14.7-5.8-20.7,15.1-9.2,18.7Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M421.2,329.2l1.5-4.9c0-.1,4.1-13.7-7-18.1-4.6-1.8-8.9-1.3-12.2,1.4-1.7,1.4-2.9,3.3-3.7,5.4-.7,2.1-.9,4.3-.5,6.5.3,1.7,1.1,3.3,2.2,4.6s2.6,2.2,4.3,2.7c.2.1.5.1.8.1s.5-.1.7-.3c.8-.6.6-1.7.4-3.3-.5-4-.4-7.7,4.8-8.6.8-.2,1.5-.2,2.3,0s1.5.5,2.1,1c3.2,2.5,3.8,8.3,3.8,8.4l.5,5.1ZM406.5,325l-.3,1l.3-1Zm7.4-12.4c-.5,0-1,.1-1.6.1-7.6,1.4-6.8,7.7-6.5,10.8c0,.4.1.8.1,1.2-1.2-.5-2.2-1.2-3-2.2s-1.3-2.2-1.5-3.4c-.3-1.8-.2-3.7.5-5.4s1.7-3.3,3-4.5c1.9-1.6,5.2-3,10.1-1.1c3.9,1.5,6.1,4.6,6.4,9c0,.5.1,1.1.1,1.6-.7-1.8-1.8-3.4-3.3-4.6-1.2-.9-2.7-1.5-4.3-1.5Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M416.4,367.7c-3,.2,6-9.3-3.4-13.3-7.7-3.3-11.4,7.8-11.4,7.8s-.9-13.3,10.1-14.9c15.6-2.2,16.7,19.5,4.7,20.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M416.3,368.7h.1c1.7-.1,3.4-.7,4.8-1.7s2.5-2.4,3.2-4c.9-2,1.2-4.3,1-6.5s-1-4.3-2.3-6.1c-2.6-3.4-6.7-4.9-11.5-4.2-11.8,1.7-10.9,15.9-10.9,16l.4,5.1l1.6-4.9c0-.1,1.9-5.6,5.6-7.3.7-.3,1.5-.5,2.2-.5.8,0,1.5.2,2.2.5c4.9,2.1,4.1,5.7,2.8,9.5-.6,1.5-.9,2.5-.3,3.3.2.2.4.3.6.4c0,.4.3.5.5.4ZM414,348.2c4,0,6.3,1.8,7.5,3.5c1.1,1.5,1.7,3.3,1.9,5.1s-.1,3.7-.8,5.4c-.5,1.2-1.3,2.2-2.3,3s-2.2,1.3-3.4,1.4c.1-.3.3-.8.4-1.1c1-2.9,3.2-9-3.9-12-1-.4-2-.7-3-.7s-2.1.2-3,.6c-1.7.9-3.2,2.1-4.2,3.7.1-.5.2-1,.4-1.5c1.4-4.3,4.2-6.7,8.3-7.3.6,0,1.3-.1,2.1-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M422.3,338c1.9.6-2.5-6.9,4.2-8.1c5.5-1,6.2,6.8,6.2,6.8s2.6-8.5-4.3-11.3c-9.7-3.8-13.8,10.1-6.1,12.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M432.2,341.9l1.5-4.9c1-3.3,1.3-10.1-4.9-12.5-3.2-1.3-6.2-.9-8.5,1-1.2,1-2,2.3-2.6,3.8-.5,1.5-.6,3-.4,4.5.2,1.2.8,2.3,1.6,3.2s1.9,1.6,3,1.9c.2.1.5.1.7.1s.4-.1.6-.3c.7-.5.6-1.4.4-2.5-.3-2.7-.3-4.8,2.9-5.4.5-.1.9-.1,1.4,0s.9.3,1.3.6c2,1.5,2.4,5.3,2.4,5.4l.6,5.1Zm-4.8-13.2c-.4,0-.7,0-1.1.1-5.4.9-4.9,5.4-4.6,7.6v.2c-.6-.3-1.1-.8-1.5-1.4s-.7-1.2-.8-1.9c-.2-1.2-.1-2.3.3-3.5.4-1.1,1.1-2.1,1.9-2.9c1.2-1,3.3-1.9,6.4-.7c1.1.4,2,1.1,2.8,2c.7.9,1.2,2,1.3,3.2-.4-.7-.9-1.3-1.5-1.7-.9-.6-2-1-3.2-1v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M425.4,331.2c-1.7,1,6.8,3,3,8.7-3.1,4.7-9.2-.2-9.2-.2s4.3,7.8,11.1,4.7c9.5-4.6,2.2-17.1-4.9-13.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M427.1,346c1.2,0,2.5-.3,3.6-.8c3.1-1.4,4.9-3.9,5.1-6.8.1-1.5-.3-3.1-.9-4.4s-1.7-2.5-3-3.4c-1-.7-2.2-1.1-3.4-1.1s-2.4.2-3.5.8c-.2.1-.4.3-.5.5s-.2.4-.2.7c-.1.8.6,1.4,1.5,2c2.2,1.6,3.7,3.1,1.8,5.8-.2.4-.6.7-.9,1s-.8.4-1.3.5c-2.5.4-5.5-2-5.5-2l-4-3.2l2.5,4.5c1.2,2.3,4.4,5.9,8.7,5.9Zm1.1-14.6c.9,0,1.8.3,2.6.8c1,.7,1.8,1.5,2.3,2.6.5,1,.8,2.2.7,3.4-.1,1.5-.9,3.7-4,5.1-1,.5-2.2.7-3.3.6s-2.2-.6-3.1-1.3c.8.2,1.5.2,2.3.1.7-.1,1.4-.4,2.1-.8.6-.4,1.1-1,1.5-1.6c3-4.5-.6-7.3-2.3-8.6l-.2-.1c.4-.1.9-.2,1.4-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M410.9,290.4c1.8-.9-6.7-3.1-2.8-8.7c3.2-4.6,9.2.4,9.2.4s-4.3-7.9-11-4.8c-9.6,4.2-2.5,16.9,4.6,13.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M408.2,292.1c1.1,0,2.2-.3,3.2-.8.2-.1.4-.3.5-.4.1-.2.2-.4.2-.6.1-.8-.6-1.4-1.5-2.1-2.1-1.7-3.6-3.2-1.8-5.9.2-.4.6-.7,1-1s.8-.4,1.3-.5c2.5-.4,5.5,2,5.5,2l4,3.3-2.4-4.5c-1.6-3-6.3-8-12.3-5.3-3.1,1.4-5,3.8-5.2,6.8-.1,1.5.2,3.1.9,4.5s1.7,2.6,3,3.4c1,.7,2.3,1.1,3.6,1.1Zm1.4-2.2c-.7.2-1.4.3-2,.2-.7-.1-1.3-.4-1.9-.8-1-.7-1.7-1.6-2.3-2.6-.5-1.1-.8-2.2-.7-3.4.1-1.5.9-3.7,4.1-5.1c1-.5,2.2-.7,3.4-.6s2.2.6,3.1,1.3c-.8-.2-1.5-.2-2.3-.1-.7.1-1.5.4-2.1.8s-1.2.9-1.6,1.6c-3.1,4.5.5,7.3,2.2,8.6l.1.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M410.8,315.5c-3.2-2.1.6,11.5-13,8.5-11.1-2.4-7.7-13.9-7.7-13.9s-10.2,10.2,1.3,18.8c16.4,12.1,32.6-4.7,19.4-13.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M403.2,334.2c2.2,0,4.4-.4,6.4-1.4c4-1.8,6.7-5.3,7-9c.3-3.5-1.6-6.8-5.3-9.2-.2-.2-.5-.3-.8-.4s-.6,0-.9.1c-1,.4-1.3,1.5-1.6,2.9-.8,3.2-2,7.5-10,5.8-2.9-.6-5-2-6.2-4-2.2-3.7-.8-8.6-.8-8.6l1.1-3.8-2.8,2.8c-.2.2-4.9,5-4.4,10.9.3,3.5,2.2,6.7,5.9,9.4c3.6,2.8,8,4.3,12.4,4.5Zm7.2-17.8c2.9,2,4.4,4.6,4.2,7.3-.2,3-2.4,5.7-5.8,7.3-5,2.3-11.3,1.2-16.8-2.9-3.1-2.3-4.8-5-5.1-7.9-.1-2.2.5-4.4,1.6-6.3-.1,2.1.5,4.3,1.5,6.1c1.5,2.5,4,4.2,7.5,5c9.9,2.2,11.6-4.2,12.4-7.3.2-.4.3-.9.5-1.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M408.2,283.4c-.5,1.9,6.8-2.7,8.2,4c1.1,5.5-6.6,6.4-6.6,6.4s8.6,2.4,11.1-4.6c3.6-10-10.5-13.6-12.7-5.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M413,295.2c3.3,0,7.3-1.2,8.9-5.7c1.2-3.2.7-6.2-1.3-8.5-1-1.1-2.4-2-3.8-2.4-1.5-.5-3-.5-4.5-.2-1.2.2-2.3.8-3.2,1.7-.9.8-1.5,1.9-1.8,3.1-.1.2-.1.5,0,.7c0,.2.1.4.3.6.6.6,1.4.5,2.5.3c2.7-.4,4.8-.4,5.4,2.8.1.5.1.9.1,1.4-.1.5-.3.9-.5,1.3-1.5,2.1-5.2,2.6-5.3,2.6l-5.1.6l5,1.4c1,.1,2.2.3,3.3.3Zm1.1-15c.9,0,1.9.2,2.7.6.9.4,1.6.9,2.3,1.6c1,1.2,2,3.2.9,6.4-.4,1.1-1,2.1-1.9,2.8s-2,1.2-3.1,1.4c.6-.4,1.2-1,1.7-1.6.4-.6.7-1.3.9-2c.1-.7.1-1.5-.1-2.2-1.1-5.4-5.6-4.7-7.7-4.4h-.2c.3-.6.8-1.2,1.3-1.6s1.2-.7,1.9-.9c.4-.1.8-.1,1.3-.1v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M396.4,367.6c1.8-.9-6.7-3.1-2.8-8.7c3.2-4.6,9.2.4,9.2.4s-4.2-7.9-11-4.8c-9.7,4.2-2.5,16.8,4.6,13.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M393.7,369.3c1.1,0,2.2-.3,3.1-.8.2-.1.4-.3.5-.4.1-.2.2-.4.2-.6.1-.8-.6-1.4-1.5-2-2.1-1.7-3.6-3.2-1.8-5.9.2-.4.6-.7,1-1s.8-.4,1.3-.5c2.5-.4,5.5,2,5.5,2l4,3.3-2.4-4.5c-1.6-3-6.3-8-12.3-5.3-3.1,1.4-5,3.8-5.2,6.8-.1,1.5.2,3.1.9,4.5s1.7,2.6,3,3.4c1.1.6,2.4,1,3.7,1ZM395,367c-.7.2-1.4.3-2,.2-.7-.1-1.3-.4-1.9-.8-1-.7-1.7-1.6-2.3-2.6-.5-1.1-.8-2.2-.7-3.4.1-1.5.9-3.7,4.1-5.1c1-.5,2.2-.7,3.3-.6s2.2.6,3.1,1.3c-.8-.2-1.5-.2-2.3-.1-.7.1-1.4.4-2.1.8-.6.4-1.2.9-1.6,1.6-3.1,4.5.5,7.3,2.2,8.6l.2.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M391.3,342.9c1.5-1.3,10.9-10.3,15.2,3.8.2.6.3,1.3.2,2-.4,2.2,2.8,3.2,3.8,1.2.9-1.9.7-4.8-1.6-9.3-9.3-17.8-22,6.2-17.6,2.3Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M408.7,352c.6,0,1.1-.2,1.6-.4.5-.3.8-.7,1.1-1.2c1.2-2.5.7-5.9-1.6-10.2-3.4-6.6-7.3-7.5-10-7.1-5.5.8-9.9,7.7-10.3,9.7-.1.3-.1.6,0,.8s.3.5.5.6c.8.5,1.7-.3,2-.5l.2-.2c3.3-2.9,6-4.1,8.2-3.5s3.9,3,5.1,7c.2.5.2,1,.1,1.5-.1.7,0,1.4.4,2s1,1.1,1.6,1.3c.4.2.8.2,1.1.2Zm-7.9-17c2.6,0,5,2,7.1,6.1c1.9,3.7,2.4,6.5,1.5,8.4-.1.2-.3.4-.5.5s-.5.1-.7,0-.4-.2-.5-.4-.2-.4-.1-.6c.1-.8.1-1.7-.2-2.4-1.5-4.7-3.7-7.6-6.5-8.4-2.4-.7-5.1.1-8,2.2c1.5-2.1,4-4.6,6.8-5.2.4-.2.8-.3,1.1-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M401.5,339.5c1.5-1.3,21.2-18.3,15.2,3.8-.8,2.9-7.5-4.7-7.5-4.7s13.1,17.3,15.8,3.9c2.4-12.2-27.9.9-23.5-3Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M420.8,348.6c.3,0,.7,0,1-.1c2.1-.5,3.5-2.4,4.2-5.8.4-2.1,0-3.7-1.1-4.9-1.3-1.3-3.5-1.7-6-1.7-.1-2.3-.7-3.8-2-4.5-1.1-.7-5.5-2.2-16,7.2-.3.2-1.1,1-.7,1.9.6,1.1,1.7.8,6.1-.3.7-.2,1.6-.4,2.5-.6c1.6,2,7.3,8.8,12,8.8ZM419,338.1c1.9,0,3.5.3,4.4,1.1.7.6.8,1.6.6,3-.5,2.5-1.4,3.9-2.6,4.2-1.3.3-2.9-.5-4.6-1.7.2-.1.4-.3.6-.5s.3-.4.3-.7c.5-1.8.9-3.6,1.1-5.4h.2Zm-2.3.2c-.2,1.6-.5,3.1-.9,4.7-.8-.2-2.7-1.8-4.6-3.7c1.7-.5,3.6-.8,5.5-1Zm.1-2.1c-3.7.4-7.4,1.1-11,2.1l-1.9.5c6.5-5.3,10.6-6.3,11.9-5.5.4.3.9,1,1,2.9v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M395.9,349.8c-.8-1.8-3.5,6.5-8.8,2.3-4.4-3.5,1-9.2,1-9.2s-8.1,3.7-5.5,10.7c3.5,9.8,16.5,3.5,13.3-3.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M389,359.6c1.3,0,2.7-.3,3.9-.9s2.3-1.4,3.1-2.5c.8-1,1.2-2.1,1.4-3.3.1-1.2-.1-2.4-.6-3.5-.1-.2-.2-.4-.4-.6-.2-.1-.4-.2-.6-.3-.8-.1-1.4.5-2.2,1.4-1.8,2-3.4,3.4-6,1.4-.4-.3-.7-.6-.9-1s-.4-.9-.4-1.3c-.2-2.5,2.4-5.3,2.4-5.3l3.5-3.8-4.7,2.1c-3.1,1.4-8.4,5.7-6.1,12c1.2,3.2,3.5,5.2,6.4,5.7.4-.1.8-.1,1.2-.1Zm6.2-8.5c.2.7.2,1.4,0,2.1s-.5,1.3-.9,1.9c-.7.9-1.7,1.6-2.8,2.1s-2.3.6-3.4.5c-1.5-.2-3.6-1.2-4.8-4.4-.4-1.1-.5-2.3-.3-3.4s.7-2.2,1.5-3.1c-.2.7-.3,1.5-.3,2.3.1.7.3,1.5.6,2.1.4.7.9,1.2,1.5,1.7c4.3,3.4,7.3,0,8.7-1.6.1-.1.2-.2.2-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M388.7,352c1.9.7-2.3-7,4.5-8c5.6-.8,6,7,6,7s2.9-8.4-3.9-11.4c-9.7-4.1-14.2,9.7-6.6,12.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M398.5,356.3l1.7-4.9c1.1-3.2,1.6-10-4.4-12.7-3.1-1.4-6.2-1.1-8.5.7-1.2,1-2.1,2.2-2.7,3.7-.6,1.4-.7,3-.5,4.5.2,1.2.7,2.3,1.4,3.3.8.9,1.8,1.6,2.9,2c.2.1.5.1.7.1s.5-.1.7-.3c.7-.5.6-1.4.5-2.5-.2-2.7-.1-4.8,3.1-5.3.5-.1.9-.1,1.4.1.5.1.9.3,1.2.6c2,1.6,2.2,5.4,2.2,5.4l.3,5.3ZM394,343c-.3,0-.6,0-.9.1-5.4.8-5.1,5.3-4.9,7.4c0,.1,0,.1,0,.2-.6-.4-1.1-.8-1.5-1.4s-.6-1.2-.7-1.9c-.2-1.2,0-2.4.4-3.4.4-1.1,1.1-2.1,2-2.8c1.2-.9,3.3-1.8,6.5-.4c1.1.4,2,1.2,2.7,2.1s1.1,2.1,1.2,3.2c-.4-.7-.9-1.3-1.5-1.8-1-.9-2.1-1.4-3.3-1.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M419.5,316.3c-2-.2-14.9-1.2-7.3-13.9.3-.6.8-1.1,1.4-1.4c1.9-1.2.5-4.2-1.6-3.6-2,.6-4.1,2.6-5.9,7.3-7.2,18.8,19.2,12.3,13.4,11.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M414.4,319c2.8,0,5.2-.6,6.1-1.1.3-.1.5-.3.6-.6s.2-.6.1-.8c-.2-.9-1.3-1.1-1.7-1.1h-.2c-4.3-.5-7.1-1.8-8.1-3.9s-.4-4.9,1.8-8.5c.2-.4.6-.8,1-1.1.6-.4,1.1-1,1.3-1.7s.1-1.4-.2-2.1-.8-1.2-1.4-1.5c-.7-.3-1.4-.4-2.1-.2-2.6.8-4.8,3.4-6.6,7.9-2.7,7-.7,10.5,1.4,12.2c2.1,1.9,5.3,2.5,8,2.5Zm2-2.1c-2.6.3-6.1.1-8.4-1.6-2.5-1.9-2.8-5.3-1-10.2c1.5-3.9,3.2-6.2,5.2-6.7.2-.1.5,0,.7.1s.4.3.5.5.1.4.1.7c-.1.2-.2.4-.4.5-.7.4-1.3,1.1-1.7,1.8-2.6,4.2-3.2,7.8-1.9,10.4c1,2.1,3.4,3.7,6.9,4.5v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M410.9,364.3c-2-.2-27.8-3.6-7.3-13.9c2.6-1.3,1.5,8.8,1.5,8.8s4.2-21.3-7.6-14.4c-10.8,6.3,19.2,20.2,13.4,19.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M411.2,365.4c.3,0,.6,0,.8-.2s.4-.4.6-.6c.4-1.1-.6-1.8-4.3-4.4-.6-.4-1.4-.9-2.1-1.4.5-2.8,1.9-12.7-2.1-15.6-1.7-1.2-4.1-1-7.1.7-1.8,1.1-2.8,2.4-2.9,4.1-.1,1.8,1,3.7,2.7,5.6-1.6,1.6-2.3,3.1-2,4.5.2,1.3,2,5.5,16,7.2.1,0,.2.1.4.1Zm-13-10.3c2.8,2.5,5.8,4.8,8.9,6.8l1.6,1.1c-8.3-1.3-11.7-3.8-12-5.2c0-.5.1-1.4,1.5-2.7Zm5.7-3.7c.4.7.5,3.2.3,6-1.5-1.1-3-2.3-4.4-3.6c1.3-.9,2.7-1.7,4.1-2.4v0Zm.1-2.1c-.3,0-.6.1-.9.2-1.7.8-3.3,1.8-4.8,2.8-1.4-1.5-2.3-3-2.3-4.3c0-.9.6-1.7,1.8-2.4c2.2-1.3,3.8-1.6,4.9-.8c1,.7,1.6,2.5,1.8,4.5c0,0-.2,0-.5,0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M383.2,269.7c-4.4-.3,9.2,13.7-4.8,19.9-11.5,5.1-17.1-11.5-17.1-11.5s-1.1,20,15.3,22.1c23.3,3.1,24.5-29.3,6.6-30.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M379.6,301.4c5.7,0,10.5-2.2,13.5-6.4c1.9-2.6,3-5.7,3.3-8.9s-.2-6.4-1.5-9.4c-1-2.3-2.6-4.3-4.7-5.7s-4.5-2.2-7-2.3c-.3,0-.6,0-.9.1s-.6.3-.7.6c-.7,1-.2,2.4.6,4.5c2.2,5.9,3.5,11.4-4.2,14.8-1.1.5-2.3.8-3.5.9-1.2,0-2.4-.2-3.5-.7-5.7-2.5-8.7-11-8.7-11.1l-1.7-4.9-.3,5.2c0,.2-1,20.9,16.2,23.2c1,.1,2,.2,3.1.1Zm3.7-30.7c2.1.1,4.1.8,5.8,2s3.1,2.9,3.9,4.8c1.2,2.6,1.6,5.5,1.4,8.3-.3,2.8-1.3,5.6-2.9,7.9-3.2,4.3-8.4,6.3-14.8,5.4-6.4-.8-10.8-4.6-13-11.2-.5-1.6-.9-3.2-1.1-4.8c1.6,2.8,4.1,6,7.5,7.4c1.4.6,2.9.9,4.4.9s3-.4,4.3-1c10.1-4.5,7-12.9,5.3-17.4-.4-.6-.6-1.4-.8-2.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M350,267.5c.5,4.4,11.9-11.4,20.4,1.4c6.9,10.4-8.4,18.8-8.4,18.8s19.8-2.3,19.2-18.9c-1-23.5-33.1-19.2-31.2-1.3Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M357,289.4l5.1-.6c.2,0,20.8-2.6,20.1-19.9-.3-7.2-3.5-12.7-9-15.4-2.9-1.4-6.1-2-9.3-1.7-3.2.2-6.3,1.3-9,3.1-2.1,1.4-3.8,3.3-4.8,5.6s-1.4,4.8-1.1,7.3c0,.3.1.6.3.9s.4.5.7.6c1.1.5,2.4-.2,4.3-1.4c5.4-3.2,10.6-5.4,15.3,1.6.7,1,1.2,2.1,1.5,3.3.2,1.2.2,2.4-.1,3.6-1.5,6.1-9.4,10.5-9.5,10.5l-4.5,2.5Zm8.4-35.7c2.4,0,4.8.5,6.9,1.6c4.8,2.4,7.6,7.3,7.9,13.7.2,6.4-2.7,11.4-8.8,14.7-1.4.8-3,1.4-4.5,1.9c2.4-2.1,5.2-5.1,6.1-8.7.4-1.5.4-3,.1-4.5s-.9-2.9-1.8-4.1c-6.1-9.2-13.8-4.7-18-2.2-.7.4-1.5.8-2.2,1.2-.2-2.1.1-4.2,1-6.1s2.3-3.5,4.1-4.7c2.6-1.9,5.9-2.9,9.2-2.8Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M360,257.7c.1,1.3,3.5-3.4,6.1.4c2,3.1-2.5,5.6-2.5,5.6s5.9-.7,5.7-5.6c-.3-7-9.8-5.7-9.3-.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M358.6,265.3l5.1-.6c2.4-.3,6.8-2,6.6-6.7-.1-2.4-1.2-4.3-3.1-5.2-1-.5-2.1-.7-3.1-.6-1.1.1-2.1.4-3,1.1-.7.5-1.3,1.2-1.6,2-.4.8-.5,1.7-.4,2.5c0,.2.1.4.2.6s.3.3.5.4c.7.3,1.3,0,2-.4c1.7-1,2.6-1.2,3.6.3.2.2.3.5.3.7.1.3,0,.5,0,.8-.3,1.2-1.9,2.4-2.5,2.7l-4.6,2.4Zm6-11c.6,0,1.2.1,1.7.4c1.2.6,1.9,1.8,1.9,3.5.1.9-.2,1.8-.7,2.5.1-.5.1-1.1,0-1.6s-.3-1-.6-1.5c-1.9-2.9-4.4-2-5.8-1.2.2-.6.6-1,1.1-1.4.7-.5,1.6-.7,2.4-.7Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M326.9,295.6c1.5-2,2.5,9.2,10.4,5.4c6.5-3.1,1.5-11.8,1.5-11.8s9.1,7,3.8,15c-7.4,11.4-21.9-.3-15.7-8.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M334.9,310c3.4,0,6.4-1.8,8.6-5.1c4.6-6.9-.7-13.7-4.1-16.4l-4.1-3.2l2.6,4.5c0,0,2.5,4.4,1.5,7.6-.2.6-.5,1.2-1,1.6-.4.5-1,.8-1.6,1.1-4.1,1.9-5.8-.5-7.4-3.6-.7-1.2-1.1-2.1-2.1-2.2-.3,0-.5,0-.7.2-.2.1-.4.3-.6.5-.9,1.2-1.5,2.6-1.7,4.1s.1,3,.7,4.4c.8,1.8,2.1,3.3,3.6,4.4c1.6,1.1,3.4,1.8,5.3,2c.5,0,.7.1,1,.1Zm6.6-16.3c1.7,2.5,2.8,6.1.3,10-2.5,3.8-5.5,4.4-7.5,4.2-1.6-.2-3-.7-4.3-1.6s-2.3-2.1-2.9-3.5c-.4-.9-.7-1.9-.6-3c.1-1,.4-2,.9-2.9.1.2.3.5.4.7c1.3,2.4,4,7.3,10.1,4.4.8-.4,1.6-.9,2.2-1.6s1.1-1.5,1.4-2.4c.3-1.4.3-2.9,0-4.3v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M351.1,274.7c.3,3,8-7.6,13.6.9c4.6,7-5.6,12.6-5.6,12.6s13.3-1.5,12.8-12.6c-.6-15.8-22.1-12.9-20.8-.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M354.1,289.8l5.1-.6c.1,0,14.2-1.8,13.7-13.7-.2-4.9-2.4-8.7-6.2-10.6-2-1-4.2-1.4-6.4-1.2s-4.3.9-6.2,2.2c-1.4,1-2.6,2.3-3.3,3.9s-1,3.3-.8,5c0,.3.1.5.2.8.1.2.3.4.6.5.9.4,1.8-.1,3.2-.9c3.5-2,6.8-3.5,9.8,1c.5.6.8,1.3.9,2.1.2.8.1,1.5,0,2.3-1,3.9-6.1,6.8-6.2,6.8l-4.4,2.4Zm7.3-24.1c1.6,0,3.1.3,4.5,1c2.2,1.1,4.9,3.5,5.1,8.9.2,4.2-1.8,7.4-5.7,9.6-.5.2-.9.5-1.4.7c1.4-1.3,2.4-3,2.9-4.8.2-1,.3-2.1.1-3.1s-.6-2-1.2-2.9c-4.3-6.4-9.8-3.2-12.5-1.6-.3.2-.7.4-1,.6-.1-1.3.2-2.5.8-3.7.6-1.1,1.4-2.1,2.5-2.8c1.6-1.2,3.7-1.9,5.9-1.9Zm-9.3,8.9l-1,.1l1-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M317,300.8c-1.4-2.6,10.9,1.8,10.8-8.4-.1-8.4-11.7-7.3-11.7-7.3s11.8-6.2,17.7,3.2c8.3,13.4-11.1,23.1-16.8,12.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M324.6,306.4c2.2,0,4.3-.6,6.2-1.7s3.5-2.7,4.6-4.6c2.1-3.7,1.8-8.1-.8-12.3-6.3-10.1-18.9-3.7-19-3.6l-4.6,2.4l5.1-.5c.1,0,5.9-.5,8.9,2.2.6.5,1,1.2,1.3,1.9s.4,1.5.4,2.2c.1,5.3-3.5,6-7.5,6.3-1.6.1-2.7.2-3.2,1-.1.2-.2.5-.2.8s.1.5.2.8c.8,1.5,2,2.8,3.5,3.7s3.2,1.4,4.9,1.3h.2Zm-.4-22.3c1.7-.1,3.5.3,5,1.2c1.5.8,2.8,2.1,3.7,3.6c2.8,4.5,2,8,.8,10.2-.9,1.6-2.3,2.9-3.8,3.8-1.6.9-3.4,1.4-5.2,1.5h-.1c-1.3,0-2.5-.3-3.6-.9s-2-1.5-2.7-2.6c.4,0,.8-.1,1.2-.1c3.1-.2,9.5-.7,9.4-8.4c0-1-.2-2.1-.6-3.1s-1-1.8-1.8-2.5c-1.5-1.2-3.2-2-5.1-2.4.5-.1,1-.2,1.6-.2.3-.1.8-.1,1.2-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M346.6,294.3c.2,2,5.3-5.1,9.1.6c3.1,4.7-3.8,8.4-3.8,8.4s8.9-1,8.6-8.4c-.4-10.5-14.8-8.6-13.9-.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M346.9,304.9l5.1-.6c3.4-.4,9.7-2.9,9.5-9.5-.1-3.4-1.7-6-4.3-7.4-1.4-.7-2.9-.9-4.5-.8-1.5.1-3,.6-4.3,1.5-1,.7-1.8,1.6-2.3,2.7s-.7,2.3-.6,3.5c0,.2.1.5.2.7s.3.4.5.5c.8.4,1.5-.1,2.5-.6c2.3-1.4,4.3-2.2,6.1.5.3.4.5.8.6,1.3s.1.9,0,1.4c-.6,2.5-3.9,4.3-3.9,4.3l-4.6,2.5Zm6.5-16.3c1,0,2,.2,2.9.7c1.4.7,3.1,2.2,3.2,5.6.1,1.2-.2,2.3-.7,3.3s-1.4,1.9-2.4,2.4c.4-.6.8-1.3,1-2.1.2-.7.2-1.5.1-2.2s-.4-1.4-.9-2c-3-4.6-6.9-2.3-8.8-1.2l-.2.1c.1-.7.3-1.4.6-2c.4-.6.8-1.1,1.4-1.5c1.1-.6,2.5-1.1,3.8-1.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M354,294.4c-1.6-1.2,0,7.4-6.8,6.2-5.5-1-3.5-8.6-3.5-8.6s-5.4,7.1.2,12c8,7,16.5-4.7,10.1-9.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M349.1,307.3c.9,0,1.7-.2,2.5-.4c1.4-.5,2.7-1.5,3.7-2.6c1-1.2,1.6-2.6,1.9-4.1.2-1.2.1-2.4-.4-3.6-.4-1.1-1.2-2.1-2.2-2.8-.2-.2-.4-.3-.6-.3s-.5,0-.7,0c-.8.3-1,1.1-1.3,2.2-.6,2.6-1.4,4.6-4.6,4-.5-.1-.9-.2-1.3-.5-.4-.2-.7-.6-1-1-1.4-2.1-.4-5.8-.4-5.9l1.3-5-3.1,4.1c-2,2.7-4.6,9,.3,13.4c1.7,1.5,3.7,2.4,5.9,2.5Zm5-11.4c.5.5.8,1.1,1,1.8s.2,1.4.1,2.1c-.2,1.2-.7,2.2-1.4,3.2-.7.9-1.7,1.6-2.8,2-1.5.5-3.7.7-6.3-1.6-.9-.7-1.5-1.7-1.9-2.8s-.4-2.3-.1-3.4c.2.8.4,1.5.9,2.2.4.6.9,1.2,1.6,1.6.6.4,1.3.7,2.1.8c5.4,1,6.4-3.4,6.9-5.6-.1-.2-.1-.3-.1-.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M385.5,264.7c1.6,1.2.1-7.4,6.8-6.1c5.5,1.1,3.4,8.6,3.4,8.6s5.5-7,0-12c-7.8-7.1-16.5,4.5-10.2,9.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M393.3,271.9l3.2-4.1c2.1-2.7,4.8-9-.1-13.4-2.5-2.3-5.5-3-8.3-2.1-1.4.5-2.7,1.4-3.7,2.6s-1.7,2.6-1.9,4.1c-.2,1.2-.1,2.4.3,3.6.4,1.1,1.2,2.1,2.1,2.9.2.2.4.3.6.3s.5,0,.7,0c.8-.3,1-1.1,1.3-2.2.7-2.6,1.4-4.6,4.7-4c.5.1.9.2,1.3.5s.7.6,1,1c1.1,1.8.6,4.8.3,5.9l-1.5,4.9ZM391,257.5c-4.1,0-5,3.7-5.5,5.6c0,.1,0,.1,0,.2-.5-.5-.8-1.1-1-1.8s-.2-1.4-.1-2.1c.2-1.2.7-2.2,1.5-3.1s1.7-1.6,2.8-2c1.5-.5,3.7-.6,6.3,1.7.9.8,1.5,1.8,1.9,2.9.3,1.1.4,2.3.1,3.4-.1-.8-.4-1.5-.8-2.2-.4-.6-.9-1.2-1.5-1.6s-1.3-.7-2.1-.8c-.6-.2-1.1-.2-1.6-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M362.5,274.7c.7-3.8-10.3,5.2-13-8.5-2.2-11.2,9.6-12.6,9.6-12.6s-13.5-5.3-16.7,8.7c-4.5,19.9,17.5,28,20.1,12.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M354.2,283.9c1,0,2-.2,3-.5c3.3-1.2,5.6-4.2,6.3-8.5.1-.3.1-.6,0-.9s-.2-.6-.4-.8c-.7-.7-1.9-.6-3.3-.3-3.2.5-7.7,1.2-9.3-6.8-.6-2.9-.2-5.3,1.2-7.3c2.5-3.5,7.5-4.2,7.6-4.2l4-.5-3.7-1.5c-.3-.1-6.5-2.5-11.8.3-3.1,1.7-5.2,4.7-6.2,9.2-1.7,7.4.1,14.3,4.7,18.5c2.1,2.1,4.9,3.2,7.9,3.3Zm7.3-9.2c-.6,3.5-2.4,5.9-4.9,6.8-2.8,1-6.2.1-9-2.4-4.1-3.7-5.7-9.9-4.1-16.5.9-3.8,2.6-6.4,5.2-7.8c2-1,4.2-1.3,6.4-1-2,.8-3.7,2.1-5,3.8-1.7,2.4-2.2,5.4-1.5,8.9c2,10,8.5,8.9,11.6,8.4.4-.1.9-.2,1.3-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M390.8,259.5c-2,.3,5.3,5.2-.3,9.1-4.6,3.2-8.5-3.5-8.5-3.5s1.3,8.8,8.7,8.3c10.4-.7,8.1-15,.1-13.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M390.1,274.5h.7c3.4-.2,6-1.9,7.2-4.6.6-1.4.9-3,.7-4.5s-.7-3-1.6-4.2c-.7-1-1.7-1.8-2.8-2.3s-2.4-.6-3.6-.5c-.2,0-.5.1-.7.2s-.3.3-.5.5c-.4.8.1,1.5.7,2.5c1.4,2.3,2.3,4.2-.4,6.1-.4.3-.8.5-1.2.6s-.9.1-1.4,0c-2.5-.5-4.4-3.8-4.5-3.8l-2.6-4.4.7,5.1c.6,3.3,3.2,9.3,9.3,9.3Zm1.8-14.1c.7,0,1.4.2,2,.6.6.3,1.1.8,1.5,1.4.7,1,1.1,2.1,1.2,3.2.1,1.2-.1,2.4-.5,3.4-.6,1.4-2.1,3.1-5.5,3.4-1.1.1-2.3-.1-3.3-.6s-1.9-1.3-2.5-2.3c.6.4,1.4.7,2.1.9s1.5.1,2.2,0c.7-.2,1.4-.5,2-.9c4.5-3.2,2-7,.9-8.8l-.1-.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M309.1,282.4c1.6,1.2.1-7.4,6.8-6.1c5.5,1.1,3.4,8.6,3.4,8.6s5.5-7,0-12c-7.8-7.1-16.5,4.5-10.2,9.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M316.9,289.6l3.2-4.1c2.1-2.7,4.8-9-.1-13.4-2.5-2.3-5.5-3-8.3-2.1-1.4.5-2.7,1.4-3.7,2.6s-1.7,2.6-1.9,4.1c-.2,1.2-.1,2.4.3,3.6.4,1.1,1.2,2.1,2.1,2.9.2.2.4.3.6.3s.5,0,.7,0c.8-.3,1-1.1,1.3-2.2.7-2.6,1.4-4.6,4.7-4c.5.1.9.2,1.3.5s.7.6,1,1c1.4,2.2.3,5.8.3,5.9l-1.5,4.9Zm-2.3-14.5c-4.1,0-5,3.7-5.5,5.6c0,.1,0,.1,0,.2-.5-.5-.8-1.1-1-1.8s-.2-1.4-.1-2.1c.2-1.2.7-2.2,1.5-3.1s1.7-1.6,2.8-2c1.5-.5,3.7-.6,6.3,1.7.9.8,1.5,1.8,1.9,2.9.3,1.1.4,2.3.1,3.4-.1-.8-.4-1.5-.8-2.2-.4-.6-.9-1.2-1.5-1.6s-1.3-.7-2.1-.8c-.6-.1-1.1-.1-1.6-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M329.6,267.9c1.8.8,13.8,5.8,2.6,15.5-.5.4-1.1.8-1.7.9-2.2.5-1.8,3.8.4,3.9c2.1.1,4.7-1.3,7.9-5.2c12.6-15.6-14.5-17.6-9.2-15.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M331.1,289.2c2.7,0,5.5-1.9,8.5-5.5c4.7-5.8,3.9-9.8,2.5-12.1-3-4.7-11-6-13-5.6-.3,0-.6.2-.8.4s-.3.5-.4.8c-.1,1,.9,1.4,1.3,1.6l.2.1c4,1.8,6.2,3.9,6.5,6.2s-1.1,4.7-4.3,7.5c-.4.3-.9.6-1.4.7-.7.2-1.3.6-1.7,1.2s-.6,1.3-.5,2s.4,1.4.9,1.9s1.2.8,1.9.8h.3Zm1.6-20.9c2.5.5,5.8,1.8,7.5,4.2c1.8,2.6,1,5.9-2.2,9.9-2.6,3.2-5,4.8-7,4.8-.2,0-.5-.1-.6-.3-.2-.2-.3-.4-.3-.6s0-.5.1-.6c.1-.2.3-.3.5-.4.8-.2,1.5-.6,2.2-1.2c3.8-3.2,5.4-6.4,5-9.3-.4-2.5-2.1-4.7-5.2-6.5Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M336.8,275.8c1.8.8,25.3,12,2.6,15.5-2.9.4,1.4-8.8,1.4-8.8s-10.5,19,2.8,16.1c12.1-2.7-12.1-25.3-6.8-22.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M341,299.8c.9,0,1.8-.1,2.7-.3c2.1-.5,3.4-1.5,4-3c.6-1.7.2-3.9-.9-6.2c2-1,3.1-2.2,3.4-3.7.2-1.3-.2-5.9-13-11.8-.3-.2-1.4-.6-2,.1-.7.9,0,1.9,2.7,5.5.5.6,1,1.3,1.5,2-1.4,2.6-5.7,11.5-2.8,15.5c1.1,1.3,2.5,1.9,4.4,1.9Zm4-8.7c.9,1.9,1.3,3.6.8,4.7-.3.9-1.1,1.4-2.5,1.7-2.5.5-4.1.3-4.9-.7s-.7-2.8-.3-4.9c.2.2.4.3.7.3.3.1.5.1.8,0c1.8-.2,3.6-.6,5.4-1.1Zm-1-1.9c-1.5.4-3.1.8-4.6,1-.1-.8.5-3.2,1.6-5.8c1.1,1.6,2.1,3.2,3,4.8Zm-5.6-11.5c7.5,3.8,10,7.2,9.8,8.7-.1.5-.5,1.4-2.2,2.2-1.9-3.2-4-6.3-6.3-9.2-.5-.6-.9-1.1-1.3-1.7v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M325.2,274.8c1.4-1.5-7.3-.6-5.6-7.2c1.4-5.4,8.8-2.8,8.8-2.8s-6.7-6-12-.8c-7.7,7.3,3.3,16.8,8.8,10.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M321,277.7c.9,0,1.8-.2,2.7-.6.8-.4,1.6-.9,2.2-1.6.2-.2.3-.4.4-.6s.1-.5,0-.7c-.2-.8-1.1-1.1-2.1-1.4-2.6-.8-4.5-1.7-3.6-4.9.1-.5.3-.9.6-1.3s.6-.7,1-.9c2.2-1.2,5.8.1,5.9.1l4.8,1.7L329,264c-2.5-2.3-8.6-5.4-13.4-.8-2.5,2.4-3.4,5.3-2.6,8.1.4,1.5,1.2,2.8,2.3,3.9s2.5,1.8,4,2.2c.6.2,1.1.3,1.7.3Zm2.7-3c-.6.4-1.2.7-1.9.8s-1.4.1-2-.1c-1.1-.3-2.2-.9-3-1.7s-1.5-1.8-1.8-3c-.4-1.5-.4-3.8,2.1-6.1.8-.8,1.8-1.4,3-1.7c1.1-.3,2.3-.2,3.4.1-.8.1-1.5.3-2.2.7s-1.2.8-1.7,1.4-.8,1.3-.9,2c-1.4,5.3,2.9,6.7,5,7.4v.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M320.3,269.1c.1,2,5.5-4.9,9.1.9c2.9,4.8-4.1,8.3-4.1,8.3s8.9-.7,8.9-8.1c-.1-10.5-14.5-9.1-13.9-1.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M320.3,279.8l5.1-.4c3.4-.3,9.8-2.5,9.8-9.2c0-3.4-1.5-6.1-4.1-7.5-1.4-.7-2.9-1.1-4.4-1s-3,.5-4.3,1.3c-1,.6-1.9,1.6-2.4,2.6-.5,1.1-.8,2.3-.7,3.5c0,.2.1.5.2.7s.3.4.5.5c.7.4,1.5,0,2.5-.5c2.4-1.3,4.3-2,6.1.8.3.4.4.8.5,1.3s.1.9-.1,1.4c-.7,2.5-4.1,4.2-4.1,4.2l-4.6,2.3Zm6.8-16.1c1.1,0,2.1.2,3.1.7c1.4.7,3,2.3,3,5.7c0,1.2-.3,2.3-.9,3.3s-1.4,1.8-2.5,2.4c.5-.6.8-1.3,1.1-2.1.2-.7.2-1.5.1-2.2s-.4-1.4-.8-2.1c-2.9-4.7-6.8-2.5-8.7-1.5l-.2.1c.1-.7.3-1.4.7-1.9.4-.6.9-1.1,1.5-1.4c1-.7,2.3-1,3.6-1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M365.2,283c-.6-1.9-4.9-14.2,9.8-12.2.7.1,1.3.3,1.9.7c1.8,1.3,4.1-1.2,2.6-2.9-1.4-1.7-4.1-2.7-9.1-2.4-20,.8-3.5,22.4-5.2,16.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M365.1,284.9c.2,0,.5-.1.7-.2.8-.6.5-1.6.3-2l-.1-.2c-1.3-4.2-1.2-7.2.3-9s4.3-2.3,8.5-1.8c.5.1,1,.2,1.4.5.6.4,1.3.6,2,.5s1.4-.4,1.8-1c.5-.5.8-1.2.8-1.9s-.2-1.4-.7-2c-1.7-2.1-5.1-3-9.9-2.8-7.4.4-9.9,3.5-10.6,6.1-1.6,5.4,2.8,12.2,4.5,13.4.4.3.7.4,1,.4Zm7-15.4c-3.3,0-5.8.9-7.3,2.7s-2,4.6-1.4,8.2c-1.3-2.3-2.5-5.6-1.9-8.4.7-3,3.7-4.7,8.9-4.9c4.2-.2,6.9.5,8.2,2.1.1.2.2.4.2.6s-.1.5-.3.6c-.1.2-.3.3-.6.3-.2,0-.5,0-.6-.2-.7-.5-1.5-.7-2.3-.8-.9-.1-1.9-.2-2.9-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M317.9,294.4c-.6-1.9-7.8-26.9,9.8-12.2c2.3,1.9-7.4,4.9-7.4,4.9s21.1-4.7,10.1-12.8c-10.1-7.4-10.9,25.7-12.5,20.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M318.2,296v0c1.3-.1,1.5-1.3,2.3-5.7.1-.7.3-1.6.5-2.5c2.8-.7,12.4-3.4,13.4-8.2.4-2.1-.7-4.2-3.5-6.2-1.7-1.3-3.4-1.6-4.9-1-1.7.7-3,2.5-4.1,4.7-2.1-.9-3.7-.9-5-.1-1.1.7-4.2,4.1-.2,17.5.3.5.6,1.5,1.5,1.5Zm3.1-16.9c-1.2,3.6-2.1,7.2-2.7,10.9-.1.6-.3,1.3-.4,2-2.1-8.1-1.3-12.3,0-13.1.4-.4,1.3-.6,3.1.2Zm5.6,3.7c-.5.6-2.7,1.7-5.3,2.7.4-1.8.9-3.7,1.5-5.5c1.3.9,2.6,1.8,3.8,2.8Zm.7-8.6c.8.1,1.6.4,2.2.9c2,1.5,3,2.9,2.7,4.2s-1.6,2.4-3.4,3.5c0-.3-.1-.5-.2-.8-.1-.2-.3-.4-.5-.6-1.4-1.2-2.9-2.3-4.5-3.2.8-1.9,1.8-3.3,3-3.8.1-.2.4-.2.7-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M378.2,310.6c-4.4-.3,9.2,13.7-4.8,19.9-11.5,5.1-17.1-11.5-17.1-11.5s-1.1,20,15.3,22.1c23.3,3.1,24.5-29.4,6.6-30.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M374.6,342.3c5.7,0,10.5-2.2,13.5-6.4c1.9-2.6,3-5.7,3.3-8.9s-.2-6.4-1.6-9.4c-1-2.3-2.6-4.3-4.7-5.7s-4.5-2.2-7-2.3c-.3,0-.6,0-.9.1s-.5.3-.7.6c-.7,1-.2,2.4.6,4.5c2.2,5.9,3.5,11.4-4.2,14.8-1.1.5-2.3.8-3.5.9-1.2,0-2.4-.2-3.5-.7-5.7-2.5-8.7-11-8.7-11.1l-1.7-4.9-.3,5.2c0,.2-1,20.9,16.2,23.2c1.1.1,2.1.1,3.2.1Zm3.7-30.7c2.1.1,4.1.8,5.8,2s3.1,2.9,3.9,4.8c1.2,2.6,1.6,5.5,1.4,8.3-.3,2.8-1.3,5.6-2.9,7.9-3.2,4.3-8.4,6.3-14.8,5.4-6.4-.8-10.8-4.6-13-11.2-.5-1.6-.9-3.2-1.1-4.8c1.6,2.8,4.1,6,7.5,7.4c1.4.6,2.9.9,4.4.9s3-.4,4.3-1c10.1-4.5,7-12.9,5.3-17.4-.4-.7-.6-1.5-.8-2.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M345,308.4c.5,4.4,11.9-11.4,20.4,1.4c6.9,10.5-8.4,18.9-8.4,18.9s19.8-2.3,19.2-18.9c-.9-23.6-33.1-19.3-31.2-1.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M352,330.2l5.1-.6c.2,0,20.8-2.6,20.1-20-.3-7.2-3.5-12.7-9-15.4-2.9-1.4-6.1-2-9.3-1.7-3.2.2-6.3,1.3-9,3.1-2.1,1.4-3.8,3.3-4.8,5.6s-1.4,4.8-1.1,7.3c0,.3.1.6.3.9s.4.5.7.6c1.1.5,2.4-.2,4.3-1.4c5.4-3.2,10.6-5.4,15.3,1.6.7,1,1.2,2.1,1.4,3.3s.2,2.4-.1,3.6c-1.5,6.1-9.4,10.5-9.5,10.5l-4.4,2.6Zm8.4-35.7c2.4,0,4.8.5,6.9,1.6c4.8,2.4,7.6,7.3,7.9,13.7.2,6.4-2.7,11.4-8.8,14.7-1.5.8-3,1.4-4.5,1.9c2.4-2.1,5.2-5.1,6.1-8.7.4-1.5.4-3,.1-4.5s-.9-2.9-1.8-4.1c-6.1-9.2-13.8-4.7-18-2.2-.7.4-1.5.8-2.2,1.2-.2-2.1.1-4.2,1-6.1s2.3-3.5,4.1-4.7c2.7-1.8,5.9-2.8,9.2-2.8v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M355.1,298.6c.1,1.3,3.5-3.4,6.1.4c2,3.1-2.5,5.6-2.5,5.6s5.9-.7,5.7-5.6c-.4-7-9.9-5.8-9.3-.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M353.7,306.2l5.1-.6c2.4-.3,6.8-2,6.6-6.7-.1-2.4-1.2-4.3-3.1-5.2-1-.5-2.1-.7-3.1-.6-1.1.1-2.1.4-3,1.1-.7.5-1.3,1.2-1.6,2s-.5,1.7-.4,2.5c0,.2.1.4.2.6s.3.3.5.4c.7.3,1.3,0,2-.4c1.7-1,2.6-1.2,3.6.3.2.2.3.5.3.7.1.3.1.5,0,.8-.3,1.2-1.9,2.3-2.5,2.7l-4.6,2.4Zm5.9-11c.6,0,1.2.1,1.7.4c1.2.6,1.9,1.8,1.9,3.5.1.9-.2,1.8-.7,2.5.1-.5.1-1.1,0-1.6s-.3-1-.6-1.5c-1.9-2.9-4.4-2-5.8-1.2.2-.6.6-1,1.1-1.4.8-.5,1.6-.7,2.4-.7Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M322,336.5c1.5-2,2.5,9.2,10.4,5.4c6.5-3.1,1.5-11.8,1.5-11.8s9.1,7,3.8,15c-7.5,11.3-22-.3-15.7-8.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M329.9,350.8c3.4,0,6.4-1.8,8.6-5.1c4.6-6.9-.7-13.7-4.1-16.4l-4.1-3.2l2.6,4.5c0,0,2.5,4.4,1.5,7.6-.2.6-.5,1.2-1,1.6-.4.5-1,.8-1.6,1.1-4.1,1.9-5.8-.5-7.4-3.6-.7-1.2-1.1-2.1-2.1-2.2-.3,0-.5,0-.7.2-.2.1-.4.3-.6.5-.9,1.2-1.5,2.6-1.7,4.1s.1,3,.7,4.4c.8,1.8,2.1,3.3,3.6,4.4c1.6,1.1,3.4,1.8,5.3,2c.5.1.8.1,1,.1Zm6.6-16.2c1.6,2.5,2.8,6.1.3,10-2.5,3.8-5.5,4.4-7.5,4.2-1.6-.2-3-.7-4.3-1.6s-2.3-2.1-2.9-3.5c-.4-.9-.6-1.9-.6-3c.1-1,.4-2,.9-2.9.1.2.3.5.4.7c1.3,2.4,4,7.3,10.1,4.4.8-.4,1.6-.9,2.2-1.6s1.1-1.5,1.4-2.4c.3-1.4.3-2.9,0-4.3v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M346.1,315.6c.3,3,8-7.6,13.6.9c4.6,7-5.6,12.6-5.6,12.6s13.3-1.5,12.8-12.6c-.6-15.8-22.1-12.9-20.8-.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M349.1,330.7l5.1-.6c.1,0,14.2-1.8,13.7-13.7-.2-4.9-2.4-8.7-6.2-10.6-2-1-4.2-1.4-6.4-1.2s-4.3.9-6.2,2.2c-1.4,1-2.6,2.3-3.3,3.9s-1,3.3-.8,5c0,.3.1.5.2.8.1.2.3.4.6.5.9.4,1.8-.1,3.2-.9c3.5-2,6.8-3.5,9.8,1c.5.6.8,1.3.9,2.1.2.8.1,1.5,0,2.3-1,3.9-6.1,6.8-6.2,6.8l-4.4,2.4Zm7.3-24.1c1.6,0,3.1.3,4.5,1c2.2,1.1,4.9,3.5,5.1,8.9.2,4.2-1.7,7.4-5.7,9.5-.5.3-1,.5-1.4.7c1.4-1.3,2.4-3,2.9-4.8.2-1,.3-2.1.1-3.1s-.6-2-1.2-2.9c-4.3-6.4-9.8-3.2-12.5-1.6-.3.2-.7.4-1,.6-.1-1.3.2-2.5.8-3.7.6-1.1,1.4-2.1,2.5-2.8c1.6-1.2,3.7-1.8,5.9-1.8Zm-9.3,8.8l-1,.1l1-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M315.1,325.4c2.7-1.2-2.6,10.7,7.6,11.4c8.4.5,8.1-11.2,8.1-11.2s5.3,12.3-4.5,17.5c-13.9,7.3-22.2-12.8-11.2-17.7Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M320.4,345.6c2.2,0,4.4-.6,6.4-1.7c10.5-5.6,5-18.6,4.9-18.8l-2-4.7.1,5.2c0,.1.1,6-2.8,8.7-.6.5-1.2.9-1.9,1.2-.7.2-1.5.3-2.3.2-5.3-.3-5.8-4-5.8-8c0-1.6,0-2.7-.8-3.2-.2-.1-.5-.2-.8-.2s-.5.1-.8.2c-1.6.7-3,1.8-4,3.2s-1.6,3.1-1.7,4.8c-.2,2.2.3,4.4,1.2,6.4c1,2,2.4,3.7,4.3,5c1.8,1.1,3.9,1.7,6,1.7Zm10.7-14.8c.1.5.1,1,.1,1.6.1,4.5-1.7,7.7-5.4,9.7-4.7,2.5-8.2,1.4-10.2.1-1.5-1.1-2.7-2.5-3.5-4.2s-1.2-3.5-1-5.4c.1-1.3.5-2.5,1.1-3.6.7-1.1,1.6-1.9,2.8-2.5c0,.4,0,.8,0,1.2c0,3.1,0,9.6,7.7,10c1,.1,2.1,0,3.1-.4c1-.3,1.9-.9,2.7-1.6c1.2-1.3,2.1-3,2.6-4.9Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M341.6,335.1c.2,2,5.3-5.1,9.1.6c3.1,4.7-3.8,8.4-3.8,8.4s8.9-1,8.6-8.5c-.4-10.4-14.8-8.5-13.9-.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M342,345.8l5.1-.6c3.4-.4,9.7-2.9,9.5-9.5-.1-3.4-1.7-6.1-4.3-7.4-1.4-.7-2.9-1-4.5-.8-1.5.1-3,.6-4.3,1.5-1,.7-1.8,1.6-2.3,2.7s-.7,2.3-.6,3.5c0,.2.1.5.2.7s.3.4.5.5c.8.4,1.5-.1,2.5-.6c2.3-1.4,4.3-2.2,6.1.5.3.4.5.8.6,1.3s.1.9,0,1.4c-.6,2.5-3.9,4.3-3.9,4.3l-4.6,2.5Zm6.5-16.3c1,0,2,.2,2.9.6c1.4.7,3.1,2.2,3.2,5.6.1,1.2-.2,2.3-.7,3.3s-1.4,1.9-2.4,2.4c.5-.6.8-1.4,1-2.1s.2-1.5.1-2.2-.4-1.4-.9-2c-3-4.6-6.9-2.3-8.8-1.2l-.2.1c.1-.7.3-1.4.6-2c.4-.6.8-1.1,1.4-1.5c1-.6,2.4-1,3.8-1v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M349.1,335.3c-1.6-1.2,0,7.4-6.8,6.2-5.5-1-3.5-8.6-3.5-8.6s-5.4,7.1.2,12c7.9,7,16.4-4.7,10.1-9.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M344.1,348.1c.9,0,1.7-.2,2.5-.4c1.4-.5,2.7-1.5,3.7-2.6c1-1.2,1.6-2.6,1.9-4.1.2-1.2.1-2.4-.4-3.6-.4-1.1-1.2-2.1-2.2-2.8-.2-.2-.4-.3-.6-.3s-.5,0-.7,0c-.8.3-1,1.1-1.3,2.2-.6,2.6-1.4,4.6-4.6,4-.5-.1-.9-.2-1.3-.5-.4-.2-.7-.6-1-1-1.1-1.8-.7-4.8-.4-5.9l1.4-5-3.1,4.1c-2,2.7-4.6,9,.3,13.4c1.6,1.6,3.6,2.5,5.8,2.5Zm5-11.4c.5.5.8,1.1,1,1.8s.2,1.4.1,2.1c-.2,1.2-.7,2.2-1.4,3.2-.7.9-1.7,1.6-2.8,2-1.5.5-3.7.7-6.3-1.6-.9-.7-1.5-1.7-1.9-2.8s-.4-2.3-.1-3.4c.2.8.4,1.5.8,2.1s.9,1.2,1.6,1.6c.6.4,1.3.7,2.1.8c5.4,1,6.4-3.4,6.9-5.6c0-.1,0-.1,0-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M380.5,305.6c1.6,1.2.1-7.4,6.8-6.1c5.5,1.1,3.4,8.6,3.4,8.6s5.5-7,0-12c-7.8-7.1-16.5,4.5-10.2,9.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M388.4,312.8l3.2-4.1c2.1-2.7,4.8-9-.1-13.4-2.5-2.3-5.5-3-8.3-2.1-1.4.5-2.7,1.4-3.7,2.6s-1.7,2.6-1.9,4.1c-.2,1.2-.1,2.4.3,3.6.4,1.1,1.2,2.1,2.1,2.9.2.2.4.3.6.3s.5,0,.7,0c.8-.3,1-1.1,1.3-2.2.7-2.6,1.4-4.6,4.7-4c.5.1.9.2,1.3.5s.7.6,1,1c1.4,2.2.3,5.8.3,5.9l-1.5,4.9ZM386,298.4c-4.1,0-5,3.7-5.5,5.6c0,.1,0,.1,0,.2-.5-.5-.8-1.1-1-1.8s-.2-1.4-.1-2.1c.2-1.2.7-2.2,1.5-3.1s1.7-1.6,2.8-2c1.5-.5,3.7-.6,6.3,1.7.9.8,1.5,1.8,1.9,2.9.3,1.1.4,2.3.1,3.4-.1-.8-.4-1.5-.8-2.2-.4-.6-.9-1.2-1.5-1.6s-1.3-.7-2.1-.8c-.6-.2-1.1-.2-1.6-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M357.5,315.6c.7-3.8-10.3,5.2-13-8.5-2.2-11.2,9.6-12.6,9.6-12.6s-13.5-5.3-16.7,8.7c-4.5,19.9,17.5,27.9,20.1,12.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M349.2,324.8c1,0,2-.2,3-.5c3.3-1.2,5.6-4.2,6.3-8.5.1-.3.1-.6,0-.9s-.2-.6-.4-.8c-.7-.7-1.9-.6-3.3-.3-3.2.5-7.7,1.2-9.3-6.8-.6-2.9-.2-5.3,1.2-7.3c2.5-3.5,7.5-4.2,7.6-4.2l4-.5-3.7-1.5c-.3-.1-6.5-2.5-11.8.3-3.1,1.7-5.2,4.7-6.2,9.2-1.7,7.4.1,14.3,4.7,18.5c2.1,2.1,4.9,3.2,7.9,3.3Zm7.3-9.2c-.6,3.5-2.4,5.9-4.9,6.8-2.8,1-6.2.1-9-2.4-4.1-3.7-5.7-9.9-4.1-16.5.9-3.8,2.6-6.4,5.2-7.8c2-1,4.2-1.3,6.4-1-2,.8-3.7,2.1-5,3.8-1.7,2.4-2.2,5.4-1.5,8.9c2,10,8.5,8.9,11.6,8.4.4-.1,1-.2,1.3-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M385.8,300.3c-2,.3,5.3,5.2-.3,9.1-4.6,3.2-8.5-3.5-8.5-3.5s1.3,8.8,8.7,8.3c10.4-.6,8.1-15,.1-13.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M385.1,315.3h.7c3.4-.2,6-1.9,7.2-4.6.6-1.4.9-3,.7-4.5s-.7-3-1.6-4.2c-.7-1-1.7-1.8-2.8-2.3s-2.3-.6-3.6-.5c-.2,0-.5.1-.7.2s-.3.3-.5.5c-.4.8.1,1.5.7,2.5c1.4,2.3,2.3,4.2-.4,6.1-.4.3-.8.5-1.2.6-.5.1-.9.1-1.4,0-2.5-.5-4.4-3.8-4.5-3.8l-2.6-4.4.7,5.1c.6,3.4,3.2,9.3,9.3,9.3Zm1.8-14c.7,0,1.4.2,2,.6.6.3,1.1.8,1.5,1.4.7,1,1.1,2.1,1.2,3.2.1,1.2-.1,2.4-.5,3.4-.7,1.4-2.1,3.1-5.5,3.4-1.2.1-2.3-.1-3.4-.6-1-.5-1.9-1.3-2.5-2.3.6.4,1.4.7,2.1.9s1.5.2,2.2,0s1.4-.5,2-.9c4.5-3.2,2-7,.9-8.8.1-.2,0-.2,0-.3v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M301,328.9c2.4,1.8-.1-10.3,10.1-8.3c8.3,1.6,5.3,12.1,5.3,12.1s8.1-9.7-.4-16.8c-11.9-10.1-24.6,5.8-15,13Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M314.1,337l3.1-3.7c.1-.1,8.6-10.6-.5-18.3-5.4-4.6-10.3-3.7-12.7-2.8-1.9.7-3.6,1.9-4.8,3.5-1.3,1.6-2.1,3.4-2.5,5.4-.2,1.6,0,3.3.6,4.8.7,1.5,1.7,2.8,3.1,3.7.2.2.5.3.7.3.3.1.5,0,.8,0c.9-.3,1.2-1.4,1.5-2.8.9-3.7,2.1-6.7,7.4-5.7.8.1,1.6.4,2.3.8s1.3,1,1.7,1.7c2,3.2.6,8.2.6,8.3l-1.3,4.8Zm-12.5-8.9l-.6.8.6-.8Zm6.4-14.5c2.7.1,5.4,1.2,7.4,3c4.4,3.8,3.8,8.3,2.5,11.5c0-1.8-.5-3.5-1.4-5-.6-.9-1.4-1.7-2.3-2.3s-2-1-3-1.2c-7.6-1.5-9.1,4.3-9.8,7.2-.1.3-.2.7-.3,1-.9-.7-1.6-1.7-2.1-2.8-.4-1.1-.6-2.3-.4-3.4.3-1.6,1-3.2,2.1-4.4c1.1-1.3,2.4-2.3,4-2.8c1.1-.6,2.2-.8,3.3-.8Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M324.6,308.7c1.8.8,13.8,5.8,2.6,15.5-.5.4-1.1.8-1.7.9-2.2.5-1.8,3.8.4,3.9c2.1.1,4.7-1.3,7.9-5.2c12.6-15.5-14.5-17.5-9.2-15.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M326.1,330.1c2.7,0,5.5-1.9,8.5-5.5c4.7-5.8,3.9-9.8,2.5-12-3-4.7-11-6-13-5.6-.3,0-.6.2-.8.4s-.3.5-.4.8c-.1,1,.9,1.4,1.3,1.6l.2.1c4,1.8,6.2,3.9,6.5,6.2s-1.1,4.7-4.3,7.5c-.4.3-.9.6-1.4.7-.7.2-1.3.6-1.7,1.2s-.6,1.3-.5,2s.4,1.4.9,1.9s1.2.8,1.9.8l.3-.1Zm1.6-20.9c2.5.5,5.8,1.8,7.5,4.2c1.8,2.6,1,5.9-2.2,9.9-2.6,3.2-5,4.9-7,4.8-.2,0-.5-.1-.6-.3-.2-.2-.3-.4-.3-.6s0-.5.1-.6c.1-.2.3-.3.5-.4.8-.2,1.5-.6,2.1-1.2c3.8-3.2,5.4-6.4,5-9.3-.2-2.5-2-4.7-5.1-6.5Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M331.8,316.7c1.8.8,25.3,12,2.6,15.5-2.9.4,1.3-8.8,1.3-8.8s-10.5,19,2.8,16.1c12.2-2.7-12-25.3-6.7-22.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M336.1,340.7c.9,0,1.8-.1,2.7-.3c2.1-.4,3.4-1.5,4-3c.6-1.7.2-3.9-.9-6.2c2-1,3.1-2.2,3.4-3.7.2-1.3-.2-5.9-13-11.8-.3-.2-1.4-.6-2,.1-.8.9,0,1.9,2.7,5.5.5.6,1,1.3,1.5,2-1.4,2.6-5.7,11.5-2.8,15.5c1,1.3,2.4,1.9,4.4,1.9Zm3.9-8.8c.9,1.9,1.3,3.6.8,4.7-.3.9-1.1,1.4-2.5,1.7-2.5.5-4.1.3-4.9-.7s-.7-2.8-.3-4.9c.2.2.4.3.7.3.3.1.5.1.8,0c1.8-.1,3.6-.5,5.4-1.1Zm-1-1.8c-1.5.4-3.1.8-4.6,1-.1-.8.5-3.2,1.6-5.8c1.1,1.6,2.1,3.2,3,4.8Zm-5.6-11.5c7.5,3.8,10,7.2,9.8,8.7-.1.5-.5,1.4-2.2,2.2-1.9-3.2-4-6.3-6.3-9.3-.5-.6-.9-1.1-1.3-1.6v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M320.2,315.6c1.4-1.5-7.3-.6-5.6-7.2c1.4-5.4,8.8-2.8,8.8-2.8s-6.7-6-12-.8c-7.6,7.3,3.4,16.8,8.8,10.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M316,318.5c.9,0,1.8-.2,2.7-.6.8-.4,1.6-.9,2.2-1.6.2-.2.3-.4.4-.6s.1-.5,0-.7c-.2-.8-1.1-1.1-2.1-1.4-2.6-.8-4.5-1.7-3.6-4.9.1-.5.3-.9.6-1.3s.6-.7,1-.9c2.2-1.2,5.8.1,5.9.1l4.8,1.7-3.8-3.4c-2.5-2.3-8.6-5.4-13.4-.8-2.5,2.4-3.4,5.3-2.6,8.1.4,1.5,1.2,2.8,2.3,3.9s2.5,1.8,4,2.2c.5.2,1,.2,1.6.2Zm2.7-2.9c-.6.4-1.2.7-1.9.8s-1.4.1-2.1-.1c-1.1-.3-2.2-.9-3-1.7s-1.5-1.8-1.8-3c-.4-1.5-.4-3.8,2.1-6.1.8-.8,1.8-1.4,3-1.7c1.1-.3,2.3-.2,3.4.2-.8.1-1.5.3-2.2.7s-1.2.8-1.7,1.4-.8,1.3-.9,2c-1.4,5.3,2.9,6.7,5,7.4l.1.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M315.3,310c.1,2,5.5-4.9,9.1.9c2.9,4.8-4.1,8.3-4.1,8.3s8.9-.7,8.9-8.1c-.1-10.6-14.5-9.1-13.9-1.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M315.2,320.6l5.2-.4c3.4-.3,9.8-2.5,9.8-9.2c0-3.4-1.5-6.1-4.1-7.5-1.4-.7-2.9-1.1-4.4-1s-3,.5-4.3,1.3c-1,.6-1.9,1.6-2.4,2.6-.5,1.1-.8,2.3-.7,3.5c0,.2.1.5.2.7s.3.4.5.5c.7.4,1.5,0,2.5-.5c2.4-1.3,4.3-2,6.1.8.3.4.4.8.5,1.3s.1.9-.1,1.4c-.6,2-3.2,3.7-4.1,4.2l-4.7,2.3Zm6.9-16c1.1,0,2.1.3,3.1.8c1.4.7,3,2.3,3,5.7c0,1.2-.3,2.3-.9,3.3s-1.4,1.8-2.5,2.4c.5-.6.8-1.3,1.1-2.1.2-.7.2-1.5.1-2.2s-.4-1.4-.8-2.1c-2.9-4.7-6.8-2.5-8.7-1.5l-.2.1c.1-.7.3-1.4.7-1.9s.9-1.1,1.5-1.4c1-.8,2.3-1.1,3.6-1.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M360.2,323.9c-.6-1.9-4.9-14.2,9.8-12.2.7.1,1.3.3,1.9.7c1.8,1.3,4.1-1.2,2.6-2.9-1.4-1.7-4.1-2.7-9.1-2.4-20,.8-3.5,22.4-5.2,16.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M360.2,325.8c.2,0,.5-.1.7-.2.8-.6.5-1.6.3-2l-.1-.2c-1.3-4.2-1.2-7.2.3-9s4.3-2.3,8.5-1.8c.5.1,1,.2,1.4.5.6.4,1.3.6,2,.5s1.4-.4,1.8-1c.5-.5.8-1.2.8-1.9s-.2-1.4-.7-2c-1.7-2.1-5.1-3-9.9-2.8-7.4.4-9.9,3.5-10.6,6.1-1.6,5.4,2.8,12.2,4.5,13.4.3.3.6.4,1,.4Zm6.9-15.4c-3.3,0-5.8.9-7.3,2.7s-2,4.6-1.4,8.2c-1.3-2.3-2.5-5.6-1.9-8.4.7-3,3.7-4.7,8.9-4.9c4.2-.2,6.9.5,8.2,2.1.1.2.2.4.2.6s-.1.5-.3.6c-.1.2-.3.3-.6.3-.2,0-.5,0-.6-.2-.7-.5-1.5-.7-2.3-.8-.9-.1-1.9-.2-2.9-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M312.9,335.3c-.6-1.9-7.8-26.9,9.8-12.2c2.3,1.9-7.4,4.9-7.4,4.9s21.1-4.7,10.1-12.8c-10.1-7.4-10.8,25.7-12.5,20.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M313.3,336.9v0c1.3-.1,1.5-1.3,2.3-5.7.1-.7.3-1.6.5-2.5c2.8-.7,12.4-3.4,13.4-8.2.4-2.1-.7-4.2-3.5-6.2-1.7-1.3-3.4-1.6-4.9-1-1.7.7-3,2.5-4.1,4.7-2.1-.9-3.7-.9-5-.1-1.1.7-4.2,4.1-.2,17.5.2.5.5,1.5,1.5,1.5Zm3-17c-1.2,3.6-2,7.2-2.7,10.9-.1.7-.3,1.4-.4,2-2.1-8.1-1.3-12.3,0-13.1.5-.3,1.3-.5,3.1.2Zm5.6,3.8c-.5.6-2.7,1.7-5.3,2.7.4-1.8.9-3.7,1.5-5.5c1.3.8,2.6,1.8,3.8,2.8Zm.7-8.6c.8.1,1.6.4,2.2.9c2,1.5,3,2.9,2.7,4.2s-1.6,2.4-3.4,3.5c0-.3-.1-.5-.2-.8-.1-.2-.3-.4-.5-.6-1.4-1.2-2.9-2.3-4.5-3.2.8-1.9,1.8-3.3,3-3.8.2-.2.4-.2.7-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M390.6,302.5c-4.4-.3,9.2,13.7-4.8,19.9-11.5,5.1-17.1-11.5-17.1-11.5s-1.1,20,15.3,22.1c23.3,3.1,24.6-29.4,6.6-30.5Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M387,334.2c5.7,0,10.5-2.2,13.5-6.4c1.9-2.6,3-5.7,3.3-8.9s-.2-6.4-1.5-9.4c-1-2.3-2.6-4.3-4.7-5.7s-4.5-2.2-7-2.3c-.3,0-.6,0-.9.1s-.5.3-.7.6c-.7,1-.2,2.4.6,4.5c2.2,5.9,3.5,11.4-4.2,14.8-1.1.5-2.3.8-3.5.9-1.2,0-2.4-.2-3.5-.7-5.7-2.5-8.7-11-8.7-11.1l-1.7-4.9-.3,5.2c0,.2-1,20.9,16.2,23.2c1,.1,2,.1,3.1.1Zm3.7-30.7c2.1.1,4.1.8,5.8,2s3.1,2.9,3.9,4.8c1.2,2.6,1.6,5.5,1.3,8.3s-1.3,5.6-2.9,7.9c-3.2,4.3-8.4,6.3-14.8,5.4-6.4-.8-10.8-4.6-13-11.2-.5-1.6-.9-3.2-1.1-4.8c1.6,2.8,4.1,6,7.5,7.4c1.4.6,2.9.9,4.4.9s3-.4,4.3-1c10.1-4.5,7-12.9,5.3-17.4-.2-.7-.5-1.5-.7-2.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M357.5,300.3c.5,4.4,11.9-11.4,20.4,1.4c6.9,10.5-8.4,18.9-8.4,18.9s19.8-2.3,19.2-18.9c-1-23.6-33.2-19.3-31.2-1.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M364.4,322.1l5.1-.6c.2,0,20.8-2.6,20.1-20-.3-7.2-3.5-12.7-9-15.4-2.9-1.4-6.1-2-9.3-1.7-3.2.2-6.3,1.3-9,3.1-2.1,1.4-3.8,3.3-4.8,5.6s-1.4,4.8-1.1,7.3c0,.3.1.6.3.9s.4.5.7.6c1.1.5,2.4-.2,4.3-1.4c5.4-3.2,10.6-5.4,15.3,1.6.7,1,1.2,2.1,1.5,3.3.2,1.2.2,2.4-.1,3.6-1.5,6.1-9.4,10.5-9.5,10.5l-4.5,2.6Zm8.4-35.7c2.4,0,4.8.5,6.9,1.6c4.8,2.4,7.6,7.3,7.9,13.7s-2.7,11.4-8.8,14.7c-1.4.8-3,1.4-4.5,1.9c2.4-2.1,5.2-5.1,6.1-8.7.4-1.5.4-3,.1-4.5s-.9-2.9-1.8-4.1c-6.1-9.2-13.8-4.7-18-2.2-.7.4-1.5.8-2.2,1.2-.2-2.1.1-4.2,1-6.1s2.3-3.5,4.1-4.7c2.7-1.8,5.9-2.8,9.2-2.8Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M357.5,289c.5,4.4,11.9-11.4,20.4,1.4c6.9,10.5-8.4,18.9-8.4,18.9s19.8-2.3,19.2-18.9c-1-23.6-33.2-19.4-31.2-1.4Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M364.4,310.8l5.1-.6c.2,0,20.8-2.6,20.1-19.9-.3-7.2-3.5-12.7-9-15.4-2.9-1.4-6.1-2-9.3-1.7s-6.3,1.3-9,3.2c-2.1,1.4-3.8,3.3-4.8,5.6s-1.4,4.8-1.1,7.3c0,.3.1.6.3.9s.4.5.7.6c1.1.5,2.4-.2,4.3-1.4c5.4-3.2,10.6-5.4,15.3,1.6.7,1,1.2,2.1,1.5,3.3.2,1.2.2,2.4-.1,3.6-1.5,6.1-9.4,10.5-9.5,10.5l-4.5,2.4Zm8.4-35.7c2.4,0,4.8.5,6.9,1.6c4.8,2.4,7.6,7.3,7.9,13.7s-2.7,11.4-8.8,14.7c-1.4.8-3,1.4-4.5,1.9c2.4-2.1,5.2-5.1,6.1-8.7.4-1.5.4-3,.1-4.5s-.9-2.9-1.8-4.1c-6.1-9.2-13.8-4.7-18-2.2-.7.4-1.5.8-2.2,1.2-.2-2.1.1-4.2,1-6.1s2.3-3.5,4.1-4.7c2.7-1.9,5.9-2.9,9.2-2.8v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M367.5,290.5c.1,1.3,3.5-3.4,6.1.4c2,3.1-2.5,5.6-2.5,5.6s5.9-.7,5.7-5.6c-.3-7-9.9-5.8-9.3-.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M366.1,298.1l5.1-.6c2.4-.3,6.8-2,6.6-6.7-.1-2.4-1.2-4.3-3.1-5.2-1-.5-2.1-.7-3.1-.6-1.1.1-2.1.4-3,1.1-.7.5-1.3,1.2-1.7,2s-.5,1.7-.4,2.5c0,.2.1.4.2.6s.3.3.5.4c.7.3,1.3,0,2-.4c1.7-1,2.6-1.2,3.6.3.2.2.3.5.3.7.1.3,0,.5,0,.8-.3,1.2-1.9,2.3-2.5,2.7l-4.5,2.4Zm5.9-11c.6,0,1.2.1,1.7.4c1.2.6,1.9,1.8,1.9,3.5.1.9-.2,1.8-.7,2.5.1-.5.1-1.1,0-1.6s-.3-1-.6-1.5c-1.9-2.9-4.4-2-5.8-1.2.2-.6.6-1,1.1-1.4.8-.5,1.6-.7,2.4-.7v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M375.3,279.3c.1,1.3,3.5-3.4,6.1.4c2.1,3.1-2.5,5.6-2.5,5.6s5.9-.7,5.7-5.6c-.3-7-9.8-5.7-9.3-.4Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M373.8,287l5.1-.6c2.4-.3,6.8-2,6.6-6.7-.1-2.4-1.2-4.3-3.1-5.2-1-.5-2.1-.7-3.1-.6-1.1.1-2.1.4-3,1.1-.7.5-1.3,1.2-1.6,2-.4.8-.5,1.7-.4,2.5c0,.2.1.4.2.6s.3.3.5.4c.7.3,1.3,0,2-.4c1.7-1,2.6-1.2,3.6.3.2.2.3.5.3.7.1.3,0,.5,0,.8-.3,1.2-1.9,2.4-2.5,2.7l-4.6,2.4Zm6.1-11.1c.6,0,1.2.1,1.7.4c1.2.6,1.9,1.8,1.9,3.5.1.9-.2,1.8-.7,2.5.1-.5.1-1.1,0-1.6s-.3-1-.6-1.5c-1.9-2.9-4.4-2-5.8-1.2.2-.6.6-1,1.1-1.4.7-.4,1.5-.7,2.4-.7v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M334.4,328.4c1.5-2,2.5,9.2,10.4,5.4c6.5-3.1,1.5-11.8,1.5-11.8s9.1,7,3.8,15c-7.5,11.3-22-.4-15.7-8.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M342.3,342.7c3.4,0,6.4-1.8,8.6-5.1c4.6-6.9-.7-13.7-4.1-16.4l-4.1-3.1l2.6,4.5c0,0,2.5,4.4,1.5,7.6-.2.6-.5,1.2-1,1.6-.4.5-1,.8-1.6,1.1-4.1,1.9-5.8-.5-7.4-3.6-.7-1.2-1.1-2.1-2.1-2.2-.3,0-.5,0-.7.1s-.4.3-.6.5c-.9,1.2-1.5,2.6-1.7,4.1s.1,3,.7,4.4c.8,1.8,2.1,3.3,3.6,4.4s3.4,1.8,5.3,2c.5.1.8.1,1,.1Zm6.7-16.2c1.7,2.5,2.8,6.1.3,10-2.5,3.8-5.5,4.4-7.5,4.2-1.6-.2-3-.7-4.3-1.6s-2.3-2.1-2.9-3.5c-.4-.9-.7-1.9-.6-3c.1-1,.4-2,.9-2.9.1.2.3.5.4.7c1.3,2.4,4,7.3,10.1,4.4.8-.4,1.6-.9,2.2-1.6s1.1-1.5,1.4-2.4c.2-1.4.3-2.9,0-4.3v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M358.5,307.5c.3,3,8-7.6,13.6.9c4.6,7-5.6,12.6-5.6,12.6s13.3-1.5,12.8-12.6c-.5-15.8-22.1-12.9-20.8-.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M361.5,322.6l5.1-.6c.1,0,14.2-1.8,13.7-13.7-.2-4.9-2.4-8.7-6.2-10.6-2-.9-4.2-1.4-6.4-1.2s-4.3.9-6.2,2.2c-1.4,1-2.6,2.3-3.3,3.9s-1,3.3-.8,5l1-.1-1,.1c0,.3.1.5.2.8.1.2.3.4.6.5.9.4,1.8-.1,3.2-.9c3.5-2,6.8-3.5,9.8,1c.5.6.8,1.3.9,2.1.2.8.1,1.5,0,2.3-1,3.9-6.1,6.8-6.2,6.8l-4.4,2.4Zm7.3-24.1c1.6,0,3.1.3,4.5,1c2.2,1.1,4.9,3.5,5.1,8.9.2,4.2-1.8,7.4-5.7,9.6-.4.2-.9.5-1.4.7c1.4-1.3,2.4-3,2.9-4.8.2-1,.3-2.1.1-3.1s-.6-2-1.2-2.9c-4.3-6.4-9.8-3.2-12.5-1.6-.3.2-.7.4-1,.6-.1-1.3.2-2.5.8-3.7.6-1.1,1.4-2.1,2.5-2.8c1.7-1.3,3.8-1.9,5.9-1.9Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M324.4,333.6c-1.4-2.6,10.9,1.8,10.8-8.4-.1-8.4-11.7-7.3-11.7-7.3s11.8-6.2,17.7,3.2c8.3,13.4-11.1,23.1-16.8,12.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M332,339.1c2.2,0,4.3-.6,6.2-1.7s3.5-2.7,4.6-4.6c2.1-3.7,1.8-8.1-.8-12.3-6.3-10.1-18.9-3.7-19-3.6l-4.6,2.4l5.1-.5c.1,0,5.9-.5,8.9,2.2.6.5,1,1.2,1.3,1.9s.4,1.5.4,2.2c.1,5.4-3.5,6-7.5,6.3-1.6.1-2.7.2-3.2,1.1-.1.2-.2.5-.2.8s.1.5.2.8c.8,1.5,2,2.8,3.5,3.7s3.2,1.4,4.9,1.3h.2Zm-.3-22.2c1.7-.1,3.5.3,5,1.2c1.5.8,2.8,2.1,3.7,3.6c2.8,4.5,2,8,.8,10.2-.9,1.6-2.3,2.9-3.8,3.8-1.6.9-3.4,1.4-5.2,1.5h-.1c-1.3,0-2.5-.3-3.6-.9s-2-1.5-2.7-2.6c.4,0,.8-.1,1.2-.1c3.1-.2,9.5-.7,9.4-8.4c0-1.1-.2-2.1-.6-3.1s-1-1.8-1.8-2.5c-1.5-1.2-3.2-2-5.1-2.4.5-.1,1-.2,1.6-.2.2-.1.7-.1,1.2-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M354,327.1c.2,2,5.3-5.1,9.1.6c3.1,4.7-3.8,8.4-3.8,8.4s8.9-1,8.6-8.5c-.4-10.5-14.7-8.6-13.9-.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M354.4,337.7l5.1-.6c3.4-.4,9.7-2.9,9.5-9.5-.1-3.4-1.7-6.1-4.3-7.4-1.4-.7-2.9-.9-4.5-.8-1.5.1-3,.6-4.3,1.5-1,.7-1.8,1.6-2.3,2.7s-.7,2.3-.6,3.5c0,.2.1.5.2.7s.3.4.5.5c.8.4,1.5-.1,2.5-.6c2.3-1.4,4.3-2.2,6.1.5.3.4.5.8.6,1.3s.1.9,0,1.4c-.6,2.5-3.9,4.3-3.9,4.3l-4.6,2.5Zm6.5-16.3c1,0,2,.2,2.9.7c1.4.7,3.1,2.2,3.2,5.6.1,1.2-.2,2.3-.7,3.3s-1.4,1.9-2.4,2.4c.4-.6.8-1.3,1-2.1.2-.7.2-1.5.1-2.2s-.4-1.4-.9-2c-3-4.6-6.9-2.3-8.8-1.2l-.2.1c0-.7.3-1.4.6-2c.4-.6.8-1.1,1.4-1.5c1.1-.7,2.4-1.1,3.8-1.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M386.8,296.8c.2,2,5.3-5.1,9.1.6c3.1,4.7-3.8,8.4-3.8,8.4s8.9-1,8.6-8.5c-.4-10.4-14.7-8.5-13.9-.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M387.2,307.4l5.1-.6c3.4-.4,9.7-2.9,9.5-9.5-.1-3.4-1.7-6.1-4.3-7.4-1.4-.7-2.9-.9-4.5-.8-1.5.1-3,.6-4.3,1.5-1,.7-1.8,1.6-2.3,2.7s-.7,2.3-.6,3.5c0,.2.1.5.2.7s.3.4.5.5c.8.4,1.5-.1,2.5-.6c2.3-1.4,4.3-2.2,6.1.5.3.4.5.8.6,1.3s.1.9,0,1.4c-.6,2.5-3.9,4.3-3.9,4.3l-4.6,2.5Zm6.5-16.2c1,0,2,.2,2.9.7c1.4.7,3.1,2.2,3.2,5.6.1,1.2-.2,2.3-.7,3.3s-1.4,1.9-2.4,2.4c.4-.6.8-1.3,1-2.1.2-.7.2-1.5.1-2.2s-.4-1.4-.9-2c-3-4.6-6.9-2.3-8.8-1.2l-.2.1c.1-.7.3-1.4.6-2c.4-.6.8-1.1,1.4-1.5c1.1-.7,2.4-1.1,3.8-1.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M369.8,319.7c.2,2,5.3-5.1,9.1.6c3.1,4.7-3.8,8.4-3.8,8.4s8.8-1,8.6-8.5c-.4-10.5-14.8-8.5-13.9-.5Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M370.2,330.3l5.1-.6c3.4-.4,9.7-2.9,9.5-9.5-.1-3.4-1.7-6-4.3-7.4-1.4-.7-2.9-.9-4.5-.8-1.5.1-3,.6-4.3,1.5-1,.7-1.8,1.6-2.3,2.7s-.7,2.3-.6,3.5c0,.2.1.5.2.7s.3.4.5.5c.8.4,1.5-.1,2.5-.6c2.3-1.4,4.3-2.2,6.1.5.3.4.5.8.6,1.3s.1.9,0,1.4c-.6,2.5-3.9,4.3-3.9,4.3l-4.6,2.5Zm6.4-16.3c1,0,2,.2,2.8.7c1.4.7,3.1,2.2,3.2,5.6.1,1.2-.2,2.3-.7,3.3s-1.4,1.9-2.4,2.4c.4-.6.8-1.4,1-2.1s.2-1.5.1-2.2-.4-1.4-.9-2c-3-4.6-6.9-2.3-8.8-1.2l-.2.1c.1-.7.3-1.4.6-2c.4-.6.8-1.1,1.4-1.5c1.2-.7,2.6-1.1,3.9-1.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M361.5,327.2c-1.6-1.2,0,7.4-6.8,6.2-5.5-1-3.5-8.6-3.5-8.6s-5.4,7.1.2,12c7.9,7,16.5-4.7,10.1-9.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M356.5,340c.9,0,1.7-.2,2.5-.5c1.4-.5,2.7-1.5,3.7-2.6c1-1.2,1.6-2.6,1.9-4.1.2-1.2.1-2.4-.4-3.6-.4-1.1-1.2-2.1-2.2-2.8-.2-.2-.4-.3-.6-.3s-.5,0-.7,0c-.8.3-1,1.1-1.3,2.2-.6,2.6-1.4,4.6-4.6,4-.5-.1-.9-.2-1.3-.5-.4-.2-.7-.6-1-1-1.4-2.1-.4-5.8-.4-5.9l1.3-5-3.1,4.1c-2,2.7-4.6,9,.3,13.4c1.7,1.7,3.8,2.6,5.9,2.6Zm5-11.4c.5.5.8,1.1,1,1.8s.2,1.4.1,2.1c-.2,1.2-.7,2.2-1.4,3.2-.7.9-1.7,1.6-2.8,2-1.5.5-3.7.7-6.3-1.6-.9-.8-1.5-1.7-1.9-2.8s-.4-2.3-.1-3.4c.2.8.4,1.5.9,2.2.4.6.9,1.2,1.6,1.6.6.4,1.3.7,2.1.8c5.4,1,6.4-3.4,6.9-5.6l-.1-.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M363.2,337.6c-1.6-1.2,0,7.4-6.8,6.2-5.5-1-3.5-8.6-3.5-8.6s-5.4,7.1.2,12c7.9,7.1,16.4-4.6,10.1-9.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M358.2,350.5c.9,0,1.7-.2,2.5-.4c1.4-.5,2.7-1.5,3.7-2.6c1-1.2,1.6-2.6,1.9-4.1.2-1.2.1-2.4-.4-3.6-.4-1.1-1.2-2.1-2.2-2.8-.2-.2-.4-.3-.6-.3s-.5,0-.7,0c-.8.3-1,1.1-1.3,2.2-.6,2.6-1.4,4.6-4.6,4-.5-.1-.9-.2-1.3-.5-.4-.2-.7-.6-1-1-1.1-1.8-.7-4.8-.4-5.9l1.4-5-3.1,4.1c-2,2.7-4.6,9,.3,13.4c1.6,1.6,3.7,2.4,5.8,2.5Zm5-11.4c.5.5.8,1.1,1,1.8s.2,1.4.1,2.1c-.2,1.2-.7,2.2-1.4,3.2-.7.9-1.7,1.6-2.8,2-1.5.5-3.7.7-6.3-1.6-.9-.7-1.5-1.7-1.9-2.8s-.4-2.3-.1-3.4c.2.8.4,1.5.8,2.1s.9,1.2,1.6,1.6c.6.4,1.3.7,2.1.8c5.4,1,6.4-3.4,6.9-5.6c0-.1,0-.2,0-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M392.9,297.5c1.6,1.2.1-7.4,6.8-6.1c5.5,1.1,3.4,8.6,3.4,8.6s5.5-7,0-12c-7.8-7.1-16.5,4.5-10.2,9.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M400.8,304.7l3.2-4.1c2.1-2.7,4.8-9-.1-13.4-2.5-2.3-5.5-3.1-8.3-2.1-1.4.5-2.7,1.4-3.7,2.6s-1.7,2.6-1.9,4.1c-.2,1.2-.1,2.4.3,3.6.4,1.1,1.2,2.1,2.1,2.9.2.2.4.3.6.3s.5,0,.7,0c.8-.3,1-1.1,1.3-2.2.7-2.6,1.4-4.6,4.7-4c.5.1.9.2,1.3.5s.7.6,1,1c1.4,2.2.3,5.8.3,5.9l-1.5,4.9Zm-2.4-14.5c-4.1,0-5,3.7-5.5,5.6c0,.1,0,.1,0,.2-.5-.5-.8-1.1-1-1.8s-.2-1.4-.1-2.1c.2-1.1.7-2.2,1.5-3.1s1.7-1.6,2.8-2c1.5-.5,3.7-.6,6.3,1.7.9.8,1.5,1.8,1.9,2.9.3,1.1.4,2.3.1,3.4-.1-.8-.4-1.5-.8-2.2-.4-.6-.9-1.2-1.5-1.6s-1.3-.7-2.1-.8c-.6-.1-1.1-.1-1.6-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M370,307.5c.7-3.8-10.3,5.1-13-8.5-2.3-11.2,9.6-12.6,9.6-12.6s-13.5-5.3-16.7,8.7c-4.5,19.9,17.4,28,20.1,12.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M361.7,316.7c1,0,2-.2,3-.5c3.3-1.2,5.6-4.2,6.3-8.5.1-.3.1-.6,0-.9s-.2-.6-.4-.8c-.7-.7-1.9-.6-3.3-.3-3.2.5-7.7,1.2-9.3-6.8-.6-2.9-.2-5.4,1.2-7.3c2.5-3.5,7.5-4.1,7.6-4.1l4-.5-3.7-1.5c-.3-.1-6.5-2.5-11.8.3-3.1,1.7-5.2,4.7-6.2,9.2-1.7,7.4.1,14.3,4.7,18.5c2,1.9,4.9,3.1,7.9,3.2Zm7.2-9.2c-.6,3.5-2.4,5.9-4.9,6.8-2.8,1-6.2.1-9-2.4-4.1-3.7-5.7-9.9-4.1-16.5.9-3.8,2.6-6.4,5.2-7.8c2-1,4.2-1.3,6.4-1-2,.8-3.7,2.1-5,3.8-1.7,2.4-2.2,5.4-1.5,8.9c2,10,8.5,8.9,11.6,8.4.4-.1,1-.2,1.3-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M398.2,292.2c-2,.3,5.3,5.2-.3,9.1-4.6,3.3-8.6-3.5-8.6-3.5s1.3,8.8,8.7,8.3c10.6-.6,8.2-15,.2-13.9Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M397.5,307.2c.2,0,.5,0,.7,0c3.4-.2,6-1.9,7.2-4.6.6-1.4.9-3,.7-4.5s-.7-3-1.6-4.2c-.7-1-1.7-1.8-2.8-2.3s-2.4-.6-3.6-.5c-.2,0-.5.1-.7.2s-.3.3-.5.5c-.4.8.1,1.5.7,2.5c1.4,2.3,2.3,4.2-.4,6.1-.4.3-.8.5-1.2.6-.5.1-.9.1-1.4,0-2.5-.5-4.4-3.8-4.5-3.8l-2.6-4.4.7,5.1c.7,3.4,3.2,9.3,9.3,9.3Zm1.8-14c.7,0,1.4.2,2,.6.6.3,1.1.8,1.5,1.4.7,1,1.1,2.1,1.2,3.2.1,1.2,0,2.4-.5,3.4-.6,1.4-2.1,3.1-5.5,3.4-1.1.1-2.3-.1-3.3-.6s-1.9-1.3-2.5-2.3c.6.4,1.4.7,2.1.9s1.5.1,2.2,0c.7-.2,1.4-.5,2-.9c4.5-3.2,2-7,.9-8.8l-.1-.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M316.5,315.2c1.6,1.2.1-7.4,6.8-6.1c5.5,1.1,3.4,8.6,3.4,8.6s5.5-7,0-12c-7.8-7.1-16.5,4.5-10.2,9.5Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M324.3,322.4l3.2-4.1c2.1-2.7,4.8-9-.1-13.4-2.5-2.3-5.5-3.1-8.3-2.1-1.4.5-2.7,1.4-3.7,2.6s-1.7,2.6-1.9,4.1c-.2,1.2-.1,2.4.3,3.6.4,1.1,1.2,2.1,2.1,2.9.2.2.4.3.6.3s.5,0,.7,0c.8-.3,1-1.1,1.3-2.2.7-2.6,1.4-4.6,4.7-4c.5.1.9.2,1.3.5s.7.6,1,1c1.4,2.2.3,5.8.3,5.9l-1.5,4.9ZM322,307.9c-4.1,0-5,3.7-5.5,5.6c0,.1,0,.1,0,.2-.5-.5-.8-1.1-1-1.8s-.2-1.4-.1-2.1c.2-1.2.7-2.2,1.5-3.1s1.7-1.6,2.8-2c1.5-.5,3.7-.6,6.3,1.7.9.8,1.5,1.8,1.9,2.9.3,1.1.4,2.3.1,3.4-.1-.8-.4-1.5-.8-2.2-.4-.6-.9-1.2-1.5-1.6s-1.3-.7-2.1-.8c-.6-.1-1.1-.2-1.6-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M337,300.6c1.8.8,13.8,5.8,2.6,15.5-.5.5-1.1.8-1.7.9-2.2.5-1.8,3.8.4,3.9c2.1.1,4.7-1.3,7.9-5.2c12.6-15.5-14.5-17.5-9.2-15.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M338.5,322c2.7,0,5.5-1.9,8.5-5.5c4.7-5.8,3.9-9.8,2.5-12.1-3-4.7-11-6-13-5.6-.3,0-.6.1-.8.3s-.4.5-.4.8c-.1,1,.9,1.4,1.3,1.6l.2.1c4,1.8,6.2,3.9,6.5,6.2s-1.1,4.7-4.3,7.5c-.4.3-.8.6-1.3.7-.7.2-1.3.6-1.7,1.2s-.6,1.3-.5,2s.4,1.4.9,1.9s1.2.8,1.9.8l.2.1Zm1.6-20.9c2.5.5,5.8,1.8,7.5,4.2c1.8,2.6,1,5.9-2.2,9.9-2.6,3.2-5,4.8-7,4.8-.2,0-.5-.1-.6-.3-.2-.2-.3-.4-.3-.6s0-.5.1-.6c.1-.2.3-.3.5-.4.8-.2,1.5-.6,2.2-1.2c3.8-3.2,5.4-6.4,5-9.3-.3-2.5-2.1-4.7-5.2-6.5Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M344.2,308.6c1.8.8,25.3,12,2.6,15.5-2.9.4,1.4-8.8,1.4-8.8s-10.5,19,2.8,16.1c12.1-2.7-12.1-25.3-6.8-22.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M348.5,332.6c.9,0,1.8-.1,2.7-.3c2.1-.4,3.4-1.5,4-3c.6-1.7.2-3.9-.9-6.2c2-1,3.1-2.2,3.4-3.7.2-1.3-.2-5.9-13-11.8-.3-.1-1.4-.6-2,.2-.7.9,0,1.9,2.7,5.5.5.6,1,1.3,1.5,2-1.4,2.6-5.7,11.5-2.8,15.5c1,1.2,2.4,1.8,4.4,1.8Zm3.9-8.8c.9,1.9,1.3,3.6.8,4.7-.3.9-1.1,1.4-2.5,1.7-2.5.5-4.1.3-4.9-.7s-.7-2.8-.3-4.9c.2.2.4.3.7.3.3.1.5.1.8,0c1.8-.1,3.6-.5,5.4-1.1Zm-.9-1.8c-1.5.4-3.1.8-4.6,1-.1-.8.5-3.2,1.6-5.8c1,1.6,2,3.2,3,4.8Zm-5.7-11.5c7.5,3.8,10,7.2,9.8,8.7-.1.5-.5,1.4-2.2,2.2-1.9-3.2-4-6.3-6.3-9.2l-1.3-1.7Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M386.2,266.9c1.8.8,25.3,12,2.6,15.5-2.9.4,1.3-8.8,1.3-8.8s-10.5,19,2.8,16.1c12.2-2.7-12-25.3-6.7-22.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M390.5,290.9c.9,0,1.8-.1,2.7-.3c2.1-.5,3.4-1.5,4-3c.6-1.7.2-3.9-.9-6.2c2-1,3.1-2.2,3.4-3.7.2-1.3-.2-5.9-13-11.8-.3-.2-1.4-.6-2,.1-.7.9,0,1.9,2.7,5.5.5.6,1,1.3,1.5,2-1.4,2.6-5.7,11.5-2.8,15.5c1,1.3,2.4,1.9,4.4,1.9Zm3.9-8.8c.9,1.9,1.3,3.6.8,4.7-.3.9-1.1,1.4-2.5,1.7-2.5.5-4.1.3-4.9-.7s-.7-2.8-.3-4.9c.2.2.4.3.7.3.3.1.5.1.8,0c1.8-.1,3.6-.5,5.4-1.1Zm-.9-1.8c-1.5.4-3.1.8-4.6,1-.1-.8.5-3.2,1.6-5.8c1,1.6,2,3.2,3,4.8v0Zm-5.7-11.5c7.5,3.8,10,7.2,9.8,8.7-.1.5-.5,1.4-2.2,2.2-1.9-3.2-4-6.3-6.3-9.2-.5-.7-.9-1.2-1.3-1.7v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M332.6,307.6c1.4-1.5-7.3-.6-5.6-7.2c1.4-5.4,8.8-2.8,8.8-2.8s-6.7-6-12-.8c-7.6,7.3,3.4,16.7,8.8,10.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M328.4,310.5c.9,0,1.8-.2,2.7-.6.8-.4,1.6-.9,2.2-1.6.2-.2.3-.4.4-.6s.1-.5,0-.7c-.2-.8-1-1.1-2.1-1.4-2.6-.8-4.5-1.7-3.6-4.9.1-.5.3-.9.6-1.3s.6-.7,1-.9c2.2-1.2,5.8,0,5.9.1l4.8,1.7-3.8-3.4c-2.5-2.3-8.6-5.4-13.4-.8-2.5,2.4-3.4,5.3-2.6,8.1.4,1.5,1.2,2.8,2.4,3.9c1.1,1.1,2.5,1.8,4,2.2.4.1,1,.1,1.5.2Zm2.7-3c-.6.4-1.2.7-1.9.8s-1.4.1-2-.1c-1.1-.3-2.2-.9-3-1.7s-1.5-1.8-1.8-3c-.4-1.5-.4-3.8,2.1-6.1.8-.8,1.8-1.4,3-1.7c1.1-.3,2.3-.2,3.4.1-.8.1-1.5.3-2.2.7s-1.2.8-1.7,1.4-.8,1.3-.9,2c-1.4,5.3,2.9,6.7,5,7.4v.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M327.7,301.9c.1,2,5.5-4.9,9.1.9c2.9,4.8-4.1,8.3-4.1,8.3s8.9-.7,8.9-8.1c-.1-10.6-14.5-9.2-13.9-1.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M327.7,312.5l5.1-.4c3.4-.3,9.8-2.5,9.8-9.2c0-3.4-1.5-6.1-4.1-7.5-1.4-.7-2.9-1.1-4.4-1s-3,.5-4.3,1.4c-1,.6-1.9,1.6-2.4,2.6-.5,1.1-.8,2.3-.7,3.5c0,.2.1.5.2.7s.3.4.5.5c.7.4,1.5,0,2.5-.5c2.4-1.3,4.3-2,6.1.8.3.4.4.8.5,1.3s.1.9-.1,1.4c-.7,2.4-4.1,4.2-4.1,4.2l-4.6,2.2Zm6.8-16c1.1,0,2.1.2,3.1.7c1.4.7,3,2.3,3,5.7c0,1.2-.3,2.3-.9,3.3s-1.4,1.8-2.5,2.4c.5-.6.8-1.3,1.1-2.1.2-.7.2-1.5.1-2.2s-.4-1.4-.8-2.1c-2.9-4.7-6.8-2.5-8.7-1.5l-.2.1c.1-.7.3-1.4.7-1.9.4-.6.9-1.1,1.5-1.4c1.1-.7,2.3-1,3.6-1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M372.6,315.7c-.6-1.9-4.9-14.2,9.8-12.2.7.1,1.3.3,1.9.7c1.8,1.3,4.1-1.2,2.6-2.9-1.4-1.7-4.1-2.7-9.1-2.4-20,.9-3.5,22.5-5.2,16.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M372.6,317.7c.2,0,.5-.1.7-.2.8-.6.5-1.6.3-2l-.1-.2c-1.3-4.2-1.2-7.2.3-9s4.3-2.3,8.5-1.8c.5.1,1,.2,1.4.5.6.4,1.3.6,2,.5s1.4-.4,1.8-1c.5-.5.8-1.2.8-1.9s-.2-1.4-.7-2c-1.7-2.1-5.1-3-9.9-2.8-7.4.4-9.9,3.5-10.6,6.1-1.6,5.4,2.8,12.2,4.5,13.4.3.3.6.4,1,.4Zm6.9-15.4c-3.3,0-5.8.9-7.3,2.7s-2,4.6-1.4,8.2c-1.3-2.3-2.5-5.6-1.9-8.4.7-3,3.7-4.7,8.9-4.9c4.2-.2,6.9.5,8.2,2.1.1.2.2.4.2.6s-.1.5-.3.6c-.1.2-.3.3-.6.3s-.5,0-.6-.1c-.7-.5-1.5-.7-2.3-.8-.8-.2-1.8-.3-2.9-.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M394.1,296.6c-.6-1.9-4.9-14.2,9.8-12.2.7.1,1.3.3,1.9.7c1.8,1.3,4.1-1.2,2.6-2.9-1.4-1.7-4.1-2.7-9.1-2.4-20.1.9-3.5,22.4-5.2,16.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M394,298.6c.2,0,.5-.1.7-.2.8-.6.5-1.6.3-2l-.8.2.8-.2-.1-.2c-1.3-4.2-1.2-7.2.3-9s4.3-2.3,8.5-1.8c.5.1,1,.2,1.4.5.6.4,1.3.6,2,.5s1.4-.4,1.8-1c.5-.5.8-1.2.8-1.9s-.2-1.4-.7-2c-1.7-2.1-5.1-3-9.9-2.8-7.4.4-9.9,3.5-10.6,6.1-1.6,5.4,2.8,12.2,4.5,13.4.4.2.7.4,1,.4Zm.2-2l-.2.1.2-.1Zm6.8-13.4c-3.3,0-5.8.9-7.3,2.7s-2,4.6-1.4,8.2c-1.3-2.3-2.5-5.6-1.9-8.4.7-3,3.7-4.7,8.9-4.9c4.2-.2,6.9.5,8.2,2.1.1.2.2.4.2.6s-.1.5-.3.6c-.1.2-.3.3-.6.3s-.5,0-.6-.1c-.7-.5-1.5-.7-2.3-.8-.9-.3-1.9-.3-2.9-.3v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M325.3,327.2c-.6-1.9-7.8-26.9,9.8-12.2c2.3,1.9-7.4,4.9-7.4,4.9s21.1-4.7,10.1-12.8c-10.1-7.4-10.8,25.7-12.5,20.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M325.7,328.8v0c1.3-.1,1.5-1.3,2.3-5.7.1-.7.3-1.6.5-2.5c2.8-.7,12.4-3.4,13.4-8.2.4-2.1-.7-4.2-3.5-6.2-1.7-1.2-3.4-1.6-4.9-1-1.7.7-3,2.5-4.1,4.7-2.1-.9-3.7-.9-5-.1-1.1.7-4.2,4.1-.2,17.5.2.5.5,1.5,1.5,1.5Zm3-17c-1.2,3.6-2.1,7.2-2.7,10.9-.1.6-.3,1.3-.4,1.9-2.1-8.1-1.3-12.3,0-13.1.5-.2,1.4-.4,3.1.3Zm5.6,3.8c-.5.7-2.7,1.7-5.3,2.7.4-1.8.9-3.7,1.5-5.5c1.4.8,2.6,1.8,3.8,2.8Zm.7-8.6c.8.1,1.6.4,2.2.9c2,1.5,3,2.9,2.7,4.2s-1.6,2.4-3.4,3.5c0-.3-.1-.5-.2-.8-.1-.2-.3-.4-.5-.6-1.4-1.2-2.9-2.3-4.5-3.2.8-1.9,1.8-3.3,3-3.8.2-.2.4-.2.7-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M401,254c2.8,3.4,3.7-16.1,17.8-10.1c11.5,4.9,3.4,20.4,3.4,20.4s15.3-12.9,5.6-26.3c-13.8-19.3-38.2,2-26.8,16Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M418.8,268.3l4-3.3c.2-.1,15.9-13.6,5.8-27.7-4.2-5.9-9.9-8.7-16-7.9-3.2.4-6.2,1.7-8.8,3.7-2.5,2-4.5,4.6-5.8,7.6-1,2.3-1.3,4.8-.9,7.3s1.4,4.8,3.1,6.7c.2.3.4.5.7.6s.6.2.9.2c1.2-.1,1.9-1.5,2.9-3.5c2.8-5.6,5.9-10.4,13.6-7.1c1.1.4,2.2,1.1,3,2c.9.9,1.5,1.9,1.9,3.1c2.1,5.9-2.1,13.9-2.1,14l-2.3,4.3Zm-4.3-37c4.7,0,9.1,2.5,12.4,7.2c6,8.4,1.7,16.6-1.9,21.2.9-3.1,1.5-7.1.3-10.6-.5-1.4-1.3-2.7-2.4-3.8-1-1.1-2.3-1.9-3.7-2.4-10.1-4.3-14.1,3.7-16.3,8-.4.8-.8,1.5-1.2,2.2-1.3-1.6-2.2-3.6-2.5-5.7s0-4.2.8-6.1c1.1-2.6,2.9-4.9,5.1-6.7c2.2-1.7,4.9-2.9,7.7-3.3.6,0,1.1,0,1.7,0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M422.1,279.6c2.9-3.4-16.5-.9-13-15.8c2.9-12.2,19.5-6.8,19.5-6.8s-15.2-12.9-26.9-1c-16.4,16.8,8.7,37.3,20.4,23.6Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M412.7,285.1c2,0,3.9-.4,5.7-1.3c1.8-.8,3.3-2.1,4.6-3.6.2-.2.4-.5.5-.8s.1-.6,0-.9c-.3-1.2-1.8-1.6-4-2.2-6-1.8-11.2-4.1-9.3-12.3.2-1.2.7-2.3,1.4-3.3s1.6-1.8,2.7-2.4c5.4-3.1,14-.3,14.1-.3l4.9,1.6-3.9-3.3c-.2-.1-16.1-13.4-28.3-1-5,5.1-6.8,11.2-5.1,17.1c1,3.1,2.7,5.8,5.1,8s5.3,3.7,8.4,4.4c1,.2,2.1.3,3.2.3Zm8.6-6c-1.4,1.6-3.1,2.8-5.1,3.5s-4.1.7-6.2.2c-2.8-.6-5.3-2-7.4-3.9s-3.7-4.4-4.6-7.1c-1.5-5.2.1-10.5,4.6-15.1c7.2-7.4,16-4.5,21.2-1.7-3.2-.4-7.2-.3-10.4,1.6-1.3.7-2.4,1.8-3.3,3s-1.5,2.6-1.8,4.1c-2.5,10.8,6.1,13.3,10.7,14.7.7.1,1.5.4,2.3.7Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M408.1,279c.9-1-4.9-.3-3.9-4.7.9-3.6,5.8-2,5.8-2s-4.5-3.8-8-.3c-4.8,5,2.6,11.1,6.1,7Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M405.3,281.4c.7,0,1.4-.2,2-.4.6-.3,1.2-.7,1.6-1.2.1-.2.2-.3.3-.6c0-.2,0-.4,0-.6-.2-.7-.9-.9-1.7-1.1-1.9-.6-2.7-1.1-2.3-2.8c0-.3.1-.5.3-.7.1-.2.3-.4.6-.5c1.1-.6,3-.2,3.6,0l4.9,1.6-3.9-3.3c-1.8-1.5-6.1-3.6-9.4-.2-1.7,1.7-2.3,3.8-1.7,5.8.3,1,.9,2,1.7,2.7s1.8,1.2,2.8,1.5c.5-.3.8-.2,1.2-.2Zm1-2.3c-.5.2-1.1.3-1.7.1-.7-.2-1.3-.5-1.9-1-.5-.5-.9-1.1-1.2-1.8-.4-1.3,0-2.6,1.2-3.8.6-.7,1.4-1.1,2.3-1.2-.5.3-.9.6-1.2,1.1-.3.4-.5.9-.6,1.5-.7,3.5,1.6,4.6,3.1,5.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M458.3,277.2c-2.5.3,5-8.1-3.2-11.3-6.7-2.6-9.6,7-9.6,7s-1.1-11.4,8.3-13.1c13.4-2.3,14.9,16.3,4.5,17.4Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M458.3,278.3h.2c1.5-.1,2.9-.7,4.1-1.6s2.1-2.1,2.7-3.5c.7-1.8,1-3.7.7-5.6s-1-3.7-2.2-5.3c-2.3-2.9-5.9-4.1-10.1-3.4-8.2,1.4-9.6,9.9-9.1,14.2l.5,5.1l1.5-4.9c0,0,1.5-4.8,4.5-6.3.6-.3,1.2-.4,1.9-.4.6,0,1.3.1,1.9.4c4.2,1.6,3.6,4.5,2.5,7.9-.4,1.3-.8,2.3-.2,3c.1.2.3.3.5.4.1-.1.4,0,.6,0Zm-2.2-17.6c1.2,0,2.3.2,3.4.7s2,1.2,2.7,2.1c.9,1.2,1.5,2.7,1.8,4.3.2,1.5,0,3.1-.6,4.6-.4,1-1,1.8-1.8,2.5s-1.7,1.1-2.7,1.3c.1-.2.2-.5.2-.7.8-2.6,2.6-7.9-3.7-10.4-.8-.4-1.8-.6-2.7-.5-.9,0-1.8.2-2.7.6-1.3.7-2.4,1.6-3.2,2.8.7-2.9,2.5-6.3,7.1-7c.9-.3,1.5-.3,2.2-.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M426.6,273.9c1.9-2.3-11-.6-8.7-10.6c1.9-8.2,13-4.6,13-4.6s-10.2-8.6-18-.7c-11,11.4,5.8,25.1,13.7,15.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M420.2,277.9c1.4,0,2.7-.3,4-.9s2.3-1.4,3.2-2.5c.2-.2.3-.4.4-.7s.1-.5,0-.8c-.3-.9-1.3-1.3-2.9-1.7-3.9-1.1-7.2-2.6-6-7.8.1-.8.4-1.5.9-2.1.4-.6,1-1.2,1.7-1.5c3.5-2,9.1-.2,9.2-.2l4.9,1.6-3.9-3.3c-.1-.1-11-9.1-19.4-.7-3.5,3.5-4.7,7.7-3.5,11.8.7,2.1,1.9,4,3.5,5.5s3.6,2.5,5.8,3c.7.2,1.4.3,2.1.3Zm5.2-4.2c-.9.9-2,1.6-3.2,1.9-1.2.4-2.5.4-3.7.1-1.8-.4-3.5-1.3-4.8-2.5-1.4-1.2-2.4-2.8-3-4.6-.7-2.4-.8-6,3-9.8c2.9-3,6.6-3.8,10.8-2.4.5.2,1,.4,1.5.6-1.9-.1-3.8.3-5.5,1.2-.9.5-1.7,1.2-2.3,2.1s-1,1.8-1.2,2.9c-1.8,7.5,4.4,9.3,7.4,10.2.3.1.7.2,1,.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M469,281c-1,2.8-6.1-9.2-13.5-2.1-6,5.8,2.8,13.5,2.8,13.5s-12.6-4.4-9.8-15.1c4-15.3,24.3-7.8,20.5,3.7Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M462.7,295.1l-3.9-3.4c0,0-4.4-4-4.5-8c0-.8.1-1.5.5-2.2.3-.7.8-1.3,1.4-1.8c3.9-3.7,6.8-1.6,9.8,1.2c1.2,1.1,2,1.8,2.9,1.6.3-.1.5-.2.7-.4s.3-.4.4-.7c.6-1.6.7-3.4.3-5.1s-1.2-3.2-2.4-4.5c-1.5-1.6-3.4-2.8-5.5-3.4s-4.4-.7-6.5-.2c-4.1,1-7.1,4.2-8.3,9-3,11.5,10.3,16.3,10.4,16.4l4.7,1.5Zm-2.5-19.5c-1.7,0-3.5.7-5.5,2.5-.8.7-1.4,1.6-1.8,2.5-.4,1-.6,2-.6,3.1.1,1.9.7,3.8,1.8,5.3-.4-.3-.8-.6-1.2-1-3.4-3-4.5-6.5-3.5-10.5c1.4-5.2,4.5-7,6.9-7.6c1.8-.4,3.7-.3,5.4.2c1.8.5,3.4,1.5,4.6,2.9.9.9,1.5,2,1.8,3.3.3,1.2.3,2.5,0,3.7-.3-.2-.6-.5-.9-.8-1.4-1.3-3.9-3.6-7-3.6v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M444,263.8c1.3-1.5-7.4-.4-5.8-7.1c1.3-5.5,8.7-3,8.7-3s-6.8-5.8-12-.5c-7.4,7.6,3.8,16.7,9.1,10.6Z"
                fill="#dcdbfa"
              ></path>
              <path
                d="M439.7,266.8c1,0,1.9-.2,2.8-.6s1.6-1,2.3-1.7c.2-.2.3-.4.3-.6s.1-.5,0-.7c-.2-.8-1.1-1.1-2.1-1.4-2.6-.8-4.5-1.6-3.8-4.8.1-.5.3-.9.5-1.3.3-.4.6-.7,1-.9c2.2-1.2,5.8-.1,5.9-.1l4.9,1.6-3.9-3.3c-2.6-2.2-8.8-5.1-13.4-.4-2.4,2.5-3.3,5.4-2.4,8.2.5,1.5,1.3,2.8,2.5,3.8c1.1,1,2.5,1.8,4,2.1.4.1.9.1,1.4.1Zm2.8-3c-.5.4-1.2.7-1.8.9-.7.2-1.4.1-2.1,0-1.1-.3-2.2-.8-3.1-1.6s-1.5-1.8-1.9-2.9c-.5-1.5-.5-3.8,1.9-6.2.8-.8,1.8-1.4,2.9-1.7s2.3-.3,3.4.1c-.8.1-1.5.4-2.2.7-.6.4-1.2.9-1.6,1.5s-.7,1.3-.9,2c-1.2,5.3,3.1,6.6,5.2,7.2h.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M438.9,258.3c.2,2,5.4-5.1,9.1.7c3.1,4.7-3.8,8.4-3.8,8.4s8.9-1,8.6-8.4c-.3-10.5-14.7-8.7-13.9-.7Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M439.2,268.9l5.1-.5c3.4-.4,9.8-2.8,9.5-9.4-.1-3.4-1.6-6.1-4.3-7.4-1.4-.7-2.9-1-4.5-.9-1.5.1-3,.6-4.3,1.5-1,.7-1.8,1.6-2.3,2.7s-.7,2.3-.6,3.5c0,.2.1.5.2.7s.3.4.5.5c.8.4,1.5,0,2.5-.6c2.3-1.4,4.3-2.2,6.1.6.3.4.5.8.6,1.3s.1.9,0,1.4c-.6,2.5-3.9,4.3-4,4.3l-4.5,2.3Zm6.6-16.2c1,0,2,.2,2.9.7c1.4.7,3.1,2.3,3.2,5.7.1,1.2-.2,2.3-.8,3.3s-1.4,1.8-2.4,2.4c.4-.6.8-1.3,1-2.1.2-.7.2-1.5.1-2.2s-.4-1.4-.9-2c-3-4.6-6.9-2.3-8.8-1.3l-.2.1c.1-.7.3-1.4.6-2c.4-.6.8-1.1,1.4-1.5c1.2-.7,2.5-1.1,3.9-1.1v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M395.8,255.7c-.2-2-5.4,5-9.1-.8-3-4.7,3.9-8.4,3.9-8.4s-8.9.8-8.8,8.3c.3,10.5,14.7,8.9,14,.9Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M389,263.2c1.7,0,3.4-.5,4.8-1.4c1-.7,1.9-1.6,2.4-2.7s.8-2.3.6-3.5c0-.2-.1-.5-.2-.7s-.3-.4-.5-.5c-.7-.4-1.5,0-2.5.6-2.4,1.3-4.3,2.1-6.1-.7-.3-.4-.5-.8-.5-1.3-.1-.5-.1-.9,0-1.4.7-2.5,4-4.2,4.1-4.3l4.6-2.4-5.1.5c-3.4.3-9.8,2.7-9.7,9.3.1,3.4,1.5,6.1,4.2,7.4c1.2.7,2.5,1.1,3.9,1.1Zm5.8-6.5c-.1.7-.3,1.4-.7,1.9-.4.6-.9,1.1-1.4,1.4-1,.6-2.1,1-3.3,1.1s-2.3-.2-3.4-.7c-1.4-.7-3-2.3-3.1-5.7c0-1.2.2-2.3.8-3.3s1.4-1.8,2.4-2.4c-.5.6-.8,1.3-1,2.1-.2.7-.2,1.5-.1,2.2s.4,1.4.8,2.1c2.9,4.6,6.9,2.4,8.8,1.4l.2-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M418.8,265.6c-3.2,2.1,10.8,4,2.7,15.3-6.6,9.3-15.8,1.6-15.8,1.6s5.4,13.4,17.8,6.2c17.6-10.3,8.4-31.8-4.7-23.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M416.6,291.9c2.7-.1,5.2-.9,7.5-2.3c6.5-3.8,10.4-9.9,10.2-16.1-.1-4.4-2.2-8.2-5.5-9.9-3.1-1.6-6.9-1.2-10.5,1.2-.3.1-.5.4-.7.6-.2.3-.3.6-.3.9c0,1,.9,1.7,2,2.6c2.6,2,6.2,4.7,1.4,11.4-1.7,2.4-3.8,3.8-6.1,4.1-4.3.6-8.2-2.6-8.2-2.7l-3.1-2.6l1.5,3.7c.1.3,2.6,6.4,8.3,8.4c1.1.5,2.3.7,3.5.7Zm8.2-27.3c1.1,0,2.1.2,3,.7c2.6,1.4,4.3,4.5,4.4,8.2.1,5.5-3.3,10.9-9.2,14.3-3.4,2-6.5,2.5-9.2,1.6-2.1-.8-3.8-2.2-5.1-4c1.9.9,4.1,1.2,6.2,1c2.9-.4,5.4-2,7.5-5c5.9-8.3.7-12.3-1.8-14.2-.4-.3-.8-.6-1.1-.9c1.5-1,3.4-1.7,5.3-1.7Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M388.3,255.4c1.5,1.2.2-7.4,6.9-6c5.5,1.1,3.3,8.6,3.3,8.6s5.5-7,.1-12c-7.7-7.2-16.5,4.3-10.3,9.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M396.1,262.7l3.2-4.1c2.1-2.7,4.8-8.9,0-13.4-2.5-2.3-5.5-3.1-8.3-2.1-1.5.5-2.7,1.4-3.7,2.6s-1.7,2.6-2,4.1c-.2,1.2-.1,2.4.3,3.6.4,1.1,1.2,2.1,2.1,2.9.2.2.4.3.6.3s.5,0,.7,0c.8-.3,1-1.1,1.3-2.2.7-2.6,1.5-4.6,4.7-3.9.5.1.9.2,1.3.5s.7.6,1,1c1.1,1.8.6,4.8.3,5.9l-1.5,4.8Zm-2.3-14.5c-4,0-5,3.7-5.5,5.6c0,.1,0,.1,0,.2-.4-.5-.8-1.2-.9-1.8-.2-.7-.2-1.4,0-2.1.2-1.2.8-2.2,1.5-3.1.8-.9,1.7-1.6,2.8-2c1.5-.5,3.7-.6,6.2,1.7.9.8,1.5,1.8,1.8,2.9s.3,2.3,0,3.4c-.1-.8-.4-1.5-.8-2.2-.4-.6-.9-1.2-1.5-1.6s-1.3-.7-2.1-.8c-.4-.2-1-.2-1.5-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M461,299.3c-.2-2-5.5,5-9.1-.8-3-4.7,3.9-8.4,3.9-8.4s-8.9.8-8.8,8.3c.2,10.5,14.7,9,14,.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M454.2,306.9c1.7,0,3.4-.5,4.8-1.4c1-.7,1.9-1.6,2.4-2.7s.8-2.3.6-3.5c0-.2-.1-.5-.2-.7s-.3-.4-.5-.5c-.7-.4-1.5,0-2.5.6-2.4,1.3-4.3,2.1-6.1-.7-.3-.4-.5-.8-.5-1.3-.1-.5-.1-.9,0-1.4.7-2.5,4-4.2,4.1-4.3l4.5-2.4-5.1.5c-3.4.3-9.8,2.7-9.7,9.3.1,3.4,1.5,6.1,4.2,7.4c1.2.7,2.6,1.1,4,1.1Zm5.8-6.6c-.1.7-.3,1.4-.7,1.9-.4.6-.9,1.1-1.4,1.4-1,.6-2.1,1-3.3,1.1s-2.3-.2-3.4-.7c-1.4-.7-3-2.3-3.1-5.7c0-1.2.2-2.3.8-3.3s1.4-1.8,2.4-2.4c-.5.6-.8,1.3-1,2.1-.2.7-.2,1.5-.1,2.2s.4,1.4.8,2.1c2.9,4.6,6.9,2.4,8.8,1.4.1,0,.1,0,.2-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M436.3,294.3c-.6-1.9-5.2-14.1,9.5-12.5.7.1,1.3.3,1.9.6c1.9,1.2,4-1.4,2.5-3s-4.1-2.6-9.1-2.2c-20,1.5-2.9,22.7-4.8,17.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M436.3,296.2c.3,0,.5-.1.7-.2.8-.6.4-1.6.3-2l-.1-.2c-1.4-4.1-1.4-7.2,0-9s4.2-2.4,8.4-2c.5,0,1,.2,1.4.5.6.4,1.3.6,2,.4.7-.1,1.4-.5,1.8-1c.5-.5.8-1.2.8-2c0-.7-.3-1.4-.8-2-1.8-2-5.2-2.9-10-2.5-7.4.6-9.8,3.8-10.5,6.4-1.4,5.4,3.2,12.2,4.9,13.3.5.2.8.3,1.1.3Zm7.1-15.5c-3.6,0-6.2,1-7.7,2.9s-1.9,4.7-1.1,8.2c-1.4-2.2-2.7-5.5-2.1-8.3.6-3.1,3.6-4.8,8.7-5.2c4.2-.3,6.9.3,8.3,1.9.2.2.2.4.2.6s-.1.5-.3.6c-.1.2-.3.3-.6.3-.2,0-.5,0-.6-.1-.7-.4-1.5-.7-2.3-.8-.9-.1-1.7-.2-2.5-.1v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M437.2,283.6c-.6-1.9-8.6-26.7,9.5-12.5c2.3,1.8-7.3,5.1-7.3,5.1s21-5.3,9.8-13c-10.3-7.1-10.2,26-12,20.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M437.6,285.3h.1c1.2-.1,1.4-1.3,2.1-5.8.1-.8.3-1.6.4-2.5c2.8-.8,12.3-3.7,13.1-8.6.4-2.1-.9-4.2-3.7-6.1-1.7-1.2-3.4-1.5-4.9-.8-1.7.7-2.9,2.5-3.9,4.9-2.1-.8-3.7-.8-5,.1-1,.7-4.1,4.2.3,17.5.3.3.6,1.3,1.5,1.3Zm2.6-17.1c-1.1,3.6-1.9,7.2-2.4,10.9-.1.7-.2,1.4-.3,2-2.4-8.1-1.6-12.2-.4-13.1.4-.3,1.3-.5,3.1.2Zm5.7,3.6c-.5.7-2.7,1.8-5.3,2.8.4-1.9.8-3.7,1.4-5.5c1.4.8,2.7,1.7,3.9,2.7Zm.5-8.6c.8.1,1.5.3,2.1.8c2.1,1.4,3,2.8,2.8,4.1-.2,1.2-1.5,2.5-3.3,3.6c0-.3-.1-.5-.2-.7s-.3-.4-.5-.6c-1.4-1.2-3-2.2-4.6-3.1.8-1.9,1.7-3.4,2.9-3.9.3-.2.5-.2.8-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M444.4,292.8c-2,0,4.6,5.8-1.4,9-4.9,2.7-8.1-4.5-8.1-4.5s.2,8.9,7.6,9.3c10.6.6,10-13.9,1.9-13.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M443.2,307.7c3.2,0,5.7-1.3,7.1-3.7.8-1.3,1.2-2.8,1.2-4.4c0-1.5-.4-3.1-1.1-4.4-.6-1.1-1.5-1.9-2.5-2.6-1.1-.6-2.3-.9-3.5-.9-.2,0-.5,0-.7.1s-.4.3-.5.5c-.4.7-.1,1.5.4,2.5c1.1,2.4,1.8,4.4-1.1,6-.4.2-.9.4-1.3.5-.5.1-.9,0-1.4-.1-2.4-.8-4-4.3-4-4.3l-2.1-4.7.1,5.1c.1,3.4,2,10,8.6,10.3l.8.1Zm2.2-13.8c.7.1,1.3.4,1.9.8s1,.9,1.4,1.5c.6,1,.9,2.2.8,3.4c0,1.2-.3,2.3-.9,3.3-.8,1.4-2.5,2.9-5.9,2.7-1.2,0-2.3-.4-3.2-1-1-.6-1.7-1.5-2.2-2.6.6.5,1.3.9,2,1.2.7.2,1.5.3,2.2.2s1.5-.3,2.1-.7c4.8-2.6,2.9-6.7,2-8.7l-.2-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M443.7,300.3c1.4-1.5-7.3-.7-5.6-7.3c1.5-5.4,8.8-2.7,8.8-2.7s-6.6-6-12-.9c-7.6,7.2,3.3,16.8,8.8,10.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M439.5,303.1c.9,0,1.8-.2,2.7-.6.8-.4,1.6-.9,2.2-1.6.2-.2.3-.4.4-.6s.1-.5,0-.7c-.2-.8-1-1.1-2.1-1.4-2.6-.9-4.5-1.8-3.6-5c.1-.5.3-.9.6-1.3s.6-.7,1.1-.9c2.3-1.2,5.8.1,5.9.1l4.8,1.7-3.8-3.5c-2.5-2.3-8.6-5.4-13.4-.9-2.5,2.4-3.4,5.2-2.7,8.1.4,1.5,1.2,2.8,2.3,3.9s2.5,1.9,3.9,2.2c.6.5,1.2.5,1.7.5Zm2.7-2.9c-.6.4-1.2.7-1.9.8s-1.4.1-2.1-.1c-1.1-.3-2.2-.9-3-1.7s-1.5-1.8-1.8-3c-.4-1.5-.3-3.8,2.1-6.1.8-.8,1.8-1.4,3-1.6c1.1-.3,2.3-.2,3.4.2-.8.1-1.5.3-2.2.7-.7.3-1.2.8-1.7,1.4s-.8,1.3-1,2c-1.4,5.3,2.9,6.7,4.9,7.4h.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M423,257.9c-1,1.7-7,13.3-15.6,1.2-.4-.5-.7-1.2-.8-1.8-.3-2.2-3.7-2.1-3.9.1-.3,2.1.8,4.8,4.4,8.3c14.4,14,18.8-12.8,15.9-7.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M414.9,270.8c1.1,0,2.2-.3,3.2-.8c5-2.6,7-10.4,6.7-12.5c0-.3-.1-.6-.3-.8s-.4-.4-.7-.4c-.9-.2-1.5.8-1.7,1.1l-.1.2c-2.1,3.8-4.4,5.8-6.7,5.9s-4.6-1.6-7.1-5c-.3-.4-.5-.9-.6-1.4-.1-.7-.5-1.4-1-1.8-.6-.5-1.3-.7-2-.7s-1.4.2-2,.7c-.5.5-.9,1.1-1,1.9-.3,2.7,1.3,5.8,4.7,9.2s6.3,4.4,8.6,4.4Zm7.4-9.8c-.8,2.5-2.3,5.7-4.8,7.1-2.7,1.6-6,.5-9.7-3.1-3-2.9-4.4-5.4-4.1-7.4c0-.2.1-.4.3-.6.2-.1.4-.2.6-.2s.5,0,.6.2c.2.1.3.3.3.6.1.8.5,1.6,1,2.3c2.9,4.1,5.9,6,8.9,5.8c2.4-.2,4.7-1.8,6.9-4.7v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M463.7,284.7c-1,1.7-14.2,24.2-15.6,1.2-.2-3,8.7,2.1,8.7,2.1s-17.9-12.2-16.2,1.4c1.4,12.4,26-9.8,23.1-4.7Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M452.4,297.2c1.8,0,6.1-1.4,12.2-12c.2-.3.7-1.3,0-2-.9-.8-1.9-.2-5.7,2.2-.6.4-1.4.9-2.1,1.4-2.4-1.6-10.9-6.7-15.1-4.1-1.8,1.1-2.5,3.4-2.1,6.8.3,2.1,1.1,3.5,2.6,4.2c1.6.8,3.8.5,6.2-.3.8,2.1,1.9,3.3,3.4,3.7.1.1.4.1.6.1Zm9.3-11c-4.5,7.1-8.1,9.3-9.5,9-.5-.1-1.3-.7-1.9-2.4c3.4-1.6,6.6-3.4,9.7-5.5l1.7-1.1Zm-13.9,5.3c-1.9.7-3.7.9-4.8.4-.8-.4-1.3-1.3-1.5-2.6-.3-2.5.1-4.2,1.2-4.8c1.1-.7,2.9-.5,4.9.1-.2.2-.3.4-.4.7-.1.2-.1.5-.1.8c0,1.8.3,3.6.7,5.4v0Zm6.9-3.4c-1.6,1-3.3,1.9-5,2.6-.3-1.6-.5-3.1-.6-4.7.8,0,3.2.8,5.6,2.1v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M326.8,273c-4.4-.3,9.2,13.7-4.8,19.9-11.5,5.1-17.1-11.5-17.1-11.5s-1.1,20,15.3,22.1c23.3,3.2,24.6-29.3,6.6-30.5Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M323.2,304.8c5.7,0,10.5-2.2,13.5-6.4c1.9-2.6,3-5.7,3.3-8.9s-.2-6.4-1.5-9.4c-1-2.3-2.6-4.3-4.7-5.7s-4.5-2.2-7-2.3c-.3,0-.6,0-.9.1s-.5.3-.7.6c-.7,1-.2,2.4.6,4.5c2.2,5.9,3.5,11.4-4.2,14.8-1.1.5-2.3.8-3.5.9-1.2,0-2.4-.2-3.5-.7-5.7-2.5-8.7-11-8.7-11.1l-1.7-4.9-.3,5.2c0,.2-1,20.9,16.2,23.2c1,0,2,.1,3.1.1Zm3.7-30.7c2.1.1,4.1.8,5.8,2s3.1,2.9,3.9,4.8c1.2,2.6,1.6,5.5,1.3,8.3s-1.3,5.6-2.9,7.9c-3.2,4.3-8.4,6.3-14.8,5.4-6.4-.8-10.8-4.6-13-11.2-.5-1.6-.9-3.2-1.1-4.8c1.6,2.8,4.1,6,7.5,7.4c1.4.6,2.9.9,4.4.9s3-.4,4.3-1c10.1-4.5,7-12.9,5.3-17.4-.2-.7-.5-1.5-.7-2.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M293.7,270.9c.5,4.4,11.9-11.4,20.4,1.4c6.9,10.4-8.4,18.9-8.4,18.9s19.8-2.3,19.2-18.9c-1-23.6-33.2-19.3-31.2-1.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M300.6,292.7l5.1-.6c.2,0,20.8-2.6,20.1-20-.3-7.2-3.5-12.7-9-15.4-2.9-1.4-6.1-2-9.3-1.7-3.2.2-6.3,1.3-9,3.1-2.1,1.4-3.8,3.3-4.8,5.6s-1.4,4.8-1.1,7.3c0,.3.1.6.3.9s.4.5.7.6c1.1.5,2.4-.2,4.3-1.4c5.4-3.2,10.6-5.4,15.3,1.6.7,1,1.2,2.1,1.4,3.3s.2,2.4-.1,3.6c-1.5,6.1-9.4,10.5-9.5,10.5l-4.4,2.6ZM309,257c2.4,0,4.8.5,6.9,1.6c4.8,2.4,7.6,7.3,7.9,13.7.4,10.3-7.7,14.8-13.3,16.6c2.4-2.1,5.1-5.1,6-8.7.4-1.5.4-3,.1-4.5s-.9-2.9-1.8-4.1c-6.1-9.2-13.8-4.7-18-2.2-.7.4-1.5.8-2.2,1.2-.2-2.1.1-4.2,1-6.1s2.3-3.5,4.1-4.7c2.8-1.8,6-2.8,9.3-2.8Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M303.7,261c.1,1.3,3.6-3.4,6.1.4c2,3.1-2.5,5.6-2.5,5.6s5.9-.7,5.7-5.6c-.3-7-9.9-5.7-9.3-.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M302.3,268.6l5.1-.6c2.4-.3,6.8-2,6.6-6.7-.1-2.4-1.2-4.3-3.1-5.2-1-.5-2.1-.7-3.1-.6-1.1.1-2.1.4-3,1.1-.7.5-1.3,1.2-1.6,2-.4.8-.5,1.7-.4,2.5c0,.2.1.4.2.6s.3.3.5.4c.7.3,1.3,0,2-.4c1.7-1,2.6-1.2,3.6.3.2.2.3.4.3.7s.1.5,0,.8c-.3,1.2-1.9,2.4-2.5,2.7l-4.6,2.4Zm6-11c.6,0,1.2.1,1.7.4c1.2.6,1.9,1.8,1.9,3.5.1.9-.2,1.8-.7,2.5.1-.5.1-1.1,0-1.6s-.3-1-.6-1.5c-1.9-2.9-4.4-2-5.8-1.2.2-.6.6-1,1.1-1.4.7-.4,1.5-.7,2.4-.7Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M270.6,299c1.5-2,2.5,9.2,10.4,5.4c6.5-3.1,1.5-11.8,1.5-11.8s9.1,7,3.8,15c-7.4,11.3-22-.4-15.7-8.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M278.6,313.3c3.4,0,6.4-1.8,8.6-5.1c4.6-6.9-.7-13.7-4.1-16.4l-4.1-3.1l2.6,4.5c0,0,2.5,4.4,1.5,7.6-.2.6-.5,1.2-1,1.6-.4.5-1,.8-1.6,1.1-4.1,1.9-5.8-.5-7.4-3.6-.7-1.2-1.1-2.1-2.1-2.2-.3,0-.5,0-.7.2-.2.1-.4.3-.6.5-.9,1.2-1.5,2.6-1.7,4.1s.1,3,.7,4.4c.8,1.8,2.1,3.3,3.6,4.4c1.6,1.1,3.4,1.8,5.3,2c.4,0,.7,0,1,0Zm6.6-16.2c1.6,2.5,2.8,6.1.3,10-2.5,3.8-5.5,4.4-7.5,4.2-1.6-.2-3-.7-4.3-1.6s-2.3-2.1-3-3.5c-.4-.9-.6-1.9-.6-3c.1-1,.4-2,.9-2.9l.4.7c1.3,2.4,4,7.3,10.1,4.4.8-.4,1.6-.9,2.2-1.6s1.1-1.5,1.4-2.4c.3-1.5.4-2.9.1-4.3v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M294.7,278c.3,3,8-7.6,13.6.9c4.6,7-5.6,12.6-5.6,12.6s13.3-1.5,12.8-12.6c-.5-15.7-22-12.9-20.8-.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M297.7,293.2l5.1-.6c.1,0,14.2-1.8,13.7-13.7-.2-4.9-2.4-8.7-6.2-10.6-2-.9-4.2-1.4-6.4-1.2s-4.3.9-6.2,2.2c-1.4,1-2.6,2.3-3.3,3.9s-1,3.3-.8,5c0,.3.1.5.2.8.1.2.3.4.6.5.9.4,1.8-.1,3.2-.9c3.5-2,6.8-3.5,9.8,1c.5.6.8,1.3.9,2.1.2.8.1,1.5,0,2.3-1,3.9-6.1,6.8-6.1,6.8l-4.5,2.4Zm7.3-24.1c1.6,0,3.1.3,4.5,1c2.2,1.1,4.9,3.5,5.1,8.9.2,4.2-1.7,7.4-5.7,9.5-.5.3-1,.5-1.4.7c1.4-1.3,2.4-3,2.9-4.8.2-1,.3-2.1.1-3.1s-.6-2-1.2-2.9c-4.3-6.4-9.8-3.2-12.5-1.6-.3.2-.7.4-1,.6-.1-1.3.2-2.5.8-3.7.6-1.1,1.4-2.1,2.5-2.8c1.7-1.2,3.8-1.8,5.9-1.8Zm-9.3,8.8l-1,.1l1-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M260.6,304.2c-1.4-2.6,10.9,1.8,10.8-8.4-.1-8.4-11.8-7.3-11.8-7.3s11.8-6.2,17.7,3.2c8.4,13.4-11,23.1-16.7,12.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M268.2,309.7c2.2,0,4.3-.6,6.2-1.7s3.5-2.7,4.6-4.6c2.1-3.7,1.8-8.1-.8-12.3-6.3-10.2-18.9-3.7-19-3.6l-4.6,2.4l5.1-.5c.1,0,5.9-.5,8.9,2.2.6.5,1,1.2,1.3,1.9s.4,1.5.4,2.2c.1,5.4-3.5,6-7.5,6.3-1.6.1-2.7.2-3.2,1.1-.1.2-.2.5-.2.8s.1.5.2.8c.8,1.5,2,2.8,3.5,3.7s3.2,1.4,4.9,1.3h.2Zm-.3-22.2c1.7-.1,3.5.3,5,1.2c1.5.8,2.8,2.1,3.7,3.6c2.8,4.5,2,8,.8,10.2-.9,1.6-2.3,2.9-3.8,3.8s-3.4,1.4-5.2,1.5h-.1c-1.3,0-2.5-.3-3.6-.9s-2-1.5-2.7-2.6c.4,0,.8-.1,1.2-.1c3.1-.2,9.5-.7,9.4-8.4c0-1.1-.2-2.1-.6-3.1s-1-1.8-1.8-2.5c-1.5-1.2-3.2-2-5.1-2.4.5-.1,1-.2,1.6-.2.2-.1.7-.1,1.2-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M294.7,310.3c1.5-1.5-7.1-1.1-4.7-7.9c2-5.6,8.9-2.4,8.9-2.4s-5.8-6.6-11.7-1.5c-8.1,7.2,1.6,17.7,7.5,11.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M290.7,313c.9,0,1.7-.2,2.6-.5.8-.3,1.5-.9,2.1-1.5.2-.2.3-.4.4-.6s.1-.5.1-.7c-.2-.8-.9-1.2-1.9-1.6-2.4-1-4.2-2-3-5.4.2-.5.4-1,.8-1.4s.8-.7,1.3-.9c1.9-.8,4.6.1,5.5.5l4.4,2-3.2-3.6c-2.3-2.5-7.9-6.2-13.1-1.6-3.8,3.4-3.8,7-3.4,8.8.3,1.4,1,2.8,2,3.9s2.2,1.9,3.6,2.4c.6.1,1.2.2,1.8.2Zm2.7-2.9c-.5.4-1.2.7-1.9.8s-1.4.1-2-.1c-1.1-.4-2-1-2.7-1.8s-1.2-1.8-1.5-2.9c-.5-2.4.4-4.9,2.7-6.9.9-.8,1.9-1.4,3.1-1.6s2.4-.1,3.5.4c-.8,0-1.6.2-2.3.5s-1.4.8-2,1.4c-.5.6-1,1.3-1.2,2.1-2,5.4,2.3,7.2,4.1,7.9l.2.2Zm.6-.6l.7.7-.7-.7v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M302.8,286.8c-1.6-1.2,0,7.4-6.8,6.2-5.5-1-3.5-8.6-3.5-8.6s-5.4,7.1.2,12c7.9,7.1,16.5-4.7,10.1-9.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M297.8,299.7c.9,0,1.7-.2,2.5-.4c1.4-.5,2.7-1.5,3.7-2.6c1-1.2,1.6-2.6,1.9-4.1.2-1.2.1-2.4-.4-3.6-.4-1.1-1.2-2.1-2.2-2.8-.2-.2-.4-.3-.6-.3s-.5,0-.7,0c-.8.3-1,1.1-1.3,2.2-.6,2.6-1.4,4.6-4.6,4-.5-.1-.9-.2-1.3-.5-.4-.2-.7-.6-1-1-1.4-2.1-.4-5.8-.4-5.9l1.3-5-3.1,4.1c-2,2.7-4.6,9,.3,13.4c1.7,1.5,3.8,2.4,5.9,2.5Zm5-11.4c.5.5.8,1.1,1,1.8s.2,1.4.1,2.1c-.2,1.2-.7,2.2-1.4,3.2-.7.9-1.7,1.6-2.8,2-1.5.5-3.7.7-6.3-1.6-.9-.7-1.5-1.7-1.9-2.8s-.4-2.3-.1-3.4c.2.8.4,1.5.9,2.2.4.6.9,1.2,1.6,1.6s1.3.7,2.1.8c5.4,1,6.4-3.4,6.9-5.6-.1-.2-.1-.3-.1-.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M329.1,268.1c1.6,1.2.1-7.4,6.8-6.1c5.5,1.1,3.4,8.6,3.4,8.6s5.5-7,0-12c-7.8-7.2-16.5,4.5-10.2,9.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M337,275.3l3.2-4.1c2.1-2.7,4.8-9-.1-13.4-2.5-2.3-5.5-3.1-8.3-2.1-1.4.5-2.7,1.4-3.7,2.6s-1.7,2.6-1.9,4.1c-.2,1.2-.1,2.4.3,3.6s1.2,2.1,2.1,2.9c.2.2.4.3.6.3s.5,0,.7,0c.8-.3,1-1.1,1.3-2.2.7-2.6,1.4-4.6,4.7-4c.5.1.9.2,1.3.5s.7.6,1,1c1.4,2.2.3,5.8.3,5.9l-1.5,4.9Zm-2.4-14.5c-4.1,0-5,3.7-5.5,5.6c0,.1,0,.1,0,.2-.5-.5-.8-1.1-1-1.8s-.2-1.4-.1-2.1c.2-1.2.7-2.2,1.5-3.1s1.7-1.6,2.8-2c1.5-.5,3.7-.6,6.3,1.7.9.8,1.5,1.8,1.9,2.9.3,1.1.4,2.3.1,3.4-.1-.8-.4-1.5-.8-2.2-.4-.6-.9-1.2-1.5-1.6s-1.3-.7-2.1-.8c-.6-.1-1.1-.2-1.6-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M309.5,283.2c.9-3.9-12.7,5.5-15.6-8.1-2.4-11.2,12.2-13,12.2-13s-16.2-4.8-20.6,9.4c-6.2,20,20.2,27.4,24,11.7Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M298.8,292.7c1.2,0,2.3-.1,3.5-.5c4.2-1.2,7.2-4.3,8.2-8.8.1-.3.1-.6,0-.9s-.2-.6-.4-.8c-.8-.8-2.1-.6-3.9-.3-4.1.7-9.6,1.5-11.3-6.5-.3-1.1-.3-2.3-.1-3.4s.6-2.2,1.3-3.2c3-4.3,10.1-5.2,10.1-5.2l4.7-.6-4.6-1.4c-.2-.1-17.2-5-21.9,10-2.7,8.9.8,14.4,3.2,16.8c1.4,1.5,3.2,2.6,5.1,3.4c2,.9,4,1.4,6.1,1.4Zm9.7-9.6c-.9,3.7-3.3,6.2-6.8,7.1-4.2,1.2-9.3-.3-12.5-3.7-3.6-3.7-4.5-9-2.7-14.8c1.7-5.5,5.4-8.6,11.1-9.3c1.2-.1,2.4-.2,3.6-.1-2.4.9-5,2.4-6.8,4.8-.8,1.2-1.4,2.5-1.6,3.9-.3,1.4-.2,2.8.1,4.2c2.1,9.9,9.6,8.7,13.6,8.1.7,0,1.3-.1,2-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M334.5,262.8c-2,.3,5.3,5.2-.3,9.1-4.6,3.2-8.5-3.5-8.5-3.5s1.3,8.8,8.7,8.3c10.4-.7,8-15,.1-13.9Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M333.7,277.8c.2,0,.5,0,.7,0c3.4-.2,6-1.9,7.2-4.6.6-1.4.9-3,.7-4.5s-.7-3-1.6-4.2c-.7-1-1.7-1.8-2.8-2.3s-2.4-.6-3.6-.5c-.2,0-.5.1-.7.2s-.3.3-.5.5c-.4.8.1,1.5.7,2.5c1.4,2.3,2.3,4.2-.4,6.1-.4.3-.8.5-1.2.6-.5.1-.9.1-1.4,0-2.5-.5-4.4-3.8-4.5-3.8l-2.6-4.4.7,5.1c.7,3.4,3.2,9.3,9.3,9.3Zm1.8-14c.7,0,1.4.2,2,.6.6.3,1.1.8,1.5,1.4.7,1,1.1,2.1,1.2,3.2.1,1.2,0,2.4-.5,3.4-.7,1.4-2.1,3.1-5.5,3.4-1.2.1-2.3-.1-3.4-.6-1-.5-1.9-1.3-2.5-2.3.7.4,1.4.7,2.1.9s1.5.1,2.2,0c.7-.2,1.4-.5,2-.9c4.5-3.2,2-7,.9-8.8v-.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M252.7,285.7c1.6,1.2.1-7.4,6.8-6.1c5.5,1.1,3.4,8.6,3.4,8.6s5.5-7,0-12c-7.8-7.1-16.5,4.5-10.2,9.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M260.5,293l3.2-4.1c2.1-2.7,4.8-9-.1-13.4-2.5-2.3-5.5-3-8.3-2.1-1.4.5-2.7,1.4-3.7,2.6s-1.7,2.6-1.9,4.1c-.2,1.2-.1,2.4.3,3.6s1.2,2.1,2.1,2.9c.2.2.4.3.6.3s.5,0,.7,0c.8-.3,1-1.1,1.3-2.2.7-2.6,1.4-4.6,4.7-4c.5.1.9.2,1.3.5s.7.6,1,1c1.1,1.8.6,4.8.3,5.9l-1.5,4.9Zm-2.3-14.5c-4.1,0-5,3.7-5.5,5.6c0,.1,0,.1,0,.2-.5-.5-.8-1.1-1-1.8s-.2-1.4-.1-2.1c.2-1.2.7-2.2,1.5-3.1s1.7-1.6,2.8-2c1.5-.5,3.7-.6,6.3,1.7.9.8,1.5,1.8,1.9,2.9.3,1.1.4,2.3.1,3.4-.1-.8-.4-1.5-.8-2.2-.4-.6-.9-1.2-1.5-1.6s-1.3-.7-2.1-.8c-.5-.2-1.1-.2-1.6-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M273.2,271.2c1.8.8,13.8,5.8,2.6,15.5-.5.4-1.1.8-1.7.9-2.2.5-1.8,3.8.4,3.9c2.1.1,4.7-1.3,7.9-5.2c12.6-15.6-14.5-17.6-9.2-15.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M274.7,292.5c2.7,0,5.5-1.9,8.5-5.5c4.7-5.8,3.9-9.8,2.5-12.1-3-4.7-11-6-13-5.6-.3,0-.6.2-.8.4s-.3.5-.4.8c-.1,1,.9,1.4,1.3,1.6l.2.1c4,1.8,6.2,3.9,6.5,6.2s-1.1,4.7-4.3,7.5c-.4.3-.9.6-1.4.7-.7.2-1.3.6-1.7,1.2s-.6,1.3-.5,2s.4,1.4.9,1.9s1.2.8,1.9.8h.3Zm1.6-20.9c2.5.5,5.8,1.8,7.5,4.2c1.8,2.6,1,5.9-2.2,9.9-2.6,3.2-5,4.9-7,4.8-.2,0-.5-.1-.6-.3-.2-.2-.3-.4-.3-.6s0-.5.1-.6c.1-.2.3-.3.5-.4.8-.2,1.5-.6,2.2-1.2c3.8-3.2,5.4-6.4,5-9.4-.3-2.3-2.1-4.5-5.2-6.4Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M280.4,279.1c1.8.8,25.3,12,2.6,15.5-2.9.4,1.3-8.8,1.3-8.8s-10.5,19,2.8,16.1c12.2-2.7-12-25.2-6.7-22.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M284.7,303.2c.9,0,1.8-.1,2.7-.3c2.1-.4,3.4-1.5,4-3c.6-1.7.2-3.9-.9-6.2c2-1,3.1-2.2,3.4-3.7.2-1.3-.2-5.9-13-11.8-.3-.1-1.4-.6-2,.2-.7.9,0,1.9,2.7,5.5.5.6,1,1.3,1.5,2-1.4,2.6-5.7,11.5-2.8,15.5c1,1.2,2.4,1.8,4.4,1.8Zm3.9-8.8c.9,1.9,1.3,3.6.8,4.7-.3.9-1.1,1.4-2.5,1.7-2.5.5-4.1.3-4.9-.7s-.7-2.8-.3-4.9c.2.2.4.3.7.3.3.1.5.1.8,0c1.8-.1,3.6-.5,5.4-1.1Zm-.9-1.8c-1.5.4-3.1.8-4.6,1-.1-.8.5-3.2,1.6-5.8c1,1.6,2,3.2,3,4.8Zm-5.6-11.5c7.5,3.8,10,7.2,9.8,8.6-.1.5-.5,1.4-2.2,2.2-1.9-3.2-4-6.3-6.3-9.2l-1.3-1.6Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M268.8,278.1c1.4-1.5-7.4-.6-5.6-7.2c1.4-5.4,8.8-2.8,8.8-2.8s-6.7-6-12-.8c-7.6,7.3,3.4,16.8,8.8,10.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M264.6,281c.9,0,1.8-.2,2.7-.6.8-.4,1.6-.9,2.2-1.6.2-.2.3-.4.3-.6.1-.2.1-.5,0-.7-.2-.8-1-1.1-2.1-1.4-2.6-.8-4.5-1.7-3.6-4.9.1-.5.3-.9.6-1.3s.6-.7,1-.9c1.9-1,4.9-.3,5.9.1l4.9,1.8-3.9-3.5c-2.5-2.3-8.6-5.4-13.4-.8-2.5,2.4-3.4,5.3-2.6,8.1.4,1.5,1.2,2.8,2.4,3.9c1.1,1.1,2.5,1.8,4,2.2.5.2,1.1.2,1.6.2Zm2.7-2.9c-.6.4-1.2.7-1.9.8s-1.4.1-2-.1c-1.1-.3-2.2-.9-3-1.7s-1.5-1.8-1.8-3c-.4-1.5-.4-3.8,2.1-6.1.8-.8,1.8-1.4,3-1.7c1.1-.3,2.3-.2,3.4.2-.8.1-1.5.3-2.2.7s-1.2.8-1.7,1.4-.8,1.3-.9,2c-1.4,5.3,2.9,6.7,5,7.4v.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M263.9,272.5c.1,2,5.5-4.9,9.1.9c2.9,4.8-4.1,8.3-4.1,8.3s8.9-.7,8.9-8.2c-.1-10.5-14.5-9.1-13.9-1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M263.9,283.1l5.1-.4c3.4-.3,9.8-2.5,9.8-9.2c0-3.4-1.5-6.1-4.1-7.5-1.4-.7-2.9-1.1-4.4-1s-3,.5-4.3,1.4c-1,.6-1.9,1.6-2.4,2.6-.5,1.1-.8,2.3-.7,3.5c0,.2.1.5.2.7s.3.4.5.5c.7.4,1.5,0,2.5-.5c2.4-1.3,4.3-2,6.1.8.3.4.4.8.5,1.3s.1.9-.1,1.4c-.7,2.4-4.1,4.2-4.1,4.2l-4.6,2.2Zm6.8-16c1.1,0,2.1.2,3.1.7c1.4.7,3,2.3,3,5.7c0,1.2-.3,2.3-.9,3.3s-1.4,1.8-2.5,2.4c.5-.6.8-1.3,1.1-2.1.2-.7.2-1.5.1-2.2s-.4-1.4-.8-2.1c-2.9-4.7-6.8-2.5-8.8-1.5l-.2.1c.1-.7.3-1.4.7-1.9.4-.6.9-1.1,1.5-1.4c1.1-.7,2.4-1.1,3.7-1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M308.8,286.3c-.6-1.9-4.9-14.2,9.8-12.2.7.1,1.3.3,1.9.7c1.8,1.3,4.1-1.2,2.6-2.9-1.4-1.7-4.1-2.7-9.1-2.4-19.9.9-3.5,22.4-5.2,16.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M308.8,288.3c.2,0,.5-.1.7-.2.8-.6.5-1.6.3-2l-.1-.2c-1.3-4.2-1.2-7.2.3-9s4.3-2.3,8.5-1.8c.5.1,1,.2,1.4.5.6.4,1.3.6,2,.5s1.4-.4,1.8-1c.5-.5.8-1.2.8-1.9s-.2-1.4-.7-2c-1.7-2.1-5.1-3-9.9-2.8-7.4.4-9.9,3.5-10.6,6.1-1.6,5.4,2.8,12.2,4.5,13.4.3.2.6.4,1,.4Zm6.9-15.5c-3.3,0-5.8.9-7.3,2.7-1.6,1.9-2,4.6-1.4,8.2-1.3-2.3-2.5-5.6-1.9-8.4.7-3,3.7-4.7,8.9-4.9c4.2-.2,6.9.5,8.2,2.1.1.2.2.4.2.6s-.1.5-.3.6c-.1.2-.3.3-.6.3-.2,0-.5,0-.6-.2-.7-.5-1.5-.7-2.3-.8s-1.8-.1-2.9-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M261.5,297.7c-.6-1.9-7.8-26.9,9.8-12.2c2.3,1.9-7.4,4.9-7.4,4.9s21.1-4.7,10.1-12.8c-10.1-7.4-10.8,25.7-12.5,20.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M261.9,299.4v0c1.3-.1,1.5-1.3,2.3-5.7.1-.7.3-1.6.5-2.5c2.8-.7,12.4-3.4,13.4-8.2.4-2.1-.7-4.2-3.5-6.2-1.7-1.2-3.4-1.6-4.9-1-1.7.7-3,2.5-4.1,4.7-2.1-.9-3.7-.9-5-.1-1.1.7-4.2,4.1-.2,17.5.2.5.5,1.5,1.5,1.5Zm.4-1.9l-.8.3.8-.3Zm2.6-15.1c-1.2,3.6-2.1,7.2-2.7,10.9-.1.7-.2,1.4-.4,2-2.1-8.1-1.3-12.3,0-13.1.5-.3,1.4-.5,3.1.2Zm5.6,3.8c-.5.6-2.7,1.7-5.3,2.7.4-1.8.9-3.7,1.5-5.5c1.4.8,2.6,1.7,3.8,2.8Zm.7-8.7c.8.1,1.6.4,2.2.9c2.1,1.5,3,2.9,2.7,4.2s-1.6,2.4-3.4,3.5c0-.3-.1-.5-.2-.8-.1-.2-.3-.4-.5-.6-1.4-1.2-2.9-2.3-4.5-3.2.8-1.9,1.8-3.3,3-3.8.2-.1.4-.2.7-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M280.9,259c-3.6,2.7,15.9,4.5,9.2,18.3-5.5,11.3-20.5,2.3-20.5,2.3s12,16,26,7c19.7-12.8-.3-38.4-14.7-27.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M286.8,290.3c3.3,0,6.6-1,9.3-2.9c6.1-3.9,9.1-9.4,8.7-15.6-.3-3.2-1.4-6.3-3.2-8.9s-4.3-4.8-7.2-6.1c-2.2-1.1-4.7-1.6-7.2-1.3s-4.9,1.2-6.8,2.7c-.3.2-.5.4-.6.7s-.2.6-.2.9c.1,1.2,1.4,1.9,3.4,3c5.5,3,10.1,6.4,6.4,14-.5,1.1-1.2,2.1-2.1,2.9s-2,1.4-3.1,1.8c-6,1.8-13.8-2.8-13.8-2.8l-4.4-2.6l3.1,4.1c-.3.1,7.4,10.1,17.7,10.1Zm1.6-32.9c1.8,0,3.5.4,5.1,1.2c2.6,1.2,4.8,3.1,6.4,5.5c1.6,2.3,2.6,5.1,2.9,7.9.4,5.4-2.4,10.2-7.8,13.7-8.6,5.6-16.6.8-21-3c3,1.1,7,1.9,10.5.8c1.4-.4,2.8-1.2,3.9-2.2s2-2.2,2.6-3.6c4.8-10-3-14.4-7.2-16.7-.8-.4-1.5-.9-2.2-1.4c1.9-1.4,4.3-2.2,6.8-2.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M254.2,278.8c3.2,3.1,1.7-16.4,16.4-12.2c12,3.5,5.8,19.8,5.8,19.8s13.6-14.6,2.4-26.8c-15.9-17.3-37.6,6.8-24.6,19.2Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M273.6,291l3.5-3.8c.1-.1,14.1-15.4,2.4-28.2-4.9-5.3-10.8-7.4-16.8-5.9-3.1.8-5.9,2.5-8.2,4.7-2.3,2.3-3.9,5.1-4.8,8.2-.7,2.4-.7,5-.1,7.4.7,2.4,2,4.6,3.9,6.3.2.2.5.4.8.5s.6.1.9,0c1.2-.3,1.7-1.7,2.4-3.8c2.1-5.9,4.6-11,12.7-8.7c1.2.3,2.3.8,3.2,1.6c1,.8,1.7,1.7,2.3,2.8c2.8,5.6-.4,14-.4,14.1l-1.8,4.8Zm-7.2-36.4c4.1,0,8.2,2,11.6,5.7c4.4,4.7,5.3,10.4,2.8,16.9-.6,1.5-1.3,3-2.2,4.4.5-3.2.7-7.2-1-10.6-.7-1.3-1.6-2.5-2.8-3.4s-2.5-1.6-4-2c-10.6-3.1-13.6,5.4-15.2,10-.3.8-.6,1.6-.9,2.4-1.5-1.4-2.6-3.3-3.2-5.3s-.5-4.2.1-6.2c.8-2.7,2.3-5.2,4.3-7.3c2-2,4.5-3.5,7.3-4.2c1.1-.2,2.2-.4,3.2-.4Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M255.5,264.8c1,.9.5-4.9,4.9-3.6c3.6,1,1.7,5.9,1.7,5.9s4.1-4.3.7-8c-4.7-5.1-11.2,2.1-7.3,5.7Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M259.3,271.6l3.5-3.7c1.6-1.7,3.9-5.9.7-9.4-1.6-1.8-3.7-2.5-5.7-2-1,.3-2,.8-2.8,1.6s-1.3,1.7-1.6,2.8c-.2.8-.2,1.7,0,2.6.2.8.7,1.6,1.3,2.2.1.2.3.3.5.3.2.1.4.1.6,0c.7-.2,1-.9,1.2-1.6.7-1.9,1.2-2.6,2.9-2.1.3.1.5.2.7.3.2.2.4.4.5.6.6,1.1.1,3-.1,3.6l-1.7,4.8Zm-.1-11.6c-2.2,0-3.2,1.8-3.7,3-.2-.6-.2-1.2-.1-1.7.2-.7.6-1.3,1.1-1.8s1.1-.9,1.8-1.1c1.3-.3,2.6.2,3.7,1.4.6.6,1,1.5,1,2.4-.2-.5-.6-.9-1-1.2s-.9-.6-1.4-.7c-.4-.2-.9-.3-1.4-.3v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M254.8,315.2c-.1-2.6,7.8,5.4,11.4-2.6c3-6.6-6.5-9.9-6.5-9.9s11.5-.5,12.6,9c1.7,13.3-17,13.9-17.5,3.5Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M263.2,323.4c2.3,0,4.5-.7,6.3-2c3-2.2,4.4-5.7,3.9-10-1-8.2-9.4-10-13.7-9.9l-5.1.2l4.8,1.7c0,0,4.7,1.7,6.1,4.9.2.6.4,1.2.3,1.9c0,.6-.2,1.3-.5,1.8-1.8,4.1-4.7,3.4-8,2.1-1.3-.5-2.2-.9-3-.3-.2.2-.4.4-.5.6s-.1.5-.1.8c.1,1.5.5,3,1.4,4.2.8,1.3,2,2.3,3.4,2.9c1.5.8,3.1,1.1,4.7,1.1Zm1.5-19.1c2.9.9,6.1,2.9,6.7,7.4.6,4.6-1.4,6.9-3,8.1-1.3.9-2.8,1.4-4.3,1.5-1.6.1-3.1-.1-4.5-.8-.9-.4-1.8-1.1-2.4-1.9s-1-1.8-1.2-2.8c.2.1.5.2.7.3c2.5,1,7.8,3,10.6-3.2.4-.8.6-1.7.7-2.7c0-.9-.1-1.8-.5-2.7-.8-1.1-1.7-2.3-2.8-3.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M259.6,283.6c2.1,2,1.1-11,11-8.2c8.1,2.3,3.9,13.3,3.9,13.3s9.1-9.8,1.6-18c-10.7-11.6-25.2,4.6-16.5,12.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M271.7,293.2l3.5-3.8c.1-.1,9.7-10.6,1.6-19.3-3.3-3.6-7.4-5.1-11.6-4.1-2.1.6-4.1,1.7-5.7,3.2-1.6,1.6-2.7,3.5-3.3,5.6-.5,1.7-.5,3.4,0,5.1s1.4,3.2,2.7,4.4c.2.2.4.3.7.4s.5.1.8,0c1-.2,1.3-1.3,1.9-2.8c1.4-3.8,2.9-7.1,8.1-5.6.7.2,1.4.5,2.1,1c.6.5,1.1,1.1,1.4,1.8c1.8,3.6-.3,9.1-.3,9.2l-1.9,4.9Zm-3.8-25.5c2.2,0,4.8.8,7.5,3.7c2.8,3.1,3.4,6.8,1.8,11-.2.5-.4,1-.6,1.4.2-1.9-.1-3.8-.9-5.6-.5-.9-1.1-1.8-1.9-2.4-.8-.7-1.8-1.1-2.8-1.4-7.4-2.1-9.5,4-10.6,6.9-.1.3-.3.8-.4,1.1-.9-.9-1.5-2.1-1.8-3.3s-.3-2.5.1-3.8c.5-1.8,1.5-3.4,2.8-4.7s2.9-2.2,4.7-2.7c.6-.1,1.3-.2,2.1-.2v0Zm-7.5,15.1l-.7.7.7-.7Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M250.5,325.6c-2.8-1.1,9.5-5.7,2.8-13.4-5.5-6.3-13.7,2.1-13.7,2.1s5-12.4,15.5-9c15.1,4.8,6.6,24.7-4.6,20.3Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M253.7,327.3c2.2,0,4.4-.8,6.1-2.3c1.7-1.4,3-3.3,3.7-5.4.8-2.1.9-4.3.6-6.5-.8-4.2-3.9-7.3-8.6-8.8-11.4-3.6-16.8,9.5-16.8,9.6l-1.9,4.8l3.6-3.7c0,0,4.1-4.1,8-4.1h.2c.8,0,1.5.2,2.2.6s1.3.8,1.8,1.5c3.5,4.1,1.2,6.9-1.7,9.7-1.2,1.1-1.9,1.9-1.7,2.9.1.3.2.5.4.7s.4.3.7.4c1,.3,2.2.6,3.4.6Zm-2-21.6c1.1,0,2.2.2,3.3.5c5.1,1.6,6.7,4.8,7.2,7.3.3,1.8.1,3.7-.5,5.4s-1.7,3.3-3.1,4.5c-.9.8-2.1,1.4-3.3,1.7s-2.5.2-3.7-.2c.2-.3.6-.6.8-.8c2.2-2.2,6.8-6.7,1.8-12.5-.7-.8-1.5-1.5-2.4-1.9-.9-.5-2-.7-3-.8-1.9,0-3.8.5-5.4,1.5.3-.4.7-.8,1-1.2c2.1-2.4,4.6-3.5,7.3-3.5Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M268.9,301.5c1.4,1.4.8-7.3,7.3-5.5c5.4,1.6,2.6,8.9,2.6,8.9s6.1-6.5,1.1-12c-7.2-7.8-16.9,3-11,8.6Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M276,309.3l3.5-3.8c2.3-2.5,5.6-8.5,1.1-13.4-2.3-2.5-5.2-3.5-8.1-2.8-1.5.4-2.8,1.2-3.9,2.3s-1.9,2.4-2.3,3.9c-.3,1.2-.3,2.4,0,3.6s1,2.2,1.9,3.1c.2.2.4.3.6.4s.5.1.7,0c.8-.2,1.1-1,1.5-2.1.9-2.6,1.8-4.4,5-3.5.5.1.9.3,1.2.6.4.3.7.7.9,1.1c1.1,2.3-.2,5.8-.2,5.9l-1.9,4.7Zm-1.6-14.6c-3.6,0-4.8,3.4-5.4,5.1l-.1.2c-.4-.6-.7-1.2-.8-1.9s-.1-1.4.1-2.1c.3-1.1.9-2.2,1.8-3c.8-.8,1.9-1.4,3-1.8c1.5-.4,3.8-.3,6.1,2.2.8.8,1.4,1.9,1.6,3s.2,2.3-.2,3.4c-.1-.8-.3-1.5-.6-2.2s-.8-1.3-1.4-1.7c-.6-.5-1.3-.8-2-1-.7-.1-1.4-.2-2.1-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M260.7,287.8c-2,.1,4.8,5.6-1.1,9.1-4.8,2.8-8.2-4.2-8.2-4.2s.5,8.9,7.9,9.1c10.5.1,9.5-14.4,1.4-14Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M259.5,302.7c3.3,0,5.9-1.4,7.4-3.9.7-1.4,1.1-2.9,1.1-4.4s-.5-3.1-1.2-4.4c-.6-1.1-1.5-1.9-2.6-2.5s-2.3-.8-3.5-.8c-.2,0-.5.1-.7.2s-.4.3-.5.5c-.4.7,0,1.5.5,2.5c1.2,2.4,1.9,4.4-.9,6.1-.4.3-.8.4-1.3.5s-.9,0-1.4-.1c-2.4-.7-4.1-4.2-4.1-4.2l-2.2-4.6.3,5.1c.2,3.4,2.3,9.9,8.9,10h.2Zm2.2-13.9c.7.1,1.3.3,1.9.7s1.1.9,1.4,1.5c.6,1,.9,2.2.9,3.3c0,1.2-.3,2.3-.8,3.4-.8,1.4-2.4,2.9-5.8,2.9-1.2,0-2.3-.3-3.3-.9s-1.8-1.5-2.3-2.5c.6.5,1.3.9,2,1.1s1.5.3,2.2.2s1.5-.4,2.1-.8c4.7-2.8,2.7-6.8,1.7-8.7v-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M279.4,253.7c2-.1-4.7-5.7,1.3-9.1c4.9-2.8,8.1,4.4,8.1,4.4s-.4-8.9-7.8-9.2c-10.5-.3-9.6,14.2-1.6,13.9Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M279.2,254.7h.3c.2,0,.5-.1.7-.2s.4-.3.5-.5c.4-.7,0-1.5-.4-2.5-1.2-2.4-1.9-4.4,1-6c.4-.3.8-.4,1.3-.5s.9,0,1.4.1c2.4.8,4,4.2,4.1,4.3l2.1,4.7-.2-5.1c-.1-3.4-2.2-9.9-8.8-10.2-3.4-.1-6.2,1.2-7.7,3.8-.8,1.3-1.2,2.9-1.2,4.4s.4,3.1,1.2,4.4c.6,1,1.4,1.9,2.5,2.5.8.5,2,.8,3.2.8Zm3.7-11.7c-1,0-1.9.3-2.7.8-4.7,2.7-2.8,6.8-1.8,8.7c0,.1.1.1.1.2-.7-.1-1.3-.4-1.9-.8s-1-.9-1.4-1.5c-.6-1-.9-2.2-.9-3.4s.3-2.3.9-3.4c.8-1.4,2.4-2.9,5.8-2.8c1.2,0,2.3.3,3.3,1c1,.6,1.8,1.5,2.2,2.5-.6-.5-1.3-.9-2-1.1-.5-.1-1.1-.2-1.6-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M268.4,276.2c-2-3.4-4.5,10.6-15.4,2-8.9-7.1-.8-15.9-.8-15.9s-13.7,4.7-7,17.5c9.3,18.1,31.2,10,23.2-3.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M260.1,291.3c4.2,0,8-1.9,9.8-5s1.5-6.8-.7-10.6c-.1-.3-.3-.5-.6-.7-.2-.2-.5-.3-.8-.3-1-.1-1.8.8-2.7,1.9-2.1,2.5-5.1,5.9-11.5.8-2.3-1.8-3.6-4-3.8-6.3-.3-4.3,3-8,3.1-8.1l2.7-2.9-3.8,1.3c-.3.1-6.6,2.3-8.8,7.9-1.3,3.3-.9,7,1.2,11c3.5,6.8,9.3,10.8,15.5,11h.4Zm7.5-14.5c1.8,3.1,2,6.1.6,8.4-1.5,2.6-4.7,4.1-8.4,4-5.5-.1-10.7-3.8-13.8-9.9-1.8-3.5-2.2-6.6-1.1-9.3.9-2,2.4-3.7,4.2-4.9-1,1.9-1.5,4-1.4,6.2.2,2.9,1.8,5.5,4.6,7.7c8,6.3,12.2,1.3,14.3-1.1.3-.4.7-.8,1-1.1v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M280.1,246.2c-1.4,1.5,7.4.5,5.7,7.2-1.4,5.4-8.8,2.9-8.8,2.9s6.7,5.9,12,.7c7.5-7.4-3.6-16.8-8.9-10.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M283.9,260.1c1.1,0,2.1-.2,3.1-.6s1.9-1,2.6-1.8c2.5-2.4,3.4-5.3,2.6-8.1-.4-1.5-1.3-2.8-2.4-3.9s-2.5-1.8-4-2.2c-1.2-.3-2.4-.3-3.6.1s-2.2,1-3,2c-.2.2-.3.4-.3.6-.1.2-.1.5,0,.7.2.8,1.1,1.1,2.1,1.4c2.6.8,4.5,1.7,3.7,4.9-.1.5-.3.9-.6,1.3s-.6.7-1,.9c-2.2,1.2-5.8,0-5.9,0l-4.9-1.7l3.9,3.4c2.2,1.8,4.9,2.9,7.7,3Zm.3-14.8c.4,0,.8,0,1.2.1c1.1.3,2.2.9,3,1.7.9.8,1.5,1.8,1.8,3c.4,1.5.4,3.8-2,6.2-.8.8-1.8,1.4-2.9,1.7s-2.3.2-3.4-.1c.8-.1,1.5-.3,2.2-.7s1.2-.8,1.7-1.4c.4-.6.8-1.3.9-2c1.4-5.3-3-6.7-5-7.3l-.2-.1c.8-.7,1.8-1.1,2.7-1.1v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M232.6,316.7c2-.1-4.7-5.7,1.3-9.1c4.9-2.8,8.1,4.4,8.1,4.4s-.4-8.9-7.8-9.2c-10.5-.3-9.6,14.2-1.6,13.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M232.4,317.7h.3c.2,0,.5-.1.7-.2s.4-.3.5-.5c.4-.7,0-1.5-.4-2.5-1.2-2.4-1.9-4.4,1-6c.4-.3.8-.4,1.3-.5s.9,0,1.4.1c2,.7,3.6,3.3,4.1,4.3l2.2,4.7-.5-5.1c-.1-3.4-2.2-9.9-8.8-10.2-3.4-.1-6.1,1.2-7.7,3.8-.8,1.3-1.2,2.9-1.2,4.4s.4,3.1,1.2,4.4c.6,1,1.4,1.9,2.4,2.5c1.2.5,2.3.8,3.5.8Zm3.7-11.7c-1,0-1.9.3-2.7.8-4.7,2.7-2.8,6.8-1.8,8.7l.1.2c-.7-.1-1.3-.4-1.9-.8s-1-.9-1.4-1.5c-.6-1-.9-2.2-.9-3.4s.3-2.3.9-3.4c.8-1.4,2.4-2.9,5.8-2.8c1.2,0,2.3.4,3.3,1s1.8,1.5,2.3,2.6c-.6-.5-1.3-.9-2-1.1-.6-.2-1.2-.3-1.7-.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M238.8,292.3c1.9-.5,14.3-4.5,12,10.1-.1.7-.3,1.3-.7,1.8-1.3,1.8,1.1,4.1,2.9,2.7c1.7-1.4,2.8-4,2.7-9-.6-20-22.5-4-16.9-5.6Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M251.8,308.4c.7,0,1.3-.2,1.8-.6c2.1-1.7,3.2-5,3-9.9-.2-7.4-3.3-10-5.9-10.8-5.4-1.7-12.3,2.5-13.5,4.2-.2.2-.3.5-.3.8s0,.6.2.8c.5.8,1.6.5,2,.4l.2-.1c4.2-1.2,7.2-1,8.9.5s2.2,4.4,1.6,8.5c-.1.5-.2,1-.5,1.4-.4.6-.6,1.3-.5,2s.4,1.4.9,1.9c.6.6,1.3.9,2.1.9ZM248,288.8c.6,0,1.2.1,1.8.2c3,.8,4.6,3.8,4.7,9c.1,4.2-.7,6.9-2.3,8.2-.2.1-.4.2-.6.2s-.4-.1-.6-.3c-.2-.1-.3-.3-.3-.6c0-.2,0-.5.2-.6.5-.7.8-1.5.9-2.3.8-4.9,0-8.4-2.2-10.4-1.8-1.6-4.6-2.1-8.1-1.6c2-1.1,4.2-1.7,6.5-1.8v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M249.4,293.7c1.9-.5,27-7.2,12,10.1-1.9,2.2-4.7-7.6-4.7-7.6s4.3,21.3,12.6,10.5c7.5-9.9-25.5-11.4-19.9-13Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M264.7,310.8c1.8,0,3.6-1.2,5.3-3.5c1.3-1.7,1.7-3.3,1.1-4.9-.6-1.7-2.4-3.1-4.7-4.2.9-2,1-3.7.2-5-.7-1.1-4-4.3-17.5-.6-.4.1-1.4.4-1.4,1.4c0,1.2,1.2,1.5,5.6,2.4l2.5.5c.6,2.8,3.1,12.5,7.9,13.6.4.2.7.3,1,.3Zm.9-10.7c1.9.9,3.3,1.9,3.7,3.1.3.9.1,1.8-.8,2.9-1.5,2-3,2.9-4.2,2.6s-2.4-1.7-3.4-3.5c.3,0,.5-.1.8-.2.2-.1.4-.3.6-.5c1.2-1.4,2.3-2.9,3.3-4.4Zm-2-.9c-.9,1.3-1.8,2.6-2.9,3.8-.6-.5-1.7-2.8-2.5-5.4c1.9.5,3.7,1,5.4,1.6ZM260,293c2.9,0,4.5.6,5,1.4.3.5.5,1.4-.3,3.1-3.5-1.2-7.1-2.2-10.8-2.9-.7-.1-1.3-.3-1.9-.4c2.5-.8,5.2-1.1,8-1.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M239.9,300.5c.1-2-6,4.3-8.9-1.9-2.4-5.1,4.9-7.8,4.9-7.8s-8.9-.2-9.7,7.2c-1,10.4,13.5,10.5,13.7,2.5Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M233.3,307.2c1.3,0,2.7-.3,3.9-.9c1.1-.5,2-1.4,2.7-2.4s1-2.2,1-3.4c0-.2,0-.5-.1-.7s-.2-.4-.4-.5c-.7-.5-1.5-.1-2.5.3-2.5,1-4.5,1.6-5.9-1.4-.2-.4-.4-.9-.4-1.3c0-.5,0-.9.2-1.4.8-2,3.5-3.4,4.5-3.7l4.8-1.8-5.2-.1c-3.4-.1-10,1.5-10.7,8.1-.3,3.4.8,6.2,3.3,7.9c1.4.8,3.1,1.3,4.8,1.3Zm5.5-5.8c-.2.7-.5,1.3-.9,1.9-.4.5-1,1-1.6,1.3-1.1.5-2.2.7-3.4.7-1.2-.1-2.3-.4-3.3-1.1-1.3-.9-2.7-2.6-2.4-6c.1-1.2.5-2.3,1.2-3.2s1.6-1.7,2.7-2.1c-.5.6-1,1.2-1.3,2-.3.7-.4,1.4-.4,2.2c0,.7.2,1.5.6,2.1c2.4,4.9,6.5,3.2,8.6,2.4l.2-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M232.5,299.3c1.4,1.4,1-7.3,7.5-5.2c5.3,1.7,2.3,9,2.3,9s6.3-6.3,1.5-12c-6.8-8-16.9,2.5-11.3,8.2Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M239.4,307.4l3.6-3.6c2.4-2.4,5.8-8.3,1.5-13.3-2.2-2.6-5.1-3.7-8-3.1-1.5.3-2.9,1.1-4,2.1s-2,2.4-2.4,3.8c-.4,1.2-.4,2.4-.1,3.6s.9,2.3,1.8,3.1c.2.2.4.3.6.4s.5.1.7,0c.8-.2,1.1-1,1.5-2c1-2.5,2-4.4,5.1-3.4.5.1.9.3,1.2.6.4.3.6.7.8,1.1c1.1,2.3-.4,5.8-.4,5.9l-1.9,4.8Zm-1.2-18.1c1.4,0,3.1.5,4.9,2.6.8.9,1.3,1.9,1.5,3.1.2,1.1.1,2.3-.4,3.4c0-.8-.2-1.6-.5-2.3s-.8-1.3-1.3-1.8c-.6-.5-1.2-.8-2-1.1-5.2-1.7-6.8,2.5-7.6,4.5l-.1.2c-.4-.6-.6-1.2-.7-1.9s0-1.4.2-2c.4-1.1,1-2.1,1.9-2.9s1.9-1.4,3.1-1.6c.2-.2.6-.2,1-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M275.7,280.8c-1.7-1.1-12.9-7.7-.4-15.7.6-.4,1.2-.6,1.9-.7c2.2-.2,2.3-3.6.1-3.9-2.1-.4-4.8.6-8.5,4-14.6,13.7,11.9,19.4,6.9,16.3Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M275.8,282.6h.2c.3,0,.6-.1.8-.3s.4-.4.5-.7c.2-.9-.7-1.5-1-1.8l-.2-.1c-3.7-2.3-5.6-4.7-5.6-7s1.8-4.6,5.3-6.9c.4-.3.9-.5,1.4-.5.7-.1,1.4-.4,1.8-1c.5-.5.8-1.2.8-1.9s-.2-1.4-.7-2c-.4-.6-1.1-.9-1.8-1.1-2.7-.5-5.8,1-9.4,4.3-5.4,5.1-5.3,9.1-4.1,11.6c2.3,5,9.7,7.4,12,7.4Zm.6-21.2c.2,0,.5,0,.7.1.2,0,.4.2.6.4.1.2.2.4.2.7c0,.2-.1.4-.2.6s-.4.3-.6.3c-.8.1-1.6.4-2.3.8-4.2,2.7-6.3,5.6-6.3,8.6c0,2.5,1.4,4.8,4.2,7.1-2.4-.9-5.5-2.6-6.8-5.2-1.4-2.8-.2-6,3.6-9.5c2.7-2.6,5-3.9,6.9-3.9v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M247,320.2c-1.7-1.1-23.4-15.4-.4-15.7c2.9,0-2.5,8.6-2.5,8.6s13-17.3-.5-16.3c-12.5.8,8.4,26.5,3.4,23.4Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M247.7,321.4c.3,0,.6-.1.8-.3.9-.8.3-1.9-1.9-5.8l-1.2-2.2c1.7-2.3,7.2-10.6,4.9-14.9-1-1.9-3.3-2.7-6.7-2.5-2.1.2-3.6,1-4.4,2.4-.9,1.6-.7,3.8,0,6.2-2.2.7-3.4,1.7-3.8,3.2-.4,1.2-.6,5.9,11.2,13.5.2.3.6.4,1.1.4Zm-8-15.1c1.4,3.5,3.1,6.8,5,10l1,1.7c-6.9-4.8-8.9-8.5-8.5-9.9.2-.5.7-1.3,2.5-1.8Zm6.8-.8c0,.8-1,3.1-2.3,5.5-.9-1.7-1.7-3.4-2.4-5.1c1.5-.3,3.1-.4,4.7-.4v0Zm-1.8-7.8c1.9,0,3.2.5,3.7,1.4.6,1.1.3,2.9-.4,4.9-.2-.2-.4-.3-.6-.4s-.5-.1-.8-.1c-1.8,0-3.7.2-5.5.4-.6-2-.8-3.7-.2-4.8.4-.8,1.4-1.3,2.7-1.4.4,0,.7,0,1.1,0v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M467.3,309.4c-2.7-3.5-4.4,15.9-18.2,9.3-11.3-5.4-2.5-20.5-2.5-20.5s-15.8,12.2-6.8,26.1c13,19.7,38.4-.5,27.5-14.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M453.4,333.5c.4,0,.8,0,1.2,0c3.2-.3,6.3-1.4,8.9-3.3s4.7-4.4,6.1-7.3c1.1-2.3,1.5-4.8,1.2-7.2-.3-2.5-1.2-4.8-2.8-6.8-.2-.3-.4-.5-.7-.6s-.6-.2-.9-.2c-1.2.1-1.9,1.4-3,3.4-3,5.5-6.3,10.1-13.9,6.5-1.1-.5-2.1-1.2-2.9-2.1s-1.4-2-1.8-3.1c-1.8-6,2.7-13.8,2.7-13.9l2.6-4.5-4.1,3.1c-.2.1-16.5,12.9-7,27.4c3.7,5.5,8.8,8.6,14.4,8.6Zm13.2-23.4c1.3,1.7,2.1,3.7,2.3,5.8s-.2,4.2-1.1,6.1c-1.2,2.6-3.1,4.8-5.4,6.5-2.3,1.6-5,2.7-7.9,3-5.4.4-10.2-2.3-13.8-7.7-3.5-5.4-3.5-11.2,0-17.1.8-1.4,1.8-2.8,2.9-4-1,3-1.8,7-.7,10.6.5,1.4,1.2,2.8,2.2,3.9s2.2,2,3.6,2.6c10,4.7,14.3-3.1,16.6-7.3.4-1,.9-1.7,1.3-2.4v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M447.3,282.8c-3,3.2,16.4,1.6,12.3,16.3-3.4,12.1-19.8,5.9-19.8,5.9s14.7,13.5,26.8,2.2c17.3-16-7-37.5-19.3-24.4Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M455.8,312.8c4.3,0,8.5-1.7,11.6-4.7c5.3-4.9,7.3-10.9,5.8-16.9-.9-3.1-2.5-5.9-4.8-8.2s-5.1-3.9-8.2-4.8c-2.4-.7-4.9-.7-7.3,0s-4.6,2-6.2,3.9c-.2.2-.4.5-.5.8s-.1.6,0,.9c.3,1.2,1.7,1.7,3.9,2.4c5.9,2,11,4.5,8.8,12.7-.3,1.2-.8,2.3-1.6,3.3-.7,1-1.7,1.8-2.8,2.3-5.6,2.8-14-.3-14.1-.3l-4.8-1.8l3.8,3.5c-.1,0,7.5,6.9,16.4,6.9Zm.7-33c1.1,0,2.2.1,3.2.4c2.7.8,5.3,2.2,7.3,4.2s3.5,4.5,4.3,7.3c1.4,5.2-.5,10.5-5.2,14.9s-10.4,5.4-16.9,3c-1.5-.6-3-1.3-4.4-2.2c3.2.5,7.2.6,10.5-1.1c1.3-.7,2.5-1.6,3.4-2.8s1.6-2.5,1.9-4c3-10.6-5.5-13.5-10-15.1-.8-.3-1.6-.6-2.4-.9c1.1-1.1,2.3-2.1,3.8-2.7c1.4-.7,2.9-1,4.5-1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M461.3,284c-.9,1,4.9.5,3.6,4.9-1,3.6-5.9,1.8-5.9,1.8s4.4,4.1,8,.7c5.2-4.9-2-11.3-5.7-7.4Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M463.8,293.6c1.5,0,2.9-.6,3.9-1.6c1.8-1.6,2.4-3.7,1.9-5.7-.3-1-.8-2-1.6-2.8s-1.7-1.3-2.8-1.6c-.8-.2-1.7-.2-2.6,0-.8.2-1.6.7-2.2,1.3-.1.1-.3.3-.3.5-.1.2-.1.4,0,.6.2.7.9,1,1.6,1.2c1.9.6,2.6,1.2,2.1,2.9-.1.3-.2.5-.3.7-.2.2-.4.4-.6.5-1.1.6-3,.1-3.6-.1l-4.8-1.8l3.8,3.5c1.6,1.5,3.5,2.3,5.5,2.4Zm.2-9.8c.3,0,.5,0,.8.1.7.2,1.3.6,1.8,1.1s.9,1.1,1.1,1.8c.3,1.3-.1,2.6-1.4,3.7-.6.6-1.5,1-2.4,1.1.5-.3.9-.6,1.2-1s.6-.9.7-1.4c.9-3.3-1.4-4.6-2.9-5.2.5-.1.8-.2,1.1-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M411,283.6c2.5-.1-5.3,7.9,2.7,11.4c6.6,2.9,9.9-6.6,9.9-6.6s.6,11.5-8.9,12.7c-13.4,1.8-14-16.8-3.7-17.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M413,302.3c.6,0,1.2,0,1.8-.1c8.2-1.1,10-9.5,9.7-13.8l-.3-5.1-1.7,4.9c0,0-1.7,4.7-4.8,6.1-.6.2-1.2.4-1.9.3-.6,0-1.3-.2-1.8-.5-4.1-1.8-3.4-4.7-2.1-8c.5-1.3.9-2.2.3-3-.2-.2-.4-.4-.6-.5s-.5-.1-.8-.1c-1.5.1-3,.6-4.2,1.4-1.2.9-2.2,2-2.9,3.4-.8,1.8-1.1,3.7-1,5.6.2,1.9.8,3.8,2,5.3.9,1.3,2.2,2.3,3.6,3s3.2,1.2,4.7,1.1Zm9-8.8c-.8,2.9-2.8,6.1-7.4,6.7s-6.9-1.3-8.1-3c-.9-1.3-1.4-2.8-1.6-4.3-.1-1.6.1-3.1.8-4.6.4-.9,1-1.8,1.9-2.4.8-.6,1.8-1,2.8-1.2-.1.2-.2.5-.3.7-1,2.5-2.9,7.8,3.2,10.5.8.4,1.7.6,2.7.6.9,0,1.8-.2,2.7-.5c1.2-.5,2.4-1.4,3.3-2.5v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M442.6,288.3c-2,2.2,11,1.1,8.2,10.9-2.3,8.1-13.2,4-13.2,4s9.8,9.1,17.9,1.5c11.6-10.8-4.6-25.2-12.9-16.4Z"
                fill="#dcdbfa"
              ></path>
              <path
                d="M448.3,308.7c3,0,5.8-1.2,8-3.3c3.6-3.4,5-7.5,4-11.6-.6-2.1-1.7-4.1-3.3-5.6-1.6-1.6-3.5-2.7-5.7-3.3-1.7-.5-3.4-.5-5.1,0s-3.2,1.4-4.3,2.7c-.2.2-.3.4-.4.7s-.1.5,0,.8c.2,1,1.3,1.3,2.8,1.8c3.8,1.3,7.1,2.9,5.6,8.1-.2.7-.5,1.5-1,2.1s-1.1,1.1-1.7,1.5c-3.6,1.8-9.1-.2-9.2-.2l-4.8-1.8l3.8,3.5c0-.1,5.2,4.6,11.3,4.6Zm.4-22.1c.7,0,1.4.1,2,.3c1.8.5,3.4,1.4,4.7,2.7s2.3,2.9,2.8,4.7c.6,2.4.5,6-3.4,9.6-3,2.9-6.7,3.5-10.9,1.9-.5-.2-1-.4-1.4-.6c1.9.2,3.8-.1,5.6-.9.9-.5,1.8-1.2,2.4-2s1.1-1.8,1.4-2.8c2.1-7.4-4-9.5-6.9-10.5-.4-.1-.8-.3-1.1-.4c1.2-1.3,3-2,4.8-2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M400.6,279.5c1.1-2.8,5.7,9.5,13.4,2.7c6.3-5.6-2.2-13.7-2.2-13.7s12.4,4.9,9.1,15.6c-4.6,15-24.6,6.6-20.3-4.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M410.9,293.2c.8,0,1.5-.1,2.3-.2c4.2-.8,7.3-3.9,8.7-8.6c3.5-11.4-9.6-16.7-9.7-16.8l-4.8-1.9l3.7,3.6c0,0,4.3,4.2,4.2,8.2c0,.8-.2,1.5-.6,2.2-.3.7-.8,1.3-1.4,1.8-4,3.5-6.9,1.3-9.7-1.6-1.1-1.2-1.9-1.9-2.9-1.7-.3.1-.5.2-.7.4s-.3.4-.4.7c-.6,1.6-.8,3.4-.5,5.1s1,3.3,2.2,4.6c1.2,1.4,2.7,2.5,4.3,3.3c1.7.5,3.5.8,5.3.9Zm4.9-21.2c.4.3.8.7,1.2,1c3.2,3.1,4.2,6.7,3,10.7-1.6,5.1-4.8,6.8-7.2,7.3-1.8.3-3.7.2-5.4-.5-1.7-.6-3.3-1.7-4.5-3.1-.8-.9-1.4-2.1-1.7-3.3s-.2-2.5.2-3.7c.3.2.6.6.8.8c2.2,2.2,6.7,6.8,12.5,1.7.8-.7,1.5-1.5,1.9-2.4.5-.9.7-2,.8-3-.1-2-.6-3.9-1.6-5.5v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M424.9,297.6c-1.4,1.5,7.3.7,5.5,7.3-1.5,5.4-8.8,2.7-8.8,2.7s6.6,6.1,12,1c7.6-7.2-3.2-16.8-8.7-11Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M428.6,311.6c2.1,0,4.1-.8,5.6-2.3c2.5-2.3,3.5-5.2,2.8-8.1-.4-1.5-1.2-2.8-2.3-3.9s-2.4-1.9-3.9-2.3c-1.2-.3-2.4-.3-3.6,0s-2.2,1-3,1.9c-.2.2-.3.4-.4.6s-.1.5,0,.7c.2.8,1,1.1,2.1,1.5c2.6.9,4.4,1.8,3.6,5-.1.5-.3.9-.6,1.3s-.6.7-1.1.9c-2.3,1.2-5.8-.1-5.9-.2l-4.8-1.8l3.8,3.5c1.5,1.4,4.5,3.2,7.7,3.2Zm.3-14.8c.4,0,.9.1,1.3.2c1.1.3,2.2.9,3,1.8.8.8,1.4,1.9,1.7,3c.4,1.5.3,3.8-2.2,6.1-.8.8-1.9,1.4-3,1.6s-2.3.2-3.4-.2c.8-.1,1.5-.3,2.2-.6s1.3-.8,1.7-1.4c.5-.6.8-1.3,1-2c1.5-5.3-2.8-6.7-4.9-7.4l-.2-.1c1-.7,1.9-1,2.8-1v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M429.6,303.4c-.1-2-5.6,4.8-9.1-1.1-2.9-4.8,4.2-8.2,4.2-8.2s-8.9.6-9,8c-.1,10.5,14.4,9.3,13.9,1.3Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M422.9,310.7c1.6,0,3.2-.4,4.6-1.3c1-.6,1.9-1.5,2.5-2.6s.8-2.3.7-3.5c0-.2-.1-.5-.2-.7s-.3-.4-.5-.5c-.7-.4-1.5,0-2.5.5-2.4,1.2-4.4,2-6-.9-.3-.4-.4-.8-.5-1.3s0-.9.1-1.4c.7-2.4,4.1-4.1,4.2-4.1l4.6-2.2-5.1.3c-3.4.2-9.9,2.4-9.9,9c0,3.4,1.4,6.1,3.9,7.6c1.2.8,2.6,1.1,4.1,1.1Zm5.7-6.3c-.1.7-.3,1.3-.7,1.9s-.9,1.1-1.5,1.4c-1,.6-2.2.9-3.3,1-1.2,0-2.3-.2-3.4-.8-1.4-.8-2.9-2.4-2.9-5.8c0-1.2.3-2.3.9-3.3s1.5-1.8,2.5-2.3c-.5.6-.8,1.3-1.1,2-.2.7-.3,1.5-.2,2.2s.4,1.5.8,2.1c2.8,4.7,6.8,2.6,8.7,1.6h.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M472.6,307.9c.1,2,5.7-4.7,9.1,1.2c2.8,4.9-4.3,8.2-4.3,8.2s8.9-.4,9.1-7.9c.2-10.5-14.2-9.6-13.9-1.5Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M472.3,318.5l5.1-.2c3.4-.2,9.9-2.2,10.1-8.9.1-3.4-1.3-6.1-3.8-7.6-1.3-.8-2.9-1.1-4.4-1.1s-3.1.4-4.4,1.2c-1.1.6-1.9,1.5-2.5,2.6s-.8,2.3-.8,3.5c0,.2.1.5.2.7s.3.4.5.5c.7.4,1.5,0,2.5-.5c2.4-1.2,4.4-1.9,6,.9.3.4.4.8.5,1.3s0,.9-.1,1.4c-.8,2.4-4.2,4.1-4.2,4.1l-4.7,2.1Zm7.1-15.8c1.1,0,2.3.3,3.3.8c1.4.8,2.9,2.4,2.8,5.8c0,1.2-.3,2.3-1,3.3s-1.5,1.8-2.5,2.3c.5-.6.9-1.3,1.1-2s.3-1.5.2-2.2-.3-1.5-.7-2.1c-2.7-4.7-6.8-2.7-8.7-1.8l-.2.1c.1-.7.4-1.3.7-1.9.4-.6.9-1,1.5-1.4c1.1-.6,2.3-.9,3.5-.9Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M450.1,297c3.3-2-10.6-4.4-2.1-15.4c7-9,15.8-.9,15.8-.9s-4.8-13.6-17.5-6.9c-18,9.4-9.7,31.3,3.8,23.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M444.3,299.8c2.2-.1,4.4-.7,6.2-1.9.3-.1.5-.3.7-.6s.3-.5.3-.8c.1-1-.8-1.8-1.9-2.7-2.5-2.1-5.9-5-.9-11.5c1.8-2.3,3.9-3.6,6.3-3.8c4.3-.4,8,3,8.1,3l3,2.7-1.4-3.8c-.1-.3-2.4-6.6-7.9-8.7-3.3-1.3-7-.9-11,1.3-6.7,3.5-10.8,9.4-10.9,15.7-.1,4.4,1.9,8.3,5.1,10.2c1.3.5,2.8.9,4.3.9Zm5.1-3.6c-3.1,1.8-6,2-8.4.7-2.6-1.5-4.1-4.7-4.1-8.4.1-5.5,3.8-10.7,9.8-13.9c3.4-1.8,6.6-2.2,9.3-1.2c2,.9,3.8,2.3,5,4.2-1.9-1-4-1.4-6.2-1.3-2.9.2-5.5,1.8-7.7,4.6-6.2,8-1.2,12.3,1.2,14.3.4.3.9.7,1.1,1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M480.1,308.5c-1.5-1.3-.5,7.4-7.1,5.7-5.4-1.4-2.9-8.8-2.9-8.8s-5.8,6.8-.6,12c7.3,7.6,16.6-3.6,10.6-8.9Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M474.8,321c.7,0,1.4-.1,2.1-.3c1.5-.5,2.8-1.3,3.9-2.4s1.8-2.5,2.1-4c.3-1.2.2-2.4-.1-3.6-.4-1.2-1.1-2.2-2-3-.2-.2-.4-.3-.6-.3-.2-.1-.5-.1-.7,0-.8.2-1.1,1.1-1.4,2.1-.8,2.6-1.7,4.5-4.9,3.7-.5-.1-.9-.3-1.3-.5-.4-.3-.7-.6-.9-1-1-1.9-.4-4.9,0-5.9l1.7-4.9-3.4,3.9c-2.2,2.6-5.2,8.7-.6,13.4.8.8,1.7,1.5,2.7,2c1.1.5,2.2.8,3.4.8Zm5.2-11.1c.4.5.7,1.2.9,1.9.1.7.1,1.4,0,2.1-.3,1.1-.8,2.2-1.6,3.1s-1.8,1.5-2.9,1.9c-1.5.4-3.8.4-6.2-2-.8-.8-1.4-1.8-1.7-3-.3-1.1-.2-2.3.1-3.4.1.8.3,1.5.7,2.2s.9,1.2,1.5,1.7c.6.4,1.3.8,2,.9c5.3,1.3,6.6-3,7.3-5.1l-.1-.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M409.4,261.4c.1,2,5.7-4.7,9.1,1.2c2.8,4.9-4.3,8.2-4.3,8.2s8.9-.4,9.1-7.9c.2-10.5-14.3-9.5-13.9-1.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M409,272.1l5.1-.3c3.4-.2,9.9-2.2,10.1-8.9.1-3.4-1.3-6.1-3.8-7.6-1.3-.8-2.9-1.1-4.4-1.1s-3.1.4-4.4,1.2c-1.1.6-1.9,1.5-2.5,2.6s-.9,2.3-.8,3.5c0,.2.1.5.2.7s.3.4.5.5c.7.4,1.5,0,2.5-.5c2.4-1.2,4.4-1.9,6,.9.3.4.4.8.5,1.3s0,.9-.1,1.4c-.8,2.4-4.2,4.1-4.2,4.1l-4.7,2.2Zm7.1-15.9c1.1,0,2.3.3,3.3.8c1.4.8,2.9,2.4,2.8,5.8c0,1.2-.3,2.3-1,3.3-.6,1-1.5,1.8-2.5,2.3.5-.6.9-1.3,1.1-2s.3-1.5.2-2.2-.3-1.5-.7-2.1c-2.7-4.7-6.8-2.7-8.7-1.8l-.2.1c.1-.7.4-1.3.7-1.9.4-.6.9-1,1.5-1.4c1.1-.6,2.3-.9,3.5-.9Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M433.8,267.5c.5,1.9,4.6,14.3-10,12.1-.7-.1-1.3-.3-1.8-.7-1.8-1.3-4.1,1.1-2.7,2.9c1.4,1.7,4,2.8,9,2.6c20-.7,3.9-22.5,5.5-16.9Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M427.3,285.4h1c7.4-.2,10-3.4,10.7-6c1.6-5.4-2.6-12.3-4.3-13.5-.2-.2-.5-.3-.8-.3s-.6,0-.8.2c-.8.5-.5,1.6-.4,2l.1.2c1.2,4.2,1.1,7.2-.4,9-1.5,1.7-4.3,2.2-8.5,1.6-.5-.1-1-.2-1.4-.5-.6-.4-1.3-.6-2-.5s-1.4.4-1.8,1c-.5.5-.8,1.2-.8,1.9s.2,1.4.7,2c1.4,1.9,4.4,2.9,8.7,2.9Zm8.2-15.2c1.2,2.3,2.4,5.6,1.7,8.4-.8,3-3.8,4.6-8.9,4.8-4.2.1-6.9-.6-8.2-2.2-.1-.2-.2-.4-.2-.6s.1-.4.3-.6c.1-.2.3-.3.6-.3.2,0,.5,0,.6.2.7.5,1.5.8,2.3.9c4.9.7,8.4,0,10.3-2.3c1.6-2,2.1-4.8,1.5-8.3v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M432.5,278.2c.5,1.9,7.4,27.1-10,12.1-2.2-1.9,7.5-4.7,7.5-4.7s-21.2,4.4-10.3,12.6c9.9,7.4,11.2-25.7,12.8-20Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M422.4,300.2c.5,0,1-.1,1.5-.3c1.7-.6,3-2.4,4.1-4.7c2.1.9,3.7,1,5,.1c1.1-.7,4.3-4,.4-17.5l-1,.3l1-.3c-.1-.4-.4-1.4-1.4-1.4-1.2,0-1.4,1.2-2.4,5.7-.2.7-.3,1.6-.5,2.5-2.8.7-12.4,3.2-13.5,8-.5,2.1.7,4.2,3.4,6.3c1,.8,2.2,1.3,3.4,1.3Zm3.8-5.9c-.9,1.9-1.9,3.3-3,3.7-.9.3-1.8.1-2.9-.8-2-1.5-2.9-3-2.6-4.2s1.6-2.4,3.5-3.4c0,.3.1.5.2.8.1.2.3.4.5.6c1.3,1.3,2.8,2.4,4.3,3.3Zm5.9-13.7c2,8.2,1.1,12.3-.2,13.1-.5.3-1.4.5-3.1-.3c1.2-3.5,2.2-7.2,2.9-10.8.1-.7.2-1.3.4-2Zm-3.5,6.4c-.4,1.8-1,3.6-1.6,5.4-1.3-.9-2.6-1.8-3.8-2.9.6-.6,2.8-1.6,5.4-2.5Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M425.6,268.6c2,.1-4.3-6,1.8-9c5-2.5,7.9,4.8,7.9,4.8s.2-8.9-7.2-9.6c-10.5-.9-10.5,13.6-2.5,13.8Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M436.2,269.7l.1-5.1c.1-3.4-1.6-10-8.2-10.7-3.4-.3-6.2.9-7.9,3.3-.8,1.3-1.3,2.8-1.4,4.3s.2,3.1.9,4.5c.5,1.1,1.4,2,2.4,2.7c1,.6,2.2,1,3.4,1c.2,0,.5,0,.7-.1s.4-.3.5-.4c.5-.7.1-1.5-.3-2.5-1-2.5-1.6-4.5,1.4-6c.4-.2.9-.4,1.3-.4.5,0,.9,0,1.4.2c2.4.9,3.8,4.5,3.8,4.5l1.9,4.7Zm-6.8-11.5c-.9,0-1.7.2-2.5.6-4.9,2.4-3.2,6.6-2.3,8.6c0,.1.1.1.1.2-.7-.1-1.3-.4-1.9-.9-.5-.4-1-1-1.3-1.6-.5-1.1-.8-2.2-.7-3.4s.4-2.3,1.1-3.3c.9-1.3,2.6-2.8,6-2.4c1.2.1,2.3.5,3.2,1.2s1.7,1.6,2.1,2.7c-.6-.5-1.2-.9-1.9-1.2-.6-.4-1.2-.5-1.9-.5v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M426.7,261.2c-1.4,1.4,7.3,1,5.2,7.5-1.7,5.3-8.9,2.3-8.9,2.3s6.3,6.3,11.9,1.4c8-6.8-2.5-16.8-8.2-11.2Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M430.3,275.4c2,0,3.9-.7,5.4-2.1c2.6-2.3,3.7-5.1,3-8-.4-1.5-1.1-2.9-2.1-4-1.1-1.1-2.4-2-3.8-2.4-1.2-.4-2.4-.4-3.6-.1s-2.3.9-3.1,1.8c-.2.2-.3.4-.4.6s-.1.5,0,.7c.2.8,1,1.1,2,1.5c2.5,1,4.4,2,3.4,5.1-.1.5-.3.9-.6,1.2-.3.4-.7.6-1.1.9-2.3,1.1-5.8-.3-5.9-.4l-4.7-1.9l3.7,3.6c1.4,1.5,4.4,3.5,7.8,3.5Zm.4-14.8c.5,0,1,.1,1.4.2c1.1.4,2.1,1,2.9,1.9s1.4,1.9,1.7,3.1c.3,1.5.2,3.8-2.4,6-.9.8-1.9,1.3-3.1,1.5-1.1.2-2.3.1-3.4-.3.8,0,1.5-.2,2.3-.6.7-.3,1.3-.8,1.8-1.3.5-.6.8-1.2,1-2c1.7-5.2-2.6-6.8-4.6-7.6l-.2-.1c.8-.5,1.7-.8,2.6-.8Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M445.6,304.4c1.1-1.7,7.6-13,15.7-.6.4.6.6,1.2.7,1.8.2,2.2,3.6,2.3,3.9.1.4-2.1-.6-4.8-4.1-8.5-13.8-14.5-19.4,12.1-16.2,7.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M463.9,308.4c.7,0,1.4-.2,1.9-.7s.9-1.1,1-1.8c.5-2.7-1-5.8-4.3-9.3-5.1-5.4-9.1-5.2-11.6-4.1-5.1,2.3-7.4,10.2-7.2,12.2c0,.3.1.6.3.8s.4.4.7.5c.9.2,1.5-.7,1.8-1.1l.1-.2c2.3-3.7,4.7-5.6,7-5.6v0c2.2,0,4.5,1.8,6.8,5.3.3.4.5.9.5,1.4.1.7.4,1.4.9,1.9s1.2.7,1.9.7h.2Zm-10-14.5c2.2,0,4.6,1.4,7.2,4.1c2.9,3,4.2,5.6,3.8,7.6c0,.2-.2.4-.4.6-.2.1-.4.2-.6.2s-.4-.1-.6-.2-.3-.4-.3-.6c-.1-.8-.4-1.6-.9-2.3-2.7-4.2-5.6-6.3-8.6-6.2-2.5,0-4.8,1.5-7.1,4.3.9-2.4,2.6-5.6,5.1-6.9.8-.4,1.6-.6,2.4-.6Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M406,275.9c1.1-1.7,15.3-23.5,15.7-.5.1,3-8.6-2.5-8.6-2.5s17.4,12.9,16.3-.7c-1-12.4-26.5,8.7-23.4,3.7Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M425.5,279.5c.9,0,1.8-.2,2.6-.6c1.9-1,2.7-3.3,2.4-6.7-.2-2.1-1-3.6-2.4-4.3-1.6-.8-3.8-.7-6.2,0-.7-2.2-1.8-3.4-3.2-3.8-1.2-.3-5.8-.6-13.4,11.3-.2.3-.8,1.3-.1,2c.8.9,1.9.3,5.8-2l2.2-1.2c1.8,1.3,7.8,5.3,12.3,5.3Zm-.2-10.2c.6,0,1.2.1,1.7.4.8.4,1.3,1.3,1.4,2.7.2,2.5-.3,4.1-1.4,4.8-1.1.6-2.9.4-4.9-.3.2-.2.3-.4.4-.6s.1-.5.1-.8c0-1.8-.2-3.7-.5-5.5c1.1-.5,2.2-.7,3.2-.7Zm-4.6,5.9c-.8,0-3.1-.9-5.5-2.3c1.7-.9,3.4-1.7,5.1-2.4.2,1.6.4,3.2.4,4.7Zm-.9-6.7c-3.4,1.4-6.8,3.1-10,5.1l-1.7,1c4.7-6.9,8.5-9,9.9-8.6.5.2,1.3.8,1.8,2.5v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M338.5,301.2c-.4,4.4,13.9-9,19.8,5.2c4.8,11.6-11.8,17-11.8,17s19.9,1.5,22.4-14.9c3.5-23.4-28.9-25.3-30.4-7.3Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M347.6,324.4c4.5,0,20.1-1.1,22.3-15.8c1.1-7.1-1-13.1-5.9-16.8-2.6-1.9-5.6-3.1-8.9-3.5-3.2-.4-6.4.1-9.4,1.4-2.3,1-4.3,2.5-5.8,4.6-1.5,2-2.3,4.4-2.5,6.9-.1.3,0,.6.1.9s.3.6.5.8c1,.7,2.4.2,4.5-.5c5.9-2.1,11.5-3.3,14.7,4.5.5,1.1.8,2.3.8,3.5s-.2,2.4-.7,3.5c-2.6,5.7-11.2,8.5-11.2,8.5l-4.9,1.6l5.1.4c.1-.1.6,0,1.3,0Zm5.4-34.2c3.5,0,6.9,1.1,9.8,3.1c4.3,3.3,6.1,8.6,5.1,14.9-.9,6.3-4.8,10.7-11.4,12.8-1.6.5-3.2.8-4.8,1c2.8-1.6,6-4,7.6-7.4.6-1.4.9-2.8.9-4.3s-.3-3-.9-4.3c-4.3-10.2-12.7-7.2-17.3-5.6-.8.3-1.6.6-2.4.8.2-2.1.9-4.1,2.1-5.8s2.9-3,4.9-3.8c2-1,4.2-1.5,6.4-1.4Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M335.8,334.3c4.4-.4-11.2-12.2,1.7-20.4c10.6-6.8,18.7,8.8,18.7,8.8s-1.9-19.9-18.5-19.5c-23.6.5-19.8,32.7-1.9,31.1Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M334.5,335.4c.5,0,.9,0,1.4-.1.3,0,.6-.1.9-.2.3-.2.5-.4.7-.7.6-1.1-.2-2.4-1.3-4.4-3.1-5.5-5.2-10.7,1.9-15.3c1-.7,2.1-1.2,3.3-1.4s2.4-.2,3.6.1c6,1.6,10.3,9.6,10.3,9.6l2.4,4.6-.5-5.1c0-.2-2.2-20.5-19.1-20.5h-.5c-7.2.2-12.7,3.2-15.5,8.7-1.4,2.9-2.1,6.1-1.9,9.3s1.2,6.3,3,9c1.2,1.9,2.9,3.5,4.9,4.6c1.9,1.3,4.1,1.8,6.4,1.8Zm1-2.1c-2.1.2-4.2-.2-6.1-1.1s-3.5-2.4-4.6-4.1c-1.5-2.4-2.4-5.2-2.6-8-.2-2.9.4-5.7,1.7-8.3c2.5-4.8,7.4-7.4,13.8-7.6s11.3,2.9,14.5,9c.8,1.5,1.4,3,1.9,4.6-2-2.5-5-5.3-8.6-6.2-1.5-.4-3-.4-4.4-.2-1.5.3-2.9.8-4.1,1.7-9.3,6-4.9,13.8-2.6,18c.4.7.8,1.5,1.1,2.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M326.1,324.1c1.3-.1-3.3-3.6.5-6.1c3.1-2,5.6,2.6,5.6,2.6s-.6-5.9-5.5-5.8c-7,.2-5.9,9.8-.6,9.3Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M333.7,325.7l-.5-5.2c-.2-2.3-1.9-6.7-6.4-6.7h-.2c-2.4,0-4.3,1.1-5.2,3-.5,1-.7,2.1-.6,3.1.1,1.1.4,2.1,1,3c.5.7,1.1,1.3,1.9,1.7s1.7.5,2.5.4c.2,0,.4-.1.6-.2s.3-.3.4-.5c.3-.7,0-1.3-.4-2-1-1.7-1.1-2.6.3-3.6.2-.2.5-.3.7-.3.3,0,.5,0,.8,0c1.2.3,2.3,2,2.6,2.5l2.5,4.8Zm-5.4-9.1c-.8,0-1.5.2-2.2.7-2.9,1.9-2,4.3-1.3,5.7-.5-.2-1-.6-1.3-1.1-.4-.6-.6-1.3-.7-2c0-.7.1-1.4.4-2.1.6-1.2,1.8-1.8,3.5-1.9.9,0,1.8.2,2.5.8-.3-.1-.6-.1-.9-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M363.4,358c-2-1.6,9.2-2.3,5.6-10.3-3-6.6-11.7-1.7-11.7-1.7s7.2-9,15-3.6c11.2,7.6-.8,22-8.9,15.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M367.9,360.6c1.2,0,2.3-.2,3.4-.7c1.8-.8,3.3-2,4.4-3.6s1.9-3.4,2.1-5.3c.4-3.7-1.4-7.1-4.9-9.5-6.8-4.7-13.7.4-16.4,3.8l-3.2,4l4.5-2.5c0,0,4.4-2.4,7.7-1.4.6.2,1.2.5,1.6,1c.5.4.8,1,1.1,1.6c1.8,4.1-.6,5.8-3.7,7.4-1.2.7-2.1,1.1-2.2,2c0,.3,0,.5.1.7s.3.4.5.6c1.4,1.2,3.2,1.9,5,1.9Zm-.9-18.9c1.7,0,3.4.6,4.7,1.6c3.7,2.6,4.3,5.6,4.1,7.6-.2,1.6-.8,3-1.7,4.3s-2.2,2.3-3.6,2.9c-.9.4-2,.6-3,.5s-2-.4-2.9-1c.2-.1.5-.3.7-.4c2.4-1.3,7.4-3.9,4.6-10-.3-.9-.9-1.6-1.5-2.3-.7-.6-1.5-1.1-2.3-1.4-1.4-.4-2.8-.5-4.3-.2c1.5-1,3.3-1.6,5.2-1.6Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M342.9,333.4c3-.3-7.5-8.1,1.1-13.6c7.1-4.5,12.5,5.9,12.5,5.9s-1.3-13.3-12.4-13.1c-15.7.3-13.2,21.9-1.2,20.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M342.1,334.5c.3,0,.6,0,.9,0s.5-.1.8-.2c.2-.1.4-.3.5-.6.5-.9-.1-1.8-.9-3.2-2-3.5-3.4-6.9,1.1-9.8.6-.4,1.3-.7,2.1-.9.8-.1,1.5-.1,2.3.1c3.9,1,6.7,6.2,6.7,6.3l2.4,4.6-.5-5.1c0-.1-1.5-14-13.1-14h-.3c-4.9.1-8.7,2.2-10.7,6-1,2-1.4,4.2-1.3,6.4s.8,4.4,2,6.2c.8,1.3,2,2.4,3.4,3.2c1.5.6,3,1,4.6,1Zm.2-2.1c-1.3,0-2.5-.3-3.6-.8-1.1-.6-2.1-1.5-2.7-2.5-1-1.6-1.6-3.3-1.7-5.2-.1-1.8.3-3.7,1.1-5.4c1.1-2.2,3.6-4.8,8.9-4.9c4.2-.1,7.3,1.9,9.4,5.9.2.5.5,1,.7,1.4-1.3-1.4-2.9-2.4-4.8-3-1-.3-2.1-.3-3.1-.1s-2,.6-2.9,1.2c-6.5,4.2-3.3,9.8-1.8,12.5.1.2.3.6.5.9Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M368.4,368c-2.6,1.4,2-10.9-8.2-10.9-8.4-.1-7.5,11.6-7.5,11.6s-6-12,3.5-17.7c13.6-8.1,22.9,11.5,12.2,17Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M354.1,373.8l-.4-5.1c0-.1-.4-5.9,2.3-8.9.5-.6,1.2-1,1.9-1.3s1.5-.4,2.2-.4c5.3,0,6,3.6,6.2,7.7.1,1.6.1,2.7,1,3.2.2.1.5.2.8.2s.5-.1.8-.2c1.6-.8,2.9-2,3.8-3.4.9-1.5,1.4-3.2,1.4-4.9c0-2.2-.5-4.4-1.6-6.3s-2.6-3.6-4.5-4.7c-3.7-2.1-8-1.9-12.2.6-10.2,6.1-4,18.9-3.9,19l2.2,4.5Zm8.5-23.7c1.5,0,3,.4,4.4,1.2c1.6,1,2.9,2.3,3.8,4c.9,1.6,1.3,3.4,1.3,5.3c0,1.3-.3,2.5-1,3.6-.6,1.1-1.5,2-2.6,2.7c0-.4-.1-.8-.1-1.2-.2-3.1-.5-9.5-8.2-9.6h-.1c-1,0-2.1.1-3,.5s-1.8,1-2.5,1.8c-1.2,1.4-2.1,3.2-2.4,5.1-.1-.5-.2-1-.2-1.6-.4-4.5,1.3-7.8,4.9-10c1.6-1.1,3.6-1.7,5.7-1.8v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M362.4,338.3c2-.2-5-5.4.8-9.1c4.7-3,8.4,3.9,8.4,3.9s-.9-8.9-8.3-8.7c-10.6.2-8.9,14.6-.9,13.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M361.9,339.3c.2,0,.4,0,.7,0c.2,0,.5-.1.7-.2s.4-.3.5-.5c.4-.7,0-1.5-.6-2.5-1.4-2.4-2.1-4.3.7-6.1.4-.3.8-.5,1.3-.5s.9-.1,1.4,0c2.5.7,4.2,4,4.3,4.1l2.4,4.6-.5-5.1c-.3-3.4-2.7-9.8-9.3-9.7-3.4.1-6.1,1.6-7.4,4.2-.7,1.4-1,2.9-.9,4.5.1,1.5.6,3,1.4,4.3.6.9,1.4,1.6,2.4,2.2.7.5,1.8.7,2.9.7Zm3.7-11.9c-1.1,0-2.1.3-3,.9-4.6,2.9-2.4,6.9-1.4,8.8l.1.2c-.7-.1-1.3-.3-1.9-.7s-1.1-.9-1.4-1.4c-.6-1-1-2.1-1.1-3.3s.2-2.3.7-3.4c.7-1.4,2.3-3,5.7-3.1c1.2,0,2.3.2,3.3.8s1.8,1.4,2.4,2.4c-.6-.5-1.3-.8-2.1-1-.4-.1-.9-.2-1.3-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M362.7,330.8c-1.2,1.6,7.4.1,6.1,6.8-1.1,5.5-8.6,3.4-8.6,3.4s7,5.5,12,0c7-7.7-4.5-16.5-9.5-10.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M366.8,344.5c1.2,0,2.3-.2,3.3-.7c1.1-.5,2-1.2,2.7-2c2.3-2.5,3.1-5.5,2.1-8.3-.5-1.4-1.4-2.7-2.6-3.7s-2.6-1.7-4.1-2c-1.2-.2-2.4-.1-3.6.3-1.1.4-2.1,1.2-2.9,2.1-.2.2-.3.4-.3.6s0,.5,0,.7c.3.8,1.1,1,2.2,1.3c2.6.7,4.6,1.4,3.9,4.7-.1.5-.2.9-.5,1.3s-.6.7-1,1c-2.2,1.4-5.8.3-5.9.3l-4.9-1.4l4.1,3.2c2.3,1.5,4.9,2.5,7.5,2.6Zm.3-14.8c.3,0,.6,0,.9.1c1.2.2,2.2.7,3.1,1.5s1.6,1.7,2,2.8c.5,1.5.6,3.7-1.7,6.3-.8.9-1.7,1.5-2.8,1.9-1.1.3-2.3.4-3.4.1.8-.1,1.5-.4,2.2-.8.6-.4,1.2-.9,1.6-1.5s.7-1.3.8-2.1c1.1-5.4-3.3-6.5-5.4-7l-.2-.1c.7-.9,1.8-1.3,2.9-1.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M333.6,298.8c1.3-1.5-7.4-.2-6-6.9c1.2-5.5,8.6-3.2,8.6-3.2s-6.9-5.6-12-.2c-7.2,7.6,4.3,16.5,9.4,10.3Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M329.3,301.9c1,0,2-.2,2.8-.6.9-.4,1.7-1,2.3-1.8.2-.2.3-.4.3-.6.1-.2,0-.5,0-.7-.3-.8-1.1-1-2.2-1.4-2.6-.7-4.6-1.5-3.9-4.7.1-.5.2-.9.5-1.3s.6-.7,1-1c2.2-1.3,5.8-.2,5.9-.2l4.9,1.5-4-3.2c-2.7-2.1-8.9-4.9-13.4-.1-2.3,2.5-3.1,5.4-2.2,8.3.5,1.5,1.4,2.8,2.5,3.8c1.2,1,2.6,1.7,4.1,2c.5-.1.9,0,1.4,0Zm2.9-3.1c-.5.4-1.2.8-1.8.9-.7.2-1.4.2-2.1,0-1.1-.2-2.2-.8-3.1-1.5-.9-.8-1.6-1.8-1.9-2.9-.5-1.5-.6-3.8,1.8-6.2.8-.9,1.8-1.5,2.9-1.8s2.3-.3,3.4,0c-.8.1-1.5.4-2.2.8-.6.4-1.2.9-1.6,1.5s-.7,1.3-.8,2.1c-1.1,5.4,3.2,6.5,5.3,7.1h.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M343.2,322c-3.8-.7,5,10.4-8.7,12.9-11.2,2-12.4-9.8-12.4-9.8s-5.5,13.4,8.4,16.8c19.7,4.8,28.2-17,12.7-19.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M336,343.5c5.1,0,9.7-1.8,12.8-5.2c3-3.2,4.1-7.4,3-11-1.1-3.4-4.1-5.6-8.4-6.5-.3-.1-.6-.1-.9,0s-.6.2-.8.4c-.8.7-.6,1.9-.4,3.3.5,3.3,1.1,7.7-7,9.2-2.9.5-5.3.1-7.2-1.3-3.4-2.6-4-7.6-4-7.6l-.4-4-1.5,3.7c-.1.3-2.6,6.5.1,11.8c1.6,3.2,4.6,5.3,9,6.4c1.8.6,3.7.8,5.7.8Zm7.1-20.5c3.5.7,5.9,2.5,6.7,5.1.9,2.8,0,6.2-2.5,8.9-3.7,4.1-9.9,5.5-16.6,3.8-3.8-.9-6.4-2.7-7.7-5.3-.9-2-1.3-4.2-.9-6.4.8,2,2.1,3.8,3.8,5.1c2.3,1.8,5.3,2.3,8.8,1.7c10-1.8,9.1-8.3,8.6-11.5-.1-.5-.2-1.1-.2-1.4v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M328.5,293.4c.2,2,5.3-5.2,9.1.5c3.2,4.6-3.6,8.5-3.6,8.5s8.9-1.1,8.5-8.6c-.7-10.6-15-8.4-14-.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M329,304l5.1-.7c3.4-.4,9.7-3,9.3-9.6-.2-3.4-1.8-6-4.4-7.3-1.4-.6-2.9-.9-4.5-.8-1.5.1-3,.7-4.3,1.6-1,.7-1.8,1.6-2.3,2.8s-.7,2.3-.5,3.5c0,.2.1.5.2.7s.3.4.5.5c.8.4,1.5-.1,2.5-.7c2.3-1.4,4.2-2.3,6.1.5.3.4.5.8.6,1.3s.1.9,0,1.4c-.6,2.5-3.9,4.4-3.9,4.4L329,304Zm6.3-16.4c1,0,1.9.2,2.8.6c1.4.7,3.1,2.2,3.3,5.6.1,1.2-.2,2.3-.7,3.3s-1.3,1.9-2.3,2.5c.4-.6.8-1.4,1-2.1s.2-1.5,0-2.2-.5-1.4-.9-2c-3.1-4.5-7-2.2-8.8-1.1l-.2.1c.1-.7.3-1.4.6-2c.4-.6.8-1.1,1.4-1.5c1.1-.8,2.5-1.2,3.8-1.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M349.9,375.6c1.3-1.5-7.4-.2-6-6.9c1.2-5.5,8.7-3.2,8.7-3.2s-6.9-5.6-12-.2c-7.3,7.6,4.2,16.6,9.3,10.3Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M345.5,378.7c1,0,2-.2,2.8-.6.9-.4,1.7-1,2.3-1.8.2-.2.3-.4.3-.6s0-.5,0-.7c-.2-.8-1.1-1-2.2-1.4-2.6-.7-4.6-1.5-3.9-4.7.1-.5.2-.9.5-1.3s.6-.7,1-1c2.2-1.3,5.8-.2,5.9-.2l4.9,1.5-4-3.2c-2.7-2.1-8.9-4.9-13.4-.1-2.4,2.5-3.1,5.4-2.2,8.3.5,1.5,1.4,2.8,2.5,3.8c1.2,1,2.6,1.7,4.1,2c.5-.1,1,0,1.4,0Zm2.9-3.1c-.5.4-1.2.8-1.8.9-.7.2-1.4.2-2.1,0-1.1-.2-2.2-.8-3.1-1.5-.9-.8-1.6-1.8-1.9-2.9-.5-1.5-.6-3.8,1.8-6.2.8-.9,1.8-1.5,2.9-1.8s2.3-.3,3.4,0c-.8.1-1.5.4-2.2.8-.6.4-1.2.9-1.6,1.5s-.7,1.3-.8,2.1c-1.1,5.4,3.2,6.5,5.3,7.1h.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M335.7,354.8c.9-1.8,6-13.7,15.5-2.3.4.5.7,1.1.9,1.8.5,2.2,3.8,1.9,3.9-.4.1-2.1-1.2-4.7-5-8-15.3-12.9-17.8,14.2-15.3,8.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M354,356.7h.3c.7,0,1.4-.4,1.9-.9s.8-1.2.8-1.9c.1-2.7-1.7-5.7-5.4-8.8-5.7-4.8-9.7-4.1-12-2.7-4.8,2.9-6.2,10.9-5.8,12.9c0,.3.2.6.4.8s.5.3.8.4c1,.1,1.4-.9,1.6-1.3l.1-.2c1.9-3.9,4-6.1,6.3-6.4s4.7,1.2,7.4,4.5c.3.4.6.9.7,1.4.1.7.5,1.2,1.1,1.7.5.3,1.1.5,1.8.5Zm-10.6-13.3c2,0,4.3,1.1,7,3.3c3.2,2.7,4.7,5.1,4.7,7.1c0,.2-.1.5-.3.6-.2.2-.4.3-.6.3s-.5,0-.6-.1c-.2-.1-.3-.3-.4-.5-.2-.8-.6-1.6-1.1-2.2-3.2-3.8-6.2-5.6-9.2-5.2-2.4.3-4.6,2-6.5,5.1.6-2.5,1.9-5.8,4.3-7.4.6-.7,1.7-1,2.7-1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M343.8,347.7c.9-1.8,12.5-25.1,15.5-2.3.4,2.9-8.8-1.5-8.8-1.5s18.7,10.9,16.1-2.5c-2.4-12.2-25.4,11.6-22.8,6.3Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M343.7,349.5c.8,0,2-.9,4.8-2.9.6-.5,1.3-1,2-1.5c2.5,1.4,11.4,5.9,15.4,3c1.7-1.2,2.3-3.6,1.6-6.9-.4-2.1-1.4-3.4-2.9-4.1-1.7-.7-3.9-.2-6.2.8-.9-2-2.1-3.2-3.6-3.4-1.3-.2-5.9.1-12,12.8-.2.3-.7,1.4.1,2c.3.1.6.2.8.2Zm19.1-10.6c.4,0,.8.1,1.1.2.9.3,1.4,1.2,1.7,2.5.5,2.5.2,4.2-.8,4.9s-2.8.7-4.9.2c.2-.2.3-.4.4-.7.1-.2.1-.5,0-.8-.2-1.8-.6-3.6-1.1-5.4c1.1-.5,2.3-.8,3.6-.9Zm-6.1-.1c-3.3,1.8-6.4,3.9-9.3,6.2-.5.4-1.1.8-1.6,1.2c3.9-7.4,7.4-9.9,8.8-9.6.5,0,1.3.5,2.1,2.2v0Zm1.6,6.6c-.8.1-3.2-.6-5.7-1.7c1.5-1.1,3.2-2.1,4.8-3c.4,1.6.7,3.1.9,4.7v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M342.6,359.4c-1.4-1.4-.7,7.4-7.3,5.5-5.4-1.5-2.6-8.9-2.6-8.9s-6.1,6.6-1,12c7.1,7.8,16.7-3.1,10.9-8.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M337.1,371.7c.6,0,1.2-.1,1.8-.2c1.5-.4,2.8-1.2,3.9-2.3s1.9-2.4,2.3-3.9c.3-1.2.3-2.4,0-3.6s-1-2.2-1.9-3c-.2-.2-.4-.3-.6-.4s-.5-.1-.7,0c-.8.2-1.1,1-1.5,2.1-.9,2.6-1.8,4.4-5,3.5-.5-.1-.9-.3-1.3-.6s-.7-.6-.9-1.1c-1-1.9-.2-4.9.2-5.9l1.8-4.9-3.5,3.8c-2.3,2.5-5.5,8.5-1,13.4.8.9,1.7,1.6,2.8,2.2c1.2.6,2.4.9,3.6.9Zm5.4-10.9c.4.6.7,1.2.8,1.9s.1,1.4-.1,2c-.3,1.1-.9,2.2-1.7,3s-1.9,1.4-3,1.7c-1.5.4-3.8.3-6.1-2.2-.8-.8-1.4-1.9-1.6-3s-.2-2.3.2-3.4c.1.8.3,1.5.6,2.2s.8,1.3,1.4,1.7c.6.5,1.3.8,2,1c5.3,1.5,6.8-2.8,7.4-4.8l.1-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M336.8,364.2c2-.1-4.8-5.6,1.1-9.1c4.8-2.9,8.2,4.2,8.2,4.2s-.5-8.9-8-9c-10.5-.2-9.3,14.3-1.3,13.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M336.5,365.2h.4c.2,0,.5-.1.7-.2s.4-.3.5-.5c.4-.7,0-1.5-.5-2.5-1.2-2.4-2-4.4.9-6.1.4-.3.8-.4,1.3-.5s.9,0,1.4.1c2.4.7,4.1,4.1,4.1,4.2l2.2,4.6-.3-5.1c-.2-3.4-2.4-9.9-9-10-3.4-.1-6.1,1.4-7.6,3.9-.7,1.4-1.1,2.9-1.1,4.4s.5,3.1,1.3,4.4c.6,1,1.4,1.8,2.4,2.4s2.1.9,3.3.9Zm3.7-11.8c-1,0-2,.3-2.8.8-4.7,2.8-2.6,6.8-1.6,8.7c0,.1.1.1.1.2-.7-.1-1.3-.3-1.9-.7s-1.1-.9-1.4-1.5c-.6-1-.9-2.2-1-3.3c0-1.2.2-2.3.8-3.4.8-1.4,2.4-3,5.8-2.9c1.2,0,2.3.3,3.3.9s1.8,1.5,2.3,2.5c-.6-.5-1.3-.9-2-1.1-.6-.1-1.1-.2-1.6-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M351.5,319.4c-1.9.5-14.3,4.6-12.1-10c.1-.7.3-1.3.7-1.8c1.3-1.8-1.1-4.1-2.9-2.7-1.6,1.3-2.8,4-2.6,9c.7,20.1,22.5,3.9,16.9,5.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M342.2,325.1c4.8,0,9.8-3.2,10.9-4.7.2-.2.3-.5.3-.8s0-.6-.2-.8c-.5-.8-1.6-.5-2-.4l-.2.1c-4.2,1.2-7.2,1.1-8.9-.4s-2.2-4.4-1.6-8.5c.1-.5.2-1,.5-1.4.4-.6.6-1.3.5-2s-.4-1.4-1-1.9c-.5-.5-1.2-.8-1.9-.9-.7,0-1.4.2-2,.7-2.1,1.7-3.1,5-3,9.9.2,7.4,3.4,10,6,10.8.8.2,1.7.3,2.6.3Zm6.6-4c-2.3,1.2-5.6,2.4-8.4,1.7-3-.8-4.6-3.8-4.8-9-.1-4.2.6-6.9,2.2-8.2.2-.1.4-.2.6-.2s.4.1.6.3c.2.1.3.4.3.6s0,.5-.2.6c-.5.7-.8,1.5-.9,2.3-.7,4.9,0,8.4,2.3,10.4c2,1.6,4.8,2.1,8.3,1.5v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M362,367c-1.9.5-27,7.4-12-10c1.9-2.2,4.7,7.6,4.7,7.6s-4.4-21.2-12.6-10.4c-7.5,9.9,25.5,11.2,19.9,12.8Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M351.5,369.8c2.6,0,6.1-.5,10.8-1.8.4-.1,1.4-.4,1.4-1.4c0-1.2-1.2-1.4-5.7-2.4l-2.5-.5c-.7-2.8-3.2-12.4-8-13.5-2.1-.5-4.2.7-6.3,3.4-1.3,1.7-1.6,3.3-1.1,4.9.6,1.7,2.4,3,4.7,4.1-.9,2-1,3.7-.1,5c.5.6,2.1,2.2,6.8,2.2Zm8.1-3.2c-8.2,2-12.3,1.1-13.1-.2-.3-.5-.5-1.4.3-3.1c3.5,1.2,7.1,2.2,10.8,2.9l2,.4Zm-8.9-8.8c.6.5,1.7,2.8,2.6,5.4-1.8-.4-3.6-1-5.4-1.6.8-1.4,1.7-2.6,2.8-3.8v0Zm-.1-2.2c-.3,0-.5.1-.8.2-.2.1-.4.3-.6.5-1.2,1.4-2.3,2.9-3.3,4.4-1.9-.9-3.3-1.9-3.7-3-.3-.9-.1-1.8.8-2.9c1.5-2,3-2.9,4.2-2.6c1.2.2,2.3,1.6,3.4,3.4v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M399.3,345.2c-3.7,2.5,15.6,5.2,8.3,18.7-6,11-20.6,1.4-20.6,1.4s11.3,16.5,25.6,8.2c20.4-11.9,1.6-38.3-13.3-28.3Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M404.5,376.8c3.1,0,6.1-.9,8.7-2.5c6.2-3.6,9.6-9,9.5-15.1-.1-3.2-1.1-6.4-2.8-9.1s-4.1-5-6.9-6.5c-2.2-1.2-4.7-1.8-7.2-1.6-2.5.1-4.9,1-6.9,2.4-.3.2-.5.4-.7.6-.2.3-.2.6-.3.9c0,1.2,1.3,2,3.2,3.2c5.3,3.3,9.7,6.9,5.7,14.3-.5,1.1-1.3,2.1-2.3,2.8-.9.8-2,1.3-3.2,1.6-6.1,1.5-13.6-3.4-13.7-3.5l-4.3-2.8l2.9,4.3c.1.2,7.6,11,18.3,11Zm1.8-32.8c1.9,0,3.8.5,5.6,1.4c2.5,1.4,4.6,3.3,6.1,5.8c1.5,2.4,2.4,5.2,2.5,8c.1,5.4-2.9,10.1-8.4,13.3-5.6,3.2-11.3,2.9-17.1-.9-1.4-.9-2.6-2-3.8-3.1c3,1.2,6.9,2.2,10.5,1.3c1.5-.4,2.8-1.1,4-2s2.1-2.1,2.8-3.5c5.3-9.7-2.3-14.5-6.4-17-.7-.4-1.4-.9-2.1-1.4c1.9-1.2,4.1-1.9,6.3-1.9Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M371.7,363.8c3.1,3.2,2.5-16.3,17-11.4c11.9,4.1,4.9,20.1,4.9,20.1s14.3-13.9,3.7-26.7c-15.2-18.1-38,5-25.6,18Z"
                fill="#dcdbfa"
              ></path>
              <path
                d="M390.6,376.8l3.7-3.6c.1-.1,14.9-14.8,3.7-28.1-4.6-5.5-10.5-7.9-16.5-6.7-3.1.7-6.1,2.2-8.4,4.3-2.4,2.2-4.2,4.9-5.2,8-.8,2.4-.9,4.9-.4,7.4.6,2.4,1.8,4.7,3.6,6.4.2.2.5.4.8.5s.6.1.9.1c1.2-.2,1.8-1.6,2.6-3.7c2.3-5.8,5.1-10.8,13.1-8.1c1.2.3,2.2.9,3.2,1.7.9.8,1.7,1.8,2.2,2.9c2.5,5.7-1,14-1.1,14.1l-2.2,4.8Zm-6.1-36.6c4.4,0,8.5,2.2,12,6.3c4.1,4.9,4.8,10.7,2,17-.7,1.5-1.5,2.9-2.4,4.3.7-3.1,1-7.2-.5-10.6-.6-1.4-1.5-2.6-2.6-3.6s-2.5-1.7-3.9-2.2c-10.5-3.6-13.8,4.7-15.6,9.2-.3.8-.6,1.6-1,2.3-1.5-1.5-2.5-3.4-2.9-5.5-.5-2.1-.3-4.2.4-6.2.9-2.7,2.5-5.1,4.6-7s4.7-3.2,7.5-3.9c.6-.1,1.5-.1,2.4-.1v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M373.7,349.9c.9,1,.7-4.9,5-3.4c3.5,1.2,1.4,6,1.4,6s4.3-4.1,1.1-7.9c-4.5-5.4-11.2,1.4-7.5,5.3Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M377.2,356.8l3.7-3.6c1.7-1.7,4.1-5.7,1.2-9.3-1.5-1.8-3.5-2.6-5.6-2.2-1.1.2-2,.7-2.8,1.5-.8.7-1.4,1.6-1.7,2.7-.3.8-.3,1.7-.1,2.6.2.8.6,1.6,1.2,2.2.1.2.3.3.5.3.2.1.4.1.6.1.7-.1,1-.8,1.3-1.5.7-1.8,1.4-2.5,3-2c.3.1.5.2.7.4s.4.4.5.6c.5,1.2-.1,3-.3,3.6l-2.2,4.6Zm.2-11.5c-2.1,0-3.1,1.7-3.6,2.9-.2-.6-.2-1.2,0-1.7.2-.7.6-1.3,1.2-1.8.5-.5,1.2-.8,1.9-1c1.4-.3,2.6.3,3.6,1.6.6.7.9,1.5.9,2.4-.2-.5-.5-.9-1-1.3-.4-.4-.9-.6-1.4-.8s-1.1-.4-1.6-.3v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M370.6,400.1c0-2.6,7.6,5.7,11.6-2.1c3.3-6.4-6-10.2-6-10.2s11.5,0,12.2,9.5c.9,13.6-17.7,13.2-17.8,2.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M379.1,408.8c2.1,0,4.1-.6,5.8-1.7c3.1-2,4.6-5.5,4.3-9.8-.6-8.3-8.9-10.5-13.2-10.5h-5l4.7,2c0,0,4.7,2,5.8,5.1.2.6.3,1.2.2,1.9-.1.6-.2,1.3-.6,1.8-2,4-4.9,3.1-8.1,1.7-1.3-.6-2.2-1-3-.5-.2.1-.4.3-.5.6-.1.2-.2.5-.1.7c0,1.5.4,3,1.2,4.3s1.9,2.3,3.3,3c1.7.9,3.4,1.4,5.2,1.4Zm1.9-19.1c2.9,1,6,3.1,6.3,7.7s-1.7,6.8-3.4,7.9c-1.3.8-2.9,1.3-4.4,1.3-1.6.1-3.1-.3-4.5-1-.9-.5-1.7-1.1-2.3-2-.6-.8-.9-1.8-1-2.8l.7.3c2.5,1.1,7.6,3.4,10.7-2.7.4-.8.7-1.7.8-2.6s-.1-1.8-.4-2.7c-.6-1.2-1.4-2.4-2.5-3.4Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M376.9,368.8c2,2.1,1.7-10.9,11.3-7.6c7.9,2.7,3.3,13.4,3.3,13.4s9.6-9.3,2.4-17.9c-10-12.1-25.3,3.4-17,12.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M388.5,378.9l3.7-3.6c.1-.1,10.1-10.1,2.5-19.2-3.2-3.8-7.2-5.4-11.4-4.6-2.2.5-4.2,1.5-5.8,3s-2.9,3.4-3.6,5.5c-.6,1.6-.6,3.4-.3,5.1.4,1.7,1.2,3.2,2.4,4.5.2.2.4.4.6.5s.5.1.8.1c1-.2,1.4-1.2,2-2.7c1.5-3.7,3.3-6.9,8.3-5.2.7.2,1.4.6,2,1.1s1,1.1,1.4,1.8c1.6,3.7-.7,9.1-.7,9.2l-1.9,4.5Zm-3-25.6c2.2,0,5,.9,7.7,4.1s3.1,6.9,1.4,11c-.2.5-.5,1-.7,1.4.3-1.9,0-3.8-.7-5.6-.4-1-1.1-1.8-1.8-2.5-.8-.7-1.7-1.2-2.7-1.5-7.3-2.5-9.7,3.5-10.9,6.4-.1.3-.3.8-.5,1.1-.8-1-1.4-2.1-1.6-3.4-.2-1.2-.1-2.5.3-3.7.6-1.8,1.6-3.3,3-4.6c1.4-1.2,3-2.1,4.8-2.5.5-.2,1.1-.2,1.7-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M365.9,410.4c-2.7-1.2,9.7-5.2,3.4-13.3-5.2-6.6-13.7,1.4-13.7,1.4s5.6-12.2,16-8.3c14.7,5.5,5.2,25.1-5.7,20.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M369.4,412.2c2.1,0,4.1-.7,5.8-2c1.8-1.4,3.1-3.1,4-5.2.8-2,1.1-4.3.9-6.5-.6-4.2-3.5-7.4-8.1-9.2-11.2-4.1-17.2,8.7-17.3,8.8l-2.1,4.7l3.8-3.5c0,0,4.4-4.1,8.4-3.7.8.1,1.5.3,2.2.7s1.2.9,1.7,1.5c3.3,4.2.9,6.9-2.1,9.6-1.2,1.1-2,1.8-1.9,2.8c0,.3.2.5.3.7.2.2.4.4.6.5c1.1.4,2.4.7,3.8.8Zm-1.9-21.7c1.3,0,2.5.2,3.7.7c5,1.9,6.5,5.2,6.8,7.6.2,1.8,0,3.7-.8,5.4-.7,1.7-1.8,3.2-3.3,4.3-1,.8-2.2,1.3-3.4,1.5-1.3.2-2.5.1-3.7-.4.2-.2.6-.5.9-.8c2.3-2,7.1-6.3,2.4-12.4-.6-.8-1.4-1.6-2.3-2.1s-1.9-.8-3-.9c-1.9-.1-3.8.4-5.5,1.3.3-.4.7-.8,1.1-1.1c2.2-2.1,4.6-3.1,7.1-3.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M368.7,345.5c-2.7-1.2,9.7-5.2,3.4-13.3-5.2-6.6-13.7,1.4-13.7,1.4s5.6-12.2,16-8.3c14.7,5.6,5.3,25.2-5.7,20.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M372.3,347.3c2.1,0,4.1-.7,5.8-2c1.8-1.4,3.1-3.1,4-5.2.8-2,1.1-4.3.9-6.5-.6-4.2-3.5-7.4-8.1-9.2-11.2-4.2-17.2,8.7-17.3,8.8l-2.1,4.7l3.8-3.5c0,0,4.4-4,8.4-3.7.8.1,1.5.3,2.2.7s1.2.9,1.7,1.5c3.3,4.2.9,6.9-2.1,9.6-1.2,1.1-2,1.8-1.9,2.8c0,.3.2.5.3.7.2.2.4.4.6.5c1.1.5,2.4.8,3.8.8Zm-1.9-21.6c1.3,0,2.5.2,3.7.7c5,1.9,6.5,5.2,6.8,7.6.2,1.8,0,3.7-.8,5.4-.7,1.7-1.8,3.2-3.3,4.4-1,.8-2.2,1.3-3.4,1.5-1.3.2-2.5.1-3.7-.4.2-.2.6-.6.9-.8c2.3-2,7.1-6.3,2.4-12.4-.6-.8-1.4-1.6-2.3-2.1s-1.9-.8-3-.9c-1.9-.1-3.8.4-5.5,1.3.3-.4.7-.8,1.1-1.1c2.1-2.2,4.5-3.2,7.1-3.2Z"
                transform="translate(0 0.000001)"
                fill="#8f8ce4"
              ></path>
              <path
                d="M385.3,387.1c1.4,1.4,1.1-7.3,7.6-5.1c5.3,1.8,2.2,9,2.2,9s6.4-6.2,1.6-11.9c-6.8-8.1-17,2.2-11.4,8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M392.1,395.3l3.7-3.6c2.4-2.4,5.9-8.2,1.7-13.3-2.2-2.6-5-3.8-7.9-3.2-1.5.3-2.9,1-4,2.1-1.1,1-2,2.3-2.5,3.8-.4,1.2-.4,2.4-.2,3.6.3,1.2.9,2.3,1.7,3.1.2.2.4.3.6.4s.5.1.7.1c.8-.2,1.2-1,1.6-2c1-2.5,2-4.3,5.2-3.3.4.1.9.4,1.2.7s.6.7.8,1.1c1,2.3-.5,5.8-.5,5.9l-2.1,4.6ZM391,377.1c1.4,0,3.2.6,4.9,2.6.8.9,1.3,1.9,1.5,3.1.2,1.1.1,2.3-.4,3.4c0-.8-.2-1.5-.5-2.2s-.8-1.3-1.3-1.8c-.6-.5-1.2-.9-1.9-1.1-5.2-1.8-6.9,2.4-7.7,4.4c0,.1-.1.1-.1.2-.4-.6-.6-1.2-.7-1.9s0-1.4.2-2c.4-1.1,1-2.1,1.9-2.9s1.9-1.3,3.1-1.6c.3-.2.7-.2,1-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M425.9,388.6c1.4,1.4,1.1-7.3,7.6-5.1c5.3,1.8,2.2,9,2.2,9s6.4-6.2,1.6-11.9c-6.7-8.1-16.9,2.2-11.4,8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M432.7,396.9l3.7-3.6c2.4-2.4,6-8.2,1.7-13.3-2.2-2.6-5-3.8-7.9-3.2-1.5.3-2.9,1-4,2.1-1.1,1-2,2.3-2.5,3.8-.4,1.2-.4,2.4-.2,3.6.3,1.2.9,2.3,1.7,3.1.2.2.4.3.6.4s.5.1.7.1c.8-.2,1.2-1,1.6-2c1-2.5,2-4.3,5.2-3.3.4.1.9.4,1.2.7s.6.7.8,1.1c.9,1.9-.1,4.9-.5,5.9l-2.1,4.6Zm-1.1-18.3c1.4,0,3.2.6,4.9,2.6.8.9,1.3,1.9,1.5,3.1.2,1.1.1,2.3-.4,3.4c0-.8-.2-1.6-.5-2.3s-.8-1.3-1.3-1.8c-.6-.5-1.2-.9-1.9-1.1-5.2-1.8-6.9,2.4-7.7,4.4c0,.1-.1.1-.1.2-.4-.6-.6-1.2-.7-1.9s0-1.4.2-2c.4-1.1,1-2.1,1.9-2.9s1.9-1.3,3.1-1.6c.3,0,.7-.1,1-.1v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M436.9,362.1c-1.6-1.2.2,7.4-6.5,6.4-5.5-.8-3.7-8.5-3.7-8.5s-5.2,7.3.5,12c8.1,6.8,16.3-5.2,9.7-9.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M432.2,375.1c.9,0,1.8-.2,2.7-.5c1.4-.6,2.7-1.5,3.6-2.8.9-1.2,1.5-2.7,1.8-4.2.2-1.2,0-2.4-.5-3.6-.5-1.1-1.3-2.1-2.2-2.8-.2-.2-.4-.3-.6-.3s-.5,0-.7.1c-.8.3-1,1.2-1.2,2.3-.5,2.7-1.2,4.6-4.5,4.2-.5,0-.9-.2-1.3-.4s-.7-.6-1-.9c-1.4-2.1-.6-5.8-.6-5.8l1.2-5-3,4.2c-2,2.8-4.4,9.2.7,13.4c1.4,1.3,3.5,2,5.6,2.1Zm4.9-11.6c.5.5.8,1.1,1,1.8s.3,1.4.2,2c-.2,1.2-.6,2.3-1.4,3.2-.7.9-1.7,1.7-2.7,2.1-1.4.6-3.7.8-6.3-1.4-.9-.7-1.6-1.7-2-2.8s-.5-2.3-.2-3.4c.2.8.5,1.5.9,2.1s1,1.1,1.6,1.5s1.4.6,2.1.7c5.4.8,6.3-3.6,6.8-5.8v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M234.7,362.6c1.6-2-9.3-.2-7.6-8.7c1.4-7,10.9-4.2,10.9-4.2s-8.9-7-15.2-.1c-9.1,9.8,5.5,20.9,11.9,13Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M229.1,366.3c1.2,0,2.4-.3,3.5-.8s2.1-1.3,2.8-2.3c.2-.2.3-.4.3-.7.1-.2,0-.5,0-.7-.3-.9-1.3-1.1-2.6-1.5-3.3-.9-6-1.9-5.2-6.2.1-.6.3-1.2.7-1.7.3-.5.8-1,1.4-1.3c2.9-1.7,7.6-.4,7.6-.4l4.9,1.4-4.1-3.2c-3.3-2.6-11.1-6-16.6,0-2.9,3.1-3.8,6.8-2.6,10.2.6,1.8,1.7,3.4,3.2,4.6c1.4,1.2,3.2,2.1,5,2.4.7.1,1.2.2,1.7.2Zm4.3-3.8c-.7.7-1.6,1.2-2.5,1.5-1,.3-2,.3-3,.1-1.5-.3-2.9-1-4.1-2s-2.1-2.3-2.6-3.7c-.7-1.9-.8-4.9,2.2-8.2c2.4-2.6,5.4-3.3,9-2.3l.5.1c-1.4.1-2.7.5-3.9,1.1-.8.5-1.4,1.1-1.9,1.9s-.8,1.6-1,2.5c-1.3,6.5,4.1,7.9,6.6,8.6l.7.4Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M364.9,371c1.6-2-9.3-.2-7.6-8.7c1.4-7,10.9-4.2,10.9-4.2s-8.9-7-15.2-.1c-9.1,9.9,5.5,21,11.9,13Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M359.3,374.8c1.2,0,2.4-.3,3.5-.8s2.1-1.3,2.8-2.3c.2-.2.3-.4.3-.7.1-.2,0-.5,0-.7-.3-.9-1.3-1.1-2.6-1.5-3.3-.9-6-1.9-5.2-6.2.1-.6.3-1.2.7-1.7s.8-1,1.4-1.3c2.8-1.7,7.6-.4,7.6-.4l4.9,1.4-4.1-3.2c-3.3-2.6-11.1-6-16.6,0-2.9,3.1-3.8,6.8-2.6,10.2.6,1.8,1.7,3.4,3.2,4.6c1.4,1.2,3.2,2.1,5,2.4.7.1,1.2.1,1.7.2Zm4.3-3.8c-.7.7-1.6,1.2-2.5,1.5-1,.3-2,.3-3,.1-1.5-.3-2.9-1-4.1-2s-2.1-2.3-2.6-3.7c-.7-1.9-.8-4.9,2.2-8.2c2.3-2.5,5.3-3.3,8.9-2.3l.5.2c-1.4.1-2.7.5-3.9,1.1-.8.5-1.4,1.1-1.9,1.9s-.8,1.6-1,2.5c-1.3,6.5,4.1,7.9,6.6,8.6l.8.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M404.3,407.7c1.6-2-9.3-.2-7.6-8.7c1.4-7,10.9-4.2,10.9-4.2s-8.9-7-15.2-.1c-9.1,9.9,5.5,21,11.9,13Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M398.7,411.5c1.2,0,2.4-.3,3.5-.8s2.1-1.3,2.8-2.3c.2-.2.3-.4.3-.7.1-.2,0-.5,0-.7-.3-.9-1.3-1.1-2.6-1.5-3.3-.9-6-1.9-5.2-6.2.1-.6.3-1.2.7-1.7s.8-1,1.3-1.3c2.8-1.7,7.6-.4,7.6-.4l4.9,1.4-4.1-3.2c-3.3-2.6-11.1-6-16.6,0-2.9,3.1-3.8,6.8-2.6,10.2.6,1.8,1.7,3.4,3.2,4.6c1.4,1.2,3.2,2.1,5,2.4.7.1,1.3.1,1.8.2Zm4.3-3.9c-.7.7-1.6,1.2-2.5,1.5-1,.3-2,.3-2.9.1-1.5-.3-2.9-1-4.1-2s-2.1-2.3-2.6-3.7c-.7-1.9-.8-4.9,2.2-8.2c2.3-2.5,5.3-3.3,8.9-2.3l.5.2c-1.4.1-2.7.5-3.9,1.2-.8.5-1.4,1.1-1.9,1.9s-.8,1.6-1,2.5c-1.3,6.5,4.1,7.9,6.6,8.6l.7.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M410.1,407c1.6-2-9.3-.2-7.6-8.7c1.4-7,10.9-4.2,10.9-4.2s-8.9-7-15.2-.1c-9.1,9.8,5.5,20.9,11.9,13Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M404.5,410.7c1.2,0,2.4-.3,3.5-.8s2.1-1.3,2.8-2.3c.2-.2.3-.4.3-.7.1-.2,0-.5,0-.7-.3-.9-1.3-1.1-2.6-1.5-3.3-.9-6-1.9-5.2-6.2.1-.6.3-1.2.7-1.7s.8-1,1.4-1.3c2.9-1.7,7.6-.4,7.6-.4l5,1.4-4.1-3.2c-3.3-2.6-11.1-6-16.6,0-2.9,3.1-3.8,6.8-2.6,10.2.6,1.8,1.7,3.4,3.2,4.6c1.4,1.2,3.2,2.1,5,2.4.6.1,1.1.2,1.6.2Zm4.3-3.8c-.7.7-1.6,1.2-2.5,1.5-1,.3-2,.3-3,.1-1.5-.3-2.9-1-4.1-2s-2.1-2.3-2.6-3.7c-.7-1.9-.8-4.9,2.2-8.2c2.3-2.5,5.3-3.3,8.9-2.3.2.1.4.1.5.2-1.4.1-2.7.5-3.9,1.1-.8.5-1.4,1.1-1.9,1.9s-.8,1.6-1,2.5c-1.3,6.5,4.1,7.9,6.6,8.6l.8.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M431.4,391.3c1.6-2-9.3-.2-7.6-8.7c1.4-7,10.9-4.2,10.9-4.2s-8.9-7-15.2-.1c-9.1,9.9,5.5,21,11.9,13Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M425.8,395c1.2,0,2.4-.3,3.5-.8s2.1-1.3,2.8-2.3c.2-.2.3-.4.3-.7.1-.2,0-.5,0-.7-.3-.9-1.2-1.1-2.6-1.5-3.3-.9-6-1.9-5.2-6.2.1-.6.3-1.2.7-1.7s.8-1,1.4-1.3c2.9-1.7,7.6-.4,7.6-.4l5,1.4-4.1-3.2c-3.3-2.6-11.1-6-16.6,0-2.9,3.1-3.8,6.8-2.6,10.2.6,1.8,1.7,3.4,3.2,4.6c1.4,1.2,3.2,2.1,5,2.4.6.2,1.1.2,1.6.2Zm4.3-3.8c-.7.7-1.6,1.2-2.5,1.5-1,.3-2,.3-3,.1-1.5-.3-2.9-1-4.1-2s-2.1-2.3-2.6-3.7c-.7-1.9-.8-4.9,2.2-8.2c2.4-2.6,5.4-3.3,9-2.3l.5.1c-1.4.1-2.7.5-3.9,1.1-.8.5-1.4,1.1-1.9,1.9s-.8,1.6-1,2.5c-1.3,6.5,4.1,7.9,6.6,8.6l.7.4Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M365,393.9c1.6-2-9.3-.2-7.6-8.7c1.4-7,10.9-4.2,10.9-4.2s-8.9-7-15.2-.1c-9,9.8,5.6,21,11.9,13Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M359.5,397.6c1.2,0,2.4-.3,3.5-.8s2.1-1.3,2.8-2.3c.2-.2.3-.4.3-.7.1-.2,0-.5,0-.7-.3-.9-1.3-1.1-2.6-1.5-3.3-.9-6-1.9-5.2-6.2.1-.6.3-1.2.7-1.7s.8-1,1.4-1.3c2.8-1.7,7.6-.4,7.6-.4l4.9,1.4-4.1-3.2c-3.3-2.6-11.1-6-16.6,0-2.9,3.1-3.8,6.8-2.6,10.2.6,1.8,1.7,3.4,3.2,4.6c1.4,1.2,3.2,2.1,5,2.4.6.2,1.2.2,1.7.2Zm4.2-3.8c-.7.7-1.6,1.2-2.5,1.5-1,.3-2,.3-3,.1-1.5-.3-2.9-1-4.1-2s-2.1-2.3-2.6-3.7c-.7-1.9-.8-4.9,2.2-8.2c2.3-2.6,5.4-3.3,9-2.3l.5.1c-1.4.1-2.7.5-3.9,1.1-.8.5-1.4,1.1-1.9,1.9s-.8,1.6-1,2.5c-1.3,6.5,4.1,7.9,6.6,8.6l.7.4Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M371.2,374.1c1.6-2-9.3-.2-7.6-8.7c1.4-7,10.9-4.2,10.9-4.2s-8.9-7-15.2-.1c-9.1,9.8,5.5,20.9,11.9,13Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M365.6,377.8c1.2,0,2.4-.3,3.5-.8s2.1-1.3,2.8-2.3c.2-.2.3-.4.3-.7.1-.2,0-.5,0-.7-.3-.9-1.3-1.1-2.6-1.5-3.3-.9-6-1.9-5.2-6.2.1-.6.3-1.2.7-1.7s.8-1,1.4-1.3c2.8-1.7,7.6-.4,7.6-.4l4.9,1.4-4.1-3.2c-3.3-2.6-11.1-6-16.6,0-2.9,3.1-3.8,6.8-2.6,10.2.6,1.8,1.7,3.4,3.2,4.6c1.4,1.2,3.2,2.1,5,2.4.7.1,1.2.2,1.7.2Zm4.3-3.8c-.7.7-1.6,1.2-2.5,1.5-1,.3-2,.3-3,.1-1.5-.3-2.9-1-4.1-2s-2.1-2.3-2.6-3.7c-.7-1.9-.8-4.9,2.2-8.2c2.3-2.5,5.3-3.3,8.9-2.3l.5.2c-1.4.1-2.7.5-3.9,1.2-.8.5-1.4,1.1-1.9,1.9s-.8,1.6-1,2.5c-1.3,6.5,4.1,7.9,6.6,8.6l.8.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M358.4,374.1c1.6-2-9.3-.2-7.6-8.7c1.4-7,10.9-4.2,10.9-4.2s-8.9-7-15.2-.1c-9.1,9.8,5.5,20.9,11.9,13Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M352.8,377.8c1.2,0,2.4-.3,3.5-.8s2.1-1.3,2.8-2.3c.2-.2.3-.4.3-.7.1-.2,0-.5,0-.7-.3-.9-1.3-1.1-2.6-1.5-3.3-.9-6-1.9-5.2-6.2.1-.6.3-1.2.7-1.7s.8-1,1.4-1.3c2.8-1.7,7.6-.4,7.6-.4l4.9,1.4-4.1-3.2c-3.3-2.6-11.1-6-16.6,0-2.9,3.1-3.8,6.8-2.6,10.2.6,1.8,1.7,3.4,3.2,4.6c1.4,1.2,3.2,2.1,5,2.4.7.1,1.2.2,1.7.2Zm4.3-3.8c-.7.7-1.6,1.2-2.5,1.5-1,.3-2,.3-3,.1-1.5-.3-2.9-1-4.1-2s-2.1-2.3-2.6-3.7c-.7-1.9-.8-4.9,2.2-8.2c2.4-2.6,5.4-3.3,9-2.3l.5.1c-1.4.1-2.7.5-3.9,1.2-.8.5-1.4,1.1-1.9,1.9s-.8,1.6-1,2.5c-1.3,6.5,4.1,7.9,6.6,8.6l.7.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M395.4,374.7c1.6-2-9.3-.2-7.6-8.7c1.4-7,10.9-4.2,10.9-4.2s-8.9-7-15.2-.1c-9.1,9.8,5.5,20.9,11.9,13Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M389.9,378.4c1.2,0,2.4-.3,3.5-.8s2.1-1.3,2.8-2.3c.2-.2.3-.4.3-.7.1-.2,0-.5,0-.7-.3-.9-1.3-1.1-2.6-1.5-3.3-.9-6-1.9-5.2-6.2.1-.6.3-1.2.7-1.7.3-.5.8-1,1.3-1.3c2.9-1.7,7.6-.4,7.6-.4l5,1.4-4.1-3.2c-3.3-2.6-11.1-6-16.6,0-2.9,3.1-3.8,6.8-2.6,10.2.6,1.8,1.7,3.4,3.2,4.6c1.4,1.2,3.2,2.1,5,2.4.6.1,1.1.2,1.7.2Zm4.2-3.8c-.7.7-1.6,1.2-2.5,1.5-1,.3-2,.3-2.9.1-1.5-.3-2.9-1-4.1-2s-2.1-2.3-2.6-3.7c-.7-1.9-.8-4.9,2.2-8.2c2.3-2.6,5.4-3.3,9-2.3l.5.1c-1.4.1-2.7.5-3.9,1.1-.8.5-1.4,1.1-1.9,1.9s-.8,1.6-1,2.5c-1.3,6.5,4.1,7.9,6.6,8.6l.6.4Zm.5-.6l.8.6-.8-.6v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M393.3,405.5c1.6-2-9.3-.2-7.6-8.7c1.4-7,10.9-4.2,10.9-4.2s-8.9-7-15.2-.1c-9.1,9.8,5.5,21,11.9,13Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M387.8,409.2c1.2,0,2.4-.3,3.5-.8s2.1-1.3,2.8-2.3c.2-.2.3-.4.3-.7.1-.2,0-.5,0-.7-.3-.9-1.2-1.1-2.6-1.5-3.3-.9-6-1.9-5.2-6.2.1-.6.3-1.2.7-1.7s.8-1,1.4-1.3c2.8-1.7,7.6-.4,7.6-.4l4.9,1.4-4.1-3.2c-3.3-2.6-11.1-6-16.6,0-2.9,3.1-3.8,6.8-2.6,10.2.6,1.8,1.7,3.4,3.2,4.6c1.4,1.2,3.2,2.1,5,2.4.6.2,1.1.2,1.7.2Zm4.2-3.8c-.7.7-1.6,1.2-2.5,1.5-1,.3-2,.3-3,.1-1.5-.3-2.9-1-4.1-2s-2.1-2.3-2.6-3.7c-.7-1.9-.8-4.9,2.2-8.2c2.3-2.6,5.4-3.3,9-2.3l.5.1c-1.4.1-2.7.5-3.9,1.2-.8.5-1.4,1.1-1.9,1.9s-.8,1.6-1,2.5c-1.3,6.5,4.1,7.9,6.6,8.6l.7.3Zm.5-.5l.8.6-.8-.6v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M378,408.6c1.6-2-9.3-.2-7.6-8.7c1.4-7,10.9-4.2,10.9-4.2s-8.9-7-15.2-.1c-9,9.8,5.6,20.9,11.9,13Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M372.5,412.3c1.2,0,2.4-.3,3.5-.8s2.1-1.3,2.8-2.3c.2-.2.3-.4.3-.7.1-.2,0-.5,0-.7-.3-.9-1.2-1.1-2.6-1.5-3.3-.9-6-1.9-5.2-6.2.1-.6.3-1.2.7-1.7.3-.5.8-1,1.3-1.3c2.9-1.7,7.6-.4,7.6-.4l5,1.5-4.1-3.2c-3.3-2.6-11.1-6-16.6,0-2.9,3.1-3.8,6.8-2.6,10.2.6,1.8,1.7,3.4,3.2,4.6c1.4,1.2,3.2,2.1,5,2.4.7,0,1.2.1,1.7.1Zm4.3-3.8c-.7.7-1.6,1.2-2.5,1.5-1,.3-2,.3-2.9.1-1.5-.3-2.9-1-4.1-2s-2.1-2.3-2.6-3.7c-.7-1.9-.8-4.9,2.2-8.2c2.3-2.5,5.3-3.3,8.9-2.3.2,0,.4.1.5.2-1.4.1-2.7.5-3.9,1.1-.8.5-1.4,1.1-1.9,1.9s-.8,1.6-1,2.5c-1.3,6.5,4.1,7.9,6.6,8.6l.7.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M391.3,382.6c-2,0,4.5,5.9-1.5,9-5,2.6-8-4.6-8-4.6s.1,8.9,7.5,9.4c10.4.7,10-13.8,2-13.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M389.9,397.4c3.1,0,5.5-1.2,7-3.6.8-1.3,1.3-2.8,1.3-4.4c0-1.5-.3-3.1-1-4.4-.6-1.1-1.4-2-2.5-2.6-1-.6-2.2-.9-3.5-.9-.2,0-.5,0-.7.1s-.4.3-.5.5c-.5.7-.1,1.5.4,2.5c1.1,2.5,1.7,4.5-1.2,6-.4.2-.9.4-1.3.4-.5,0-.9,0-1.4-.2-2.4-.9-3.9-4.3-3.9-4.4l-2-4.7v5.1c0,3.4,1.8,10,8.4,10.4.4.2.7.2.9.2Zm2.3-13.7c.7.1,1.3.4,1.9.8s1,1,1.3,1.6c.5,1,.8,2.2.8,3.4s-.4,2.3-1,3.3c-.8,1.3-2.5,2.8-5.9,2.6-1.2-.1-2.3-.4-3.2-1.1-1-.7-1.7-1.6-2.2-2.6.6.5,1.3.9,2,1.2s1.5.4,2.2.3s1.5-.3,2.1-.7c4.8-2.5,3-6.7,2.1-8.6l-.1-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M241.4,354.1c-2,0,4.5,5.9-1.6,9-5,2.6-8-4.6-8-4.6s.1,8.9,7.5,9.4c10.6.7,10.2-13.8,2.1-13.8Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M240.1,368.9c3.1,0,5.5-1.2,7-3.6.8-1.3,1.3-2.8,1.3-4.4c0-1.5-.3-3.1-1-4.4-.6-1.1-1.4-2-2.5-2.6-1-.6-2.2-.9-3.5-.9-.2,0-.5,0-.7.1s-.4.3-.5.5c-.5.7-.1,1.5.4,2.5c1.1,2.5,1.7,4.5-1.2,6-.4.2-.9.4-1.3.4-.5,0-.9,0-1.4-.2-2.4-.9-3.9-4.3-3.9-4.4l-2-4.7v5.1c0,3.4,1.8,10,8.4,10.4.4.2.6.2.9.2Zm2.3-13.7c.7.1,1.3.4,1.9.8s1,.9,1.3,1.6c.5,1,.8,2.2.8,3.4s-.4,2.3-1,3.3c-.8,1.3-2.5,2.8-5.9,2.6-1.2-.1-2.3-.4-3.2-1.1-1-.7-1.7-1.6-2.2-2.6.6.5,1.3.9,2,1.2s1.5.4,2.2.3s1.5-.3,2.1-.7c4.8-2.5,3-6.7,2.1-8.6l-.1-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M243.8,330.2c-2,0,4.5,5.9-1.6,9-5,2.6-8-4.6-8-4.6s.1,8.9,7.5,9.4c10.5.7,10.1-13.8,2.1-13.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M242.4,345.1c3.1,0,5.5-1.2,7-3.6.8-1.3,1.3-2.8,1.3-4.4c0-1.5-.3-3.1-1-4.4-.6-1.1-1.4-2-2.5-2.6-1-.6-2.2-.9-3.5-.9-.2,0-.5,0-.7.1s-.4.3-.5.5c-.5.7-.1,1.5.4,2.5c1.1,2.5,1.7,4.5-1.2,6-.4.2-.9.4-1.3.4-.5,0-.9,0-1.4-.2-2.4-.9-3.9-4.4-3.9-4.4l-2-4.7v5.1c0,3.4,1.8,10,8.4,10.4l.9.2Zm2.3-13.8c.7.1,1.3.4,1.9.8s1,.9,1.3,1.6c.5,1,.8,2.2.8,3.4s-.4,2.3-1,3.3c-.8,1.3-2.5,2.8-5.9,2.6-1.2-.1-2.3-.4-3.2-1.1-1-.7-1.7-1.6-2.2-2.6.6.5,1.3.9,2,1.2s1.5.3,2.2.3c.7-.1,1.5-.3,2.1-.7c4.8-2.5,3-6.7,2.1-8.6l-.1-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M398.1,339.9c2,0-4.4-5.9,1.7-9c5-2.5,7.9,4.7,7.9,4.7s0-8.9-7.3-9.5c-10.5-.9-10.3,13.7-2.3,13.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M398.1,340.9c.2,0,.5,0,.7-.1s.4-.3.5-.4c.5-.7.1-1.5-.3-2.5-1.1-2.5-1.7-4.5,1.3-6c.4-.2.9-.4,1.3-.4.5,0,.9,0,1.4.2c2.4.9,3.8,4.4,3.8,4.4l1.9,4.7v-5.1c0-3.4-1.7-10-8.3-10.5-3.4-.3-6.2.9-7.8,3.4-.8,1.3-1.3,2.8-1.4,4.3s.3,3.1,1,4.5c.6,1.1,1.4,2,2.4,2.6c1.1.5,2.3.8,3.5.9v0Zm3.8-11.6c-.9,0-1.8.2-2.5.6-4.9,2.5-3.1,6.6-2.2,8.6c0,.1.1.1.1.2-.7-.1-1.3-.4-1.9-.9-.6-.4-1-1-1.3-1.6-.5-1.1-.8-2.2-.7-3.4s.4-2.3,1-3.3c.9-1.3,2.6-2.8,6-2.5c1.2.1,2.3.5,3.2,1.1.9.7,1.7,1.6,2.1,2.7-.6-.5-1.2-.9-2-1.2-.6-.2-1.2-.3-1.8-.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M386,361.8c-1.8-3.4-5,10.4-15.5,1.3-8.6-7.5-.1-15.9-.1-15.9s-13.9,4.1-7.8,17.1c8.5,18.5,30.8,11.5,23.4-2.5Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M377.7,376.5c4,0,7.5-1.7,9.4-4.6s1.8-6.7-.2-10.6c-.1-.3-.3-.5-.5-.7s-.5-.3-.8-.4c-1-.1-1.8.7-2.8,1.8-2.3,2.4-5.3,5.7-11.5.3-2.2-1.9-3.4-4.1-3.5-6.5-.1-4.3,3.4-7.9,3.5-7.9l2.8-2.8-3.8,1.1c-.3.1-6.7,2-9.1,7.4-1.5,3.2-1.2,6.9.7,11.1c3.2,6.9,8.8,11.3,15,11.7.1.1.5.1.8.1Zm7.5-14.1c1.6,3.2,1.7,6.1.2,8.4-1.6,2.5-4.9,3.9-8.6,3.6-5.5-.4-10.5-4.3-13.3-10.6-1.6-3.6-1.9-6.7-.7-9.4c1-2,2.5-3.6,4.4-4.7-1.1,1.8-1.6,4-1.6,6.1.1,2.9,1.5,5.6,4.2,7.9c7.7,6.7,12.2,1.9,14.3-.4.4-.2.7-.6,1.1-.9Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M245.2,343.7c-1.8-3.4-5,10.4-15.5,1.3-8.6-7.4-.1-15.9-.1-15.9s-13.9,4.1-7.8,17.1c8.5,18.5,30.8,11.5,23.4-2.5Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M236.9,358.4c4,0,7.5-1.7,9.4-4.5c1.9-3,1.9-6.7-.2-10.6-.1-.3-.3-.5-.5-.7s-.5-.3-.8-.4c-1-.1-1.8.7-2.8,1.8-2.3,2.4-5.3,5.7-11.5.3-2.2-2-3.4-4.1-3.5-6.5-.1-4.3,3.4-7.9,3.4-7.9l2.8-2.8-3.8,1.1c-.3.1-6.7,2-9.1,7.4-1.5,3.2-1.2,6.9.7,11c3.2,6.9,8.8,11.3,15,11.7.2.1.6.1.9.1Zm7.5-14.1c1.6,3.2,1.7,6.1.2,8.4-1.6,2.5-4.9,3.9-8.6,3.6-5.5-.4-10.5-4.3-13.4-10.6-1.6-3.6-1.9-6.7-.7-9.4c1-2,2.5-3.6,4.4-4.7-1.1,1.8-1.6,4-1.6,6.1.1,2.9,1.5,5.6,4.2,7.9c7.7,6.7,12.2,1.9,14.3-.4.5-.3.8-.6,1.2-.9v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M399.1,332.4c-1.4,1.4,7.3.9,5.3,7.4-1.6,5.4-8.9,2.5-8.9,2.5s6.4,6.2,12,1.3c7.8-7-2.8-16.9-8.4-11.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M402.7,346.5c2,0,4-.8,5.5-2.2c2.6-2.3,3.6-5.1,2.9-8-.4-1.5-1.1-2.9-2.2-4s-2.4-1.9-3.9-2.4c-1.2-.4-2.4-.4-3.6-.1s-2.2.9-3.1,1.8c-.2.2-.3.4-.4.6s-.1.5,0,.7c.2.8,1,1.1,2,1.5c2.5.9,4.4,1.9,3.4,5.1-.1.5-.3.9-.6,1.2-.3.4-.7.7-1.1.9-2.3,1.1-5.8-.3-5.9-.3l-4.8-1.9l3.7,3.6c1.8,1.6,4.8,3.5,8.1,3.5Zm.4-14.8c.5,0,.9.1,1.4.2c1.1.3,2.1,1,3,1.8.8.8,1.4,1.9,1.7,3c.4,1.5.2,3.8-2.3,6.1-.8.8-1.9,1.3-3,1.6-1.1.2-2.3.1-3.4-.3.8-.1,1.5-.3,2.2-.6s1.3-.8,1.7-1.4c.5-.6.8-1.2,1-2c1.6-5.2-2.6-6.8-4.7-7.5l-.2-.1c.8-.5,1.7-.8,2.6-.8v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M348.4,400.6c2,0-4.4-5.9,1.7-9c5-2.5,7.9,4.7,7.9,4.7s0-8.9-7.3-9.5c-10.6-.8-10.4,13.7-2.3,13.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M348.4,401.6c.2,0,.5,0,.7-.1s.4-.3.5-.4c.5-.7.1-1.5-.3-2.5-1.1-2.5-1.7-4.5,1.3-6c.4-.2.9-.4,1.3-.4.5,0,.9,0,1.4.2c2.4.9,3.8,4.4,3.8,4.4l1.9,4.7v-5.1c0-3.4-1.7-10-8.3-10.5-3.4-.3-6.2.9-7.8,3.4-.8,1.3-1.3,2.8-1.4,4.3s.3,3.1,1,4.5c.5,1.1,1.4,2,2.4,2.6s2.2.9,3.5.9v0Zm3.7-11.5c-.9,0-1.8.2-2.5.6-4.9,2.5-3.1,6.6-2.2,8.6l.1.2c-.7-.1-1.3-.4-1.9-.8-.5-.4-1-1-1.3-1.6-.5-1-.8-2.2-.7-3.4s.4-2.3,1-3.3c.8-1.3,2.6-2.8,6-2.5c1.2.1,2.3.5,3.2,1.1.9.7,1.7,1.6,2.1,2.7-.6-.5-1.3-.9-2-1.2-.6-.3-1.2-.4-1.8-.4Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M355.7,376.5c1.9-.4,14.5-3.9,11.5,10.7-.1.7-.4,1.3-.8,1.8-1.4,1.7.9,4.1,2.7,2.8c1.7-1.2,3-3.9,3.1-8.9.5-20-22.2-5.1-16.5-6.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M368.1,393.2c.6,0,1.2-.2,1.7-.5c2.2-1.6,3.4-4.9,3.5-9.7.2-7.4-2.8-10.2-5.4-11.1-5.3-1.9-12.4,2-13.7,3.6-.2.2-.3.5-.4.8c0,.3,0,.6.2.8.5.8,1.6.6,2,.5l.2-.1c4.3-1,7.2-.7,8.9.9s2,4.5,1.2,8.6c-.1.5-.3,1-.6,1.4-.4.6-.7,1.3-.6,2c0,.7.3,1.4.9,1.9.3.3.6.5,1,.7.2.1.6.2,1.1.2Zm-3.4-19.8c.8,0,1.5.1,2.2.3c3,.9,4.4,4.1,4.3,9.2-.1,4.2-1,6.9-2.6,8.1-.2.1-.4.2-.7.2-.2,0-.4-.1-.6-.3s-.3-.4-.3-.6s0-.5.2-.6c.5-.7.9-1.4,1-2.2c1-4.9.4-8.4-1.7-10.5-1.8-1.7-4.5-2.4-8-1.9c1.9-1.1,4-1.6,6.2-1.7v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M366.3,378.4c1.9-.4,27.4-5.9,11.5,10.7-2,2.1-4.3-7.8-4.3-7.8s3.2,21.5,12,11c8-9.5-24.9-12.6-19.2-13.9Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M380.9,396.2c1.7,0,3.5-1.1,5.3-3.2c1.4-1.6,1.8-3.2,1.3-4.8-.5-1.7-2.2-3.2-4.4-4.4c1-2,1.2-3.7.4-5-.6-1.1-3.8-4.5-17.5-1.4-.4.1-1.4.3-1.5,1.3c0,1.2,1.2,1.5,5.5,2.7l2.4.7c.5,2.8,2.5,12.6,7.3,13.9.5.2.9.2,1.2.2Zm1.2-10.7c1.8,1,3.2,2,3.5,3.2.3.9,0,1.8-.9,2.9-1.6,2-3.1,2.8-4.3,2.4-1.2-.3-2.3-1.8-3.2-3.7.3,0,.5-.1.7-.2s.4-.3.6-.4c1.3-1.2,2.5-2.7,3.6-4.2Zm-1.9-.8c-.9,1.3-2,2.5-3.1,3.6-.6-.6-1.5-2.9-2.3-5.5c1.9.5,3.7,1.1,5.4,1.9Zm-4.4-6.5c3.6,0,5.5.8,6,1.6.3.5.4,1.4-.5,3.1-3.5-1.4-7-2.6-10.6-3.4l-1.9-.5c2.2-.5,4.6-.8,7-.8Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M224.3,327.1c1.9-.4,27.4-5.9,11.5,10.7-2,2.1-4.3-7.8-4.3-7.8s3.2,21.5,12,11c8-9.6-24.9-12.6-19.2-13.9Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M239,344.8c1.7,0,3.5-1.1,5.3-3.2c1.4-1.6,1.8-3.2,1.3-4.8-.5-1.7-2.2-3.2-4.4-4.4c1-2,1.1-3.7.4-5-.6-1.1-3.8-4.5-17.5-1.4-.4.1-1.4.3-1.5,1.4c0,1.2,1.1,1.5,5.5,2.7l2.4.7c.5,2.9,2.5,12.6,7.3,13.9.4.1.8.1,1.2.1Zm1.1-10.6c1.8,1,3.2,2,3.6,3.2.3.9,0,1.8-.9,2.9-1.6,1.9-3.1,2.8-4.3,2.4-1.2-.3-2.3-1.8-3.2-3.7.3,0,.5-.1.7-.2s.4-.3.6-.4c1.2-1.3,2.4-2.7,3.5-4.2Zm-1.8-.9c-.9,1.3-2,2.5-3.1,3.6-.6-.6-1.5-2.9-2.3-5.5c1.8.5,3.6,1.2,5.4,1.9Zm-4.5-6.5c3.6,0,5.5.8,6,1.6.3.5.4,1.4-.5,3.1-3.5-1.4-7-2.6-10.6-3.4l-1.9-.5c2.3-.5,4.6-.7,7-.8Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M356.4,384.8c.2-2-6.2,4-8.8-2.3-2.2-5.2,5.3-7.6,5.3-7.6s-8.9-.6-10,6.7c-1.6,10.4,12.9,11.2,13.5,3.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M350,391.2c1.2,0,2.4-.2,3.5-.7s2.1-1.3,2.8-2.2c.7-1,1.1-2.2,1.2-3.4c0-.2,0-.5-.1-.7s-.2-.4-.4-.6c-.7-.5-1.5-.2-2.5.2-2.5.9-4.6,1.4-5.9-1.7-.2-.4-.3-.9-.3-1.3c0-.5.1-.9.3-1.4c1-2.3,4.6-3.5,4.7-3.5l4.9-1.6-5.1-.4c-3.4-.2-10.1,1-11.1,7.6-.5,3.4.5,6.3,2.9,8c1.3,1.1,3.2,1.7,5.1,1.7Zm5.3-5.6c-.2.7-.5,1.3-1,1.8s-1,.9-1.7,1.2c-1.1.5-2.3.6-3.4.5-1.2-.1-2.3-.6-3.2-1.2-1.2-.9-2.6-2.8-2.1-6.1.1-1.2.6-2.2,1.3-3.1s1.7-1.6,2.8-2c-.6.5-1,1.2-1.4,1.9-.3.7-.5,1.4-.5,2.2c0,.7.2,1.5.5,2.2c2.1,5,6.4,3.5,8.4,2.8l.3-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M349.1,383.3c1.4,1.5,1.4-7.3,7.8-4.8c5.2,2,1.9,9.1,1.9,9.1s6.6-6,2-11.9c-6.5-8.4-17,1.5-11.7,7.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M355.6,391.7l3.8-3.5c2.5-2.3,6.2-8,2.2-13.2-2.1-2.7-4.9-3.9-7.8-3.5-1.5.3-2.9.9-4.1,1.9s-2.1,2.3-2.6,3.7c-.4,1.1-.5,2.4-.3,3.6s.8,2.3,1.6,3.2c.1.2.3.3.6.4.2.1.5.1.7.1.8-.1,1.2-.9,1.6-2c1.1-2.5,2.2-4.3,5.3-3.1.4.1.9.4,1.2.7s.6.7.8,1.1c.8,2-.2,4.9-.7,5.8l-2.3,4.8Zm-.6-18.3c1.4,0,3.3.6,5,2.8.7.9,1.2,2,1.3,3.1s0,2.3-.5,3.4c0-.8-.2-1.6-.4-2.3-.3-.7-.7-1.3-1.3-1.8-.5-.5-1.2-.9-1.9-1.1-5.1-1.9-7,2.2-7.8,4.2l-.1.2c-.4-.6-.6-1.3-.6-2-.1-.7,0-1.4.3-2c.4-1.1,1.1-2.1,2-2.8s2-1.3,3.1-1.5c.3-.1.6-.2.9-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M247.1,326.1c.2-2-6.2,4-8.8-2.3-2.2-5.2,5.3-7.6,5.3-7.6s-8.9-.6-10,6.7c-1.6,10.5,12.9,11.2,13.5,3.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M240.7,332.5c1.2,0,2.4-.2,3.5-.7s2.1-1.3,2.8-2.3s1.1-2.2,1.2-3.4c0-.2,0-.5-.1-.7s-.2-.4-.4-.6c-.7-.5-1.5-.2-2.5.2-2.5.9-4.6,1.4-5.9-1.7-.2-.4-.3-.9-.3-1.3c0-.5.1-.9.3-1.4c1-2.3,4.6-3.5,4.7-3.5l4.9-1.6-5.1-.4c-3.4-.2-10.1,1-11.1,7.6-.5,3.4.5,6.3,2.9,8c1.4,1.3,3.2,1.8,5.1,1.8Zm5.3-5.6c-.2.7-.5,1.3-1,1.8s-1,.9-1.7,1.2c-1.1.5-2.3.6-3.4.5-1.2-.1-2.3-.6-3.2-1.2-1.2-.9-2.6-2.8-2.1-6.1.1-1.2.6-2.2,1.3-3.1s1.7-1.6,2.8-2c-.6.5-1,1.2-1.4,1.9-.3.7-.5,1.4-.5,2.2s.2,1.5.5,2.2c2.1,5,6.4,3.5,8.4,2.8l.3-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M239.8,324.6c1.4,1.5,1.4-7.3,7.8-4.8c5.2,2,1.9,9.1,1.9,9.1s6.6-6,2-11.9c-6.5-8.4-17,1.6-11.7,7.6Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M246.3,333l3.8-3.5c2.5-2.3,6.2-8,2.2-13.3-2.1-2.7-4.9-3.9-7.8-3.5-1.5.3-2.9.9-4.1,1.9s-2.1,2.3-2.6,3.7c-.4,1.1-.5,2.4-.3,3.6s.8,2.3,1.6,3.2c.1.2.3.3.6.4.2.1.5.1.7.1.8-.1,1.2-.9,1.6-2c1.1-2.5,2.2-4.3,5.3-3.1.4.1.8.4,1.2.7.3.3.6.7.8,1.1.9,2.4-.7,5.8-.7,5.8l-2.3,4.9Zm-.6-18.2c1.4,0,3.3.6,5,2.8.7.9,1.2,2,1.3,3.1s0,2.3-.5,3.4c0-.8-.2-1.6-.4-2.3-.3-.7-.7-1.3-1.3-1.8-.5-.5-1.2-.9-1.9-1.1-5.1-1.9-7,2.2-7.8,4.2l-.1.2c-.4-.6-.6-1.3-.6-2-.1-.7,0-1.4.3-2c.4-1.1,1.1-2.1,2-2.8.9-.8,2-1.3,3.1-1.5.3-.2.6-.2.9-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M393.2,366.7c-1.6-1.2-12.5-8.3.3-15.7.6-.3,1.2-.6,1.9-.6c2.2-.1,2.5-3.4.3-3.9-2-.5-4.9.4-8.7,3.6-15.3,13.1,10.9,20,6.2,16.6Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M393.3,368.6h.1c.3,0,.6-.1.8-.2.2-.2.4-.4.5-.7.3-.9-.6-1.6-1-1.8l-.2-.1c-3.6-2.5-5.3-5-5.2-7.3s2-4.5,5.6-6.6c.4-.3.9-.4,1.4-.5.7,0,1.4-.3,1.9-.8s.8-1.2.9-1.9-.1-1.4-.5-2-1-1-1.8-1.2c-2.7-.6-5.9.7-9.6,3.8-5.7,4.8-5.7,8.9-4.7,11.4c2.1,5.2,9.6,7.9,11.8,7.9Zm1.2-21.2c.3,0,.6,0,.9.1.2.1.4.2.5.4s.2.4.1.6c0,.2-.1.4-.2.6-.2.2-.4.3-.6.3-.8.1-1.6.3-2.3.7-4.3,2.5-6.5,5.3-6.7,8.3-.1,2.5,1.2,4.9,3.9,7.3-2.4-1-5.4-2.9-6.6-5.5-1.3-2.8.1-6,4-9.3c2.8-2.3,5.1-3.5,7-3.5Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M232.2,331.4c-1.6-1.2-12.5-8.3.3-15.7.6-.4,1.2-.6,1.9-.6c2.2-.1,2.5-3.4.3-3.9-2-.5-4.9.4-8.7,3.6-15.3,13,10.9,20,6.2,16.6Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M232.3,333.3h.1c.3,0,.6,0,.8-.2s.4-.4.5-.7c.3-.9-.6-1.6-1-1.8l-.2-.1c-3.6-2.5-5.3-5-5.2-7.3s2-4.5,5.6-6.6c.4-.3.9-.4,1.4-.5.7,0,1.4-.3,1.9-.8s.8-1.2.8-1.9c.1-.7-.1-1.4-.5-2s-1-1-1.8-1.2c-2.7-.6-5.9.7-9.6,3.8-5.7,4.8-5.7,8.9-4.7,11.4c2.2,5.1,9.7,7.9,11.9,7.9Zm1.2-21.3c.3,0,.6,0,.9.1.2.1.4.2.5.4s.2.4.1.6c0,.2-.1.4-.2.6-.2.2-.4.3-.6.3-.8.1-1.6.3-2.3.7-4.3,2.5-6.5,5.3-6.7,8.3-.1,2.5,1.2,4.9,3.9,7.3-2.4-1-5.4-2.9-6.6-5.5-1.3-2.8.1-6,4-9.3c2.8-2.3,5.2-3.5,7-3.5Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M362.6,404.7c-1.6-1.2-22.6-16.5.3-15.7c2.9.1-2.9,8.4-2.9,8.4s13.8-16.7.2-16.3c-12.4.4,7.2,27,2.4,23.6Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M363.3,406.1c.3,0,.5-.1.7-.3.9-.8.4-1.9-1.7-5.9-.3-.7-.7-1.4-1.1-2.3c1.8-2.3,7.7-10.3,5.6-14.7-.9-1.9-3.1-2.9-6.6-2.8-2.1.1-3.6.8-4.5,2.2-.9,1.6-.9,3.8-.3,6.2-2.2.6-3.5,1.6-4,3-.4,1.2-.9,5.8,10.6,14c.4.3.8.5,1.3.6ZM356,390.5c1.3,3.5,2.8,7,4.5,10.3.3.6.6,1.2.9,1.8-6.7-5.1-8.5-8.9-8-10.3.2-.6.8-1.3,2.6-1.8Zm6.7-.5c0,.8-1.1,3.1-2.6,5.4-.8-1.7-1.5-3.5-2.1-5.3c1.6-.1,3.2-.1,4.7-.1Zm-2-7.8c2.3,0,3.7.5,4.3,1.6.5,1.1.2,2.9-.6,4.8-.2-.2-.4-.3-.6-.5-.2-.1-.5-.2-.7-.2-1.8-.1-3.7,0-5.5.2-.5-2-.6-3.7.1-4.8.5-.8,1.4-1.2,2.8-1.2h.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M229.5,354.6c-1.6-1.2-22.6-16.5.3-15.7c2.9.1-2.9,8.4-2.9,8.4s13.8-16.7.2-16.3c-12.4.4,7.2,27,2.4,23.6Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M230.1,356c.3,0,.5-.1.7-.3.9-.8.4-1.9-1.7-5.9-.3-.7-.7-1.4-1.1-2.3c1.8-2.3,7.7-10.3,5.6-14.7-.9-1.9-3.1-2.9-6.6-2.8-2.1.1-3.6.8-4.5,2.2-.9,1.6-.9,3.8-.3,6.2-2.2.6-3.5,1.6-4,3-.4,1.2-.9,5.8,10.6,14c.5.4.9.5,1.3.6Zm-7.2-15.6c1.3,3.5,2.8,7,4.5,10.3.3.6.6,1.2.9,1.8-6.7-5.1-8.5-8.9-8-10.3.2-.6.7-1.3,2.6-1.8Zm6.7-.4c0,.8-1.1,3.1-2.6,5.4-.8-1.7-1.5-3.5-2.1-5.2c1.6-.3,3.2-.3,4.7-.2Zm-2-7.9c2.3,0,3.7.5,4.3,1.6.5,1.1.2,2.9-.6,4.8-.2-.2-.4-.4-.6-.5s-.5-.2-.7-.2c-1.8-.1-3.7,0-5.5.2-.5-2-.6-3.7.1-4.8.5-.8,1.4-1.2,2.8-1.2h.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M350.5,272.5c4-1.9-14.7-7.5-5.5-19.7c7.6-10,20.6,1.7,20.6,1.7s-8.7-18-24.1-11.9c-21.9,8.7-7.3,37.7,9,29.9Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M344.9,274.9c2.1,0,4.1-.5,6-1.4.3-.1.6-.3.8-.5s.3-.5.4-.9c.2-1.2-1-2.2-2.7-3.6-4.7-4.1-8.6-8.3-3.5-15c.7-1,1.6-1.8,2.6-2.5c1.1-.6,2.2-1,3.4-1.1c6.2-.6,12.9,5.4,13,5.5l3.8,3.5-2.3-4.7c-.1-.2-9.3-18.8-25.4-12.4-6.7,2.6-10.8,7.4-11.6,13.6-.3,3.2.2,6.4,1.4,9.4c1.3,3,3.3,5.5,5.9,7.4c2.5,1.7,5.3,2.6,8.2,2.7Zm5-3.2c-1.9.9-4,1.3-6.1,1.1s-4.1-1-5.8-2.3c-2.3-1.7-4.1-4-5.2-6.6s-1.6-5.5-1.3-8.3c.7-5.3,4.3-9.6,10.3-11.9c9.6-3.8,16.5,2.4,20.1,7-2.8-1.6-6.5-3.2-10.1-2.8-1.5.2-2.9.6-4.2,1.4s-2.4,1.8-3.3,3c-6.7,8.8.2,14.6,3.8,17.8.6.4,1.2,1,1.8,1.6Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M380.5,258.3c-2.6-3.6-4.9,15.8-18.5,8.8-11.1-5.8-1.8-20.6-1.8-20.6s-16.2,11.7-7.6,25.9c12.3,20,38.3.6,27.9-14.1Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M366.3,282h.8c3.2-.2,6.3-1.3,9-3c2.7-1.8,4.9-4.2,6.3-7.1c1.2-2.2,1.7-4.7,1.5-7.2s-1.1-4.9-2.6-6.9c-.2-.3-.4-.5-.7-.7s-.6-.2-.9-.2c-1.2,0-2,1.4-3.1,3.3-3.2,5.4-6.7,9.9-14.1,6-1.1-.5-2.1-1.3-2.9-2.2s-1.4-2-1.7-3.2c-1.6-6,3.1-13.7,3.1-13.8l2.8-4.4-4.2,3c-.2.1-16.9,12.4-7.8,27.2c3.5,5.9,8.7,9.2,14.5,9.2Zm13.4-23c1.2,1.7,1.9,3.7,2.1,5.8.1,2.1-.3,4.2-1.3,6.1-1.3,2.5-3.2,4.7-5.6,6.3s-5.1,2.5-8,2.7c-5.4.2-10.1-2.6-13.5-8.1s-3.2-11.3.5-17.1c.9-1.4,1.9-2.7,3-3.9-1.1,3-2,7-1.1,10.6.4,1.4,1.1,2.8,2.1,3.9s2.2,2.1,3.5,2.7c9.8,5.1,14.4-2.7,16.8-6.8.6-.8,1-1.5,1.5-2.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M376.5,271.8c-.8-1.1-1.5,4.7-5.5,2.6-3.3-1.7-.5-6.1-.5-6.1s-4.8,3.5-2.3,7.7c3.7,5.9,11.4.1,8.3-4.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M372.3,279.5h.3c1.1-.1,2.1-.4,3-1s1.6-1.4,2.1-2.4c.4-.8.6-1.6.5-2.5s-.4-1.7-.9-2.4c-.1-.2-.3-.3-.5-.4s-.4-.1-.6-.1c-.7,0-1.1.7-1.5,1.4-1,1.7-1.7,2.3-3.3,1.5-.2-.1-.5-.3-.6-.5-.2-.2-.3-.4-.4-.7-.3-1.2.5-3,.9-3.5l2.7-4.4-4.2,3c-1.9,1.4-4.9,5.1-2.5,9c1.3,1.9,3,3,5,3Zm3.9-6c.1.6,0,1.2-.3,1.7-.3.6-.8,1.2-1.4,1.6s-1.3.6-2,.7c-1.4.1-2.5-.7-3.4-2.1-.5-.8-.7-1.7-.6-2.5.1.5.4,1,.8,1.4s.8.8,1.3,1c3,1.6,4.7-.4,5.6-1.8v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M387,222.5c-.4,2.5-6.6-6.8-11.7.4-4.2,5.9,4.4,11,4.4,11s-11.3-1.7-10.6-11.2c1-13.6,19.4-10.5,17.9-.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M384.6,235.6l-4.4-2.7c0,0-4.3-2.6-5-5.9-.1-.6-.1-1.3,0-1.9s.4-1.2.8-1.7c2.6-3.6,5.3-2.4,8.2-.5c1.2.8,2,1.3,2.9.9.2-.1.4-.3.6-.5.1-.2.2-.4.3-.7.2-1.5,0-3-.5-4.4s-1.5-2.6-2.8-3.5c-1.6-1.1-3.4-1.9-5.3-2.1s-3.9.1-5.6.8c-3.4,1.5-5.4,4.7-5.7,9-.6,8.3,7.2,11.7,11.5,12.3l5,.9Zm-4.8-16.5c-1.8,0-3.6.8-5.3,3.2-.6.7-1,1.6-1.2,2.5s-.2,1.8,0,2.7c.3,1.4,1,2.7,1.9,3.8-2.7-1.4-5.4-4-5.1-8.6s2.7-6.5,4.5-7.3c1.4-.6,3-.8,4.6-.7c1.6.2,3,.7,4.3,1.7.8.6,1.5,1.4,2,2.3.4.9.7,1.9.6,3l-.7-.4c-1.3-.9-3.4-2.2-5.6-2.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M376.1,252.6c-1.7-2.4-3.3,10.6-12.4,5.9-7.4-3.8-1.2-13.8-1.2-13.8s-10.8,7.8-5.1,17.3c8.2,13.4,25.6.4,18.7-9.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M366.6,268.8h.5c2.2-.1,4.3-.9,6.2-2.1c1.8-1.2,3.3-2.9,4.3-4.9.8-1.5,1.1-3.3,1-5s-.7-3.4-1.8-4.8c-.1-.2-.3-.4-.6-.5-.2-.1-.5-.2-.8-.2-1,0-1.5,1-2.4,2.4-2,3.5-4.3,6.4-9,3.9-.7-.3-1.3-.8-1.8-1.4s-.9-1.3-1.1-2c-1.1-3.9,2-8.9,2-9l2.7-4.4-4.2,3c-.1.1-11.5,8.5-5.3,18.7c2.7,4,6.3,6.3,10.3,6.3Zm9-15.1c.7,1.1,1,2.3,1.1,3.6c0,1.3-.2,2.5-.8,3.6-.8,1.6-2.1,3-3.6,4.1-1.5,1-3.3,1.6-5.2,1.8-2.4.1-5.9-.7-8.7-5.3-2.2-3.6-2.1-7.3.3-11.1.3-.5.6-.9.9-1.3-.6,1.8-.6,3.8-.2,5.6.3,1,.8,2,1.5,2.8s1.5,1.5,2.5,1.9c6.8,3.5,10.1-2,11.7-4.7c0-.4.3-.8.5-1v0Zm.5-1.1l-.8.6.8-.6Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M390,234.6c-3,0,6.8-8.7-2.2-13.5-7.4-4-12,6.8-12,6.8s.3-13.4,11.4-14c15.6-.8,14.8,20.9,2.8,20.7Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M390.1,235.7c1.7,0,3.4-.4,4.9-1.3s2.7-2.1,3.5-3.6c1.1-1.9,1.6-4.1,1.6-6.4c0-2.2-.7-4.4-1.8-6.3-2.2-3.6-6.2-5.4-11.1-5.2-11.9.7-12.3,14.8-12.3,15l-.1,5.2l2-4.7c0-.1,2.4-5.5,6.2-6.8.7-.2,1.5-.3,2.3-.3.8.1,1.5.3,2.2.7c4.7,2.5,3.6,6,1.9,9.7-.7,1.5-1.1,2.5-.6,3.3.1.2.4.4.6.5s.5.2.8.2h-.1v0Zm-2-20.8c4.7,0,7.2,2.3,8.4,4.2.9,1.6,1.4,3.4,1.5,5.3c0,1.8-.4,3.7-1.3,5.3-.6,1.1-1.5,2.1-2.5,2.8-1.1.7-2.3,1.1-3.6,1.1.1-.3.3-.7.5-1.1c1.3-2.8,4-8.7-2.8-12.3-.9-.5-1.9-.9-3-1s-2.1,0-3.1.4c-1.8.7-3.3,1.9-4.5,3.4.2-.5.3-1,.6-1.5c1.8-4.1,4.7-6.3,8.9-6.6.3,0,.6,0,.9,0v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M370.5,233.2c-1.1-1.6-2.2,7.1-8.3,3.9-5-2.6-.8-9.2-.8-9.2s-7.2,5.2-3.4,11.6c5.5,9,17.1.3,12.5-6.3Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M364.2,244.4h.4c1.5-.1,3-.6,4.3-1.4s2.3-2,3-3.4c.6-1.1.8-2.3.7-3.5s-.5-2.4-1.2-3.4c-.1-.2-.3-.4-.5-.5s-.4-.2-.7-.2c-.8,0-1.3.8-1.9,1.7-1.4,2.3-2.7,4-5.6,2.5-.4-.2-.8-.5-1.1-.8-.3-.4-.5-.8-.7-1.2-.7-2.5,1.4-5.7,1.4-5.7l2.7-4.4-4.2,3c-2.8,2-7.1,7.3-3.7,12.9c1.8,2.8,4.3,4.4,7.1,4.4Zm5.9-9.7c.3.6.4,1.3.4,2s-.2,1.4-.5,2c-.5,1-1.3,1.9-2.3,2.6-1,.6-2.1,1-3.3,1.1-1.5.1-3.8-.5-5.5-3.4-.6-1-1-2.1-1-3.3s.3-2.3.9-3.3c-.1.8,0,1.6.2,2.3s.6,1.4,1,2c.5.6,1.1,1,1.8,1.4c4.9,2.5,7.2-1.4,8.3-3.3v-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M363.9,236.8c2,.3-3.6-6.5,2.9-8.7c5.3-1.8,7.2,5.8,7.2,5.8s1.2-8.9-6-10.4c-10.3-2.3-12,12.1-4.1,13.3Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M374.3,239.1l.7-5.1c.5-3.4-.3-10.2-6.8-11.6-3.4-.7-6.3.1-8.2,2.3-1,1.2-1.7,2.6-1.9,4.1-.3,1.5-.1,3.1.4,4.5.4,1.1,1.1,2.2,2.1,2.9.9.8,2.1,1.3,3.3,1.4.2,0,.5,0,.7,0c.2-.1.4-.2.6-.4.6-.6.3-1.5,0-2.6-.7-2.6-1-4.7,2-5.8.4-.2.9-.3,1.4-.2.5,0,.9.1,1.3.4c1.9,1,2.9,3.9,3.2,4.9l1.2,5.2Zm-5.9-12.3c-.7,0-1.3.1-1.9.3-5.2,1.8-3.9,6.1-3.4,8.2c0,.1,0,.1.1.2-.7-.2-1.2-.6-1.7-1.1s-.9-1.1-1.1-1.8c-.4-1.1-.5-2.3-.3-3.5s.7-2.2,1.5-3.1c1-1.2,2.9-2.4,6.3-1.7c1.1.2,2.2.8,3,1.6s1.5,1.8,1.8,2.9c-.5-.6-1.1-1.1-1.8-1.5-.9-.3-1.7-.5-2.5-.5v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M350.9,278c-2-.3,3.5,6.5-3,8.6-5.3,1.7-7.1-5.9-7.1-5.9s-1.4,8.8,5.9,10.5c10.1,2.5,12.1-11.9,4.2-13.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M348.8,292.6c1.1,0,2.2-.2,3.2-.6s1.9-1.1,2.6-1.9c1-1.2,1.7-2.6,2-4.1s.2-3.1-.3-4.5c-.4-1.2-1.1-2.2-2-3s-2.1-1.3-3.3-1.5c-.2-.1-.5,0-.7,0s-.4.2-.6.4c-.6.6-.3,1.5-.1,2.6.7,2.6,1,4.7-2.1,5.7-.4.2-.9.2-1.4.2s-.9-.2-1.3-.4c-2.2-1.2-3.1-4.9-3.1-5l-1.2-5-.8,5.1c-.5,3.4.2,10.2,6.6,11.7.8.2,1.7.3,2.5.3Zm2.8-13.3c.7.2,1.2.6,1.7,1.1s.8,1.1,1.1,1.8c.4,1.1.4,2.3.2,3.5s-.7,2.2-1.5,3.1c-1,1.2-3,2.4-6.3,1.6-1.1-.2-2.2-.8-3-1.6s-1.4-1.8-1.7-2.9c.5.6,1.1,1.1,1.8,1.5s1.4.6,2.1.6c.7.1,1.5,0,2.2-.3c5.2-1.7,4.1-6.1,3.5-8.2-.1-.1-.1-.2-.1-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M366.1,258.1c1.3,3.7,6.5-9.5,15.5,1.1c7.4,8.7-2.3,15.7-2.3,15.7s14.3-2,10.3-15.8c-5.7-19.5-28.8-15.9-23.5-1Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M375.5,276.4l4-.5c.3,0,6.9-1,10.1-6c1.9-3,2.3-6.7,1-11-2.1-7.3-7-12.5-13.1-13.8-4.3-1-8.5.1-11,2.9-2.3,2.6-2.8,6.4-1.4,10.5.1.3.2.6.4.8s.5.4.8.5c1,.3,1.9-.4,3-1.4c2.6-2,6.1-4.8,11.4,1.4c1.9,2.3,2.8,4.6,2.5,6.9-.5,4.3-4.5,7.3-4.6,7.3l-3.1,2.4Zm-1.1-29.7c.9,0,1.7.1,2.6.3c5.4,1.2,9.7,5.9,11.6,12.4c1.1,3.8.8,6.9-.7,9.3-1.3,1.8-3,3.2-5.1,4c1.3-1.7,2.2-3.7,2.5-5.8.3-2.9-.7-5.8-3-8.5-6.6-7.7-11.7-3.7-14.2-1.7-.4.3-.8.7-1.1.8-1.1-3.4-.8-6.3,1-8.4.8-.9,1.8-1.5,2.9-2c1.1-.2,2.3-.4,3.5-.4Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M348.8,285.2c1.6-1.2-7.1-2-4.2-8.1c2.4-5.1,9.2-1.1,9.2-1.1s-5.4-7.1-11.6-3c-8.9,5.7.1,17.1,6.6,12.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M345.4,287.4c1.4,0,2.9-.5,4-1.4.2-.1.4-.3.5-.5s.2-.4.1-.7c-.1-.8-.8-1.3-1.8-1.8-2.4-1.3-4.1-2.5-2.7-5.5.2-.4.4-.8.8-1.1.3-.3.8-.6,1.2-.7c2.4-.8,5.7,1.1,5.7,1.2l4.4,2.6-3.1-4.1c-2.1-2.7-7.4-6.9-13-3.3-2.9,1.9-4.3,4.5-4.1,7.5.1,1.5.7,3,1.6,4.3s2.1,2.3,3.5,2.9c.9.4,1.9.6,2.9.6Zm2-2.5c-.6.3-1.3.5-2,.5s-1.4-.2-2-.5c-1.1-.5-2-1.3-2.7-2.2-.7-1-1.1-2.1-1.2-3.2-.1-1.6.3-3.8,3.2-5.6c1-.7,2.1-1,3.2-1.1c1.2-.1,2.3.2,3.3.8-.8-.1-1.6,0-2.3.2s-1.4.6-1.9,1.1c-.6.5-1,1.1-1.3,1.8-2.4,4.9,1.6,7.1,3.5,8.2h.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M409,225.4c-2-.3,3.5,6.5-3,8.6-5.3,1.8-7.1-5.9-7.1-5.9s-1.4,8.8,5.9,10.5C415,241,417,226.7,409,225.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M407,239.9c1.1,0,2.2-.2,3.2-.6s1.9-1.1,2.6-1.9c1-1.2,1.7-2.6,2-4.1s.2-3.1-.3-4.5c-.4-1.2-1.1-2.2-2-3s-2.1-1.3-3.3-1.5c-.2-.1-.5,0-.7,0s-.4.2-.6.4c-.6.6-.3,1.5-.1,2.6.7,2.6,1,4.7-2.1,5.7-.4.2-.9.2-1.4.2s-.9-.2-1.3-.4c-2.2-1.2-3.1-4.9-3.1-5l-1.2-5-.8,5.1c-.5,3.4.2,10.2,6.6,11.7.8.2,1.6.3,2.5.3Zm2.8-13.3c.7.2,1.2.6,1.7,1.1s.8,1.1,1.1,1.8c.4,1.1.4,2.3.2,3.5s-.7,2.2-1.5,3.1c-1,1.2-3,2.4-6.3,1.6-1.1-.2-2.2-.8-3-1.6s-1.4-1.8-1.7-2.9c.5.6,1.1,1.1,1.8,1.5s1.4.6,2.1.6c.7.1,1.5,0,2.2-.3c5.2-1.7,4.1-6.1,3.5-8.2-.1-.1-.1-.1-.1-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M398.2,248.1c-2,.1-14.9,1.7-9.8-12.3.2-.6.6-1.2,1.1-1.7c1.6-1.5-.3-4.2-2.3-3.2-1.9,1-3.5,3.4-4.4,8.3-3.4,19.9,21.2,8.4,15.4,8.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M390.4,252c4,0,8-1.7,9.1-2.6.2-.2.4-.4.5-.7s.1-.6,0-.8c-.4-.9-1.5-.8-1.9-.8h-.2c-4.4.4-7.3-.4-8.7-2.2s-1.4-4.7.1-8.7c.2-.5.4-.9.8-1.3.5-.5.9-1.2.9-1.9.1-.7-.1-1.4-.6-2-.4-.6-1-1-1.7-1.2s-1.4-.1-2.1.2c-2.4,1.2-4.1,4.3-4.9,9.1-1.3,7.3,1.3,10.4,3.7,11.7c1.6.8,3.3,1.2,5,1.2Zm4.8-2.8c-2.5.8-6,1.3-8.6,0-2.8-1.4-3.8-4.7-2.9-9.8.7-4.1,2-6.7,3.8-7.6.2-.1.4-.1.7-.1.2.1.4.2.5.4s.2.4.2.6-.1.4-.3.6c-.6.6-1.1,1.3-1.4,2.1-1.7,4.7-1.6,8.2.2,10.6c1.7,2,4.2,3.1,7.8,3.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M388,244.6c-2,.1-27.9,1.8-9.8-12.3c2.3-1.8,3.1,8.4,3.1,8.4s0-21.7-10.2-12.7c-9.3,8.3,22.8,16.2,16.9,16.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M383.6,245.8c1.4,0,2.9-.1,4.5-.2.4,0,1.5-.1,1.6-1.1.2-1.2-.9-1.7-5.1-3.5l-2.3-1c-.1-2.9-.6-12.9-5.1-14.9-2-.9-4.2-.2-6.8,2.1-1.6,1.4-2.3,2.9-2,4.6.3,1.8,1.7,3.5,3.8,5-1.3,1.8-1.7,3.4-1.1,4.8.3,1.1,2.4,4.2,12.5,4.2Zm2.1-2.1c-5.2.1-11.8-.5-12.7-2.8-.2-.5-.2-1.4.9-3c3.2,1.9,6.6,3.6,10,5l1.8.8Zm-7-10.4c.5.7,1.1,3.1,1.4,5.8-1.7-.8-3.4-1.7-5-2.7c1.2-1.1,2.4-2.2,3.6-3.1Zm0-2.2c-.4,0-.8.2-1.2.4-1.5,1.1-2.9,2.3-4.1,3.7-1.7-1.2-2.8-2.5-3-3.7-.1-.9.3-1.8,1.4-2.7c1.9-1.7,3.5-2.3,4.6-1.7c1.2.5,2,2.1,2.6,4.1-.1,0-.2-.1-.3-.1v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M398.7,239.8c-.4,1.9,6.7-3.1,8.4,3.6c1.4,5.4-6.3,6.7-6.3,6.7s8.7,2,10.9-5.2c3-10-11.2-12.9-13-5.1Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M403.4,251.4c3.4,0,7.8-1.3,9.3-6.1c1-3.3.4-6.3-1.7-8.4-1.1-1.1-2.5-1.9-3.9-2.3-1.5-.4-3.1-.4-4.5,0-1.2.3-2.2.9-3.1,1.8-.8.9-1.4,2-1.7,3.2-.1.2-.1.5,0,.7s.2.4.3.6c.6.6,1.5.4,2.5.2c2.6-.5,4.7-.7,5.6,2.5.1.4.2.9.1,1.4s-.2.9-.5,1.3c-1.4,2.1-5.1,2.8-5.2,2.8l-5.1.9l5,1.1c1,.2,1.9.3,2.9.3Zm1.3-15c1.8,0,3.5.7,4.8,1.9c1.1,1.1,2.2,3.1,1.2,6.4-.3,1.1-.9,2.1-1.8,2.9s-1.9,1.3-3,1.5c.6-.5,1.2-1,1.6-1.7.4-.6.7-1.3.8-2.1.1-.7.1-1.5-.2-2.2-1.4-5.3-5.8-4.4-7.9-4h-.2c.3-.6.7-1.2,1.2-1.6s1.2-.8,1.8-.9c.6-.2,1.1-.2,1.7-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M405.7,242.4c-1.1-1.7-2.4,7-8.4,3.6-4.9-2.7-.5-9.2-.5-9.2s-7.4,5-3.8,11.4c5.2,9.2,17.1.9,12.7-5.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M399.3,253.3v0c1.6,0,3.1-.5,4.4-1.3s2.4-1.9,3.1-3.3c.6-1.1.9-2.3.8-3.5s-.4-2.4-1.1-3.4c-.1-.2-.3-.4-.5-.5s-.4-.2-.7-.2c-.8,0-1.3.7-1.9,1.7-1.5,2.3-2.8,3.9-5.7,2.3-.4-.2-.8-.5-1.1-.9s-.5-.8-.6-1.2c-.6-2.5,1.5-5.6,1.5-5.7l2.9-4.3-4.3,2.9c-2.8,1.9-7.3,7-4.1,12.8c1.8,3,4.4,4.6,7.3,4.6Zm6-9.5c.3.6.4,1.3.3,2c0,.7-.2,1.4-.6,2-.6,1-1.4,1.9-2.4,2.5s-2.1,1-3.3,1c-1.6,0-3.7-.6-5.4-3.6-.6-1-.9-2.1-.9-3.3s.4-2.3,1-3.3c-.1.8-.1,1.6.1,2.3s.5,1.4,1,2s1.1,1.1,1.7,1.4c4.7,2.7,7.2-1.1,8.4-3h.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M359.7,252.2c1.4,1.4,11.1,10-2.6,15.5-.6.3-1.3.4-2,.3-2.2-.2-3,3-.9,3.8c2,.8,4.9.4,9.1-2.3c17.1-10.6-7.8-21.4-3.6-17.3Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M356.2,273.2c2.2,0,4.7-.9,7.7-2.8c6.3-3.9,6.9-7.9,6.3-10.6-1.3-5.5-8.4-9.3-10.5-9.5-.3-.1-.6,0-.8.1-.3.1-.5.3-.6.6-.4.9.4,1.6.7,1.9l.2.2c3.2,3,4.5,5.7,4.1,8s-2.6,4.1-6.6,5.7c-.5.2-1,.3-1.5.3-.7-.1-1.4.1-2,.5s-1,1.1-1.1,1.8c-.2.7-.1,1.4.2,2.1.3.6.9,1.1,1.6,1.4.7.1,1.5.3,2.3.3Zm6.3-19.6c2.2,1.4,4.9,3.6,5.7,6.4.8,3-1,5.9-5.3,8.6-3.5,2.2-6.3,2.9-8.2,2.2-.2-.1-.4-.2-.5-.5-.1-.2-.1-.4-.1-.7c0-.2.2-.4.3-.6.2-.1.4-.2.6-.2.8.1,1.7-.1,2.4-.4c4.6-1.8,7.2-4.2,7.8-7.2.5-2.2-.4-4.8-2.7-7.6Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M395.5,219.2c1.4,1.4,19.9,19.7-2.6,15.5-2.9-.5,4.1-7.9,4.1-7.9s-16.2,14.4-2.7,16.1c12.5,1.5-3-27.8,1.2-23.7Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M395.3,243.9c.7,0,1.4-.1,2.1-.4.6-.3,1.2-.7,1.7-1.2c1.1-1.4,1.4-3.6,1.2-6.1c2.2-.2,3.7-1,4.4-2.4.6-1.1,1.7-5.6-8.4-15.4-.3-.3-1.1-1-1.9-.5-1,.6-.6,1.8.8,6.1.2.7.5,1.5.8,2.4-2.1,2-9.2,9-7.7,13.7.6,2,2.7,3.3,6.1,3.7.2.1.6.1.9.1Zm2.9-7.6c.2,2,0,3.8-.8,4.7-.6.7-1.5,1-2.9.8-2.5-.3-4-1.1-4.4-2.3s.2-2.9,1.3-4.7c.1.2.3.4.5.5s.5.2.7.3c1.9.4,3.8.6,5.6.7v0Zm-1.6-7.6c.5,1.8,1,3.6,1.4,5.5-1.6-.1-3.2-.3-4.7-.6.1-.8,1.5-2.8,3.3-4.9Zm-.2-7.2c5.8,6.1,7.1,10.1,6.4,11.4-.2.5-1,1.1-2.8,1.3-.7-3.7-1.7-7.3-2.9-10.8-.3-.7-.5-1.3-.7-1.9Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M306.4,252c4.4-.1-10.4-12.9,3-20.2c11-6.1,18.1,9.9,18.1,9.9s-.7-20-17.2-20.7c-23.6-1-21.9,31.4-3.9,31Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M306,253h.4c.3,0,.6,0,.9-.2.3-.1.5-.4.7-.6.6-1,0-2.4-1-4.4-2.7-5.6-4.5-11.1,2.9-15.1c1-.6,2.2-1,3.4-1.2c1.2-.1,2.4,0,3.6.4c5.9,2,9.6,10.2,9.7,10.3l2.1,4.7-.2-5.2c0-.2-.9-20.9-18.2-21.7-7.2-.3-12.9,2.4-16.1,7.7-1.6,2.8-2.5,5.9-2.5,9.2c0,3.2.8,6.4,2.4,9.2c1.2,2.1,2.9,3.9,5,5.1s4.4,1.8,6.9,1.8Zm.2-2.1h-.2c-2.1,0-4.1-.5-5.9-1.6-1.8-1-3.3-2.6-4.2-4.4-1.4-2.5-2.1-5.3-2.1-8.2s.8-5.7,2.2-8.1c2.8-4.6,7.8-7,14.3-6.7c10.3.4,14.1,8.9,15.5,14.6-1.9-2.6-4.6-5.5-8.2-6.7-1.4-.5-2.9-.6-4.4-.4s-2.9.7-4.2,1.5c-9.7,5.3-5.8,13.4-3.7,17.8.2.7.6,1.5.9,2.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M339.6,251.1c-.9-4.4-10.9,12.4-20.4.5-7.8-9.8,6.7-19.6,6.7-19.6s-19.6,4.1-17.4,20.5c2.9,23.5,34.6,16.3,31.1-1.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M324,268.5c4.3,0,8.5-1.5,11.8-4.2c2-1.6,3.4-3.6,4.3-6c.8-2.4,1-4.9.5-7.3c0-.3-.2-.6-.3-.9-.2-.3-.4-.5-.7-.6-1.1-.4-2.3.4-4.2,1.7-5.1,3.6-10.1,6.3-15.4-.2-.8-.9-1.4-2-1.8-3.2s-.4-2.4-.3-3.6c.9-6.2,8.4-11.3,8.5-11.3l4.3-2.9-5,1c-.2,0-20.4,4.4-18.2,21.7.9,7.1,4.6,12.3,10.3,14.6c1.9.8,4.1,1.2,6.2,1.2Zm14.6-17c.4,2.1.3,4.2-.4,6.2s-2,3.7-3.6,5c-2.2,1.8-4.9,3-7.7,3.5s-5.7.2-8.4-.8c-5-2-8.2-6.5-9-12.9s1.7-11.6,7.4-15.5c1.4-.9,2.8-1.7,4.3-2.3-2.2,2.3-4.7,5.5-5.2,9.2-.2,1.5-.1,3,.3,4.4s1.1,2.8,2.1,3.9c6.9,8.6,14.2,3.4,18.1.6.7-.4,1.4-.9,2.1-1.3v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M330.5,261.8c-.3-1.3-3.2,3.7-6.1.1-2.3-2.9,2-5.8,2-5.8s-5.8,1.2-5.2,6.1c.9,7,10.3,4.9,9.3-.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M325.8,267.7c1.4,0,2.8-.5,4-1.4.7-.5,1.2-1.3,1.5-2.1s.4-1.7.2-2.6c0-.2-.1-.4-.3-.6-.1-.2-.3-.3-.5-.4-.7-.3-1.3.1-1.9.6-1.6,1.1-2.5,1.4-3.6.1-.2-.2-.3-.4-.4-.7s-.1-.5-.1-.8c.2-1.3,1.7-2.5,2.2-2.9l4.2-2.9-5,1c-2.3.5-6.6,2.6-6,7.2.3,2.4,1.6,4.1,3.5,4.9.8.5,1.5.6,2.2.6Zm3.6-4.4c-.2.6-.5,1.1-.9,1.4-.6.5-1.2.8-1.9.9s-1.4.1-2.1-.2c-1.2-.5-2-1.6-2.2-3.3-.1-.9,0-1.8.5-2.6-.1.5,0,1.1.1,1.6s.4,1,.8,1.4c2.1,2.8,4.4,1.7,5.7.8Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M360,221.1c-1.4,2.2-3.3-8.9-10.9-4.5-6.2,3.7-.4,11.9-.4,11.9s-9.7-6.2-5.1-14.6c6.4-11.9,21.9-1.6,16.4,7.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M352.5,232.1l-3-4.2c0,0-2.9-4.2-2.2-7.4.1-.6.4-1.2.8-1.7s.9-.9,1.5-1.2c3.9-2.3,5.8,0,7.7,2.9.8,1.2,1.4,2,2.3,2c.3,0,.5-.1.7-.2s.4-.3.5-.5c.8-1.3,1.3-2.7,1.3-4.3c0-1.5-.3-3-1.1-4.3-1-1.7-2.3-3-4-4-1.6-1-3.5-1.5-5.4-1.5h-.1c-3.7,0-6.9,2.1-8.9,5.9-3.9,7.3,1.9,13.6,5.5,15.9l4.4,2.6Zm0-17.6c-1.4,0-2.7.5-3.9,1.2-.8.4-1.5,1.1-2.1,1.8s-.9,1.6-1.1,2.5c-.3,1.4-.2,2.9.2,4.3-1.9-2.4-3.4-5.9-1.2-9.9s5.1-4.8,7.1-4.9h.1c1.6,0,3.1.5,4.4,1.3s2.4,1.9,3.2,3.3c.5.9.8,1.9.9,2.9c0,1-.2,2-.6,3-.1-.2-.3-.4-.4-.6-1.1-1.8-3.2-4.9-6.6-4.9v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M337.8,244.1c-.6-2.9-7.3,8.3-13.7.3-5.2-6.6,4.5-13.1,4.5-13.1s-13.1,2.7-11.6,13.7c2,15.7,23.2,11,20.8-.9Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M327.5,256c3,0,5.8-1,8.1-2.9c1.3-1.1,2.4-2.5,2.9-4.2.6-1.6.7-3.4.3-5.1c0-.3-.1-.5-.3-.7s-.4-.4-.6-.5c-.9-.4-1.8.3-3.1,1.2-3.3,2.3-6.5,4.1-9.8-.1-.5-.6-.9-1.3-1.1-2s-.3-1.5-.2-2.3c.6-3.9,5.5-7.3,5.5-7.3l4.3-2.9-5,1c-.1,0-14,3-12.5,14.9.7,4.9,3.2,8.5,7.1,10c1.4.7,2.9,1,4.4.9Zm9.4-11.1c.2,1.3,0,2.5-.5,3.7s-1.2,2.2-2.2,3c-1.4,1.2-3.2,1.9-5,2.2s-3.7.1-5.4-.5c-2.3-.9-5.2-3.1-5.8-8.4-.5-4.1,1.1-7.5,4.8-10c.5-.3.9-.6,1.4-.8-1.2,1.4-2.1,3.2-2.5,5.1-.2,1-.1,2.1.2,3.1s.8,1.9,1.5,2.7c4.8,6,10.1,2.3,12.6.5.3-.1.7-.4.9-.6v0Zm.9-.8l-1,.2l1-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M369.5,215c1.6,2.5-11-.8-10,9.4.8,8.4,12.4,6.2,12.4,6.2s-11.2,7.2-17.9-1.6c-9.6-12.6,8.9-24.1,15.5-14Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M362.6,234.4c5.2,0,9.7-2.9,9.8-2.9l4.3-2.8-5.1.9c-.1,0-5.9,1.1-9.1-1.4-.6-.5-1.1-1.1-1.5-1.7-.4-.7-.6-1.4-.6-2.2-.5-5.3,3-6.3,6.9-7c1.6-.3,2.7-.4,3.1-1.4.1-.2.1-.5.1-.8s-.1-.5-.3-.7c-.9-1.5-2.2-2.6-3.8-3.4s-3.3-1.1-5-.9c-2.2.2-4.3,1-6.1,2.2-1.8,1.3-3.3,3-4.2,5-1.7,3.9-1.1,8.2,1.9,12.1c1.1,1.5,2.5,2.7,4.1,3.6c1.8,1,3.7,1.4,5.5,1.4Zm4.1-2.7c-.5.1-1,.3-1.5.4-4.4.8-7.9-.4-10.4-3.8-3.2-4.3-2.7-7.8-1.7-10.1.8-1.7,2-3.1,3.5-4.2s3.3-1.7,5.1-1.9c1.3-.1,2.5.1,3.7.6s2.2,1.3,2.9,2.3c-.4.1-.8.1-1.2.2-3.1.5-9.4,1.5-8.6,9.2.1,1,.4,2.1.9,3s1.2,1.7,2,2.4c1.5,1.1,3.4,1.8,5.3,1.9v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M340.6,224.2c-.4-1.9-4.9,5.6-9.1.2-3.5-4.4,3-8.7,3-8.7s-8.7,1.8-7.8,9.2c1.3,10.4,15.5,7.2,13.9-.7Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M333.6,232.5c2.1,0,4-.7,5.6-2c.9-.8,1.7-1.8,2.1-2.9s.5-2.4.2-3.6c0-.2-.1-.5-.3-.6-.1-.2-.3-.3-.5-.4-.8-.3-1.5.2-2.4.8-2.2,1.6-4.1,2.6-6.1,0-.3-.3-.5-.8-.7-1.2-.1-.4-.2-.9-.1-1.4.3-2.1,2.7-4.1,3.5-4.7l4.3-2.9-5.1,1c-3.3.7-9.4,3.7-8.6,10.3.4,3.4,2.2,5.9,5,7c1.1.4,2.1.6,3.1.6Zm6.1-7.2c0,.7-.1,1.4-.4,2s-.7,1.2-1.3,1.6c-.9.7-2,1.2-3.2,1.4s-2.4.1-3.5-.3c-1.4-.6-3.3-1.9-3.7-5.3-.2-1.1,0-2.3.4-3.4s1.2-2,2.1-2.6c-.4.7-.7,1.4-.8,2.2-.1.7-.1,1.5.1,2.2s.6,1.4,1.1,2c3.4,4.3,7.1,1.6,8.9.4l.3-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M333.1,224.8c1.7,1.1-.7-7.4,6.1-6.8c5.6.5,4.3,8.2,4.3,8.2s4.7-7.6-1.3-12c-8.4-6.2-15.8,6.2-9.1,10.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M341.7,231.1l2.7-4.4c1.8-2.9,3.8-9.4-1.5-13.3-2.8-2-5.8-2.5-8.5-1.2-1.4.7-2.6,1.7-3.4,3-.9,1.3-1.4,2.8-1.5,4.3-.1,1.2.1,2.4.7,3.5.5,1.1,1.4,2,2.4,2.6.2.1.4.2.7.3.2,0,.5,0,.7-.1.8-.4.9-1.2,1.1-2.3.4-2.7.9-4.7,4.2-4.4.5,0,.9.1,1.3.3s.8.5,1.1.9c1.6,2,.9,5.8.9,5.8l-.9,5Zm-3.1-14.2c-4.7,0-5.3,4.1-5.6,6.2c0,.1,0,.1,0,.2-.5-.5-.9-1.1-1.1-1.7s-.3-1.3-.3-2c.1-1.2.5-2.3,1.2-3.3s1.6-1.8,2.6-2.3c1.4-.7,3.7-1,6.4,1c.9.7,1.7,1.6,2.1,2.7.5,1.1.6,2.2.4,3.4-.2-.8-.6-1.5-1-2.1-.5-.6-1-1.1-1.7-1.4-.7-.4-1.4-.6-2.1-.6-.4-.1-.6-.1-.9-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M304.5,257.1c-1.7-1.1.6,7.4-6.2,6.7-5.6-.6-4.1-8.3-4.1-8.3s-4.8,7.5,1.1,12c8.3,6.4,15.9-6,9.2-10.4Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M300,270.3c1,0,2.1-.2,3-.7c1.4-.7,2.6-1.7,3.5-2.9.9-1.3,1.4-2.7,1.6-4.3.1-1.2-.1-2.4-.6-3.5s-1.4-2-2.4-2.7c-.2-.1-.4-.2-.7-.3-.2,0-.5,0-.7.1-.8.3-.9,1.2-1.1,2.3-.4,2.7-1,4.7-4.3,4.4-.5,0-.9-.1-1.3-.4-.4-.2-.8-.5-1.1-.9-1.5-2-.9-5.8-.8-5.8l.9-5.1-2.8,4.3c-1.8,2.9-3.9,9.4,1.4,13.4c1.5,1.4,3.4,2.1,5.4,2.1Zm4.6-11.8c.5.5.9,1.1,1.1,1.7s.3,1.3.2,2c-.1,1.2-.5,2.3-1.2,3.3s-1.6,1.7-2.6,2.2c-1.4.6-3.7,1-6.4-1.1-.9-.7-1.7-1.6-2.1-2.7s-.6-2.3-.4-3.4c.2.8.6,1.5,1,2.1s1,1.1,1.7,1.5s1.4.6,2.1.6c5.4.6,6.2-3.9,6.5-6.1.1.1.1,0,.1-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M326.5,245.1c-.3,3.9,9.8-6.1,13.7,7.3c3.2,10.9-8.5,13.4-8.5,13.4s13.9,4.1,15.8-10.2c2.8-20.2-19.8-26.2-21-10.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M336,267.4c2.5.1,5-.6,7.2-2c3-1.9,4.7-5.2,5.4-9.7c1-7.5-1.4-14.2-6.4-18-3.5-2.6-7.8-3.3-11.2-1.8-3.2,1.5-5.2,4.7-5.5,9.1c0,.3,0,.6.1.9s.3.6.5.8c.8.7,1.9.4,3.3,0c3.2-.8,7.5-1.9,9.9,6c.8,2.9.7,5.3-.5,7.3-2.2,3.7-7.1,4.8-7.2,4.8l-3.9.8l3.9,1.1c1.3.5,2.9.7,4.4.7Zm-1.2-30.2c2.2.1,4.4.8,6.1,2.2c4.4,3.3,6.5,9.3,5.6,16.1-.5,3.9-2,6.6-4.5,8.2-1.9,1.1-4.1,1.7-6.3,1.6c1.9-1,3.5-2.5,4.6-4.3c1.5-2.5,1.7-5.5.7-9-2.9-9.7-9.2-8.1-12.3-7.3-.4.1-.9.2-1.4.3.3-3.5,1.8-6.1,4.3-7.2c1.2-.4,2.2-.6,3.2-.6Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M299.6,262.8c1.9-.4-5.7-4.7-.5-9.1c4.3-3.6,8.8,2.7,8.8,2.7s-2.1-8.7-9.4-7.5c-10.3,1.7-6.7,15.7,1.1,13.9Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M298.3,264c.5,0,1.1-.1,1.6-.2.2,0,.4-.1.6-.3s.3-.3.4-.6c.3-.8-.3-1.5-.9-2.4-1.6-2.2-2.7-4-.2-6.1.3-.3.7-.6,1.2-.7.4-.1.9-.2,1.4-.2c2.5.3,4.7,3.4,4.8,3.4l3,4.2-1.2-5c-.8-3.3-4.1-9.3-10.6-8.3-3.4.5-5.8,2.4-6.8,5.2-.5,1.5-.6,3-.3,4.5s1,2.9,2,4.1c.6.7,1.4,1.3,2.2,1.7.9.5,1.8.7,2.8.7Zm.2-2c-.7,0-1.4-.1-2-.4s-1.2-.7-1.6-1.2c-.8-.9-1.3-2-1.5-3.1s-.2-2.3.2-3.5c.5-1.5,1.8-3.3,5.2-3.9c1.1-.2,2.3-.1,3.4.3s2,1.1,2.7,2.1c-.7-.4-1.4-.6-2.2-.7-.7-.1-1.5,0-2.2.2s-1.4.6-1.9,1.1c-4.2,3.6-1.4,7.2-.1,8.9v.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M379,232.7c-1.7-1.1.6,7.4-6.2,6.7-5.6-.6-4.1-8.3-4.1-8.3s-4.8,7.5,1.1,12c8.3,6.3,15.9-6,9.2-10.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M374.5,245.9c1,0,2.1-.2,3-.7c1.4-.7,2.6-1.7,3.5-2.9.9-1.3,1.4-2.7,1.6-4.3.1-1.2-.1-2.4-.6-3.5s-1.4-2-2.4-2.7c-.2-.1-.4-.2-.7-.3-.2,0-.5,0-.7.1-.8.3-.9,1.2-1.1,2.3-.4,2.7-1,4.7-4.3,4.4-.5,0-.9-.1-1.3-.4-.4-.2-.8-.5-1.1-.9-1.3-1.7-1-4.7-.8-5.8l1-5.1-2.8,4.4c-1.9,2.9-4,9.4,1.3,13.4c1.5,1.2,3.4,1.9,5.4,2Zm4.7-11.8c.5.5.9,1.1,1.1,1.7.2.7.3,1.4.2,2-.1,1.2-.5,2.3-1.2,3.3s-1.6,1.7-2.6,2.2c-1.4.6-3.7,1-6.4-1.1-.9-.7-1.7-1.6-2.1-2.7s-.6-2.3-.4-3.4c.2.8.5,1.5,1,2.1.4.6,1,1.1,1.7,1.4.7.4,1.4.6,2.1.6c5.4.6,6.2-3.9,6.5-6.1.1.1.1.1.1,0v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M359.9,249c-1.9-.7-14.3-4.5-4-15.2.5-.5,1-.9,1.6-1.1c2.1-.7,1.4-4-.8-3.9-2.1.1-4.6,1.7-7.4,5.8-11.1,16.8,16.1,16.4,10.6,14.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M358.5,251c.7,0,1.4-.1,2-.2.3-.1.5-.2.7-.4s.3-.5.3-.8c0-1-1-1.4-1.4-1.5l-.1-.1c-4.1-1.4-6.5-3.3-7-5.6s.7-4.8,3.6-7.9c.3-.4.8-.7,1.3-.8.7-.2,1.2-.7,1.6-1.3s.5-1.4.3-2.1c-.1-.7-.5-1.4-1.1-1.8-.6-.5-1.3-.7-2-.6-2.7.1-5.5,2.3-8.2,6.3-4.1,6.2-3,10.1-1.4,12.2c2.7,3.5,8.2,4.6,11.4,4.6Zm-1.6-21.1c.2,0,.4.1.6.2s.3.4.3.6c.1.2,0,.5-.1.7s-.3.4-.5.4c-.8.3-1.5.8-2,1.4-3.4,3.6-4.8,6.8-4.2,9.8.6,2.4,2.5,4.4,5.7,6-2.6-.3-6-1.3-7.8-3.5-2-2.4-1.6-5.8,1.3-10.1c2.3-3.6,4.6-5.4,6.7-5.5v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M352,241.7c-1.9-.7-26.3-9.7-4-15.2c2.9-.7-.5,8.9-.5,8.9s8.8-19.8-4.2-15.8c-11.9,3.8,14.2,24.1,8.7,22.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M352.6,242.9c.2,0,.4,0,.6-.1s.3-.2.4-.4c.7-1-.2-1.9-3.2-5.2-.5-.6-1.1-1.2-1.7-1.9c1.1-2.7,4.6-12,1.4-15.7-1.4-1.6-3.8-1.9-7.1-.9-2,.6-3.3,1.8-3.7,3.4-.5,1.8.2,3.9,1.4,6.1-1.9,1.2-2.9,2.5-3,4-.1,1.3.7,5.8,14,10.6.2,0,.5.1.9.1Zm-10.9-13c2.2,3.1,4.5,5.9,7.1,8.6l1.4,1.5c-7.8-3.1-10.6-6.3-10.5-7.8c0-.5.4-1.4,2-2.3v0Zm6.4-2.3c.2.8-.2,3.2-1,5.9-1.2-1.4-2.4-2.9-3.5-4.5c1.5-.6,3-1.1,4.5-1.4v0Zm.2-2.1c-.2,0-.4,0-.5.1-1.8.4-3.6,1-5.3,1.7-1-1.8-1.6-3.4-1.3-4.6.2-.9,1-1.5,2.3-1.9c2.4-.8,4.1-.7,4.9.3s1,2.7.7,4.8c-.1-.3-.5-.4-.8-.4v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M363.7,241.7c-1.2,1.6,7.4-.1,6.2,6.7-.9,5.5-8.5,3.6-8.5,3.6s7.2,5.3,12-.3c6.9-8-4.9-16.4-9.7-10Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M367.9,255.2c1.2,0,2.4-.2,3.5-.7s2-1.2,2.8-2.2c2.3-2.6,2.9-5.6,1.9-8.3-.6-1.4-1.5-2.7-2.7-3.7s-2.6-1.6-4.1-1.8c-1.2-.2-2.4-.1-3.6.4-1.1.5-2.1,1.2-2.8,2.2-.2.2-.3.4-.3.6s0,.5.1.7c.3.8,1.2,1,2.2,1.2c2.6.6,4.6,1.3,4.1,4.6-.1.5-.2.9-.5,1.3-.2.4-.6.7-1,1-1.8,1.2-4.8.7-5.8.5l-5-1.3l4.2,3.1c2,1.5,4.5,2.4,7,2.4Zm.3-14.8c.3,0,.5,0,.8.1c1.2.2,2.2.7,3.2,1.4.9.7,1.6,1.7,2.1,2.8.5,1.5.7,3.7-1.5,6.3-.7.9-1.7,1.6-2.8,1.9-1.1.4-2.3.4-3.4.2.8-.2,1.5-.4,2.2-.9.6-.4,1.2-.9,1.6-1.6.4-.6.7-1.3.8-2.1.9-5.4-3.5-6.4-5.6-6.9h-.2c.6-.8,1.7-1.2,2.8-1.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M369.1,246.9c-.3-2-5,5.4-9.1-.1-3.4-4.5,3.3-8.6,3.3-8.6s-8.8,1.5-8.1,8.9c.9,10.5,15.1,7.8,13.9-.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M362.1,255c2,0,3.9-.6,5.4-1.8c1-.7,1.7-1.7,2.2-2.8.4-1.1.6-2.4.4-3.6c0-.2-.1-.5-.2-.6-.1-.2-.3-.3-.5-.4-.8-.3-1.5.1-2.4.8-2.3,1.5-4.1,2.4-6.1-.2-.3-.4-.5-.8-.6-1.2-.1-.5-.1-.9-.1-1.4.5-2.5,3.7-4.5,3.7-4.6l4.4-2.7-5.1.9c-3.4.6-9.6,3.4-8.9,10c.3,3.4,2,6,4.7,7.1.9.2,2,.5,3.1.5Zm6-7c0,.7-.2,1.4-.5,2s-.8,1.2-1.3,1.6c-.9.7-2,1.2-3.2,1.3-1.2.2-2.3,0-3.4-.4-1.4-.6-3.2-2-3.5-5.5-.1-1.2.1-2.3.5-3.4.5-1.1,1.3-1.9,2.2-2.6-.4.7-.7,1.4-.9,2.2-.1.7-.1,1.5.1,2.2s.5,1.4,1,2c3.3,4.4,7,1.9,8.9.7l.1-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M332.5,245.9c2-.2-5.2-5.3.5-9.1c4.6-3.2,8.5,3.7,8.5,3.7s-1.1-8.9-8.5-8.5c-10.5.5-8.4,14.8-.5,13.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M331.8,246.9c.3,0,.6,0,.9-.1.2,0,.5-.1.7-.2s.4-.3.5-.5c.4-.8-.1-1.5-.7-2.5-1.4-2.3-2.2-4.2.5-6.1.4-.3.8-.5,1.3-.6s.9-.1,1.4,0c2.5.6,4.4,3.9,4.4,3.9l2.5,4.5-.7-5.1c-.4-3.4-3-9.7-9.6-9.4-3.4.2-6,1.8-7.3,4.4-.6,1.4-.9,2.9-.8,4.5.1,1.5.7,3,1.5,4.3.6.9,1.4,1.6,2.4,2.1.9.6,1.9.8,3,.8Zm3.7-12c-1.1,0-2.2.4-3.1,1-4.5,3.1-2.2,7-1.1,8.8l.1.2c-.7,0-1.4-.2-2-.6-.6-.3-1.1-.8-1.5-1.4-.7-1-1.1-2.1-1.2-3.3s.1-2.3.6-3.4c.7-1.4,2.2-3.1,5.6-3.3c1.2-.1,2.3.2,3.3.7s1.9,1.4,2.5,2.4c-.6-.4-1.4-.8-2.1-1-.3-.1-.7-.1-1.1-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M335.2,230.4c1.9-.7,26.4-9.5,12.8,9.1-1.7,2.4-5.3-7.2-5.3-7.2s6,20.8,13.4,9.3c6.7-10.5-26.4-9.1-20.9-11.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M351.5,246.1c1.9,0,3.7-1.3,5.4-3.9c1.1-1.8,1.4-3.4.7-4.9-.8-1.6-2.6-2.8-5-3.7.7-2.1.7-3.8-.2-5-.8-1-4.3-4-17.5.9-.3.1-1.4.5-1.3,1.5.1,1.2,1.4,1.4,5.8,1.9l2.5.3c.9,2.8,4.2,12.2,9,12.9.2,0,.4,0,.6,0Zm.3-10.7c1.9.7,3.4,1.6,3.9,2.7.4.8.2,1.8-.5,3-1.4,2.1-2.7,3.1-4,3-1.3-.2-2.5-1.5-3.7-3.2.3,0,.5-.1.7-.3.2-.1.4-.3.6-.5c1.1-1.5,2.1-3.1,3-4.7v0Zm-2-.6c-.8,1.4-1.6,2.7-2.6,4-.7-.5-1.9-2.6-3-5.2c2,.3,3.8.7,5.6,1.2v0Zm-2.7-6c2,0,3.2.4,3.7,1c.3.4.6,1.4-.1,3.1-3.6-.9-7.3-1.6-11-2l-2-.3c4.1-1.3,7.2-1.8,9.4-1.8v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M335.9,241.6c-.1-2-5.6,4.8-9.1-1.1-2.8-4.8,4.2-8.2,4.2-8.2s-8.9.5-9,8c-.1,10.5,14.3,9.3,13.9,1.3Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M329.1,248.9c1.6,0,3.2-.4,4.6-1.3c1-.6,1.9-1.5,2.5-2.6s.8-2.3.8-3.5c0-.2-.1-.5-.2-.7s-.3-.4-.5-.5c-.7-.4-1.5,0-2.5.5-2.4,1.2-4.4,2-6-.9-.3-.4-.4-.8-.5-1.3s0-.9.1-1.4c.7-2.4,4.1-4.1,4.2-4.1l4.6-2.2-5.1.3c-3.4.2-9.9,2.3-10,9c0,3.4,1.4,6.1,3.9,7.6c1.2.7,2.6,1.1,4.1,1.1Zm5.7-6.4c-.1.7-.3,1.3-.7,1.9s-.9,1.1-1.5,1.4c-1,.6-2.2.9-3.3,1-1.2,0-2.3-.2-3.4-.8-1.4-.8-2.9-2.4-2.9-5.8c0-1.2.3-2.3.9-3.3s1.5-1.8,2.5-2.3c-.5.6-.8,1.3-1.1,2-.2.7-.3,1.5-.2,2.2s.4,1.5.8,2.1c2.8,4.7,6.8,2.6,8.7,1.7l.2-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M305.4,246.4c1.5,1.3.4-7.4,7.1-5.8c5.5,1.3,3,8.7,3,8.7s5.8-6.8.5-12c-7.5-7.4-16.7,3.9-10.6,9.1Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M313,253.9l3.3-3.9c2.2-2.6,5.1-8.8.4-13.4-2.4-2.4-5.4-3.3-8.2-2.4-1.5.5-2.8,1.3-3.8,2.5-1,1.1-1.8,2.5-2.1,4-.3,1.2-.2,2.4.2,3.6s1.1,2.2,2,3c.2.2.4.3.6.3.2.1.5.1.7,0c.8-.2,1.1-1.1,1.4-2.2.8-2.6,1.6-4.5,4.8-3.8.5.1.9.3,1.3.5.4.3.7.6.9,1c1.3,2.2.1,5.8.1,5.9l-1.6,4.9Zm-2.1-14.5c-3.9,0-4.9,3.6-5.4,5.4l-.1.2c-.4-.5-.7-1.2-.9-1.9s-.1-1.4,0-2.1c.3-1.1.8-2.2,1.6-3.1s1.8-1.5,2.9-1.9c1.5-.4,3.8-.5,6.2,1.9.8.8,1.4,1.8,1.7,2.9s.3,2.3-.1,3.4c-.1-.8-.4-1.5-.7-2.2-.4-.7-.9-1.2-1.5-1.7-.6-.4-1.3-.7-2-.9-.5.1-1.1,0-1.7,0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M323.1,237.1c.7,1.8,6.1,13.7-8.7,13.1-.7,0-1.3-.2-1.9-.5-1.9-1.1-3.9,1.6-2.4,3.1c1.6,1.6,4.3,2.3,9.3,1.6c19.8-2.7,1.5-22.7,3.7-17.3Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M316.1,255.7c1.1,0,2.3-.1,3.4-.3c7.4-1,9.5-4.4,10.1-7.1c1.1-5.5-3.9-11.9-5.7-13-.2-.2-.5-.3-.8-.3s-.6.1-.8.3c-.7.6-.3,1.7-.2,2l.1.2c1.7,4.1,1.8,7.1.5,9s-4.1,2.7-8.3,2.5c-.5,0-1-.1-1.5-.4-.6-.4-1.4-.5-2.1-.3s-1.3.6-1.7,1.1c-.5.6-.7,1.3-.6,2c0,.7.3,1.4.9,1.9c1.4,1.7,3.7,2.4,6.7,2.4Zm8.9-16.1c1.5,2.1,3,5.3,2.6,8.2-.5,3.1-3.3,5-8.4,5.7-4.1.6-7,.1-8.4-1.4-.2-.2-.3-.4-.3-.6s.1-.5.2-.6c.1-.2.3-.3.5-.4s.5,0,.7.1c.7.4,1.5.6,2.4.6c4.9.2,8.3-.9,10-3.4c1.5-2,1.7-4.7.7-8.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M369.2,221.5c.7,1.8,10.2,26.1-8.7,13.1-2.4-1.7,7-5.5,7-5.5s-20.6,6.6-9,13.6c10.7,6.4,8.5-26.6,10.7-21.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M361.1,244.5c.6,0,1.3-.1,1.9-.4c1.6-.8,2.8-2.7,3.6-5.1c2.1.7,3.8.6,5-.4c1-.8,3.9-4.4-1.4-17.5-.1-.3-.6-1.4-1.5-1.2-1.2.2-1.3,1.4-1.8,5.9-.1.8-.2,1.6-.3,2.5-2.7.9-12,4.5-12.6,9.4-.2,2.1,1.1,4.1,4.1,5.9.9.6,1.9.9,3,.9Zm3.6-6.2c-.7,1.9-1.5,3.5-2.6,4-.8.4-1.8.3-3-.5-2.2-1.3-3.2-2.6-3.1-3.9s1.4-2.6,3.1-3.8c0,.3.1.5.3.7.1.2.3.4.6.5c1.5,1.2,3,2.1,4.7,3Zm4.4-14.3c2.9,7.9,2.4,12.1,1.2,13-.4.3-1.4.6-3.1,0c.8-3.6,1.4-7.3,1.7-11.1c0-.6.1-1.2.2-1.9v0Zm-2.8,6.7c-.2,1.9-.6,3.7-1,5.6-1.4-.7-2.8-1.5-4.1-2.4.5-.8,2.6-2,5.1-3.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M426.4,306.6c1.4,2.9,5.4-10.8,14.3-4.2c7.4,5.5-1.1,15.1-1.1,15.1s13.1-6.5,8.5-17.8c-6.5-15.9-27.5-4.9-21.7,6.9Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M435.5,320.8l4.6-2.3c.1-.1,13.8-7.1,9-19.1-2-5-5.7-8-10.2-8.5-2.4-.2-4.8.2-7,1.2s-4.1,2.5-5.5,4.5c-1.1,1.5-1.8,3.3-1.9,5.2-.2,1.9.2,3.7,1.1,5.4.1.3.3.5.5.7s.5.3.8.3c1,.1,1.8-.8,2.9-2.1c2.8-3.4,5.8-6.2,10.5-2.7.7.5,1.3,1.1,1.8,1.8.4.7.7,1.5.8,2.4.5,4.4-3.8,9.3-3.8,9.4l-3.6,3.8Zm2-28c.4,0,.8,0,1.1.1c3.8.4,6.8,3,8.6,7.3c1.7,4.3,1,8.3-2.3,12.1-.5.5-1,1-1.5,1.5c1-2,1.5-4.2,1.4-6.4-.1-1.1-.5-2.2-1.1-3.2s-1.4-1.8-2.3-2.4c-6.7-5-11.2.4-13.3,3-.3.4-.7.8-.9,1.1-.6-1.3-.8-2.7-.6-4.2.2-1.4.7-2.8,1.5-3.9c1.1-1.5,2.5-2.7,4.2-3.5s3.3-1.5,5.2-1.5Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M436.6,337.9c3.6-2.6-15.8-4.7-9-18.4c5.6-11.2,20.5-2,20.5-2s-11.8-16.1-25.9-7.3c-19.8,12.5-.1,38.3,14.4,27.7Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M429.3,341.4c2.9,0,5.7-1,8-2.7.3-.2.5-.4.6-.7s.2-.6.2-.9c-.1-1.2-1.4-2-3.3-3.1-5.4-3.1-10-6.5-6.2-14.1.5-1.1,1.2-2.1,2.2-2.9.9-.8,2-1.4,3.2-1.7c6-1.7,13.7,2.9,13.8,3l4.4,2.7-3.1-4.2c-.1-.2-12.6-16.8-27.3-7.5-6.1,3.8-9.3,9.3-8.9,15.5.2,3.2,1.3,6.3,3.1,9s4.3,4.8,7.2,6.2c1.8.9,3.9,1.4,6.1,1.4Zm6.6-4.2c-1.7,1.2-3.7,2-5.8,2.2s-4.2-.2-6.1-1.2c-2.6-1.3-4.7-3.2-6.3-5.5-1.6-2.4-2.6-5.1-2.8-7.9-.3-5.4,2.5-10.2,8-13.6c5.4-3.4,11.2-3.3,17.1.3c1.4.9,2.7,1.9,3.9,3-3-1.1-7-2-10.6-.9-1.4.4-2.8,1.1-3.9,2.1s-2,2.2-2.7,3.6c-4.9,9.9,2.8,14.4,7,16.8.8.1,1.5.6,2.2,1.1v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M423.1,334.1c1.1-.8-4.7-1.4-2.7-5.5c1.7-3.3,6.1-.6,6.1-.6s-3.5-4.8-7.7-2.2c-5.9,3.8,0,11.4,4.3,8.3Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M420.9,335.8c1,0,2-.3,2.8-.9.2-.1.3-.3.4-.5s.1-.4.1-.6c0-.7-.7-1.1-1.4-1.5-1.7-1-2.3-1.7-1.6-3.2.1-.2.3-.5.5-.6.2-.2.4-.3.7-.4c1.2-.4,3,.5,3.5.8l4.4,2.7-3-4.1c-1.4-1.9-5.1-4.9-9-2.4-2,1.3-3.1,3.1-3,5.2.1,1.1.4,2.1,1,3s1.4,1.6,2.4,2.1c.8.2,1.5.4,2.2.4Zm.5-2.1c-.6.1-1.2,0-1.7-.3-.6-.3-1.2-.8-1.6-1.4s-.6-1.3-.7-2c-.1-1.4.6-2.5,2-3.4.7-.5,1.6-.7,2.5-.6-.5.2-1,.4-1.4.8s-.7.8-1,1.3c-1.5,3.2.5,4.8,1.9,5.6v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M472.4,344c-2.5-.3,6.7-6.7-.5-11.7-5.9-4.1-10.9,4.6-10.9,4.6s1.6-11.4,11.1-10.8c13.5.8,10.6,19.3.3,17.9Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M473.5,345.1c1.3,0,2.6-.3,3.8-.9s2.2-1.5,2.9-2.6c1.1-1.6,1.8-3.4,2-5.3s-.1-3.9-.9-5.6c-1.6-3.4-4.8-5.4-9.1-5.6-8.3-.5-11.6,7.4-12.2,11.6l-.7,5.1l2.6-4.4c0,0,2.6-4.4,5.9-5.1.6-.1,1.3-.1,1.9,0s1.2.4,1.7.8c3.7,2.6,2.4,5.3.6,8.2-.7,1.2-1.3,2-.9,2.9.1.2.3.4.5.6.2.1.5.2.7.2.4.1.8.1,1.2.1Zm-2.1-18h.7c4.5.3,6.5,2.6,7.4,4.5.6,1.4.9,3,.7,4.6s-.7,3-1.6,4.3c-.6.8-1.4,1.5-2.3,2s-1.9.7-3,.6c.1-.2.3-.5.4-.7c1.4-2.3,4.4-7.1-1.2-11-.7-.6-1.6-.9-2.5-1.1s-1.8-.2-2.7,0c-1.4.4-2.7,1-3.8,2c1.4-2.6,3.7-5.2,7.9-5.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M442.3,333.4c2.4-1.7-10.6-3.1-6-12.3c3.8-7.5,13.7-1.4,13.7-1.4s-7.9-10.8-17.3-4.9c-13.3,8.4-.2,25.7,9.6,18.6Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M437.3,336.1c2,0,3.9-.7,5.5-1.8.2-.1.4-.3.5-.6.1-.2.2-.5.2-.8c0-1-1-1.5-2.4-2.3-3.5-2-6.4-4.2-4-9c.3-.7.8-1.3,1.3-1.8.6-.5,1.3-.9,2-1.1c3.9-1.1,8.9,1.9,9,2l4.4,2.7-3.1-4.2c-.1-.1-8.6-11.5-18.7-5.1-4.2,2.6-6.4,6.4-6.1,10.7.2,2.2.9,4.3,2.1,6.2c1.2,1.8,2.9,3.3,4.9,4.3c1.5.5,3,.8,4.4.8Zm3.9-3.2c-1.1.7-2.3,1.1-3.6,1.1-1.3.1-2.5-.2-3.7-.8-1.7-.8-3.1-2.1-4.1-3.6s-1.7-3.3-1.8-5.2c-.1-2.5.6-6,5.2-8.8c3.5-2.2,7.3-2.1,11.1.2.5.3.9.6,1.3.9-1.8-.5-3.8-.6-5.6-.1-1,.3-1.9.8-2.7,1.5s-1.4,1.5-1.9,2.5c-3.5,6.9,2.1,10.1,4.8,11.7.3.2.7.4,1,.6Zm.5-.3l.6.8-.6-.8v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M481.9,350.1c-1.6,2.5-3.8-10.4-12.7-5.2-7.2,4.3-.5,13.8-.5,13.8s-11.3-7.2-6-17c7.5-13.8,25.6-1.8,19.2,8.4Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M472.5,362.4l-3-4.2c0,0-3.4-4.9-2.5-8.8.2-.8.5-1.5,1-2.1s1.1-1.1,1.7-1.5c4.6-2.7,7,.1,9.2,3.4.9,1.4,1.5,2.2,2.5,2.2.3,0,.5-.1.8-.2.2-.1.4-.3.5-.6.9-1.5,1.5-3.1,1.5-4.9c0-1.7-.4-3.4-1.3-4.9-1.1-1.9-2.7-3.5-4.6-4.6s-4-1.7-6.2-1.8h-.1c-4.2,0-7.9,2.5-10.2,6.8-5.6,10.5,6.2,18.3,6.3,18.3l4.4,2.9Zm.7-19.7c-1.6,0-3.1.5-4.5,1.4-.9.5-1.7,1.2-2.4,2-.6.8-1.1,1.8-1.3,2.8-.3,1.9-.2,3.8.5,5.6-.3-.4-.7-.8-1-1.2-2.6-3.7-2.9-7.4-.9-11.1c2.5-4.7,6-5.7,8.4-5.7h.1c1.8,0,3.6.6,5.2,1.5s2.9,2.3,3.8,3.8c.6,1.1,1,2.3,1,3.6s-.3,2.5-.9,3.6c-.2-.3-.5-.7-.7-1-1.1-1.9-3.5-5.3-7.3-5.3v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M461.5,327.6c1.6-1.2-7.1-2.1-4-8.2c2.5-5,9.2-.9,9.2-.9s-5.3-7.2-11.6-3.3c-8.9,5.6-.1,17.1,6.4,12.4Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M458.2,329.7c1.4,0,2.8-.5,3.9-1.3.2-.1.4-.3.5-.5s.2-.4.1-.7c0-.8-.8-1.3-1.8-1.8-2.3-1.4-4-2.6-2.5-5.6.2-.4.5-.8.8-1.1.4-.3.8-.5,1.2-.7c2.4-.7,5.7,1.3,5.7,1.3l4.4,2.7-3-4.1c-2-2.8-7.3-7-12.9-3.5-2.9,1.8-4.4,4.5-4.2,7.4.1,1.5.6,3,1.5,4.3s2,2.3,3.4,3c.9.4,1.9.6,2.9.6Zm1.9-2.5c-.6.3-1.3.4-2,.4s-1.4-.2-2-.5c-1.1-.5-2-1.3-2.6-2.3-.7-1-1.1-2.1-1.2-3.3-.1-1.5.4-3.8,3.3-5.6c1-.6,2.1-1,3.3-1s2.3.3,3.3.9c-.8-.1-1.6,0-2.3.2s-1.4.6-2,1.1-1,1.1-1.3,1.8c-2.5,4.9,1.5,7.2,3.3,8.2l.2.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M457.9,321.1c-.3,2,6.4-3.7,8.7,2.8c1.9,5.3-5.7,7.3-5.7,7.3s8.8,1.1,10.4-6.1c2.1-10.4-12.3-12-13.4-4Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M462.3,332.2c3.6,0,8.8-1.4,9.9-7c.7-3.4-.2-6.3-2.4-8.2-1.2-1-2.6-1.6-4.1-1.9s-3.1-.1-4.5.4c-1.1.4-2.2,1.1-2.9,2.1s-1.2,2.1-1.4,3.3c0,.2,0,.5,0,.7.1.2.2.4.4.6.7.6,1.5.3,2.5,0c2.6-.8,4.7-1.1,5.8,2c.2.4.3.9.2,1.4c0,.5-.1.9-.4,1.3-1.2,2.2-4.8,3.2-4.9,3.3l-5,1.4l5.1.7c.7,0,1.2,0,1.7-.1Zm1.9-15.2c1.6,0,3.1.5,4.3,1.5s2.4,2.9,1.8,6.2c-.2,1.1-.7,2.2-1.5,3.1s-1.8,1.5-2.9,1.8c.6-.5,1.1-1.1,1.4-1.8s.5-1.4.6-2.1c0-.7-.1-1.5-.4-2.2-1.8-5.2-6.2-3.9-8.3-3.3l-.2.1c.2-.7.6-1.3,1.1-1.8s1.1-.9,1.7-1.1c.9-.2,1.6-.4,2.4-.4Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M416.6,308.5c.3-2-6.5,3.6-8.7-2.9-1.8-5.3,5.8-7.2,5.8-7.2s-8.8-1.3-10.4,6c-2.3,10.2,12.1,12,13.3,4.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M410.3,314.5c1,0,2-.2,2.9-.5c1.1-.4,2.2-1.1,2.9-2c.8-.9,1.3-2.1,1.4-3.3c0-.2,0-.5,0-.7-.1-.2-.2-.4-.4-.6-.6-.6-1.5-.3-2.5,0-2.6.7-4.7,1-5.7-2.1-.2-.4-.3-.9-.2-1.4c0-.5.2-.9.4-1.3c1.2-2.2,4.9-3.2,4.9-3.2l5-1.3-5.1-.7c-3.4-.5-10.1.3-11.6,6.8-.7,3.4.1,6.3,2.3,8.2c1.6,1.3,3.6,2.1,5.7,2.1Zm5-5.3c-.2.7-.6,1.3-1.1,1.7-.5.5-1.1.9-1.8,1.1-1.1.4-2.3.5-3.5.3s-2.2-.7-3.1-1.5c-1.2-1-2.4-2.9-1.7-6.3.2-1.1.8-2.2,1.5-3c.8-.8,1.8-1.4,2.9-1.7-.6.5-1.1,1.1-1.5,1.8s-.6,1.4-.6,2.1.1,1.5.3,2.2c1.8,5.2,6.1,4,8.2,3.4l.4-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M436.6,323.5c-3.7,1.4,9.6,6.4-.9,15.5-8.6,7.5-15.7-2.1-15.7-2.1s2.1,14.3,15.8,10.2c19.6-6,15.7-29,.8-23.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M431.2,348.8c1.7,0,3.4-.3,5-.8c7.2-2.2,12.4-7.2,13.7-13.3.9-4.3-.2-8.5-3-10.9-2.7-2.3-6.4-2.8-10.5-1.3-.3.1-.6.2-.8.5-.2.2-.4.5-.5.8-.3,1,.4,1.9,1.4,3c2,2.6,4.9,6.1-1.3,11.5-2.2,2-4.6,2.8-6.9,2.6-4.3-.5-7.3-4.5-7.3-4.5l-2.4-3.2.6,4c0,.3,1.1,6.9,6.1,10.1c1.6,1,3.7,1.6,5.9,1.5Zm9.7-25.1c1.7,0,3.3.5,4.6,1.6c2.2,1.9,3.1,5.4,2.4,9-1.1,5.4-5.7,9.8-12.3,11.8-3.7,1.1-6.9.9-9.3-.6-1.8-1.3-3.2-3-4.1-5.1c1.7,1.3,3.7,2.2,5.8,2.4c2.9.3,5.8-.7,8.4-3.1c7.6-6.7,3.5-11.8,1.5-14.3-.3-.4-.6-.7-.8-1.1c1.2-.4,2.5-.6,3.8-.6Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M409.4,306.5c1.2,1.6,1.9-7.1,8.1-4.3c5.1,2.4,1.2,9.2,1.2,9.2s7-5.5,2.9-11.7c-5.9-8.8-17.1.4-12.2,6.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M415.2,315.3l4.1-3.2c2.7-2.1,6.8-7.6,3.1-13.1-1.9-2.9-4.6-4.3-7.5-4-1.5.2-3,.7-4.2,1.6s-2.2,2.1-2.9,3.5c-.5,1.1-.7,2.3-.6,3.5s.6,2.4,1.4,3.3c.1.2.3.3.5.5s.4.1.7.1c.8-.1,1.3-.8,1.8-1.8c1.3-2.4,2.5-4.1,5.5-2.7.4.2.8.4,1.1.8.3.3.6.7.7,1.2.8,2.4-1.1,5.7-1.1,5.8l-2.6,4.5Zm.3-18.3c1.5,0,3.5.6,5.2,3.1.7.9,1.1,2.1,1.1,3.2.1,1.2-.2,2.3-.7,3.3c0-.8,0-1.5-.3-2.3-.2-.7-.6-1.4-1.1-1.9-.5-.6-1.1-1-1.8-1.3-5-2.3-7.1,1.7-8.1,3.6c0,.1-.1.1-.1.2-.3-.6-.5-1.3-.5-2s.1-1.4.4-2c.5-1.1,1.2-2,2.2-2.7s2.1-1.1,3.2-1.3c.2.2.4.2.5.1v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M415.3,296.6c1.2,1.6,1.9-7.1,8.1-4.3c5.1,2.4,1.2,9.2,1.2,9.2s7-5.5,2.9-11.7c-5.9-8.8-17.1.4-12.2,6.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M421.2,305.5l4.1-3.2c2.7-2.1,6.8-7.6,3.1-13.1-1.9-2.9-4.6-4.3-7.5-4-1.5.2-3,.7-4.2,1.6s-2.2,2.1-2.9,3.5c-.5,1.1-.7,2.3-.6,3.5s.6,2.4,1.4,3.3c.1.2.3.3.5.5.2.1.4.1.7.1.8-.1,1.3-.8,1.8-1.8c1.3-2.4,2.5-4.1,5.5-2.7.4.2.8.4,1.1.8.3.3.6.7.7,1.2.8,2.4-1.1,5.7-1.1,5.8l-2.6,4.5Zm.3-18.3c1.5,0,3.5.6,5.2,3.1.7.9,1.1,2.1,1.1,3.2.1,1.2-.2,2.3-.8,3.3c0-.8,0-1.5-.3-2.3-.2-.7-.6-1.4-1.1-1.9-.5-.6-1.1-1-1.8-1.3-5-2.3-7.1,1.7-8.1,3.6l-.1.2c-.3-.6-.5-1.3-.5-2s.1-1.4.4-2c.5-1.1,1.2-2,2.2-2.7.9-.7,2.1-1.1,3.2-1.3.3.1.4.1.6.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M469.8,366.1c.3-2-6.5,3.6-8.7-2.9-1.8-5.3,5.8-7.2,5.8-7.2s-8.8-1.3-10.4,6c-2.3,10.3,12.1,12.1,13.3,4.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M463.6,372.1c1,0,2-.2,2.9-.5c1.1-.4,2.2-1.1,2.9-2c.8-.9,1.3-2.1,1.4-3.3c0-.2,0-.5,0-.7-.1-.2-.2-.4-.4-.6-.6-.6-1.5-.3-2.5,0-2.6.7-4.7,1-5.7-2.1-.2-.4-.3-.9-.2-1.4c0-.5.2-.9.4-1.3c1.2-2.2,4.9-3.2,4.9-3.2l5-1.3-5.1-.7c-3.4-.5-10.1.3-11.6,6.8-.7,3.4.1,6.3,2.3,8.2c1.5,1.4,3.6,2.1,5.7,2.1Zm5-5.2c-.2.6-.6,1.2-1.1,1.7s-1.1.8-1.7,1.1c-1.1.4-2.3.5-3.5.3s-2.2-.7-3.1-1.5c-1.2-1-2.4-2.9-1.7-6.3.2-1.1.8-2.2,1.5-3c.8-.8,1.8-1.4,2.9-1.7-.6.5-1.1,1.1-1.5,1.8s-.6,1.4-.6,2.1.1,1.5.3,2.2c1.8,5.2,6.1,4,8.2,3.4l.3-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M447,355.5c-.2-2-1.8-14.9,12.1-10c.6.2,1.2.6,1.7,1c1.5,1.6,4.2-.3,3.2-2.3-1-1.9-3.4-3.5-8.4-4.3-19.8-3.1-8.1,21.4-8.6,15.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M446.9,357.4c.2,0,.3,0,.5-.1.9-.4.8-1.5.7-1.9v-.2c-.4-4.4.3-7.3,2.1-8.7s4.7-1.4,8.7,0c.5.2.9.4,1.3.8.5.5,1.2.8,1.9.9s1.4-.1,2-.6c.6-.4,1-1,1.2-1.7s.1-1.4-.3-2.1c-1.3-2.4-4.3-4-9.1-4.8-7.3-1.2-10.4,1.4-11.7,3.8-2.6,5,.2,12.6,1.6,14.1.1.2.3.3.5.4s.3.1.6.1Zm6.7-14c-1.7-.1-3.4.4-4.7,1.5-1.9,1.5-2.9,4.1-3,7.7-.8-2.5-1.4-6-.1-8.6c1.4-2.8,4.6-3.8,9.7-3c4.1.7,6.7,1.9,7.6,3.7.1.2.1.4.1.7s-.2.4-.4.5-.4.2-.6.2-.4-.1-.6-.3c-.6-.6-1.3-1-2.1-1.3-1.9-.6-3.9-1-5.9-1.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M450.4,345.3c-.2-2-2.1-28,12.1-10c1.8,2.3-8.3,3.2-8.3,3.2s21.6-.3,12.5-10.4c-8.3-9.2-15.8,23-16.3,17.2Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M450.7,347c1,0,1.5-1.2,3.2-5.1.3-.7.6-1.5,1-2.3c2.9-.1,12.8-.8,14.8-5.3.9-2,.1-4.3-2.1-6.8-1.4-1.6-3-2.2-4.6-2-1.8.3-3.4,1.8-4.9,3.8-1.9-1.3-3.5-1.6-4.9-1.1-1.2.5-5,3.1-3.8,17.1c0,.4.1,1.5,1.1,1.6l.2.1Zm6.1-16c-1.9,3.2-3.5,6.6-4.9,10.1-.3.6-.5,1.2-.8,1.8-.4-8.4,1.3-12.3,2.7-12.8.6-.2,1.5-.2,3,.9Zm4.8,4.9c-.7.5-3,1.1-5.8,1.5.8-1.7,1.6-3.4,2.6-5c1.2,1,2.2,2.2,3.2,3.5v0Zm2-8.4c.5,0,.9.2,1.3.4s.8.5,1,.9c1.7,1.9,2.3,3.5,1.8,4.6-.5,1.2-2.1,2-4.1,2.7c0-.3,0-.5,0-.8-.1-.3-.2-.5-.4-.7-1.1-1.5-2.4-2.8-3.7-4.1c1.2-1.7,2.4-2.9,3.7-3.1.1.1.3.1.4.1v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M455.3,355.9c-1.9-.4,3.1,6.7-3.5,8.5-5.4,1.5-6.8-6.3-6.8-6.3s-1.9,8.7,5.3,10.8c10.1,3,12.8-11.2,5-13Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M452.9,370.4c1,0,2-.2,2.9-.6s1.8-.9,2.5-1.6c1.1-1.1,1.8-2.5,2.2-4s.4-3.1-.1-4.5c-.3-1.2-1-2.2-1.8-3.1-.9-.8-2-1.4-3.2-1.7-.2-.1-.5-.1-.7,0s-.4.2-.6.3c-.6.6-.4,1.5-.2,2.5.5,2.7.7,4.7-2.5,5.6-.4.1-.9.2-1.4.1s-.9-.2-1.3-.5c-1.8-1.1-2.7-4.1-2.9-5.1l-.9-5.1-1.1,5.1c-.7,3.3-.4,10.2,6,12c1.1.4,2.1.6,3.1.6Zm3-13.2c.6.3,1.2.7,1.7,1.2s.8,1.1,1,1.8c.3,1.1.3,2.3,0,3.5-.3,1.1-.9,2.2-1.7,3-1.1,1.1-3.1,2.2-6.4,1.2-1.1-.3-2.1-.9-2.9-1.7s-1.3-1.9-1.6-3c.5.6,1,1.2,1.7,1.6.6.4,1.3.7,2.1.8.7.1,1.5,0,2.2-.2c5.3-1.4,4.4-5.9,3.9-8v-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M452.8,363c1.6-1.1-7-2.3-3.7-8.4c2.7-4.9,9.2-.6,9.2-.6s-5-7.4-11.5-3.7c-9.1,5.4-.7,17.2,6,12.7Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M449.6,365c1.3,0,2.6-.4,3.7-1.2.2-.1.4-.3.5-.5s.2-.4.2-.7c0-.8-.8-1.3-1.7-1.9-2.3-1.4-3.9-2.8-2.4-5.6.2-.4.5-.8.9-1.1s.8-.5,1.2-.6c2.5-.6,5.6,1.4,5.7,1.5l4.3,2.8-2.9-4.2c-1.9-2.8-7.1-7.3-12.8-4-3,1.7-4.6,4.3-4.5,7.3.1,1.5.5,3,1.3,4.3s2,2.4,3.3,3.1c1,.5,2.1.8,3.2.8Zm1.8-2.4c-.6.3-1.3.4-2,.4s-1.4-.2-2-.6c-1-.6-1.9-1.4-2.5-2.4s-1-2.1-1-3.3c0-1.6.5-3.8,3.5-5.5c1-.6,2.1-.9,3.3-.9s2.3.3,3.3,1c-.8-.1-1.6-.1-2.3.1s-1.4.5-2,1-1.1,1.1-1.4,1.7c-2.6,4.8,1.2,7.2,3.1,8.3v.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M442.5,317c-1.4,1.4-9.9,11.3-15.5-2.4-.3-.6-.4-1.3-.3-1.9.2-2.2-3.1-2.9-3.8-.8-.7,2-.3,4.9,2.4,9.1c10.7,16.8,21.2-8.2,17.2-4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M433.3,327.9c.6,0,1.1-.1,1.7-.2c5.5-1.3,9.2-8.5,9.4-10.6.1-.3,0-.6-.1-.8-.1-.3-.3-.5-.6-.6-.9-.4-1.6.4-1.9.7l-.2.2c-3,3.2-5.6,4.6-7.9,4.2s-4.2-2.6-5.7-6.5c-.2-.5-.3-1-.3-1.5.1-.7-.1-1.4-.6-2-.4-.6-1.1-1-1.8-1.1-.7-.2-1.5-.1-2.1.2s-1.1.9-1.4,1.6c-1,2.6-.1,5.9,2.5,10c3.3,5,6.5,6.4,9,6.4Zm7.8-8c-1.3,2.2-3.6,5-6.3,5.8-3,.9-5.9-.9-8.7-5.3-2.2-3.5-3-6.3-2.3-8.2.1-.2.2-.4.5-.5.2-.1.4-.1.7-.1.2,0,.4.1.6.3.1.2.2.4.2.6-.1.8.1,1.7.4,2.4c1.9,4.6,4.3,7.2,7.3,7.7c2.2.6,4.8-.4,7.6-2.7Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M475.9,352.5c-1.4,1.4-19.4,20.2-15.5-2.4.5-2.9,7.9,4.1,7.9,4.1s-14.6-16-16.1-2.5c-1.4,12.4,27.7-3.4,23.7.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M463,362.2c2.2,0,6.5-1.4,13.6-8.9.2-.3,1-1.1.5-1.9-.6-1-1.8-.6-6.1.9-.7.2-1.5.5-2.4.8-2-2.1-9.1-9.1-13.8-7.5-2,.7-3.3,2.7-3.6,6.1-.2,2.1.3,3.7,1.6,4.7c1.4,1.1,3.6,1.4,6.1,1.1.3,2.2,1.1,3.7,2.4,4.4.5.2,1.1.3,1.7.3Zm10.5-8.7c-6,5.9-10,7.2-11.3,6.5-.5-.2-1.1-.9-1.4-2.8c3.7-.8,7.3-1.8,10.8-3.1.7-.2,1.3-.4,1.9-.6Zm-14.7,1.9c-2,.2-3.8.1-4.7-.7-.7-.6-1-1.5-.8-2.9.3-2.5,1-4,2.3-4.4c1.2-.4,2.9.2,4.7,1.3-.2.1-.4.3-.5.5s-.2.5-.3.7c-.4,1.8-.6,3.7-.7,5.5v0Zm7.5-1.7c-1.8.6-3.6,1-5.5,1.4.1-1.6.2-3.2.5-4.7.9.2,2.9,1.5,5,3.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M422.6,322.5c-3.4,2.8,16.1,3.7,10.1,17.8-4.9,11.6-20.4,3.4-20.4,3.4s12.8,15.3,26.3,5.6c19.2-13.9-2.1-38.3-16-26.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M429.4,353.4c3.6,0,7.1-1.2,10-3.4c5.8-4.2,8.6-9.9,7.9-16-.5-3.2-1.7-6.2-3.7-8.8-2-2.5-4.6-4.5-7.6-5.8-2.3-1-4.8-1.3-7.3-.9s-4.8,1.5-6.7,3.1c-.3.2-.5.4-.6.7s-.2.6-.2.9c.1,1.2,1.5,1.9,3.5,2.9c5.6,2.8,10.4,5.9,7.1,13.7-.4,1.1-1.1,2.2-2,3-.9.9-1.9,1.5-3,2-5.9,2.1-13.9-2-14-2.1l-4.6-2.4l3.3,3.9c.3.2,8,9.2,17.9,9.2Zm1.2-32.9c1.6,0,3.1.3,4.6.9c2.6,1.1,4.9,2.9,6.7,5.1c1.8,2.3,2.9,4.9,3.3,7.7.7,5.3-1.8,10.4-7,14.1-5.2,3.8-11,4.1-17.1.8-1.5-.8-2.8-1.7-4.1-2.7c3.1.9,7.1,1.5,10.6.2c1.4-.5,2.7-1.3,3.8-2.4s1.9-2.3,2.4-3.7c4.3-10.2-3.8-14.1-8.1-16.3-.8-.4-1.5-.8-2.2-1.2c1.9-1.6,4.5-2.5,7.1-2.5v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M397.1,343.7c3.4,2.9.8-16.5,15.7-13.1c12.2,2.8,6.8,19.5,6.8,19.5s12.8-15.3,1-26.9c-16.8-16.5-37.2,8.8-23.5,20.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M417.1,354.8l3.3-4c.1-.2,13.3-16.2.9-28.3-5.2-5-11.2-6.8-17.1-5-3.1,1-5.8,2.8-8,5.2s-3.7,5.3-4.4,8.4c-.6,2.4-.5,5,.3,7.4s2.2,4.5,4.2,6.1c.2.2.5.4.8.5s.6.1.9,0c1.2-.3,1.6-1.8,2.2-4c1.8-6,4-11.2,12.2-9.3c1.2.2,2.3.7,3.3,1.4s1.8,1.6,2.4,2.7c3.1,5.4.4,14,.3,14.1l-1.3,4.8Zm-8.4-36c3.9,0,7.8,1.8,11.2,5.1c4.6,4.5,5.9,10.2,3.7,16.7-.5,1.6-1.2,3.1-2,4.5.4-3.2.3-7.2-1.6-10.5-.7-1.3-1.8-2.4-3-3.3s-2.6-1.5-4.1-1.8c-10.8-2.5-13.3,6.1-14.6,10.7-.2.8-.5,1.6-.8,2.4-1.6-1.4-2.8-3.1-3.5-5.1s-.7-4.1-.3-6.2c.6-2.8,2-5.4,3.9-7.5s4.3-3.7,7.1-4.6c1.4-.2,2.7-.4,4-.4v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M397.6,329.6c1,.9.3-4.9,4.7-3.9c3.6.8,2,5.8,2,5.8s3.8-4.5.3-8c-5-4.8-11.1,2.7-7,6.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M401.8,336.2l3.3-4c1.5-1.8,3.5-6.1.2-9.4-1.7-1.7-3.8-2.3-5.8-1.7-1,.3-2,.9-2.7,1.7s-1.2,1.8-1.5,2.8c-.2.8-.1,1.7.1,2.6.3.8.8,1.6,1.5,2.1.2.1.3.2.6.3.2,0,.4,0,.6,0c.7-.2.9-.9,1.1-1.7.6-1.9,1.1-2.7,2.8-2.3.3,0,.5.1.7.3s.4.3.5.6c.6,1.1.2,3,0,3.6l-1.4,5.1Zm-.5-11.6c-2.4,0-3.3,1.9-3.8,3.2-.2-.5-.3-1.1-.1-1.7.2-.7.5-1.4,1-1.9s1.1-.9,1.8-1.2c1.3-.4,2.6,0,3.8,1.2.7.6,1.1,1.4,1.2,2.3-.3-.5-.6-.9-1.1-1.2-.4-.3-.9-.5-1.5-.6-.5,0-.9-.1-1.3-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M399.6,380c-.3-2.5,8.1,5,11.3-3.2c2.6-6.7-7-9.6-7-9.6s11.4-1.1,13.1,8.3c2.2,13.3-16.3,14.8-17.4,4.5Z"
                fill="#e3e2fb"
              ></path>
              <path
                d="M407.8,387.7c2.4,0,4.8-.8,6.8-2.3c2.9-2.3,4.1-5.9,3.3-10.2-1.4-8.2-9.9-9.5-14.2-9.1l-5.1.5l4.9,1.5c0,0,4.8,1.5,6.3,4.5.3.6.4,1.2.4,1.8s-.1,1.3-.4,1.9c-1.6,4.2-4.5,3.6-7.9,2.5-1.3-.4-2.3-.7-3-.2-.2.2-.3.4-.4.6s-.1.5-.1.7c.1,1.5.7,2.9,1.6,4.2.9,1.2,2.1,2.1,3.5,2.7c1.5.7,2.9,1,4.3.9Zm1.1-19.1c2.9.7,6.3,2.5,7,7c.8,4.5-1,6.9-2.6,8.2-1.2.9-2.7,1.6-4.3,1.8-1.5.2-3.1,0-4.6-.5-1-.4-1.8-1-2.5-1.8s-1.1-1.7-1.3-2.7c.2.1.5.2.7.2c2.6.8,7.9,2.6,10.4-3.7.4-.8.5-1.8.5-2.7s-.2-1.8-.6-2.7c-.6-1.2-1.6-2.3-2.7-3.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M402.8,348.1c2.3,1.9.6-11,10.5-8.7c8.2,1.9,4.6,13.1,4.6,13.1s8.6-10.2.7-18c-11.3-11.1-25,5.8-15.8,13.6Z"
                fill="#e3e2fb"
              ></path>
              <path
                d="M415.3,357.1l3.3-4c.1-.1,9.1-11.1.6-19.4-3.5-3.5-7.7-4.7-11.8-3.5-2.1.7-4,1.9-5.5,3.6-1.5,1.6-2.5,3.6-3,5.8-.4,1.7-.3,3.4.3,5.1.6,1.6,1.6,3.1,2.9,4.2.2.2.4.3.7.4s.5.1.8,0c.9-.3,1.2-1.4,1.7-2.9c1.1-3.9,2.6-7.2,7.8-6c.8.1,1.5.4,2.1.9.6.4,1.2,1,1.5,1.7c2,3.5.2,9.1.2,9.2l-1.6,4.9Zm-4.7-25.3c2.1,0,4.6.8,7.2,3.3c3,2.9,3.8,6.6,2.4,10.8-.2.5-.3,1-.6,1.5.1-1.9-.3-3.8-1.2-5.5-.5-.9-1.2-1.7-2.1-2.3s-1.8-1-2.9-1.2c-7.5-1.7-9.3,4.4-10.2,7.4-.1.4-.2.8-.3,1.1-.9-.9-1.6-2-1.9-3.2-.4-1.2-.4-2.5-.1-3.7.4-1.8,1.3-3.5,2.5-4.8c1.2-1.4,2.8-2.4,4.6-3c.9-.2,1.7-.4,2.6-.4Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M395.9,390.6c-2.8-.9,9.2-6.2,2-13.5-5.8-6-13.5,2.8-13.5,2.8s4.3-12.7,15.1-9.8c15.2,3.9,7.8,24.3-3.6,20.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M398.7,392.1c2.4,0,4.7-1,6.5-2.6c1.6-1.5,2.8-3.4,3.4-5.6.6-2.1.7-4.4.2-6.5-1-4.1-4.2-7.1-9-8.3-11.5-3-16.2,10.4-16.3,10.5l-1.7,4.9l3.4-3.9c0,0,3.9-4.5,8-4.6.8,0,1.5.1,2.2.4s1.3.8,1.8,1.4c3.7,3.9,1.6,6.8-1.1,9.8-1.1,1.2-1.8,2-1.6,3c.1.3.2.5.4.7s.4.3.7.4c.9.2,2,.4,3.1.4Zm-2.2-21.5c.9,0,1.9.1,2.8.4c5.2,1.4,7,4.5,7.6,6.9.4,1.8.3,3.7-.2,5.5s-1.5,3.4-2.8,4.7c-.9.9-2,1.5-3.2,1.8s-2.5.3-3.7,0c.2-.3.5-.6.8-.9c2.1-2.3,6.5-7,1.1-12.6-.7-.8-1.6-1.4-2.5-1.8-1-.4-2-.6-3.1-.6-1.9.1-3.7.7-5.3,1.8.3-.4.6-.8,1-1.2c2-2.7,4.6-4,7.5-4Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M412.9,365.5c1.5,1.3.4-7.4,7-5.8c5.5,1.3,3.1,8.7,3.1,8.7s5.7-6.8.4-12c-7.5-7.4-16.6,3.9-10.5,9.1Z"
                fill="#e3e2fb"
              ></path>
              <path
                d="M420.5,373l3.3-4c2.2-2.6,5.1-8.8.4-13.4-2.5-2.4-5.4-3.2-8.2-2.4-1.5.5-2.8,1.3-3.8,2.5-1,1.1-1.8,2.5-2.1,4-.3,1.2-.2,2.4.2,3.6s1.1,2.2,2,3c.2.2.4.3.6.3.2.1.5.1.7,0c.8-.2,1.1-1.1,1.4-2.2.8-2.6,1.6-4.5,4.8-3.8.5.1.9.3,1.3.5.4.3.7.6.9,1c1,1.8.4,4.9.1,5.9l-1.6,5Zm-2-14.5c-3.9,0-4.9,3.6-5.4,5.4c0,.1,0,.1-.1.2-.4-.5-.7-1.2-.9-1.9s-.2-1.4,0-2.1c.3-1.1.8-2.2,1.6-3.1s1.8-1.5,2.9-1.9c1.5-.5,3.8-.5,6.2,1.9.8.8,1.5,1.8,1.8,2.9s.3,2.3,0,3.4c-.1-.8-.4-1.5-.7-2.2-.4-.7-.9-1.2-1.5-1.7s-1.3-.7-2-.9c-.7,0-1.4,0-1.9,0v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M418.4,360.5c-2,.2,5.1,5.4-.6,9.1-4.7,3.1-8.4-3.8-8.4-3.8s1,8.9,8.4,8.6c10.5-.4,8.6-14.8.6-13.9Z"
                fill="#e3e2fb"
              ></path>
              <path
                d="M417.4,375.4h.3c3.4-.1,6.1-1.6,7.4-4.3.7-1.4,1-2.9.9-4.5-.1-1.5-.6-3-1.5-4.3-.7-1-1.6-1.8-2.7-2.3s-2.3-.7-3.5-.6c-.2,0-.5.1-.7.2s-.4.3-.5.5c-.4.8.1,1.5.6,2.5c1.4,2.3,2.2,4.3-.6,6.1-.4.3-.8.5-1.3.6s-.9.1-1.4,0c-2-.5-3.8-3-4.3-4l-2.5-4.6.6,5.2c.5,3.3,2.9,9.5,9.2,9.5Zm2.1-14c.7.1,1.4.3,2,.6.6.4,1.1.8,1.5,1.4.6,1,1,2.1,1.1,3.3s-.1,2.3-.6,3.4c-.7,1.4-2.2,3.1-5.6,3.2-1.2.1-2.3-.2-3.3-.7-1-.6-1.8-1.4-2.4-2.4.6.4,1.3.8,2.1,1c.7.2,1.5.2,2.2.1s1.4-.4,2-.9c4.6-3,2.3-6.9,1.2-8.8l-.2-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M420.9,317.2c2-.2-5-5.4.8-9.1c4.7-3,8.4,3.9,8.4,3.9s-.8-8.9-8.3-8.8c-10.5.3-8.9,14.7-.9,14Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M420.4,318.3c.2,0,.4,0,.6,0s.5-.1.7-.2.4-.3.5-.5c.4-.7,0-1.5-.6-2.5-1.4-2.4-2.1-4.3.7-6.1.4-.3.8-.5,1.3-.5.5-.1.9-.1,1.4,0c2.5.7,4.2,4,4.3,4.1l2.4,4.6-.5-5.1c-.3-3.4-2.7-9.8-9.3-9.7-3.4.1-6.1,1.6-7.4,4.2-.7,1.4-1,2.9-.9,4.5.1,1.5.6,3,1.4,4.3.6.9,1.4,1.7,2.4,2.2.8.4,1.9.7,3,.7Zm3.7-11.9c-1.1,0-2.1.3-2.9.9-4.6,2.9-2.4,6.9-1.4,8.8l.1.2c-.7-.1-1.3-.3-1.9-.7s-1.1-.9-1.4-1.4c-.6-1-1-2.1-1.1-3.3s.2-2.3.7-3.4c.7-1.4,2.3-3,5.7-3.1c1.2,0,2.3.2,3.3.8s1.8,1.4,2.4,2.4c-.6-.5-1.3-.8-2.1-1-.5-.2-.9-.2-1.4-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M411.1,340.3c-2.2-3.2-3.9,10.8-15.3,2.8-9.3-6.6-1.7-15.8-1.7-15.8s-13.4,5.4-6.1,17.8c10.4,17.6,31.8,8.3,23.1-4.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M402.9,355.8h.4c4.4-.1,8.2-2.2,9.9-5.5c1.6-3.1,1.2-6.9-1.3-10.5-.1-.3-.4-.5-.6-.7-.3-.2-.6-.3-.9-.3-1,0-1.7.9-2.6,2-2,2.6-4.7,6.2-11.4,1.4-2.4-1.7-3.8-3.8-4.1-6.1-.6-4.3,2.6-8.2,2.6-8.2l2.6-3.1-3.7,1.5c-.3.1-6.4,2.7-8.4,8.3-1.1,3.3-.5,7,1.8,10.9c3.7,6.6,9.6,10.3,15.7,10.3Zm7.4-14.8c1.9,3,2.3,5.9,1.1,8.4-1.4,2.6-4.5,4.3-8.2,4.4-5.5.1-10.9-3.3-14.3-9.2-2-3.4-2.5-6.5-1.6-9.2.8-2.1,2.1-3.9,3.9-5.2-.9,2-1.2,4.1-1,6.2.4,2.9,2,5.4,5,7.5c8.3,5.9,12.3.6,14.2-1.9.3-.3.7-.8.9-1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M421.2,309.8c-1.2,1.5,7.4.1,6.1,6.9-1.1,5.5-8.6,3.3-8.6,3.3s7,5.5,12,.1c7.1-7.8-4.5-16.6-9.5-10.3Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M425.3,323.4c1.1,0,2.3-.2,3.3-.7s2-1.2,2.7-2c2.3-2.5,3.1-5.5,2.1-8.3-.5-1.5-1.4-2.7-2.6-3.7s-2.6-1.7-4.1-2c-1.2-.2-2.4-.1-3.6.3-1.1.4-2.1,1.2-2.9,2.1-.2.2-.3.4-.3.6s0,.5,0,.7c.3.8,1.1,1,2.2,1.3c2.6.7,4.6,1.5,3.9,4.7-.1.5-.2.9-.5,1.3s-.6.7-1,1c-2.2,1.4-5.8.3-5.9.3l-4.9-1.4l4.1,3.2c2.3,1.6,4.9,2.5,7.5,2.6Zm.3-14.8c.3,0,.6,0,.9.1c1.2.2,2.2.7,3.1,1.5s1.6,1.7,2,2.8c.5,1.5.6,3.7-1.7,6.3-.8.9-1.8,1.5-2.9,1.8s-2.3.4-3.4.1c.8-.1,1.5-.4,2.2-.8.6-.4,1.2-.9,1.6-1.5s.7-1.3.8-2.1c1.1-5.4-3.3-6.5-5.4-7h-.2c.8-.8,1.9-1.2,3-1.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M377.5,382.7c2-.2-5-5.4.8-9.1c4.7-3,8.3,3.9,8.3,3.9s-.8-8.9-8.3-8.7c-10.4.1-8.8,14.6-.8,13.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M377,383.7c.2,0,.4,0,.6,0s.5-.1.7-.2.4-.3.5-.5c.4-.7,0-1.5-.6-2.5-1.4-2.4-2.1-4.3.7-6.1.4-.3.8-.5,1.3-.5.5-.1.9-.1,1.4,0c2.5.7,4.2,4,4.3,4.1l2.4,4.5-.5-5.1c-.3-3.4-2.7-9.8-9.3-9.7-3.4.1-6.1,1.6-7.4,4.2-.7,1.4-1,2.9-.9,4.5.1,1.5.6,3,1.4,4.3.6.9,1.4,1.7,2.4,2.2.8.5,1.9.8,3,.8Zm3.7-11.9c-1,0-2.1.3-2.9.9-4.6,2.9-2.4,6.9-1.4,8.8l.1.2c-.7-.1-1.3-.3-1.9-.7s-1.1-.9-1.4-1.4c-.6-1-1-2.1-1.1-3.3s.2-2.3.7-3.4c.7-1.4,2.3-3,5.7-3.1c1.2,0,2.3.2,3.3.8s1.8,1.4,2.4,2.4c-.6-.5-1.3-.8-2.1-1-.5-.2-1-.2-1.4-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M382.5,358c1.9-.6,14-5.3,12.5,9.5-.1.7-.3,1.3-.6,1.9-1.2,1.9,1.4,4.1,3,2.6s2.6-4.2,2.2-9.2c-1.6-20.1-22.7-3-17.1-4.8Z"
                fill="#e3e2fb"
              ></path>
              <path
                d="M396.1,373.3c.7,0,1.4-.3,1.9-.7c2-1.8,2.9-5.2,2.5-10-.6-7.4-3.8-9.8-6.5-10.5-5.4-1.4-12.1,3.2-13.3,4.9-.2.2-.3.5-.3.8s.1.6.2.8c.6.8,1.6.4,2,.3l.2-.1c4.1-1.4,7.1-1.4,9,0s2.4,4.2,2,8.4c0,.5-.2,1-.5,1.4-.4.6-.5,1.3-.4,2s.5,1.4,1,1.8c.8.7,1.5.9,2.2.9Zm-4.2-19.4c.5,0,.9,0,1.4.1c3,.6,4.8,3.6,5.2,8.7.3,4.2-.3,7-1.8,8.3-.2.2-.4.2-.6.2s-.5-.1-.6-.3c-.2-.1-.3-.3-.3-.6c0-.2,0-.5.1-.6.4-.7.7-1.5.8-2.3.5-4.9-.4-8.4-2.8-10.2-1.9-1.5-4.7-1.9-8.2-1.1c1.8-1.1,4.4-2.2,6.8-2.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M393.1,358.8c1.9-.6,26.6-8.6,12.5,9.5-1.8,2.3-5.1-7.3-5.1-7.3s5.4,21,13,9.8c7.1-10.4-25.9-10.1-20.4-12Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M409.1,375c1.9,0,3.7-1.3,5.4-3.8c1.2-1.8,1.5-3.4.8-4.9-.7-1.7-2.5-2.9-4.9-3.9.8-2.1.8-3.8-.1-5-.7-1-4.2-4.1-17.5.4-.3.1-1.4.5-1.3,1.5.1,1.2,1.3,1.4,5.8,2.1l2.5.4c.8,2.8,3.8,12.3,8.6,13.1.2.1.4.1.7.1Zm.4-10.7c1.9.8,3.4,1.7,3.9,2.9.4.8.2,1.8-.6,3-1.4,2.1-2.8,3.1-4.1,2.8-1.2-.2-2.5-1.5-3.6-3.3.3,0,.5-.1.7-.2s.4-.3.6-.5c1.2-1.6,2.2-3.1,3.1-4.7v0Zm-1.9-.7c-.8,1.4-1.7,2.7-2.7,3.9-.7-.5-1.8-2.7-2.8-5.3c1.9.4,3.7.8,5.5,1.4v0Zm-3-6.1c2.3,0,3.7.5,4.1,1.1.3.4.5,1.4-.1,3.1-3.6-1.1-7.2-1.8-10.9-2.4l-2-.3c2.8-.9,5.8-1.4,8.9-1.5v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M384,366.1c0-2-5.8,4.6-9-1.4-2.7-4.9,4.5-8.1,4.5-8.1s-8.9.2-9.3,7.7c-.6,10.5,13.9,9.8,13.8,1.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M377.2,373.2c1.5,0,3-.4,4.3-1.1c1.1-.6,1.9-1.5,2.5-2.5.6-1.1.9-2.3.8-3.5c0-.2,0-.5-.2-.7-.1-.2-.3-.4-.5-.5-.7-.4-1.5-.1-2.5.4-2.4,1.2-4.4,1.8-6-1.1-.2-.4-.4-.9-.5-1.3-.1-.5,0-.9.1-1.4.8-2.4,4.3-4,4.3-4l4.7-2.1-5.1.1c-3.4.1-9.9,2-10.2,8.6-.2,3.4,1.1,6.2,3.7,7.7c1.5.9,3,1.4,4.6,1.4Zm5.6-6.2c-.1.7-.4,1.3-.8,1.9s-.9,1-1.5,1.4c-1,.6-2.2.9-3.4.8-1.2,0-2.3-.3-3.3-.9-1.4-.8-2.9-2.5-2.7-5.9c0-1.2.4-2.3,1-3.3s1.5-1.7,2.6-2.2c-.5.6-.9,1.3-1.2,2-.2.7-.3,1.5-.2,2.2s.3,1.5.7,2.1c2.6,4.8,6.7,2.9,8.7,1.9h.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M376.5,365.3c1.5,1.4.6-7.4,7.2-5.6c5.4,1.5,2.8,8.8,2.8,8.8s6-6.6.9-12c-7.3-7.6-16.8,3.4-10.9,8.8Z"
                transform="translate(.000001 0)"
                fill="#9d9ae7"
              ></path>
              <path
                d="M383.8,373.1l3.4-3.8c2.3-2.5,5.4-8.6.8-13.4-2.4-2.5-5.2-3.4-8.1-2.7-1.5.4-2.8,1.2-3.9,2.3s-1.8,2.5-2.2,4c-.3,1.2-.3,2.4.1,3.6s1,2.2,1.9,3c.2.2.4.3.6.4s.5.1.7,0c.8-.2,1.1-1,1.4-2.1.9-2.6,1.8-4.5,4.9-3.6.5.1.9.3,1.3.6s.7.6.9,1c1.2,2.3-.1,5.8-.1,5.9l-1.7,4.8Zm-1.7-14.6c-3.7,0-4.8,3.4-5.4,5.2c0,.1,0,.1-.1.2-.4-.6-.7-1.2-.8-1.9s-.1-1.4.1-2.1c.3-1.1.9-2.2,1.7-3s1.8-1.5,3-1.8c1.5-.4,3.8-.4,6.1,2.1.8.8,1.4,1.9,1.6,3c.3,1.1.2,2.3-.2,3.4-.1-.8-.3-1.5-.7-2.2-.3-.7-.8-1.2-1.4-1.7s-1.3-.8-2-1c-.6-.1-1.3-.2-1.9-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M418.7,344.5c-1.7-1-13.3-7-1.3-15.6.5-.4,1.2-.7,1.8-.8c2.2-.4,2.1-3.7-.1-3.9-2.1-.2-4.8.8-8.3,4.5-13.8,14.4,13,18.7,7.9,15.8Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M418.6,346.3c.2,0,.3,0,.5,0c.3,0,.6-.1.8-.3s.4-.4.4-.7c.2-.9-.8-1.5-1.1-1.7l-.2-.1c-3.8-2.1-5.8-4.4-5.9-6.7s1.5-4.6,5-7.1c.4-.3.9-.5,1.4-.6.7-.1,1.4-.5,1.8-1c.5-.6.7-1.3.7-2s-.3-1.4-.7-2c-.5-.5-1.1-.9-1.9-1-2.7-.3-5.8,1.3-9.1,4.8-5.2,5.4-4.7,9.4-3.5,11.8c2.2,4.6,9.3,6.6,11.8,6.6Zm-.1-21.2c.2,0,.3,0,.5,0s.4.1.6.3.2.4.2.6s0,.5-.2.6c-.1.2-.3.3-.6.3-.8.1-1.6.5-2.3,1-4,2.9-6,5.9-5.8,8.9.1,2.5,1.7,4.7,4.6,6.9-2.5-.8-5.7-2.3-7.1-4.8-1.6-2.7-.5-6,3-9.7c2.9-2.7,5.2-4.1,7.1-4.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M392.1,385.3c-1.7-1-24.2-14.2-1.3-15.6c2.9-.2-2.1,8.7-2.1,8.7s12.1-18-1.4-16.2c-12.3,1.5,9.9,26,4.8,23.1Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M392.8,386.6c.2,0,.3,0,.5-.1.1-.1.3-.2.4-.3.8-.9.2-1.9-2.3-5.7-.4-.6-.9-1.4-1.4-2.1c1.6-2.4,6.7-11,4.1-15.2-1.1-1.8-3.4-2.5-6.8-2.1-2.1.3-3.5,1.2-4.2,2.7-.8,1.6-.5,3.8.3,6.2-2.1.8-3.3,1.9-3.7,3.4-.3,1.2-.3,5.9,11.9,12.9.4.2.8.3,1.2.3Zm-8.7-14.7c1.6,3.4,3.4,6.6,5.5,9.8.4.6.7,1.1,1.1,1.7-7.1-4.4-9.3-8-9-9.5.1-.6.6-1.4,2.4-2v0Zm6.7-1.2c.1.8-.8,3.1-2,5.6-1-1.6-1.9-3.3-2.7-5c1.5-.3,3.1-.5,4.7-.6v0Zm.2-2h-.2c-1.8.1-3.7.3-5.5.7-.7-1.9-1-3.7-.4-4.8.4-.8,1.3-1.3,2.6-1.5c2.5-.3,4.2.1,4.8,1.2.7,1.1.5,2.9-.1,4.9-.2-.2-.4-.3-.6-.4s-.4-.1-.6-.1v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M465.6,387.7c-1-4.3-10.5,12.7-20.4,1-8.1-9.6,6.2-19.7,6.2-19.7s-19.4,4.6-16.9,21c3.7,23.2,35.1,15.3,31.1-2.3Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M450.1,405.4c4.5,0,8.8-1.6,12.2-4.5c1.9-1.6,3.3-3.7,4.1-6.1s.9-4.9.3-7.4c0-.3-.2-.6-.4-.9-.2-.2-.5-.4-.7-.6-1.1-.4-2.3.5-4.1,1.9-5,3.8-9.9,6.6-15.4.2-.8-.9-1.4-2-1.8-3.1-.4-1.2-.5-2.4-.4-3.6.8-6.2,8.1-11.5,8.2-11.5l4.2-3-5,1.2c-.2,0-20.3,5-17.6,22.1c1.1,7.1,4.9,12.2,10.7,14.3c1.7.7,3.7,1,5.7,1Zm14.6-17.3c.5,2.1.4,4.2-.3,6.2s-1.9,3.8-3.5,5.1c-2.2,1.8-4.8,3.1-7.6,3.6s-5.7.4-8.4-.5c-5.1-1.8-8.4-6.3-9.4-12.7s1.4-11.6,7-15.6c1.4-1,2.8-1.8,4.3-2.5-2.2,2.3-4.5,5.6-5,9.4-.2,1.5,0,3,.4,4.4.5,1.4,1.2,2.7,2.2,3.9c7.1,8.5,14.3,3.1,18.1.1.8-.5,1.5-1,2.2-1.4Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M458.3,355.2c-4.1,1.7,14.3,8.2,4.5,20-8.1,9.6-20.5-2.7-20.5-2.7s7.8,18.4,23.5,13.1c22.4-7.6,9.1-37.3-7.5-30.4Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M460.3,387.5c2,0,4-.4,5.9-1c6.8-2.3,11.2-6.9,12.2-13c.5-3.2.2-6.4-1-9.5-1.1-3-3.1-5.7-5.5-7.7-1.9-1.6-4.2-2.7-6.7-3-2.5-.4-5,0-7.3,1-.3.1-.6.3-.8.5s-.4.5-.4.8c-.2,1.2.9,2.2,2.5,3.8c4.6,4.3,8.2,8.7,2.8,15.1-.7,1-1.7,1.8-2.8,2.3-1.1.6-2.3.9-3.5,1-6.2.3-12.7-6-12.7-6.1l-3.7-3.6l2,4.7c.2.3,6.5,14.7,19,14.7Zm3-32.3c2.7,0,5.3,1,7.3,2.7c2.2,1.8,3.9,4.2,4.9,6.9s1.3,5.6.9,8.4c-.9,5.3-4.8,9.4-10.9,11.4-9.7,3.3-16.3-3.2-19.7-8c2.7,1.8,6.3,3.5,10,3.3c1.5-.1,3-.5,4.3-1.2s2.5-1.7,3.4-2.9c7.1-8.5.6-14.6-3-17.9-.6-.6-1.2-1.2-1.8-1.8c1.6-.6,3.1-.9,4.6-.9Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M470.6,362.1c-1.2.5,4.3,2.4,1.4,5.9-2.4,2.9-6.1-.8-6.1-.8s2.3,5.5,7,3.9c6.6-2.3,2.6-11.1-2.3-9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M471.2,372.4c.7,0,1.3-.1,2-.3c2.3-.8,3.7-2.3,4.1-4.4.2-1.1.1-2.2-.3-3.2s-1-1.9-1.9-2.6c-.7-.6-1.5-.9-2.3-1-.9-.1-1.7,0-2.5.4-.2.1-.4.2-.5.4s-.2.4-.3.6c-.1.7.4,1.2,1,1.8c1.4,1.4,1.9,2.2.8,3.5-.2.2-.4.4-.6.5s-.5.2-.8.2c-1.3,0-2.8-1.1-3.3-1.6l-3.7-3.6l2,4.7c.8,1.6,3,4.6,6.3,4.6Zm1-9.6c.6.1,1.1.3,1.6.7.6.5,1,1.1,1.2,1.7.3.7.3,1.4.2,2.1-.2,1.4-1.2,2.3-2.8,2.8-.8.3-1.8.3-2.6,0c.5,0,1.1-.2,1.6-.4.5-.3.9-.6,1.2-1c2.3-2.7.7-4.8-.4-5.9v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M424.9,341c2.4.9-8.1,5-2.2,11.5c4.8,5.4,11.7-1.9,11.7-1.9s-4.2,10.7-13.3,7.9c-13-3.9-5.9-21.1,3.8-17.5Z"
                fill="#9d9ae7"
              ></path>
              <path
                d="M424,360c6.1,0,10-5.6,11.3-9l1.9-4.8-3.5,3.7c0,0-3.4,3.6-6.8,3.6h-.1c-.6,0-1.3-.2-1.8-.5-.6-.3-1.1-.7-1.5-1.2-3-3.3-1.2-5.7,1.3-8.1c1-1,1.7-1.7,1.5-2.6-.1-.2-.2-.5-.4-.7s-.4-.3-.6-.4c-1.4-.6-2.9-.7-4.4-.4s-2.9.9-4,1.9c-1.5,1.3-2.5,2.9-3.2,4.7-.6,1.8-.8,3.8-.4,5.7.8,3.6,3.4,6.3,7.5,7.6c1,.4,2.1.5,3.2.5Zm6.8-5.5c-2,2.3-5.1,4.4-9.5,3.1s-5.7-4-6.2-6c-.3-1.5-.2-3.1.4-4.6s1.4-2.8,2.6-3.8c.8-.7,1.7-1.2,2.7-1.4s2-.2,3,.1l-.5.6c-1.9,1.9-5.9,5.9-1.4,10.9.6.7,1.3,1.3,2.2,1.7.8.4,1.7.6,2.7.6c1.4-.1,2.8-.5,4-1.2ZM424.9,341l-.4.9.4-.9Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M451.8,358.3c-2.7,1.1,9.6,5.5,3,13.4-5.4,6.4-13.7-1.8-13.7-1.8s5.2,12.3,15.7,8.7c15-5.1,6.1-24.9-5-20.3Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M453.1,380.2c1.4,0,2.8-.3,4.1-.7c4.7-1.6,7.7-4.7,8.4-9c.3-2.2.1-4.4-.7-6.5s-2.1-3.9-3.8-5.3c-1.3-1.1-2.9-1.8-4.6-2.1-1.7-.2-3.5,0-5,.7-.3.1-.5.2-.7.4s-.3.4-.4.7c-.2,1,.6,1.7,1.8,2.8c2.9,2.8,5.3,5.6,1.8,9.7-.5.6-1.1,1.1-1.7,1.5-.7.4-1.4.6-2.2.6-4.1.2-8.2-3.9-8.3-4l-3.7-3.6l2,4.7c.1.2,4.4,10.1,13,10.1Zm2-21.6c1.7,0,3.4.6,4.7,1.7c1.4,1.2,2.5,2.7,3.2,4.4s.9,3.6.6,5.4c-.4,2.4-2,5.7-7.1,7.4-3.9,1.4-7.6.4-10.7-2.7-.4-.4-.7-.8-1.1-1.2c1.7.9,3.5,1.4,5.4,1.4c1-.1,2.1-.3,3-.8s1.8-1.2,2.4-2c4.9-5.9.3-10.3-2-12.5-.3-.3-.6-.6-.8-.8.8-.1,1.6-.3,2.4-.3Zm-2.9.6l-.4-.9.4.9Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M417.1,332.9c2.1-2.1,1.4,11,11.1,8c8-2.5,3.6-13.3,3.6-13.3s9.3,9.6,1.9,17.9c-10.4,11.8-25.2-4.1-16.6-12.6Z"
                fill="#dcdbfa"
              ></path>
              <path
                d="M425.4,350.7c3.3,0,6.5-1.6,9.1-4.5c7.9-8.9-1.9-19.2-2-19.3l-3.6-3.7l1.9,4.8c0,.1,2.2,5.5.4,9.2-.3.7-.8,1.3-1.4,1.8s-1.3.8-2,1c-5.1,1.6-6.8-1.7-8.2-5.4-.6-1.5-.9-2.5-1.9-2.8-.3-.1-.5,0-.8.1s-.5.2-.7.4c-1.2,1.2-2.1,2.7-2.6,4.4-.4,1.7-.4,3.4.1,5.1.6,2.1,1.8,4,3.4,5.6c1.6,1.5,3.6,2.6,5.7,3.1c1,.1,1.8.2,2.6.2Zm8.6-18.3c.2.5.5.9.7,1.4c1.7,4.2,1.1,7.8-1.6,11-3.6,4-7.1,4.2-9.5,3.6-1.8-.5-3.4-1.4-4.8-2.7-1.3-1.3-2.3-2.9-2.9-4.6-.4-1.2-.4-2.5-.2-3.7.3-1.2.9-2.4,1.7-3.3.1.3.3.8.4,1.1c1.1,2.9,3.3,9,10.7,6.7c1-.3,2-.8,2.8-1.4.8-.7,1.5-1.5,1.9-2.5.8-1.7,1-3.7.8-5.6Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M431.7,359.5c-1.8.8,6.4,3.7,2,8.9-3.6,4.3-9.1-1.2-9.1-1.2s3.5,8.2,10.5,5.8c10-3.3,4.1-16.6-3.4-13.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M432.7,374.5c1,0,1.9-.2,2.8-.5c3.2-1.1,5.3-3.3,5.8-6.2.2-1.5.1-3.1-.5-4.5-.5-1.4-1.5-2.7-2.6-3.7-.9-.8-2.1-1.3-3.3-1.5s-2.4,0-3.5.5c-.2.1-.4.2-.6.4s-.3.4-.3.6c-.1.8.5,1.4,1.3,2.2c2,1.9,3.3,3.5,1.2,6-.3.4-.6.7-1.1.9-.4.2-.9.3-1.3.4-2.5.1-5.3-2.5-5.3-2.6l-3.7-3.6l2,4.7c1.2,2.7,4.3,6.9,9.1,6.9Zm1.3-14.4c1.1,0,2.1.4,2.9,1.1.9.8,1.6,1.7,2,2.8s.5,2.3.4,3.5c-.3,1.5-1.3,3.6-4.5,4.7-1.1.4-2.3.5-3.4.2-1.1-.2-2.2-.8-3-1.6.7.3,1.5.4,2.3.3.7,0,1.5-.2,2.1-.6.7-.3,1.2-.8,1.7-1.4c3.5-4.2.2-7.3-1.4-8.8l-.1-.1c.4-.1.7-.1,1-.1v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M433.7,366.7c.7-1.9-7.1,2.1-7.8-4.7-.6-5.6,7.2-5.8,7.2-5.8s-8.3-3.1-11.5,3.6c-4.4,9.5,9.2,14.4,12.1,6.9Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M428.2,371.4c.4,0,.7,0,1.1-.1c1.2-.1,2.3-.6,3.3-1.4s1.7-1.8,2.1-2.9c.1-.2.1-.5.1-.7s-.1-.5-.2-.7c-.5-.7-1.4-.6-2.5-.6-2.7.1-4.8,0-5.2-3.3-.1-.5,0-.9.1-1.4s.3-.9.6-1.2c1.7-1.9,5.5-2,5.5-2l5.1-.1-4.8-1.8c-3.2-1.2-10-1.9-12.8,4.1-1.4,3.1-1.3,6.1.5,8.5.8,1.1,1.9,2,3.1,2.6c1.3.7,2.6,1,4,1Zm4.2-4.2c-.4.6-.9,1.1-1.4,1.5-.6.4-1.2.6-1.9.7-1.2.1-2.3,0-3.4-.5s-2-1.2-2.8-2.1c-.9-1.3-1.7-3.4-.3-6.5.5-1.1,1.2-2,2.2-2.6c1-.7,2.1-1,3.2-1.1-.7.4-1.3.8-1.8,1.4s-.9,1.2-1.1,2c-.2.7-.3,1.5-.2,2.2.6,5.4,5.1,5.2,7.3,5.1l.2-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M471.1,388.5c-.7,1.8,7.1-2,7.8,4.8.5,5.6-7.3,5.7-7.3,5.7s8.3,3.3,11.5-3.4c4.6-9.5-9-14.6-12-7.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M475.9,400.8c3,0,6.3-1.1,8.1-4.8c1.5-3.1,1.4-6.1-.4-8.5-.9-1.2-2.1-2.2-3.5-2.8s-3-.9-4.5-.7c-1.2.1-2.4.6-3.3,1.3-1,.7-1.7,1.7-2.2,2.9-.1.2-.1.5-.1.7s.1.5.2.7c.5.7,1.4.7,2.5.6c2.7-.1,4.8.1,5.1,3.3.1.5,0,.9-.1,1.4-.1.4-.4.9-.7,1.2-1.7,1.9-5.5,2-5.5,2l-5.1.1l4.8,1.9c1.5.4,3.1.7,4.7.7Zm.6-14.9c1.1,0,2.1.2,3,.7s1.8,1.1,2.4,2c.9,1.3,1.7,3.4.2,6.5-.5,1.1-1.2,2-2.2,2.6s-2.1,1-3.2,1c.7-.4,1.3-.8,1.9-1.4.5-.6.9-1.2,1.1-1.9s.3-1.5.2-2.2c-.5-5.4-5-5.3-7.2-5.2h-.2c.4-.6.9-1.1,1.5-1.4.6-.4,1.2-.6,1.9-.7.2,0,.4,0,.6,0v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M455,369.3c3.9-.5-7.8-8.4,4.4-14.9c10.1-5.3,14.8,5.7,14.8,5.7s1.2-14.4-13.1-13.5c-20.3,1.2-21.8,24.5-6.1,22.7Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M453.3,370.4c.6,0,1.2,0,1.8-.1.3,0,.6-.1.9-.3s.5-.4.6-.6c.5-.9,0-2-.6-3.3-1.4-3-3.4-7,3.9-10.8c2.6-1.4,5.1-1.7,7.3-.9c4.1,1.4,6.1,6,6.1,6.1l1.6,3.7.3-4c0-.3.5-6.9-3.6-11.2-2.5-2.5-6-3.7-10.5-3.4-7.6.5-13.7,4.1-16.4,9.8-1.9,4-1.7,8.3.4,11.3c1.8,2.4,4.7,3.7,8.2,3.7Zm1.4-2.1c-3.5.4-6.3-.6-7.9-2.8-1.7-2.4-1.8-6-.3-9.3c2.4-5,7.8-8.2,14.7-8.7c3.9-.3,6.9.7,8.9,2.8c1.5,1.6,2.5,3.7,2.8,5.9-1.3-1.7-3.1-3-5.1-3.7-2.8-1-5.8-.6-8.9,1-9,4.7-6.1,10.7-4.8,13.5.3.4.5.9.6,1.3v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M474.6,323.6c-3.1,2.4,11.1,3.1,3.9,15.1-5.9,9.8-15.6,2.8-15.6,2.8s6.4,13,18.2,4.8c16.8-11.6,6-32.4-6.5-22.7Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M473.6,350c2.6,0,5.3-1,8.1-2.9c6.2-4.3,9.6-10.6,8.9-16.9-.4-4.4-2.8-8-6.2-9.5-3.2-1.4-6.9-.7-10.4,2-.3.2-.5.4-.6.7s-.2.6-.2.9c0,1,1,1.7,2.2,2.5c2.7,1.8,6.5,4.3,2.3,11.3-1.5,2.5-3.5,4.1-5.8,4.6-4.2.9-8.3-2-8.4-2l-3.3-2.3L462,342c.1.2,3.1,6.2,8.9,7.7.9.2,1.8.3,2.7.3Zm7.5-27.9c.9,0,1.7.2,2.5.5c2.7,1.2,4.6,4.2,5,7.8.6,5.5-2.5,11.1-8.1,15-3.2,2.2-6.3,3-9.1,2.3-2.1-.6-4-1.9-5.4-3.5c2,.7,4.2.9,6.3.5c2.9-.6,5.3-2.5,7.1-5.5c5.2-8.7-.3-12.3-2.9-14-.4-.3-.9-.6-1.1-.8c1.6-1.4,3.6-2.2,5.7-2.3v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M477.6,392.1c-.8-1.8-3.5,6.5-8.8,2.3-4.4-3.5.9-9.2.9-9.2s-8.1,3.8-5.5,10.7c3.7,9.9,16.7,3.6,13.4-3.8Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M470.8,402c1.3,0,2.7-.3,3.9-.9s2.3-1.5,3.1-2.5c.7-1,1.2-2.1,1.4-3.3.1-1.2-.1-2.4-.6-3.5-.1-.2-.2-.4-.4-.6-.2-.1-.4-.2-.6-.3-.8-.1-1.4.5-2.2,1.4-1.8,2-3.4,3.4-5.9,1.4-.4-.3-.7-.6-.9-1s-.4-.9-.4-1.3c-.2-2.5,2.4-5.3,2.4-5.4l3.5-3.8-4.7,2.2c-3.1,1.4-8.3,5.8-6,12c1.2,3.2,3.5,5.2,6.4,5.6.2,0,.6,0,1,0Zm6.2-8.6c.2.7.2,1.4,0,2.1-.1.7-.5,1.3-.9,1.9-.7.9-1.7,1.7-2.8,2.1-1.1.5-2.3.6-3.4.5-1.5-.2-3.6-1.2-4.8-4.3-.4-1.1-.6-2.2-.3-3.4.2-1.1.7-2.2,1.5-3.1-.2.7-.3,1.5-.3,2.3.1.7.3,1.5.6,2.1.4.7.9,1.2,1.5,1.7c4.3,3.4,7.3,0,8.7-1.6l.2-.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M432.5,320.1c-.7,1.8,7.1-2,7.8,4.8.5,5.6-7.3,5.7-7.3,5.7s8.3,3.3,11.5-3.4c4.6-9.5-9-14.6-12-7.1Z"
                fill="#dcdbfa"
              ></path>
              <path
                d="M437.4,332.4c3,0,6.3-1.1,8.1-4.8c1.5-3.1,1.4-6.1-.4-8.5-.9-1.2-2.1-2.2-3.5-2.8s-3-.9-4.5-.7c-1.2.1-2.4.6-3.3,1.3-1,.7-1.7,1.7-2.2,2.9-.1.2-.1.5-.1.7s.1.5.2.7c.5.7,1.4.7,2.5.6c2.7-.1,4.8.1,5.1,3.4.1.5,0,.9-.1,1.4-.1.4-.4.9-.7,1.2-1.7,1.9-5.5,2-5.5,2l-5.1.1l4.8,1.9c1.4.4,3,.6,4.7.6Zm.6-14.9c1.1,0,2.1.3,3,.7.9.5,1.8,1.1,2.4,2c.9,1.3,1.7,3.4.2,6.5-.5,1.1-1.2,2-2.2,2.6s-2.1,1-3.2,1c.7-.4,1.3-.8,1.9-1.4.5-.6.9-1.2,1.1-1.9s.3-1.5.2-2.2c-.5-5.4-5-5.3-7.2-5.2h-.2c.4-.6.9-1.1,1.5-1.4.6-.4,1.3-.6,1.9-.6.1-.1.4-.1.6-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M452.3,335.7c-.3,2-1.6,14.9-14.1,6.9-.6-.4-1.1-.8-1.4-1.4-1.1-1.9-4.2-.6-3.6,1.5.5,2,2.5,4.2,7.2,6.1c18.4,7.7,12.7-18.9,11.9-13.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M446.3,351.2c1.1,0,2.2-.2,3.2-.6s1.9-1.1,2.7-1.9c3.7-4.2,2.7-12.3,1.6-14.1-.1-.3-.3-.5-.6-.6s-.5-.2-.8-.1c-.9.2-1.1,1.3-1.2,1.7v.2c-.6,4.3-2,7-4.1,8s-4.9.3-8.4-2c-.4-.3-.8-.6-1.1-1.1-.4-.6-.9-1.1-1.6-1.3s-1.4-.2-2.1.1-1.2.8-1.6,1.4c-.3.6-.4,1.4-.2,2.1.7,2.6,3.3,4.9,7.8,6.8c2.1.8,4.2,1.3,6.4,1.4Zm6.4-12.4c.2,2.6-.1,6.1-1.9,8.4-1.9,2.4-5.4,2.7-10.1.7-3.8-1.6-6.1-3.4-6.6-5.4-.1-.2,0-.5.1-.7s.3-.4.5-.4c.2-.1.4-.1.7-.1.2.1.4.2.5.4.4.7,1,1.3,1.7,1.7c4.2,2.7,7.7,3.4,10.4,2.2c2.2-1,3.8-3.3,4.7-6.8Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M446.7,344.9c-.3,2-4.4,27.7-14.1,6.9-1.3-2.7,8.8-1.2,8.8-1.2s-21.1-4.7-14.6,7.2c6,10.9,20.7-18.7,19.9-12.9Z"
                fill="#dcdbfa"
              ></path>
              <path
                d="M430.2,361.2c1.7,0,3.6-1,5.4-2.6c1.5,1.7,3,2.4,4.5,2.2c1.3-.2,5.6-1.9,7.6-15.8.1-.4.2-1.5-.7-1.8-1.1-.5-1.8.5-4.5,4.2l-1.5,2c-2.8-.6-12.6-2.2-15.6,1.7-1.3,1.7-1.1,4.1.5,7.1c1,1.9,2.4,2.9,4,3h.3Zm4.2-4.2c-1.6,1.4-3,2.2-4.3,2.1-.9-.1-1.7-.7-2.4-1.9-1.2-2.2-1.5-3.9-.7-4.9s2.5-1.5,4.6-1.7c-.1.2-.1.5-.1.8s.1.5.2.7c.7,1.8,1.6,3.4,2.7,4.9Zm10.9-10c-1.5,8.3-4.1,11.6-5.5,11.8-.5.1-1.4-.1-2.7-1.5c2.6-2.7,4.9-5.6,7.1-8.7.3-.6.7-1.1,1.1-1.6Zm-5.8,4.3c-1.2,1.5-2.4,2.9-3.7,4.3-.9-1.3-1.6-2.7-2.3-4.2.8-.3,3.3-.3,6-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M444.3,333.4c1.8.9-1.5-7.2,5.3-7.4c5.6-.2,5.2,7.7,5.2,7.7s3.8-8.1-2.6-11.7c-9.1-5.3-15.1,7.9-7.9,11.4Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M453.5,338.7l2.2-4.7c1.5-3.1,2.7-9.8-3.1-13.1-3-1.7-6-1.8-8.5-.2-1.3.8-2.4,2-3.1,3.4s-1.1,2.9-1,4.4c0,1.2.4,2.4,1.1,3.4s1.6,1.8,2.7,2.3c.2.1.4.2.7.2s.5-.1.7-.2c.7-.4.7-1.3.8-2.4.1-2.7.4-4.8,3.7-4.9.5,0,.9,0,1.4.2.4.2.8.4,1.2.7c1.5,1.5,1.7,4.6,1.6,5.6l-.4,5.3Zm-3.7-13.8h-.2c-5.5.2-5.6,4.7-5.6,6.9c0,.1,0,.1,0,.2-.6-.4-1-.9-1.3-1.6-.3-.6-.5-1.3-.5-2c0-1.2.2-2.3.8-3.4.5-1,1.3-1.9,2.3-2.6c1.3-.8,3.5-1.4,6.5.2c1,.6,1.9,1.4,2.4,2.4.6,1,.9,2.2.8,3.3-.3-.7-.7-1.4-1.3-1.9-.5-.5-1.1-.9-1.7-1.2-.7-.2-1.4-.3-2.2-.3v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M448.4,327.1c-1.9.7,6.3,3.9,1.7,9-3.8,4.2-9.1-1.5-9.1-1.5s3.2,8.3,10.3,6.2c10.1-3.1,4.6-16.5-2.9-13.7Z"
                fill="#dcdbfa"
              ></path>
              <path
                d="M449,342.1c.9,0,1.7-.1,2.5-.4c3.3-1,5.4-3.1,6-6c.3-1.5.2-3.1-.3-4.5-.5-1.5-1.4-2.8-2.5-3.8-.9-.8-2-1.4-3.2-1.6s-2.4-.1-3.6.3c-.2.1-.4.2-.6.4s-.3.4-.3.6c-.2.8.4,1.4,1.2,2.2c1.9,1.9,3.2,3.6,1,6-.3.4-.7.7-1.1.9s-.9.3-1.3.3v0c-2.5,0-5.1-2.7-5.1-2.7l-3.5-3.7l1.8,4.8c1.1,2.7,4.1,7.2,9,7.2Zm1.4-14.4c1.1,0,2.2.4,3,1.2.9.8,1.5,1.8,1.9,2.9s.5,2.3.3,3.5c-.3,1.5-1.4,3.5-4.7,4.5-1.1.4-2.3.4-3.4.1s-2.1-.9-2.9-1.7c.7.3,1.5.4,2.3.4.7,0,1.5-.2,2.2-.5s1.3-.8,1.8-1.4c3.7-4.1.5-7.3-1.1-8.8l-.1-.1c.2,0,.5-.1.7-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M475,360.4c1.8.9-1.5-7.2,5.3-7.4c5.6-.2,5.2,7.7,5.2,7.7s3.8-8.1-2.6-11.8c-9.2-5.2-15.1,8-7.9,11.5Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M484.2,365.8l2.2-4.7c1.5-3.1,2.7-9.8-3.1-13.1-3-1.7-6-1.8-8.5-.2-1.3.8-2.3,2-3.1,3.4-.7,1.4-1.1,2.9-1,4.4c0,1.2.4,2.4,1.1,3.4s1.6,1.8,2.7,2.3c.2.1.4.2.7.2.2,0,.5-.1.7-.2.7-.4.7-1.3.8-2.4.1-2.7.4-4.8,3.7-4.9.5,0,.9,0,1.4.2.4.2.8.4,1.2.7c1.5,1.5,1.7,4.6,1.6,5.6l-.4,5.3ZM480.5,352h-.2c-5.5.2-5.6,4.7-5.6,6.9c0,.1,0,.1,0,.2-.6-.4-1-.9-1.3-1.6-.3-.6-.5-1.3-.5-2c0-1.2.2-2.3.8-3.4.5-1,1.3-1.9,2.3-2.6c1.3-.8,3.5-1.4,6.5.3c1,.6,1.9,1.4,2.4,2.4.6,1,.9,2.2.8,3.3-.3-.7-.7-1.4-1.3-2-.5-.5-1.1-.9-1.7-1.2-.8-.2-1.5-.3-2.2-.3v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M479,354.1c-1.9.7,6.3,3.9,1.7,9-3.7,4.2-9.1-1.5-9.1-1.5s3.2,8.3,10.3,6.2c10.1-3,4.7-16.5-2.9-13.7Z"
                fill="#dcdbfa"
              ></path>
              <path
                d="M479.7,369.2c.9,0,1.7-.1,2.5-.4c3.3-1,5.4-3.1,6.1-6c.3-1.5.2-3.1-.3-4.5-.5-1.5-1.4-2.8-2.5-3.8-.9-.8-2-1.3-3.2-1.6-1.2-.2-2.4-.1-3.6.3-.2.1-.4.2-.6.4s-.3.4-.3.6c-.2.8.4,1.5,1.2,2.2c1.9,1.9,3.2,3.6,1,6-.3.4-.7.7-1.1.9s-.9.3-1.3.3v0c-2.5,0-5.1-2.7-5.1-2.7l-3.5-3.7l1.8,4.8c.9,2.7,4,7.2,8.9,7.2Zm1.4-14.4c1.1,0,2.2.4,3,1.2.9.8,1.5,1.8,1.9,2.9s.5,2.3.3,3.5c-.3,1.5-1.4,3.5-4.7,4.5-1.1.4-2.3.4-3.4.1s-2.1-.9-3-1.7c.7.3,1.5.4,2.3.4.7,0,1.5-.2,2.2-.5s1.3-.8,1.8-1.4c3.7-4.1.5-7.3-1.1-8.8l-.1-.1c.3-.1.5-.1.8-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M447.8,374.2c1.7-1.1,12.2-8.7,14.5,5.9.1.7.1,1.3-.1,2-.7,2.1,2.3,3.6,3.6,1.7c1.2-1.9,1.4-4.7-.2-9.4-6.7-19-22.6,3-17.8-.2Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M464.2,385.7c.5,0,1-.1,1.4-.3s.8-.6,1-1c1.5-2.3,1.5-5.7-.1-10.3-2.4-7-6.1-8.5-8.8-8.5v0c-5.6,0-10.9,6.2-11.6,8.1-.1.3-.2.6-.1.8.1.3.2.5.4.7.8.6,1.7,0,2-.3l.2-.1c3.6-2.4,6.5-3.2,8.7-2.3c2.1.9,3.4,3.5,4.1,7.7.1.5.1,1-.1,1.5-.2.7-.2,1.4.1,2.1s.8,1.2,1.5,1.5c.4.3.8.4,1.3.4Zm-6.6-18.1c3,0,5.3,2.4,7,7.1c1.4,3.9,1.5,6.8.3,8.5-.1.2-.3.3-.6.4-.2.1-.5,0-.7-.1s-.4-.2-.5-.5c-.1-.2-.1-.4,0-.7.2-.8.3-1.6.2-2.5-.8-4.9-2.5-8-5.3-9.2-2.2-1-5-.7-8.2,1c1.8-1.9,4.6-4,7.5-4.2l.3.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M423.5,331.9c1.7-1.1,23.6-15.2,14.5,6-1.2,2.7-6.8-5.8-6.8-5.8s10.5,19,15.1,6.1c4.2-11.7-27.7-3-22.8-6.3Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M442,343.7h.4c2.1-.2,3.8-1.9,4.9-5.1.7-2,.6-3.7-.5-5-1.1-1.4-3.2-2.2-5.7-2.5.3-2.2-.2-3.8-1.4-4.8-1-.8-5.1-2.9-16.8,4.8-.3.2-1.2.8-.9,1.7.4,1.1,1.6,1,6.1.6l2.5-.2c1.5,2.4,6.6,10.5,11.4,10.5Zm-1.2-10.6c2,.3,3.7.8,4.5,1.8.6.7.6,1.7.1,3-.9,2.4-1.9,3.7-3.2,3.8s-2.8-.9-4.3-2.3c.2-.1.5-.2.7-.4s.3-.4.4-.6c.7-1.8,1.3-3.5,1.8-5.3Zm-2.1-.2c-.4,1.5-1,3-1.6,4.5-.8-.3-2.4-2.1-4.1-4.4c2-.1,3.9-.2,5.7-.1Zm-1.7-2.1c-3.1,0-6.3.3-9,.6l-2,.2c7.2-4.4,11.4-4.7,12.5-3.8.4.4.9,1.2.7,3-.8,0-1.5,0-2.2,0v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M419.6,367.8c-.3,2.9,9.3-5.7,13,3.7c3,7.7-8.1,11-8.1,11s13.1,1.4,15-9.5c2.7-15.2-18.6-17-19.9-5.2Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M425.7,383.6c3.5,0,13.1-.9,14.8-10.4.9-4.8-.5-8.9-3.7-11.5-1.7-1.3-3.8-2.2-5.9-2.5-2.2-.3-4.4,0-6.4.8-1.6.6-3,1.7-4,3-1,1.4-1.7,3-1.8,4.7c0,.3,0,.5.1.8.1.2.2.5.4.6.8.6,1.8.3,3.3-.2c3.8-1.2,7.3-1.9,9.2,3c.3.7.5,1.4.4,2.2c0,.8-.2,1.5-.5,2.2-1.7,3.6-7.3,5.2-7.4,5.3l-4.9,1.5l5.1.5c0,0,.5,0,1.3,0Zm3.3-22.5c2.3,0,4.6.7,6.5,2.2s3.9,4.4,3,9.6c-.7,4.1-3.2,6.7-7.4,8-.5.1-1,.3-1.4.4c1.6-1,2.9-2.4,3.8-4c.5-.9.7-2,.7-3s-.2-2.1-.6-3c-2.8-7.1-8.8-5.1-11.7-4.2-.3.1-.8.2-1.1.3.2-1.2.7-2.4,1.5-3.3.8-1,1.8-1.7,3-2.1c1.1-.6,2.4-.9,3.7-.9Zm-8.4,6.8l-1-.1l1,.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M424.8,360c-.3,2.9,9.3-5.7,13,3.7c3,7.7-8.1,11-8.1,11s13.1,1.4,15-9.5c2.7-15.3-18.6-17.1-19.9-5.2Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M430.9,375.8c3.5,0,13.1-.9,14.8-10.4.9-4.8-.5-8.9-3.7-11.5-1.7-1.3-3.8-2.2-5.9-2.5-2.2-.3-4.4,0-6.4.8-1.6.6-3,1.7-4,3-1,1.4-1.7,3-1.8,4.7c0,.3,0,.5.1.8.1.2.2.5.4.6.8.6,1.8.3,3.3-.2c3.8-1.2,7.3-1.9,9.2,3c.3.7.5,1.4.5,2.2s-.2,1.5-.5,2.2c-1.7,3.6-7.3,5.2-7.4,5.3l-4.9,1.5l5.1.5c-.1-.1.4,0,1.2,0Zm3.3-22.5c2.3,0,4.6.7,6.5,2.2s3.9,4.4,3,9.6c-.7,4.1-3.2,6.8-7.4,8-.5.1-1,.3-1.4.4c1.6-1,2.9-2.4,3.8-4c.5-.9.7-2,.7-3s-.2-2.1-.6-3c-2.8-7.1-8.8-5.1-11.7-4.2-.3.1-.8.2-1.1.3.2-1.2.7-2.4,1.5-3.3.8-1,1.8-1.7,3-2.1c1.1-.7,2.4-.9,3.7-.9v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M410,388c4.4-.3-10.9-12.4,2.2-20.3c10.7-6.5,18.5,9.2,18.5,9.2s-1.4-19.9-18-20c-23.5-.1-20.6,32.2-2.7,31.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M409.1,389c.3,0,.7,0,1,0s.6-.1.9-.2.5-.4.7-.6c.6-1.1-.1-2.4-1.2-4.4-2.9-5.5-4.9-10.9,2.3-15.2c1-.7,2.2-1.1,3.4-1.3s2.4-.1,3.6.2c6,1.8,10,9.8,10.1,9.9l2.3,4.7-.4-5.2c0-.2-1.7-20.9-19-21-7.2,0-12.8,2.9-15.8,8.3-1.5,2.8-2.2,6-2.1,9.2s1,6.3,2.7,9.1c1.2,2,2.9,3.7,4.9,4.8c2,1.2,4.3,1.8,6.6,1.7Zm.7-2c-2.1.1-4.2-.3-6.1-1.3s-3.4-2.5-4.5-4.3c-1.5-2.4-2.3-5.2-2.4-8.1s.6-5.7,1.9-8.2c2.6-4.7,7.5-7.3,13.9-7.3h.1c6.5,0,11.3,3.2,14.3,9.4.7,1.5,1.3,3,1.7,4.6-2-2.5-4.8-5.4-8.4-6.4-1.4-.4-3-.5-4.4-.3-1.5.2-2.9.8-4.2,1.6-9.5,5.7-5.3,13.6-3,17.9.4.8.8,1.6,1.1,2.4v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M400.7,377.5c1.4-.1-3.2-3.7.7-6c3.2-1.9,5.5,2.7,5.5,2.7s-.4-5.9-5.4-5.9c-7,0-6.2,9.6-.8,9.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M408.2,379.3l-.4-5.1c-.2-2.4-1.7-6.9-6.4-6.9-2.4,0-4.3,1-5.3,2.8-.5,1-.8,2-.7,3.1c0,1.1.4,2.1.9,3.1.5.7,1.1,1.3,1.9,1.7s1.6.6,2.5.5c.2,0,.4-.1.6-.2s.3-.3.4-.4c.4-.7,0-1.3-.3-2-.9-1.7-1.1-2.7.4-3.6.2-.1.5-.3.7-.3s.5,0,.8,0c1.2.4,2.3,2,2.6,2.6l2.3,4.7Zm-5.3-9.3c-.7,0-1.5.2-2.1.6-3,1.8-2.1,4.3-1.4,5.7-.5-.2-1-.6-1.3-1.1-.4-.6-.6-1.3-.6-2s.1-1.4.5-2.1c.7-1.2,1.9-1.8,3.5-1.8.9,0,1.8.3,2.4.8-.4,0-.7-.1-1-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M437,412.3c-2-1.6,9.2-2.1,5.9-10.2-2.8-6.7-11.7-2-11.7-2s7.4-8.8,15.1-3.2c11,8-1.3,22-9.3,15.4Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M441.7,415c1.1,0,2.2-.2,3.2-.6c1.8-.7,3.3-1.9,4.5-3.4s2-3.3,2.2-5.2c.5-3.7-1.2-7.1-4.7-9.6-6.7-4.9-13.7.1-16.5,3.3l-3.3,3.9l4.6-2.4c0,0,4.5-2.3,7.7-1.2.6.2,1.1.6,1.6,1c.5.5.8,1,1,1.6c1.7,4.1-.7,5.7-3.9,7.3-1.3.6-2.2,1-2.3,2c0,.3,0,.5.1.7s.3.4.5.6c1.5,1.3,3.3,2,5.3,2Zm-.9-18.9c1.8,0,3.5.6,4.9,1.7c3.7,2.7,4.1,5.7,3.9,7.7-.2,1.5-.9,3-1.8,4.2-1,1.2-2.2,2.2-3.7,2.8-.9.4-2,.6-3,.5s-2-.5-2.8-1l.7-.3c2.4-1.2,7.5-3.7,4.9-9.9-.3-.9-.8-1.6-1.5-2.3-.7-.6-1.4-1.1-2.3-1.5-1.4-.4-2.8-.6-4.3-.3c1.5-1,3.3-1.6,5-1.6v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M417.2,387.2c3-.2-7.3-8.3,1.5-13.6c7.2-4.3,12.4,6.2,12.4,6.2s-.9-13.3-12.1-13.4c-15.8-.1-13.8,21.6-1.8,20.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M416.6,388.3c.2,0,.4,0,.7,0s.5-.1.8-.2c.2-.1.4-.3.6-.6.5-.9,0-1.8-.8-3.3-1.9-3.6-3.2-7,1.4-9.7.6-.4,1.4-.7,2.1-.8s1.5-.1,2.3.1c3.9,1.1,6.5,6.4,6.5,6.5l2.3,4.6-.4-5.1c0-.1-1.1-14.3-13.1-14.3h-.1c-4.9,0-8.7,2-10.8,5.7-1,2-1.5,4.2-1.5,6.4.1,2.2.7,4.4,1.9,6.3.8,1.4,2,2.5,3.4,3.3c1.5.7,3.1,1.1,4.7,1.1Zm.6-2.1v1-1v0Zm5-14.6c-1.4,0-2.8.4-4,1.2-6.6,4-3.6,9.7-2.1,12.4.2.3.4.7.5,1.1-1.3,0-2.5-.3-3.6-.9s-2-1.5-2.7-2.6c-1-1.6-1.5-3.4-1.6-5.2-.1-1.9.4-3.7,1.2-5.3c1.2-2.1,3.7-4.7,9-4.7h.1c4.2,0,7.3,2,9.3,6.1.2.5.4,1,.6,1.4-1.3-1.4-2.9-2.5-4.7-3.1-.7-.3-1.3-.4-2-.4v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M441.8,422.5c-2.7,1.3,2.3-10.8-7.9-11.2-8.4-.3-7.8,11.4-7.8,11.4s-5.7-12.1,4-17.6c13.7-7.6,22.5,12.2,11.7,17.4Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M427.3,427.9l-.3-5.2c0-.1-.3-6,2.6-8.8.5-.5,1.2-1,1.9-1.2.7-.3,1.5-.4,2.3-.3c5.3.2,5.9,3.8,6,7.8c0,1.6.1,2.7.9,3.2.2.1.5.2.8.2s.5-.1.8-.2c1.6-.7,2.9-1.9,3.9-3.3s1.5-3.1,1.6-4.9c.1-2.2-.4-4.4-1.4-6.4s-2.5-3.6-4.4-4.8c-3.6-2.2-8-2.1-12.3.3-10.4,5.9-4.5,18.8-4.4,18.9l2,4.7Zm8.9-23.4c1.6,0,3.2.5,4.5,1.3c1.5,1,2.8,2.4,3.6,4.1.8,1.6,1.3,3.5,1.2,5.3c0,1.3-.4,2.5-1.1,3.6s-1.6,2-2.7,2.6c0-.4,0-.8,0-1.2-.1-3.1-.2-9.5-7.9-9.8-1-.1-2.1.1-3.1.5s-1.9,1-2.6,1.7c-1.3,1.4-2.2,3.1-2.6,5-.1-.5-.1-1-.2-1.6-.2-4.5,1.5-7.8,5.1-9.8c1.9-1.1,3.8-1.7,5.8-1.7v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M436.6,392.6c2-.1-4.9-5.6,1-9.1c4.8-2.9,8.3,4.1,8.3,4.1s-.6-8.9-8.1-8.9c-10.6,0-9.3,14.4-1.2,13.9Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M436.2,393.6h.5c.2,0,.5-.1.7-.2s.4-.3.5-.5c.4-.7,0-1.5-.5-2.5-1.3-2.4-2-4.4.8-6.1.4-.3.8-.4,1.3-.5s.9,0,1.4.1c2.4.7,4.1,4.1,4.2,4.1l2.3,4.6-.4-5.1c-.2-3.4-2.4-9.9-9.1-9.9h-.1c-3.4,0-6.1,1.4-7.5,4-.7,1.4-1.1,2.9-1,4.4c0,1.5.5,3,1.3,4.4.6,1,1.4,1.8,2.4,2.3.9.7,2,1,3.2.9Zm3.7-11.8c-1,0-2,.3-2.9.8-4.7,2.8-2.6,6.8-1.6,8.8c0,.1.1.1.1.2-.7-.1-1.4-.3-1.9-.7-.6-.4-1.1-.9-1.4-1.5-.6-1-1-2.2-1-3.3s.2-2.3.8-3.4c.7-1.4,2.3-3,5.7-3v0c1.2,0,2.3.3,3.3.9s1.8,1.5,2.3,2.5c-.6-.5-1.3-.8-2.1-1.1-.4-.1-.8-.2-1.3-.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M437,385.1c-1.3,1.5,7.4.3,5.9,7-1.2,5.5-8.7,3.1-8.7,3.1s6.9,5.7,12,.3c7.3-7.5-4-16.5-9.2-10.4Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M441,398.9c1.1,0,2.2-.2,3.2-.7s1.9-1.1,2.7-1.9c2.4-2.5,3.2-5.4,2.3-8.2-.5-1.5-1.3-2.8-2.5-3.8-1.1-1-2.5-1.7-4-2.1-1.2-.3-2.4-.2-3.6.2-1.1.4-2.2,1.1-2.9,2.1-.2.2-.3.4-.3.6-.1.2,0,.5,0,.7.2.8,1.1,1,2.2,1.4c2.6.7,4.5,1.6,3.8,4.8-.1.5-.3.9-.5,1.3-.3.4-.6.7-1,.9-1.8,1.1-4.8.5-5.9.1l-4.9-1.5l4,3.3c2.1,1.7,4.7,2.7,7.4,2.8Zm.3-14.8c.3,0,.7,0,1,.1c1.1.3,2.2.8,3.1,1.6s1.5,1.8,1.9,2.9c.5,1.5.5,3.8-1.8,6.2-.8.9-1.8,1.5-2.9,1.8s-2.3.3-3.4,0c.8-.1,1.5-.4,2.2-.8.6-.4,1.2-.9,1.6-1.5s.7-1.3.9-2c1.2-5.3-3.2-6.6-5.2-7.2l-.2-.1c.7-.6,1.7-1,2.8-1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M408.8,352.4c1.3-1.5-7.4-.4-5.8-7.1c1.3-5.5,8.7-3,8.7-3s-6.8-5.8-12-.5c-7.4,7.5,3.8,16.7,9.1,10.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M404.5,355.4c1,0,1.9-.2,2.8-.6s1.6-1,2.2-1.7c.2-.2.3-.4.3-.6.1-.2.1-.5,0-.7-.2-.8-1.1-1.1-2.1-1.4-2.6-.8-4.5-1.6-3.7-4.8.1-.5.3-.9.5-1.3.3-.4.6-.7,1-.9c2.2-1.2,5.8-.1,5.9-.1l4.9,1.6-3.9-3.3c-2.6-2.2-8.7-5.2-13.4-.5-2.4,2.4-3.3,5.4-2.4,8.2.5,1.5,1.3,2.8,2.4,3.8s2.5,1.8,4,2.1c.5.1,1,.2,1.5.2Zm2.8-3c-.5.4-1.2.7-1.9.9-.7.1-1.4.1-2.1,0-1.1-.3-2.2-.8-3.1-1.6s-1.5-1.8-1.9-2.9c-.4-1.5-.5-3.8,1.9-6.2.8-.8,1.8-1.4,2.9-1.7s2.3-.3,3.4.1c-.8.1-1.5.4-2.2.7-.7.4-1.2.9-1.7,1.5-.4.6-.7,1.3-.9,2-1.3,5.3,3.1,6.6,5.1,7.3l.5-.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M417.7,375.8c-3.8-.8,4.7,10.5-9,12.7-11.2,1.8-12.2-10.2-12.2-10.2s-5.8,13.2,8,17c19.6,5.4,28.6-16.1,13.2-19.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M410.6,397.2c4.9,0,9.3-1.7,12.4-4.8c3.1-3.2,4.3-7.3,3.3-10.9-1-3.4-3.9-5.7-8.2-6.7-.3-.1-.6-.1-.9,0s-.6.2-.8.4c-.8.7-.7,1.8-.5,3.3.4,3.3.9,7.7-7.2,9-2.9.5-5.3,0-7.2-1.5-3.4-2.7-3.8-7.7-3.8-7.7l-.3-4-1.6,3.7c-.1.3-2.8,6.4-.2,11.8c1.5,3.2,4.5,5.4,8.9,6.6c1.8.5,3.9.8,6.1.8Zm7.1-20.4c3.5.8,5.8,2.6,6.6,5.3.9,2.8-.2,6.2-2.8,8.9-3.8,4-10.1,5.2-16.7,3.4-3.8-1-6.3-2.9-7.6-5.5-.9-2-1.1-4.3-.8-6.5.7,2,2,3.8,3.6,5.2c2.3,1.8,5.3,2.5,8.8,1.9c10-1.6,9.3-8.1,8.9-11.2c0-.6,0-1,0-1.5Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M403.8,346.8c.2,2,5.4-5,9.1.7c3,4.7-3.9,8.4-3.9,8.4s8.9-.9,8.7-8.3c-.3-10.5-14.7-8.8-13.9-.8Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M404,357.5l5.1-.5c3.4-.4,9.8-2.8,9.6-9.4-.1-3.4-1.6-6.1-4.2-7.4-1.4-.7-2.9-1-4.5-.9-1.5.1-3,.6-4.3,1.4-1,.7-1.8,1.6-2.4,2.7-.5,1.1-.7,2.3-.6,3.5c0,.2.1.5.2.7s.3.4.5.5c.8.4,1.5,0,2.5-.6c2.4-1.4,4.3-2.1,6.1.6.3.4.5.8.5,1.3.1.5.1.9,0,1.4-.6,2.5-4,4.3-4,4.3l-4.5,2.4Zm6.6-16.2c1,0,2,.2,2.9.7c1.4.7,3,2.3,3.1,5.7.1,1.2-.2,2.3-.8,3.3s-1.4,1.9-2.4,2.4c.5-.6.8-1.4,1-2.1s.2-1.5.1-2.2-.4-1.4-.8-2c-3-4.6-6.9-2.4-8.8-1.3l-.2.1c.1-.7.3-1.4.6-2c.4-.6.9-1.1,1.4-1.5c1.3-.8,2.6-1.1,3.9-1.1v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M423.1,429.6c1.3-1.5-7.4-.4-5.8-7.1c1.3-5.5,8.7-3,8.7-3s-6.8-5.8-12-.5c-7.4,7.5,3.8,16.7,9.1,10.6Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M418.8,432.6c1,0,1.9-.2,2.8-.6s1.6-1,2.2-1.7c.2-.2.3-.4.3-.6.1-.2.1-.5,0-.7-.2-.8-1.1-1.1-2.1-1.4-2.6-.8-4.5-1.6-3.7-4.8.1-.5.3-.9.5-1.3.3-.4.6-.7,1-.9c2.2-1.2,5.8-.1,5.9-.1l4.9,1.6-3.9-3.3c-2.6-2.2-8.7-5.2-13.4-.5-2.4,2.4-3.3,5.3-2.4,8.2.5,1.5,1.3,2.8,2.4,3.8s2.5,1.8,4,2.1c.5.2,1,.2,1.5.2Zm2.8-3c-.5.4-1.2.7-1.9.9-.7.1-1.4.1-2.1,0-1.1-.3-2.2-.8-3.1-1.6s-1.5-1.8-1.9-2.9c-.4-1.5-.5-3.8,1.9-6.2.8-.8,1.8-1.4,2.9-1.7s2.3-.3,3.4.1c-.8.1-1.5.4-2.2.7-.7.4-1.2.9-1.7,1.5-.4.6-.7,1.3-.9,2-1.3,5.3,3.1,6.6,5.1,7.2h.5Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M409.4,408.4c.9-1.8,6.4-13.6,15.6-1.9.4.5.7,1.1.9,1.8.4,2.2,3.8,2,3.9-.3.2-2.1-1-4.7-4.8-8.1-15.1-13.2-18.2,13.8-15.6,8.5Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M427.8,410.8h.2c.7,0,1.4-.3,1.9-.8s.8-1.2.9-1.9c.2-2.7-1.5-5.7-5.1-8.9-5.6-4.9-9.6-4.4-11.9-3-4.9,2.8-6.5,10.8-6.1,12.8c0,.3.1.6.3.8s.5.4.7.4c1,.1,1.5-.8,1.6-1.2l.1-.2c2-3.9,4.1-6,6.4-6.2s4.7,1.4,7.3,4.7c.3.4.6.9.7,1.4.1.7.5,1.3,1,1.7.6.2,1.3.4,2,.4Zm-10.5-13.6c2,0,4.4,1.2,7,3.5c3.1,2.8,4.6,5.2,4.5,7.2c0,.2-.1.4-.3.6s-.4.2-.6.2-.5,0-.6-.2c-.2-.1-.3-.3-.4-.6-.2-.8-.5-1.6-1.1-2.2-3.1-3.9-6.1-5.7-9.1-5.4-2.4.2-4.7,1.9-6.7,4.9.7-2.5,2.1-5.8,4.5-7.3.8-.4,1.8-.7,2.8-.7Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M417.7,401.6c.9-1.8,13.1-24.8,15.6-1.9.3,2.9-8.8-1.7-8.8-1.7s18.5,11.4,16.2-2.1c-2.1-12.3-25.7,10.9-23,5.7Z"
                fill="#dcdbfa"
              ></path>
              <path
                d="M436.6,403.4c1.1,0,2.3-.3,3.2-.9c1.8-1.2,2.4-3.5,1.8-6.9-.4-2.1-1.3-3.5-2.8-4.1-1.7-.7-3.9-.3-6.2.6-.9-2.1-2-3.2-3.5-3.5-1.3-.2-5.9,0-12.3,12.5-.2.3-.7,1.3.1,2c.9.8,1.9.1,5.6-2.5.6-.4,1.4-.9,2.1-1.4c1.9,1.1,7.6,4.2,12,4.2Zm.2-10.2c.4,0,.9.1,1.3.2.8.4,1.4,1.2,1.6,2.6.4,2.5.1,4.2-.9,4.9-1.1.7-2.8.6-4.9.1.2-.2.3-.4.4-.7s.1-.5.1-.8c-.2-1.8-.5-3.7-1-5.5c1-.4,2.2-.7,3.4-.8Zm-6-.2c-3.3,1.7-6.5,3.7-9.5,5.9l-1.6,1.1c4.1-7.3,7.6-9.7,9.1-9.4.5.2,1.3.6,2,2.4Zm1.4,6.6c-.8.1-3.2-.7-5.7-1.8c1.6-1,3.2-2,4.9-2.9.4,1.6.7,3.1.8,4.7Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M416.2,413.2c-1.4-1.4-.9,7.3-7.4,5.3-5.4-1.7-2.4-8.9-2.4-8.9s-6.2,6.4-1.3,12c6.9,7.9,16.8-2.7,11.1-8.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M410.6,425.4c.6,0,1.1-.1,1.7-.2c1.5-.4,2.9-1.1,4-2.2s1.9-2.4,2.4-3.9c.4-1.2.4-2.4.1-3.6s-.9-2.2-1.8-3.1c-.2-.2-.4-.3-.6-.4s-.5-.1-.7,0c-.8.2-1.1,1-1.5,2-.9,2.5-1.9,4.4-5.1,3.4-.5-.1-.9-.3-1.2-.6-.4-.3-.7-.7-.9-1.1-.9-1.9-.1-4.9.3-5.9l1.9-4.8-3.6,3.7c-2.4,2.4-5.7,8.4-1.4,13.4c1.9,2.2,4.1,3.3,6.4,3.3Zm5.5-10.8c.4.6.7,1.2.8,1.9s0,1.4-.2,2c-.3,1.1-1,2.1-1.8,2.9-.9.8-1.9,1.4-3,1.7-1.5.3-3.8.2-6-2.3-.8-.9-1.3-1.9-1.5-3s-.1-2.3.3-3.4c.1.8.3,1.5.6,2.3.3.7.8,1.3,1.4,1.8s1.2.8,2,1c5.2,1.6,6.8-2.6,7.6-4.7-.3,0-.3-.1-.2-.2Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M410.3,417.9c2-.1-4.7-5.7,1.3-9.1c4.9-2.7,8.1,4.4,8.1,4.4s-.3-8.9-7.7-9.2c-10.5-.4-9.8,14.1-1.7,13.9Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M410.1,418.9h.2c.2,0,.5,0,.7-.2.2-.1.4-.3.5-.5.4-.7.1-1.5-.4-2.5-1.2-2.4-1.9-4.4,1-6c.4-.3.8-.4,1.3-.5s.9,0,1.4.1c2.4.8,4,4.2,4,4.3l2.1,4.7-.2-5.1c-.1-3.4-2.1-9.9-8.7-10.2-3.4-.1-6.2,1.2-7.7,3.7-.8,1.3-1.2,2.9-1.2,4.4s.4,3.1,1.2,4.4c.6,1,1.4,1.9,2.4,2.5c1.1.6,2.3.9,3.4.9Zm3.7-11.8c-1,0-1.9.3-2.7.8-4.8,2.7-2.8,6.8-1.9,8.7l.1.2c-.7-.1-1.3-.4-1.9-.8s-1-.9-1.4-1.5c-.6-1-.9-2.2-.9-3.4s.3-2.3.9-3.4c.8-1.4,2.5-2.9,5.9-2.7c1.2,0,2.3.4,3.2,1c1,.6,1.7,1.5,2.2,2.6-.6-.5-1.3-.9-2-1.1-.4-.3-.9-.3-1.5-.4Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M426.1,373.5c-1.9.5-14.4,4.2-11.8-10.4.1-.7.4-1.3.8-1.8c1.4-1.8-1-4.1-2.8-2.8-1.7,1.3-2.9,3.9-2.8,9c0,20.1,22.3,4.5,16.6,6Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M417,378.9c4.7,0,9.7-3,10.7-4.4.2-.2.3-.5.4-.8c0-.3,0-.6-.2-.8-.5-.8-1.6-.5-2-.4l-.2.1c-4.2,1.1-7.2.9-8.9-.7s-2.1-4.4-1.4-8.6c.1-.5.3-1,.6-1.4.4-.6.6-1.3.6-2-.1-.7-.4-1.4-.9-1.9s-1.2-.8-1.9-.9-1.4.2-2,.6c-2.2,1.7-3.3,4.9-3.2,9.8c0,7.4,3.1,10,5.7,10.9.7.4,1.7.5,2.7.5Zm6.4-3.8c-2.3,1.2-5.7,2.3-8.4,1.5-3-.9-4.5-3.9-4.5-9.1c0-4.2.8-6.9,2.4-8.2.2-.1.4-.2.7-.2.2,0,.4.1.6.3.2.1.3.4.3.6s0,.5-.2.6c-.5.7-.8,1.4-.9,2.3-.9,4.9-.2,8.4,2,10.4c1.7,1.8,4.4,2.3,8,1.8v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M435.4,421.4c-1.9.5-27.2,6.7-11.8-10.3c2-2.2,4.5,7.7,4.5,7.7s-3.8-21.4-12.3-10.7c-7.7,9.6,25.3,11.8,19.6,13.3Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M425.4,423.9c3.5-.1,6.9-.6,10.3-1.5.4-.1,1.4-.4,1.4-1.4c0-1.2-1.2-1.5-5.6-2.5l-2.5-.6c-.6-2.8-2.9-12.5-7.6-13.7-2.1-.5-4.2.6-6.3,3.2-1.4,1.7-1.7,3.3-1.2,4.8.6,1.7,2.3,3.1,4.6,4.2-1,2-1.1,3.7-.3,5c.4.7,2,2.5,7.2,2.5Zm7.5-3c-8.2,1.8-12.3.7-13-.5-.3-.5-.4-1.4.4-3.1c3.5,1.3,7.1,2.4,10.7,3.1l1.9.5Zm-8.6-9.1c.6.6,1.6,2.8,2.4,5.5-1.8-.5-3.6-1.1-5.4-1.7.9-1.4,1.9-2.6,3-3.8Zm-.1-2.1c-.3,0-.5.1-.8.2-.2.1-.4.3-.6.5-1.3,1.4-2.4,2.8-3.4,4.4-1.8-.9-3.2-2-3.6-3.1-.3-.9,0-1.8.9-2.9c1.6-2,3-2.9,4.3-2.5c1.2.1,2.3,1.5,3.2,3.4v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M401.3,433.8c2.3-1.2-8.5-4.1-3.4-11.2c4.2-5.9,11.8.7,11.8.7s-5.2-10.2-14-6.5c-12.4,5.2-3.6,21.7,5.6,17Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M398,435.6c1.3,0,2.6-.3,3.8-.9.2-.1.4-.3.6-.5s.2-.4.3-.7c.1-.9-.7-1.6-1.8-2.4-2.7-2.2-4.7-4.3-2.1-8c.3-.5.8-1,1.3-1.3s1.1-.6,1.8-.6c3.4-.4,7.2,2.8,7.2,2.8l3.9,3.4-2.4-4.6c-2-3.8-7.7-10.2-15.3-7-4,1.7-6.3,4.6-6.7,8.3-.2,1.9.2,3.9,1,5.6s2.1,3.2,3.6,4.4c1.4,1,3.1,1.5,4.8,1.5Zm2.9-2.7l.5.9-.5-.9v0Zm-.7.3c-1,.4-2,.5-3,.4s-2-.5-2.8-1.1c-1.3-.9-2.3-2.1-2.9-3.5-.7-1.4-.9-3-.8-4.5.2-2,1.4-4.9,5.5-6.6c3.2-1.4,6.3-.8,9.2,1.6.2.1.3.3.5.4-1.3-.5-2.7-.7-4.2-.6-.9.1-1.8.4-2.6.9s-1.5,1.1-2,1.9c-3.9,5.5.4,9,2.5,10.7l.6.4Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M379.9,403.1c1.4,4.2,9.4-13.5,20.2-2.8c8.9,8.9-4.5,20.2-4.5,20.2s19-6.2,15-22.3c-5.6-22.9-36.2-12.3-30.7,4.9Z"
                fill="#dcdbfa"
              ></path>
              <path
                d="M391,423.1l4.9-1.6c.2-.1,19.8-6.7,15.7-23.6-1.7-7-5.9-11.7-11.9-13.3-3.1-.8-6.4-.7-9.5.2s-5.9,2.6-8.2,4.9c-1.8,1.8-3,4-3.6,6.4s-.4,5,.4,7.4c.1.3.2.6.4.8s.5.4.8.5c1.2.3,2.3-.7,4-2.2c4.7-4.2,9.3-7.4,15.3-1.5.9.8,1.6,1.8,2.1,3c.5,1.1.7,2.3.7,3.6-.3,6.2-7.1,12.1-7.2,12.2l-3.9,3.2Zm4.5-37c1.3,0,2.6.2,3.8.5c5.2,1.4,8.9,5.6,10.4,11.8s-.4,11.7-5.7,16.2c-1.3,1.1-2.6,2-4.1,2.8c2-2.5,4.1-6,4.2-9.7.1-1.5-.2-3-.8-4.4s-1.4-2.6-2.5-3.7c-7.8-7.8-14.5-1.8-18.1,1.4-.6.6-1.3,1.1-2,1.6-.6-2-.7-4.1-.2-6.2.5-2,1.6-3.9,3-5.4c1.6-1.6,3.4-2.8,5.5-3.7c2.1-.8,4.3-1.2,6.5-1.2v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M387.7,391.5c.4,1.3,2.8-4,6-.8c2.6,2.6-1.4,6-1.4,6s5.6-1.8,4.5-6.6c-1.6-6.9-10.7-3.7-9.1,1.4Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M387.8,399.2l4.9-1.6c2.3-.7,6.2-3.3,5.1-7.8-.6-2.3-2-3.9-4.1-4.5-1.1-.3-2.2-.2-3.2.1s-2,.9-2.8,1.6c-.6.6-1,1.4-1.2,2.2s-.2,1.7.1,2.6c.1.2.2.4.3.5.2.2.3.3.5.3.7.2,1.3-.3,1.8-.8c1.5-1.3,2.3-1.7,3.6-.5.2.2.4.4.5.6s.2.5.1.8c-.1,1.3-1.4,2.7-1.9,3.1l-3.7,3.4Zm4.5-12.1c.3,0,.6,0,1,.1c1.3.4,2.2,1.4,2.6,3c.2.9.2,1.8-.2,2.6c0-.5-.1-1.1-.3-1.6s-.5-1-.9-1.3c-2.4-2.4-4.7-1-5.9,0c.1-.6.4-1.1.8-1.5s.9-.7,1.4-.9c.4-.3,1-.4,1.5-.4Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M362.8,435.2c1.1-2.3,4.3,8.5,11.3,3.2c5.8-4.3-.9-11.8-.9-11.8s10.3,5.1,6.7,13.9c-5,12.6-21.6,4.1-17.1-5.3Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M371.5,447.9c.4,0,.8,0,1.2-.1c3.7-.4,6.6-2.9,8.2-6.9c3.1-7.7-3.4-13.3-7.2-15.2l-4.6-2.3l3.4,3.9c0,0,3.3,3.8,3,7.2-.1.6-.3,1.2-.6,1.8-.3.5-.8,1-1.3,1.4-3.6,2.7-5.7.7-8-2-.9-1.1-1.5-1.8-2.5-1.7-.2,0-.5.1-.7.3s-.4.4-.5.6c-.7,1.4-1,2.9-.8,4.4.1,1.5.7,2.9,1.6,4.2c1,1.4,2.4,2.5,3.9,3.3c1.5.6,3.2,1.1,4.9,1.1Zm5.2-17.4c2.1,2.2,4,5.5,2.3,9.7-1.7,4.3-4.5,5.4-6.5,5.6-1.5.1-3.1-.1-4.5-.8-1.4-.6-2.7-1.6-3.6-2.9-.6-.8-1-1.8-1.2-2.8-.1-1,0-2.1.3-3c.2.2.3.4.5.6c1.7,2.1,5.4,6.4,10.8,2.3.8-.5,1.4-1.2,1.9-2s.8-1.7.9-2.6c0-1.4-.3-2.8-.9-4.1Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M415.7,422c-1-1.4-1.7,6.1-7,3.5-4.3-2.1-.9-7.9-.9-7.9s-6.1,4.6-2.7,10c4.9,7.6,14.7-.1,10.6-5.6Z"
                fill="#dcdbfa"
              ></path>
              <path
                d="M410.3,431.8c.2,0,.3,0,.5,0c1.3-.1,2.6-.6,3.7-1.4s2-1.8,2.6-3c.5-1,.7-2,.5-3.1-.1-1.1-.5-2.1-1.2-2.9-.1-.2-.3-.3-.5-.4s-.4-.1-.6-.1c-.8,0-1.2.7-1.7,1.6-1.1,2-2.1,3.4-4.6,2.2-.4-.2-.7-.4-.9-.7-.3-.3-.4-.6-.6-1-.5-1.7.6-4.1,1-4.8l2.6-4.4-4.1,3.1c-2.4,1.8-6,6.5-2.9,11.4c1.7,2.2,3.9,3.5,6.2,3.5Zm5.1-8.3c.2.5.3,1,.3,1.5s-.2,1-.4,1.5c-.4.9-1.1,1.7-1.9,2.2-.8.6-1.7.9-2.7,1-1.3.1-3.1-.3-4.7-2.7-.5-.7-.8-1.5-.9-2.4-.1-.8,0-1.7.4-2.5c0,.5.1.9.2,1.4.2.6.5,1.2,1,1.7.4.5,1,.9,1.6,1.2c4.2,2.1,6.2-1.3,7.1-2.9v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M364,446.1c-1.9-2.3,11-.4,8.9-10.4-1.8-8.2-13-4.8-13-4.8s10.4-8.5,18-.3c10.8,11.4-6.3,24.8-13.9,15.5Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M370.5,450.2c.7,0,1.3-.1,2-.2c2.2-.4,4.2-1.5,5.8-2.9c1.7-1.5,2.9-3.3,3.6-5.4c1.3-4.1.1-8.3-3.3-11.8-8.2-8.7-19.3.2-19.4.3l-4,3.3l4.9-1.5c.1,0,5.7-1.7,9.2.3.7.4,1.2.9,1.7,1.6.4.6.7,1.4.8,2.1c1.1,5.2-2.3,6.6-6.1,7.7-1.6.4-2.6.7-2.9,1.7-.1.3-.1.5,0,.8s.2.5.4.7c.9,1.1,2,2,3.2,2.6c1.3.4,2.7.7,4.1.7Zm-.4-22.1c1.3,0,2.7.3,3.9.8s2.3,1.4,3.2,2.4c3.7,3.9,3.5,7.5,2.8,9.8-.6,1.7-1.7,3.3-3,4.5-1.4,1.2-3.1,2.1-4.9,2.5-1.2.3-2.5.2-3.7-.2s-2.3-1.1-3.2-2c.3-.1.8-.2,1.1-.3c3-.8,9.2-2.5,7.6-10.1-.2-1-.6-2-1.2-2.9s-1.4-1.6-2.3-2.1c-1.7-.9-3.6-1.3-5.5-1.3.5-.2,1-.4,1.5-.5c1.2-.4,2.4-.6,3.7-.6v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M381.8,430c.6,1.9,4.2-6.1,9.1-1.2c4,4-2,9-2,9s8.5-2.8,6.7-10c-2.5-10.2-16.2-5.5-13.8,2.2Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M384.3,440.3l4.9-1.6c3.2-1.1,9-4.7,7.4-11.2-.8-3.3-2.8-5.6-5.7-6.4-1.5-.4-3.1-.3-4.5.1-1.5.4-2.8,1.2-3.9,2.3-.9.9-1.5,1.9-1.7,3.1-.3,1.2-.2,2.4.2,3.6.1.2.2.4.3.6.2.2.4.3.6.4.8.2,1.5-.4,2.3-1.1c2-1.8,3.7-3,6.1-.7.3.3.6.7.8,1.1s.3.9.3,1.4c-.1,2.5-3,5-3,5l-4.1,3.4Zm4.5-17.3c.5,0,1,.1,1.6.2c1.5.4,3.4,1.6,4.3,4.9.3,1.1.3,2.3,0,3.4s-1,2.1-1.8,2.9c.3-.7.5-1.5.5-2.2s-.1-1.5-.4-2.2-.7-1.3-1.3-1.8c-3.9-3.9-7.2-.8-8.9.6l-.1.1c-.1-.7,0-1.4.2-2c.2-.7.6-1.3,1.1-1.7.6-.6,1.4-1.2,2.2-1.5s1.7-.7,2.6-.7Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M389.1,428.6c-1.8-.9,1.5,7.2-5.4,7.4-5.6.1-5.1-7.7-5.1-7.7s-3.9,8.1,2.6,11.8c9.1,5.3,15.2-7.9,7.9-11.5Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M385.4,442.4c1.4,0,2.7-.4,3.9-1.1c1.3-.8,2.4-2,3.1-3.3.7-1.4,1.1-2.9,1-4.4c0-1.2-.4-2.4-1.1-3.4s-1.6-1.8-2.7-2.4c-.2-.1-.4-.2-.7-.2-.2,0-.5.1-.7.2-.7.4-.8,1.3-.8,2.4-.1,2.7-.4,4.8-3.7,4.9-.5,0-.9,0-1.4-.2-.4-.2-.8-.4-1.2-.8-1.5-1.5-1.6-4.6-1.6-5.7l.3-5.2-2.2,4.7c-1.5,3.1-2.8,9.8,3,13.1c1.5.8,3.1,1.3,4.8,1.4Zm4.1-12.4c.6.4,1,1,1.3,1.6s.5,1.3.5,2c0,1.2-.2,2.3-.8,3.4-.5,1-1.4,1.9-2.3,2.6-1.4.8-3.5,1.4-6.5-.3-1-.6-1.9-1.4-2.4-2.4-.6-1-.8-2.2-.8-3.3.3.7.7,1.4,1.3,2c.5.5,1.2,1,1.9,1.2.7.3,1.5.4,2.2.3c5.5-.1,5.6-4.7,5.7-6.8-.1-.2-.1-.2-.1-.3Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M414.1,393.3c1.8.9-1.4-7.3,5.5-7.3c5.6-.1,5,7.8,5,7.8s4-8-2.4-11.8c-9.1-5.5-15.3,7.6-8.1,11.3Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M423.2,398.7l2.3-4.6c1.5-3.1,2.9-9.7-2.8-13.1-2.9-1.8-6-1.9-8.5-.4-1.3.8-2.4,1.9-3.1,3.3s-1.1,2.9-1.1,4.4c0,1.2.4,2.4,1,3.4s1.6,1.9,2.7,2.4c.2.1.4.2.7.2.2,0,.5-.1.7-.2.7-.4.8-1.3.8-2.4.1-2.7.5-4.7,3.8-4.8.5,0,.9,0,1.4.2.4.2.8.4,1.1.8c1.7,1.9,1.5,5.6,1.5,5.7l-.5,5.1Zm-3.6-13.8v0c-5.5.1-5.7,4.6-5.8,6.8c0,.1,0,.1,0,.2-.5-.4-1-1-1.3-1.6s-.5-1.3-.5-2c0-1.2.3-2.3.8-3.4.6-1,1.4-1.9,2.4-2.5c1.4-.8,3.5-1.4,6.5.4c1,.6,1.8,1.4,2.4,2.4s.8,2.2.8,3.3c-.3-.7-.7-1.4-1.2-2-.5-.5-1.1-1-1.8-1.3-.8-.2-1.6-.3-2.3-.3v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M393.5,407.6c-.1-3.9-9.1,7.1-14.4-5.7-4.4-10.5,6.9-14.3,6.9-14.3s-14.2-2.5-14.6,11.9c-.5,20.4,22.6,23.9,22.1,8.1Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M385.2,418.5c1.7,0,3.4-.4,4.9-1.3c3-1.8,4.6-5.2,4.5-9.6c0-.3,0-.6-.2-.9-.1-.3-.3-.5-.6-.7-.9-.6-1.9-.2-3.3.3-3.1,1.2-7.3,2.7-10.5-4.8-1.2-2.7-1.3-5.2-.3-7.3c1.8-3.9,6.5-5.6,6.6-5.6l3.8-1.3-3.9-.7c-.3,0-6.9-1.1-11.5,2.7-2.7,2.2-4.2,5.7-4.3,10.2-.2,7.6,2.9,14,8.3,17.2c2,1.1,4.2,1.8,6.5,1.8Zm7.3-10.7c.1,3.6-1.2,6.3-3.5,7.7-2.5,1.5-6.1,1.3-9.3-.5-4.7-2.8-7.5-8.5-7.3-15.4.1-3.9,1.3-6.8,3.5-8.7c1.8-1.4,3.9-2.2,6.1-2.3-1.8,1.2-3.2,2.8-4.1,4.7-1.2,2.7-1.1,5.7.3,9c3.9,9.4,10.1,7,13,5.9.4-.1,1-.3,1.3-.4Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M418.2,387c-1.9.7,6.2,4,1.5,9-3.8,4.1-9.1-1.7-9.1-1.7s3,8.4,10.2,6.4c10.2-2.8,5-16.3-2.6-13.7Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M418.7,402.1c.8,0,1.6-.1,2.4-.3c3.3-.9,5.5-3,6.2-5.9.3-1.5.3-3.1-.2-4.5-.5-1.5-1.3-2.8-2.4-3.8-.9-.8-2-1.4-3.2-1.6s-2.4-.1-3.6.3c-.2.1-.4.2-.6.4s-.3.4-.3.6c-.2.8.4,1.5,1.2,2.3c1.9,2,3.1,3.7.9,6.1-.3.4-.7.6-1.1.8s-.9.3-1.3.3c-2.5,0-5.1-2.8-5.1-2.8l-3.5-3.8l1.7,4.8c.9,2.5,4,7.1,8.9,7.1Zm1.5-14.4c1.2,0,2.3.5,3.1,1.2.9.8,1.5,1.8,1.9,2.9s.4,2.3.2,3.5c-.4,1.5-1.5,3.5-4.7,4.4-1.1.3-2.3.4-3.4,0-1.1-.3-2.1-.9-2.9-1.8.7.3,1.5.4,2.3.5v0c.7,0,1.5-.2,2.1-.5.7-.3,1.3-.8,1.8-1.3c3.7-4,.6-7.3-.9-8.8l-.1-.1c.1,0,.3,0,.6,0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M359.9,407.5c1.9.5,14.7,2.9,5.6,14.6-.4.5-.9,1-1.5,1.3-2,1-1,4.1,1.2,3.8c2.1-.4,4.4-2.2,6.7-6.6c9.2-17.9-17.7-14.5-12-13.1Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M364.9,428.2c.2,0,.3,0,.5,0c2.7-.5,5.2-2.9,7.4-7.2c3.4-6.6,1.9-10.3,0-12.3-3.9-4.1-12-3.7-13.9-2.9-.3.1-.5.3-.7.5s-.2.5-.2.8c.1,1,1.2,1.2,1.6,1.3h.2c4.3,1,6.8,2.6,7.6,4.7.8,2.2-.2,4.9-2.7,8.2-.3.4-.7.7-1.1,1-.6.3-1.2.8-1.4,1.5-.3.7-.3,1.4-.1,2.1.2.6.5,1.1,1,1.5.5.6,1.2.8,1.8.8ZM363,407.3c2.6,0,6.1.6,8.2,2.6c2.3,2.2,2.2,5.6-.2,10.2-1.9,3.7-3.9,5.8-5.9,6.1-.2,0-.5,0-.7-.1s-.3-.3-.4-.5-.1-.4,0-.7c.1-.2.2-.4.4-.5.7-.4,1.4-.9,1.9-1.6c3-3.9,4.1-7.3,3-10.2-.7-2.3-2.9-4.1-6.3-5.3v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M368.5,413.8c1.9.5,27.2,6.8,5.6,14.7-2.8,1-.5-8.9-.5-8.9s-6.5,20.7,5.9,15.2c11.5-5.1-16.7-22.3-11-21Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M376,436.7c1.4-.1,2.8-.4,4.1-1c1.9-.9,3-2.1,3.3-3.7.3-1.8-.6-3.8-2.1-5.9c1.8-1.4,2.6-2.8,2.6-4.3-.1-1.3-1.4-5.7-15.1-9-.4-.1-1.4-.3-1.9.5-.5,1.1.4,1.9,3.8,4.8l1.9,1.7c-.8,2.8-3.3,12.4.4,15.7.8.8,1.9,1.2,3,1.2Zm3.6-9.5c1.2,1.7,2,3.2,1.8,4.5-.1.9-.8,1.6-2.1,2.2-2.3,1-4,1.1-4.9.3-1-.9-1.3-2.6-1.3-4.7.2.1.5.2.7.2.3,0,.5,0,.8-.1c1.7-.8,3.4-1.5,5-2.4Zm-1.3-1.6c-1.4.7-2.9,1.4-4.3,1.9-.3-.8-.1-3.3.4-6c1.3,1.3,2.6,2.7,3.9,4.1Zm-7.8-10.2c8.1,2.2,11.2,5.1,11.3,6.5c0,.5-.3,1.4-1.8,2.6-2.5-2.8-5.2-5.4-8-7.8-.5-.4-1-.9-1.5-1.3v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M365.5,415.7c1.2-2.5-10.1,2.1-10.4-7.4-.3-7.9,10.7-7.3,10.7-7.3s-11.3-5.3-16.4,3.8c-7.3,12.8,11.2,21.1,16.1,10.9Z"
                fill="#e8e8fc"
              ></path>
              <path
                d="M358.1,421.3c.2,0,.4,0,.6,0c1.6-.1,3.2-.6,4.6-1.5s2.4-2.2,3.1-3.6c.1-.2.2-.5.2-.7c0-.3-.1-.5-.2-.7-.5-.8-1.6-.8-3.1-.9-3.9-.1-7.1-.6-7.2-5.5-.1-.7,0-1.4.3-2.1.2-.7.6-1.3,1.1-1.8c2.7-2.6,8.1-2.4,8.2-2.4l5.1.3-4.7-2.2c-.1-.1-12.2-5.6-17.7,4.2-2.3,4.1-2.4,8.2-.3,11.6c1.1,1.7,2.6,3,4.3,4c1.8.8,3.7,1.3,5.7,1.3Zm6.2-5.4c-.6,1-1.4,1.8-2.4,2.4s-2.1.9-3.3,1c-1.7.1-3.4-.3-4.9-1.1s-2.8-2-3.8-3.4c-1.2-1.9-2.1-5.2.3-9.5c1.9-3.4,5-5,9.1-4.7.4,0,.8.1,1.1.1-1.6.4-3.2,1.2-4.4,2.4-.7.7-1.2,1.5-1.6,2.5-.4.9-.5,1.9-.4,2.9.2,7.3,6.3,7.4,9.2,7.5.4-.2.8-.1,1.1-.1v0Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M406.3,422c.8.8,6.5,5.6-1.3,8.9-.3.2-.7.2-1.1.2-.3,0-.5.1-.7.2-.2.2-.4.4-.4.7-.1.3,0,.5.1.8.1.2.3.4.6.5c1.1.4,2.8.1,5.2-1.4c9.6-6.3-4.8-12.2-2.4-9.9Z"
                fill="#e4e3fb"
              ></path>
              <path
                d="M404.5,434.5c1.4,0,2.9-.6,4.7-1.8c3.8-2.5,4.1-5,3.7-6.6-.9-3.4-5.2-5.5-6.5-5.7-.3,0-.5,0-.7.1s-.4.3-.5.6c-.2.5-.1,1.1.5,1.6l.1.1c1.7,1.6,2.5,3,2.3,4.1-.2,1.4-2,2.4-3.4,3-.2.1-.4.1-.7.1-.5,0-1,.1-1.4.4s-.7.8-.8,1.3-.1,1,.2,1.5c.2.5.7.8,1.2,1c.4.3.9.3,1.3.3Zm5.2-9.9c.6.6,1,1.3,1.2,2c.4,1.4-.6,2.9-2.8,4.4-1.9,1.2-3.3,1.7-4.3,1.4c0,0-.1,0-.1-.1c0,0,0-.1,0-.1s0-.1,0-.1c.6,0,1.1-.1,1.6-.3c2.8-1.2,4.3-2.7,4.6-4.6.3-.8.2-1.7-.2-2.6Z"
                fill="#8f8ce4"
              ></path>
              <path
                d="M386.9,391.2c1.6,4.7-17.6-6-20.2,11-2.2,14,17.5,15.3,17.5,15.3s-21.3,7-28.5-10.2c-10.1-24.5,24.7-35.3,31.2-16.1Z"
                fill="#c8c6f7"
              ></path>
              <path
                d="M374.6,419.9c3.4,0,6.7-.5,10-1.4l4.9-1.6-5.1-.4c-.1,0-10.3-.8-14.7-6.3-.9-1.1-1.5-2.4-1.8-3.7-.3-1.4-.4-2.8-.1-4.1c1.6-10.3,9.7-9.5,15-9c2.5.2,4.2.4,5-.6.2-.3.3-.6.4-.9c0-.3,0-.7-.1-1-.8-2.7-2.4-5-4.5-6.9-2.1-1.8-4.7-3-7.5-3.4-3.6-.6-7.2-.2-10.6,1-3.4,1.3-6.4,3.4-8.7,6.1-4.3,5.4-5,12.4-1.9,19.9c4,10.1,12.7,12.3,19.7,12.3Zm4.2-2.2c-2.1.3-4.1.4-6.2.2-7.8-.5-13.2-4.2-16-11s-2.2-13.1,1.6-17.8c2.1-2.5,4.8-4.4,7.8-5.5s6.3-1.5,9.5-1c2.4.4,4.6,1.4,6.5,2.9c1.8,1.6,3.2,3.6,3.9,5.9-1,0-2,0-3-.1-5.4-.5-15.3-1.5-17.2,10.7-.3,1.6-.3,3.3.1,5c.4,1.6,1.1,3.1,2.2,4.5c2.8,3.5,7.3,5.2,10.8,6.2Z"
                fill="#8f8ce4"
              ></path>
            </g>
            <clipPath id="efDeGdW83Eu1037">
              <use
                xlinkHref="#efDeGdW83Eu4"
                transform="translate(218.2 207.5)"
              ></use>
            </clipPath>
          </g>
        </g>
      </svg>
    </div>
  );
};
