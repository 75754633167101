interface UxSVGProps {
  classes: any;
}

export const UxTrainingSVG = ({ classes }: UxSVGProps) => {
  return (
    <div className={`${classes}`}>
      <svg
        id="e2n4USX5VQ81"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 488 458"
        shape-rendering="geometricPrecision"
        text-rendering="geometricPrecision"
      >
        <defs>
          <linearGradient
            id="e2n4USX5VQ821-fill"
            x1="411.281"
            y1="213.146415"
            x2="434.012"
            y2="213.146415"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ821-fill-0"
              offset="0%"
              stop-color="#ffc344"
            ></stop>
            <stop
              id="e2n4USX5VQ821-fill-1"
              offset="42.29%"
              stop-color="#ffc144"
            ></stop>
            <stop
              id="e2n4USX5VQ821-fill-2"
              offset="59.87%"
              stop-color="#feba46"
            ></stop>
            <stop
              id="e2n4USX5VQ821-fill-3"
              offset="72.85%"
              stop-color="#fcae49"
            ></stop>
            <stop
              id="e2n4USX5VQ821-fill-4"
              offset="83.58%"
              stop-color="#f99c4c"
            ></stop>
            <stop
              id="e2n4USX5VQ821-fill-5"
              offset="92.81%"
              stop-color="#f58551"
            ></stop>
            <stop
              id="e2n4USX5VQ821-fill-6"
              offset="99.64%"
              stop-color="#f26f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ822-fill"
            x1="416.765"
            y1="229.921715"
            x2="441.521"
            y2="229.921715"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ822-fill-0"
              offset="0%"
              stop-color="#ffc344"
            ></stop>
            <stop
              id="e2n4USX5VQ822-fill-1"
              offset="43.66%"
              stop-color="#ffc144"
            ></stop>
            <stop
              id="e2n4USX5VQ822-fill-2"
              offset="60.95%"
              stop-color="#feba46"
            ></stop>
            <stop
              id="e2n4USX5VQ822-fill-3"
              offset="73.58%"
              stop-color="#fcae49"
            ></stop>
            <stop
              id="e2n4USX5VQ822-fill-4"
              offset="83.94%"
              stop-color="#f99d4c"
            ></stop>
            <stop
              id="e2n4USX5VQ822-fill-5"
              offset="92.79%"
              stop-color="#f68651"
            ></stop>
            <stop
              id="e2n4USX5VQ822-fill-6"
              offset="99.64%"
              stop-color="#f26f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ827-fill"
            x1="444.5616"
            y1="232.172015"
            x2="512.9266"
            y2="232.172015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ827-fill-0"
              offset="0%"
              stop-color="#ffc344"
            ></stop>
            <stop
              id="e2n4USX5VQ827-fill-1"
              offset="99.64%"
              stop-color="#f26f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ828-fill"
            x1="456.6226"
            y1="227.710015"
            x2="479.224"
            y2="227.710015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ828-fill-0"
              offset="0%"
              stop-color="#ffc344"
            ></stop>
            <stop
              id="e2n4USX5VQ828-fill-1"
              offset="22.96%"
              stop-color="#fdb547"
            ></stop>
            <stop
              id="e2n4USX5VQ828-fill-2"
              offset="66.3%"
              stop-color="#f7904f"
            ></stop>
            <stop
              id="e2n4USX5VQ828-fill-3"
              offset="99.64%"
              stop-color="#f26f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ829-fill"
            x1="454.4784"
            y1="206.212815"
            x2="496.5834"
            y2="206.212815"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ829-fill-0"
              offset="0%"
              stop-color="#ffc344"
            ></stop>
            <stop
              id="e2n4USX5VQ829-fill-1"
              offset="18.64%"
              stop-color="#febd45"
            ></stop>
            <stop
              id="e2n4USX5VQ829-fill-2"
              offset="44.37%"
              stop-color="#fbab49"
            ></stop>
            <stop
              id="e2n4USX5VQ829-fill-3"
              offset="74.08%"
              stop-color="#f78e4f"
            ></stop>
            <stop
              id="e2n4USX5VQ829-fill-4"
              offset="99.64%"
              stop-color="#f26f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ845-fill"
            x1="230.658"
            y1="366.263415"
            x2="261.088"
            y2="366.263415"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ845-fill-0"
              offset="26.9%"
              stop-color="#6f4c30"
            ></stop>
            <stop
              id="e2n4USX5VQ845-fill-1"
              offset="56.89%"
              stop-color="#9d784d"
            ></stop>
            <stop
              id="e2n4USX5VQ845-fill-2"
              offset="85.1%"
              stop-color="#c29c64"
            ></stop>
            <stop
              id="e2n4USX5VQ845-fill-3"
              offset="100%"
              stop-color="#d0a96d"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ847-fill"
            x1="170.6084"
            y1="385.257915"
            x2="189.2501"
            y2="385.257915"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ847-fill-0"
              offset="26.9%"
              stop-color="#6f4c30"
            ></stop>
            <stop
              id="e2n4USX5VQ847-fill-1"
              offset="56.89%"
              stop-color="#9d784d"
            ></stop>
            <stop
              id="e2n4USX5VQ847-fill-2"
              offset="85.1%"
              stop-color="#c29c64"
            ></stop>
            <stop
              id="e2n4USX5VQ847-fill-3"
              offset="100%"
              stop-color="#d0a96d"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ852-fill"
            x1="135.2125"
            y1="230.253015"
            x2="151.9895"
            y2="230.253015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ852-fill-0"
              offset="26.9%"
              stop-color="#6f4c30"
            ></stop>
            <stop
              id="e2n4USX5VQ852-fill-1"
              offset="56.89%"
              stop-color="#9d784d"
            ></stop>
            <stop
              id="e2n4USX5VQ852-fill-2"
              offset="85.1%"
              stop-color="#c29c64"
            ></stop>
            <stop
              id="e2n4USX5VQ852-fill-3"
              offset="100%"
              stop-color="#d0a96d"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ853-fill"
            x1="143.651"
            y1="225.948315"
            x2="155.515"
            y2="225.948315"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ853-fill-0"
              offset="26.9%"
              stop-color="#6f4c30"
            ></stop>
            <stop
              id="e2n4USX5VQ853-fill-1"
              offset="56.89%"
              stop-color="#9d784d"
            ></stop>
            <stop
              id="e2n4USX5VQ853-fill-2"
              offset="85.1%"
              stop-color="#c29c64"
            ></stop>
            <stop
              id="e2n4USX5VQ853-fill-3"
              offset="100%"
              stop-color="#d0a96d"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ854-fill"
            x1="134.747"
            y1="212.933015"
            x2="156.3192"
            y2="212.933015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ854-fill-0"
              offset="26.9%"
              stop-color="#6f4c30"
            ></stop>
            <stop
              id="e2n4USX5VQ854-fill-1"
              offset="56.89%"
              stop-color="#9d784d"
            ></stop>
            <stop
              id="e2n4USX5VQ854-fill-2"
              offset="85.1%"
              stop-color="#c29c64"
            ></stop>
            <stop
              id="e2n4USX5VQ854-fill-3"
              offset="100%"
              stop-color="#d0a96d"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ861-fill"
            x1="154.741"
            y1="287.584615"
            x2="159.787"
            y2="287.584615"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ861-fill-0"
              offset="26.9%"
              stop-color="#6f4c30"
            ></stop>
            <stop
              id="e2n4USX5VQ861-fill-1"
              offset="56.89%"
              stop-color="#9d784d"
            ></stop>
            <stop
              id="e2n4USX5VQ861-fill-2"
              offset="85.1%"
              stop-color="#c29c64"
            ></stop>
            <stop
              id="e2n4USX5VQ861-fill-3"
              offset="100%"
              stop-color="#d0a96d"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ862-fill"
            x1="153.587"
            y1="284.513415"
            x2="160.968"
            y2="284.513415"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ862-fill-0"
              offset="26.9%"
              stop-color="#6f4c30"
            ></stop>
            <stop
              id="e2n4USX5VQ862-fill-1"
              offset="56.89%"
              stop-color="#9d784d"
            ></stop>
            <stop
              id="e2n4USX5VQ862-fill-2"
              offset="85.1%"
              stop-color="#c29c64"
            ></stop>
            <stop
              id="e2n4USX5VQ862-fill-3"
              offset="100%"
              stop-color="#d0a96d"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ863-fill"
            x1="141.075"
            y1="287.548515"
            x2="161.519"
            y2="287.548515"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ863-fill-0"
              offset="26.9%"
              stop-color="#6f4c30"
            ></stop>
            <stop
              id="e2n4USX5VQ863-fill-1"
              offset="56.89%"
              stop-color="#9d784d"
            ></stop>
            <stop
              id="e2n4USX5VQ863-fill-2"
              offset="85.1%"
              stop-color="#c29c64"
            ></stop>
            <stop
              id="e2n4USX5VQ863-fill-3"
              offset="100%"
              stop-color="#d0a96d"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ865-fill"
            x1="194.326"
            y1="290.964915"
            x2="210.2408"
            y2="290.964915"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ865-fill-0"
              offset="26.9%"
              stop-color="#6f4c30"
            ></stop>
            <stop
              id="e2n4USX5VQ865-fill-1"
              offset="56.89%"
              stop-color="#9d784d"
            ></stop>
            <stop
              id="e2n4USX5VQ865-fill-2"
              offset="85.1%"
              stop-color="#c29c64"
            ></stop>
            <stop
              id="e2n4USX5VQ865-fill-3"
              offset="100%"
              stop-color="#d0a96d"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ866-fill"
            x1="206.245"
            y1="294.436015"
            x2="208.3"
            y2="294.436015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ866-fill-0"
              offset="26.9%"
              stop-color="#6f4c30"
            ></stop>
            <stop
              id="e2n4USX5VQ866-fill-1"
              offset="56.89%"
              stop-color="#9d784d"
            ></stop>
            <stop
              id="e2n4USX5VQ866-fill-2"
              offset="85.1%"
              stop-color="#c29c64"
            ></stop>
            <stop
              id="e2n4USX5VQ866-fill-3"
              offset="100%"
              stop-color="#d0a96d"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ867-fill"
            x1="207.154"
            y1="292.037015"
            x2="211.395"
            y2="292.037015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ867-fill-0"
              offset="26.9%"
              stop-color="#6f4c30"
            ></stop>
            <stop
              id="e2n4USX5VQ867-fill-1"
              offset="56.89%"
              stop-color="#9d784d"
            ></stop>
            <stop
              id="e2n4USX5VQ867-fill-2"
              offset="85.1%"
              stop-color="#c29c64"
            ></stop>
            <stop
              id="e2n4USX5VQ867-fill-3"
              offset="100%"
              stop-color="#d0a96d"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ868-fill"
            x1="196.056"
            y1="292.068115"
            x2="202.6494"
            y2="292.068115"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ868-fill-0"
              offset="26.9%"
              stop-color="#6f4c30"
            ></stop>
            <stop
              id="e2n4USX5VQ868-fill-1"
              offset="56.89%"
              stop-color="#9d784d"
            ></stop>
            <stop
              id="e2n4USX5VQ868-fill-2"
              offset="85.1%"
              stop-color="#c29c64"
            ></stop>
            <stop
              id="e2n4USX5VQ868-fill-3"
              offset="100%"
              stop-color="#d0a96d"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ882-fill"
            x1="324.7276"
            y1="241.501015"
            x2="324.5156"
            y2="249.440015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ882-fill-0"
              offset="0%"
              stop-color="#444b8c"
            ></stop>
            <stop
              id="e2n4USX5VQ882-fill-1"
              offset="99.64%"
              stop-color="#26264f"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ883-fill"
            x1="323"
            y1="231.628315"
            x2="346.325"
            y2="149.727315"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ883-fill-0"
              offset="0%"
              stop-color="#444b8c"
            ></stop>
            <stop
              id="e2n4USX5VQ883-fill-1"
              offset="99.64%"
              stop-color="#26264f"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ885-fill"
            x1="351.2244"
            y1="185.445115"
            x2="310.5564"
            y2="208.398115"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ885-fill-0"
              offset="25.87%"
              stop-color="#006393"
            ></stop>
            <stop
              id="e2n4USX5VQ885-fill-1"
              offset="43.03%"
              stop-color="#086797"
            ></stop>
            <stop
              id="e2n4USX5VQ885-fill-2"
              offset="69.78%"
              stop-color="#1e71a1"
            ></stop>
            <stop
              id="e2n4USX5VQ885-fill-3"
              offset="93.13%"
              stop-color="#377cac"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ889-fill"
            x1="360.16"
            y1="238.304815"
            x2="295"
            y2="238.304815"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ889-fill-0"
              offset="0%"
              stop-color="#444b8c"
            ></stop>
            <stop
              id="e2n4USX5VQ889-fill-1"
              offset="99.64%"
              stop-color="#26264f"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ890-fill"
            x1="339.8765"
            y1="203.735815"
            x2="321.2585"
            y2="244.097815"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ890-fill-0"
              offset="0%"
              stop-color="#007dbb"
            ></stop>
            <stop
              id="e2n4USX5VQ890-fill-1"
              offset="100%"
              stop-color="#0bf"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ891-fill"
            x1="316.2685"
            y1="194.758315"
            x2="299.1055"
            y2="231.965315"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ891-fill-0"
              offset="0%"
              stop-color="#ffc444"
            ></stop>
            <stop
              id="e2n4USX5VQ891-fill-1"
              offset="99.64%"
              stop-color="#f36f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ892-fill"
            x1="336.7844"
            y1="202.331215"
            x2="318.9623"
            y2="240.967215"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ892-fill-0"
              offset="0%"
              stop-color="#007dbb"
            ></stop>
            <stop
              id="e2n4USX5VQ892-fill-1"
              offset="100%"
              stop-color="#0bf"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ897-fill"
            x1="270.2874"
            y1="316.720815"
            x2="299.2004"
            y2="292.053815"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ897-fill-0"
              offset="0%"
              stop-color="#444b8c"
            ></stop>
            <stop
              id="e2n4USX5VQ897-fill-1"
              offset="99.64%"
              stop-color="#26264f"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ898-fill"
            x1="294.1739"
            y1="324.320015"
            x2="290.3549"
            y2="221.633015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ898-fill-0"
              offset="0%"
              stop-color="#444b8c"
            ></stop>
            <stop
              id="e2n4USX5VQ898-fill-1"
              offset="99.64%"
              stop-color="#26264f"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ8100-fill"
            x1="305.1858"
            y1="278.375515"
            x2="290.4028"
            y2="310.423515"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ8100-fill-0"
              offset="0%"
              stop-color="#007dbb"
            ></stop>
            <stop
              id="e2n4USX5VQ8100-fill-1"
              offset="100%"
              stop-color="#0bf"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ8101-fill"
            x1="298.1475"
            y1="296.530315"
            x2="284.9245"
            y2="325.195315"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ8101-fill-0"
              offset="0%"
              stop-color="#ffc444"
            ></stop>
            <stop
              id="e2n4USX5VQ8101-fill-1"
              offset="99.64%"
              stop-color="#f36f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ8102-fill"
            x1="298.6291"
            y1="262.108615"
            x2="284.8371"
            y2="292.009615"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ8102-fill-0"
              offset="25.87%"
              stop-color="#006393"
            ></stop>
            <stop
              id="e2n4USX5VQ8102-fill-1"
              offset="43.03%"
              stop-color="#086797"
            ></stop>
            <stop
              id="e2n4USX5VQ8102-fill-2"
              offset="69.78%"
              stop-color="#1e71a1"
            ></stop>
            <stop
              id="e2n4USX5VQ8102-fill-3"
              offset="93.13%"
              stop-color="#377cac"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ8103-fill"
            x1="296.665"
            y1="310.338515"
            x2="287.1041"
            y2="331.066515"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ8103-fill-0"
              offset="0%"
              stop-color="#007dbb"
            ></stop>
            <stop
              id="e2n4USX5VQ8103-fill-1"
              offset="100%"
              stop-color="#0bf"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ8104-fill"
            x1="287.1588"
            y1="279.210415"
            x2="274.5108"
            y2="306.628415"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ8104-fill-0"
              offset="0%"
              stop-color="#007dbb"
            ></stop>
            <stop
              id="e2n4USX5VQ8104-fill-1"
              offset="100%"
              stop-color="#0bf"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ8108-fill"
            x1="333.5129"
            y1="309.123215"
            x2="351.5199"
            y2="293.760215"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ8108-fill-0"
              offset="0%"
              stop-color="#444b8c"
            ></stop>
            <stop
              id="e2n4USX5VQ8108-fill-1"
              offset="99.64%"
              stop-color="#26264f"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ8109-fill"
            x1="348.5195"
            y1="315.471015"
            x2="345.9196"
            y2="245.583015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ8109-fill-0"
              offset="0%"
              stop-color="#444b8c"
            ></stop>
            <stop
              id="e2n4USX5VQ8109-fill-1"
              offset="99.64%"
              stop-color="#26264f"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ8111-fill"
            x1="354.4621"
            y1="285.153615"
            x2="345.0721"
            y2="305.508615"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ8111-fill-0"
              offset="0%"
              stop-color="#007dbb"
            ></stop>
            <stop
              id="e2n4USX5VQ8111-fill-1"
              offset="100%"
              stop-color="#0bf"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ8112-fill"
            x1="350.7472"
            y1="298.290615"
            x2="343.0252"
            y2="315.031615"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ8112-fill-0"
              offset="0%"
              stop-color="#007dbb"
            ></stop>
            <stop
              id="e2n4USX5VQ8112-fill-1"
              offset="100%"
              stop-color="#0bf"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ8113-fill"
            x1="349.7463"
            y1="307.463715"
            x2="344.5243"
            y2="318.784715"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ8113-fill-0"
              offset="0%"
              stop-color="#ffc444"
            ></stop>
            <stop
              id="e2n4USX5VQ8113-fill-1"
              offset="99.64%"
              stop-color="#f36f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ8114-fill"
            x1="345.3506"
            y1="285.644815"
            x2="337.1736"
            y2="303.371815"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ8114-fill-0"
              offset="0%"
              stop-color="#ffc444"
            ></stop>
            <stop
              id="e2n4USX5VQ8114-fill-1"
              offset="99.64%"
              stop-color="#f36f56"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ8115-fill"
            x1="351.0965"
            y1="274.008815"
            x2="342.9185"
            y2="291.737815"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ8115-fill-0"
              offset="25.87%"
              stop-color="#006393"
            ></stop>
            <stop
              id="e2n4USX5VQ8115-fill-1"
              offset="43.03%"
              stop-color="#086797"
            ></stop>
            <stop
              id="e2n4USX5VQ8115-fill-2"
              offset="69.78%"
              stop-color="#1e71a1"
            ></stop>
            <stop
              id="e2n4USX5VQ8115-fill-3"
              offset="93.13%"
              stop-color="#377cac"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ8122-fill"
            x1="97.7014"
            y1="349.881115"
            x2="121.7636"
            y2="349.881115"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ8122-fill-0"
              offset="26.9%"
              stop-color="#6f4c30"
            ></stop>
            <stop
              id="e2n4USX5VQ8122-fill-1"
              offset="56.89%"
              stop-color="#9d784d"
            ></stop>
            <stop
              id="e2n4USX5VQ8122-fill-2"
              offset="85.1%"
              stop-color="#c29c64"
            ></stop>
            <stop
              id="e2n4USX5VQ8122-fill-3"
              offset="100%"
              stop-color="#d0a96d"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ8125-fill"
            x1="51.4487"
            y1="258.598015"
            x2="74.3036"
            y2="258.598015"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ8125-fill-0"
              offset="26.9%"
              stop-color="#6f4c30"
            ></stop>
            <stop
              id="e2n4USX5VQ8125-fill-1"
              offset="56.89%"
              stop-color="#9d784d"
            ></stop>
            <stop
              id="e2n4USX5VQ8125-fill-2"
              offset="85.1%"
              stop-color="#c29c64"
            ></stop>
            <stop
              id="e2n4USX5VQ8125-fill-3"
              offset="100%"
              stop-color="#d0a96d"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ8126-fill"
            x1="62.1538"
            y1="254.341715"
            x2="72.8053"
            y2="254.341715"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ8126-fill-0"
              offset="54.19%"
              stop-color="#6f4c30"
            ></stop>
            <stop
              id="e2n4USX5VQ8126-fill-1"
              offset="72.98%"
              stop-color="#9d784d"
            ></stop>
            <stop
              id="e2n4USX5VQ8126-fill-2"
              offset="90.66%"
              stop-color="#c29c64"
            ></stop>
            <stop
              id="e2n4USX5VQ8126-fill-3"
              offset="100%"
              stop-color="#d0a96d"
            ></stop>
          </linearGradient>
          <linearGradient
            id="e2n4USX5VQ8127-fill"
            x1="56.628"
            y1="231.604715"
            x2="84.3253"
            y2="245.518715"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop
              id="e2n4USX5VQ8127-fill-0"
              offset="47.03%"
              stop-color="#6f4c30"
            ></stop>
            <stop
              id="e2n4USX5VQ8127-fill-1"
              offset="56.53%"
              stop-color="#795636"
            ></stop>
            <stop
              id="e2n4USX5VQ8127-fill-2"
              offset="72.86%"
              stop-color="#957048"
            ></stop>
            <stop
              id="e2n4USX5VQ8127-fill-3"
              offset="93.94%"
              stop-color="#c29b64"
            ></stop>
            <stop
              id="e2n4USX5VQ8127-fill-4"
              offset="100%"
              stop-color="#d0a96d"
            ></stop>
          </linearGradient>
        </defs>
        <ellipse
          rx="80.2"
          ry="18.4"
          transform="translate(394.5 409.7)"
          fill="#88a9c7"
          fill-opacity="0.5"
        ></ellipse>
        <path
          d="M267.2,41v54.8c0,4.3-1,7.8-2.3,7.8h-.3c-1.3,0-2.3-3.5-2.3-7.8v-54.2c1.6-.2,3.3-.4,4.9-.6Z"
          fill="#9fc1f4"
        ></path>
        <path
          d="M263.4,90.4c-18.1,0-32.7,15.5-32.7,34.6h65.4c0-19.2-14.6-34.6-32.7-34.6Z"
          fill="#f6c651"
        ></path>
        <path
          d="M266,90.8c-.9-.2-1.9-.3-2.9-.3-11.8,0-21.3,15.4-21.3,34.5h5.7c.1-17.5,8.1-31.9,18.5-34.2Z"
          opacity="0.5"
          fill="#fff"
        ></path>
        <g>
          <path
            d="M399.9,402.9c-.2,3-4.5,3-9.3,2.5-4.8-.6-3.5.7-10.3.8s-9.1-2.1-9.4-3.1c-.3-.9,3-1.6,6.4-2.4.4-.1.9-.2,1.3-.3c3.9-1,6.5-3.9,9.9-6.5c3.2-2.4,7.3-11.2,7.3-11.2l9.2-.4c0,0-3.9,7.9-5.4,11.3-.9,2-.5,4.5-.1,6.3.3.9.5,1.9.4,3Z"
            fill="#ffb27d"
          ></path>
          <path
            d="M390.6,405.4c-4.8-.6-3.5.7-10.3.8s-9.1-2.1-9.4-3.1c-.3-.9,3-1.6,6.5-2.4v.1c0,0,4.4,2.3,9.9,1.8c4.5-.5,9.2-2.3,12.3-2.7.2,1,.4,2.1.3,3.1-.2,2.9-4.5,3-9.3,2.4Z"
            fill="#233862"
          ></path>
          <path
            d="M463.7,403.5c-.2,3-4.5,3.1-9.3,2.5s-3.5.7-10.3.8-9.1-2.1-9.4-3.1c-.3-.9,3-1.6,6.4-2.4.4-.1.9-.2,1.3-.3c3.9-1,6.5-3.9,9.9-6.5c3.2-2.4,0-16.8,0-16.8l10.2-1c0,0-.5,12.1-.5,17.7c0,2.2,1,4.3,1.4,6.2.2.8.4,1.9.3,2.9Z"
            fill="#ffb27d"
          ></path>
          <path
            d="M454.4,406c-4.8-.6-3.5.7-10.3.8s-9.1-2.1-9.4-3.1c-.3-.9,3-1.6,6.4-2.4v.1c0,0,4.4,2.3,9.9,1.8c4.5-.5,9.2-2.4,12.3-2.7.2,1,.4,2.1.3,3.1-.1,2.9-4.4,3-9.2,2.4Z"
            fill="#233862"
          ></path>
          <path
            d="M434,154.4c-1.8-7.2.8-10.5,3.1-12.3c1.1-2.6,2.3-4.6,3.6-5.3c2.3-1.3,5.1-1.5,7-1.3-.5-.5-1.5-.7-1.5-.7c1.2-.2,3.9,1,3.9,1c16.7-6.4,19.3,18.1,19,23.9-.3,5.7-.3,12.4,8.4,16.9c16.6,8.6-5.5,40.9-5.5,40.9.4-4.6-20.9-29-32-36.3-2.7-4-3.7-11-3.7-11.6.5,5.6,1.4,8.7,2.3,10.5-5.2-4.6-5.9-15.8-4.6-25.7Z"
            fill="#0b232b"
          ></path>
          <path
            d="M440.6,181.6c-.1,0,5.4,2.8,9.4,1.7c3.8-1,6.3-6,6.3-6-1.6-.3-2.1-2.6-2.1-12.1l-.7.1-12.3,2.4c0,0,.3,3.9.3,7.5c0,3.2-.2,6.1-.9,6.4Z"
            fill="#ffb27d"
          ></path>
          <path
            d="M441.2,167.8c0,0,.3,3.9.3,7.5c6.9-.2,10.6-6.5,12.1-10l-12.4,2.5Z"
            fill="#ed975d"
          ></path>
          <path
            d="M391,387.4c-.6,1.9.8,3.9,2.7,3.9l4.8.2l5.1.2c1.3,0,2.5-.5,3.3-1.5c4.1-5,16.6-21.6,24.2-45.8.2-.7.4-1.4.7-2.1c5-14.6,15.4-33,20.9-49.6.9-2.6,1.7-5.1,2.4-7.5.2-.8.4-1.5.7-2.2c4-14,5.2-23.3,6.3-33.1l-39.6.3c0,0-8.4,64.8-31.5,137.2Z"
            fill="#2a4e96"
          ></path>
          <path
            d="M428,227.4c.1,18.8-2.8,26.9-2.8,26.9s17,8.6,37.1,2.3c.2-.1,14.4-4.1,14.4-4.1-.3-.8-3.1-13.4-3.5-14.1-4.3-9.1-5.4-13-5.9-15.8-.9-5.2-1-10.7-.5-15.8c1.2-13.6,4.9-24.8,4.9-24.8-5.5-1.2-10.6-3.4-16.4-5.6-1.3,1.6-5.3,3.3-8.8,3.3-4-.1-5.3-1.8-5.1-3.7-4.3,2.4-7.7,4.6-7.7,4.6-3.6,6.3-11,12.4-11.5,22.1-.1,2.6,5.8,24.2,5.8,24.7Z"
            fill="#fff"
          ></path>
          <path
            d="M462.1,229.5c1.7,2.4,5.1,17.3,13.3,19.2-4.3-10.6-7.5-15.3-8-18.5-.9-6.1-1-12.4-.5-18.3-2.8-4.8-4.8-8.2-4.8-8.2-.2-.8-4.4-13.7-5.7-2.2-1.4,11.6,3.8,25.3,5.7,28Z"
            fill="#5f9aa8"
          ></path>
          <path
            d="M438.9,171.2c0,0,16.3.3,16.4-9.9c0-10.2,2.1-17-8.3-17.6s-12.5,3.3-13.3,6.7c-.9,3.4,1,20.5,5.2,20.8Z"
            fill="#ffb27d"
          ></path>
          <path
            d="M439.5,143.5c0,0,4.5,11.9,11.3,13.7c6.9,1.8,9.8-.1,9.8-.1s-5.3-4.2-6.4-11.5c.2,0-11.3-7.8-14.7-2.1Z"
            fill="#0b232b"
          ></path>
          <path
            d="M440.6,144.4c0,0-3.3,2.7-4.2,5.3-.8,2.6-1.2,7.9-2.7,7.7c0,0-3.4-8.1.4-12.7c4.1-4.8,6.5-.3,6.5-.3Z"
            fill="#0b232b"
          ></path>
          <path
            d="M413.2,240.5L405.5,264l4.4,1.8L422.7,245l-9.5-4.5Z"
            fill="#ffb27d"
          ></path>
          <path
            d="M434,179.3c0,0-9.1,3.6-11.7,9.2s-6.6,43.4-8.2,47.3-2.8,6.9-2.8,6.9s4.6,3.9,9.2,4.1c4.6.4,13.5-67.5,13.5-67.5Z"
            fill="url(#e2n4USX5VQ821-fill)"
          ></path>
          <path
            d="M441.5,176.1c0,0-9.5,23.4-9.7,28.1-.3,4.7-1.1,77.3-1.1,77.3s.6,3.3-5.8,1.9-8.1-2.8-8.1-2.8s6.1-44.8,4.4-60.3c-1.7-15.6,12.8-41,12.8-41l7.5-3.2Z"
            fill="url(#e2n4USX5VQ822-fill)"
          ></path>
          <path
            d="M398.6,391.6l5.5.2c1,0,1.9-.4,2.6-1.2c3.7-4.5,16.7-21.4,24.5-46.2.2-.7.4-1.4.7-2.1c5-14.6,15.4-33,20.9-49.6.9-2.6,1.7-5.1,2.4-7.5.2-.8.4-1.5.7-2.2l-5.8-4.4c0,0-17.5,28.4-22.9,55-4.9,23.2-23.8,51.1-28.6,58Z"
            fill="#1e3d70"
          ></path>
          <path
            d="M449.4,336.1c-.1,12.3.5,39.6,2,53c.1,1.2,1.2,2.1,2.4,2.1h4.1c1.7,0,3,0,3.9,0c.7,0,1.4-.5,1.5-1.3c4.1-20.9,7.1-38.3,9.2-53.3c1.8-12.6,3.4-23.3,4.6-32.6s2.1-17.1,2.4-23.8c0-1,.1-2.1.1-3c0-1,0-1.9,0-2.9L445.4,271c.5,22.8,4,57.5,4,65.1Z"
            fill="#2a4e96"
          ></path>
          <path
            d="M457.9,391.3c1.6,0,2.8,0,3.6,0c.9,0,1.7-.7,1.9-1.6c4.1-20.8,7-38.1,9.2-53c1.8-12.6,3.4-23.3,4.6-32.6s2.1-17.1,2.4-23.8L467,287.7c0,0,8,2.8,5,24.2-3.1,21.4-9.9,76.3-14.1,79.4Z"
            fill="#1e3d70"
          ></path>
          <path
            d="M464.6,204.7l-9.1-28.1c0,0,11.5,3.4,17.3,6.3c0,0,3.6,4.7,5.9,15.4c0,0,2.4,25.8-2.9,35.3c0,0-16.7,3.3-43.4-5.8v-6.1c0,0,29.2,1.5,35.2,2.2l-3-19.2Z"
            fill="#ffb27d"
          ></path>
          <path
            d="M456.3,280.1c0,0,2.4,8.7,10.8,7.6c8.3-1.1,20.2-6.1,20.2-6.1s-3.6-11.3-8-26.1c-5.4-17.9-12-41-15.1-55.6-.9-4.1-1.5-7.5-1.7-9.9l-6.9-13.4c0,0-10.3,23.2-10.9,29-.7,5.8,11.6,74.5,11.6,74.5Z"
            fill="url(#e2n4USX5VQ827-fill)"
          ></path>
          <path
            d="M456.6,217.8c.4,9.5,2.6,27.8,22.4,37.6.1,0,.1.1.2.1-5.4-17.9-12-41-15.1-55.6-3.7,5.5-7.7,12.7-7.5,17.9Z"
            fill="url(#e2n4USX5VQ828-fill)"
          ></path>
          <path
            d="M455.5,176.6c0,0,16.9,1.2,21,10.3s5.4,39.3.2,48.2c0,0-7.1,2-19.8-.3-.2,0-.3-.1-.5-.1c0,0-4-9.9-.4-11.8c0,0,10.7.6,9.5-3-1.1-3.6-10-43.3-10-43.3Z"
            fill="url(#e2n4USX5VQ829-fill)"
          ></path>
          <path
            d="M413,225.5l.5,1.6l27.8-8.6c0,0,.4-.4.2-1s-.7-.6-.7-.6L413,225.5Z"
            fill="#a9453d"
          ></path>
          <path d="M411,227l2-1.5.5,1.6-2.5-.1Z" fill="#a9453d"></path>
          <path
            d="M406.6,263.4c0,0-7,3.9-8,6.9-.9,3-.3,9.2.8,9.1s2.8-6.7,3.7-7.1c.9-.5,2.2-1.9,2.2-.8c0,1-.9,2.9-.5,3.2.5.3,1.9-2.6,3-3.2c1-.6,1.4-5.1,2.4-6.4c1.1-1.3-3.6-1.7-3.6-1.7Z"
            fill="#ffb27d"
          ></path>
          <path
            d="M432.3,221.7c0,0-6.3-3.9-8.9-3.3s-9.4,5.5-6.1,6.5c3.2,1.1,5.9-.1,7.1.7s2.2,2.2,8,2.2-.1-6.1-.1-6.1Z"
            fill="#ffb27d"
          ></path>
        </g>
        <g>
          <g opacity="0.6">
            <g opacity="0.6">
              <path
                d="M148.7,402.6h-.1c-.6,0-1.1-.5-1.1-1.1l1.7-71.7l4.9-.6-4.2,72.3c-.1.6-.6,1.1-1.2,1.1Z"
                opacity="0.6"
                fill="#77a4e5"
              ></path>
            </g>
            <g opacity="0.6">
              <path
                d="M120.3,402.6h-.1c-.6,0-1.1-.5-1.1-1.1l1.7-71.7l4.9-.6-4.2,72.3c-.1.6-.6,1.1-1.2,1.1Z"
                opacity="0.6"
                fill="#77a4e5"
              ></path>
            </g>
            <g opacity="0.6">
              <path
                d="M198.7,402.6h.1c.6,0,1.1-.5,1.1-1.1l-1.7-71.7-4.9-.6l4.2,72.3c.1.6.6,1.1,1.2,1.1Z"
                opacity="0.6"
                fill="#77a4e5"
              ></path>
            </g>
            <path
              d="M117.1,325.3v-49.1c0-8.6,7-15.7,15.7-15.7h35.4c8.6,0,15.7,7,15.7,15.7v51l-66.8-1.9Z"
              opacity="0.6"
              fill="#77a4e5"
            ></path>
            <path
              d="M190.2,333h-68.4c-2.6,0-4.7-2.1-4.7-4.7v-3.1h73.1v7.8v0Z"
              opacity="0.6"
              fill="#77a4e5"
            ></path>
            <path
              d="M198.3,333h-51.7c-1.9,0-3.5-1.6-3.5-3.5v-.8c0-1.9,1.6-3.5,3.5-3.5h51.7c1.9,0,3.5,1.6,3.5,3.5v.8c0,2-1.5,3.5-3.5,3.5Z"
              opacity="0.6"
              fill="#77a4e5"
            ></path>
          </g>
          <path
            d="M237.1,374c.5,2.5,4,2.1,8,1.1c3.9-1,3,.2,8.6-.4s7.3-2.7,7.5-3.5c.2-.7-2.6-1-5.6-1.3-.4,0-.7-.1-1.1-.1-3.4-.4-5.8-2.6-8.9-4.4-2.9-1.7-7.2-8.5-7.2-8.5l-7.7.6c0,0,4,6.1,5.6,8.8.9,1.6.9,3.6.8,5.2-.2.7-.2,1.6,0,2.5Z"
            fill="url(#e2n4USX5VQ845-fill)"
          ></path>
          <path
            d="M245,375.1c3.9-1,3,.2,8.6-.4s7.3-2.7,7.5-3.5c.2-.7-2.6-1-5.6-1.3v.1c0,0-3.4,2.4-8,2.5-3.8.1-7.8-1-10.4-1-.1.9-.1,1.8.1,2.6.4,2.3,3.9,1.9,7.8,1Z"
            fill="#233862"
          ></path>
          <path
            d="M171.1,385c-1.5,2,1.1,4.4,4.4,6.7s1.8,2.4,6,6.2s6.9,3.7,7.6,3.3-1-2.6-2.7-5.1c-.2-.3-.4-.6-.6-.9-1.9-2.8-1.9-6.1-2.6-9.5-.6-3.3,4.7-15.3,4.7-15.3l-8.6-1.1c0,0-1.1,7.3-2.1,10.2-.6,1.7-3,2.9-4.2,3.8-.7.5-1.4,1-1.9,1.7Z"
            fill="url(#e2n4USX5VQ847-fill)"
          ></path>
          <path
            d="M175.5,391.7c3.3,2.3,1.8,2.4,6,6.2s6.9,3.7,7.6,3.3-1-2.6-2.7-5.1h-.1c0,0-4-.9-7.2-4.4-2.6-2.8-4.4-6.5-6.2-8.5-.7.5-1.4,1.1-1.9,1.8-1.5,2,1.2,4.4,4.5,6.7Z"
            fill="#233862"
          ></path>
          <path
            d="M206.1,323.5l-7.8,21.1L188,372.2c0,0-2.8.3-5.4-.5-2.5-.7-5.3-2.6-5.3-2.6s2.8-29.6,8.2-46.6c1.4-4.4,2.9-7.9,4.6-9.9c8.2-9.7,16,10.9,16,10.9Z"
            fill="#163560"
          ></path>
          <path
            d="M198.2,290c-5.1-1.7-20.6-.7-35.4,2.2l-2.3-3.9-31.8-2.5c0,0-10.5,23.3-4.8,38.1.8,2.2,2.5,3.6,5.4,3.6c0,0,.5,0,.5,0s18.9,0,25.1,0c15.8,0,33.5-10.9,35.8-11.3c3.7-.7,5.2,6.7,9,14.6c3.7,7.9,35.2,34.8,35.2,34.8s4.8,0,6.8-.7c2.1-.8,2.9-1.8,2.9-1.8s-34.8-69.4-46.4-73.1Z"
            fill="#2a4e96"
          ></path>
          <path
            d="M149.4,192c-4.5-1.1-10.6.2-11.6.3-12.1,1.4-14.8,20.2-13.9,25.4c1.7,9.7-3,12.4-8.5,20.1-11.1,15.5-3.4,28.3,5.6,31.4c5.4,1.9,12.8-3.3,12.8-3.3.1,0,19.2-18.2,28.6-27c16-6.4-1.6-44.2-13-46.9Z"
            fill="#0b232b"
          ></path>
          <path
            d="M152,236.2c.1,0-4.9,3.3-9,2.6-3.8-.6-7.9-5.6-7.8-5.6c1.5-.5,2.9-2.3,1.9-11.5l.7.1L150,223c0,0,.1,3.8.5,7.3.3,2.9.8,5.7,1.5,5.9Z"
            fill="url(#e2n4USX5VQ852-fill)"
          ></path>
          <path
            d="M150.1,222.9c0,0,.1,3.8.5,7.3-6.7.5-10.9-5.3-12.6-8.5l12.1,1.2Z"
            fill="url(#e2n4USX5VQ853-fill)"
          ></path>
          <path
            d="M152.6,226c0,0-15.8,1.8-16.8-8-1-9.9-3.7-16.3,6.3-17.8c10-1.6,12.4,2,13.5,5.2c1.2,3.1,1.1,19.9-3,20.6Z"
            fill="url(#e2n4USX5VQ854-fill)"
          ></path>
          <path
            d="M153.8,200.2c0,0-7.6,10.9-14.1,13.3s-9.5.9-9.5.9s3.1-4.3,4.1-10.1c.3-1.7,1.5-3.2,3.2-3.7c4.6-1.6,13.5-4.1,16.3-.4Z"
            fill="#0b232b"
          ></path>
          <path
            d="M147.5,201c0,0,4.1,2.3,5.7,4.4c1.6,2,3.2,7.3,3,9.7c0,0,2.6-11.2-1.5-15.3-4.4-4.3-7.2,1.2-7.2,1.2Z"
            fill="#0b232b"
          ></path>
          <path
            d="M164.1,262.4c-.5,31.9-.1,25.2-3.6,28.3-.8.7-3.2,1.2-6.3,1.4-9.8.6-27-1-27.6-2.8-2.3-6.3-.7-8.2-2.5-17.8-.2-1-.4-2.1-.7-3.3-1.6-6.6-3.1-8.8,2.5-19.4c5.1-9.6,10.5-17.2,11-16.9c13.3,7.9,14.2,1.1,14.2,1.1s13.3,13.8,13,29.4Z"
            fill="#fff"
          ></path>
          <path
            d="M198.3,344.6l-10.5,28.7c0,0-3.8,1.2-6.5.8-2.8-.4-4.3-1.9-4.3-1.9s3.2-32.7,8.5-49.7l12.8,22.1Z"
            fill="#2a4e96"
          ></path>
          <path
            d="M150.9,232.9c0,0,13.7,2.3,15.3,8.2s13.4,38.4,13.4,38.4-9.3,3.4-12.5.2-11.2-29.9-11.2-29.9l-5-16.9Z"
            fill="#fff"
          ></path>
          <path
            d="M154.2,292c-9.8.6-27-1-27.6-2.8-2.3-6.3-.7-8.2-2.5-17.8l4.6-10.4c0,0-.6,12,3.7,15.8c4.4,3.9,17.9,7.5,20,10.8.9,1.6,1.6,3.1,1.8,4.4Z"
            fill="#dfebfd"
          ></path>
          <path
            d="M154.7,286.2c0,0,2.9,2.5,3.7,2.7s1.3,0,1.3,0l-2.1-2.5-2.9-.2Z"
            fill="url(#e2n4USX5VQ861-fill)"
          ></path>
          <path
            d="M153.6,283.2l7.4,1.9c0,0-.2.5-1,.7-.8.1-3.1-.2-3.1-.2l-3.3-2.4Z"
            fill="url(#e2n4USX5VQ862-fill)"
          ></path>
          <path
            d="M141.1,287.3c0,0,10.5-4.7,12.5-4.1s7,3.3,7.8,4.4-4.2-.1-5.7-.2c-1-.1-3.3,1.3-4.5,2-4.9,2.8-8.2,2.5-8.2,2.5l-1.9-4.6Z"
            fill="url(#e2n4USX5VQ863-fill)"
          ></path>
          <path
            d="M136.7,231.8c0,0-14.9,2.8-17.7,6.9-6,9-18.8,46.3-11.7,51.3c8.1,5.6,36.8,1.5,36.8,1.5s1-4.5-.4-6.6c-1.3-2.2-22.9-5.7-23.9-6.6-1-1,9.9-15.4,8.7-18.2-3.6-8.8,8.2-28.3,8.2-28.3Z"
            fill="#fff"
          ></path>
          <path
            d="M195.7,284.7c0,0,10.4,2.8,11.5,4.4s3.2,6.6,3.1,7.9-2.9-2.6-4-3.5c-.7-.6-3.2-1-4.4-1.2-3.1-.5-4.6-1.9-5.8-2.6-.8-.5-1.7-.4-1.7-.4l1.3-4.6Z"
            fill="url(#e2n4USX5VQ865-fill)"
          ></path>
          <path
            d="M206.2,292c0,0,.6,3.5,1.1,4.2.5.6.9.8.9.8l-.1-3.1-1.9-1.9Z"
            fill="url(#e2n4USX5VQ866-fill)"
          ></path>
          <path
            d="M207.2,289.1l4.2,5.7c0,0-.5.3-1.1-.1-.7-.4-2.1-2-2.1-2l-1-3.6Z"
            fill="url(#e2n4USX5VQ867-fill)"
          ></path>
          <path
            d="M196.1,289.6c0,0,1,2.3,2.9,3.2c1.9.8,3.3,2.1,3.7,1.7.3-.5-1.5-2.8-1.5-2.8l-5.1-2.1Z"
            fill="url(#e2n4USX5VQ868-fill)"
          ></path>
          <path
            d="M176.9,271.9l21.9,13.9c0,0-2.3,3.1-3.1,3.4-4.1,1.5-22.3.1-28.6-9.3l9.8-8Z"
            fill="#fff"
          ></path>
          <path
            d="M168.2,287c1.3,0,14.2-.9,14.2-.9s-.4,2.3.4,3.5L170.3,292l-2.6-.8.5-4.2Z"
            fill="#f9ddbf"
          ></path>
          <path
            d="M146.7,289l21.5-2.1c0,0-.8,4,2,5l-23.7,1.7c.1.1-1.9-2.6.2-4.6Z"
            fill="#f99746"
          ></path>
          <path
            d="M182.3,286.4l-14.5,1.1c0,0,.1-.5.4-.5s14.4-1,14.4-1l-.1.4h-.2Z"
            fill="#f99746"
          ></path>
          <path
            d="M170.2,292l12.8-2.4-.1-.3-13.6,2.6.9.1Z"
            fill="#f99746"
          ></path>
        </g>
        <g>
          <path
            d="M407.7,404.5L372.6,258.8l2.5-5.7l34.8,151.5c.1.4-.2.8-.6.8h-.6c-.6-.1-.9-.4-1-.9Z"
            fill="#77a4e5"
          ></path>
          <path
            d="M249.1,405.3c-.7,0-1.3-.6-1.2-1.4l10.3-62.7h4.4l-12.3,63c0,.6-.6,1.1-1.2,1.1Z"
            fill="#77a4e5"
          ></path>
          <path
            d="M348.4,405.3c-.7,0-1.3-.6-1.2-1.4l10.3-62.7h4.4l-12.3,63c-.1.6-.6,1.1-1.2,1.1Z"
            fill="#77a4e5"
          ></path>
          <path
            d="M372.4,341.4h-123.5L274.5,181h123.5L372.4,341.4Z"
            fill="#e2f1f8"
          ></path>
          <path d="M381.7,340.1h-141.9v4.7h141.9v-4.7Z" fill="#77a4e5"></path>
        </g>
        <g id="e2n4USX5VQ880" transform="translate(5 -1)">
          <path
            d="M317.4,249.2c1.1-.4,2-1.3,2.2-2.5l1.6-8.1l13.9.5l1.7,8.4c.2,1.2-.7,2.4-1.8,2.8l-5.8,2.3c-1.1.4-2.2.6-3.4.5L313.5,252c-.4,0-.6-.3-.7-.7c0-.4-.3-.8,0-.9l4.6-1.2Z"
            fill="#97a6b7"
          ></path>
          <path
            d="M317.2,248.7c1.1-.4,2-1.3,2.2-2.5l1.6-8l13.6.5l1.7,8.3c.2,1.2-.4,2.3-1.5,2.8l-5.8,2.3c-1.1.4-2.2.6-3.4.5l-12.3-1c-.4,0-.6-.3-.7-.7c0-.4.2-.7.5-.8l4.1-1.4Z"
            fill="url(#e2n4USX5VQ882-fill)"
          ></path>
          <path
            d="M295,233v-36.1c0-.7.6-1.4,1.4-1.4l62.1-2.5c.9,0,1.7.6,1.7,1.4v39.9L295,233Z"
            fill="url(#e2n4USX5VQ883-fill)"
          ></path>
          <path
            d="M358.2,200v31.2c0,.8-.7,1.4-1.6,1.4l-58.9-1.7c-.7,0-1.3-.6-1.3-1.3v-27.8l61.8-1.8Z"
            fill="#fff"
          ></path>
          <path
            d="M296.4,201.8v-3.2c0-.7.6-1.3,1.3-1.3l59-2.2c.9,0,1.6.6,1.6,1.3v3.6l-61.9,1.8Z"
            fill="url(#e2n4USX5VQ885-fill)"
          ></path>
          <path
            d="M353.9,197.6c0,.3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5c-.2,0-.5.2-.5.5Z"
            fill="#9d3060"
          ></path>
          <path
            d="M351.6,197.7c0,.3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5Z"
            fill="#9d3060"
          ></path>
          <path
            d="M349.2,197.7c0,.3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5c-.2,0-.5.3-.5.5Z"
            fill="#9d3060"
          ></path>
          <path
            d="M360.2,234.4v8c0,.8-.8,1.5-1.7,1.4l-62.1-2.6c-.7,0-1.4-.7-1.4-1.4v-7l65.2,1.6Z"
            fill="url(#e2n4USX5VQ889-fill)"
          ></path>
          <path
            d="M319.5,217.5l34.2.2v-14.3l-34.2.6v13.5Z"
            fill="url(#e2n4USX5VQ890-fill)"
          ></path>
          <path
            d="M300.2,217.4l17.3.1v-13.5l-17.3.3v13.1Z"
            opacity="0.8"
            fill="url(#e2n4USX5VQ891-fill)"
          ></path>
          <path
            d="M300.2,227.1l53.6,1.2v-8.7l-53.6-.5v8Z"
            fill="url(#e2n4USX5VQ892-fill)"
          ></path>
          <g opacity="0.48">
            <path
              d="M301.7,228.4c5.1-1.7,9.3,1.8,13-1c4.1-3.1.8-9.1,4.6-12.6c5.3-4.9,15.9,2.7,20.5-1.9c3.2-3.2-.6-8.3,2.1-13.6c1.4-2.7,3.9-4.6,7-5.8l9.4-.4c.9,0,1.7.6,1.7,1.4v39.9v8c0,.8-.8,1.5-1.7,1.4l-62.1-2.6c-.7,0-1.4-.7-1.4-1.4v-5.2c2.4-3.8,4.8-5.5,6.9-6.2Z"
              opacity="0.48"
              fill="#fff"
            ></path>
          </g>
        </g>
        <path
          d="M292,267.3c0,.3.3.5.7.6.4,0,.7-.2.7-.5s-.3-.5-.7-.6-.7.2-.7.5Z"
          fill="#de8bdf"
        ></path>
        <g id="e2n4USX5VQ896" transform="translate(-9 0)">
          <path
            d="M274.1,266.5v55.5c0,.8.9,1.1,1.9,1.2l35.7,4l.5.1c1.1.1,2-.5,2-1.4v-58c0-.9-.9-1.6-2-1.6h-.7l-35.8.1c-1-.1-1.6-.7-1.6.1Z"
            fill="url(#e2n4USX5VQ897-fill)"
          ></path>
          <path
            d="M273,266.5v55.5c0,.8.8,1.6,1.9,1.7l36.3,3.5c1.1.1,2-.5,2-1.4v-58c0-.9-.9-1.6-2-1.6l-36.3-1.1c-1.1-.1-1.9.5-1.9,1.4Z"
            fill="url(#e2n4USX5VQ898-fill)"
          ></path>
          <path
            d="M310.3,269.4l-34.6-1.2c-.9,0-1.6.7-1.6,1.6l-.1,51c0,1,.7,1.8,1.7,1.9l34.5,3.3c.9.1,1.6-.6,1.6-1.5v-53.4c.1-.9-.6-1.6-1.5-1.7Z"
            fill="#fff"
          ></path>
          <path
            d="M309.2,282.1l-19.7-.9v18.4l19.7,1.4v-18.9Z"
            fill="url(#e2n4USX5VQ8100-fill)"
          ></path>
          <path
            d="M309.2,303.3L276.7,301v11.1l32.5,2.7v-11.5Z"
            opacity="0.8"
            fill="url(#e2n4USX5VQ8101-fill)"
          ></path>
          <path
            d="M310.2,269.4l-34.4-1.2c-.9,0-1.6.7-1.6,1.6v7.6l37.7,1.7v-7.9c0-1-.8-1.8-1.7-1.8Z"
            fill="url(#e2n4USX5VQ8102-fill)"
          ></path>
          <path
            d="M309.2,317.3l-32.5-2.8v5l32.5,3v-5.2Z"
            fill="url(#e2n4USX5VQ8103-fill)"
          ></path>
          <path
            d="M287.7,281.1l-11-.5v18.2l11,.8v-18.5Z"
            fill="url(#e2n4USX5VQ8104-fill)"
          ></path>
          <g opacity="0.48">
            <path
              d="M284.7,298.4c3.3-6.6,11-5,13.9-12.3c1.7-4.3-.4-6.3.9-11.6.9-3.6,2.8-6.4,5-8.6l6.7.2c1.1,0,2,.8,2,1.6v58c0,.9-.9,1.5-2,1.4L282,324.3c2.2-3,3.1-5.8,3.4-8.3c1-7.5-3.6-11.7-.7-17.6Z"
              opacity="0.48"
              fill="#fff"
            ></path>
          </g>
        </g>
        <g id="e2n4USX5VQ8107" transform="translate(1 0)">
          <path
            d="M337.6,276.1v38.3c0,.6.5.7,1,.8l19.1,2.4h.3c.6.1,1.1-.4,1.1-1v-39.9c0-.6-.5-1.1-1.1-1.1h-.4l-19.2.3c-.5,0-.8-.4-.8.2Z"
            fill="url(#e2n4USX5VQ8108-fill)"
          ></path>
          <path
            d="M337,276v38.3c0,.6.5,1.1,1,1.1l19.5,2c.6.1,1.1-.4,1.1-1v-39.9c0-.6-.5-1.1-1.1-1.1L338,275c-.5,0-1,.4-1,1Z"
            fill="url(#e2n4USX5VQ8109-fill)"
          ></path>
          <path
            d="M356.8,277.8l-17-.6c-1.2,0-2.2.9-2.2,2.1v34.3c0,.6.4,1,1,1.1l18.2,1.9c.6.1,1-.4,1-.9v-36.8c.1-.6-.4-1-1-1.1Z"
            fill="#fff"
          ></path>
          <path
            d="M355.8,287l-10-.5v12.8l10,.8v-13.1Z"
            fill="url(#e2n4USX5VQ8111-fill)"
          ></path>
          <path
            d="M355.8,301.6l-16.2-1.3v7.7l16.2,1.5v-7.9Z"
            fill="url(#e2n4USX5VQ8112-fill)"
          ></path>
          <path
            d="M355.8,310.9l-16.2-1.5v3.5l16.2,1.6v-3.6Z"
            opacity="0.8"
            fill="url(#e2n4USX5VQ8113-fill)"
          ></path>
          <path
            d="M344.7,286.4l-5-.3v12.6l5,.4v-12.7Z"
            opacity="0.8"
            fill="url(#e2n4USX5VQ8114-fill)"
          ></path>
          <path
            d="M356.9,277.8l-18.2-.7c-.6,0-1.1.5-1.1,1.1v5.3l20.3,1v-5.7c0-.5-.5-.9-1-1Z"
            fill="url(#e2n4USX5VQ8115-fill)"
          ></path>
          <path
            d="M347.2,276.4c0,.2.2.4.4.4s.4-.2.4-.4-.2-.4-.4-.4c-.2.1-.4.2-.4.4Z"
            fill="#de8bdf"
          ></path>
          <g opacity="0.48">
            <path
              d="M343.3,299c1.8-4.5,6.6-3.4,8.1-7.9c1.2-3.7-1.5-5.8-.4-10.1.6-2.4,2.1-4.2,3.6-5.5l3,.1c.6,0,1.1.5,1.1,1.1v39.9c0,.6-.5,1-1.1,1L342.7,316c1.2-1.9,1.7-3.6,1.8-5c.5-5.3-2.8-7.9-1.2-12Z"
              opacity="0.48"
              fill="#fff"
            ></path>
          </g>
        </g>
        <g>
          <path
            d="M151.8,457.3h-34.7c-1.7-2-1.1-9.5-.8-12c.1-.5.1-.8.1-.8s11.8-9.5,13.5-2.2c.1.2.1.5.2.7c2,6.8,11.2,9.9,16.5,10.7c3.4.6,4.7,2.4,5.2,3.6Z"
            fill="#1b3051"
          ></path>
          <path
            d="M57.2,344.7c1.3,0,59.4,10.9,69,22.8c4.8,5.9,5.5,74.6,5.5,74.6s-5.9,7.4-18.8,3.1c0,0-9.4-47-8.7-61.2.2-3.9-39.7-.6-47-2.5s-25.2-17.7-21.2-25.5c3.9-7.7,21.2-11.3,21.2-11.3Z"
            fill="#233862"
          ></path>
          <path
            d="M99.3,341.5c0,0,3.7,3.2,7.5,4.1c2.2.5,4.3,1.1,5.5,1.7c3.4,1.6,10,8.7,9.4,9.4-2,2.3-9.8-3.6-11.3-3.2s2.5,3.8.9,4.8c-.8.5-2.4-2.5-3.8-2.9-2-.6-3.3-3.5-3.9-3.7-1.1-.4-5.9-4.7-5.9-4.7l1.6-5.5Z"
            fill="url(#e2n4USX5VQ8122-fill)"
          ></path>
          <path
            d="M167.1,457.3h-34.7c-1.7-2-1.1-9.5-.8-12c.1-.5.1-.8.1-.8s11.8-9.5,13.5-2.2c.1.2.1.5.2.7c2,6.8,11.2,9.9,16.5,10.7c3.4.6,4.7,2.4,5.2,3.6Z"
            fill="#1e3c68"
          ></path>
          <path
            d="M74.3,344.7c1.3,0,59.4,10.9,69,22.8c4.8,5.9,5.5,74.6,5.5,74.6s-5.9,7.4-18.8,3.1c0,0-9.4-47-8.7-61.2.2-3.9-39.7-.6-47-2.5s-25.1-17.8-21.2-25.5s21.2-11.3,21.2-11.3Z"
            fill="#2b478b"
          ></path>
          <path
            d="M70.7,274.1c-4.2-.3-8.6-1.5-12.4-2.7-4.7-1.5-8.6-3.8-6.1-8.1c2.3-4,5.9-12.9,5.7-20.3l8.8,7.1l6,4.8c0,0,0,2.8-.7,7.8-.1.9-.3,1.9-.4,2.9-.1.8.3,1.7,1,2.1c0,0,.1.1.1.1c3,2,1.5,6.6-2,6.3Z"
            fill="url(#e2n4USX5VQ8125-fill)"
          ></path>
          <path
            d="M72.7,253.7c0,0,.3,1-.2,4.8-5.3-.8-10.4-5-10.4-5c2.7-.8,3.7-3.7,5.7-3.5l4.9,3.7Z"
            fill="url(#e2n4USX5VQ8126-fill)"
          ></path>
          <path
            d="M77.6,254.7c0,0-20.5,3.4-22.5-9.4-1.9-12.8-5.9-21,7.2-23.7c13-2.7,16.3,1.8,18,5.9s2.6,25.9-2.7,27.2Z"
            fill="url(#e2n4USX5VQ8127-fill)"
          ></path>
          <path
            d="M83.4,221.8c.9,3.1-1.5,6.8-5.4,8.1-5.9,1.8-4.6,9-7.2,8.9s-3.7-4.1-6.1-.9c-2,2.8,1.7,6,1.4,7.5-.4,2.6-4.5,7.9-8.2,7.6s-10.5-17.7-9.2-23.1s6.9-11.1,12.1-11.9s12.4,1.5,14.1,1.5c1.6.1,8,.6,8.5,2.3Z"
            fill="#0b232b"
          ></path>
          <path
            d="M88.4,340.9c-.3,3-1,5-1.9,5.6-3.1,2-7.1,4.6-11.6,7.1-16.1,9-38,17.6-42.5,2.5-5.3-17.8-1.8-51.1,3.3-71.9c3-11.9,11.2-24.5,16.7-24.5c9.4,0,23,2.5,28.5,16c2.1,5.2,5.2,23.4,6.8,40c1,10,1.4,19.4.7,25.2Z"
            fill="#f6c651"
          ></path>
          <path
            d="M51.4,259.9l2.1-4.8c0,0,19,1.9,22.2,12.8.1,0-14.6-7.8-24.3-8Z"
            fill="#f6c651"
          ></path>
          <path
            d="M88.4,340.9c-.3,3-1,5-1.9,5.6-3.1,2-7.1,4.6-11.6,7.1-4.7-2.9-14.4-9.7-16.7-17.8-3-10.6-.9-23.6-.9-23.6l31.1,28.7Z"
            fill="#fca94a"
          ></path>
          <path
            d="M55.3,276.1c0,0,1.6,45.4,4.2,54.4c4.4,15.3,44.6,20.7,44.6,20.7s2.4-2.7,1.3-7.1c0,0-24-18.9-24.7-22.5s5.2-28-1.6-36.9c-6.7-8.8-19.2-16.5-23.8-8.6Z"
            fill="#f6c651"
          ></path>
          <path
            d="M5,456.9h-.1c-1.1,0-1.9-.9-1.8-1.9l7.2-72l6.3.6L6.2,455.9c-.1.6-.6,1-1.2,1Z"
            fill="#77a4e5"
          ></path>
          <path
            d="M42.1,456.9h-.1c-1.1,0-1.9-.9-1.8-1.9l7.2-72l6.3.6L43.3,456c-.1.5-.6.9-1.2.9Z"
            fill="#77a4e5"
          ></path>
          <path
            d="M111.8,456.9h.1c1.1,0,1.9-.9,1.8-1.9l-7.2-72-6.3.6L110.6,456c.1.5.6.9,1.2.9Z"
            fill="#77a4e5"
          ></path>
          <path
            d="M7,372.7L0.2,312.6c-.9-8.7,2-17.2,14.5-17.8l22-1.3c13-.5,18.9,10.3,20.6,18.7l13.1,66.1L7,372.7Z"
            fill="#77a4e5"
          ></path>
          <path
            d="M10.7,384h97.7c2,0,3.6-1.6,3.6-3.6v-4.4c0-2-1.6-3.6-3.6-3.6h-101.3v8c0,2,1.6,3.6,3.6,3.6Z"
            fill="#77a4e5"
          ></path>
          <path
            d="M9.9,384h57.7c1.5,0,2.8-1.2,2.8-2.8v-6c0-1.5-1.2-2.8-2.8-2.8h-60.5v8.8c0,1.5,1.3,2.8,2.8,2.8Z"
            fill="#77a4e5"
          ></path>
        </g>
      </svg>
    </div>
  );
};
